import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import { ingestionApiDefinitions } from 'modules/service/types';
import { LOAD_STATUS, PERMISSIONS, AUTOMATION_ID } from 'modules/common/constants';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { getUserProfile } from 'modules/auth/selectors';
import {
    getTargetCredentialData,
    goToEditTargetCredentialPage,
    goToTargetCredentialsListPage,
    resetTargetCredentialDetails,
    resetTargetCredentialManifest
} from '../../actions';
import {
    getTargetCredentialDetails,
    getTargetCredentialDetailsLoadStatus,
    getTargetCredentialManifest,
    getTargetCredentialManifestLoadStatus
} from '../../selectors';

import local from './local.module.scss';

const SKELETON_PROPERTIES_COUNT = 2;

type RouteMatchParams = Record<'ownerId' | 'credentialId', string>;

const TargetCredentialDetailsLayout: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const { ownerId, credentialId } = useParams<RouteMatchParams>();
    const owner = useSelector(getUserProfile).owners.find(({ id }) => id === ownerId);
    const canView = Boolean(owner?.permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.VIEW));
    const canManage = Boolean(owner?.permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.MANAGE));

    const {
        name,
        targetType,
        authMethod,
        credentialContext,
        updatedBy,
        updateTimestamp = 0
    }: ingestionApiDefinitions['TargetCredentialDetailResponseDto'] = useSelector(getTargetCredentialDetails);
    const manifest = useSelector(getTargetCredentialManifest);
    const dataLoadStatus = useSelector(getTargetCredentialDetailsLoadStatus);
    const manifestLoadStatus = useSelector(getTargetCredentialManifestLoadStatus);
    const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED && manifestLoadStatus === LOAD_STATUS.LOADED;

    useEffect(() => {
        dispatch(resetTargetCredentialDetails());
        dispatch(resetTargetCredentialManifest());

        if (canView) {
            dispatch(getTargetCredentialData(ownerId, credentialId));
        }
    }, []);

    useDidUpdateEffect(() => {
        dispatch(resetTargetCredentialDetails());
        dispatch(resetTargetCredentialManifest());

        if (canView) {
            dispatch(getTargetCredentialData(ownerId, credentialId));
        }
    }, [ ownerId, credentialId ]);

    if (!canView) {
        return (
            <ErrorPage error='403' />
        );
    }

    return (
        <div>
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_CREDENTIALS_BREADCRUMB,
                        label: (<FormattedMessage id='credentials.allCredentials' />),
                        onClick: () => { dispatch(goToTargetCredentialsListPage()); }
                    },
                    {
                        label: name || ''
                    }
                ]}
                selectedItemIndex={1}
            />
            <div className={local.card}>
                <div className={classnames(local.titleContainer, 'container-row')}>
                    <div className={local.title}>
                        {
                            isLoaded ? name : <SkeletonItem width='320px' height='24px' />
                        }
                    </div>
                    {
                        isLoaded && canManage && <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.TARGET_CREDENTIAL_EDIT_BTN}
                                disabled={!isLoaded}
                                onClick={() => {
                                    dispatch(goToEditTargetCredentialPage(credentialId, ownerId));
                                }}
                            >
                                <FormattedMessage id='common.edit' />
                            </button>
                        </div>
                    }
                </div>
                <div className={local.ownerName}>
                    {
                        isLoaded ?
                            owner?.name :
                            <SkeletonItem width='160px' height='20px' />
                    }
                </div>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.targetType' />
                </div>
                <div className={local.mainPropertyValue}>
                    {
                        isLoaded ?
                            <FormattedMessage id={`common.targetType.${targetType}`} defaultMessage={targetType || 'Unknown'} /> :
                            <SkeletonItem width='160px' height='24px' />
                    }
                </div>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.authMethod' />
                </div>
                <div className={local.mainPropertyValue}>
                    {
                        isLoaded ?
                            <FormattedMessage id={`common.authMethod.${authMethod}`} defaultMessage={authMethod || 'Unknown'} /> :
                            <SkeletonItem width='160px' height='24px' />
                    }
                </div>
                <div>
                    {
                        isLoaded ?
                            credentialContext?.map(({ key, value, isSecret }) => {
                                const [panelId, fieldId] = key.split('.');
                                const field = manifest.panels.find(({ id }) => id === panelId)?.fields.find(({ id }) => id === fieldId);

                                return (
                                    <div key={key}>
                                        <div className={local.propertyName}>
                                            {field?.label || key}
                                        </div>
                                        <div className={local.propertyValue}>
                                            {isSecret && value ? '*'.repeat(12) : value}
                                        </div>
                                    </div>
                                );
                            }) :
                            Array.from(Array(SKELETON_PROPERTIES_COUNT)).map((el, index) => (
                                <div key={index}>
                                    <div className={local.propertyName}>
                                        <SkeletonItem width='160px' height='20px' />
                                    </div>
                                    <div className={local.propertyValue}>
                                        <SkeletonItem width='320px' height='16px' />
                                    </div>
                                </div>
                            ))
                    }
                </div>
                {
                    isLoaded &&
                    <Fragment>
                        <div className={local.divider} />
                        <div className={local.lastUpdatedTitle}>
                            <FormattedMessage id='common.lastUpdated' />
                        </div>
                        <div className={local.lastUpdatedValue}>
                            {`${moment.utc(updateTimestamp).format('DD MMMM YYYY [at] HH:mm UTC')} by ${updatedBy}`}
                        </div>
                    </Fragment>
                }
            </div>
        </div>
    );
};

export default TargetCredentialDetailsLayout;
