import { change, SubmissionError } from 'redux-form';
import { push } from 'redux-first-history';
import { Moment } from 'moment';

import { intl } from 'modules/i18n';
import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import { confirm, getErrorText, prepareFormErrors, showErrorSnackbar } from 'modules/common/utils';
import { ADVERITY_OPTIONS_FIELD_TYPE, EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS } from 'modules/common/constants';
import { getRouterPath } from 'modules/common/selectors';
import { getSelectedClientId } from 'modules/auth/selectors';
import { goToCredentialsListPage } from 'modules/credentials/actions';
import { ADVERITY_AUTHORIZATIONS_TAB_INDEX } from 'modules/credentials/constants';
import { AUTHORIZATIONS_ROUTES } from '../constants';
import * as selectors from '../selectors';
import * as storeActions from './storeActions';

export const getAuthorizationTypes = () => async (dispatch) => {
    dispatch(storeActions.setAuthorizationTypesLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response: ingestionApiDefinitions['ApiCollectionContainerDtoAuthorizationTypeDetailsDto'] = await service.api.getAdverityAuthorizationTypes();
        const items = response.objects?.filter(({ isDeprecated }) => !isDeprecated)
            .sort(({ name: name1 }, { name: name2 }) => (name1! > name2! ? 1 : -1));

        dispatch(storeActions.setAuthorizationTypes(items));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setAuthorizationTypesLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadAuthorizationTypeOptions = (authorizationTypeId) => async (dispatch) => {
    dispatch(storeActions.setAuthorizationTypeOptionsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response: ingestionApiDefinitions['AuthorizationTypeOptionsDto'] = await service.api.getAdverityAuthorizationTypeOptions(authorizationTypeId);

        dispatch(change('authorizationForm', 'configuration', undefined));
        dispatch(storeActions.setAuthorizationTypeOptions(response.actions!.POST));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setAuthorizationTypeOptionsLoadStatus(LOAD_STATUS.LOADED));
    }
};

const validateFormValues = (formValues, options) => {
    const errors: { [key: string]: any } = {};

    if (!formValues.authorizationTypeId) {
        errors.authorizationTypeId = 'custom.Required';
    }

    const requiredConfigurationFields = Object.entries<{ [key: string]: any }>(options)
        .filter(([, { required }]) => required)
        .map(([key]) => key);
    const { configuration } = formValues;

    requiredConfigurationFields.forEach((fieldName) => {
        if (!configuration || configuration[fieldName] === undefined || configuration[fieldName] === null) {
            if (!errors.configuration) {
                errors.configuration = {};
            }

            errors.configuration[fieldName] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

const prepareFormValues = (formValues, options) => {
    const processedValues = {};

    Object.entries(formValues).forEach(([key, value]) => {
        const { type } = options[key];

        switch (type) {
            case ADVERITY_OPTIONS_FIELD_TYPE.BASE64:
                processedValues[key] = btoa(value as string);
                break;
            case ADVERITY_OPTIONS_FIELD_TYPE.DATETIME:
                processedValues[key] = (value as Moment).format('YYYY-MM-DDTHH:mm:ss[Z]');
                break;
            default:
                processedValues[key] = value;
        }
    });

    return processedValues;
};

const prepareAuthorizationFormErrors = (data) => {
    data.validationErrors = data.validationErrors.map(({ code, field, description }) => ({
        code,
        description,
        field: field !== 'non_field_errors' ? `configuration.${field}` : field
    }));

    return prepareFormErrors(data);
};

export const addAuthorization = (formValues) => async (dispatch, getState) => {
    const storeState = getState();
    const options = selectors.getAuthorizationTypeOptions(storeState);

    const errors = validateFormValues(formValues, options);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setAuthorizationFormOperationInProgress(true));

    try {
        const clientId = getSelectedClientId(storeState);
        const { authorizationTypeId, configuration } = formValues;

        await service.api.addAdverityAuthorization(
            clientId,
            authorizationTypeId,
            { configuration: prepareFormValues(configuration || {}, options) }
        );

        service.analytics.trackEvent('Create Adverity authorization', EVENT_CATEGORY.CREDENTIALS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === AUTHORIZATIONS_ROUTES.NEW) {
            dispatch(goToAuthorizationsListPage(authorizationTypeId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareAuthorizationFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setAuthorizationFormOperationInProgress(false));
    }
};

export const goToAddAuthorizationPage = (authorizationTypeId: number | undefined = undefined) => (dispatch) => {
    dispatch(push(AUTHORIZATIONS_ROUTES.NEW, authorizationTypeId ? { authorizationTypeId } : undefined));
};

export const goToAuthorizationsListPage = (authorizationTypeId: number | undefined = undefined) => (dispatch) => {
    dispatch(goToCredentialsListPage(ADVERITY_AUTHORIZATIONS_TAB_INDEX, authorizationTypeId));
};

export const getAuthorizationsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setAuthorizationsListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const { authorizationTypeId, page, pageSize } = selectors.getAuthorizationsListState(storeState);

        const list: ingestionApiDefinitions['ApiPaginatedCollectionContainerDtoAuthorizationDto'] = await service.api.getAdverityAuthorizationsByType(clientId, authorizationTypeId, page, pageSize);

        dispatch(storeActions.appendAuthorizationsListItems(list.objects));
        dispatch(storeActions.setAuthorizationsListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setAuthorizationsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreAuthorizations = () => async (dispatch, getState) => {
    const page = selectors.getAuthorizationsListState(getState()).page + 1;

    dispatch(storeActions.setAuthorizationsListPage(page));
    await dispatch(getAuthorizationsList());

    service.analytics.trackEvent('Load more Adverity authorizations', EVENT_CATEGORY.CREDENTIALS);
};

export const deleteAuthorization = (authorizationId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'common.delete' }),
        messages: [ intl.formatMessage({ id: 'credentials.deleteAdverityAuthorizationConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setAuthorizationsListOperationInProgress(true));

        try {
            const storeState = getState();

            const clientId = getSelectedClientId(storeState);
            const { authorizationTypeId } = selectors.getAuthorizationsListState(storeState);

            await service.api.deleteAdverityAuthorization(clientId, authorizationTypeId, authorizationId);

            dispatch(storeActions.setAuthorizationsListPage(0));
            dispatch(storeActions.setAuthorizationsListItems([]));
            dispatch(storeActions.setAuthorizationsListLoadStatus(LOAD_STATUS.REQUIRED));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setAuthorizationsListOperationInProgress(false));
        }
    }
};

export const authorizeAuthorization = (authorizationId) => async (dispatch, getState) => {
    dispatch(storeActions.setAuthorizationsListOperationInProgress(true));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const { authorizationTypeId } = selectors.getAuthorizationsListState(storeState);

        const { url }: ingestionApiDefinitions['AuthorizeOauthAuthorizationDto'] = await service.api.authorizeAdverityAuthorization(clientId, authorizationTypeId, authorizationId);

        if (url) {
            const tab = window.open(url, '_blank');
            tab?.focus();
        } else {
            showErrorSnackbar(intl.formatMessage({ id: 'credentials.adverityAuthorizationLinkError' }));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setAuthorizationsListOperationInProgress(false));
    }
};
