export const SET_DATASETS = 'dataCatalog/SET_DATASETS';
export const SET_DATASETS_LOAD_STATUS = 'dataCatalog/SET_DATASETS_LOAD_STATUS';
export const SET_DATASET_TABLES = 'dataCatalog/SET_DATASET_TABLES';
export const SET_DATASET_METADATA = 'dataCatalog/SET_DATASET_METADATA';
export const SET_DATASET_LOAD_STATUS = 'dataCatalog/SET_DATASET_LOAD_STATUS';
export const SET_TABLE_DATA = 'dataCatalog/SET_TABLE_DATA';
export const SET_TABLE_DATA_LOAD_STATUS = 'dataCatalog/SET_TABLE_DATA_LOAD_STATUS';
export const SET_SELECTED_DATASET_ID = 'dataCatalog/SET_SELECTED_DATASET_ID';
export const SET_SELECTED_TABLE_ID = 'dataCatalog/SET_SELECTED_TABLE_ID';
export const SET_SCHEMA_SORT_BY = 'dataCatalog/SET_SCHEMA_SORT_BY';
export const SET_SCHEMA_SORT_ORDER = 'dataCatalog/SET_SCHEMA_SORT_ORDER';
export const SET_IS_MASTER_PROJECT = 'dataCatalog/SET_IS_MASTER_PROJECT';
export const SET_IS_SEARCH_IN_PROGRESS = 'dataCatalog/SET_IS_SEARCH_IN_PROGRESS';
export const SET_SEARCH_RESULT = 'dataCatalog/SET_SEARCH_RESULT';
export const SET_SEARCH_QUERY = 'dataCatalog/SET_SEARCH_QUERY';
export const RESET_CATALOG = 'dataCatalog/RESET';
