// members list
export const APPEND_MEMBERS_LIST_ITEMS = 'admin/members/APPEND_MEMBERS_LIST_ITEMS';
export const SET_MEMBERS_LIST_ITEMS = 'admin/members/SET_MEMBERS_LIST_ITEMS';
export const SET_MEMBERS_LIST_PAGE = 'admin/members/SET_MEMBERS_LIST_PAGE';
export const SET_MEMBERS_LIST_COUNT = 'admin/members/SET_MEMBERS_LIST_COUNT';
export const SET_MEMBERS_LIST_LOAD_STATUS = 'admin/members/SET_MEMBERS_LIST_LOAD_STATUS';
export const SET_MEMBERS_LIST_SORT_COLUMN = 'admin/members/SET_MEMBERS_LIST_SORT_COLUMN';
export const SET_MEMBERS_LIST_SORT_ORDER = 'admin/members/SET_MEMBERS_LIST_SORT_ORDER';
export const SET_MEMBERS_LIST_SEARCH_TEXT = 'admin/members/SET_MEMBERS_LIST_SEARCH_TEXT';
export const RESET_MEMBERS_LIST = 'admin/members/RESET_MEMBERS_LIST';
export const SET_MEMBERS_LIST_OPERATION_IN_PROGRESS = 'admin/members/SET_MEMBERS_LIST_OPERATION_IN_PROGRESS';
export const OPEN_MEMBER_DIALOG = 'admin/members/OPEN_MEMBER_DIALOG';
export const CLOSE_MEMBER_DIALOG = 'admin/members/CLOSE_MEMBER_DIALOG';

// member details
export const SET_MEMBER_DETAILS = 'admin/members/SET_MEMBER_DETAILS';
export const SET_MEMBER_DETAILS_LOAD_STATUS = 'admin/members/SET_MEMBER_DETAILS_LOAD_STATUS';
export const RESET_MEMBER_DETAILS = 'admin/members/RESET_MEMBER_DETAILS';
export const SET_MEMBER_DETAILS_OPERATION_IN_PROGRESS = 'admin/members/SET_MEMBER_DETAILS_OPERATION_IN_PROGRESS';
export const ADD_OWNER_MEMBER_ROLE = 'admin/members/ADD_OWNER_MEMBER_ROLE';
export const REMOVE_OWNER_MEMBER_ROLE = 'admin/members/REMOVE_OWNER_MEMBER_ROLE';
export const REMOVE_OWNER_MEMBERSHIP = 'admin/members/REMOVE_OWNER_MEMBERSHIP';
export const ADD_CLIENT_MEMBER_ROLE = 'admin/members/ADD_CLIENT_MEMBER_ROLE';
export const REMOVE_CLIENT_MEMBER_ROLE = 'admin/members/REMOVE_CLIENT_MEMBER_ROLE';
export const REMOVE_CLIENT_MEMBERSHIP = 'admin/members/REMOVE_CLIENT_MEMBERSHIP';
export const OPEN_OWNER_MEMBERSHIP_DIALOG = 'admin/members/OPEN_OWNER_MEMBERSHIP_DIALOG';
export const CLOSE_OWNER_MEMBERSHIP_DIALOG = 'admin/members/CLOSE_OWNER_MEMBERSHIP_DIALOG';
export const OPEN_CLIENT_MEMBERSHIP_DIALOG = 'admin/members/OPEN_CLIENT_MEMBERSHIP_DIALOG';
export const CLOSE_CLIENT_MEMBERSHIP_DIALOG = 'admin/members/CLOSE_CLIENT_MEMBERSHIP_DIALOG';
export const OPEN_GROUP_MEMBERSHIP_DIALOG = 'admin/members/OPEN_GROUP_MEMBERSHIP_DIALOG';
export const CLOSE_GROUP_MEMBERSHIP_DIALOG = 'admin/members/CLOSE_GROUP_MEMBERSHIP_DIALOG';
export const SET_GROUPS_LIST = 'admin/members/SET_GROUPS_LIST';
