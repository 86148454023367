import { connect } from 'react-redux';

import { removeSubmitError } from 'modules/form/actions';
import { updateGroupItem, removeGroupItem } from '../../actions';
import { getGroupItemProps } from '../../selectors';
import GroupItem from './GroupItem';
import type { GroupItemData } from './GroupItem';

const mapDispatchToProps = {
    updateItem: updateGroupItem,
    removeItem: removeGroupItem,
    removeSubmitError
};

export default connect(getGroupItemProps, mapDispatchToProps)(GroupItem);
export type { GroupItemData };
