import { connect } from 'react-redux';

import * as actions from 'modules/feeds/actions';
import { getFeedsListLayoutProps } from 'modules/feeds/selectors';
import FeedsListLayout from './FeedsListLayout';

const mapDispatchToProps = {
    loadData: actions.getFeedsList,
    goToAddFeedPage: actions.goToAddFeedPage,
    goToFeedRunsPage: actions.goToFeedRunsPage,
    onSearch: actions.feedsListSearch,
    resetTable: actions.resetFeedsList,
    loadMore: actions.loadMoreFeeds
};

export default connect(getFeedsListLayoutProps, mapDispatchToProps)(FeedsListLayout);
