import React, { Fragment, FunctionComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ReportPlatformCollectorCredential from '../ReportPlatformCollectorCredential';
import { REPORT_FORM_SECOND_STAGE } from '../../constants';
import { goToReportsListPage, setReportFormStage } from '../../actions';
import { getReportFormOperationInProgress, getReportFormTemplate } from '../../selectors';

import local from './local.module.scss';

interface FormThirdStageProps {
    isEditMode: boolean;
}

const FormThirdStage: FunctionComponent<FormThirdStageProps> = ({ isEditMode }) => {
    const dispatch = useAppDispatch();

    const isOperationInProgress = useSelector(getReportFormOperationInProgress);
    const template = useSelector(getReportFormTemplate);
    const formPlatforms = useReduxFormValue('reportForm', 'platforms');

    const [firstCredential, ...otherCredentials] = Object.entries<{ [key: string]: any }>(formPlatforms).map(([key, { platformId }]) => {
        const {
            platformName,
            collectors
        } = template.platforms.find(({ platformId: templatePlatformId }) => platformId === templatePlatformId);

        return collectors.map(({ collectorId, collectorName, vieCollectorId }) => (
            <ReportPlatformCollectorCredential
                key={`${platformId}.${collectorId}`}
                platformPrefix={key}
                platformName={platformName}
                collectorName={collectors.length > 1 ? collectorName : undefined}
                collectorId={collectorId}
                vieCollectorId={vieCollectorId}
            />
        ));
    }).flat();

    return (
        <Fragment>
            <div className={local.card}>
                <div className={classnames(local.titleContainer, 'container-row')}>
                    <div className={local.title}>
                        <FormattedMessage id={isEditMode ? 'reports.editReport' : 'reports.newReport'} />
                    </div>
                    <div className={classnames(local.buttonsContainer, 'container-row')}>
                        <div className='ls-button'>
                            <button
                                disabled={isOperationInProgress}
                                className='btn-flat'
                                onClick={() => {
                                    dispatch(goToReportsListPage());
                                }}
                            >
                                <FormattedMessage id='common.cancel' />
                            </button>
                        </div>
                        <div className='ls-button'>
                            <button
                                disabled={isOperationInProgress}
                                className='btn-flat'
                                onClick={(event) => {
                                    event.preventDefault();
                                    dispatch(setReportFormStage(REPORT_FORM_SECOND_STAGE));
                                }}
                            >
                                <FormattedMessage id='common.previous' />
                            </button>
                        </div>
                        <FormSubmitButton
                            form='reportForm'
                            width={65}
                            disabled={isOperationInProgress}
                            text={<FormattedMessage id='common.save' />}
                            isSubmitting={isOperationInProgress}
                        />
                    </div>
                </div>
                {firstCredential}
            </div>
            {
                otherCredentials.map((component, idx) => (
                    <div key={idx} className={local.card}>
                        {component}
                    </div>
                ))
            }
        </Fragment>
    );
};

export default FormThirdStage;
