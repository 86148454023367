import { PERMISSIONS, AUTOMATION_ID } from 'modules/common/constants';
import { CREDENTIALS_ROUTES } from 'modules/credentials/constants';
import { SOURCES_ROUTES } from 'modules/sources/constants';
import { FEEDS_ROUTES } from 'modules/feeds/constants';
import { TARGETS_ROUTES } from 'modules/targets/constants';
import { PIPELINES_ROUTES } from 'modules/transformationPipelines/constants';
import { INSTANCES_ROUTES } from 'modules/transformationInstances/constants';
import { CATALOG_ROUTES } from 'modules/dataCatalog/constants';
import { UPLOADS_ROUTES } from 'modules/uploads/constants';
import { REPORTS_ROUTES } from 'modules/reports/constants';
import { BASE_PATH as COLLECTOR_CREDENTIALS_BASE_PATH } from 'modules/collectorCredentials/constants';
import { BASE_PATH as TARGET_CREDENTIALS_BASE_PATH } from 'modules/targetCredentials/constants';

const ACTION_HANDLERS = {
};

export const initialState = {
    menuItems: [
        {
            id: AUTOMATION_ID.REPORTS_TAB,
            name: 'common.reports',
            route: REPORTS_ROUTES.LIST,
            requiredPermissions: [ PERMISSIONS.REPORTS.VIEW ],
            clientSpecific: true
        },
        {
            id: AUTOMATION_ID.INGESTION_TAB,
            name: 'common.ingestion',
            subItems: [
                {
                    id: AUTOMATION_ID.FEEDS_TAB,
                    name: 'common.feeds',
                    route: FEEDS_ROUTES.LIST,
                    requiredPermissions: [ PERMISSIONS.FEEDS.VIEW ],
                    clientSpecific: true
                },
                {
                    id: AUTOMATION_ID.SOURCES_TAB,
                    name: 'common.sources',
                    route: SOURCES_ROUTES.LIST,
                    requiredPermissions: [ PERMISSIONS.SOURCES.VIEW ]
                },
                {
                    id: AUTOMATION_ID.TARGETS_TAB,
                    name: 'common.destinations',
                    route: TARGETS_ROUTES.LIST,
                    requiredPermissions: [ PERMISSIONS.TARGETS.VIEW ]
                },
                {
                    id: AUTOMATION_ID.CREDENTIALS_TAB,
                    name: 'common.credentials',
                    route: CREDENTIALS_ROUTES.LIST,
                    nestedRoutes: [ COLLECTOR_CREDENTIALS_BASE_PATH, TARGET_CREDENTIALS_BASE_PATH ],
                    checkAccess: ({ owners, clients }, selectedClientId) => {
                        const client = clients.find(({ id }) => id === selectedClientId);

                        return client.permissions.includes(PERMISSIONS.ADVERITY_AUTHORIZATIONS.VIEW) ||
                            owners.some(({ permissions }) =>
                                permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.VIEW) ||
                                permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.VIEW)
                            );
                    }
                }
            ]
        },
        {
            id: AUTOMATION_ID.TRANSFORMATION_TAB,
            name: 'common.transformation',
            subItems: [
                {
                    id: AUTOMATION_ID.PIPELINES_TAB,
                    name: 'common.pipelines',
                    route: PIPELINES_ROUTES.LIST,
                    requiredPermissions: [ PERMISSIONS.PIPELINES.VIEW ]
                },
                {
                    id: AUTOMATION_ID.INSTANCES_TAB,
                    name: 'common.instances',
                    route: INSTANCES_ROUTES.LIST,
                    requiredPermissions: [ PERMISSIONS.INSTANCES.VIEW ],
                    clientSpecific: true
                }
            ]
        },
        {
            id: AUTOMATION_ID.UPLOADS_TAB,
            name: 'common.uploads',
            route: UPLOADS_ROUTES.LIST,
            requiredPermissions: [ PERMISSIONS.UPLOADS.VIEW ],
            clientSpecific: true
        },
        {
            id: AUTOMATION_ID.CATALOGUE_TAB,
            name: 'common.catalog',
            route: CATALOG_ROUTES.LIST,
            requiredPermissions: []
        }
    ]
};

const reducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};

export default reducer;
