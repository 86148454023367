import React, { FunctionComponent, ReactNode } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

import local from './local.module.scss';

interface RadioButtonProps {
    id?: string;
    value: any;
    disabled: boolean;
    label: ReactNode;
}

const RadioButton: FunctionComponent<RadioButtonProps> = ({ disabled, label, value, id }) => (
    <FormControlLabel
        value={value}
        label={label}
        classes={{
            label: local.radioLabelRoot
        }}
        disabled={disabled}
        control={
            <Radio
                id={id}
                icon={<FontAwesomeIcon className={local.radioUncheckedIcon} icon={faCircle} />}
                checkedIcon={<FontAwesomeIcon className={local.radioCheckedIcon} icon={faCheckCircle} />}
                disableRipple={true}
                classes={{
                    root: local.radioRoot
                }}
            />
        }
    />
);

export default RadioButton;
