import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from 'modules/auth/reducer';
import mainReducer from 'modules/main/reducer';
import collectorCredentialsReducer from 'modules/collectorCredentials/reducer';
import targetCredentialsReducer from 'modules/targetCredentials/reducer';
import targetsReducer from 'modules/targets/reducer';
import feedsReducer from 'modules/feeds/reducer';
import sourcesReducer from 'modules/sources/reducer';
import adminReducer from 'modules/admin/reducer';
import pipelinesReducer from 'modules/transformationPipelines/reducer';
import instancesReducer from 'modules/transformationInstances/reducer';
import dataCatalogReducer from 'modules/dataCatalog/reducer';
import uploadsReducer from 'modules/uploads/reducer';
import reportsReducer from 'modules/reports/reducer';
import adverityAuthorizationsReducer from 'modules/adverityAuthorizations/reducer';
import enhancedFormReducer from 'modules/form/reducer';

import { REDUCER_NAME as authReducerName } from 'modules/auth/constants';
import { REDUCER_NAME as mainReducerName } from 'modules/main/constants';
import { REDUCER_NAME as collectorCredentialsReducerName } from 'modules/collectorCredentials/constants';
import { REDUCER_NAME as targetCredentialsReducerName } from 'modules/targetCredentials/constants';
import { REDUCER_NAME as targetsReducerName } from 'modules/targets/constants';
import { REDUCER_NAME as feedsReducerName } from 'modules/feeds/constants';
import { REDUCER_NAME as sourcesReducerName } from 'modules/sources/constants';
import { REDUCER_NAME as adminReducerName } from 'modules/admin/constants';
import { REDUCER_NAME as pipelinesReducerName } from 'modules/transformationPipelines/constants';
import { REDUCER_NAME as instancesReducerName } from 'modules/transformationInstances/constants';
import { REDUCER_NAME as dataCatalogReducerName } from 'modules/dataCatalog/constants';
import { REDUCER_NAME as uploadsReducerName } from 'modules/uploads/constants';
import { REDUCER_NAME as reportsReducerName } from 'modules/reports/constants';
import { REDUCER_NAME as adverityAuthorizationsReducerName } from 'modules/adverityAuthorizations/constants';

export const createRootReducer = (routerReducer) => combineReducers({
    [authReducerName]: authReducer,
    [mainReducerName]: mainReducer,
    [collectorCredentialsReducerName]: collectorCredentialsReducer,
    [targetCredentialsReducerName]: targetCredentialsReducer,
    [targetsReducerName]: targetsReducer,
    [feedsReducerName]: feedsReducer,
    [sourcesReducerName]: sourcesReducer,
    [adminReducerName]: adminReducer,
    [pipelinesReducerName]: pipelinesReducer,
    [instancesReducerName]: instancesReducer,
    [dataCatalogReducerName]: dataCatalogReducer,
    [uploadsReducerName]: uploadsReducer,
    [reportsReducerName]: reportsReducer,
    [adverityAuthorizationsReducerName]: adverityAuthorizationsReducer,
    form: formReducer.plugin({
        // make sure to add enhancedFormReducer for every form which can contain APSQL inputs
        // or needs to support custom form actions
        instanceForm: enhancedFormReducer.bind(null, 'instanceForm'),
        instanceAdHocRunForm: enhancedFormReducer.bind(null, 'instanceAdHocRunForm'),
        feedForm: enhancedFormReducer.bind(null, 'feedForm'),
        sourceForm: enhancedFormReducer.bind(null, 'sourceForm'),
        groupForm: enhancedFormReducer.bind(null, 'groupForm'),
        reportForm: enhancedFormReducer.bind(null, 'reportForm')
    }),
    router: routerReducer
});
