import { connect } from 'react-redux';

import * as actions from 'modules/transformationInstances/actions';
import { getInstancesListLayoutProps } from 'modules/transformationInstances/selectors';
import InstancesListLayout from './InstancesListLayout';

const mapDispatchToProps = {
    loadData: actions.getInstancesList,
    onSearch: actions.instancesListSearch,
    resetTable: actions.resetInstancesList,
    loadMore: actions.loadMoreInstances,
    goToAddInstancePage: actions.goToAddInstancePage,
    toggleArchivedInstances: actions.toggleArchivedInstances
};

export default connect(getInstancesListLayoutProps, mapDispatchToProps)(InstancesListLayout);
