import { connect } from 'react-redux';

import { withRouteParams } from 'modules/common/hoc';
import {
    getCollectorCredentialDetails,
    goToCollectorCredentialsListPage,
    goToEditCollectorCredentialPage,
    resetCollectorCredentialDetails
} from '../../actions';
import { getCollectorCredentialDetailsProps } from '../../selectors';
import CollectorCredentialDetailsLayout from './CollectorCredentialDetailsLayout';

const mapDispatchToProps = {
    resetData: resetCollectorCredentialDetails,
    loadData: getCollectorCredentialDetails,
    goToEditCollectorCredentialPage,
    goToCollectorCredentialsListPage
};

export default withRouteParams(connect(getCollectorCredentialDetailsProps, mapDispatchToProps)(CollectorCredentialDetailsLayout));
