import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useAppDispatch } from 'modules/common/hooks';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH } from 'modules/common/constants';
import { formatString } from 'modules/common/utils';
import { TARGETS_ROUTES } from '../../constants';
import { getTargetsListState } from '../../selectors';
import { goToTargetDetailsPage, loadMoreTargets, targetsListSort } from '../../actions';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const TargetsList: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { items, totalCount, sortBy, sortOrder, loadStatus } = useSelector(getTargetsListState);

    const isLoaded = loadStatus === LOAD_STATUS.LOADED;

    const tableConfig = [
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.name' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'name',
            accessor: ({ id, ownerId, name }) => (
                <a href={`#${formatString(TARGETS_ROUTES.DETAILS, ownerId, id)}`} className={local.name}>
                    {name}
                </a>
            ),
            width: CONTENT_CONTAINER_MAX_WIDTH,
            skeletonWidth: '74%',
            sortable: true,
            showTooltip: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.targetType' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'targetType',
            accessor: ({ targetType }) => <FormattedMessage id={`common.targetType.${targetType}`} defaultMessage={targetType} />,
            width: '15%',
            skeletonWidth: '100%',
            sortable: true,
            showTooltip: true,
            headerCellClassName: 'secondary-column-cell'
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.owner' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            accessor: 'ownerName',
            width: '15%',
            skeletonWidth: '100%',
            sortable: true,
            showTooltip: true,
            headerCellClassName: 'secondary-column-cell'
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'updateTimestamp',
            accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
            width: '20%',
            skeletonWidth: '100%',
            sortable: true,
            showTooltip: true,
            headerCellClassName: 'secondary-column-cell'
        }
    ];

    return (
        <Fragment>
            <Table
                columns={tableConfig}
                data={items}
                onSort={(columnName: string) => { dispatch(targetsListSort(columnName)); }}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={isLoaded}
                onRowClick={({ id, ownerId }) => {
                    isLoaded && dispatch(goToTargetDetailsPage(id, ownerId));
                }}
            />
            {
                isLoaded && (totalCount > items.length) &&
                <LoadMoreButton
                    load={() => { dispatch(loadMoreTargets()); }}
                    count={items.length}
                    totalCount={totalCount}
                />
            }
        </Fragment>
    );
};

export default TargetsList;
