import React, { Fragment, FunctionComponent, ReactElement } from 'react';
import classnames from 'classnames';

import local from './local.module.scss';

export interface BreadcrumbItemType {
    id?: string;
    label: string | ReactElement;
    onClick?: () => void;
}

interface BreadcrumbsClasses {
    breadcrumbs?: string;
    breadcrumbsLink?: string;
    breadcrumbsDivider?: string;
}

interface BreadcrumbsProps {
    items: BreadcrumbItemType[];
    selectedItemIndex?: number;
    classes?: BreadcrumbsClasses;
}

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = (props) => {
    const { items = [], selectedItemIndex, classes = {} } = props;

    const { length: itemsCount } = items;

    if (!itemsCount) {
        return null;
    }

    return (
        <div className={classnames(local.breadcrumbs, classes.breadcrumbs)}>
            {items.map((item, index) => {
                const { label, onClick, id } = item;
                const isSelected = index === selectedItemIndex;

                return (
                    <Fragment key={`breadcrumb_${index}`}>
                        <span
                            id={id}
                            className={classnames(local.breadcrumbsLink, classes.breadcrumbsLink, { [local.breadcrumbsSelected]: isSelected })}
                            onClick={onClick}
                        >
                            {label}
                        </span>
                        {(index < itemsCount - 1) && (<span className={classnames(local.breadcrumbsDivider, classes.breadcrumbsDivider)}>/</span>)}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
