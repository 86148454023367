import React, { useEffect, FunctionComponent, Fragment, PropsWithChildren } from 'react';

import { pendoSnippetFunction } from './snippet';

const { REACT_APP_PENDO_API_KEY } = process.env;

const PendoProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    useEffect(() => {
        pendoSnippetFunction(REACT_APP_PENDO_API_KEY);
    }, []);

    return (
        <Fragment>
            {children}
        </Fragment>
    );
};

export default PendoProvider;
