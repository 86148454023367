import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import { RouteComponentProps } from 'modules/common/interfaces';
import { AUTOMATION_ID, EVENT_CATEGORY } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import ClientForm from '../ClientForm';

import local from './local.module.scss';

interface ClientFormLayoutProps {
    isOperationInProgress: boolean;
    canAccess: boolean;
    goToClientsListPage: Function;
    goToClientDetailsPage: Function;
    addClient: Function;
    editClient: Function;
    clearSubmitErrors: Function;
    isEditMode: boolean;
    displayName: string;
    isDataLoading: boolean;
}

interface RouteMatchParams {
    clientId: string;
}

class ClientFormLayout extends Component<ClientFormLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public render() {
        const { isEditMode, isOperationInProgress, isDataLoading, match, canAccess } = this.props;

        if (!canAccess) {
            return (
                <ErrorPage error='403' />
            );
        }

        return (
            <Fragment>
                {this.renderBreadcrumbs()}
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            <FormattedMessage id={isEditMode ? 'admin.editClient' : 'admin.newClient'} />
                        </div>
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.CLIENT_FORM_CANCEL_BTN}
                                    disabled={isOperationInProgress}
                                    className='btn-flat'
                                    onClick={this.onCancelButtonClick}
                                >
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <FormSubmitButton
                                id={AUTOMATION_ID.CLIENT_FORM_SAVE_BTN}
                                form='clientForm'
                                width={130}
                                disabled={isOperationInProgress || isDataLoading}
                                text={<FormattedMessage id='common.saveChanges' />}
                                isSubmitting={isOperationInProgress}
                            />
                        </div>
                    </div>
                    <ClientForm isEditMode={isEditMode} match={match} onSubmit={this.onFormSubmit} />
                </div>
            </Fragment>
        );
    }

    private onFormSubmit = (values) => {
        const { isEditMode, addClient, editClient, clearSubmitErrors, match: { params: { clientId } } } = this.props;

        clearSubmitErrors('clientForm');

        return isEditMode ?
            editClient(clientId, values) :
            addClient(values);
    }

    private renderBreadcrumbs = () => {
        const { isEditMode, displayName, goToClientsListPage } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_CLIENTS_BREADCRUMB,
                        label: (<FormattedMessage id='admin.allClients' />),
                        onClick: () => { goToClientsListPage(); }
                    },
                    {
                        label: isEditMode ? displayName : <FormattedMessage id='admin.newClient' />
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private onCancelButtonClick = () => {
        const { isEditMode, goToClientDetailsPage, goToClientsListPage, match } = this.props;

        if (isEditMode) {
            goToClientDetailsPage(match.params.clientId);

            service.analytics.trackEvent('Cancel client edit', EVENT_CATEGORY.ADMIN);
        } else {
            goToClientsListPage();

            service.analytics.trackEvent('Cancel client creation', EVENT_CATEGORY.ADMIN);
        }
    }
}

export default ClientFormLayout;
