import service from 'modules/service';
import { intl } from 'modules/i18n';
import { confirm, getErrorText, showErrorSnackbar } from 'modules/common/utils';
import { EVENT_CATEGORY, LOAD_STATUS } from 'modules/common/constants';
import { getUserProfile } from 'modules/auth/selectors';
import { loadUserProfile } from 'modules/auth/actions';
import * as storeActions from './storeActions';

export const deleteMember = (id) => async (dispatch) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.deleteMember' }),
        messages: [ intl.formatMessage({ id: 'admin.deleteMemberConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setMembersListOperationInProgress(true));

        try {
            await service.api.deleteMember(id);
            service.analytics.trackEvent('Delete member', EVENT_CATEGORY.ADMIN);

            dispatch(storeActions.setMembersListPage(0));
            dispatch(storeActions.setMembersListItems([]));
            dispatch(storeActions.setMembersListLoadStatus(LOAD_STATUS.REQUIRED));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setMembersListOperationInProgress(false));
        }
    }
};

export const addOwnerRole = (memberId, ownerId, role) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.addRole' }),
        messages: [ intl.formatMessage({ id: 'admin.addRoleConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setMemberDetailsOperationInProgress(true));

        try {
            await service.api.addOwnerRole(memberId, ownerId, role);

            dispatch(storeActions.addOwnerMemberRole({ ownerId, role }));

            service.analytics.trackEvent('Add owner member role', EVENT_CATEGORY.ADMIN, role);

            const { id } = getUserProfile(getState());
            if (id === memberId) {
                await dispatch(loadUserProfile());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setMemberDetailsOperationInProgress(false));
        }
    }
};

export const deleteOwnerMembership = (memberId, ownerId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.revokeMembership' }),
        messages: [ intl.formatMessage({ id: 'admin.ownerRevokeMembershipConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setMemberDetailsOperationInProgress(true));

        try {
            await service.api.deleteOwnerMembership(memberId, ownerId);

            dispatch(storeActions.removeOwnerMembership(ownerId));

            service.analytics.trackEvent('Revoke owner membership', EVENT_CATEGORY.ADMIN);

            const { id } = getUserProfile(getState());
            if (id === memberId) {
                await dispatch(loadUserProfile());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setMemberDetailsOperationInProgress(false));
        }
    }
};

export const deleteOwnerRole = (memberId, ownerId, role) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.removeRole' }),
        messages: [ intl.formatMessage({ id: 'admin.removeRoleConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setMemberDetailsOperationInProgress(true));

        try {
            await service.api.deleteOwnerRole(memberId, ownerId, role);

            dispatch(storeActions.removeOwnerMemberRole({ ownerId, role }));

            service.analytics.trackEvent('Remove owner member role', EVENT_CATEGORY.ADMIN);

            const { id } = getUserProfile(getState());
            if (id === memberId) {
                await dispatch(loadUserProfile());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setMemberDetailsOperationInProgress(false));
        }
    }
};

export const addClientRole = (memberId, clientId, role) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.addRole' }),
        messages: [ intl.formatMessage({ id: 'admin.addRoleConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setMemberDetailsOperationInProgress(true));

        try {
            await service.api.addClientRole(memberId, clientId, role);

            dispatch(storeActions.addClientMemberRole({ clientId, role }));

            service.analytics.trackEvent('Add client member role', EVENT_CATEGORY.ADMIN, role);

            const { id } = getUserProfile(getState());
            if (id === memberId) {
                await dispatch(loadUserProfile());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setMemberDetailsOperationInProgress(false));
        }
    }
};

export const deleteClientMembership = (memberId, clientId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.revokeMembership' }),
        messages: [ intl.formatMessage({ id: 'admin.clientRevokeMembershipConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setMemberDetailsOperationInProgress(true));

        try {
            await service.api.deleteClientMembership(memberId, clientId);

            dispatch(storeActions.removeClientMembership(clientId));

            service.analytics.trackEvent('Revoke client membership', EVENT_CATEGORY.ADMIN);

            const { id } = getUserProfile(getState());
            if (id === memberId) {
                await dispatch(loadUserProfile());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setMemberDetailsOperationInProgress(false));
        }
    }
};

export const deleteClientRole = (memberId, clientId, role) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.removeRole' }),
        messages: [ intl.formatMessage({ id: 'admin.removeRoleConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.confirm' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setMemberDetailsOperationInProgress(true));

        try {
            await service.api.deleteClientRole(memberId, clientId, role);

            dispatch(storeActions.removeClientMemberRole({ clientId, role }));

            service.analytics.trackEvent('Remove client member role', EVENT_CATEGORY.ADMIN);

            const { id } = getUserProfile(getState());
            if (id === memberId) {
                await dispatch(loadUserProfile());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setMemberDetailsOperationInProgress(false));
        }
    }
};
