import { connect } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';

import { getOwnerFormLayoutProps } from '../../selectors';
import { goToOwnersListPage, addOwner } from '../../actions';
import OwnerFormLayout from './OwnerFormLayout';

const mapDispatchToProps = {
    goToOwnersListPage,
    addOwner,
    clearSubmitErrors
};

export default connect(getOwnerFormLayoutProps, mapDispatchToProps)(OwnerFormLayout);
