import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withRouteParams } from 'modules/common/hoc';
import { getInstanceDetailsProps } from 'modules/transformationInstances/selectors';
import * as actions from 'modules/transformationInstances/actions';
import InstanceDetailsLayout from './InstanceDetailsLayout';

const mapDispatchToProps = {
    resetData: actions.resetInstanceDetails,
    loadData: actions.getInstanceDetails,
    goToInstancesListPage: actions.goToInstancesListPage,
    togglePauseInstanceSchedule: actions.togglePauseInstanceSchedule,
    toggleEnableInstance: actions.toggleEnableInstance,
    goToEditInstancePage: actions.goToEditInstancePage,
    openAdHocRunDialog: actions.openAdHocRunDialog,
    setRunMode: actions.setInstanceRunMode,
    archiveInstance: actions.archiveInstance,
    unarchiveInstance: actions.unarchiveInstance,
    showInstanceDeleteDialog: actions.showInstanceDeleteDialog
};

export default withRouteParams(injectIntl(connect(getInstanceDetailsProps, mapDispatchToProps)(InstanceDetailsLayout)));
