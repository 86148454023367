import React, { Fragment, FunctionComponent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import { AUTOMATION_ID, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { Tabs, TabPanel } from 'modules/common/components/Tabs';
import SearchInput from 'modules/common/components/SearchInput';
import ErrorPage from 'modules/common/components/ErrorPage';
import SelectInput from 'modules/common/components/SelectInput';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import CollectorCredentialsList from 'modules/collectorCredentials/components/CollectorCredentialsList';
import TargetCredentialsList from 'modules/targetCredentials/components/TargetCredentialsList';
import AuthorizationsList from 'modules/adverityAuthorizations/components/AuthorizationsList';
import {
    collectorCredentialsListSearch,
    getCollectorCredentialsList,
    goToAddCollectorCredentialPage,
    resetCollectorCredentialsList
} from 'modules/collectorCredentials/actions';
import {
    getTargetCredentialsList,
    goToAddTargetCredentialPage,
    resetTargetCredentialsList,
    targetCredentialsListSearch
} from 'modules/targetCredentials/actions';
import {
    getAuthorizationTypes,
    getAuthorizationsList,
    goToAddAuthorizationPage,
    setAuthorizationsListLoadStatus,
    setAuthorizationsListType,
    setAuthorizationsListItems,
    setAuthorizationsListPage,
    resetAuthorizationsList
} from 'modules/adverityAuthorizations/actions';
import { getCollectorCredentialsListLoadStatus } from 'modules/collectorCredentials/selectors';
import { getTargetCredentialsListState } from 'modules/targetCredentials/selectors';
import {
    getAuthorizationTypesLoadStatus,
    getAuthorizationTypesList,
    getAuthorizationsListState
} from 'modules/adverityAuthorizations/selectors';
import {
    ADVERITY_AUTHORIZATIONS_TAB_INDEX,
    COLLECTOR_CREDENTIALS_TAB_INDEX,
    TARGET_CREDENTIALS_TAB_INDEX
} from '../../constants';

import local from './local.module.scss';

interface LocationState {
    tabIndex?: number;
    adverityAuthorizationTypeId?: number;
}

interface CredentialsListsLayoutProps {
    locationState?: LocationState;
}

const CredentialsListsLayout: FunctionComponent<CredentialsListsLayoutProps> = ({ locationState }) => {
    const dispatch = useAppDispatch();

    const { owners, clients } = useSelector(getUserProfile);
    const selectedClientId = useSelector(getSelectedClientId);
    const client = clients.find(({ id }) => id === selectedClientId);

    const canViewCollectorCredentials = owners.some((owner) => owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.VIEW));
    const canManageCollectorCredentials = owners.some((owner) => owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE));
    const canViewTargetCredentials = owners.some((owner) => owner.permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.VIEW));
    const canManageTargetCredentials = owners.some((owner) => owner.permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.MANAGE));
    const canViewAdverityAuthorizations = client.permissions.includes(PERMISSIONS.ADVERITY_AUTHORIZATIONS.VIEW);
    const canManageAdverityAuthorizations = client.permissions.includes(PERMISSIONS.ADVERITY_AUTHORIZATIONS.MANAGE);

    const accessMap = {
        [COLLECTOR_CREDENTIALS_TAB_INDEX]: canViewCollectorCredentials,
        [TARGET_CREDENTIALS_TAB_INDEX]: canViewTargetCredentials,
        [ADVERITY_AUTHORIZATIONS_TAB_INDEX]: canViewAdverityAuthorizations
    };
    const defaultTabIndex = typeof locationState?.tabIndex === 'number' && accessMap[locationState.tabIndex] ?
        locationState.tabIndex :
        Object.entries(accessMap).filter(([, value]) => value).map(([key]) => parseInt(key, 10))[0] ?? -1;

    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex);
    const searchInputRef = useRef() as MutableRefObject<HTMLInputElement>;

    const {
        authorizationTypeId: adverityAuthorizationTypeId,
        loadStatus: adverityAuthorizationsLoadStatus
    } = useSelector(getAuthorizationsListState);
    const adverityAuthorizationTypes = useSelector(getAuthorizationTypesList);
    const adverityAuthorizationTypesLoadStatus = useSelector(getAuthorizationTypesLoadStatus);

    const collectorCredentialsLoadStatus = useSelector(getCollectorCredentialsListLoadStatus);

    const { loadStatus: targetCredentialsLoadStatus } = useSelector(getTargetCredentialsListState);

    const isDataLoading = collectorCredentialsLoadStatus === LOAD_STATUS.LOADING ||
        targetCredentialsLoadStatus === LOAD_STATUS.LOADING ||
        adverityAuthorizationsLoadStatus === LOAD_STATUS.LOADING;

    useEffect(() => {
        dispatch(resetCollectorCredentialsList());
        dispatch(resetTargetCredentialsList());
        dispatch(resetAuthorizationsList());

        if (
            locationState?.tabIndex === ADVERITY_AUTHORIZATIONS_TAB_INDEX &&
            locationState?.adverityAuthorizationTypeId
        ) {
            dispatch(setAuthorizationsListType(locationState.adverityAuthorizationTypeId));
        }
    }, []);

    useEffect(() => {
        switch (selectedTabIndex) {
            case COLLECTOR_CREDENTIALS_TAB_INDEX:
                if (canViewCollectorCredentials && collectorCredentialsLoadStatus === LOAD_STATUS.REQUIRED) {
                    dispatch(getCollectorCredentialsList());
                }
                break;
            case TARGET_CREDENTIALS_TAB_INDEX:
                if (canViewTargetCredentials && targetCredentialsLoadStatus === LOAD_STATUS.REQUIRED) {
                    dispatch(getTargetCredentialsList());
                }
                break;
            case ADVERITY_AUTHORIZATIONS_TAB_INDEX:
                if (canViewAdverityAuthorizations) {
                    if (adverityAuthorizationTypesLoadStatus === LOAD_STATUS.REQUIRED) {
                        dispatch(getAuthorizationTypes());
                    }

                    if (adverityAuthorizationTypeId && adverityAuthorizationsLoadStatus === LOAD_STATUS.REQUIRED) {
                        dispatch(getAuthorizationsList());
                    }
                }
                break;
        }
    },
    [
        adverityAuthorizationTypeId,
        adverityAuthorizationTypesLoadStatus,
        adverityAuthorizationsLoadStatus,
        collectorCredentialsLoadStatus,
        targetCredentialsLoadStatus,
        selectedTabIndex
    ]);

    if (!canViewCollectorCredentials && !canViewTargetCredentials && !canViewAdverityAuthorizations) {
        return (
            <ErrorPage error='403' />
        );
    }

    return (
        <Fragment>
            <div className={classnames(local.tableControls, 'container-row')}>
                {
                    selectedTabIndex === ADVERITY_AUTHORIZATIONS_TAB_INDEX ?
                        <SelectInput
                            inputProperties={{
                                onChange: (value) => {
                                    if (value !== adverityAuthorizationTypeId) {
                                        dispatch(setAuthorizationsListType(value));
                                        dispatch(setAuthorizationsListPage(0));
                                        dispatch(setAuthorizationsListItems([]));
                                        dispatch(setAuthorizationsListLoadStatus(LOAD_STATUS.REQUIRED));
                                    }
                                },
                                value: adverityAuthorizationTypeId
                            }}
                            items={adverityAuthorizationTypes}
                            disabled={isDataLoading || !canViewAdverityAuthorizations}
                            searchable={true}
                            placeholder={<FormattedMessage id='credentials.selectAuthorizationType' />}
                            isLoading={adverityAuthorizationTypesLoadStatus === LOAD_STATUS.LOADING}
                            width={440}
                        /> :
                        <SearchInput
                            inputId={AUTOMATION_ID.CREDENTIALS_SEARCH}
                            disabled={isDataLoading}
                            onSearch={(value) => {
                                selectedTabIndex === COLLECTOR_CREDENTIALS_TAB_INDEX ?
                                    (canViewCollectorCredentials && dispatch(collectorCredentialsListSearch(value))) :
                                    (canViewTargetCredentials && dispatch(targetCredentialsListSearch(value)));
                            }}
                            ref={searchInputRef}
                        />
                }
                {
                    selectedTabIndex === COLLECTOR_CREDENTIALS_TAB_INDEX && canManageCollectorCredentials &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.NEW_COLLECTOR_CREDENTIAL_BTN}
                            disabled={isDataLoading}
                            onClick={() => {
                                dispatch(goToAddCollectorCredentialPage());
                            }}
                            className={local.newButton}
                        >
                            <FormattedMessage id='credentials.newCollectorCredential' />
                        </button>
                    </div>
                }
                {
                    selectedTabIndex === TARGET_CREDENTIALS_TAB_INDEX && canManageTargetCredentials &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.NEW_TARGET_CREDENTIAL_BTN}
                            disabled={isDataLoading}
                            onClick={() => {
                                dispatch(goToAddTargetCredentialPage());
                            }}
                            className={local.newButton}
                        >
                            <FormattedMessage id='credentials.newDestinationCredential' />
                        </button>
                    </div>
                }
                {
                    selectedTabIndex === ADVERITY_AUTHORIZATIONS_TAB_INDEX && canManageAdverityAuthorizations &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.NEW_ADVERITY_AUTHORIZATION_BTN}
                            disabled={isDataLoading}
                            onClick={() => {
                                dispatch(goToAddAuthorizationPage(adverityAuthorizationTypeId));
                            }}
                            className={local.newButton}
                        >
                            <FormattedMessage id='credentials.newAdverityAuthorization' />
                        </button>
                    </div>
                }
            </div>
            <Tabs
                tabClasses={{ root: local.tabRoot }}
                value={selectedTabIndex}
                onChange={(event, idx) => {
                    setSelectedTabIndex(idx);

                    if (searchInputRef.current) {
                        searchInputRef.current.value = '';
                    }

                    switch (idx) {
                        case COLLECTOR_CREDENTIALS_TAB_INDEX:
                            dispatch(resetCollectorCredentialsList());
                            break;
                        case TARGET_CREDENTIALS_TAB_INDEX:
                            dispatch(resetTargetCredentialsList());
                            break;
                        case ADVERITY_AUTHORIZATIONS_TAB_INDEX:
                            dispatch(resetAuthorizationsList());
                            break;
                    }
                }}
                labels={[
                    <FormattedMessage id='common.collectorCredentials' />,
                    <FormattedMessage id='common.destinationCredentials' />,
                    <FormattedMessage id='common.adverityAuthorizations' />
                ]}
                disabled={isDataLoading}
            />
            <TabPanel index={COLLECTOR_CREDENTIALS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                {
                    canViewCollectorCredentials ?
                        <CollectorCredentialsList /> :
                        <div className={local.noAccess}>
                            <FormattedMessage id='credentials.noAccessToCollectorCredentialsMessage' />
                        </div>
                }
            </TabPanel>
            <TabPanel index={TARGET_CREDENTIALS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                {
                    canViewTargetCredentials ?
                        <TargetCredentialsList /> :
                        <div className={local.noAccess}>
                            <FormattedMessage id='credentials.noAccessToTargetCredentialsMessage' />
                        </div>
                }
            </TabPanel>
            <TabPanel index={ADVERITY_AUTHORIZATIONS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                {
                    canViewAdverityAuthorizations ?
                        <AuthorizationsList /> :
                        <div className={local.noAccess}>
                            <FormattedMessage id='credentials.noAccessToAdverityAuthorizationsMessage' />
                        </div>
                }
            </TabPanel>
        </Fragment>
    );
};

export default CredentialsListsLayout;
