import React, { ForwardedRef, FunctionComponent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { IntlShape } from 'react-intl';

import { KEY_CODE } from 'modules/common/constants';

import local from './local.module.scss';

export interface SearchInputProps {
    intl: IntlShape;
    onSearch: Function;
    disabled?: boolean;
    placeholderId?: string;
    inputId?: string;
    forwardedRef?: ForwardedRef<HTMLInputElement>;
    width?: number | string;
}

const SearchInput: FunctionComponent<SearchInputProps> = ({
    intl,
    onSearch,
    placeholderId,
    inputId,
    forwardedRef,
    disabled = false,
    width = 440
}) => {
    const searchPlaceholder = intl.formatMessage({ id: placeholderId || 'common.search' });

    const onKeyDown = useCallback((event) => {
        const { keyCode } = event;
        const { value } = event.target as HTMLInputElement;

        if (keyCode === KEY_CODE.ENTER) {
            onSearch(value);
        }
    }, [ onSearch ]);

    return (
        <div
            style={{ width }}
            className={classnames('container-row', 'ls-input')}
        >
            <FontAwesomeIcon icon={faSearch} className={local.searchIcon} />
            <input
                id={inputId}
                disabled={disabled}
                className={local.searchInput}
                placeholder={searchPlaceholder}
                onKeyDown={onKeyDown}
                ref={forwardedRef}
                style={{ width }}
            />
        </div>
    );
};

export default SearchInput;
