import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { clearSubmitErrors } from 'redux-form';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ScrollableDrawer from 'modules/common/components/ScrollableDrawer';
import CollectorCredentialForm from '../CollectorCredentialForm';
import { addCollectorCredential, setReportCollectorCredentialFormDrawerOpen } from '../../actions';
import { getReportCollectorCredentialForm } from '../../selectors';

import local from './local.module.scss';

const CollectorCredentialDrawer: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const {
        isDrawerOpen,
        credentialOperationInProgress: isOperationInProgress,
        collectors: { loadStatus: collectorsLoadStatus },
        manifest: { loadStatus: manifestLoadStatus }
    } = useSelector(getReportCollectorCredentialForm);

    const isDataLoading = [ collectorsLoadStatus, manifestLoadStatus ].includes(LOAD_STATUS.LOADING);

    const setDrawerOpen = (value: boolean) => {
        dispatch(setReportCollectorCredentialFormDrawerOpen(value));
    };

    return (
        <ScrollableDrawer
            isOpen={isDrawerOpen}
            setOpen={setDrawerOpen}
            width={520}
            title={<FormattedMessage id='common.newCredential' />}
            buttons={
                <Fragment>
                    <div className='ls-button'>
                        <button
                            className='btn-flat'
                            disabled={isOperationInProgress}
                            onClick={() => { setDrawerOpen(false); }}
                        >
                            <FormattedMessage id='common.cancel' />
                        </button>
                    </div>
                    <div className='ls-button'>
                        <FormSubmitButton
                            form='reportCollectorCredentialForm'
                            width={130}
                            disabled={isOperationInProgress || isDataLoading}
                            text={<FormattedMessage id='common.save' />}
                            isSubmitting={isOperationInProgress}
                        />
                    </div>
                </Fragment>
            }
        >
            <div className={local.formContainer}>
                <CollectorCredentialForm onSubmit={(values) => {
                    dispatch(clearSubmitErrors('reportCollectorCredentialForm'));

                    return dispatch(addCollectorCredential(values));
                }} />
            </div>
        </ScrollableDrawer>
    );
};

export default CollectorCredentialDrawer;
