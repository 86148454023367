import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import ClientMembershipForm from '../ClientMembershipForm';
import { getIsClientMembershipDialogOpen, getMemberDetails } from '../../selectors';
import { addClientMembership, closeClientMembershipDialog } from '../../actions';

import local from './local.module.scss';

const ClientMembershipDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const isClientMembershipDialogOpen = useSelector(getIsClientMembershipDialogOpen);
    const { id } = useSelector(getMemberDetails);

    const onSubmit = useCallback((formValues) => dispatch(addClientMembership(id, formValues)), [ id ]);
    const onClose = useCallback(() => { dispatch(closeClientMembershipDialog()); }, []);

    return (
        <Dialog
            open={isClientMembershipDialogOpen}
            title={<FormattedMessage id='admin.addClient' />}
            onClose={onClose}
            classes={{ body: local.body }}
        >
            <ClientMembershipForm onSubmit={onSubmit} />
        </Dialog>
    );
};

export default ClientMembershipDialog;
