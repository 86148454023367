import { REDUCER_NAME } from '../constants';

// common
export const getAuthorizationTypesList = (state) => state[REDUCER_NAME].common.authorizationTypes.list;
export const getAuthorizationTypesLoadStatus = (state) => state[REDUCER_NAME].common.authorizationTypes.loadStatus;

// authorization form
export const getAuthorizationTypeOptions = (state) => state[REDUCER_NAME].authorizationForm.options.object;
export const getAuthorizationTypeOptionsLoadStatus = (state) => state[REDUCER_NAME].authorizationForm.options.loadStatus;
export const getAuthorizationFormOperationInProgress = (state) => state[REDUCER_NAME].authorizationForm.authorizationOperationInProgress;

// authorizations list
export const getAuthorizationsListState = (state) => state[REDUCER_NAME].authorizationsList;
