import { formatString } from 'modules/common/utils';
import { CLIENTS_ROUTES } from './constants';
import ClientsListLayout from './components/ClientsListLayout';
import ClientFormLayout from './components/ClientFormLayout';
import ClientDetailsLayout from './components/ClientDetailsLayout';

export default [
    {
        path: CLIENTS_ROUTES.LIST,
        component: ClientsListLayout
    },
    {
        path: CLIENTS_ROUTES.NEW,
        component: ClientFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(CLIENTS_ROUTES.EDIT, ':clientId'),
        component: ClientFormLayout,
        params: {
            isEditMode: true
        }
    },
    {
        path: formatString(CLIENTS_ROUTES.DETAILS, ':clientId'),
        component: ClientDetailsLayout
    }
];
