import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Dialog from 'modules/common/components/Dialog';
import { useAppDispatch } from 'modules/common/hooks';
import { getIsClientMembershipDialogOpen } from '../../selectors';
import { closeClientMembershipDialog, addClientMember } from '../../actions';
import ClientMembershipForm from '../ClientMembershipForm';

interface ClientMembershipDialogProps {
    clientId: string;
    canManage: boolean;
}

const ClientMembershipDialog: FunctionComponent<ClientMembershipDialogProps> = ({ clientId, canManage }) => {
    const dispatch = useAppDispatch();
    const isClientMembershipDialogOpen = useSelector(getIsClientMembershipDialogOpen);
    const onSubmit = useCallback((formValues) => dispatch(addClientMember(clientId, formValues)), [ clientId ]);
    const onClose = useCallback(() => dispatch(closeClientMembershipDialog()), []);

    return (
        <Dialog
            open={isClientMembershipDialogOpen}
            title={<FormattedMessage id='admin.addMember' />}
            onClose={onClose}
        >
            <ClientMembershipForm onSubmit={onSubmit} canManage={canManage} />
        </Dialog>
    );
};

export default ClientMembershipDialog;
