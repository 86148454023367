import { connect } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';

import { withRouteParams } from 'modules/common/hoc';
import { makeGroupFormLayoutProps } from '../../selectors';
import { goToGroupDetailsPage, goToGroupsListPage, addGroup, editGroup } from '../../actions';
import GroupFormLayout from './GroupFormLayout';

const mapDispatchToProps = {
    goToGroupsListPage,
    goToGroupDetailsPage,
    addGroup,
    editGroup,
    clearSubmitErrors
};

export default withRouteParams(connect(makeGroupFormLayoutProps, mapDispatchToProps)(GroupFormLayout));
