import React, { FunctionComponent, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { memberApiDefinitions } from 'modules/service/types';
import { formatString } from 'modules/common/utils';
import { LOAD_STATUS } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { GROUPS_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface GroupsListProps {
    items: Array<memberApiDefinitions['GroupSearchResponseDto']>;
    status: LoadStatus;
    goToGroupDetailsPage: Function;
    sortBy: string;
    sortOrder: SortOrder;
    onSort: Function;
}

const GroupsList: FunctionComponent<GroupsListProps> = ({
    items,
    status,
    onSort,
    sortBy,
    sortOrder,
    goToGroupDetailsPage
}) => {
    const isLoaded = status === LOAD_STATUS.LOADED;

    const tableConfig = useMemo(() => [
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.name' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'name',
            accessor: ({ id, name }) => (
                <a href={`#${formatString(GROUPS_ROUTES.DETAILS, id)}`} className={local.name}>
                    {name}
                </a>
            ),
            width: '60%',
            skeletonWidth: '100%',
            showTooltip: true,
            sortable: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='admin.membersCount' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            accessor: 'memberCount',
            skeletonWidth: '100%',
            sortable: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'updateTimestamp',
            accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
            skeletonWidth: '100%',
            sortable: true
        }
    ], [ sortBy, sortOrder ]);

    const onTableRowClick = useCallback(({ id }) => {
        if (status === LOAD_STATUS.LOADED) {
            goToGroupDetailsPage(id);
        }
    }, [ status ]);

    return (
        <Table
            columns={tableConfig}
            data={items}
            skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
            isLoaded={isLoaded}
            onRowClick={onTableRowClick}
            onSort={onSort}
        />
    );
};

export default GroupsList;
