import React from 'react';
import routes from '../../routes';

import Menu from '../Menu';

import local from './local.module.scss';

const Layout = () => (
    <div className={local.mainContainer}>
        <Menu />
        <div className={local.contentContainer}>{routes()}</div>
    </div>
);

export default Layout;
