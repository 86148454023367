import React, { Fragment, FunctionComponent, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { memberApiDefinitions } from 'modules/service/types';
import { useDidUpdateEffect } from 'modules/common/hooks';
import { LoadStatus } from 'modules/common/interfaces';
import { AUTOMATION_ID, LOAD_STATUS } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import SearchInput from 'modules/common/components/SearchInput';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import GroupsList from '../GroupsList';

import local from './local.module.scss';

interface GroupsListLayoutProps {
    status: LoadStatus;
    canAccess: boolean;
    canManage: boolean;
    items: Array<memberApiDefinitions['GroupSearchResponseDto']>;
    totalCount: number;
    loadData: Function;
    loadMore: Function;
    onSearch: Function;
    goToAddGroupPage: Function;
    resetTable: Function;
}

const GroupsListLayout: FunctionComponent<GroupsListLayoutProps> = ({
    status,
    canAccess,
    canManage,
    items,
    totalCount,
    loadData,
    loadMore,
    onSearch,
    goToAddGroupPage,
    resetTable
}) => {
    useEffect(() => {
        if (canAccess) {
            resetTable();
            loadData();
        }
    }, []);

    useDidUpdateEffect(() => {
        if (status === LOAD_STATUS.REQUIRED) {
            loadData();
        }
    }, [ status ]);

    const newGroupButtonCallback = useCallback(() => { goToAddGroupPage(); }, []);

    if (!canAccess) {
        return (
            <ErrorPage error='403' />
        );
    }

    const isLoaded = status === LOAD_STATUS.LOADED;

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='common.groups' />
            </div>
            <div className={classnames(local.tableControls, 'container-row')}>
                <SearchInput inputId={AUTOMATION_ID.GROUPS_SEARCH} disabled={!isLoaded} onSearch={onSearch} />
                {
                    canManage &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.NEW_GROUP_BTN}
                            disabled={!isLoaded}
                            onClick={newGroupButtonCallback}
                        >
                            <FormattedMessage id='admin.newGroup' />
                        </button>
                    </div>
                }
            </div>
            <div className={local.card}>
                <GroupsList />
                {
                    isLoaded && (totalCount > items.length) &&
                    <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                }
            </div>
        </Fragment>
    );
};

export default GroupsListLayout;
