import { connect } from 'react-redux';

import * as actions from '../../actions';
import { getMembersListLayoutProps } from '../../selectors';
import MembersListLayout from './MembersListLayout';

const mapDispatchToProps = {
    loadData: actions.getMembersList,
    onSearch: actions.membersListSearch,
    resetTable: actions.resetMembersList,
    loadMore: actions.loadMoreMembers,
    openMemberDialog: actions.openMemberDialog
};

export default connect(getMembersListLayoutProps, mapDispatchToProps)(MembersListLayout);
