// instances list
export const APPEND_INSTANCES_LIST_ITEMS = 'instances/APPEND_INSTANCES_LIST_ITEMS';
export const SET_INSTANCES_LIST_ITEMS = 'instances/SET_INSTANCES_LIST_ITEMS';
export const SET_INSTANCES_LIST_PAGE = 'instances/SET_INSTANCES_LIST_PAGE';
export const SET_INSTANCES_LIST_COUNT = 'instances/SET_INSTANCES_LIST_COUNT';
export const SET_INSTANCES_LIST_LOAD_STATUS = 'instances/SET_INSTANCES_LIST_LOAD_STATUS';
export const SET_INSTANCES_LIST_SORT_COLUMN = 'instances/SET_INSTANCES_LIST_SORT_COLUMN';
export const SET_INSTANCES_LIST_SORT_ORDER = 'instances/SET_INSTANCES_LIST_SORT_ORDER';
export const SET_INSTANCES_LIST_SEARCH_TEXT = 'instances/SET_INSTANCES_LIST_SEARCH_TEXT';
export const SET_INSTANCES_LIST_SHOW_ARCHIVED = 'instances/SET_INSTANCES_LIST_SHOW_ARCHIVED';
export const RESET_INSTANCES_LIST = 'instances/RESET_INSTANCES_LIST';

// instance details
export const SET_INSTANCE_DETAILS = 'instances/SET_INSTANCE_DETAILS';
export const SET_INSTANCE_DETAILS_LOAD_STATUS = 'instances/SET_INSTANCE_DETAILS_LOAD_STATUS';
export const RESET_INSTANCE_DETAILS = 'instances/RESET_INSTANCE_DETAILS';
export const SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS = 'instances/SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS';
export const SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA = 'instances/SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA';
export const UNSET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA = 'instances/UNSET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA';
export const OPEN_AD_HOC_RUN_DIALOG = 'instances/OPEN_AD_HOC_RUN_DIALOG';
export const CLOSE_AD_HOC_RUN_DIALOG = 'instances/CLOSE_AD_HOC_RUN_DIALOG';
export const SET_RUN_MODE = 'instances/SET_RUN_MODE';
export const OPEN_INSTANCE_DELETE_DIALOG = 'feeds/OPEN_INSTANCE_DELETE_DIALOG';
export const SET_INSTANCE_DELETE_DIALOG_INSTANCES = 'feeds/SET_INSTANCE_DELETE_DIALOG_INSTANCES';
export const CLOSE_INSTANCE_DELETE_DIALOG = 'feeds/CLOSE_INSTANCE_DELETE_DIALOG';

// instance form
export const SET_INSTANCE_FORM_OPERATION_IN_PROGRESS = 'instances/SET_INSTANCE_FORM_OPERATION_IN_PROGRESS';
export const RESET_INSTANCE_FORM = 'instances/RESET_INSTANCE_FORM';
export const ADD_TRIGGER = 'instances/ADD_TRIGGER';
export const REMOVE_TRIGGER = 'instances/REMOVE_TRIGGER';
export const UPDATE_TRIGGER = 'instances/UPDATE_TRIGGER';
export const INIT_TRIGGERS = 'instances/INIT_TRIGGERS';
export const SET_INSTANCE_FORM_TRIGGERS_INSTANCES = 'instances/SET_INSTANCE_FORM_TRIGGERS_INSTANCES';
export const SET_INSTANCE_FORM_TRIGGERS_INSTANCES_LOAD_STATUS = 'instances/SET_INSTANCE_FORM_TRIGGERS_INSTANCES_LOAD_STATUS';
export const SET_INSTANCE_FORM_TRIGGERS_FEEDS = 'instances/SET_INSTANCE_FORM_TRIGGERS_FEEDS';
export const SET_INSTANCE_FORM_TRIGGERS_FEEDS_LOAD_STATUS = 'instances/SET_INSTANCE_FORM_TRIGGERS_FEEDS_LOAD_STATUS';
export const SET_INSTANCE_FORM_PIPELINES = 'instances/SET_INSTANCE_FORM_PIPELINES';
export const SET_INSTANCE_FORM_PIPELINES_LOAD_STATUS = 'instances/SET_INSTANCE_FORM_PIPELINES_LOAD_STATUS';
export const SET_INSTANCE_FORM_PIPELINE_DETAILS_LOAD_STATUS = 'instances/SET_INSTANCE_FORM_PIPELINE_DETAILS_LOAD_STATUS';
export const SET_INSTANCE_FORM_CONFIGURATION_TEMPLATE = 'instances/SET_INSTANCE_FORM_CONFIGURATION_TEMPLATE';
export const SET_INSTANCE_FORM_FLAGS = 'instances/SET_INSTANCE_FORM_FLAGS';

// common
export const SET_EXPORT_TARGETS_DATA = 'instances/SET_EXPORT_TARGETS_DATA';
export const SET_EXPORT_TARGETS_DATA_LOAD_STATUS = 'instances/SET_EXPORT_TARGETS_DATA_LOAD_STATUS';
