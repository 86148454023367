import React, { Fragment, FunctionComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { LOAD_STATUS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import { removeSyncError } from 'modules/form/actions';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import RefreshIcon from 'modules/common/components/SvgIcons/RefreshIcon';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import { NameFieldRenderer, PlatformsErrorFieldRenderer } from './fieldRenderers';
import ReportPlatform from '../ReportPlatform';
import {
    addReportPlatform,
    goToReportsListPage,
    setReportFormStage,
    validateReportFormSecondStage
} from '../../actions';
import {
    getReportDetailsLoadStatus,
    getReportFormOperationInProgress,
    getReportFormSelectedPlatforms,
    getReportFormTemplate,
    getReportFormTemplateLoadStatus
} from '../../selectors';
import { REPORT_FORM_FIRST_STAGE } from '../../constants';

import local from './local.module.scss';

interface FormSecondStageProps {
    isEditMode: boolean;
    change: InjectedFormProps['change'];
}

const FormSecondStage: FunctionComponent<FormSecondStageProps> = ({ isEditMode, change }) => {
    const dispatch = useAppDispatch();

    const template = useSelector(getReportFormTemplate);
    const templateLoadStatus = useSelector(getReportFormTemplateLoadStatus);
    const selectedPlatforms = useSelector(getReportFormSelectedPlatforms);
    const isOperationInProgress = useSelector(getReportFormOperationInProgress);
    const dataLoadStatus = useSelector(getReportDetailsLoadStatus);

    const addPlatform = (event) => {
        event.preventDefault();

        dispatch(removeSyncError({
            form: 'reportForm',
            field: 'platforms'
        }));

        dispatch(addReportPlatform());
    };

    return (
        <div className={local.card}>
            <div className={classnames(local.titleContainer, 'container-row')}>
                <div className={local.title}>
                    <FormattedMessage id={isEditMode ? 'reports.editReport' : 'reports.newReport'} />
                </div>
                <div className={classnames(local.buttonsContainer, 'container-row')}>
                    <div className='ls-button'>
                        <button
                            disabled={isOperationInProgress}
                            className='btn-flat'
                            onClick={() => {
                                dispatch(goToReportsListPage());
                            }}
                        >
                            <FormattedMessage id='common.cancel' />
                        </button>
                    </div>
                    {
                        !isEditMode &&
                        <div className='ls-button'>
                            <button
                                className='btn-flat'
                                onClick={(event) => {
                                    event.preventDefault();
                                    dispatch(setReportFormStage(REPORT_FORM_FIRST_STAGE));
                                }}
                            >
                                <FormattedMessage id='common.previous' />
                            </button>
                        </div>
                    }
                    <div className='ls-button'>
                        <button
                            disabled={isOperationInProgress}
                            className={local.nextButton}
                            onClick={(event) => {
                                event.preventDefault();

                                return dispatch(validateReportFormSecondStage(isEditMode));
                            }}
                        >
                            {
                                isOperationInProgress ?
                                    <RefreshIcon className={local.submittingIcon} color='#fff' /> :
                                    <FormattedMessage id='common.next' />
                            }
                        </button>
                    </div>
                </div>
            </div>
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.name' />
                </div>
                <Field
                    name='name'
                    component={NameFieldRenderer}
                    disabled={isOperationInProgress || isEditMode}
                    isEditMode={isEditMode}
                    dataLoadStatus={dataLoadStatus}
                />
            </div>
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='reports.template' />
                </div>
                {
                    templateLoadStatus === LOAD_STATUS.LOADED ?
                        <div className={local.nonEditableField}>
                            {template.name}
                        </div> :
                        <SkeletonItem width='160px' height='16px' />
                }
            </div>
            {
                templateLoadStatus === LOAD_STATUS.LOADED &&
                <Fragment>
                    {
                        selectedPlatforms.length ?
                            <div>
                                <span className={local.tableLabel}>
                                    <FormattedMessage id='reports.sourcePlatform' />
                                </span>
                                <span className={local.tableLabel}>
                                    <FormattedMessage id='reports.market' />
                                    <IconWithTooltip className={local.marketTooltip}>
                                        <FormattedMessage id='reports.marketTooltip' />
                                    </IconWithTooltip>
                                </span>
                            </div> : null
                    }
                    {
                        selectedPlatforms.map((platform, index) => (
                            <ReportPlatform
                                key={platform.id}
                                index={index}
                                change={change}
                            />
                        ))
                    }
                </Fragment>
            }
            <Field name='platforms' component={PlatformsErrorFieldRenderer} />
            <div className={classnames('plus-button', 'ls-button')}>
                <button
                    disabled={templateLoadStatus !== LOAD_STATUS.LOADED || !Object.keys(template).length || isOperationInProgress}
                    className='btn-transparent'
                    onClick={addPlatform}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <FormattedMessage id='reports.addPlatform' />
                </button>
            </div>
        </div>
    );
};

export default FormSecondStage;
