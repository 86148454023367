import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { memberApiDefinitions } from 'modules/service/types';
import { useDidUpdateEffect } from 'modules/common/hooks';
import { AUTOMATION_ID, LOAD_STATUS } from 'modules/common/constants';
import { TabPanel, Tabs } from 'modules/common/components/Tabs';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { LoadStatus, RouteComponentProps } from 'modules/common/interfaces';
import ErrorPage from 'modules/common/components/ErrorPage';
import GroupClientsTable from '../GroupClientsTable';
import GroupOwnersTable from '../GroupOwnersTable';
import GroupMembershipsTable from '../GroupMembershipsTable';
import AddMembershipsDialog from '../AddMembershipsDialog';

import local from './local.module.scss';

interface GroupDetailsLayoutProps {
    data: memberApiDefinitions['GroupDetailsResponseDto'];
    isOperationInProgress: boolean;
    canAccess: boolean;
    canManage: boolean;
    status: LoadStatus;
    loadData: Function;
    goToGroupsListPage: Function;
    goToEditGroupPage: Function;
    resetData: Function;
    deleteGroup: Function;
    openAddMembershipsDialog: Function;
}

interface RouteMatchParams {
    groupId: string;
}

const CLIENTS_TAB_INDEX = 0;
const OWNERS_TAB_INDEX = 1;
const MEMBERSHIPS_TAB_INDEX = 2;

const GroupDetailsLayout: FunctionComponent<GroupDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> = ({
    data,
    status,
    loadData,
    canAccess,
    canManage,
    isOperationInProgress,
    goToGroupsListPage,
    goToEditGroupPage,
    resetData,
    deleteGroup,
    openAddMembershipsDialog,
    match
}) => {
    const { groupId } = match.params;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        resetData();
        loadData(groupId);
    }, []);

    useDidUpdateEffect(() => {
        resetData();
    }, [ groupId ]);

    useDidUpdateEffect(() => {
        if (status === LOAD_STATUS.REQUIRED) {
            loadData(groupId);
        }
    }, [ status ]);

    const editButtonCallback = useCallback(() => {
        if (canManage) {
            goToEditGroupPage(groupId);
        }
    }, [ groupId, canManage ]);

    const deleteButtonCallback = useCallback(() => {
        if (canManage) {
            deleteGroup(groupId);
        }
    }, [ groupId, canManage ]);

    const breadcrumbsCallback = useCallback(() => { goToGroupsListPage(); }, []);

    if (!canAccess) {
        return (
            <ErrorPage error='403' />
        );
    }

    const isLoaded = status === LOAD_STATUS.LOADED;

    if (isLoaded && !data.name) {
        return (
            <ErrorPage error='404' />
        );
    }

    const breadcrumbsItems = [
        {
            id: AUTOMATION_ID.ALL_GROUPS_BREADCRUMB,
            label: (<FormattedMessage id='admin.allGroups' />),
            onClick: breadcrumbsCallback
        },
        {
            label: data.name!
        }
    ];

    const areControlsDisabled = !isLoaded || isOperationInProgress;

    return (
        <Fragment>
            <Breadcrumbs
                items={breadcrumbsItems}
                selectedItemIndex={1}
            />
            {data.description && <div className={local.description}>{data.description}</div>}
            <div className={classnames(local.controls, 'container-row')}>
                <Tabs
                    value={selectedTabIndex}
                    onChange={(event, idx) => {
                        setSelectedTabIndex(idx);
                    }}
                    labels={[
                        <FormattedMessage id='common.clients' />,
                        <FormattedMessage id='common.owners' />,
                        <FormattedMessage id='common.memberships' />
                    ]}
                    disabled={areControlsDisabled}
                />
                {
                    canManage &&
                    <div className={classnames(local.tableControls, 'container-row')}>
                        {
                            selectedTabIndex === MEMBERSHIPS_TAB_INDEX &&
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.ADD_GROUP_MEMBERS_BTN}
                                    onClick={() => { openAddMembershipsDialog(); }}
                                    disabled={areControlsDisabled}
                                >
                                    <FormattedMessage id='admin.addMembers' />
                                </button>
                            </div>
                        }
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.EDIT_GROUP_BTN}
                                onClick={editButtonCallback}
                                disabled={areControlsDisabled}
                            >
                                <FormattedMessage id='admin.editGroup' />
                            </button>
                        </div>
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.DELETE_GROUP_BTN}
                                onClick={deleteButtonCallback}
                                className='btn-negative'
                                disabled={areControlsDisabled}
                            >
                                <FormattedMessage id='admin.deleteGroup' />
                            </button>
                        </div>
                    </div>
                }
            </div>
            <TabPanel index={CLIENTS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <GroupClientsTable items={data.clients || []} isLoaded={isLoaded} />
            </TabPanel>
            <TabPanel index={OWNERS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <GroupOwnersTable items={data.owners || []} isLoaded={isLoaded} />
            </TabPanel>
            <TabPanel index={MEMBERSHIPS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <GroupMembershipsTable
                    groupId={groupId}
                    items={data.members || []}
                    canManage={canManage}
                    isLoaded={isLoaded}
                />
            </TabPanel>
            <AddMembershipsDialog />
        </Fragment>
    );
};

export default GroupDetailsLayout;
