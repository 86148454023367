import React, { Fragment } from 'react';

import SkeletonItem from 'modules/common/components/SkeletonItem';

const DEFAULT_HEIGHT = '16px';

const SkeletonTableRows = ({ count, columns }) => {
    const rows: JSX.Element[] = [];

    for (let i = 0; i < count; i++) {
        rows.push(
            <tr key={`skeleton_row_${i}`}>
                {
                    columns.map(({ skeletonWidth, skeletonHeight, cellClassName }, cellIndex) => (
                        <td key={`skeleton_cell_${cellIndex}`} className={cellClassName}>
                            <SkeletonItem width={skeletonWidth} height={skeletonHeight || DEFAULT_HEIGHT} />
                        </td>
                    ))
                }
            </tr>
        );
    }

    return (
        <Fragment>
            {rows}
        </Fragment>
    );
};

export default SkeletonTableRows;
