import { connect } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';

import { withRouteParams } from 'modules/common/hoc';
import { goToFeedsListPage, goToFeedDetailsPage, addFeed, editFeed } from '../../actions';
import { getFeedFormLayoutProps } from '../../selectors';
import FeedFormLayout from './FeedFormLayout';

const mapDispatchToProps = {
    goToFeedsListPage,
    goToFeedDetailsPage,
    addFeed,
    editFeed,
    clearSubmitErrors
};

export default withRouteParams(connect(getFeedFormLayoutProps, mapDispatchToProps)(FeedFormLayout));
