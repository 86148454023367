import React, { Fragment, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import { getUserProfile } from 'modules/auth/selectors';
import { useAppDispatch } from 'modules/common/hooks';
import { AUTOMATION_ID, EVENT_CATEGORY, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import {
    getTargetCredentialDetails,
    getTargetCredentialDetailsLoadStatus,
    getTargetCredentialManifestLoadStatus,
    getTargetCredentialFormOperationInProgress
} from '../../selectors';
import {
    addTargetCredential,
    editTargetCredential,
    goToTargetCredentialDetailsPage,
    goToTargetCredentialsListPage
} from '../../actions';
import TargetCredentialForm from '../TargetCredentialForm';

import local from './local.module.scss';

interface TargetCredentialFormLayoutProps {
    isEditMode: boolean;
}

type RouteMatchParams = Record<'ownerId' | 'credentialId', string>;

const TargetCredentialFormLayout: FunctionComponent<TargetCredentialFormLayoutProps> = ({ isEditMode }) => {
    const dispatch = useAppDispatch();

    const { ownerId, credentialId } = useParams<RouteMatchParams>();

    const data: ingestionApiDefinitions['TargetCredentialDetailResponseDto'] = useSelector(getTargetCredentialDetails);
    const dataLoadStatus = useSelector(getTargetCredentialDetailsLoadStatus);
    const manifestLoadStatus = useSelector(getTargetCredentialManifestLoadStatus);
    const isOperationInProgress = useSelector(getTargetCredentialFormOperationInProgress);

    const { owners } = useSelector(getUserProfile);
    const canAccess = isEditMode ?
        owners.find(({ id }) => id === ownerId)?.permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.MANAGE) :
        owners.some(({ permissions }) => permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.MANAGE));

    if (!canAccess) {
        return (
            <ErrorPage error='403' />
        );
    }

    const onCancelButtonClick = () => {
        if (isEditMode) {
            dispatch(goToTargetCredentialDetailsPage(credentialId, ownerId));

            service.analytics.trackEvent('Cancel target credential edit', EVENT_CATEGORY.CREDENTIALS);
        } else {
            dispatch(goToTargetCredentialsListPage());

            service.analytics.trackEvent('Cancel target credential creation', EVENT_CATEGORY.CREDENTIALS);
        }
    };

    const onFormSubmit = (values) => {
        dispatch(clearSubmitErrors('targetCredentialForm'));

        return isEditMode ?
            dispatch(editTargetCredential(credentialId, values)) :
            dispatch(addTargetCredential(values));
    };

    return (
        <Fragment>
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_CREDENTIALS_BREADCRUMB,
                        label: (<FormattedMessage id='credentials.allCredentials' />),
                        onClick: () => { dispatch(goToTargetCredentialsListPage()); }
                    },
                    {
                        label: isEditMode ? data.name : (<FormattedMessage id='credentials.newDestinationCredential' />)
                    }
                ]}
                selectedItemIndex={1}
            />
            <div className={local.card}>
                <div className={classnames(local.titleContainer, 'container-row')}>
                    <div className={local.title}>
                        <FormattedMessage id={isEditMode ? 'credentials.editDestinationCredential' : 'credentials.newDestinationCredential'} />
                    </div>
                    <div className={classnames(local.buttonsContainer, 'container-row')}>
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.TARGET_CREDENTIAL_FORM_CANCEL_BTN}
                                disabled={isOperationInProgress}
                                className='btn-flat'
                                onClick={onCancelButtonClick}
                            >
                                <FormattedMessage id='common.cancel' />
                            </button>
                        </div>
                        <FormSubmitButton
                            id={AUTOMATION_ID.TARGET_CREDENTIAL_FORM_SAVE_BTN}
                            form='targetCredentialForm'
                            width={130}
                            disabled={isOperationInProgress || [ dataLoadStatus, manifestLoadStatus ].includes(LOAD_STATUS.LOADING)}
                            text={<FormattedMessage id='common.saveChanges' />}
                            isSubmitting={isOperationInProgress}
                        />
                    </div>
                </div>
                <TargetCredentialForm onSubmit={onFormSubmit} isEditMode={isEditMode} />
            </div>
        </Fragment>
    );
};

export default TargetCredentialFormLayout;
