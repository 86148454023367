import { push } from 'redux-first-history';
import { FormErrors, SubmissionError } from 'redux-form';
import { validate as validateEmail } from 'email-validator';

import service from 'modules/service';
import { intl } from 'modules/i18n';
import { memberApiDefinitions } from 'modules/service/types';
import { getRouterPath } from 'modules/common/selectors';
import {
    alert,
    confirm,
    formatString,
    getErrorText,
    getUniqueElements,
    prepareFormErrors,
    showErrorSnackbar
} from 'modules/common/utils';
import {
    EVENT_CATEGORY,
    HTTP_CODE,
    LOAD_STATUS,
    SORT_ORDER
} from 'modules/common/constants';
import { getUserProfile } from 'modules/auth/selectors';
import { loadUserProfile } from 'modules/auth/actions';
import { GROUP_ITEM_TYPE, GROUPS_ROUTES } from '../constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

export const loadGroupsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setGroupsListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const payload = selectors.getGroupsListSearchPayload(getState());
        const list: memberApiDefinitions['ApiPaginatedCollectionContainerDto«GroupSearchResponseDto»'] = await service.api.getGroupsSearchList(payload);

        dispatch(storeActions.appendGroupsListItems(list.objects));
        dispatch(storeActions.setGroupsListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setGroupsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreGroups = () => async (dispatch, getState) => {
    const page = selectors.getGroupsListPage(getState()) + 1;

    dispatch(storeActions.setGroupsListPage(page));
    await dispatch(loadGroupsList());

    service.analytics.trackEvent('Load more user groups', EVENT_CATEGORY.ADMIN);
};

export const groupsListSearch = (text) => (dispatch, getState) => {
    const currentSearchText = selectors.getGroupsListSearchText(getState());

    if (text !== currentSearchText) {
        dispatch(storeActions.setGroupsListPage(0));
        dispatch(storeActions.setGroupsListItems([]));
        dispatch(storeActions.setGroupsListSearchText(text));
        dispatch(storeActions.setGroupsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('User groups list search', EVENT_CATEGORY.ADMIN);
    }
};

export const groupsListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const currentSortColumn = selectors.getGroupsListSortColumn(storeState);
    const currentSortOrder = selectors.getGroupsListSortOrder(storeState);

    if (column === currentSortColumn) {
        const order = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setGroupsListSortOrder(order));
    } else {
        dispatch(storeActions.setGroupsListSortColumn(column));

        if (currentSortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setGroupsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setGroupsListPage(0));
    dispatch(storeActions.setGroupsListItems([]));
    dispatch(storeActions.setGroupsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('User groups list sort', EVENT_CATEGORY.ADMIN);
};

export const goToAddGroupPage = () => (dispatch) => {
    dispatch(push(GROUPS_ROUTES.NEW));
};

export const goToGroupDetailsPage = (id) => (dispatch) => {
    dispatch(push(formatString(GROUPS_ROUTES.DETAILS, id)));
};

export const goToGroupsListPage = () => (dispatch) => {
    dispatch(push(GROUPS_ROUTES.LIST));
};

export const addGroupItem = (type) => (dispatch) => {
    switch (type) {
        case GROUP_ITEM_TYPE.CLIENT:
            dispatch(storeActions.addGroupClient());
            break;
        case GROUP_ITEM_TYPE.OWNER:
            dispatch(storeActions.addGroupOwner());
            break;
    }
};

export const updateGroupItem = (type, data) => (dispatch) => {
    switch (type) {
        case GROUP_ITEM_TYPE.CLIENT:
            dispatch(storeActions.updateGroupClient(data));
            break;
        case GROUP_ITEM_TYPE.OWNER:
            dispatch(storeActions.updateGroupOwner(data));
            break;
    }
};

export const removeGroupItem = (type, data) => (dispatch) => {
    switch (type) {
        case GROUP_ITEM_TYPE.CLIENT:
            dispatch(storeActions.removeGroupClient(data));
            break;
        case GROUP_ITEM_TYPE.OWNER:
            dispatch(storeActions.removeGroupOwner(data));
            break;
    }
};

const validateGroupFormValues = (getState, formValues) => {
    const requiredFields = [ 'name' ];
    const errors: any = {};

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    const storeState = getState();
    const clients = selectors.getGroupFormClients(storeState);
    const owners = selectors.getGroupFormOwners(storeState);

    clients.forEach(({ id, roles }, idx) => {
        if (!id || !roles.length) {
            errors[`client_${idx}`] = 'custom.Required';
        }
    });

    owners.forEach(({ id, roles }, idx) => {
        if (!id || !roles.length) {
            errors[`owner_${idx}`] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

export const addGroup = (formValues) => async (dispatch, getState) => {
    const errors = validateGroupFormValues(getState, formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setGroupFormOperationInProgress(true));

    try {
        const storeState = getState();
        const { name, description } = formValues;
        const clients = selectors.getGroupFormClients(storeState);
        const owners = selectors.getGroupFormOwners(storeState);

        const payload = {
            name,
            description,
            // strip redundant keys
            clients: clients.map(({ id, roles }) => ({ id, roles })),
            owners: owners.map(({ id, roles }) => ({ id, roles }))
        };

        const { id: groupId }: memberApiDefinitions['GroupCreateResponseDto'] = await service.api.addGroup(payload);

        service.analytics.trackEvent('Create user group', EVENT_CATEGORY.ADMIN);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === GROUPS_ROUTES.NEW) {
            dispatch(goToGroupDetailsPage(groupId));
        }
    } catch (error) {
        if (error instanceof SubmissionError) {
            throw error;
        } else {
            const { data, status } = error.response;

            if (status === HTTP_CODE.BAD_REQUEST || status === HTTP_CODE.CONFLICT) {
                showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

                throw new SubmissionError(
                    status === HTTP_CODE.BAD_REQUEST ? prepareFormErrors(data) : { name: 'custom.GroupsAlreadyExists' } as FormErrors
                );
            } else {
                showErrorSnackbar(getErrorText(error));
            }
        }
    } finally {
        dispatch(storeActions.setGroupFormOperationInProgress(false));
    }
};

export const getGroupDetails = (groupId) => async (dispatch) => {
    dispatch(storeActions.setGroupDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details = await service.api.getGroupDetails(groupId);
        dispatch(storeActions.setGroupDetails(details));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setGroupDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const editGroup = (groupId, formValues) => async (dispatch, getState) => {
    const errors = validateGroupFormValues(getState, formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setGroupFormOperationInProgress(true));

    try {
        const storeState = getState();
        const { name, description } = formValues;
        const clients = selectors.getGroupFormClients(storeState);
        const owners = selectors.getGroupFormOwners(storeState);

        await service.api.editGroup(groupId, {
            name,
            description,
            // strip redundant keys
            clients: clients.map(({ id, roles }) => ({ id, roles })),
            owners: owners.map(({ id, roles }) => ({ id, roles }))
        });

        service.analytics.trackEvent('Edit user group', EVENT_CATEGORY.ADMIN);

        const { id: memberId } = getUserProfile(storeState);
        const { members } = selectors.getGroupDetails(storeState);

        if (members.some(({ id }) => id === memberId)) {
            await dispatch(loadUserProfile());
        }

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(GROUPS_ROUTES.EDIT, groupId)) {
            dispatch(goToGroupDetailsPage(groupId));
        }
    } catch (error) {
        if (error instanceof SubmissionError) {
            throw error;
        } else {
            const { data, status } = error.response;

            if (status === HTTP_CODE.BAD_REQUEST || status === HTTP_CODE.CONFLICT) {
                showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

                throw new SubmissionError(
                    status === HTTP_CODE.BAD_REQUEST ? prepareFormErrors(data) : { name: 'custom.GroupsAlreadyExists' } as FormErrors
                );
            } else {
                showErrorSnackbar(getErrorText(error));
            }
        }
    } finally {
        dispatch(storeActions.setGroupFormOperationInProgress(false));
    }
};

export const goToEditGroupPage = (groupId) => (dispatch) => {
    dispatch(push(formatString(GROUPS_ROUTES.EDIT, groupId)));
};

export const deleteGroupMembership = (groupId, memberId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.deleteGroupMembership' }),
        messages: [ intl.formatMessage({ id: 'admin.deleteGroupMembershipConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.delete' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        try {
            dispatch(storeActions.setGroupDetailsOperationInProgress(true));

            await service.api.deleteGroupMembership(groupId, memberId);

            service.analytics.trackEvent('Delete group membership', EVENT_CATEGORY.ADMIN);

            dispatch(storeActions.removeGroupMembership(memberId));

            const { id } = getUserProfile(getState());
            if (id === memberId) {
                await dispatch(loadUserProfile());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setGroupDetailsOperationInProgress(false));
        }
    }
};

export const deleteGroup = (groupId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.deleteGroup' }),
        messages: [ intl.formatMessage({ id: 'admin.deleteGroupConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.delete' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        try {
            dispatch(storeActions.setGroupDetailsOperationInProgress(true));

            await service.api.deleteGroup(groupId);

            service.analytics.trackEvent('Delete group', EVENT_CATEGORY.ADMIN);

            const storeState = getState();
            const { id: memberId } = getUserProfile(storeState);
            const { members } = selectors.getGroupDetails(storeState);

            if (members.some(({ id }) => id === memberId)) {
                await dispatch(loadUserProfile());
            }

            const path = getRouterPath(getState());

            // prevent changing location if user is not on this page anymore
            if (path === formatString(GROUPS_ROUTES.DETAILS, groupId)) {
                dispatch(goToGroupsListPage());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setGroupDetailsOperationInProgress(false));
        }
    }
};

export const addMemberships = (formValues) => async (dispatch, getState) => {
    const usernames = formValues.usernames
        .split(',')
        .map((username) => username.trim());

    if (usernames.length !== getUniqueElements(usernames).length) {
        throw new SubmissionError({ usernames: 'custom.GroupUsernamesDuplicates' });
    }

    if (!usernames.every((username) => validateEmail(username))) {
        throw new SubmissionError({ usernames: 'custom.InvalidGroupUsernames' });
    }

    try {
        dispatch(storeActions.setGroupDetailsOperationInProgress(true));

        const { id } = selectors.getGroupDetails(getState());

        const { notExistingUsernames } = await service.api.addGroupMemberships(id, { usernames });

        dispatch(storeActions.closeAddMembershipsDialog());

        if (notExistingUsernames.length) {
            alert({
                title: intl.formatMessage({ id: 'admin.addGroupMembers' }),
                content: `${intl.formatMessage({ id: 'admin.addGroupMembershipsWarning' })}: ${notExistingUsernames.join(', ')}`
            });
        }

        await dispatch(getGroupDetails(id));
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setGroupDetailsOperationInProgress(false));
    }
};
