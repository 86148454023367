import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import SearchInput from 'modules/common/components/SearchInput';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import { LoadStatus } from 'modules/common/interfaces';
import { memberApiDefinitions } from 'modules/service/types';
import MembersList from '../MembersList';
import MemberDialog from '../MemberDialog';

import local from './local.module.scss';

interface MembersListLayoutProps {
    items: Array<memberApiDefinitions['MemberSearchResponseDto']>;
    status: LoadStatus;
    totalCount: number;
    canView: boolean;
    canManage: boolean;
    loadData: Function;
    onSearch: Function;
    resetTable: Function;
    loadMore: Function;
    isOperationInProgress: boolean;
    openMemberDialog: Function;
}

class MembersListLayout extends Component<MembersListLayoutProps> {
    public componentDidMount() {
        const { loadData, resetTable, canView } = this.props;

        if (canView) {
            resetTable();
            loadData();
        }
    }

    public componentDidUpdate() {
        const { status, canView, loadData } = this.props;

        if (canView && status === LOAD_STATUS.REQUIRED) {
            loadData();
        }
    }

    public render() {
        const {
            status,
            canView,
            canManage,
            totalCount,
            items,
            loadMore,
            onSearch,
            openMemberDialog,
            isOperationInProgress
        } = this.props;

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const isLoaded = status === LOAD_STATUS.LOADED;
        const areControlsDisabled = !isLoaded || isOperationInProgress;

        return (
            <Fragment>
                <div className={local.title}>
                    <FormattedMessage id='common.members' />
                </div>
                <div className={classnames(local.tableControls, 'container-row')}>
                    <SearchInput inputId={AUTOMATION_ID.MEMBERS_SEARCH} disabled={areControlsDisabled} onSearch={onSearch} />
                    {
                        canManage &&
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.NEW_MEMBER_BTN}
                                disabled={areControlsDisabled}
                                onClick={() => { openMemberDialog(); }}
                            >
                                <FormattedMessage id='admin.newMember' />
                            </button>
                        </div>
                    }
                </div>
                <div className={local.card}>
                    <MembersList />
                    {
                        isLoaded && (totalCount > items.length) &&
                        <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                    }
                </div>
                <MemberDialog />
            </Fragment>
        );
    }
}

export default MembersListLayout;
