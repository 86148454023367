import { createSelector } from 'reselect';

import { sort } from 'modules/common/utils';
import { REDUCER_NAME } from '../constants';

export const getSelectedUploadsType = (state) => state[REDUCER_NAME].selectedUploadsType;
export const getUploadInProgress = (state) => state[REDUCER_NAME].uploadInProgress;
export const getUploadSuccess = (state) => state[REDUCER_NAME].uploadSuccess;
export const getUploadErrors = (state) => state[REDUCER_NAME].uploadErrors;
export const getUploadsListState = (state) => state[REDUCER_NAME].uploadsList;

export const getUploadsListItems = createSelector(
    getUploadsListState,
    ({ items, sortBy, sortOrder }) => sort(items, sortBy, sortOrder)
);
