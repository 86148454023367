import { connect } from 'react-redux';

import { getClientsListProps } from '../../selectors';
import { clientsListSort, goToClientDetailsPage, loadMoreClients } from '../../actions';
import ClientsList from './ClientsList';

const mapDispatchToProps = {
    goToClientDetailsPage,
    loadMore: loadMoreClients,
    onSort: clientsListSort
};

export default connect(getClientsListProps, mapDispatchToProps)(ClientsList);
