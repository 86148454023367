import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import { ingestionApiDefinitions } from 'modules/service/types';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { formatString } from 'modules/common/utils';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { FEED_STATE, FEED_VERSION_STATE, FEEDS_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface FeedsListProps {
    items: Array<ingestionApiDefinitions['FeedSearchResponseDto']>;
    status: LoadStatus;
    sortOrder: SortOrder;
    sortBy: string;
    onSort: Function;
    goToFeedDetailsPage: Function;
    clientId: string;
}

class FeedsList extends Component<FeedsListProps> {
    public render() {
        const { items, status, onSort } = this.props;

        return <Table columns={this.getTableConfig()} data={items} onSort={onSort} skeletonRowsCount={LIST_SKELETON_ROWS_COUNT} isLoaded={status === LOAD_STATUS.LOADED} onRowClick={this.onTableRowClick} />;
    }

    private getTableConfig = () => {
        const { sortBy, sortOrder, clientId } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.name' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'name',
                accessor: ({ feedId, feedName, suffix, feedState, feedVersionState }) => {
                    let className = '';

                    if (feedState === FEED_STATE.TEST) {
                        className = local.test;
                    } else {
                        switch (feedVersionState) {
                            case FEED_VERSION_STATE.PENDING:
                                className = local.pending;
                                break;
                            case FEED_VERSION_STATE.SUPERSEDED:
                                className = local.superseded;
                                break;
                            case FEED_VERSION_STATE.VALIDATION_FAILED:
                                className = local.validationFailed;
                                break;
                            case FEED_VERSION_STATE.CANCELLED:
                                className = local.cancelled;
                                break;
                        }
                    }

                    return (
                        <Fragment>
                            <a href={`#${formatString(FEEDS_ROUTES.DETAILS, clientId, feedId)}`} className={local.name}>
                                {`${feedName}_${suffix}`}
                            </a>
                            {
                                feedState === FEED_STATE.TEST &&
                                <span className={classnames(local.label, className)}>
                                    <FormattedMessage id={`feeds.feedState.${feedState}`} />
                                </span>
                            }
                            {
                                feedState !== FEED_STATE.TEST && feedVersionState && feedVersionState !== FEED_VERSION_STATE.VALID &&
                                <span className={classnames(local.label, className)}>
                                    <FormattedMessage id={`feeds.feedVersionState.${feedVersionState}`} />
                                </span>
                            }
                        </Fragment>
                    );
                },
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '74%',
                skeletonCellWidth: 755,
                sortable: true,
                showTooltip: true
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.lastRun' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'lastRunTime',
                accessor: ({ lastRunTimeTimestamp }) => lastRunTimeTimestamp ? moment.utc(lastRunTimeTimestamp).format('DD MMM YYYY [at] HH:mm') : '-',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 161,
                sortable: false,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: 'secondary-column-cell'
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.nextRun' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'nextRunTime',
                accessor: ({ nextRunTimeTimestamp }) => nextRunTimeTimestamp ? moment.utc(nextRunTimeTimestamp).format('DD MMM YYYY [at] HH:mm') : '-',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 161,
                sortable: false,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: 'secondary-column-cell'
            }
        ];
    };

    private onTableRowClick = ({ feedId }) => {
        const { status, goToFeedDetailsPage } = this.props;

        if (status === LOAD_STATUS.LOADED) {
            goToFeedDetailsPage(feedId);
        }
    };
}

export default FeedsList;
