import React, { Fragment, FunctionComponent, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { getUserProfile } from 'modules/auth/selectors';
import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import Input from 'modules/common/components/Input';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import RolesSelectField from 'modules/admin/components/RolesSelectField';
import { ROLE_TYPE } from 'modules/admin/constants';
import { closeClientMembershipDialog } from '../../actions';
import { getClientMembershipOperationInProgress } from '../../selectors';

import local from './local.module.scss';

interface ClientMembershipFormProps {
    canManage: boolean;
}

const UserName = ({ input, meta, disabled }) => {
    const intl = useIntl();

    return (
        <Fragment>
            <div className={local.label}>
                <FormattedMessage id='admin.memberEmail' />
            </div>
            <Input
                inputProperties={input}
                disabled={disabled}
                placeholder={intl.formatMessage({ id: 'admin.usernamePlaceholder' })}
                className={classnames({ 'error-input': Boolean(meta.error) })}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

const ClientMembershipForm: FunctionComponent<ClientMembershipFormProps & InjectedFormProps<{}, ClientMembershipFormProps>> = ({
    change,
    handleSubmit,
    canManage
}) => {
    const { username: currentUsername } = useSelector(getUserProfile);

    useEffect(() => {
        if (!canManage) {
            change('username', currentUsername);
        }
    }, [ canManage ]);

    const dispatch = useAppDispatch();

    const close = useCallback(() => {
        dispatch(closeClientMembershipDialog());
    }, []);

    const username = useReduxFormValue('clientMembershipForm', 'username');
    const roles = useReduxFormValue('clientMembershipForm', 'roles');

    const isOperationInProgress = useSelector(getClientMembershipOperationInProgress);

    return (
        <Fragment>
            <form id='clientMembershipForm' onSubmit={handleSubmit} autoComplete='off'>
                <Field
                    name='username'
                    disabled={isOperationInProgress || !canManage}
                    component={UserName}
                />
                <div className={local.label}>
                    <FormattedMessage id='admin.roles' />
                </div>
                <RolesSelectField
                    fieldName='roles'
                    type={ROLE_TYPE.CLIENT}
                    width={480}
                    disabled={isOperationInProgress}
                />
            </form>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button disabled={isOperationInProgress} className='btn-flat' onClick={close}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <FormSubmitButton
                    form='clientMembershipForm'
                    width={130}
                    disabled={!username || !roles?.length || isOperationInProgress}
                    text={<FormattedMessage id='common.submit' />}
                    isSubmitting={isOperationInProgress}
                />
            </div>
        </Fragment>
    );
};

export default ClientMembershipForm;
