import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import FileUploadArea from 'modules/common/components/FileUploadArea';
import { useAppDispatch } from 'modules/common/hooks';
import { setUploadErrors, setUploadSuccess, uploadFile } from '../../actions';
import { getSelectedUploadsType, getUploadErrors, getUploadInProgress, getUploadSuccess } from '../../selectors';
import { UPLOAD_MAX_SIZE } from '../../constants';
import TemplateDownload from '../TemplateDownload';

import local from './local.module.scss';

const learnMoreLink = 'https://data-platform-community.choreograph.com/hc/en-us/articles/8410485142044-Ingesting-Manually-Uploaded-Stats-from-CSV-Files';

const NewUpload: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const uploadInProgress = useSelector(getUploadInProgress);
    const uploadSuccess = useSelector(getUploadSuccess);
    const uploadErrors = useSelector(getUploadErrors);
    const type = useSelector(getSelectedUploadsType);

    return (
        <div className={local.container}>
            <div className={local.title}>
                <FormattedMessage id='common.upload' />
            </div>
            <div className={local.description}>
                <FormattedMessage id={`uploads.description.${type}`} />
                &nbsp;
                <a href={learnMoreLink}><FormattedMessage id='common.learnMore' /></a>
            </div>
            <TemplateDownload />
            <FileUploadArea
                mimeType='text/csv'
                additionalText={<FormattedMessage id='uploads.limitationsMessage' />}
                onFileAccepted={(file) => {
                    dispatch(uploadFile(file));
                }}
                onFileRejected={(errors) => {
                    dispatch(setUploadSuccess(false));
                    dispatch(setUploadErrors(errors));
                }}
                uploadInProgress={uploadInProgress}
                maxSize={UPLOAD_MAX_SIZE}
                error={uploadSuccess === false}
            />
            {
                uploadSuccess === false && uploadErrors.length > 0 &&
                <div className={local.errorsContainer}>
                    {
                        uploadErrors.map((errorMessage, idx) =>
                            <div key={idx} className={local.error}>
                                <FontAwesomeIcon icon={faCircleExclamation} className={local.errorIcon} />
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
            }
            {
                uploadSuccess === true &&
                <div className={local.uploadSuccessMessage}>
                    <FormattedMessage id='common.uploadSuccessMessage' />
                </div>
            }
        </div>
    );
};

export default NewUpload;
