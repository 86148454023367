import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import * as actions from '../../actions';
import { getFeedFormProps } from '../../selectors';
import { METHOD_SWITCH_OPTIONS_VALUES } from '../../constants';
import { SCHEDULE_FREQUENCY } from 'modules/common/constants';
import FeedForm from './FeedForm';

const mapDispatchToProps = {
    resetForm: actions.resetFeedFormDataAndValues,
    resetData: actions.resetFeedDetails,
    getFeedDataForEditing: actions.getFeedDataForEditing
};

export default reduxForm({
    form: 'feedForm',
    initialValues: {
        method: METHOD_SWITCH_OPTIONS_VALUES.SCHEDULE,
        cron: {
            frequency: SCHEDULE_FREQUENCY.WEEKLY
        },
        collectionOffsetDays: 0,
        createNewDatastream: false
    }
})(injectIntl(connect(getFeedFormProps, mapDispatchToProps)(FeedForm)) as any) as any;
