import { push } from 'redux-first-history';
import { change, SubmissionError } from 'redux-form';

import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import { intl } from 'modules/i18n';
import {
    EVENT_CATEGORY,
    HTTP_CODE,
    INGEST_MODE,
    LOAD_STATUS,
    MANIFEST_FIELD_TYPE,
    SORT_ORDER,
    TARGET_TYPE
} from 'modules/common/constants';
import {
    formatString,
    getErrorText,
    prepareFormErrors,
    prepareFormValues,
    showErrorSnackbar
} from 'modules/common/utils';
import {
    GO_LIVE_TYPE,
    SAVE_MODE,
    SCHEMA_MODE,
    SOURCE_DETAILS_VIEW,
    SOURCE_STATE,
    SOURCE_TRANSIENT_STATE,
    SOURCES_ROUTES
} from 'modules/sources/constants';
import { FEEDS_ROUTES } from 'modules/feeds/constants';
import { getRouterPath } from 'modules/common/selectors';
import { isCorrectDateColumnType } from '../utils';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

const { REACT_APP_ADVERITY_COLLECTOR_ID } = process.env;

export const getSourcesList = () => async (dispatch, getState) => {
    dispatch(storeActions.setSourcesListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const payload = selectors.getSourcesListSearchPayload(getState());
        const list: ingestionApiDefinitions['ApiPaginatedCollectionContainerDtoSourceSearchResponseDto'] = await service.api.getSourcesSearchList(payload);

        dispatch(storeActions.appendSourcesListItems(list.objects));
        dispatch(storeActions.setSourcesListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourcesListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreSources = () => async (dispatch, getState) => {
    const page = selectors.getSourcesListPage(getState()) + 1;

    dispatch(storeActions.setSourcesListPage(page));
    await dispatch(getSourcesList());

    service.analytics.trackEvent('Load more items', EVENT_CATEGORY.SOURCES);
};

export const sourcesListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const currentSortColumn = selectors.getSourcesListSortColumn(storeState);
    const currentSortOrder = selectors.getSourcesListSortOrder(storeState);

    if (column === currentSortColumn) {
        const order = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setSourcesListSortOrder(order));
    } else {
        dispatch(storeActions.setSourcesListSortColumn(column));

        if (currentSortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setSourcesListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setSourcesListPage(0));
    dispatch(storeActions.setSourcesListItems([]));
    dispatch(storeActions.setSourcesListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('List sort', EVENT_CATEGORY.SOURCES);
};

export const sourcesListSearch = (text) => (dispatch, getState) => {
    const currentSearchText = selectors.getSourcesListSearchText(getState());

    if (text !== currentSearchText) {
        dispatch(storeActions.setSourcesListPage(0));
        dispatch(storeActions.setSourcesListItems([]));
        dispatch(storeActions.setSourcesListSearchText(text));
        dispatch(storeActions.setSourcesListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('List search', EVENT_CATEGORY.SOURCES);
    }
};

export const goToSourceDetailsPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(SOURCES_ROUTES.DETAILS, ownerId, id)));
};

export const goToAddSourcePage = (testMode = false) => (dispatch) => {
    dispatch(push(SOURCES_ROUTES.NEW, { testMode }));
};

export const goToEditSourcePage = (ownerId, sourceId, versionId) => (dispatch) => {
    dispatch(push(formatString(SOURCES_ROUTES.EDIT, ownerId, sourceId, versionId)));
};

export const getSourceDetails = (ownerId, sourceId) => async (dispatch) => {
    dispatch(storeActions.setSourceDetailsLoadStatus(LOAD_STATUS.LOADING));

    let sourceDetailsAvailable = true;
    let details;

    try {
        details = await service.api.getCurrentSourceVersionDetails(ownerId, sourceId);
        dispatch(storeActions.setSourceDetails(details));
    } catch (err) {
        sourceDetailsAvailable = false;
        showErrorSnackbar(getErrorText(err));
    }

    if (sourceDetailsAvailable && details.state === SOURCE_TRANSIENT_STATE.VALID) {
        // if source version is in VALID state, check if another version exists
        let additionalDetails;

        try {
            additionalDetails = await service.api.getCurrentSourceVersionDetails(ownerId, sourceId, true);
        } catch (err) {
            if (!err.response || err.response.status !== HTTP_CODE.NOT_FOUND) {
                // ignore the error in case of 404, it means that only active source version exists
                showErrorSnackbar(getErrorText(err));
            }
        }

        if (additionalDetails) {
            // if source is in PROMOTING state, overwrite VALID version data with VALIDATING one
            details.sourceState === SOURCE_STATE.PROMOTING ?
                dispatch(storeActions.setSourceDetails(additionalDetails)) :
                dispatch(storeActions.setAdditionalSourceDetails(additionalDetails));
        }
    }

    dispatch(storeActions.setSourceDetailsLoadStatus(LOAD_STATUS.LOADED));
};

export const goToSourcesListPage = () => (dispatch) => {
    dispatch(push(SOURCES_ROUTES.LIST));
};

const USE_DEDUPLICATE_COLUMNS = process.env.REACT_APP_SOURCE_DEDUPLICATE_COLUMNS === 'yes';

const getSourcePayload = (formValues, storeState, isEditMode, isDraftOnly) => {
    const {
        origin,
        report,
        schema,
        ingestMode,
        dataCollectorConfigurations,
        dateColumn = null,
        primaryKeyColumns = [],
        overwriteByColumns = [],
        deduplicate,
        ownerId,
        bigquery,
        snowflake
    } = formValues;

    const mode = selectors.getSourceFormMode(storeState);
    const manifests = selectors.getSourceFormManifests(storeState);
    const deduplicateColumns = selectors.getSourceFormDeduplicateColumns(storeState)
        .filter(({ field }) => field); // filter out items for which user didn't pick a column

    const commonPayload: any = {
        schemaMode: mode,
        ingestionSettings: {
            ingestMode,
            primaryKeyColumns,
            dateColumn,
            deduplicate,
            overwriteByColumns: ingestMode === INGEST_MODE.INSERT_OR_OVERWRITE_BY_COLUMNS ? overwriteByColumns : [],
            targetTypeSpecificSettings: {
                [TARGET_TYPE.BIGQUERY]: {
                    partitionByDateColumn: bigquery?.partitionByDateColumn || false,
                    clusterColumns: bigquery?.clusterColumns || []
                },
                [TARGET_TYPE.SNOWFLAKE]: {
                    clusteringKey: snowflake?.clusteringKey || []
                }
            }
        },
        dataCollectorConfigurations: []
    };

    if (USE_DEDUPLICATE_COLUMNS && deduplicate && deduplicateColumns.length) {
        commonPayload.ingestionSettings.deduplicateColumns = deduplicateColumns;
    }

    type payloadDto = ingestionApiDefinitions['SourceCreateRequestDto'] | ingestionApiDefinitions['SourceVersionCreateRequestDto'];
    const payload: payloadDto = isEditMode ?
        isDraftOnly ? { ...commonPayload, origin, report, ownerId } : commonPayload :
        { ...commonPayload, origin, report };

    if (mode === SCHEMA_MODE.SIMPLE) {
        const schemaObject = selectors.getSourceFormSchema(storeState);
        payload.schemaFields = schemaObject.fields;
    } else {
        payload.schema = schema;
    }

    dataCollectorConfigurations && dataCollectorConfigurations.forEach((collectorId) => {
        const values = prepareFormValues(manifests[collectorId], formValues[collectorId], false);
        const configurationContext: Array<ingestionApiDefinitions['ConfigurationContext']> = [];

        manifests[collectorId].panels.forEach(({ id: panelId, fields }) => fields.forEach(({
            id: fieldId,
            type,
            nullable,
            defaultValue
        }) => {
            const key = `${panelId}.${fieldId}`;

            if (values[key]) {
                const { value, overridable = false } = values[key];

                // if secret value is absent, set null
                // if non-secret value is absent, set empty string
                // in all other cases, set value cast to string
                const valueToSet = type === MANIFEST_FIELD_TYPE.PASSWORD ?
                    value === undefined ? null as any : String(value) :
                    value === undefined ? '' : String(value);

                configurationContext.push({
                    key,
                    value: valueToSet,
                    overridable
                });
            } else if (!nullable) {
                configurationContext.push({
                    key,
                    value: defaultValue,
                    overridable: true
                });
            }
        }));

        const sourceVersionCollectorConfigurationPayload: any = {
            collectorId,
            configurationContext
        };

        if (formValues[collectorId]?.collectorSpecificConfiguration) {
            const collectorSpecificConfiguration = { ...formValues[collectorId].collectorSpecificConfiguration };

            if (collectorId === REACT_APP_ADVERITY_COLLECTOR_ID) {
                if (collectorSpecificConfiguration.datastreamConfigurationTemplate) {
                    collectorSpecificConfiguration.datastreamConfigurationTemplate = JSON.parse(collectorSpecificConfiguration.datastreamConfigurationTemplate);
                }

                if (collectorSpecificConfiguration.datastreamTypeId) {
                    collectorSpecificConfiguration.datastreamTypeName = selectors.getSourceFormDatastreamTypes(storeState)
                        .find(({ id }) => id === collectorSpecificConfiguration.datastreamTypeId)?.name;
                }
            }

            sourceVersionCollectorConfigurationPayload.collectorSpecificConfiguration = collectorSpecificConfiguration;
        } else {
            sourceVersionCollectorConfigurationPayload.collectorSpecificConfiguration = null;
        }

        payload.dataCollectorConfigurations && payload.dataCollectorConfigurations.push(sourceVersionCollectorConfigurationPayload);
    });

    return payload;
};

export const addSource = (formValues, saveMode, testMode) => async (dispatch, getState) => {
    dispatch(storeActions.setSourceFormOperationInProgress(true));

    try {
        const { ownerId, goLiveType, goLiveDate } = formValues;

        const payload = getSourcePayload(formValues, getState(), false, saveMode === SAVE_MODE.DRAFT);

        const queryParameters: any = {
            mode: saveMode
        };

        if (saveMode === SAVE_MODE.LIVE && goLiveType === GO_LIVE_TYPE.DATE) {
            // hour is required for the API, but not present in the design, so needs to be hard-coded
            queryParameters.goLiveDateTime = goLiveDate.format('yyyyMMDD') + '00';
        }

        const sourceDetails: ingestionApiDefinitions['SourceVersionDetailDto'] = await service.api.addSource(
            ownerId,
            queryParameters,
            { ...payload, testMode }
        );

        service.analytics.trackEvent('Created source', EVENT_CATEGORY.SOURCES);
        if (saveMode === SAVE_MODE.LIVE) {
            service.analytics.trackEvent(
                'Create pending version ',
                EVENT_CATEGORY.SOURCES, goLiveType === GO_LIVE_TYPE.NOW ? 'Upgrade now' : 'Upgrade scheduled'
            );
        } else {
            service.analytics.trackEvent('Create draft version', EVENT_CATEGORY.SOURCES);
        }

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === SOURCES_ROUTES.NEW) {
            dispatch(goToSourceDetailsPage(sourceDetails.sourceId, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setSourceFormOperationInProgress(false));
    }
};

export const editSource = (sourceId, versionId, formValues, saveMode) => async (dispatch, getState) => {
    dispatch(storeActions.setSourceFormOperationInProgress(true));

    try {
        const { ownerId, goLiveType, goLiveDate } = formValues;

        const storeState = getState();

        const isOnlyDraftExisting = selectors.getSourceFormDraftFlag(storeState);
        const payload = getSourcePayload(formValues, storeState, true, isOnlyDraftExisting);

        const queryParameters: any = {
            mode: saveMode
        };

        const details = selectors.getSourceDetails(storeState);
        const additionalDetails = selectors.getAdditionalSourceDetails(storeState);

        const { state, ownerId: savedOwnerId } = versionId === details.sourceVersionId ? details : additionalDetails;
        if (state === SOURCE_TRANSIENT_STATE.DRAFT) {
            queryParameters.draftSourceVersionId = versionId;
        }

        if (saveMode === SAVE_MODE.LIVE && goLiveType === GO_LIVE_TYPE.DATE) {
            // hour is required for the API, but not present in the design, so needs to be hard-coded
            queryParameters.goLiveDateTime = goLiveDate.format('yyyyMMDD') + '00';
        }

        await service.api.editSourceVersion(savedOwnerId, sourceId, queryParameters, payload);

        if (saveMode === SAVE_MODE.LIVE) {
            service.analytics.trackEvent('Create pending version', EVENT_CATEGORY.SOURCES, goLiveType === GO_LIVE_TYPE.NOW ? 'Upgrade now' : 'Upgrade scheduled');
        } else if (state === SOURCE_TRANSIENT_STATE.DRAFT) {
            service.analytics.trackEvent('Edit draft version', EVENT_CATEGORY.SOURCES);
        } else {
            service.analytics.trackEvent('Create draft version', EVENT_CATEGORY.SOURCES);
        }

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(SOURCES_ROUTES.EDIT, savedOwnerId, sourceId, versionId)) {
            dispatch(goToSourceDetailsPage(sourceId, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setSourceFormOperationInProgress(false));
    }
};

export const getCollectorsList = () => async (dispatch) => {
    dispatch(storeActions.setSourceFormCollectorsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response: ingestionApiDefinitions['ApiCollectionContainerDtoCollectorSummaryResponseDto'] = await service.api.getCollectorsList();
        dispatch(storeActions.setSourceFormCollectors(
            response.objects?.sort(({ description: name1 }, { description: name2 }) => (name1 > name2 ? 1 : -1)))
        );
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceFormCollectorsLoadStatus(LOAD_STATUS.LOADED));
    }
};

const updateManifests = (collectorIds) => async (dispatch, getState) => {
    dispatch(storeActions.setSourceFormManifestsLoadStatus(LOAD_STATUS.LOADING));

    try {
        let manifestsChanged = false;
        const manifests = selectors.getSourceFormManifests(getState());
        const updatedManifests = {};

        for (const collectorId in manifests) {
            if (manifests.hasOwnProperty(collectorId)) {
                if (!collectorIds.includes(collectorId)) {
                    manifestsChanged = true;
                }
            }
        }

        for (const id of collectorIds) {
            if (manifests[id]) {
                updatedManifests[id] = manifests[id];
            } else {
                updatedManifests[id] = await service.api.getCollectorConfigurationManifest(id);
                manifestsChanged = true;
            }
        }

        if (manifestsChanged) {
            dispatch(storeActions.setSourceFormManifests(updatedManifests));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceFormManifestsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const setSourceCollectors = () => async (dispatch, getState) => {
    const { dataCollectorConfigurations } = selectors.getSourceFormValues(getState());

    dataCollectorConfigurations && await dispatch(updateManifests(dataCollectorConfigurations));
};

export const setMode = (mode) => (dispatch, getState) => {
    const storeState = getState();

    if (mode === SCHEMA_MODE.ADVANCED) {
        dispatch(storeActions.setSourceFormSchemaBackup(selectors.getSourceFormSchema(storeState)));
        dispatch(storeActions.setSourceFormSchema({}));
    } else {
        dispatch(storeActions.setSourceFormSchema(selectors.getSourceFormSchemaBackup(storeState)));
    }

    dispatch(storeActions.setSourceFormMode(mode));
};

export const deleteSourceVersion = (ownerId, sourceId, versionId) => async (dispatch) => {
    dispatch(storeActions.setSourceDetailsOperationInProgress(true));

    try {
        await service.api.deleteSourceVersion(ownerId, sourceId, versionId);

        service.analytics.trackEvent('Delete source version', EVENT_CATEGORY.SOURCES);

        dispatch(goToSourcesListPage());
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceDetailsOperationInProgress(false));
    }
};

export const getSourceVersionDataForEditing = (ownerId, sourceId, versionId) => async (dispatch) => {
    dispatch(storeActions.setSourceDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        let details: ingestionApiDefinitions['SourceVersionDetailDto'] = await service.api.getCurrentSourceVersionDetails(ownerId, sourceId);
        if (details.sourceVersionId === versionId) {
            if (details.state === SOURCE_TRANSIENT_STATE.DRAFT) {
                // only source draft exists
                dispatch(storeActions.setSourceFormDraftFlag(true));
            }
        } else {
            details = await service.api.getSourceVersionDetails(ownerId, sourceId, versionId);
        }

        dispatch(storeActions.setSourceDetails(details));
        await dispatch(getCollectorsList());

        if (details.dataCollectorConfigurations) {
            const collectorIds = details.dataCollectorConfigurations.map(({ collectorId }) => collectorId);
            collectorIds && await dispatch(updateManifests(collectorIds));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const deleteSource = (ownerId, sourceId) => async (dispatch) => {
    dispatch(storeActions.setSourceDetailsOperationInProgress(true));

    try {
        await service.api.deleteSource(ownerId, sourceId);

        service.analytics.trackEvent('Delete source', EVENT_CATEGORY.SOURCES);

        dispatch(goToSourcesListPage());
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceDetailsOperationInProgress(false));
    }
};

export const archiveSource = (ownerId, sourceId) => async (dispatch) => {
    dispatch(storeActions.setSourceDetailsOperationInProgress(true));

    try {
        await service.api.archiveSource(ownerId, sourceId);

        service.analytics.trackEvent('Archive source', EVENT_CATEGORY.SOURCES);

        await dispatch(getSourceDetails(ownerId, sourceId));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceDetailsOperationInProgress(false));
    }
};

export const cloneSource = (testMode = false) => (dispatch, getState) => {
    const storeState = getState();

    const view = selectors.getSourceDetailsView(storeState);
    const dataToClone = view === SOURCE_DETAILS_VIEW.MAIN ?
        selectors.getSourceDetails(storeState) :
        selectors.getAdditionalSourceDetails(storeState);

    delete dataToClone.report;
    delete dataToClone.origin;
    delete dataToClone.ownerId;

    if (dataToClone.dataCollectorConfigurations?.length) {
        dataToClone.dataCollectorConfigurations.forEach(
            ({ configurationContext }) => configurationContext.forEach((contextItem) => {
                if (contextItem.isSecret) {
                    delete contextItem.value;
                }
            })
        );
    }

    dispatch(push(SOURCES_ROUTES.NEW, { dataToClone, testMode }));
};

export const getManifests = (collectorIds) => async (dispatch) => {
    await dispatch(getCollectorsList());
    collectorIds?.length && await dispatch(updateManifests(collectorIds));
};

export const createFeedBasedOnCurrentSource = () => (dispatch, getState) => {
    const { sourceId } = selectors.getSourceDetails(getState());

    dispatch(push(FEEDS_ROUTES.NEW, { sourceId }));
};

export const promoteTestSource = (ownerId, sourceId) => async (dispatch) => {
    dispatch(storeActions.setSourceDetailsOperationInProgress(true));

    try {
        await service.api.promoteTestSource(ownerId, sourceId);

        service.analytics.trackEvent('Promote test source to production mode', EVENT_CATEGORY.SOURCES);

        // clear additional source details in case if test source had any source version other than valid
        dispatch(storeActions.setAdditionalSourceDetails({}));
        await dispatch(getSourceDetails(ownerId, sourceId));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceDetailsOperationInProgress(false));
    }
};

export const applySourcesListFilters = (states, ownerIds) => (dispatch) => {
    dispatch(storeActions.setSourcesListFiltersStates(states));
    dispatch(storeActions.setSourcesListFiltersOwnerIds(ownerIds));

    dispatch(storeActions.setSourcesListPage(0));
    dispatch(storeActions.setSourcesListItems([]));
    dispatch(storeActions.setSourcesListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('Apply list filters', EVENT_CATEGORY.SOURCES);
};

export const populateSourceFormValues = (data: ingestionApiDefinitions['SourceVersionDetailDto']) => (dispatch) => {
    const formName = 'sourceForm';
    const {
        ingestionSettings: {
            ingestMode,
            dateColumn,
            primaryKeyColumns,
            deduplicate,
            deduplicateColumns,
            overwriteByColumns,
            targetTypeSpecificSettings: {
                [TARGET_TYPE.BIGQUERY]: {
                    partitionByDateColumn,
                    clusterColumns
                },
                [TARGET_TYPE.SNOWFLAKE]: {
                    clusteringKey
                }
            }
        },
        schemaMode,
        schema,
        schemaFields,
        dataCollectorConfigurations,
        origin,
        report,
        ownerId
    } = data;

    dispatch(change(formName, 'origin', origin));
    dispatch(change(formName, 'report', report));
    dispatch(change(formName, 'ownerId', ownerId));

    dispatch(setMode(schemaMode));

    const isInSimpleMode = schemaMode === SCHEMA_MODE.SIMPLE;
    const fields = isInSimpleMode ?
        schemaFields :
        JSON.parse(schema as string).fields.map(({ name, type }) => ({ name, type }));

    dispatch(storeActions.setSourceFormSchema({ fields }));

    if (!isInSimpleMode) {
        dispatch(change(formName, 'schema', schema));
    }

    dispatch(change(formName, 'ingestMode', ingestMode));
    dispatch(change(formName, 'primaryKeyColumns', primaryKeyColumns));
    dispatch(change(formName, 'deduplicate', deduplicate));
    dispatch(change(formName, 'bigquery.clusterColumns', clusterColumns));
    dispatch(change(formName, 'bigquery.partitionByDateColumn', partitionByDateColumn));
    dispatch(change(formName, 'snowflake.clusteringKey', clusteringKey));

    overwriteByColumns?.length && dispatch(change(formName, 'overwriteByColumns', overwriteByColumns));

    if (dateColumn) {
        const dateColumnField = fields.find(({ name }) => name === dateColumn);

        if (dateColumnField && isCorrectDateColumnType(dateColumnField.type, schemaMode)) {
            dispatch(change(formName, 'dateColumn', dateColumn));
        }
    }

    if (dataCollectorConfigurations) {
        dispatch(change(
            formName,
            'dataCollectorConfigurations',
            dataCollectorConfigurations.map(({ collectorId }) => collectorId)
        ));
    }

    if (deduplicate && deduplicateColumns?.length) {
        dispatch(storeActions.setDeduplicateColumns(deduplicateColumns));
    }
};

export const getDatastreamTypes = () => async (dispatch) => {
    dispatch(storeActions.setSourceFormDatastreamTypesLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response: ingestionApiDefinitions['ApiCollectionContainerDtoDatastreamTypeDto'] = await service.api.getAdverityDatastreamTypes();
        dispatch(storeActions.setSourceFormDatastreamTypes(
            response.objects?.filter(({ isDeprecated }) => !isDeprecated)
                .sort(({ name: name1 }, { name: name2 }) => (name1! > name2! ? 1 : -1)))
        );
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setSourceFormDatastreamTypesLoadStatus(LOAD_STATUS.LOADED));
    }
};
