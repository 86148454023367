import React, { Fragment, FunctionComponent, ReactNode, useCallback } from 'react';
import { ThunkAction } from 'redux-thunk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch } from '../../hooks';
import { SortOrder } from '../../interfaces';
import { SORT_ORDER } from '../../constants';

import local from './local.module.scss';

interface SortableHeaderProps {
    id: string;
    title: ReactNode;
    sortBy: string;
    sortOrder: SortOrder;
    sortCallback: (sortBy: string) => ThunkAction<void, any, any, any>;
    className: string;
}

const SortableHeader: FunctionComponent<SortableHeaderProps> = ({
    id,
    title,
    sortBy,
    sortOrder,
    sortCallback,
    className
}) => {
    const dispatch = useAppDispatch();
    const onSort = useCallback(() => { dispatch(sortCallback(id)); }, [ id ]);

    return (
        <div className={className} onClick={onSort}>
            {title}
            {
                sortBy === id ?
                    <FontAwesomeIcon
                        icon={sortOrder === SORT_ORDER.ASC ? faArrowUp : faArrowDown}
                        className={local.sortOrderIcon}
                    /> :
                    <Fragment>
                        <FontAwesomeIcon icon={faArrowUp} className={local.mutedIcon} />
                        <FontAwesomeIcon icon={faArrowDown} className={local.mutedIcon} />
                    </Fragment>
            }
        </div>
    );
};

export default SortableHeader;
