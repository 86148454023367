import clientsRoutes from './clients/routes';
import ownersRoutes from './owners/routes';
import membersRoutes from './members/routes';
import groupsRoutes from './groups/routes';

export default [
    ...clientsRoutes,
    ...ownersRoutes,
    ...membersRoutes,
    ...groupsRoutes
];
