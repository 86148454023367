import React, { Fragment, FunctionComponent } from 'react';
import classnames from 'classnames';
import { filesize } from 'filesize';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Drawer from 'modules/common/components/Drawer';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import { CATALOG_ITEM_TYPE } from '../../constants';
import { getTableInfo } from '../../selectors';

import local from './local.module.scss';

interface TableDataProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
}

const TableData: FunctionComponent<TableDataProps> = ({ isOpen, setOpen }) => {
    const {
        data: {
            type,
            query,
            tableId,
            numRows,
            numBytes,
            location,
            creationTime,
            baseTableInfo,
            expirationTime,
            lastModifiedTime,
            partitioned
        }
    } = useSelector(getTableInfo);

    return (
        <Drawer
            isOpen={isOpen}
            setOpen={setOpen}
            title={
                <TextWithTooltip>{tableId}</TextWithTooltip>
            }
            buttons={
                <div className='ls-button'>
                    <button className={local.closeButton} onClick={() => { setOpen(false); }}>
                        <FormattedMessage id='common.done' />
                    </button>
                </div>
            }
        >
            <div className={classnames(local.basicInfo, 'container-row')}>
                {
                    Number.isInteger(numRows) &&
                    <div className={classnames(local.column, 'container-column')}>
                        <div className={local.propertyName}>
                            <FormattedMessage id='common.rows' />
                        </div>
                        <div className={local.propertyValue}>
                            {numRows}
                        </div>
                    </div>
                }
                {
                    Number.isInteger(numBytes) &&
                    <div className={classnames(local.column, 'container-column')}>
                        <div className={local.propertyName}>
                            <FormattedMessage id='common.size' />
                        </div>
                        <div className={local.propertyValue}>
                            {filesize(numBytes, { base: 2, standard: 'jedec', output: 'string' })}
                        </div>
                    </div>
                }
                {
                    location &&
                    <div className={classnames(local.column, 'container-column')}>
                        <div className={local.propertyName}>
                            <FormattedMessage id='common.location' />
                        </div>
                        <div className={local.propertyValue}>
                            {location}
                        </div>
                    </div>
                }
            </div>
            <div className={local.mainInfo}>
                <div className='container-row'>
                    <div className={local.propertyName}>
                        <FormattedMessage id='common.lastUpdated' />:
                    </div>
                    <div className={local.propertyValue}>
                        {moment(lastModifiedTime).format('DD MMM YYYY')}
                    </div>
                </div>
                <div className='container-row'>
                    <div className={local.propertyName}>
                        <FormattedMessage id='common.created' />:
                    </div>
                    <div className={local.propertyValue}>
                        {moment(creationTime).format('DD MMM YYYY')}
                    </div>
                </div>
                {
                    expirationTime &&
                    <div className='container-row'>
                        <div className={local.propertyName}>
                            <FormattedMessage id='common.expirationDate' />:
                        </div>
                        <div className={local.propertyValue}>
                            {moment(expirationTime).format('DD MMM YYYY')}
                        </div>
                    </div>
                }
            </div>
            <div className={local.partitionInfo}>
                <div className='container-row'>
                    <div className={local.propertyName}>
                        <FormattedMessage id='common.type' />:
                    </div>
                    <div className={local.propertyValue}>
                        <FormattedMessage id={`dataCatalog.entityType.${type}`} defaultMessage={type} />
                    </div>
                </div>
                {
                    [ CATALOG_ITEM_TYPE.TABLE, CATALOG_ITEM_TYPE.SNAPSHOT, CATALOG_ITEM_TYPE.EXTERNAL ].includes(type) &&
                    <Fragment>
                        <div className='container-row'>
                            <div className={local.propertyName}>
                                <FormattedMessage id='dataCatalog.partitioned' />:
                            </div>
                            <div className={local.propertyValue}>
                                <FormattedMessage id={partitioned ? 'common.yes' : 'common.no'} />
                            </div>
                        </div>
                        {
                            partitioned &&
                            <Fragment>
                                <div className='container-row'>
                                    <div className={local.propertyName}>
                                        <FormattedMessage id='dataCatalog.partitionedBy' />:
                                    </div>
                                    <div className={local.propertyValue}>
                                        {partitioned.type}
                                    </div>
                                </div>
                                <div className='container-row'>
                                    <div className={local.propertyName}>
                                        <FormattedMessage id='dataCatalog.partitionedOnField' />:
                                    </div>
                                    <div className={local.propertyValue}>
                                        {partitioned.field}
                                    </div>
                                </div>
                                <div className='container-row'>
                                    <div className={local.propertyName}>
                                        <FormattedMessage id='dataCatalog.partitionExpiry' />:
                                    </div>
                                    <div className={local.propertyValue}>
                                        {
                                            partitioned.expirationMs ?
                                                `${Math.floor(partitioned.expirationMs / 1000)} s` : // TODO update when we'll know what would be the range for this value
                                                '-'
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                }
            </div>
            {
                baseTableInfo &&
                <div className={local.snapshotInfo}>
                    <div className='container-row'>
                        <div className={local.propertyName}>
                            <FormattedMessage id='dataCatalog.baseTableId' />:
                        </div>
                        <div className={local.propertyValue}>
                            <TextWithTooltip>{baseTableInfo.tableId}</TextWithTooltip>
                        </div>
                    </div>
                    <div className='container-row'>
                        <div className={local.propertyName}>
                            <FormattedMessage id='dataCatalog.snapshotTime' />:
                        </div>
                        <div className={local.propertyValue}>
                            {moment(baseTableInfo.snapshotTime).format('DD MMM YYYY [at] HH:mm')}
                        </div>
                    </div>
                </div>
            }
            {
                query &&
                <div className={local.query}>
                    <span><FormattedMessage id='common.query' />:</span>
                    &nbsp;{query}
                </div>
            }
        </Drawer>
    );
};

export default TableData;
