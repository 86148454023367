export const REDUCER_NAME = 'collectorCredentials';
export const COLLECTOR_CREDENTIALS_LIST_PAGE_SIZE = 50;

export const COLLECTOR_CREDENTIALS_LIST_SORT_ATTRIBUTES_MAP = {
    name: 'NAME',
    collector: 'COLLECTOR',
    lastUpdated: 'LAST_UPDATE',
    ownerName: 'OWNER'
};

export const BASE_PATH = '/credentials';

export const COLLECTOR_CREDENTIALS_ROUTES = {
    DETAILS: `${BASE_PATH}/view/{0}/{1}`,
    NEW: `${BASE_PATH}/add`,
    EDIT: `${BASE_PATH}/edit/{0}/{1}`
};
