import React, { Fragment, FunctionComponent, useState, useCallback } from 'react';
import classnames from 'classnames';
import { Chip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import { CLIENT_ROLES, OWNER_ROLES } from 'modules/common/constants';
import { CLIENT_ROLE_GROUPS, OWNER_ROLE_GROUPS, ROLE_TYPE } from '../../constants';
import RolesSelectMenu from '../RolesSelectMenu';

import local from './local.module.scss';

interface RolesChipsProps {
    onSelect?: Function;
    onRemove?: Function;
    type: typeof ROLE_TYPE[keyof typeof ROLE_TYPE];
    selectedRoles: string[];
    disabled?: boolean;
    editable?: boolean;
}

const RolesChips: FunctionComponent<RolesChipsProps> = ({ onSelect, onRemove, type, selectedRoles, disabled, editable }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleClick = useCallback((event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    }, [ setAnchorEl, setOpen, disabled ]);

    const handleSelect = useCallback((...args) => {
        setOpen(false);
        onSelect && onSelect(...args);
    }, [ onSelect, setOpen ]);

    const value = selectedRoles || [];
    const itemGroups = type === ROLE_TYPE.CLIENT ? CLIENT_ROLE_GROUPS : OWNER_ROLE_GROUPS;

    return (
        <Fragment>
            <div>
                {
                    value.map((role) =>
                        <Chip
                            classes={{
                                root: local.chipRoot,
                                disabled: local.chipDisabled
                            }}
                            key={role}
                            label={<FormattedMessage id={`admin.roles.${role}`} defaultMessage={role} />}
                            size='small'
                            onDelete={
                                editable ?
                                    () => {
                                        if (!disabled && onRemove) {
                                            onRemove(role);
                                        }
                                    } : undefined
                            }
                            disabled={disabled}
                            deleteIcon={
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={local.deleteIcon}
                                />
                            }
                        />
                    )
                }
                {
                    editable && selectedRoles.filter((role) =>
                        type === ROLE_TYPE.CLIENT ? Boolean(CLIENT_ROLES[role]) : Boolean(OWNER_ROLES[role])
                    ).length < itemGroups.length &&
                    <FontAwesomeIcon
                        icon={faPlus}
                        className={classnames(local.plusIcon, { [local.disabled]: disabled })}
                        onClick={handleClick}
                    />
                }
            </div>
            <RolesSelectMenu
                open={open}
                anchorEl={anchorEl}
                disabled={disabled}
                itemGroups={itemGroups}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                selectedItems={value}
                onClose={() => { setOpen(false); }}
                onSelect={handleSelect}
            />
        </Fragment>
    );
};

export default RolesChips;
