import { createSelector } from 'reselect';

import { REDUCER_NAME } from '../constants';

export const getTokens = (state) => state[REDUCER_NAME].tokens;
export const isUserDataLoaded = (state) => state[REDUCER_NAME].isUserDataLoaded;
export const isUserAccessAllowed = (state) => state[REDUCER_NAME].isUserAccessAllowed;
export const getClientsList = (state) => state[REDUCER_NAME].clientsList;
export const getSelectedClientId = (state) => state[REDUCER_NAME].selectedClientId;
export const getUserProfile = (state) => state[REDUCER_NAME].userProfile;
export const getUserName = (state) => state[REDUCER_NAME].userProfile.username;
export const getClientIdToCheckOnLoad = (state) => state[REDUCER_NAME].clientIdToCheckOnLoad;

export const getPermissionsForSelectedClient = createSelector(
    getUserProfile,
    getSelectedClientId,
    ({ clients }, selectedClientId) => {
        const client = clients.find(({ id }) => id === selectedClientId);

        return client ? client.permissions : [];
    }
);
