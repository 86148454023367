import React, { Fragment, FunctionComponent, memo } from 'react';

import SkeletonItem from '../SkeletonItem';

import local from './local.module.scss';

interface SkeletonSectionLoaderIndicatorProps {
    blocksCount?: number;
    key?: string;
}

const SkeletonSectionLoaderIndicator: FunctionComponent<SkeletonSectionLoaderIndicatorProps> = ({ blocksCount = 2, key = '' }) => {
    const blockContent = (
        <Fragment>
            <div className={local.item}>
                <SkeletonItem width='160px' height='20px' />
            </div>
            <div className={local.item}>
                <SkeletonItem width='320px' height='16px' />
            </div>
        </Fragment>
    );

    return (
        <div className={local.root}>
            {[ ...Array(blocksCount) ].map((item, index) => (
                <div key={`${key}_skeleton_section_loader_block_${index}`} className={local.block}>
                    {blockContent}
                </div>
            ))}
        </div>
    );
};

export default memo(SkeletonSectionLoaderIndicator);
