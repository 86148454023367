import React from 'react';
import { useLocation } from 'react-router-dom';

export const withLocationState = (WrappedComponent) => (props) => {
    const { state } = useLocation();

    return (
        <WrappedComponent
            {...props}
            locationState={state || undefined}
        />
    );
};
