import { createSelector } from 'reselect';

import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { SelectInputItem } from 'modules/common/components/SelectInput';
import { REDUCER_NAME, INSTANCES_LIST_SORT_ATTRIBUTES_MAP, TRIGGER_TYPE } from '../constants';

// instances list
export const getInstancesListItems = (state) => state[REDUCER_NAME].instancesList.items;
export const getInstancesListPage = (state) => state[REDUCER_NAME].instancesList.page;
export const getInstancesListPageSize = (state) => state[REDUCER_NAME].instancesList.pageSize;
export const getInstancesListTotalCount = (state) => state[REDUCER_NAME].instancesList.totalCount;
export const getInstancesListLoadStatus = (state) => state[REDUCER_NAME].instancesList.loadStatus;
export const getInstancesListSortColumn = (state) => state[REDUCER_NAME].instancesList.sortBy;
export const getInstancesListSortOrder = (state) => state[REDUCER_NAME].instancesList.sortOrder;
export const getInstancesListSearchText = (state) => state[REDUCER_NAME].instancesList.searchBy;
export const getInstancesListShowArchived = (state) => state[REDUCER_NAME].instancesList.showArchived;

// instance details
export const getInstanceDetails = (state) => state[REDUCER_NAME].instanceDetails.data;
export const getInstanceDetailsLoadStatus = (state) => state[REDUCER_NAME].instanceDetails.loadStatus;
export const getInstanceDetailsOperationInProgress = (state) => state[REDUCER_NAME].instanceDetails.instanceOperationInProgress;
export const getInstanceDetailsOperationInProgressReporterData = (state) => state[REDUCER_NAME].instanceDetails.instanceOperationInProgressReporterData;
export const getIsAdHocRunDialogOpen = (state) => state[REDUCER_NAME].instanceDetails.isAdHocRunDialogOpen;
export const getInstanceRunMode = (state) => state[REDUCER_NAME].instanceDetails.runMode;
export const getIsInstanceDeleteDialogOpen = (state) => state[REDUCER_NAME].instanceDetails.isInstanceDeleteDialogOpen;
export const getInstanceDependentInstances = (state) => state[REDUCER_NAME].instanceDetails.dependentInstances;

// instance form
export const getInstanceFormOperationInProgress = (state) => state[REDUCER_NAME].instanceForm.instanceOperationInProgress;
export const getInstanceFormTriggers = (state) => state[REDUCER_NAME].instanceForm.triggers;
export const getInstanceFormTriggersData = (state) => state[REDUCER_NAME].instanceForm.triggersData;
export const getInstanceFormPipelines = (state) => state[REDUCER_NAME].instanceForm.pipelines.list;
export const getInstanceFormPipelinesLoadStatus = (state) => state[REDUCER_NAME].instanceForm.pipelines.loadStatus;
export const getInstanceFormConfigurationTemplate = (state) => state[REDUCER_NAME].instanceForm.pipelineDetails.configurationTemplate;
export const getInstanceFormTriggerConfigurationTemplate = (state) => state[REDUCER_NAME].instanceForm.pipelineDetails.filteredConfigurationTemplate;
export const getInstanceFormFlags = (state) => state[REDUCER_NAME].instanceForm.pipelineDetails.flags;
export const getInstanceFormPipelineDetailsLoadStatus = (state) => state[REDUCER_NAME].instanceForm.pipelineDetails.loadStatus;

// common
export const getExportTargetsData = (state) => state[REDUCER_NAME].common.exportTargetsData.list;
export const getExportTargetsDataLoadStatus = (state) => state[REDUCER_NAME].common.exportTargetsData.loadStatus;

export const getInstancesListSearchPayload = createSelector(
    getInstancesListPage,
    getInstancesListPageSize,
    getInstancesListSortColumn,
    getInstancesListSortOrder,
    getInstancesListSearchText,
    getInstancesListShowArchived,
    (page, pageSize, sortBy, ordering, searchString, showArchived) => ({
        orderBy: [
            {
                attribute: INSTANCES_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering
            }
        ],
        searchString,
        page,
        pageSize,
        excludingArchived: !showArchived
    })
);

export const getInstancesListLayoutProps = createSelector(
    getUserProfile,
    getSelectedClientId,
    getInstancesListItems,
    getInstancesListLoadStatus,
    getInstancesListTotalCount,
    getInstancesListShowArchived,
    ({ clients }, clientId, items, status, totalCount, showArchived) => {
        const client = clients.find(({ id }) => id === clientId);
        const permissions = client?.permissions || [];

        return {
            items,
            status,
            totalCount,
            showArchived,
            canView: permissions.includes(PERMISSIONS.INSTANCES.VIEW),
            canManage: permissions.includes(PERMISSIONS.INSTANCES.MANAGE)
        };
    }
);

export const getInstancesListProps = createSelector(
    getInstancesListItems,
    getInstancesListLoadStatus,
    getInstancesListSortOrder,
    getInstancesListSortColumn,
    getSelectedClientId,
    (items, status, sortOrder, sortBy, clientId) => ({
        items,
        status,
        sortOrder,
        sortBy,
        clientId
    })
);

export const getInstanceDetailsProps = createSelector(
    getUserProfile,
    getInstanceDetailsLoadStatus,
    getInstanceDetails,
    getInstanceDetailsOperationInProgress,
    getInstanceDetailsOperationInProgressReporterData,
    ({ clients, owners }, loadStatus, data, isOperationInProgress, operationInProgressReporterData) => ({
        clients,
        owners,
        loadStatus,
        data,
        isOperationInProgress,
        operationInProgressReporterData
    })
);

export const getInstanceFormValues = (state) => {
    const { instanceForm } = state.form;

    return instanceForm?.values || {};
};

export const getInstanceFormLayoutProps = createSelector(
    getInstanceFormOperationInProgress,
    getUserProfile,
    getSelectedClientId,
    getInstanceDetails,
    getInstanceDetailsLoadStatus,
    getInstanceFormPipelineDetailsLoadStatus,
    (
        isOperationInProgress,
        { clients },
        selectedClientId,
        { name },
        dataLoadStatus,
        pipelineDetailsLoadStatus
    ) => ({
        isOperationInProgress,
        client: clients.find(({ id }) => id === selectedClientId),
        displayName: name || '',
        isDataLoading: [ dataLoadStatus, pipelineDetailsLoadStatus ].includes(LOAD_STATUS.LOADING)
    })
);

export const getInstanceFormProps = createSelector(
    getInstanceFormPipelines,
    getInstanceFormPipelinesLoadStatus,
    getInstanceFormConfigurationTemplate,
    getInstanceFormFlags,
    getInstanceFormPipelineDetailsLoadStatus,
    getInstanceFormValues,
    getExportTargetsData,
    getExportTargetsDataLoadStatus,
    getInstanceFormTriggers,
    getInstanceFormOperationInProgress,
    getInstanceDetails,
    getInstanceDetailsLoadStatus,
    (
        pipelines,
        pipelinesLoadStatus,
        configurationTemplate,
        { supportsIngestionSettings, supportsExportTargets },
        pipelineDetailsLoadStatus,
        formValues,
        exportTargetsData,
        exportTargetsDataLoadStatus,
        triggers,
        isOperationInProgress,
        data,
        dataLoadStatus
    ) => ({
        pipelines,
        pipelinesLoadStatus,
        configurationTemplate,
        pipelineDetailsLoadStatus,
        formValues,
        exportTargetsData,
        exportTargetsDataLoadStatus,
        triggers,
        isOperationInProgress,
        data,
        dataLoadStatus,
        supportsIngestionSettings,
        supportsExportTargets
    })
);

export const getInstanceTriggerProps = (state, { index, currentInstanceId }) => {
    const triggers = getInstanceFormTriggers(state);
    const { feeds, instances } = getInstanceFormTriggersData(state);
    const configurationTemplate = getInstanceFormTriggerConfigurationTemplate(state);
    const isOperationInProgress = getInstanceFormOperationInProgress(state);

    const { type: triggerType, id: triggerId } = triggers[index] || {};

    let items: SelectInputItem[] = [];

    if (triggerType) {
        if (triggerType === TRIGGER_TYPE.UPSTREAM_FEED) {
            items = feeds.loadStatus === LOAD_STATUS.LOADED ?
                feeds.list.filter(
                    ({ id: feedId }) => triggerId === feedId || !triggers.some(({ id, type }) => id === feedId && type === TRIGGER_TYPE.UPSTREAM_FEED)
                ) : [];
        } else if (triggerType === TRIGGER_TYPE.UPSTREAM_INSTANCE) {
            items = instances.loadStatus === LOAD_STATUS.LOADED ?
                instances.list.filter(
                    ({ id: instanceId }) => instanceId !== currentInstanceId && (triggerId === instanceId || !triggers.some(({ id, type }) => id === instanceId && type === TRIGGER_TYPE.UPSTREAM_INSTANCE))
                ) : [];
        }
    }

    return {
        items,
        feedsLoadStatus: feeds.loadStatus,
        instancesLoadStatus: instances.loadStatus,
        configurationTemplate,
        isOperationInProgress
    };
};
