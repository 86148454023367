import * as t from '../actionTypes';
import { PIPELINES_LIST_PAGE_SIZE } from 'modules/transformationPipelines/constants';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';

const ACTION_HANDLERS = {
    [t.SET_PIPELINES_LIST_ITEMS]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            items: action.payload
        }
    }),
    [t.APPEND_PIPELINES_LIST_ITEMS]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            items: state.pipelinesList.items.concat(action.payload)
        }
    }),
    [t.SET_PIPELINES_LIST_PAGE]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            page: action.payload
        }
    }),
    [t.SET_PIPELINES_LIST_COUNT]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            totalCount: action.payload
        }
    }),
    [t.SET_PIPELINES_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            sortBy: action.payload
        }
    }),
    [t.SET_PIPELINES_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            sortOrder: action.payload
        }
    }),
    [t.SET_PIPELINES_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            loadStatus: action.payload
        }
    }),
    [t.SET_PIPELINES_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        pipelinesList: {
            ...state.pipelinesList,
            searchBy: action.payload
        }
    }),
    [t.RESET_PIPELINES_LIST]: (state) => ({
        ...state,
        pipelinesList: initialState.pipelinesList
    }),
    [t.SET_PIPELINE_DETAILS]: (state, action) => ({
        ...state,
        pipelineDetails: {
            ...state.pipelineDetails,
            data: action.payload
        }
    }),
    [t.SET_PIPELINE_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        pipelineDetails: {
            ...state.pipelineDetails,
            loadStatus: action.payload
        }
    }),
    [t.RESET_PIPELINE_DETAILS]: (state) => ({
        ...state,
        pipelineDetails: initialState.pipelineDetails
    }),
    [t.SET_PIPELINE_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        pipelineForm: {
            ...state.pipelineForm,
            pipelineOperationInProgress: action.payload
        }
    }),
    [t.RESET_PIPELINE_FORM]: (state) => ({
        ...state,
        pipelineForm: initialState.pipelineForm
    })
};

export const initialState = {
    pipelinesList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: PIPELINES_LIST_PAGE_SIZE,
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED
    },
    pipelineDetails: {
        loadStatus: LOAD_STATUS.REQUIRED,
        data: {}
    },
    pipelineForm: {
        pipelineOperationInProgress: false
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
