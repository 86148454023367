// feeds list
export const APPEND_FEEDS_LIST_ITEMS = 'feeds/APPEND_FEEDS_LIST_ITEMS';
export const SET_FEEDS_LIST_ITEMS = 'feeds/SET_FEEDS_LIST_ITEMS';
export const SET_FEEDS_LIST_PAGE = 'feeds/SET_FEEDS_LIST_PAGE';
export const SET_FEEDS_LIST_COUNT = 'feeds/SET_FEEDS_LIST_COUNT';
export const SET_FEEDS_LIST_LOAD_STATUS = 'feeds/SET_FEEDS_LIST_LOAD_STATUS';
export const SET_FEEDS_LIST_SORT_COLUMN = 'feeds/SET_FEEDS_LIST_SORT_COLUMN';
export const SET_FEEDS_LIST_SORT_ORDER = 'feeds/SET_FEEDS_LIST_SORT_ORDER';
export const SET_FEEDS_LIST_SEARCH_TEXT = 'feeds/SET_FEEDS_LIST_SEARCH_TEXT';
export const RESET_FEEDS_LIST = 'feeds/RESET_FEEDS_LIST';

// feed details
export const SET_FEED_DETAILS = 'feeds/SET_FEED_DETAILS';
export const SET_ADDITIONAL_FEED_DETAILS = 'feeds/SET_ADDITIONAL_FEED_DETAILS';
export const SET_FEED_DETAILS_LOAD_STATUS = 'feeds/SET_FEED_DETAILS_LOAD_STATUS';
export const RESET_FEED_DETAILS = 'feeds/RESET_FEED_DETAILS';
export const SET_FEED_DETAILS_OPERATION_IN_PROGRESS = 'feeds/SET_FEED_DETAILS_OPERATION_IN_PROGRESS';
export const SET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA = 'feeds/SET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA';
export const UNSET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA = 'feeds/UNSET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA';
export const SET_FEED_DETAILS_VIEW = 'feeds/SET_FEED_DETAILS_VIEW';
export const OPEN_AD_HOC_RUN_DIALOG = 'feeds/OPEN_AD_HOC_RUN_DIALOG';
export const CLOSE_AD_HOC_RUN_DIALOG = 'feeds/CLOSE_AD_HOC_RUN_DIALOG';
export const OPEN_FEED_DELETE_DIALOG = 'feeds/OPEN_FEED_DELETE_DIALOG';
export const SET_FEED_DELETE_DIALOG_INSTANCES = 'feeds/SET_FEED_DELETE_DIALOG_INSTANCES';
export const CLOSE_FEED_DELETE_DIALOG = 'feeds/CLOSE_FEED_DELETE_DIALOG';

// feed form
export const RESET_FEED_FORM = 'feeds/RESET_FEED_FORM';
export const SET_FEED_FORM_OPERATION_IN_PROGRESS = 'feeds/SET_FEED_FORM_OPERATION_IN_PROGRESS';
export const SET_FEED_FORM_SOURCES = 'feeds/SET_FEED_FORM_SOURCES';
export const SET_FEED_FORM_SOURCES_LOAD_STATUS = 'feeds/SET_FEED_FORM_SOURCES_LOAD_STATUS';
export const SET_FEED_FORM_SOURCE_DETAILS = 'feeds/SET_FEED_FORM_SOURCE_DETAILS';
export const SET_FEED_FORM_SOURCE_DETAILS_LOAD_STATUS = 'feeds/SET_FEED_FORM_SOURCE_DETAILS_LOAD_STATUS';
export const SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST = 'feeds/SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST';
export const SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST_LOAD_STATUS = 'feeds/SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST_LOAD_STATUS';
export const RESET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST = 'feeds/RESET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST';
export const SET_FEED_FORM_COLLECTOR_CREDENTIALS = 'feeds/SET_FEED_FORM_COLLECTOR_CREDENTIALS';
export const SET_FEED_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS = 'feeds/SET_FEED_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS';
export const ADD_FEED_SCHEDULE = 'feeds/ADD_FEED_SCHEDULE';
export const REMOVE_FEED_SCHEDULE = 'feeds/REMOVE_FEED_SCHEDULE';
export const SET_FEED_SCHEDULES = 'feeds/SET_FEED_SCHEDULES';
export const CLEAR_FEED_SCHEDULES = 'feeds/CLEAR_FEED_SCHEDULES';
export const ADD_FEED_TARGET = 'feeds/ADD_FEED_TARGET';
export const REMOVE_FEED_TARGET = 'feeds/REMOVE_FEED_TARGET';
export const SET_FEED_TARGETS = 'feeds/SET_FEED_TARGETS';
export const SET_FEED_FORM_TARGETS = 'feeds/SET_FEED_FORM_TARGETS';
export const SET_FEED_FORM_TARGETS_LOAD_STATUS = 'feeds/SET_FEED_FORM_TARGETS_LOAD_STATUS';
export const SET_FEED_FORM_TARGET_CREDENTIALS = 'feeds/SET_FEED_FORM_TARGET_CREDENTIALS';
export const SET_FEED_FORM_TARGET_CREDENTIALS_LOAD_STATUS = 'feeds/SET_FEED_FORM_TARGET_CREDENTIALS_LOAD_STATUS';
export const SET_FEED_FORM_ADVERITY_AUTHORIZATIONS = 'feeds/SET_FEED_FORM_ADVERITY_AUTHORIZATIONS';
export const SET_FEED_FORM_ADVERITY_AUTHORIZATIONS_LOAD_STATUS = 'feeds/SET_FEED_FORM_ADVERITY_AUTHORIZATIONS_LOAD_STATUS';

// feed runs
export const APPEND_FEED_RUNS_LIST_ITEMS = 'feeds/APPEND_FEED_RUNS_LIST_ITEMS';
export const SET_FEED_RUNS_LIST_ITEMS = 'feeds/SET_FEED_RUNS_LIST_ITEMS';
export const SET_FEED_RUNS_LIST_PAGE = 'feeds/SET_FEED_RUNS_LIST_PAGE';
export const SET_FEED_RUNS_LIST_COUNT = 'feeds/SET_FEED_RUNS_LIST_COUNT';
export const SET_FEED_RUNS_LIST_LOAD_STATUS = 'feeds/SET_FEED_RUNS_LIST_LOAD_STATUS';
export const SET_FEED_RUNS_LIST_SORT_COLUMN = 'feeds/SET_FEED_RUNS_LIST_SORT_COLUMN';
export const SET_FEED_RUNS_LIST_SORT_ORDER = 'feeds/SET_FEED_RUNS_LIST_SORT_ORDER';
export const SET_FEED_RUNS_OPERATION_IN_PROGRESS = 'feeds/SET_FEED_RUNS_OPERATION_IN_PROGRESS';
export const SET_FEED_RUNS_LIST_SEARCH_TEXT = 'feeds/SET_FEED_RUNS_LIST_SEARCH_TEXT';
export const SET_SELECTED_FEED_RUNS = 'feeds/SET_SELECTED_FEED_RUNS';
export const RESET_FEED_RUNS_LIST = 'feeds/RESET_FEED_RUNS_LIST';
export const SET_FEED_RUNS_FILTERS_STATUS = 'feeds/SET_FEED_RUNS_FILTERS_STATUS';
export const SET_FEED_RUNS_FILTERS_FETCH_START = 'feeds/SET_FEED_RUNS_FILTERS_FETCH_START';
export const SET_FEED_RUNS_FILTERS_FETCH_END = 'feeds/SET_FEED_RUNS_FILTERS_FETCH_END';
export const SET_FEED_RUNS_FILTERS_RUN_DATE = 'feeds/SET_FEED_RUNS_FILTERS_RUN_DATE';
export const RESET_FEED_RUNS_FILTERS = 'feeds/RESET_FEED_RUNS_FILTERS';

// common
export const SET_FEED_COLLECTION_OFFSET_TEMPLATE = 'feeds/SET_FEED_COLLECTION_OFFSET_TEMPLATE';
export const SET_FEED_COLLECTION_OFFSET_TEMPLATE_LOAD_STATUS = 'feeds/SET_FEED_COLLECTION_OFFSET_TEMPLATE_LOAD_STATUS';
