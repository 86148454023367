import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getUserProfile } from 'modules/auth/selectors';
import { memberApiDefinitions } from 'modules/service/types';
import { useAppDispatch } from 'modules/common/hooks';
import { PERMISSIONS } from 'modules/common/constants';
import { formatString } from 'modules/common/utils';
import Table from 'modules/common/components/Table';
import { ROLE_TYPE } from 'modules/admin/constants';
import RolesChips from 'modules/admin/components/RolesChips';
import { CLIENTS_ROUTES } from 'modules/admin/clients/constants';
import { goToClientDetailsPage } from 'modules/admin/clients/actions';

import local from '../GroupDetailsLayout/local.module.scss';

interface GroupClientsTableProps {
    items: Array<memberApiDefinitions['GroupClientDetails']>;
    isLoaded: boolean;
}

const LIST_SKELETON_ROWS_COUNT = 5;

const GroupClientsTable: FunctionComponent<GroupClientsTableProps> = ({ items, isLoaded }) => {
    const dispatch = useAppDispatch();
    const { clients } = useSelector(getUserProfile);

    const onTableRowClick = useCallback(({ id }) => {
        if (isLoaded) {
            const canViewClient = clients?.find(({ id: clientId }) => id === clientId)?.permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW);

            if (canViewClient) {
                dispatch(goToClientDetailsPage(id));
            }
        }
    }, [ isLoaded, clients ]);

    const tableConfig = useMemo(() => [
        {
            Header: <FormattedMessage id='common.name' />,
            id: 'name',
            accessor: ({ id, name }) => {
                const canViewClient = clients?.find(({ id: clientId }) => id === clientId)?.permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW);

                return canViewClient ? (
                    <a href={`#${formatString(CLIENTS_ROUTES.DETAILS, id)}`} className={local.name}>
                        {name}
                    </a>
                ) : name;
            },
            width: '25%',
            skeletonWidth: '100%',
            showTooltip: true
        },
        {
            Header: <FormattedMessage id='admin.roles' />,
            id: 'roles',
            accessor: ({ roles }) => (
                <RolesChips
                    editable={false}
                    type={ROLE_TYPE.CLIENT}
                    selectedRoles={roles}
                />
            ),
            skeletonWidth: '100%'
        }
    ], [ clients ]);

    return (
        <Table
            columns={tableConfig}
            data={items}
            skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
            isLoaded={isLoaded}
            onRowClick={onTableRowClick}
        />
    );
};

export default GroupClientsTable;
