import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import isEqual from 'lodash/isEqual';
import RadioGroup from '@mui/material/RadioGroup';
import moment from 'moment';

import { ingestionApiDefinitions } from 'modules/service/types';
import { GO_LIVE_TYPE, SCHEMA_MODE, SOURCE_STATE } from 'modules/sources/constants';
import { isDeepEqual } from 'modules/common/utils';
import DatePicker from 'modules/common/components/DatePicker';
import RadioButton from 'modules/common/components/RadioButton';

import { AUTOMATION_ID, TARGET_TYPE } from 'modules/common/constants';

import local from '../SourceFormLayout/local.module.scss';

interface GoLiveSectionProps {
    isEditMode: boolean;
    isOperationInProgress: boolean;
    isTestMode: boolean;
    formValues: { [key: string]: any };
    mode: typeof SCHEMA_MODE[keyof typeof SCHEMA_MODE];
    schema: any;
    data: ingestionApiDefinitions['SourceVersionDetailDto'];
}

interface GoLiveSectionState {
    error?: string;
}

class GoLiveSection extends Component<GoLiveSectionProps, GoLiveSectionState> {
    public state: { error?: string } = { error: undefined };

    public render() {
        const { isEditMode, isOperationInProgress, formValues: { goLiveType } } = this.props;
        const { error } = this.state;

        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='sources.goLiveDate' />
                </div>
                <div className={local.label}>
                    <FormattedMessage id='sources.goLiveDateLabel' />
                </div>
                <div className='container-row'>
                    <Field
                        name='goLiveType'
                        isOperationInProgress={isOperationInProgress}
                        component={this.renderGoLiveRadio}
                    />
                    <div>
                        <Field
                            name='goLiveDate'
                            isOperationInProgress={isOperationInProgress}
                            goLiveType={goLiveType}
                            component={this.renderGoLiveDatePicker}
                        />
                        {
                            isEditMode && !this.isTestSource() &&
                            <div className={local.goLiveRadioWarning}>
                                <FormattedMessage id='common.notRecommended' />
                            </div>
                        }
                    </div>
                </div>
                {
                    error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                    </div>
                }
                {
                    isEditMode && this.renderGoLiveWarnings()
                }
            </Fragment>
        );
    }

    private renderGoLiveWarnings = () => {
        const { schema: schemaObj, mode, formValues, data } = this.props;

        if (!data.ingestionSettings) {
            return null;
        }

        const {
            ingestionSettings: {
                primaryKeyColumns,
                dateColumn,
                targetTypeSpecificSettings: {
                    [TARGET_TYPE.BIGQUERY]: {
                        clusterColumns
                    }
                }
            },
            schema,
            schemaFields
        } = data;

        const goLiveNow = formValues.goLiveType === GO_LIVE_TYPE.NOW;

        let isSchemaChanged = mode === SCHEMA_MODE.ADVANCED ?
            formValues.schema !== schema :
            !isDeepEqual(schemaObj.fields, schemaFields!);

        isSchemaChanged = isSchemaChanged || (
            !isEqual(primaryKeyColumns, formValues.primaryKeyColumns) ||
            !isEqual(clusterColumns, formValues.bigquery.clusterColumns) ||
            dateColumn !== formValues.dateColumn
        );

        const date = !goLiveNow && formValues.goLiveDate ? formValues.goLiveDate : moment();
        const isWeekend = date.day() % 6 === 0;

        return (
            <div className={local.goLiveWarnings}>
                {
                    goLiveNow &&
                    <div className={local.goLiveStrongWarning}>
                        <FormattedMessage id='sources.disableFeedWarning' />
                    </div>
                }
                {
                    isSchemaChanged && (
                        <div className={local.goLiveWarning}>
                            {
                                isWeekend ?
                                    <FormattedMessage id='sources.validateOnNextWorkingDay' /> :
                                    <FormattedMessage id='sources.validateOnUpgradeDate' />
                            }
                        </div>
                    )
                }
            </div>
        );
    }

    private renderGoLiveDatePicker = ({ input, isOperationInProgress, goLiveType }) => {
        const { goLiveDate } = this.props.formValues;

        return (
            <DatePicker
                value={goLiveDate}
                onChange={input.onChange}
                disabled={this.isTestSource() || isOperationInProgress || (goLiveType !== GO_LIVE_TYPE.DATE)}
                disablePast={true}
            />
        );
    }

    private renderGoLiveRadio = ({ input, isOperationInProgress, meta }) => {
        const isTestSource = this.isTestSource();

        this.setState({ error: meta.error });

        return (
            <div className={local.goLiveRadio}>
                <RadioGroup {...input}>
                    <RadioButton
                        id={AUTOMATION_ID.SOURCE_FORM_GO_LIVE_DATE_ON}
                        disabled={isTestSource || isOperationInProgress}
                        label={<FormattedMessage id='common.on' />}
                        value={GO_LIVE_TYPE.DATE}
                    />
                    <RadioButton
                        id={AUTOMATION_ID.SOURCE_FORM_GO_LIVE_DATE_NOW}
                        disabled={isTestSource || isOperationInProgress}
                        label={<FormattedMessage id='common.now' />}
                        value={GO_LIVE_TYPE.NOW}
                    />
                </RadioGroup>
            </div>
        );
    }

    private isTestSource = () => {
        const { isTestMode, data: { sourceState } } = this.props;

        return isTestMode || sourceState === SOURCE_STATE.TEST;
    }
}

export default GoLiveSection;
