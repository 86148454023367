export const REDUCER_NAME = 'sources';
export const SOURCES_LIST_PAGE_SIZE = 50;

export const SOURCES_LIST_SORT_ATTRIBUTES_MAP = {
    report: 'REPORT',
    origin: 'ORIGIN',
    lastUpdated: 'LAST_UPDATE',
    ownerName: 'OWNER'
};

export const SOURCE_ORIGIN = {
    CUSTOM: 'CUSTOM'
};

export const AVRO_FIELD_TYPES = {
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    INT: 'INT',
    LONG: 'LONG',
    FLOAT: 'FLOAT',
    DOUBLE: 'DOUBLE',
    DATE: 'DATE',
    TIMESTAMP: 'TIMESTAMP',
    TIME: 'TIME'
} as const;

export const SCHEMA_MODE = {
    SIMPLE: 'SIMPLE',
    ADVANCED: 'ADVANCED'
} as const;

export const SAVE_MODE = {
    LIVE: 'LIVE',
    DRAFT: 'DRAFT'
} as const;

export const GO_LIVE_TYPE = {
    NOW: 'NOW',
    DATE: 'DATE'
};

export const SOURCE_TRANSIENT_STATE = {
    VALID: 'VALID',
    VALIDATING: 'VALIDATING',
    PENDING: 'PENDING',
    DRAFT: 'DRAFT',
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    SUPERSEDED: 'SUPERSEDED',
    CANCELLED: 'CANCELLED'
} as const;

export const SOURCE_DETAILS_VIEW = {
    MAIN: 'MAIN',
    ADDITIONAL: 'ADDITIONAL'
} as const;

export const SOURCES_ROUTES = {
    LIST: '/sources',
    DETAILS: '/sources/view/{0}/{1}',
    NEW: '/sources/add',
    EDIT: '/sources/edit/{0}/{1}/{2}'
};

export const SOURCE_STATE = {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED',
    TEST: 'TEST',
    PROMOTING: 'PROMOTING'
} as const;

export const TEST_SOURCE_EXPIRATION_PERIOD_DAYS = 30;
