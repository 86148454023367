import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { ingestionApiDefinitions } from 'modules/service/types';
import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import SearchInput from 'modules/common/components/SearchInput';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import FeedsList from 'modules/feeds/components/FeedsList';
import ErrorPage from 'modules/common/components/ErrorPage';
import { LoadStatus } from 'modules/common/interfaces';

import local from './local.module.scss';

interface FeedsListLayoutProps {
    items: Array<ingestionApiDefinitions['FeedSearchResponseDto']>;
    status: LoadStatus;
    totalCount: number;
    canView: boolean;
    canManage: boolean;
    loadData: Function;
    goToAddFeedPage: Function;
    goToFeedRunsPage: Function;
    onSearch: Function;
    resetTable: Function;
    loadMore: Function;
}

class FeedsListLayout extends Component<FeedsListLayoutProps> {
    public componentDidMount() {
        const { loadData, resetTable, canView } = this.props;

        if (canView) {
            resetTable();
            loadData();
        }
    }

    public componentDidUpdate() {
        const { status, canView, loadData } = this.props;

        if (canView && status === LOAD_STATUS.REQUIRED) {
            loadData();
        }
    }

    public render() {
        const {
            goToAddFeedPage,
            goToFeedRunsPage,
            status,
            canView,
            canManage,
            totalCount,
            items,
            loadMore,
            onSearch
        } = this.props;

        if (!canView) {
            return <ErrorPage error='403' />;
        }

        const isLoaded = status === LOAD_STATUS.LOADED;

        return (
            <Fragment>
                <div className={local.title}>
                    <FormattedMessage id='common.feeds' />
                </div>
                <div className={classnames(local.tableControls, 'container-row')}>
                    <SearchInput inputId={AUTOMATION_ID.FEEDS_SEARCH} disabled={!isLoaded} onSearch={onSearch} placeholderId='feeds.searchByFeedName' />
                    <div className={classnames(local.buttonsContainer, 'container-row')}>
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.FEED_RUNS_BTN}
                                disabled={!isLoaded}
                                onClick={() => {
                                    goToFeedRunsPage();
                                }}
                            >
                                <FormattedMessage id='feeds.viewFeedRuns' />
                            </button>
                        </div>
                        {canManage && (
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.NEW_FEED_BTN}
                                    disabled={!isLoaded}
                                    onClick={() => {
                                        goToAddFeedPage();
                                    }}
                                >
                                    <FormattedMessage id='feeds.newFeed' />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className={local.card}>
                    <FeedsList />
                    {isLoaded && totalCount > items.length && <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />}
                </div>
            </Fragment>
        );
    }
}

export default FeedsListLayout;
