import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import { formatString } from 'modules/common/utils';
import { LOAD_STATUS, PERMISSIONS, AUTOMATION_ID } from 'modules/common/constants';
import ButtonWithTooltip from 'modules/common/components/ButtonWithTooltip';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { getUserProfile } from 'modules/auth/selectors';
import {
    deleteTarget,
    getTargetData,
    goToEditTargetPage,
    goToTargetsListPage,
    resetTargetDetails,
    resetTargetManifest
} from '../../actions';
import {
    getTargetDetails,
    getTargetDetailsLoadStatus,
    getTargetDetailsOperationInProgress,
    getTargetManifest,
    getTargetManifestLoadStatus
} from '../../selectors';

import local from './local.module.scss';

type RouteMatchParams = Record<'ownerId' | 'targetId', string>;

const SKELETON_PROPERTIES_COUNT = 2;

const TargetDetailsLayout: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { ownerId, targetId } = useParams<RouteMatchParams>();

    const owner = useSelector(getUserProfile).owners.find(({ id }) => id === ownerId);
    const canView = Boolean(owner?.permissions.includes(PERMISSIONS.TARGETS.VIEW));
    const canManage = Boolean(owner?.permissions.includes(PERMISSIONS.TARGETS.MANAGE));

    const {
        name,
        targetType,
        configurationContext,
        feedCount,
        updatedBy,
        updateTimestamp = 0
    } = useSelector(getTargetDetails);
    const manifest = useSelector(getTargetManifest);
    const dataLoadStatus = useSelector(getTargetDetailsLoadStatus);
    const manifestLoadStatus = useSelector(getTargetManifestLoadStatus);
    const isOperationInProgress = useSelector(getTargetDetailsOperationInProgress);
    const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED && manifestLoadStatus === LOAD_STATUS.LOADED;

    useEffect(() => {
        dispatch(resetTargetDetails());
        dispatch(resetTargetManifest());

        if (canView) {
            dispatch(getTargetData(ownerId, targetId));
        }
    }, []);

    useDidUpdateEffect(() => {
        dispatch(resetTargetDetails());
        dispatch(resetTargetManifest());

        if (canView) {
            dispatch(getTargetData(ownerId, targetId));
        }
    }, [ ownerId, targetId ]);

    if (!canView) {
        return (
            <ErrorPage error='403' />
        );
    }

    return (
        <div>
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_TARGETS_BREADCRUMB,
                        label: (<FormattedMessage id='targets.allDestinations' />),
                        onClick: () => { dispatch(goToTargetsListPage()); }
                    },
                    {
                        label: name || ''
                    }
                ]}
                selectedItemIndex={1}
            />
            <div className={local.card}>
                <div className={classnames(local.titleContainer, 'container-row')}>
                    <div className={local.title}>
                        {
                            isLoaded ? name : <SkeletonItem width='320px' height='24px' />
                        }
                    </div>
                    {
                        isLoaded && canManage &&
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <ButtonWithTooltip
                                    id={AUTOMATION_ID.TARGET_DELETE_BTN}
                                    className='btn-negative-transparent'
                                    buttonText={<FormattedMessage id='common.delete' />}
                                    tooltipText={formatString(intl.formatMessage({ id: 'targets.cannotDeleteMessage' }), feedCount)}
                                    tooltipCondition={feedCount > 0}
                                    disabled={isOperationInProgress || (feedCount > 0)}
                                    onClick={() => {
                                        dispatch(deleteTarget(ownerId, targetId));
                                    }}
                                />
                            </div>
                            <div className='ls-button'>
                                <ButtonWithTooltip
                                    id={AUTOMATION_ID.TARGET_EDIT_BTN}
                                    buttonText={<FormattedMessage id='common.edit' />}
                                    tooltipText={formatString(intl.formatMessage({ id: 'targets.cannotEditMessage' }), feedCount)}
                                    tooltipCondition={feedCount > 0}
                                    disabled={isOperationInProgress || (feedCount > 0)}
                                    onClick={() => {
                                        dispatch(goToEditTargetPage(targetId, ownerId));
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className={local.ownerName}>
                    {
                        isLoaded ?
                            owner?.name :
                            <SkeletonItem width='160px' height='20px' />
                    }
                </div>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.targetType' />
                </div>
                <div className={local.mainPropertyValue}>
                    {
                        isLoaded ?
                            <FormattedMessage id={`common.targetType.${targetType}`} defaultMessage={targetType || 'Unknown'} /> :
                            <SkeletonItem width='160px' height='24px' />
                    }
                </div>
                <div>
                    {
                        isLoaded ?
                            configurationContext?.map(({ key, value, isSecret }) => {
                                const [panelId, fieldId] = key.split('.');
                                const field = manifest.panels.find(({ id }) => id === panelId)?.fields.find(({ id }) => id === fieldId);

                                return (
                                    <div key={key}>
                                        <div className={local.propertyName}>
                                            {field?.label || key}
                                        </div>
                                        <div className={local.propertyValue}>
                                            {isSecret && value ? '*'.repeat(12) : value}
                                        </div>
                                    </div>
                                );
                            }) :
                            Array.from(Array(SKELETON_PROPERTIES_COUNT)).map((el, index) => (
                                <div key={index}>
                                    <div className={local.propertyName}>
                                        <SkeletonItem width='160px' height='20px' />
                                    </div>
                                    <div className={local.propertyValue}>
                                        <SkeletonItem width='320px' height='16px' />
                                    </div>
                                </div>
                            ))
                    }
                </div>
                {
                    isLoaded &&
                    <Fragment>
                        <div className={local.divider} />
                        <div className={local.lastUpdatedTitle}>
                            <FormattedMessage id='common.lastUpdated' />
                        </div>
                        <div className={local.lastUpdatedValue}>
                            {`${moment.utc(updateTimestamp).format('DD MMMM YYYY [at] HH:mm UTC')} by ${updatedBy}`}
                        </div>
                    </Fragment>
                }
            </div>
        </div>
    );
};

export default TargetDetailsLayout;
