import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import local from './local.module.scss';

const AuthLoadingScreen = () => (
    <div className={classnames('container-row', local.menuContainer)}>
        <div className={local.logo}>
            <FormattedMessage id='common.logoText' />
        </div>
        <div className={local.userIcon} />
    </div>
);

export default AuthLoadingScreen;
