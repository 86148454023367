import React, { Fragment, FunctionComponent, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import { UPLOADS_TYPE } from '../../constants';
import { resetUploadsPage, setSelectedUploadsType } from '../../actions';
import { getSelectedUploadsType, getUploadsListState } from '../../selectors';

import local from './local.module.scss';

interface ListItemProps {
    id: string;
    name: string;
    isSelected: boolean;
    isDataLoading: boolean;
}

const ListItem: FunctionComponent<ListItemProps> = ({ id, name, isSelected, isDataLoading }) => {
    const dispatch = useAppDispatch();

    const clickHandler = useCallback(() => {
        if (!isSelected && !isDataLoading) {
            dispatch(setSelectedUploadsType(id));
        }
    }, [ isSelected, isDataLoading ]);

    return (
        <div
            className={classnames(
                local.item,
                'container-row',
                { [local.selected]: isSelected, [local.disabled]: isDataLoading }
            )}
            onClick={clickHandler}
        >
            <FormattedMessage id={`uploads.type.${name}`} />
            <FontAwesomeIcon icon={faChevronRight} className={local.itemIcon} />
        </div>
    );
};

const UploadTypesList: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetUploadsPage());
    }, []);

    const selectedType = useSelector(getSelectedUploadsType);
    const { loadStatus } = useSelector(getUploadsListState);

    const isDataLoading = loadStatus === LOAD_STATUS.LOADING;

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='common.type' />
            </div>
            <ListItem
                id={UPLOADS_TYPE.INPUTS}
                name={UPLOADS_TYPE.INPUTS}
                isSelected={selectedType === UPLOADS_TYPE.INPUTS}
                isDataLoading={isDataLoading}
            />
            <ListItem
                id={UPLOADS_TYPE.DELIVERY}
                name={UPLOADS_TYPE.DELIVERY}
                isSelected={selectedType === UPLOADS_TYPE.DELIVERY}
                isDataLoading={isDataLoading}
            />
            <ListItem
                id={UPLOADS_TYPE.DELETE}
                name={UPLOADS_TYPE.DELETE}
                isSelected={selectedType === UPLOADS_TYPE.DELETE}
                isDataLoading={isDataLoading}
            />
        </Fragment>
    );
};

export default UploadTypesList;
