import React, { Fragment, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { FormattedMessage } from 'react-intl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { getUserProfile } from 'modules/auth/selectors';

import { AUTOMATION_ID } from 'modules/common/constants';

import local from './local.module.scss';

const UserMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { keycloak, initialized } = useKeycloak();

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [ setAnchorEl ]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [ setAnchorEl ]);

    const logout = useCallback(() => {
        handleClose();

        if (keycloak && initialized) {
            keycloak.logout();
        }
    }, [ keycloak, initialized, handleClose ]);

    const { username, firstName, lastName } = useSelector(getUserProfile);
    const text = firstName && lastName ?
        `${firstName[0]}${lastName[0]}` :
        username ? username[0] : '';

    return (
        <Fragment>
            <div id={AUTOMATION_ID.USER_ICON} className={local.userIcon} onClick={handleClick}>{text}</div>
            <Menu
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                classes={{ paper: local.paper }}
            >
                <MenuItem
                    id={AUTOMATION_ID.SIGN_OUT_BTN}
                    onClick={logout}
                    classes={{
                        root: local.item,
                        focusVisible: local.focusedItem
                    }}
                >
                    <FormattedMessage id='auth.logout' />
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default UserMenu;
