import { push } from 'redux-first-history';
import { FormErrors, SubmissionError } from 'redux-form';

import service from 'modules/service';
import { intl } from 'modules/i18n';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { confirm, formatString, getErrorText, prepareFormErrors, showErrorSnackbar } from 'modules/common/utils';
import { getRouterPath } from 'modules/common/selectors';
import { memberApiDefinitions } from 'modules/service/types';
import { getUserProfile } from 'modules/auth/selectors';
import { loadUserProfile } from 'modules/auth/actions';
import { OWNERS_ROUTES } from '../constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

export const getOwners = () => async (dispatch) => {
    dispatch(storeActions.setOwnersLoadStatus(LOAD_STATUS.LOADING));

    try {
        const { owners }: memberApiDefinitions['ListOwnerLiteResponseDto'] = await service.api.getOwners();
        dispatch(storeActions.setOwners(owners));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setOwnersLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const goToOwnerMembershipsPage = (id) => (dispatch) => {
    dispatch(push(formatString(OWNERS_ROUTES.DETAILS, id)));
};

export const goToOwnersListPage = () => (dispatch) => {
    dispatch(push(OWNERS_ROUTES.LIST));
};

export const goToAddOwnerPage = () => (dispatch) => {
    dispatch(push(OWNERS_ROUTES.NEW));
};

const validateOwnerFormValues = (formValues) => {
    const requiredFields = [ 'name' ];
    const errors = {};

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

export const addOwner = (formValues) => async (dispatch, getState) => {
    const errors = validateOwnerFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setOwnerFormOperationInProgress(true));

    try {
        await service.api.addOwner({ name: formValues.name });

        service.analytics.trackEvent('Create owner', EVENT_CATEGORY.ADMIN);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === OWNERS_ROUTES.NEW) {
            dispatch(goToOwnersListPage());
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST || status === HTTP_CODE.CONFLICT) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(
                status === HTTP_CODE.BAD_REQUEST ? prepareFormErrors(data) : { name: 'custom.OwnerAlreadyExists' } as FormErrors
            );
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setOwnerFormOperationInProgress(false));
    }
};

export const addOwnerMember = (ownerId, { username, roles }) => async (dispatch, getState) => {
    dispatch(storeActions.setOwnerMembershipsListOperationInProgress(true));

    try {
        await service.api.addOwnerMembershipByUsername(ownerId, { username, roles });

        service.analytics.trackEvent('Add owner member', EVENT_CATEGORY.ADMIN);

        const { username: currentUserName } = getUserProfile(getState());
        if (username === currentUserName) {
            await dispatch(loadUserProfile());
        }

        dispatch(storeActions.resetOwnerDetails());
        dispatch(storeActions.closeOwnerMembershipDialog());
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            throw new SubmissionError(prepareFormErrors(data));
        } else if (status === HTTP_CODE.NOT_FOUND) {
            // TODO remove this when proper validation error will be implemented on BE
            throw new SubmissionError({ username: 'custom.MemberNotFound' });
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setOwnerMembershipsListOperationInProgress(false));
    }
};

export const ownersListSearch = (text) => (dispatch, getState) => {
    const { searchBy } = selectors.getOwnersListTableState(getState());

    if (text !== searchBy) {
        dispatch(storeActions.setOwnersListPage(0));
        dispatch(storeActions.setOwnersListSearchText(text));

        service.analytics.trackEvent('Owners list search', EVENT_CATEGORY.ADMIN);
    }
};

export const ownersListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getOwnersListTableState(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setOwnersListSortOrder(order));
    } else {
        dispatch(storeActions.setOwnersListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setOwnersListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setOwnersListPage(0));

    service.analytics.trackEvent('Owners list sort', EVENT_CATEGORY.ADMIN);
};

export const loadMoreOwners = () => (dispatch, getState) => {
    const { page } = selectors.getOwnersListTableState(getState());

    dispatch(storeActions.setOwnersListPage(page + 1));

    service.analytics.trackEvent('Load more owners', EVENT_CATEGORY.ADMIN);
};

export const ownerMembershipsListSearch = (text) => (dispatch, getState) => {
    const { searchBy } = selectors.getOwnerMembershipsListTableState(getState());

    if (text !== searchBy) {
        dispatch(storeActions.setOwnerMembershipsListPage(0));
        dispatch(storeActions.setOwnerMembershipsListSearchText(text));

        service.analytics.trackEvent('Owner memberships list search', EVENT_CATEGORY.ADMIN);
    }
};

export const ownerMembershipsListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getOwnerMembershipsListTableState(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setOwnerMembershipsListSortOrder(order));
    } else {
        dispatch(storeActions.setOwnerMembershipsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setOwnerMembershipsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setOwnerMembershipsListPage(0));

    service.analytics.trackEvent('Owner memberships list sort', EVENT_CATEGORY.ADMIN);
};

export const loadMoreOwnerMemberships = () => (dispatch, getState) => {
    const { page } = selectors.getOwnerMembershipsListTableState(getState());

    dispatch(storeActions.setOwnerMembershipsListPage(page + 1));

    service.analytics.trackEvent('Load more owner memberships', EVENT_CATEGORY.ADMIN);
};

export const resetOwnerMembershipsTable = () => (dispatch) => {
    dispatch(storeActions.setOwnerMembershipsListSearchText(''));
    dispatch(storeActions.setOwnerMembershipsListSortOrder(SORT_ORDER.ASC));
    dispatch(storeActions.setOwnerMembershipsListPage(0));
};

export const deleteOwner = (ownerId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.deleteOwner' }),
        messages: [ intl.formatMessage({ id: 'admin.deleteOwnerConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.delete' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        try {
            dispatch(storeActions.setOwnerMembershipsListOperationInProgress(true));

            await service.api.deleteOwner(ownerId);

            service.analytics.trackEvent('Delete owner', EVENT_CATEGORY.ADMIN);

            const { owners } = getUserProfile(getState());
            const owner = owners.find((item) => item.id === ownerId);
            if (owner) {
                await dispatch(loadUserProfile());
            }

            const path = getRouterPath(getState());

            // prevent changing location if user is not on this page anymore
            if (path === formatString(OWNERS_ROUTES.DETAILS, ownerId)) {
                dispatch(goToOwnersListPage());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setOwnerMembershipsListOperationInProgress(false));
        }
    }
};

export const ownerGroupsListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getOwnerGroupsListTableState(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setOwnerGroupsListSortOrder(order));
    } else {
        dispatch(storeActions.setOwnerGroupsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setOwnerGroupsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setOwnerGroupsListPage(0));

    service.analytics.trackEvent('Owner groups list sort', EVENT_CATEGORY.ADMIN);
};

export const loadMoreOwnerGroups = () => (dispatch, getState) => {
    const { page } = selectors.getOwnerGroupsListTableState(getState());

    dispatch(storeActions.setOwnerGroupsListPage(page + 1));

    service.analytics.trackEvent('Load more owner groups', EVENT_CATEGORY.ADMIN);
};

export const getOwnerDetails = (ownerId) => async (dispatch) => {
    dispatch(storeActions.setOwnerDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details = await service.api.getOwnerDetails(ownerId);

        dispatch(storeActions.setOwnerDetails(details));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setOwnerDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};
