import {
    CRON_DAY_OF_MONTH_VALUES,
    CRON_DEFAULT_VALUE,
    CRON_HOUR_VALUES,
    CRON_MINUTE_VALUES,
    SCHEDULE_FREQUENCY,
    WEEKDAYS
} from '../constants';

export const generateCronExpression = (cronProperties): string | null => {
    const { frequency, hour, minute, weekday, dayOfMonth, expression } = cronProperties;

    switch (frequency) {
        case SCHEDULE_FREQUENCY.DAILY:
            return hour && minute ? `0 ${minute} ${hour} * * *` : null;
        case SCHEDULE_FREQUENCY.WEEKLY:
            return hour && minute && weekday ? `0 ${minute} ${hour} * * ${weekday}` : null;
        case SCHEDULE_FREQUENCY.MONTHLY:
            return hour && minute && dayOfMonth ? `0 ${minute} ${hour} ${dayOfMonth} * *` : null;
        case SCHEDULE_FREQUENCY.CUSTOM: {
            const { length } = expression.split(' ');

            // if there are 5 parts in cron expression, this means seconds were skipped, but BE validation requires them
            return length === 5 ? `0 ${expression}` : expression;
        }
        default:
            return null;
    }
};

export const parseCronExpression = (cronExpression: string) => {
    const [second, minute, hour, dayOfMonth, month, weekday] = cronExpression.split(' ');

    const weekdayIndex = parseInt(weekday, 10);
    let parsedWeekday: string | null = weekday.toLowerCase();

    if (!isNaN(weekdayIndex)) {
        if (weekdayIndex === 0 || weekdayIndex === WEEKDAYS.length) {
            parsedWeekday = WEEKDAYS.at(-1)!;
        } else {
            parsedWeekday = WEEKDAYS[weekdayIndex - 1];
        }
    } else if (!WEEKDAYS.concat(CRON_DEFAULT_VALUE).includes(parsedWeekday)) {
        parsedWeekday = null;
    }

    return {
        hour,
        minute,
        second,
        dayOfMonth,
        month,
        weekday: parsedWeekday
    };
};

export const detectCronFrequency = ({ second, minute, hour, dayOfMonth, month, weekday }): string => {
    if (second !== '0' ||
        !CRON_HOUR_VALUES.includes(hour) ||
        !CRON_MINUTE_VALUES.includes(minute) ||
        month !== CRON_DEFAULT_VALUE ||
        (dayOfMonth === CRON_DEFAULT_VALUE && weekday !== CRON_DEFAULT_VALUE && !WEEKDAYS.includes(weekday)) ||
        (weekday === CRON_DEFAULT_VALUE && dayOfMonth !== CRON_DEFAULT_VALUE && !CRON_DAY_OF_MONTH_VALUES.includes(dayOfMonth)) ||
        (weekday !== CRON_DEFAULT_VALUE && dayOfMonth !== CRON_DEFAULT_VALUE)) {
        return SCHEDULE_FREQUENCY.CUSTOM;
    } else {
        if (dayOfMonth !== CRON_DEFAULT_VALUE) {
            return SCHEDULE_FREQUENCY.MONTHLY;
        } else if (weekday !== CRON_DEFAULT_VALUE) {
            return SCHEDULE_FREQUENCY.WEEKLY;
        } else {
            return SCHEDULE_FREQUENCY.DAILY;
        }
    }
};
