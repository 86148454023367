import React, { Fragment, FunctionComponent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { isValidCron } from 'cron-validator';
import cronstrue from 'cronstrue';

import { locale } from 'modules/i18n';
import Input from '../Input';

import local from './local.module.scss';

interface CronFieldProps {
    name: string;
    disabled?: boolean;
}

const CronInput = ({ input, disabled, meta }) => {
    const timer = useRef(null) as MutableRefObject<number | null>;
    const [message, setMessage] = useState('');
    const intl = useIntl();

    const getMessage = useCallback((value) => {
        let newMessage;

        if (value) {
            if (isValidCron(value, { seconds: true, alias: true, allowBlankDay: true, allowSevenAsSunday: true })) {
                try {
                    newMessage = cronstrue.toString(value, { locale, use24HourTimeFormat: true, verbose: true });
                } catch (error) {
                    newMessage = intl.formatMessage({ id: 'common.invalidCronExpression' });
                }
            } else {
                newMessage = intl.formatMessage({ id: 'common.invalidCronExpression' });
            }
        } else {
            newMessage = '';
        }

        return newMessage;
    }, []);

    useEffect(() => {
        setMessage(getMessage(input.value));

        return () => {
            timer.current && clearTimeout(timer.current);
        };
    }, []);

    const onChange = (event) => {
        const { value } = event.currentTarget;

        timer.current && clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            setMessage(getMessage(value));
        }, 500) as unknown as number;

        input.onChange(event);
    };

    return (
        <Fragment>
            <Tooltip
                title={message}
                classes={{ tooltip: local.tooltip }}
            >
                {/* important to add this span: see https://mui.com/material-ui/react-tooltip/#disabled-elements */}
                <span>
                    <Input
                        inputProperties={{ ...input, onChange }}
                        placeholder={intl.formatMessage({ id: 'common.cronExpression' })}
                        disabled={disabled}
                        className={classnames({ 'error-input': Boolean(meta.error) }, local.input)}
                    />
                </span>
            </Tooltip>
            {
                meta.error ?
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div> :
                    <div className={local.message}>
                        <FormattedMessage id='common.cronExpression' />
                    </div>
            }
        </Fragment>
    );
};

const CronField: FunctionComponent<CronFieldProps> = ({ name, disabled }) => (
    <Field
        name={name}
        disabled={disabled}
        component={CronInput}
    />
);

export default CronField;
