import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Field, InjectedFormProps } from 'redux-form';
import { useLocation } from 'react-router-dom';

import { LOAD_STATUS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import AdverityDynamicFieldsSection from 'modules/common/components/AdverityDynamicFieldsSection';
import {
    getAuthorizationFormOperationInProgress,
    getAuthorizationTypeOptions,
    getAuthorizationTypeOptionsLoadStatus,
    getAuthorizationTypesList,
    getAuthorizationTypesLoadStatus
} from '../../selectors';
import {
    getAuthorizationTypes,
    loadAuthorizationTypeOptions,
    setAuthorizationTypeOptions,
    setAuthorizationTypeOptionsLoadStatus
} from '../../actions';
import { AuthorizationTypeFieldRenderer, ErrorFieldRenderer } from './fieldRenderers';

import local from '../AuthorizationFormLayout/local.module.scss';

const AuthorizationForm: FunctionComponent<InjectedFormProps> = ({
    handleSubmit,
    change
}) => {
    const dispatch = useAppDispatch();

    const authorizationTypes = useSelector(getAuthorizationTypesList);
    const authorizationTypesLoadStatus = useSelector(getAuthorizationTypesLoadStatus);
    const authorizationTypeOptions = useSelector(getAuthorizationTypeOptions);
    const authorizationTypeOptionsLoadStatus = useSelector(getAuthorizationTypeOptionsLoadStatus);
    const isOperationInProgress = useSelector(getAuthorizationFormOperationInProgress);

    const { state } = useLocation();

    useEffect(() => {
        dispatch(setAuthorizationTypeOptions({}));
        dispatch(setAuthorizationTypeOptionsLoadStatus(LOAD_STATUS.REQUIRED));

        if (state?.authorizationTypeId) {
            const { authorizationTypeId } = state;

            change('authorizationTypeId', authorizationTypeId);
            dispatch(loadAuthorizationTypeOptions(authorizationTypeId));
        }
    }, []);

    useEffect(() => {
        if (authorizationTypesLoadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getAuthorizationTypes());
        }
    }, [ authorizationTypesLoadStatus ]);

    return (
        <form id='authorizationForm' onSubmit={handleSubmit} autoComplete='off'>
            <Field
                name='non_field_errors'
                component={ErrorFieldRenderer}
            />
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.type' />
                </div>
                <Field
                    name='authorizationTypeId'
                    component={AuthorizationTypeFieldRenderer}
                    disabled={isOperationInProgress}
                    items={authorizationTypes}
                    itemsLoadStatus={authorizationTypesLoadStatus}
                />
            </div>
            {
                authorizationTypeOptionsLoadStatus === LOAD_STATUS.LOADING &&
                <SkeletonSectionLoaderIndicator />
            }
            {
                authorizationTypeOptionsLoadStatus === LOAD_STATUS.LOADED &&
                <AdverityDynamicFieldsSection
                    change={change}
                    config={authorizationTypeOptions}
                    disabled={isOperationInProgress}
                    prefix='configuration'
                />
            }
        </form>
    );
};

export default AuthorizationForm;
