import React from 'react';
import { FormattedMessage } from 'react-intl';
import { push } from 'redux-first-history';

import { useAppDispatch } from 'modules/common/hooks';

import local from './local.module.scss';

const Logo = () => {
    const dispatch = useAppDispatch();

    return (
        <div
            className={local.logo}
            onClick={() => dispatch(push('/'))}
        >
            <FormattedMessage id='common.logoText' />
        </div>
    );
};

export default Logo;
