export const REDUCER_NAME = 'targets';

export const TARGETS_ROUTES = {
    LIST: '/targets',
    NEW: '/targets/add',
    EDIT: '/targets/edit/{0}/{1}',
    DETAILS: '/targets/view/{0}/{1}'
};

export const TARGETS_LIST_PAGE_SIZE = 50;

export const TARGETS_LIST_SORT_ATTRIBUTES_MAP = {
    name: 'NAME',
    targetType: 'TARGET_TYPE',
    updateTimestamp: 'LAST_UPDATE',
    ownerName: 'OWNER'
};
