import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { getUserProfile } from 'modules/auth/selectors';
import { ingestionApiDefinitions } from 'modules/service/types';
import { AUTOMATION_ID, LOAD_STATUS, PERMISSIONS, TARGET_TYPE } from 'modules/common/constants';
import { useAppDispatch, useDidUpdateEffect, useReduxFormValue } from 'modules/common/hooks';
import SelectInput from 'modules/common/components/SelectInput';
import Input from 'modules/common/components/Input';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import {
    getTargetData,
    loadTargetManifest,
    resetTargetDetails,
    resetTargetForm,
    resetTargetManifest
} from '../../actions';
import {
    getTargetDetailsLoadStatus,
    getTargetManifest,
    getTargetManifestLoadStatus,
    getTargetFormOperationInProgress,
    getTargetDetails
} from '../../selectors';

import local from '../TargetFormLayout/local.module.scss';

interface TargetFormOwnProps {
    isEditMode: boolean;
}

type RouteMatchParams = Record<'ownerId' | 'targetId', string>;

type TargetFormProps = TargetFormOwnProps & InjectedFormProps<{}, TargetFormOwnProps>;

type FormFieldProps = WrappedFieldProps & { isEditMode: boolean; isOperationInProgress: boolean };

const NameField: FunctionComponent<FormFieldProps> = ({ input, meta, isEditMode, isOperationInProgress }) => {
    const intl = useIntl();

    const isLoaded = useSelector(getTargetDetailsLoadStatus) === LOAD_STATUS.LOADED;
    const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'common.name';
    const placeholder = intl.formatMessage({ id: placeholderMessageId });

    const isDisabled = isEditMode ?
        (isOperationInProgress || !isLoaded) :
        isOperationInProgress;

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.name' />
            </div>
            <Input
                inputProperties={input}
                id={AUTOMATION_ID.TARGET_FORM_NAME}
                className={classnames({ 'error-input': Boolean(meta.error) })}
                placeholder={placeholder}
                disabled={isDisabled}
                maxLength={255}
                trackTiming={true}
                trackingName='Target name'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const TargetTypeField: FunctionComponent<FormFieldProps> = ({ input, meta, isEditMode, isOperationInProgress }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const targetType = useReduxFormValue('targetForm', 'targetType');

    const items = [
        {
            id: TARGET_TYPE.BIGQUERY,
            name: intl.formatMessage({ id: `common.targetType.${TARGET_TYPE.BIGQUERY}` })
        },
        {
            id: TARGET_TYPE.SNOWFLAKE,
            name: intl.formatMessage({ id: `common.targetType.${TARGET_TYPE.SNOWFLAKE}` })
        }
    ];

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.targetType' />
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.TARGET_FORM_TYPE}
                items={items}
                inputProperties={{
                    ...input,
                    onChange: (value) => {
                        if (value !== targetType) {
                            dispatch(loadTargetManifest(value));
                            input.onChange(value);
                        }
                    }
                }}
                disabled={isEditMode || isOperationInProgress}
                error={meta.error}
                trackTiming={true}
                trackingName='Target type'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const OwnerField: FunctionComponent<FormFieldProps> = ({ input, meta, isEditMode, isOperationInProgress }) => {
    const { owners } = useSelector(getUserProfile);
    const items = owners.reduce((acc, { id, name, permissions }) => {
        if (permissions.includes(PERMISSIONS.TARGETS.MANAGE)) {
            acc.push({ id, name });
        }

        return acc;
    }, []);

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.owner' />
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.TARGET_FORM_OWNER}
                placeholder={<FormattedMessage id='targets.ownerPlaceholder' />}
                items={items}
                inputProperties={input}
                disabled={isEditMode || isOperationInProgress}
                error={meta.error}
                trackTiming={true}
                trackingName='Target owner'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const TargetForm: FunctionComponent<TargetFormProps> = ({
    isEditMode,
    change,
    handleSubmit
}) => {
    const dispatch = useAppDispatch();

    const params = useParams<RouteMatchParams>();

    const isOperationInProgress = useSelector(getTargetFormOperationInProgress);
    const manifestLoadStatus = useSelector(getTargetManifestLoadStatus);
    const manifest = useSelector(getTargetManifest);
    const data: ingestionApiDefinitions['TargetDetailResponseDto'] = useSelector(getTargetDetails);

    let defaultValues: { [key: string]: string } | undefined;
    if (isEditMode) {
        defaultValues = data.configurationContext ? data.configurationContext.reduce((acc, { key, value }) => {
            acc[key] = value;

            return acc;
        }, {}) : {};
    }

    useEffect(() => {
        dispatch(resetTargetForm());
        dispatch(resetTargetManifest());

        if (isEditMode) {
            const { ownerId, targetId } = params;

            dispatch(resetTargetDetails());
            dispatch(getTargetData(ownerId, targetId));
        }
    }, [ isEditMode, params ]);

    useDidUpdateEffect(() => {
        if (isEditMode) {
            change('name', data.name);
            change('targetType', data.targetType);
            change('ownerId', data.ownerId);
        }
    }, [ isEditMode, data ]);

    return (
        <Fragment>
            <form id='targetForm' onSubmit={handleSubmit} autoComplete='off'>
                <Field
                    name='name'
                    isEditMode={isEditMode}
                    isOperationInProgress={isOperationInProgress}
                    component={NameField}
                />
                <Field
                    name='ownerId'
                    isEditMode={isEditMode}
                    isOperationInProgress={isOperationInProgress}
                    component={OwnerField}
                />
                <Field
                    name='targetType'
                    isEditMode={isEditMode}
                    isOperationInProgress={isOperationInProgress}
                    change={change}
                    component={TargetTypeField}
                />
                {manifestLoadStatus === LOAD_STATUS.LOADING && <SkeletonSectionLoaderIndicator />}
                {manifestLoadStatus === LOAD_STATUS.LOADED &&
                    <DynamicFieldsSection
                        prefix='configurationContext'
                        change={change}
                        config={manifest}
                        disabled={isOperationInProgress}
                        editMode={isEditMode}
                        defaultValues={defaultValues}
                        form='targetForm'
                    />
                }
                <Field
                    name='configurationContext'
                    component={
                        // ignore error if it belongs to nested field
                        ({ meta }) => meta.error && (typeof meta.error === 'string') ?
                            <div className={classnames(local.error, 'form-error-message')}>
                                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                            </div> :
                            null
                    }
                />
            </form>
        </Fragment>
    );
};

export default TargetForm;
