import React, { Fragment, FunctionComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faExternalLink } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch } from 'modules/common/hooks';
import Table from 'modules/common/components/Table';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { getAuthorizationsListState } from '../../selectors';
import { authorizeAuthorization, deleteAuthorization, loadMoreAuthorizations } from '../../actions';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const AuthorizationsList: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const {
        authorizationTypeId,
        items,
        totalCount,
        loadStatus,
        authorizationOperationInProgress
    } = useSelector(getAuthorizationsListState);

    const isLoaded = authorizationTypeId ? loadStatus === LOAD_STATUS.LOADED : true;

    const { clients } = useSelector(getUserProfile);
    const selectedClientId = useSelector(getSelectedClientId);
    const client = clients.find(({ id }) => id === selectedClientId);
    const canManage = client.permissions.includes(PERMISSIONS.ADVERITY_AUTHORIZATIONS.MANAGE);

    const tableConfig: object[] = [
        {
            Header: <FormattedMessage id='common.name' />,
            id: 'name',
            accessor: ({ name, isAuthorized }) => (
                <div className='container-row'>
                    <TextWithTooltip>
                        <span className={local.name}>
                            {name}
                        </span>
                    </TextWithTooltip>
                    {
                        isAuthorized &&
                        <span className={local.label}>
                            <FormattedMessage id='common.authorized' />
                        </span>
                    }
                </div>
            ),
            sortable: false,
            showTooltip: false
        }
    ];

    if (canManage) {
        tableConfig.push({
            id: 'controls',
            accessor: ({ id, isAuthorized, configuration: { app } }) => (
                <div className={classnames('container-row', local.controlsContainer)}>
                    {
                        (!isAuthorized && app) &&
                        <div
                            className={classnames(local.authorizationLinkContainer, { [local.disabled]: authorizationOperationInProgress })}
                            onClick={() => {
                                if (!authorizationOperationInProgress) {
                                    dispatch(authorizeAuthorization(id));
                                }
                            }}
                        >
                            <FontAwesomeIcon className={local.linkIcon} icon={faExternalLink} />
                            <FormattedMessage id='common.authorize' />
                        </div>
                    }
                    <div
                        className={classnames(local.deleteIconContainer, { [local.disabled]: authorizationOperationInProgress })}
                        onClick={() => {
                            if (!authorizationOperationInProgress) {
                                dispatch(deleteAuthorization(id));
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
                    </div>
                </div>
            ),
            width: '20%',
            skeletonWidth: '100%',
            skeletonCellWidth: 70
        });
    }

    return (
        <Fragment>
            <Table
                columns={tableConfig}
                data={items}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={isLoaded}
                noDataMessage={
                    !authorizationTypeId ?
                        <FormattedMessage id='credentials.adverityAuthorizationsListNoDataMessage' /> :
                        undefined
                }
            />
            {
                isLoaded && (totalCount > items.length) &&
                <LoadMoreButton
                    load={() => { dispatch(loadMoreAuthorizations()); }}
                    count={items.length}
                    totalCount={totalCount}
                />
            }
        </Fragment>
    );
};

export default AuthorizationsList;
