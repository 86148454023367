import * as t from '../actionTypes';

export const setOwners = (data) => ({
    type: t.SET_OWNERS,
    payload: data
});

export const setOwnersLoadStatus = (data) => ({
    type: t.SET_OWNERS_LOAD_STATUS,
    payload: data
});

export const resetOwners = () => ({
    type: t.RESET_OWNERS
});

export const setOwnerFormOperationInProgress = (data) => ({
    type: t.SET_OWNER_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setOwnerMembershipsListOperationInProgress = (data) => ({
    type: t.SET_OWNER_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS,
    payload: data
});

export const addOwnerMemberRole = (data) => ({
    type: t.ADD_OWNER_MEMBER_ROLE,
    payload: data
});

export const removeOwnerMembership = (data) => ({
    type: t.REMOVE_OWNER_MEMBERSHIP,
    payload: data
});

export const removeOwnerMemberRole = (data) => ({
    type: t.REMOVE_OWNER_MEMBER_ROLE,
    payload: data
});

export const openOwnerMembershipDialog = () => ({
    type: t.OPEN_OWNER_MEMBERSHIP_DIALOG
});

export const closeOwnerMembershipDialog = () => ({
    type: t.CLOSE_OWNER_MEMBERSHIP_DIALOG
});

export const setOwnersListSearchText = (data) => ({
    type: t.SET_OWNERS_LIST_SEARCH_TEXT,
    payload: data
});

export const setOwnersListSortOrder = (data) => ({
    type: t.SET_OWNERS_LIST_SORT_ORDER,
    payload: data
});

export const setOwnersListSortColumn = (data) => ({
    type: t.SET_OWNERS_LIST_SORT_COLUMN,
    payload: data
});

export const setOwnersListPage = (data) => ({
    type: t.SET_OWNERS_LIST_PAGE,
    payload: data
});

export const setOwnerMembershipsListSearchText = (data) => ({
    type: t.SET_OWNER_MEMBERSHIPS_LIST_SEARCH_TEXT,
    payload: data
});

export const setOwnerMembershipsListSortOrder = (data) => ({
    type: t.SET_OWNER_MEMBERSHIPS_LIST_SORT_ORDER,
    payload: data
});

export const setOwnerMembershipsListSortColumn = (data) => ({
    type: t.SET_OWNER_MEMBERSHIPS_LIST_SORT_COLUMN,
    payload: data
});

export const setOwnerMembershipsListPage = (data) => ({
    type: t.SET_OWNER_MEMBERSHIPS_LIST_PAGE,
    payload: data
});

export const setOwnerGroupsListSortOrder = (data) => ({
    type: t.SET_OWNER_GROUPS_LIST_SORT_ORDER,
    payload: data
});

export const setOwnerGroupsListSortColumn = (data) => ({
    type: t.SET_OWNER_GROUPS_LIST_SORT_COLUMN,
    payload: data
});

export const setOwnerGroupsListPage = (data) => ({
    type: t.SET_OWNER_GROUPS_LIST_PAGE,
    payload: data
});

export const setOwnerDetails = (data) => ({
    type: t.SET_OWNER_DETAILS,
    payload: data
});

export const setOwnerDetailsLoadStatus = (data) => ({
    type: t.SET_OWNER_DETAILS_LOAD_STATUS,
    payload: data
});

export const resetOwnerDetails = () => ({
    type: t.RESET_OWNER_DETAILS
});
