import ReactGA from 'react-ga4';

import { EVENT_CATEGORY } from 'modules/common/constants';

const { REACT_APP_GA_MEASUREMENT_ID, NODE_ENV } = process.env;
const __TEST__ = NODE_ENV === 'test';
const __LOCAL__ = NODE_ENV === 'development';

class AnalyticsManager {
    private static instance;

    constructor() {
        if (!AnalyticsManager.instance) {
            if (!__TEST__ && !__LOCAL__) {
                this.initialize();
            }

            AnalyticsManager.instance = this;
        }

        return AnalyticsManager.instance;
    }

    public trackPage = (path: string) => {
        if (ReactGA.isInitialized) {
            ReactGA.send({ hitType: 'pageview', page: path, title: path });
        }
    }

    public trackEvent = (action: string, category: string = EVENT_CATEGORY.ALL, label: string = '', value: number = 0) => {
        if (ReactGA.isInitialized) {
            ReactGA.event({
                category,
                action,
                label,
                value
            });
        }
    }

    public identify = (details: object) => {
        if (ReactGA.isInitialized) {
            ReactGA.gtag('set', 'user_properties', details);
        }
    }

    private initialize = () => {
        if (!ReactGA.isInitialized) {
            ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID!);
        }
    }
}

const analyticsService = new AnalyticsManager();

export default analyticsService;
