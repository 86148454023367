import { connect } from 'react-redux';

import { withRouteParams } from 'modules/common/hoc';
import { makeClientDetailsLayoutProps } from '../../selectors';
import {
    deleteClient,
    getClientDetails,
    goToClientsListPage,
    goToEditClientPage,
    openClientMembershipDialog,
    clientMembershipsListSearch,
    resetClientMembershipsTable,
    resetClientDetails
} from '../../actions';
import ClientDetailsLayout from './ClientDetailsLayout';

const mapDispatchToProps = {
    loadData: getClientDetails,
    resetTable: resetClientMembershipsTable,
    resetData: resetClientDetails,
    onSearch: clientMembershipsListSearch,
    openClientMembershipDialog,
    goToClientsListPage,
    goToEditClientPage,
    deleteClient
};

export default withRouteParams(connect(makeClientDetailsLayoutProps, mapDispatchToProps)(ClientDetailsLayout));
