import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import Input from 'modules/common/components/Input';
import { getOwnerFormOperationInProgress } from '../../selectors';

import local from './local.module.scss';

const NameFieldRenderer = ({ input, meta, disabled }) => {
    const intl = useIntl();

    return (
        <Fragment>
            <Input
                inputProperties={input}
                disabled={disabled}
                placeholder={intl.formatMessage({ id: 'common.name' })}
                maxLength={255}
                className={classnames({ 'error-input': Boolean(meta.error) })}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

const OwnerForm = ({ handleSubmit }) => {
    const isOperationInProgress = useSelector(getOwnerFormOperationInProgress);

    return (
        <form id='ownerForm' onSubmit={handleSubmit} autoComplete='off'>
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.name' />
                </div>
                <Field
                    name='name'
                    component={NameFieldRenderer}
                    disabled={isOperationInProgress}
                />
            </div>
        </form>
    );
};

export default OwnerForm;
