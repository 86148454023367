// pipelines list
export const APPEND_PIPELINES_LIST_ITEMS = 'pipelines/APPEND_PIPELINES_LIST_ITEMS';
export const SET_PIPELINES_LIST_ITEMS = 'pipelines/SET_PIPELINES_LIST_ITEMS';
export const SET_PIPELINES_LIST_PAGE = 'pipelines/SET_PIPELINES_LIST_PAGE';
export const SET_PIPELINES_LIST_COUNT = 'pipelines/SET_PIPELINES_LIST_COUNT';
export const SET_PIPELINES_LIST_LOAD_STATUS = 'pipelines/SET_PIPELINES_LIST_LOAD_STATUS';
export const SET_PIPELINES_LIST_SORT_COLUMN = 'pipelines/SET_PIPELINES_LIST_SORT_COLUMN';
export const SET_PIPELINES_LIST_SORT_ORDER = 'pipelines/SET_PIPELINES_LIST_SORT_ORDER';
export const SET_PIPELINES_LIST_SEARCH_TEXT = 'pipelines/SET_PIPELINES_LIST_SEARCH_TEXT';
export const RESET_PIPELINES_LIST = 'pipelines/RESET_PIPELINES_LIST';

// pipelines details
export const SET_PIPELINE_DETAILS = 'pipelines/SET_PIPELINE_DETAILS';
export const SET_PIPELINE_DETAILS_LOAD_STATUS = 'pipelines/SET_PIPELINE_DETAILS_LOAD_STATUS';
export const RESET_PIPELINE_DETAILS = 'pipelines/RESET_PIPELINE_DETAILS';

// pipeline form
export const SET_PIPELINE_FORM_OPERATION_IN_PROGRESS = 'pipelines/SET_PIPELINE_FORM_OPERATION_IN_PROGRESS';
export const RESET_PIPELINE_FORM = 'pipelines/RESET_PIPELINE_FORM';
