import React, { Fragment, FunctionComponent, useCallback, useState } from 'react';
import classnames from 'classnames';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ExpandMore } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { push } from 'redux-first-history';

import { useAppDispatch } from 'modules/common/hooks';

import local from './local.module.scss';

interface TabWithSubItemsProps {
    id: string;
    name: string;
    selectedSubItemIndex: number;
    subItems: Array<{ id: string; name: string; route: string }>;
}

const TabWithSubItems: FunctionComponent<TabWithSubItemsProps> = ({ id, name, selectedSubItemIndex, subItems }) => {
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [ setAnchorEl ]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [ setAnchorEl ]);

    const isOpen = Boolean(anchorEl);

    return (
        <Fragment>
            <div id={id} className={local.tab} onClick={handleClick}>
                <span className={local.menuName}>
                    <FormattedMessage id={name} />
                </span>
                <ExpandMore className={classnames(local.icon, { [local.iconOpen]: isOpen })} />
            </div>
            <Menu
                anchorEl={anchorEl}
                keepMounted={true}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ paper: local.paper }}
            >
                {
                    subItems.map((subItem, index) => (
                        <MenuItem
                            id={subItem.id}
                            key={subItem.id}
                            onClick={(event) => {
                                if (!event.ctrlKey) {
                                    dispatch(push(subItem.route));
                                    handleClose();
                                }
                            }}
                            classes={{
                                root: local.item,
                                focusVisible: local.focusedItem,
                                selected: local.selectedItem
                            }}
                            selected={index === selectedSubItemIndex}
                        >
                            <a href={`#${subItem.route}`}>
                                <FormattedMessage id={subItem.name} />
                            </a>
                        </MenuItem>
                    ))
                }
            </Menu>
        </Fragment>
    );
};

export default TabWithSubItems;
