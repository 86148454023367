import { formatString } from 'modules/common/utils';
import { GROUPS_ROUTES } from './constants';
import GroupsListLayout from './components/GroupsListLayout';
import GroupFormLayout from './components/GroupFormLayout';
import GroupDetailsLayout from './components/GroupDetailsLayout';

export default [
    {
        path: GROUPS_ROUTES.LIST,
        component: GroupsListLayout
    },
    {
        path: GROUPS_ROUTES.NEW,
        component: GroupFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(GROUPS_ROUTES.EDIT, ':groupId'),
        component: GroupFormLayout,
        params: {
            isEditMode: true
        }
    },
    {
        path: formatString(GROUPS_ROUTES.DETAILS, ':groupId'),
        component: GroupDetailsLayout
    }
];
