import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import * as actions from '../../actions';
import { getInstanceFormProps } from '../../selectors';
import InstanceForm from './InstanceForm';
import { METHOD_SWITCH_OPTIONS, TRIGGERS_LOGIC_TYPE_SWITCH_OPTIONS } from '../../constants';
import { SCHEDULE_FREQUENCY } from 'modules/common/constants';
import { removeSubmitError } from 'modules/form/actions';

const mapDispatchToProps = {
    resetForm: actions.resetInstanceFormDataAndValues,
    resetData: actions.resetInstanceDetails,
    addTrigger: actions.addTrigger,
    initTriggers: actions.initTriggers,
    getFormData: actions.getInstanceFormData,
    getPipelineDetails: actions.getPipelineDetails,
    getInstanceDataForEditing: actions.getInstanceDetails,
    updateTriggersOverrides: actions.updateTriggersOverrides,
    removeSubmitError
};

export default reduxForm({
    form: 'instanceForm',
    initialValues: {
        method: METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER,
        cron: {
            frequency: SCHEDULE_FREQUENCY.WEEKLY
        },
        producesFullDataset: false,
        triggersLogicType: TRIGGERS_LOGIC_TYPE_SWITCH_OPTIONS.OR
    }
})(injectIntl(connect(getInstanceFormProps, mapDispatchToProps)(InstanceForm)) as any) as any;
