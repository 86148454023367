import { ReportState } from './interfaces';

export const REPORTS_ROUTES = {
    LIST: '/reports',
    NEW: '/reports/add',
    EDIT: '/reports/edit/{0}/{1}',
    ASSETS: '/reports/assets/{0}/{1}'
};

export const REDUCER_NAME = 'reports';

export const REPORTS_LIST_PAGE_SIZE = 50;

export const REPORTS_LIST_SORT_ATTRIBUTES_MAP = {
    name: 'NAME',
    updateTimestamp: 'LAST_UPDATE'
};

export const REPORT_STATE = {
    CREATING: 'CREATING',
    CREATION_FAILED: 'CREATION_FAILED',
    UPDATING: 'UPDATING',
    UPDATE_FAILED: 'UPDATE_FAILED',
    DELETING: 'DELETING',
    DELETION_FAILED: 'DELETION_FAILED',
    ACTIVE: 'ACTIVE'
} as const;

export const REPORT_EXECUTION_STATUS = {
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
    RUNNING: 'RUNNING',
    UNKNOWN: 'UNKNOWN',
    NOT_APPLICABLE: 'NOT_APPLICABLE'
} as const;

export const REPORT_FORM_FIRST_STAGE = 0;
export const REPORT_FORM_SECOND_STAGE = 1;
export const REPORT_FORM_THIRD_STAGE = 2;

export const CUSTOM_VALUE = 'CUSTOM';

export const INTERIM_REPORT_STATES: ReportState[] = [
    REPORT_STATE.CREATING,
    REPORT_STATE.UPDATING,
    REPORT_STATE.DELETING
];

export const PLATFORM_ASSETS_TAB_INDEX = 0;
export const INSTANCE_ASSETS_TAB_INDEX = 1;

export const REPORT_ASSET_TYPE = {
    PLATFORM: 'PLATFORM',
    INSTANCE: 'INSTANCE'
} as const;

export const REPORT_RERUN_STATUS = {
    FAILED: 'FAILED',
    SUCCEEDED: 'SUCCEEDED',
    PARTIALLY_FAILED: 'PARTIALLY_FAILED'
} as const;
