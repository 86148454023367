import * as t from '../actionTypes';

export const addSyncError = (data) => ({
    type: t.ADD_SYNC_ERROR,
    payload: data
});

export const removeSyncError = (data) => ({
    type: t.REMOVE_SYNC_ERROR,
    payload: data
});

export const removeSubmitError = (data) => ({
    type: t.REMOVE_SUBMIT_ERROR,
    payload: data
});
