import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Popover, PopoverProps } from '@mui/material';

import local from './local.module.scss';

interface RolesSelectMenuProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: PopoverProps['onClose'];
    onSelect: Function;
    itemGroups: any[];
    selectedItems: string[];
    width?: number;
    disabled?: boolean;
    anchorOrigin?: PopoverProps['anchorOrigin'];
    transformOrigin?: PopoverProps['transformOrigin'];
}

const DEFAULT_WIDTH = 220;
const DEFAULT_HEIGHT = 255;

const RolesSelectMenu: FunctionComponent<RolesSelectMenuProps> = ({
    open,
    anchorEl,
    onClose,
    onSelect,
    itemGroups,
    selectedItems,
    width,
    disabled,
    anchorOrigin,
    transformOrigin
}) => (
    <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={transformOrigin || { vertical: 'top', horizontal: 'left' }}
    >
        <Scrollbars style={{ width: width || DEFAULT_WIDTH, height: DEFAULT_HEIGHT }}>
            <div className={classnames(local.container, { [local.disabled]: disabled })}>
                {
                    itemGroups.map(({ name, roles }, groupIndex) => {
                        const isRoleFromGroupSelected = roles.some(({ id }) => selectedItems.includes(id));

                        return (
                            <div key={groupIndex} className={classnames(local.group, { [local.selectedGroup]: isRoleFromGroupSelected })}>
                                <div className={local.groupTitle}>
                                    {name}
                                </div>
                                <div className={local.groupItemsContainer}>
                                    {
                                        roles.map(({ id, name: roleName }) =>
                                            <div
                                                key={id}
                                                className={classnames(local.groupItem, { [local.selectedItem]: selectedItems.includes(id) })}
                                                onClick={() => {
                                                    if (onSelect && !disabled && !isRoleFromGroupSelected && !selectedItems.includes(id)) {
                                                        onSelect(id);
                                                    }
                                                }}
                                            >
                                                {roleName}
                                                {
                                                    selectedItems.includes(id) &&
                                                    <FontAwesomeIcon icon={faCheck} className={local.checkIcon} />
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </Scrollbars>
    </Popover>
);

export default RolesSelectMenu;
