import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import RadioGroup from '@mui/material/RadioGroup';
import classnames from 'classnames';

import { intl } from 'modules/i18n';
import { MANIFEST_FIELD_TYPE, OVERRIDABLE_FIELD_SUFFIX, SECRET_VALUE_PLACEHOLDER } from 'modules/common/constants';
import Input from '../Input';
import SelectInput from '../SelectInput';
import Checkbox from '../Checkbox';
import RadioButton from '../RadioButton';
import ApSqlInput from '../ApSqlInput';

import local from './local.module.scss';

const getLabel = ({ label, id }, fieldName) => (
    (label || id) &&
    <div className={local.fieldLabel}>
        <FormattedMessage id={fieldName} defaultMessage={label || id} />
    </div>
);

const getMessageBlock = ({ description }, { error }, fieldName) => {
    return description || error ?
        <div className={local.fieldMessage}>
            {
                error ?
                    <span className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                    </span> :
                    <FormattedMessage id={`${fieldName}.description`} defaultMessage={description} />
            }
        </div> :
        null;
};

const getOverridableField = (name, disabled, classname) => (
    <div className={classname}>
        <Field
            name={`${name}-${OVERRIDABLE_FIELD_SUFFIX}`}
            disabled={disabled}
            component={renderOverridableField}
        />
    </div>
);

const renderOverridableField = ({ input, disabled }) => (
    <Checkbox
        inputProperties={input}
        disabled={disabled}
        label={<FormattedMessage id='common.editable' />}
    />
);

export const renderTextField = ({ config, input, meta, disabled, renderEditableCheckboxes, formName }) => (
    <Fragment>
        {getLabel(config, input.name)}
        <div className='container-row'>
            <Input
                id={input.name}
                inputProperties={input}
                disabled={disabled}
                className={classnames({ 'error-input': Boolean(meta.error) }, local.textInput)}
                trackTiming={true}
                trackingName={`[${formName}] ${input.name}`}
            />
            {renderEditableCheckboxes && getOverridableField(input.name, disabled, local.textOverridable)}
        </div>
        {getMessageBlock(config, meta, input.name)}
    </Fragment>
);

export const renderPasswordField = ({ config, input, meta, disabled, renderEditableCheckboxes, formName }) => (
    <Fragment>
        {getLabel(config, input.name)}
        <div className='container-row'>
            <Input
                id={input.name}
                inputProperties={{
                    ...input,
                    onPaste: (event) => {
                        if (input.value === SECRET_VALUE_PLACEHOLDER) {
                            // clear placeholder value, pasted text will be set by default browser behaviour
                            event.target.value = '';
                        }
                    },
                    onInput: (event) => {
                        if (input.value === SECRET_VALUE_PLACEHOLDER &&
                            event.nativeEvent.inputType !== 'insertFromPaste') {
                            event.target.value = event.nativeEvent.data;
                        }
                    }
                }}
                disabled={disabled}
                className={classnames({ 'error-input': Boolean(meta.error) }, local.passwordInput)}
                trackTiming={true}
                trackingName={`[${formName}] ${input.name}`}
                password={true}
            />
            {renderEditableCheckboxes && getOverridableField(input.name, disabled, local.passwordOverridable)}
        </div>
        {getMessageBlock(config, meta, input.name)}
    </Fragment>
);

export const renderSelectField = ({ config, input, meta, disabled, renderEditableCheckboxes, formName }) => (
    <Fragment>
        {getLabel(config, input.name)}
        <div className='container-row'>
            <SelectInput
                items={config.options.map((option) => {
                    const messageId = `${input.name}.option.${option}`;

                    return {
                        id: option,
                        name: intl.formatMessage({ id: messageId, defaultMessage: option })
                    };
                })}
                inputProperties={input}
                disabled={disabled}
                error={Boolean(meta.error)}
                trackTiming={true}
                trackingName={`[${formName}] ${input.name}`}
            />
            {renderEditableCheckboxes && getOverridableField(input.name, disabled, local.selectOverridable)}
        </div>
        {getMessageBlock(config, meta, input.name)}
    </Fragment>
);

export const renderMultiSelectField = ({ config, input, meta, disabled, renderEditableCheckboxes, formName }) => (
    <Fragment>
        {getLabel(config, input.name)}
        <div className='container-row'>
            <SelectInput
                items={config.options.map((option) => {
                    const messageId = `${input.name}.option.${option}`;

                    return {
                        id: option,
                        name: intl.formatMessage({ id: messageId, defaultMessage: option })
                    };
                })}
                inputProperties={input}
                multiple={true}
                disabled={disabled}
                error={Boolean(meta.error)}
                trackTiming={true}
                trackingName={`[${formName}] ${input.name}`}
            />
            {renderEditableCheckboxes && getOverridableField(input.name, disabled, local.multiSelectOverridable)}
        </div>
        {getMessageBlock(config, meta, input.name)}
    </Fragment>
);

export const renderCheckboxField = ({ config, input, meta, disabled, renderEditableCheckboxes }) => (
    <Fragment>
        <div className='container-row'>
            <Checkbox
                inputProperties={input}
                disabled={disabled}
                label={intl.formatMessage({ id: input.name, defaultMessage: config.label })}
            />
            {renderEditableCheckboxes && getOverridableField(input.name, disabled, local.checkboxOverridable)}
        </div>
        {getMessageBlock(config, meta, input.name)}
    </Fragment>
);

export const renderRadioField = ({ config, input, meta, disabled, renderEditableCheckboxes }) => {
    const options = config.options.map((option, index) => {
        const messageId = `${input.name}.option.${option}`;

        return (
            <RadioButton
                key={index}
                disabled={disabled}
                label={intl.formatMessage({ id: messageId, defaultMessage: option })}
                value={option}
            />
        );
    });

    return (
        <Fragment>
            <RadioGroup {...input}>
                {options}
            </RadioGroup>
            {renderEditableCheckboxes && getOverridableField(input.name, disabled, local.radioOverridable)}
            {getMessageBlock(config, meta, input.name)}
        </Fragment>
    );
};

export const renderHiddenField = () => null;

export const renderNonEditableField = ({ config, input: { name, value } }) => {
    const fieldValue = value.toString();
    const valueToDisplay = config.type === MANIFEST_FIELD_TYPE.PASSWORD ?
        '*'.repeat(12) :
        <FormattedMessage id={`${name}.option.${fieldValue}`} defaultMessage={fieldValue || ' '} />;

    return (
        <div className={local.nonEditableField}>
            <FormattedMessage id={name} defaultMessage={config.label} />
            : {valueToDisplay}
        </div>
    );
};

export const renderApSqlField = (props) => <ApSqlInput {...props} />;

export const renderIntegerField = ({ config, input, meta, disabled, formName }) => (
    <Fragment>
        {getLabel(config, input.name)}
        <div className='container-row'>
            <Input
                id={input.name}
                inputProperties={input}
                disabled={disabled}
                className={classnames({ 'error-input': Boolean(meta.error) }, local.textInput)}
                trackTiming={true}
                trackingName={`[${formName}] ${input.name}`}
            />
        </div>
        {getMessageBlock(config, meta, input.name)}
    </Fragment>
);
