import React, { Component, ComponentType, Fragment } from 'react';

import { DynamicField, ConnectedDynamicField } from '../DynamicField';
import { ingestionApiDefinitions } from 'modules/service/types';
import { MANIFEST_FIELD_TYPE, OVERRIDABLE_FIELD_SUFFIX } from 'modules/common/constants';

import local from './local.module.scss';

export interface DynamicFieldsSectionProps {
    change: Function;
    config: ingestionApiDefinitions['ManifestDto'];
    disabled: boolean;
    defaultValues?: { [key: string]: string };
    prefix: string;
    renderEditableCheckboxes?: boolean;
    form: string;
    viewOnlyForNonEditableFields?: boolean;
    customSqlValidator?: Function;
    editMode?: boolean;
    customFields?: {
        [key: string]: {
            component: ComponentType<any>;
            props?: { [key: string]: any };
            checkCondition?: (props: { [key: string]: any }) => boolean;
        };
    };
}

class DynamicFieldsSection extends Component<DynamicFieldsSectionProps> {
    public componentDidUpdate(prevProps) {
        const { change, config, renderEditableCheckboxes } = this.props;
        const { config: prevConfig } = prevProps;

        if (config !== prevConfig) {
            prevConfig.panels.forEach((panel) => {
                panel.fields.forEach((field) => {
                    // only way to completely remove value from redux form is to set it to 'undefined'
                    const key = `${panel.id}.${field.id}`;
                    change(key, undefined);

                    if (renderEditableCheckboxes) {
                        change(`${key}-${OVERRIDABLE_FIELD_SUFFIX}`, undefined);
                    }
                });
            });
        }
    }

    public render() {
        const { panels } = this.props.config;
        const panelElements: JSX.Element[] = panels ? panels.map((panelConfig) => this.getPanel(panelConfig)) : [];

        return (
            <Fragment>
                {panelElements}
            </Fragment>
        );
    }

    private getPanel = (config) => {
        const fields: JSX.Element[] = config.fields.map((fieldConfig) => this.getField(config.id, fieldConfig));

        return (
            <div className={local.panel} key={config.id}>
                {fields}
            </div>
        );
    }

    private getField = (panelId, config) => {
        const {
            prefix,
            form,
            disabled,
            editMode,
            change,
            renderEditableCheckboxes,
            defaultValues,
            viewOnlyForNonEditableFields,
            customSqlValidator,
            customFields
        } = this.props;

        const { id, type } = config;
        const fieldName = `${panelId}.${id}`;
        const fullFieldName = `${prefix}.${fieldName}`;

        const props: any = {
            prefix,
            form,
            disabled,
            editMode,
            config,
            panelId,
            renderEditableCheckboxes,
            change,
            customFields
        };

        if (defaultValues && defaultValues.hasOwnProperty(fieldName)) {
            props.defaultValue = defaultValues[fieldName];

            if (renderEditableCheckboxes) {
                props.defaultEditableValue = defaultValues[`${fieldName}-${OVERRIDABLE_FIELD_SUFFIX}`];
            }

            if (viewOnlyForNonEditableFields) {
                props.viewOnly = !Boolean(defaultValues[`${fieldName}-${OVERRIDABLE_FIELD_SUFFIX}`]);
            }
        }

        if (customSqlValidator && type === MANIFEST_FIELD_TYPE.AP_SQL) {
            props.customSqlValidator = customSqlValidator;
        }

        if (
            customFields && customFields.hasOwnProperty(id) &&
            (!customFields[id].checkCondition || customFields[id].checkCondition(props))
        ) {
            const { component: CustomField, props: customProps } = customFields[id];

            return <CustomField key={fullFieldName} {...props} {...customProps} />;
        }

        return (
            config.dependOn ?
                <ConnectedDynamicField key={fullFieldName} {...props} /> :
                <DynamicField key={fullFieldName} {...props} />
        );
    }
}

export default DynamicFieldsSection;
