import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { ROLE_TYPE } from 'modules/admin/constants';
import RolesSelect from 'modules/admin/components/RolesSelect';
import Accordion from 'modules/common/components/Accordion';
import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import { ClientRole, OwnerRole } from 'modules/common/interfaces';
import { GROUP_ITEM_TYPE } from '../../constants';

import local from './local.module.scss';

export interface GroupItemData {
    id: string;
    roles: ClientRole[] | OwnerRole[];
    key: string;
}

interface GroupItemProps {
    data: GroupItemData;
    type: typeof GROUP_ITEM_TYPE[keyof typeof GROUP_ITEM_TYPE];
    items: SelectInputItem[];
    index: number;
    updateItem: Function;
    removeItem: Function;
    isOperationInProgress: boolean;
    removeSubmitError: Function;
}

interface GroupItemState {
    error?: string;
}

class GroupItem extends Component<GroupItemProps, GroupItemState> {
    public state = { error: undefined };

    public shouldComponentUpdate(nextProps, nextState) {
        const { items, ...otherProps } = this.props;

        if (this.state.error !== nextState.error) {
            return true;
        }

        for (const key in otherProps) {
            if (otherProps[key] !== nextProps[key]) {
                return true;
            }
        }

        const { items: nextItems } = nextProps;
        if (items.length !== nextItems.length) {
            return true;
        }

        const { length } = items;
        for (let i = 0; i < length; i++) {
            if (items[i].id !== nextItems[i].id) {
                return true;
            }
        }

        return false;
    }

    public render() {
        const {
            data: { id, roles },
            items,
            index,
            type,
            isOperationInProgress
        } = this.props;

        const { error } = this.state;

        const fieldName = type === GROUP_ITEM_TYPE.CLIENT ? `client_${index}` : `owner_${index}`;
        const label = <FormattedMessage id={type === GROUP_ITEM_TYPE.CLIENT ? 'common.client' : 'common.owner'} />;

        return (
            <div className={classnames(local.container, 'container-column')}>
                <Accordion classes={{ summaryContent: local.accordionLabel, detailsRoot: local.accordionDetails }}
                    label={
                        <Fragment>
                            {label} #{index + 1}
                            <Field name={fieldName} component={this.renderError} />
                        </Fragment>
                    }
                >
                    <div className={local.field}>
                        <div className={local.label}>
                            <FormattedMessage id='common.name' />
                        </div>
                        <SelectInput
                            items={items}
                            width={200}
                            disabled={isOperationInProgress}
                            searchable={true}
                            inputProperties={{ onChange: this.onIdChange, value: id || '' }}
                            error={Boolean(error) && !id}
                        />
                    </div>
                    <div className={classnames('container-row', local.bottomContainer)}>
                        <div className={local.field}>
                            <div className={local.label}>
                                <FormattedMessage id='admin.roles' />
                            </div>
                            <RolesSelect
                                value={roles}
                                onChange={this.onRolesChange}
                                type={type === GROUP_ITEM_TYPE.CLIENT ? ROLE_TYPE.CLIENT : ROLE_TYPE.OWNER}
                                width={480}
                                disabled={!id || isOperationInProgress}
                                error={Boolean(error && id && !roles.length) ? error : undefined}
                            />
                        </div>
                        <div
                            className={classnames(local.deleteIconContainer, { [local.disabled]: Boolean(isOperationInProgress) })}
                            onClick={this.onDelete}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
                        </div>
                    </div>
                </Accordion>
            </div>
        );
    }

    private renderError = ({ meta }) => {
        const { error } = meta;

        if (this.state.error !== error) {
            this.setState({ error });
        }

        return (
            <Fragment>
                {
                    meta.error &&
                    <FontAwesomeIcon icon={faCircleExclamation} className={local.errorIcon} />
                }
            </Fragment>
        );
    }

    private onIdChange = (value) => {
        const { updateItem, index, type } = this.props;

        updateItem(type, { index, data: { id: value, roles: [] } });
    }

    private onRolesChange = (value) => {
        const { updateItem, data, index, type, removeSubmitError } = this.props;
        const fieldName = type === GROUP_ITEM_TYPE.CLIENT ? `client_${index}` : `owner_${index}`;

        updateItem(type, { index, data: { ...data, roles: value } });
        removeSubmitError({ form: 'groupForm', field: fieldName });
    }

    private onDelete = () => {
        const { removeItem, index, isOperationInProgress, type } = this.props;

        if (!isOperationInProgress) {
            removeItem(type, index);
        }
    }
}

export default GroupItem;
