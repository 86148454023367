import { connect } from 'react-redux';

import * as actions from 'modules/sources/actions';
import { getSourcesListProps } from 'modules/sources/selectors';
import SourcesList from './SourcesList';

const mapDispatchToProps = {
    onSort: actions.sourcesListSort,
    goToSourceDetailsPage: actions.goToSourceDetailsPage
};

export default connect(getSourcesListProps, mapDispatchToProps)(SourcesList);
