import React, { Fragment, FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Dialog from 'modules/common/components/Dialog';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import { formatString } from 'modules/common/utils';
import { useAppDispatch } from 'modules/common/hooks';
import { PERMISSIONS } from 'modules/common/constants';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { INSTANCES_ROUTES, INSTANCE_STATE } from 'modules/transformationInstances/constants';
import { getFeedDependentInstances, getFeedDetails, getIsFeedDeleteDialogOpen } from '../../selectors';
import { deleteFeed, closeFeedDeleteDialog } from '../../actions';

import local from './local.module.scss';

const FeedDeleteDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const isDialogOpen = useSelector(getIsFeedDeleteDialogOpen);
    const instances = useSelector(getFeedDependentInstances);
    const clientId = useSelector(getSelectedClientId);
    const { feedId } = useSelector(getFeedDetails);
    const { clients } = useSelector(getUserProfile);

    const client = clients.find(({ id }) => id === clientId);
    const canViewInstances = client.permissions.includes(PERMISSIONS.INSTANCES.VIEW);
    const canManageInstances = client.permissions.includes(PERMISSIONS.INSTANCES.MANAGE);
    const canDeleteFeed = !instances.length || canManageInstances;

    const closeCallback = useCallback(() => { dispatch(closeFeedDeleteDialog()); }, []);
    const deleteCallback = useCallback(() => { dispatch(deleteFeed(clientId, feedId)); }, [ clientId, feedId ]);

    const intl = useIntl();

    return (
        <Dialog
            open={isDialogOpen}
            title={<FormattedMessage id='feeds.deleteFeed' />}
            onClose={closeCallback}
            classes={{ body: local.body }}
        >
            <div className={local.paragraph}>
                <FormattedMessage id={canDeleteFeed ? 'feeds.deleteFeedConfirmation' : 'feeds.cannotDeleteFeedMessage'} />
            </div>
            {
                instances.length ?
                    <div className={local.paragraph}>
                        <FormattedMessage id='feeds.deleteFeedDependentTriggers' />
                        <ul className={local.linksContainer}>
                            {instances.map(({ id, name, state }, idx) => (
                                <li key={idx}>
                                    <TextWithTooltip>
                                        {
                                            canViewInstances ?
                                                <a className={local.link} href={`#${formatString(INSTANCES_ROUTES.DETAILS, clientId, id)}`}>
                                                    {name}
                                                </a> :
                                                name
                                        }
                                        {
                                            state !== INSTANCE_STATE.ENABLED && ` [${intl.formatMessage({ id: `instances.state.${state}` })}]`
                                        }
                                    </TextWithTooltip>
                                </li>
                            ))}
                        </ul>
                    </div> :
                    null
            }
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                {
                    canDeleteFeed ?
                        <Fragment>
                            <div className='ls-button'>
                                <button className='btn-flat' onClick={closeCallback}>
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <div className='ls-button'>
                                <button className='btn-negative' onClick={deleteCallback}>
                                    <FormattedMessage id='common.confirm' />
                                </button>
                            </div>
                        </Fragment> :
                        <div className='ls-button'>
                            <button onClick={closeCallback}>
                                OK
                            </button>
                        </div>
                }
            </div>
        </Dialog>
    );
};

export default FeedDeleteDialog;
