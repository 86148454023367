import React, { Fragment, FunctionComponent, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

import { AUTOMATION_ID, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import Breadcrumbs, { BreadcrumbItemType } from 'modules/common/components/Breadcrumbs';
// import SearchInput from 'modules/common/components/SearchInput';
// import SelectInput from 'modules/common/components/SelectInput';
import FeedRunsList from '../FeedRunsList';
import FeedRunsFilters from '../FeedRunsFilters';
// import { FEED_EXECUTION_STATUS_FILTER_ITEMS } from '../../constants';
import { getFeedRunsLayoutProps } from '../../selectors';
import { /* feedRunsListSearch, getFeedRunsByStatus, */ goToFeedsListPage, rerunFeeds } from '../../actions';

import local from './local.module.scss';

const FeedRunsLayout: FunctionComponent = () => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const dispatch = useAppDispatch();

    const {
        // status,
        loadStatus,
        selectedItemsIds,
        isOperationInProgress,
        clientPermissions
    } = useSelector(getFeedRunsLayoutProps);

    const isLoaded = loadStatus === LOAD_STATUS.LOADED;

    const breadcrumbsItems: BreadcrumbItemType[] = useMemo(() => [
        {
            id: AUTOMATION_ID.ALL_FEEDS_BREADCRUMB,
            label: (<FormattedMessage id='feeds.allFeeds' />),
            onClick: () => { dispatch(goToFeedsListPage()); }
        },
        {
            label: (<FormattedMessage id='feeds.feedRuns' />)
        }
    ], []);

    return (
        <Fragment>
            <Breadcrumbs
                items={breadcrumbsItems}
                selectedItemIndex={1}
            />
            <div className={classnames(local.tableControls, 'container-row')}>
                <div className='container-row'>
                    {/* TODO uncomment when search and filtering by status will be implemented */}
                    {/*<SearchInput*/}
                    {/*    inputId={AUTOMATION_ID.FEED_RUNS_SEARCH}*/}
                    {/*    disabled={!isLoaded || isOperationInProgress}*/}
                    {/*    onSearch={(text) => { dispatch(feedRunsListSearch(text)); }}*/}
                    {/*/>*/}
                    {/*<SelectInput*/}
                    {/*    inputProperties={{*/}
                    {/*        value: status,*/}
                    {/*        onChange: (newStatus) => { dispatch(getFeedRunsByStatus(newStatus)); }*/}
                    {/*    }}*/}
                    {/*    items={FEED_EXECUTION_STATUS_FILTER_ITEMS}*/}
                    {/*    disabled={!isLoaded || isOperationInProgress}*/}
                    {/*    isClearable={true}*/}
                    {/*    width={160}*/}
                    {/*/>*/}
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.FEED_RUNS_FILTER_BTN}
                            className={local.filterButton}
                            onClick={() => { setDrawerOpen(true); }}
                            disabled={!isLoaded || isOperationInProgress}
                        >
                            <FontAwesomeIcon icon={faSliders} className={local.filterIcon} />
                            <FormattedMessage id='common.filter' />
                        </button>
                    </div>
                </div>
                {
                    clientPermissions.includes(PERMISSIONS.FEEDS.RUN) &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.FEEDS_RERUN_BTN}
                            onClick={() => { dispatch(rerunFeeds()); }}
                            disabled={!isLoaded || isOperationInProgress || !selectedItemsIds.length}
                        >
                            <FormattedMessage id='feeds.rerunFeeds' />
                        </button>
                    </div>
                }
            </div>
            <div className={local.card}>
                <FeedRunsList />
            </div>
            <FeedRunsFilters isOpen={isDrawerOpen} setOpen={setDrawerOpen} />
        </Fragment>
    );
};

export default FeedRunsLayout;
