import * as t from '../actionTypes';
import { LOAD_STATUS } from 'modules/common/constants';
import { AUTHORIZATIONS_LIST_PAGE_SIZE } from '../constants';

const ACTION_HANDLERS = {
    [t.SET_AUTHORIZATION_TYPES]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            authorizationTypes: {
                ...state.common.authorizationTypes,
                list: action.payload
            }
        }
    }),
    [t.SET_AUTHORIZATION_TYPES_LOAD_STATUS]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            authorizationTypes: {
                ...state.common.authorizationTypes,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_AUTHORIZATION_TYPE_OPTIONS]: (state, action) => ({
        ...state,
        authorizationForm: {
            ...state.authorizationForm,
            options: {
                ...state.authorizationForm.options,
                object: action.payload
            }
        }
    }),
    [t.SET_AUTHORIZATION_TYPE_OPTIONS_LOAD_STATUS]: (state, action) => ({
        ...state,
        authorizationForm: {
            ...state.authorizationForm,
            options: {
                ...state.authorizationForm.options,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_AUTHORIZATION_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        authorizationForm: {
            ...state.authorizationForm,
            authorizationOperationInProgress: action.payload
        }
    }),
    [t.APPEND_AUTHORIZATIONS_LIST_ITEMS]: (state, action) => ({
        ...state,
        authorizationsList: {
            ...state.authorizationsList,
            items: state.authorizationsList.items.concat(action.payload)
        }
    }),
    [t.SET_AUTHORIZATIONS_LIST_ITEMS]: (state, action) => ({
        ...state,
        authorizationsList: {
            ...state.authorizationsList,
            items: action.payload
        }
    }),
    [t.SET_AUTHORIZATIONS_LIST_TYPE]: (state, action) => ({
        ...state,
        authorizationsList: {
            ...state.authorizationsList,
            authorizationTypeId: action.payload
        }
    }),
    [t.SET_AUTHORIZATIONS_LIST_PAGE]: (state, action) => ({
        ...state,
        authorizationsList: {
            ...state.authorizationsList,
            page: action.payload
        }
    }),
    [t.SET_AUTHORIZATIONS_LIST_COUNT]: (state, action) => ({
        ...state,
        authorizationsList: {
            ...state.authorizationsList,
            totalCount: action.payload
        }
    }),
    [t.SET_AUTHORIZATIONS_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        authorizationsList: {
            ...state.authorizationsList,
            loadStatus: action.payload
        }
    }),
    [t.SET_AUTHORIZATIONS_LIST_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        authorizationsList: {
            ...state.authorizationsList,
            authorizationOperationInProgress: action.payload
        }
    }),
    [t.RESET_AUTHORIZATIONS_LIST]: (state) => ({
        ...state,
        authorizationsList: initialState.authorizationsList
    })
};

export const initialState = {
    common: {
        authorizationTypes: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        }
    },
    authorizationForm: {
        options: {
            object: {},
            loadStatus: LOAD_STATUS.REQUIRED
        },
        authorizationOperationInProgress: false
    },
    authorizationsList: {
        authorizationTypeId: null,
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: AUTHORIZATIONS_LIST_PAGE_SIZE,
        loadStatus: LOAD_STATUS.REQUIRED,
        authorizationOperationInProgress: false
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
