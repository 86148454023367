import React, { Fragment, FunctionComponent, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ErrorPage from 'modules/common/components/ErrorPage';
import SearchInput from 'modules/common/components/SearchInput';
import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { AUTOMATION_ID, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { getReportsListState } from '../../selectors';
import { getReportsList, goToAddReportPage, reportsListSearch, resetReportsList } from '../../actions';
import ReportsList from '../ReportsList';

import local from './local.module.scss';

const ReportsListLayout: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const { loadStatus, reportOperationInProgress } = useSelector(getReportsListState);
    const { clients, owners } = useSelector(getUserProfile);
    const selectedClientId = useSelector(getSelectedClientId);
    const client = clients.find(({ id }) => id === selectedClientId);

    const canView = client.permissions.includes(PERMISSIONS.REPORTS.VIEW);
    const canManage = client.permissions.includes(PERMISSIONS.REPORTS.MANAGE) &&
        owners.some(({ permissions }) => permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE_FROM_REPORT));

    useEffect(() => {
        if (canView) {
            dispatch(resetReportsList());
            dispatch(getReportsList());
        }
    }, []);

    useDidUpdateEffect(() => {
        if (canView && loadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getReportsList());
        }
    }, [ loadStatus ]);

    const newButtonCallback = useCallback(() => {
        dispatch(goToAddReportPage());
    }, []);

    const searchCallback = useCallback((searchString: string) => {
        dispatch(reportsListSearch(searchString));
    }, []);

    if (!canView) {
        return (
            <ErrorPage error='403' />
        );
    }

    const isDisabled = reportOperationInProgress || (loadStatus !== LOAD_STATUS.LOADED);

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='common.reports' />
            </div>
            <div className={classnames(local.tableControls, 'container-row')}>
                <SearchInput disabled={isDisabled} onSearch={searchCallback} />
                {
                    canManage &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.NEW_REPORT_BTN}
                            onClick={newButtonCallback}
                            disabled={isDisabled}
                        >
                            <FormattedMessage id='reports.newReport' />
                        </button>
                    </div>
                }
            </div>
            <div className={local.card}>
                <ReportsList />
            </div>
        </Fragment>
    );
};

export default ReportsListLayout;
