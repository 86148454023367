import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { intl } from 'modules/i18n';
import { showErrorSnackbar } from 'modules/common/utils';
import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import InstanceAdHocRunForm from '../InstanceAdHocRunForm';
import { getInstanceRunMode, getIsAdHocRunDialogOpen } from '../../selectors';
import { closeAdHocRunDialog, runInstance } from '../../actions';
import { INSTANCE_RUN_MODE } from '../../constants';

import local from './local.module.scss';

const InstanceAdHocRunDialog = () => {
    const dispatch = useAppDispatch();
    const isAdHocRunDialogOpen = useSelector(getIsAdHocRunDialogOpen);
    const runMode = useSelector(getInstanceRunMode);

    const titleMessageId = runMode === INSTANCE_RUN_MODE.TEST ? 'common.runNowInTestMode' : 'common.runNow';

    const onSubmit = useCallback((values) => {
        dispatch(clearSubmitErrors('instanceAdHocRunForm'));

        return dispatch(runInstance(values));
    }, []);

    const onSubmitFail = useCallback(() => {
        showErrorSnackbar(intl.formatMessage({ id: 'instances.adHocRunOnFormSubmitFail' }));
    }, []);

    return (
        <Dialog
            open={isAdHocRunDialogOpen}
            title={<FormattedMessage id={titleMessageId} />}
            onClose={() => { dispatch(closeAdHocRunDialog()); }}
            classes={{ body: local.body }}
        >
            <InstanceAdHocRunForm
                onSubmit={onSubmit}
                onSubmitFail={onSubmitFail}
            />
        </Dialog>
    );
};

export default InstanceAdHocRunDialog;
