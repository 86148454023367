import React, { Fragment, FunctionComponent, PropsWithChildren, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';
import { Field } from 'redux-form';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import { AUTOMATION_ID, LOAD_STATUS } from 'modules/common/constants';
import Checkbox from 'modules/common/components/Checkbox';
import SchemaDeduplicateColumn from '../SchemaDeduplicateColumn';
import { addDeduplicateColumn, moveDeduplicateColumn, setDeduplicateColumns } from '../../actions';
import {
    getSourceDetailsLoadStatus,
    getSourceFormDeduplicateColumns,
    getSourceFormOperationInProgress
} from '../../selectors';

import local from './local.module.scss';
import itemStyles from '../SchemaDeduplicateColumn/local.module.scss';

interface SchemaDeduplicationSettingsProps {
    isEditMode: boolean;
    selectColumns: boolean;
}

// TODO remove type conversion workaround when typings will be fixed in 'react-sortable-hoc'
const SchemaDeduplicateColumnsContainer = SortableContainer(({ children }) => <div>{children}</div>) as unknown as FunctionComponent<PropsWithChildren<SortableContainerProps>>;

const DeduplicateCheckbox = ({ input, disabled, onCheckboxChange }) => (
    <Checkbox
        inputProperties={{
            ...input,
            onChange: (...args) => {
                onCheckboxChange();
                input.onChange(...args);
            }
        }}
        label={<FormattedMessage id='sources.deduplicateSourceData' />}
        disabled={disabled}
    />
);

const SchemaDeduplicationSettings: FunctionComponent<SchemaDeduplicationSettingsProps> = ({ isEditMode, selectColumns }) => {
    const deduplicate = useReduxFormValue('sourceForm', 'deduplicate');

    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);
    const deduplicateColumns = useSelector(getSourceFormDeduplicateColumns);

    const dispatch = useAppDispatch();

    const onCheckboxChange = useCallback(() => {
        dispatch(setDeduplicateColumns([]));
    }, []);

    const addItem = useCallback((event) => {
        dispatch(addDeduplicateColumn());

        // prevent form submission
        event.preventDefault();
    }, []);

    const shouldCancelDragStart = useCallback((event) => {
        return !(event.target as HTMLElement).closest('svg')?.classList.contains(itemStyles.dragIcon);
    }, []);

    const moveItem = useCallback(({ oldIndex, newIndex }) => {
        if (newIndex !== oldIndex) {
            dispatch(moveDeduplicateColumn({ oldIndex, newIndex }));
        }
    }, []);

    const dataLoadStatus = useSelector(getSourceDetailsLoadStatus);
    const disabled = isEditMode ? (isOperationInProgress || (dataLoadStatus !== LOAD_STATUS.LOADED)) : isOperationInProgress;

    return (
        <Fragment>
            <Field
                name='deduplicate'
                disabled={disabled}
                onCheckboxChange={onCheckboxChange}
                component={DeduplicateCheckbox}
            />
            {
                selectColumns && deduplicate && <Fragment>
                    <div className={local.hint}>
                        <FormattedMessage id='sources.deduplicateSourceDataMessage' />
                    </div>
                    {
                        deduplicateColumns.length ?
                            <div>
                                <span className={local.tableLabel}>
                                    <FormattedMessage id='sources.columnName' />
                                </span>
                                <span className={local.tableLabel}>
                                    <FormattedMessage id='common.order' />
                                </span>
                            </div> :
                            null
                    }
                    <SchemaDeduplicateColumnsContainer
                        shouldCancelStart={shouldCancelDragStart}
                        onSortEnd={moveItem}
                        lockAxis='y'
                    >
                        {deduplicateColumns.map((data, index) => <SchemaDeduplicateColumn key={index} index={index} itemIndex={index} data={data} />)}
                    </SchemaDeduplicateColumnsContainer>
                    <div className={classnames(local.addColumnButtonContainer, 'plus-button', 'ls-button')}>
                        <button
                            id={AUTOMATION_ID.SOURCE_FORM_DEDUPLICATE_CHECKBOX}
                            className='btn-transparent'
                            onClick={addItem}
                            disabled={disabled}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            <FormattedMessage id='common.addColumn' />
                        </button>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
};

export default SchemaDeduplicationSettings;
