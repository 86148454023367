import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import local from './local.module.scss';

interface IconWithTooltipProps {
    children: NonNullable<ReactNode>;
    className?: string;
    tooltipPlacement?: TooltipProps['placement'];
    icon?: IconDefinition;
}

const IconWithTooltip: FunctionComponent<IconWithTooltipProps> = ({
    children,
    icon = faQuestionCircle,
    tooltipPlacement = 'bottom-start',
    className = ''
}) => (
    <Tooltip
        title={children}
        placement={tooltipPlacement}
        classes={{ tooltip: local.tooltip }}
    >
        <span>
            <FontAwesomeIcon icon={icon} className={classnames(local.tooltipIcon, className)} />
        </span>
    </Tooltip>
);

export default IconWithTooltip;
