import * as t from '../actionTypes';

export const setTargetCredentialManifest = (data) => ({
    type: t.SET_CREDENTIAL_MANIFEST,
    payload: data
});

export const setTargetCredentialManifestLoadStatus = (data) => ({
    type: t.SET_CREDENTIAL_MANIFEST_LOAD_STATUS,
    payload: data
});

export const resetTargetCredentialManifest = () => ({
    type: t.RESET_CREDENTIAL_MANIFEST
});

export const resetTargetCredentialForm = () => ({
    type: t.RESET_CREDENTIAL_FORM
});

export const setTargetCredentialFormOperationInProgress = (data) => ({
    type: t.SET_CREDENTIAL_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setTargetCredentialDetailsLoadStatus = (data) => ({
    type: t.SET_CREDENTIAL_DETAILS_LOAD_STATUS,
    payload: data
});

export const setTargetCredentialDetails = (data) => ({
    type: t.SET_CREDENTIAL_DETAILS,
    payload: data
});

export const resetTargetCredentialDetails = () => ({
    type: t.RESET_CREDENTIAL_DETAILS
});

export const setTargetCredentialsListItems = (data) => ({
    type: t.SET_CREDENTIALS_LIST_ITEMS,
    payload: data
});

export const appendTargetCredentialsListItems = (data) => ({
    type: t.APPEND_CREDENTIALS_LIST_ITEMS,
    payload: data
});

export const setTargetCredentialsListPage = (data) => ({
    type: t.SET_CREDENTIALS_LIST_PAGE,
    payload: data
});

export const setTargetCredentialsListCount = (data) => ({
    type: t.SET_CREDENTIALS_LIST_COUNT,
    payload: data
});

export const setTargetCredentialsListLoadStatus = (data) => ({
    type: t.SET_CREDENTIALS_LIST_LOAD_STATUS,
    payload: data
});

export const setTargetCredentialsListSortColumn = (data) => ({
    type: t.SET_CREDENTIALS_LIST_SORT_COLUMN,
    payload: data
});

export const setTargetCredentialsListSortOrder = (data) => ({
    type: t.SET_CREDENTIALS_LIST_SORT_ORDER,
    payload: data
});

export const setTargetCredentialsListSearchText = (data) => ({
    type: t.SET_CREDENTIALS_LIST_SEARCH_TEXT,
    payload: data
});

export const resetTargetCredentialsList = () => ({
    type: t.RESET_CREDENTIALS_LIST
});
