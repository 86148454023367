import { intl } from 'modules/i18n';
import { CLIENT_ROLES, OWNER_ROLES } from 'modules/common/constants';

export const REDUCER_NAME = 'admin';

export const ROLE_TYPE = {
    OWNER: 'OWNER',
    CLIENT: 'CLIENT'
} as const;

export const CLIENT_ROLE_GROUPS = [
    {
        name: intl.formatMessage({ id: 'common.reports' }),
        roles: [
            {
                id: CLIENT_ROLES.REPORTING_MANAGER,
                name: intl.formatMessage({ id: 'admin.manager' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.ingestion' }),
        roles: [
            {
                id: CLIENT_ROLES.INGESTION_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            },
            {
                id: CLIENT_ROLES.INGESTION_RUNNER,
                name: intl.formatMessage({ id: 'admin.runner' })
            },
            {
                id: CLIENT_ROLES.INGESTION_MANAGER,
                name: intl.formatMessage({ id: 'admin.manager' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.transformation' }),
        roles: [
            {
                id: CLIENT_ROLES.TRANSFORMATION_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            },
            {
                id: CLIENT_ROLES.TRANSFORMATION_RUNNER,
                name: intl.formatMessage({ id: 'admin.runner' })
            },
            {
                id: CLIENT_ROLES.TRANSFORMATION_MANAGER,
                name: intl.formatMessage({ id: 'admin.manager' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.uploads' }),
        roles: [
            {
                id: CLIENT_ROLES.MANUAL_UPLOAD_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            },
            {
                id: CLIENT_ROLES.MANUAL_UPLOAD_MANAGER,
                name: intl.formatMessage({ id: 'admin.manager' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.dataCatalog' }),
        roles: [
            {
                id: CLIENT_ROLES.DATA_CATALOG_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.memberships' }),
        roles: [
            {
                id: CLIENT_ROLES.MEMBERSHIPS_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            },
            {
                id: CLIENT_ROLES.MEMBERSHIPS_ADMIN,
                name: intl.formatMessage({ id: 'admin.admin' })
            }
        ]
    }
];

export const OWNER_ROLE_GROUPS = [
    {
        name: intl.formatMessage({ id: 'common.reports' }),
        roles: [
            {
                id: OWNER_ROLES.REPORTING_MANAGER,
                name: intl.formatMessage({ id: 'admin.manager' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.ingestion' }),
        roles: [
            {
                id: OWNER_ROLES.INGESTION_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            },
            {
                id: OWNER_ROLES.INGESTION_USER,
                name: intl.formatMessage({ id: 'admin.user' })
            },
            {
                id: OWNER_ROLES.INGESTION_MANAGER,
                name: intl.formatMessage({ id: 'admin.manager' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.transformation' }),
        roles: [
            {
                id: OWNER_ROLES.TRANSFORMATION_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            },
            {
                id: OWNER_ROLES.TRANSFORMATION_USER,
                name: intl.formatMessage({ id: 'admin.user' })
            },
            {
                id: OWNER_ROLES.TRANSFORMATION_MANAGER,
                name: intl.formatMessage({ id: 'admin.manager' })
            }
        ]
    },
    {
        name: intl.formatMessage({ id: 'common.memberships' }),
        roles: [
            {
                id: OWNER_ROLES.MEMBERSHIPS_VIEWER,
                name: intl.formatMessage({ id: 'admin.viewer' })
            },
            {
                id: CLIENT_ROLES.MEMBERSHIPS_ADMIN,
                name: intl.formatMessage({ id: 'admin.admin' })
            }
        ]
    }
];
