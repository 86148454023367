import * as storeActions from './storeActions';
import * as selectors from '../selectors';
import service from 'modules/service';
import { memberApiDefinitions } from 'modules/service/types';
import { UserProfile } from 'modules/common/interfaces';
import { processUserProfile } from 'modules/common/utils';
import { USER_TRAIT, USER_TYPE } from 'modules/common/constants';

export const updateTokens = (tokens) => (dispatch) => {
    if (tokens.token) {
        dispatch(storeActions.setData({ tokens }));
    }
};

const internalUserRegExp = new RegExp('@(choreograph.com|groupm.tech)$', 'i');

export const loadUserProfile = () => async (dispatch, getState) => {
    try {
        const userProfile: memberApiDefinitions['MemberProfileResponseDto'] = await service.api.getUserProfile();
        const profileWithPermissions: UserProfile = processUserProfile(userProfile);

        const clientsList = profileWithPermissions.clients ?
            profileWithPermissions.clients
                .map(({ id, name }) => ({ id, name }))
                .sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1)) :
            [];

        dispatch(storeActions.setUserProfile({
            userProfile: profileWithPermissions,
            clientsList
        }));

        let isClientSet = false;
        const clientIdToCheckOnLoad = selectors.getClientIdToCheckOnLoad(getState());
        if (clientIdToCheckOnLoad) {
            dispatch(storeActions.setClientIdToCheckOnLoad(null));

            if (clientsList.some(({ id }) => id === clientIdToCheckOnLoad)) {
                dispatch(saveSelectedClientId(clientIdToCheckOnLoad));
                isClientSet = true;
            }
        }

        if (!isClientSet && clientsList.length) {
            const savedClientId = localStorage.getItem(`${userProfile.username}.selectedClientId`);
            const selectedClientId = savedClientId && clientsList.some(({ id }) => id === savedClientId) ?
                savedClientId : clientsList[0].id;

            dispatch(saveSelectedClientId(selectedClientId));
        }

        try {
            const type = internalUserRegExp.test(userProfile.username) ? USER_TYPE.INTERNAL : USER_TYPE.EXTERNAL;
            service.analytics.identify({ [USER_TRAIT.USER_TYPE]: type });

            const { pendo } = window;
            const pendoVisitorId = userProfile.id;

            if (pendo && pendo.isReady && pendo.getVisitorId && (!pendo.isReady() || pendo.getVisitorId() !== pendoVisitorId)) {
                const { username, firstName, lastName } = userProfile;
                const match = username.match(/@(.+?)\./);

                let account;
                if (match && match[1]) {
                    const domain = match[1].toLowerCase();
                    account = { id: domain, name: domain };
                }

                pendo.initialize({
                    visitor: {
                        id: pendoVisitorId,
                        email: username,
                        full_name: `${firstName} ${lastName}`
                    },
                    account
                });
            }
        } catch (err) {
            console.error('Failed to initialize analytics.', err);
        }

        dispatch(storeActions.setAccessAllowed(true));
    } catch (err) {
        dispatch(storeActions.setAccessAllowed(false));
    } finally {
        dispatch(storeActions.setDataLoaded(true));
    }
};

export const saveSelectedClientId = (clientId) => (dispatch, getState) => {
    const username = selectors.getUserName(getState());

    localStorage.setItem(`${username}.selectedClientId`, clientId);
    dispatch(storeActions.setSelectedClientId(clientId));
};
