import { connect } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';

import { withRouteParams } from 'modules/common/hoc';
import { goToInstancesListPage, goToInstanceDetailsPage, addInstance, editInstance } from '../../actions';
import { getInstanceFormLayoutProps } from '../../selectors';
import InstanceFormLayout from './InstanceFormLayout';

const mapDispatchToProps = {
    goToInstancesListPage,
    goToInstanceDetailsPage,
    addInstance,
    editInstance,
    clearSubmitErrors
};

export default withRouteParams(connect(getInstanceFormLayoutProps, mapDispatchToProps)(InstanceFormLayout));
