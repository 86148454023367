import { connect } from 'react-redux';

import { getClientsListLayoutProps } from '../../selectors';
import { getClients, resetClients, goToAddClientPage, clientsListSearch } from '../../actions';
import ClientsListLayout from './ClientsListLayout';

const mapDispatchToProps = {
    loadData: getClients,
    onSearch: clientsListSearch,
    resetTable: resetClients,
    goToAddClientPage
};

export default connect(getClientsListLayoutProps, mapDispatchToProps)(ClientsListLayout);
