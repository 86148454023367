import { connect } from 'react-redux';

import { removeSubmitError } from 'modules/form/actions';
import { addFeedSchedule, clearFeedSchedules, getCollectionOffsetTemplate } from '../../actions';
import { getDataCollectionSectionProps } from '../../selectors';
import DataCollectionSection from './DataCollectionSection';

const mapDispatchToProps = {
    getCollectionOffsetTemplate,
    addFeedSchedule,
    clearFeedSchedules,
    removeSubmitError
};

export default connect(getDataCollectionSectionProps, mapDispatchToProps)(DataCollectionSection);
