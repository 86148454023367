import React, { MutableRefObject, FunctionComponent, useCallback, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import classnames from 'classnames';

import { formatString } from 'modules/common/utils';
import { getSelectedUploadsType } from '../../selectors';
import { UPLOADS_TYPE } from '../../constants';

import local from './local.module.scss';

const TEMPLATE_FILE_PATH_PATTERN = '/templates/template-{0}-{1}.csv';

const TEMPLATES = {
    [UPLOADS_TYPE.INPUTS]: {
        OLIVE: 'Olive',
        DCM: 'DCM'
    },
    [UPLOADS_TYPE.DELIVERY]: {
        OLIVE: 'Olive',
        DCM: 'DCM'
    },
    [UPLOADS_TYPE.DELETE]: {
        OLIVE: 'Olive',
        DCM: 'DCM'
    }
};

const TemplateDownload: FunctionComponent = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const downloadLinkRef = useRef() as MutableRefObject<HTMLAnchorElement>;

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [ setAnchorEl ]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [ setAnchorEl ]);

    const type = useSelector(getSelectedUploadsType);

    const download = useCallback((subtype) => {
        const { current: link } = downloadLinkRef;

        link.href = formatString(TEMPLATE_FILE_PATH_PATTERN, type.toLowerCase(), subtype.toLowerCase());
        link.click();

        handleClose();
    }, [ type ]);

    return (
        <div className={classnames('container-row', local.container)}>
            <a href='/#' ref={downloadLinkRef} style={{ display: 'none' }}>
                Download template
            </a>
            <FormattedMessage id='common.downloadTemplateMessage' />
            <span onClick={handleClick} className={local.download}>
                <FontAwesomeIcon icon={faDownload} className={local.icon} />
                <FormattedMessage id='common.downloadTemplate' />
            </span>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ paper: local.paper }}
            >
                {
                    Object.entries(TEMPLATES[type]).map(([subtype, label]) => (
                        <MenuItem
                            key={subtype}
                            onClick={() => { download(subtype); }}
                            classes={{ root: local.item, focusVisible: local.focusedItem }}
                        >
                            {label}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
};

export default TemplateDownload;
