import * as t from '../actionTypes';

export const setSourcesListItems = (data) => ({
    type: t.SET_SOURCES_LIST_ITEMS,
    payload: data
});

export const appendSourcesListItems = (data) => ({
    type: t.APPEND_SOURCES_LIST_ITEMS,
    payload: data
});

export const setSourcesListPage = (data) => ({
    type: t.SET_SOURCES_LIST_PAGE,
    payload: data
});

export const setSourcesListCount = (data) => ({
    type: t.SET_SOURCES_LIST_COUNT,
    payload: data
});

export const setSourcesListLoadStatus = (data) => ({
    type: t.SET_SOURCES_LIST_LOAD_STATUS,
    payload: data
});

export const setSourcesListSortColumn = (data) => ({
    type: t.SET_SOURCES_LIST_SORT_COLUMN,
    payload: data
});

export const setSourcesListSortOrder = (data) => ({
    type: t.SET_SOURCES_LIST_SORT_ORDER,
    payload: data
});

export const setSourcesListSearchText = (data) => ({
    type: t.SET_SOURCES_LIST_SEARCH_TEXT,
    payload: data
});

export const resetSourcesList = () => ({
    type: t.RESET_SOURCES_LIST
});

export const setSourceDetailsLoadStatus = (data) => ({
    type: t.SET_SOURCE_DETAILS_LOAD_STATUS,
    payload: data
});

export const setSourceDetails = (data) => ({
    type: t.SET_SOURCE_DETAILS,
    payload: data
});

export const setAdditionalSourceDetails = (data) => ({
    type: t.SET_ADDITIONAL_SOURCE_DETAILS,
    payload: data
});

export const resetSourceDetails = () => ({
    type: t.RESET_SOURCE_DETAILS
});

export const setSourceDetailsOperationInProgress = (data) => ({
    type: t.SET_SOURCE_DETAILS_OPERATION_IN_PROGRESS,
    payload: data
});

export const setSourceDetailsView = (data) => ({
    type: t.SET_SOURCE_DETAILS_VIEW,
    payload: data
});

export const setSourceFormCollectors = (data) => ({
    type: t.SET_SOURCE_FORM_COLLECTORS,
    payload: data
});

export const setSourceFormCollectorsLoadStatus = (data) => ({
    type: t.SET_SOURCE_FORM_COLLECTORS_LOAD_STATUS,
    payload: data
});

export const setSourceFormSchema = (data) => ({
    type: t.SET_SOURCE_FORM_SCHEMA,
    payload: data
});

export const setSourceFormSchemaBackup = (data) => ({
    type: t.SET_SOURCE_FORM_SCHEMA_BACKUP,
    payload: data
});

export const setSourceFormManifests = (data) => ({
    type: t.SET_SOURCE_FORM_MANIFESTS,
    payload: data
});

export const setSourceFormManifestsLoadStatus = (data) => ({
    type: t.SET_SOURCE_FORM_MANIFESTS_LOAD_STATUS,
    payload: data
});

export const resetSourceForm = () => ({
    type: t.RESET_SOURCE_FORM
});

export const setSourceFormOperationInProgress = (data) => ({
    type: t.SET_SOURCE_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setSourceFormMode = (data) => ({
    type: t.SET_SOURCE_FORM_MODE,
    payload: data
});

export const addDeduplicateColumn = () => ({
    type: t.ADD_DEDUPLICATE_COLUMN
});

export const setDeduplicateColumnName = (data) => ({
    type: t.SET_DEDUPLICATE_COLUMN_NAME,
    payload: data
});

export const setDeduplicateColumnOrder = (data) => ({
    type: t.SET_DEDUPLICATE_COLUMN_ORDER,
    payload: data
});

export const moveDeduplicateColumn = (data) => ({
    type: t.MOVE_DEDUPLICATE_COLUMN,
    payload: data
});

export const removeDeduplicateColumn = (data) => ({
    type: t.REMOVE_DEDUPLICATE_COLUMN,
    payload: data
});

export const setDeduplicateColumns = (data) => ({
    type: t.SET_DEDUPLICATE_COLUMNS,
    payload: data
});

export const setSourceFormDraftFlag = (data) => ({
    type: t.SET_SOURCE_FORM_DRAFT_FLAG,
    payload: data
});

export const setSourcesListFiltersStates = (data) => ({
    type: t.SET_SOURCES_LIST_FILTERS_STATES,
    payload: data
});

export const setSourcesListFiltersOwnerIds = (data) => ({
    type: t.SET_SOURCES_LIST_FILTERS_OWNER_IDS,
    payload: data
});

export const setSourceFormDatastreamTypes = (data) => ({
    type: t.SET_SOURCE_FORM_DATASTREAM_TYPES,
    payload: data
});

export const setSourceFormDatastreamTypesLoadStatus = (data) => ({
    type: t.SET_SOURCE_FORM_DATASTREAM_TYPES_LOAD_STATUS,
    payload: data
});
