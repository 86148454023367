import { combineReducers } from 'redux';

import clientsReducer from './clients/reducer';
import ownersReducer from './owners/reducer';
import membersReducer from './members/reducer';
import groupsReducer from './groups/reducer';
import { REDUCER_NAME as clientsReducerName } from './clients/constants';
import { REDUCER_NAME as ownersReducerName } from './owners/constants';
import { REDUCER_NAME as membersReducerName } from './members/constants';
import { REDUCER_NAME as groupsReducerName } from './groups/constants';

export default combineReducers({
    [clientsReducerName]: clientsReducer,
    [ownersReducerName]: ownersReducer,
    [membersReducerName]: membersReducer,
    [groupsReducerName]: groupsReducer
});
