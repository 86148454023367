import React, { Fragment, FunctionComponent, useMemo } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import Table from 'modules/common/components/Table';
import { REPORT_ASSET_TYPE, REPORT_EXECUTION_STATUS } from '../../constants';
import { getReportAssetsData, getReportAssetsListItems } from '../../selectors';
import { reportAssetsListSort, rerunReportAsset } from '../../actions';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const ReportPlatformsList: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { reportId } = useParams();

    const {
        loadStatus,
        reportOperationInProgress,
        platformsTable: { sortBy, sortOrder }
    } = useSelector(getReportAssetsData);
    const { platforms } = useSelector(getReportAssetsListItems);

    const tableConfig = useMemo(() => [
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.name' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            accessor: 'name',
            width: '35%',
            skeletonWidth: '100%',
            showTooltip: true,
            sortable: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.status' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'executionStatus',
            accessor: ({ executionStatus }) => {
                let className = '';

                switch (executionStatus) {
                    case REPORT_EXECUTION_STATUS.FAILED:
                        className = local.failed;
                        break;
                    case REPORT_EXECUTION_STATUS.SUCCEEDED:
                        className = local.succeeded;
                        break;
                    case REPORT_EXECUTION_STATUS.RUNNING:
                        className = local.running;
                        break;
                    case REPORT_EXECUTION_STATUS.UNKNOWN:
                        className = local.warning;
                        break;
                }

                return (
                    <div className='container-row'>
                        <Tooltip
                            title={<FormattedMessage id={`reports.platformStatus.${executionStatus}.message`} />}
                            classes={{ tooltip: local.tooltip }}
                        >
                            <span className={classnames(local.label, className)}>
                                <FormattedMessage id={`reports.platformStatus.${executionStatus}`} defaultMessage={executionStatus} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            width: '15%',
            skeletonWidth: '100%',
            sortable: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'updateTimestamp',
            accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
            skeletonWidth: '100%',
            sortable: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<Fragment><FormattedMessage id='common.nextRun' /> (UTC)</Fragment>}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'nextRunTimestamp',
            accessor: ({ nextRunTimestamp }) => moment.utc(nextRunTimestamp).format('DD MMM YYYY [at] HH:mm'),
            skeletonWidth: '100%',
            sortable: true
        },
        {
            id: 'rowControls',
            accessor: ({ id, executionStatus }) => {
                const isDisabled = reportOperationInProgress ||
                    executionStatus === REPORT_EXECUTION_STATUS.RUNNING;

                return (
                    <div className={local.tableRowControlsContainer}>
                        <div
                            className={classnames(local.rerunButton, { [local.disabled]: isDisabled })}
                            onClick={() => {
                                if (!isDisabled) {
                                    dispatch(rerunReportAsset(REPORT_ASSET_TYPE.PLATFORM, reportId, id));
                                }
                            }}
                        >
                            <Fragment>
                                <FontAwesomeIcon className={local.icon} icon={faRepeat} />
                                <FormattedMessage id='common.rerun' />
                            </Fragment>
                        </div>
                    </div>
                );
            },
            width: '10%',
            skeletonWidth: '100%'
        }
    ], [ sortBy, sortOrder, reportOperationInProgress ]);

    return (
        <Table
            columns={tableConfig}
            data={platforms}
            isLoaded={loadStatus === LOAD_STATUS.LOADED}
            skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
            onSort={(column) => {
                dispatch(reportAssetsListSort(REPORT_ASSET_TYPE.PLATFORM, column));
            }}
        />
    );
};

export default ReportPlatformsList;
