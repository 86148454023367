import { connect } from 'react-redux';

import { getGroupsListLayoutProps } from '../../selectors';
import { loadGroupsList, resetGroupsList, goToAddGroupPage, groupsListSearch, loadMoreGroups } from '../../actions';
import GroupsListLayout from './GroupsListLayout';

const mapDispatchToProps = {
    loadData: loadGroupsList,
    loadMore: loadMoreGroups,
    onSearch: groupsListSearch,
    resetTable: resetGroupsList,
    goToAddGroupPage
};

export default connect(getGroupsListLayoutProps, mapDispatchToProps)(GroupsListLayout);
