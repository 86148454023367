import React, { FunctionComponent, useCallback, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import SelectInput from 'modules/common/components/SelectInput';
import {
    getIsGroupMembershipDialogOpen,
    getMemberDetailsOperationInProgress,
    getMembershipDialogGroups
} from '../../selectors';
import { addGroupMembership, closeGroupMembershipDialog } from '../../actions';

import local from './local.module.scss';

const GroupMembershipDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const groups = useSelector(getMembershipDialogGroups);
    const isGroupMembershipDialogOpen = useSelector(getIsGroupMembershipDialogOpen);
    const isOperationInProgress = useSelector(getMemberDetailsOperationInProgress);

    const [groupId, setGroupId] = useState();

    const onSubmit = useCallback(() => dispatch(addGroupMembership(groupId)), [ groupId ]);
    const onClose = useCallback(() => dispatch(closeGroupMembershipDialog()), []);

    return (
        <Dialog
            open={isGroupMembershipDialogOpen}
            title={<FormattedMessage id='admin.addGroup' />}
            onClose={onClose}
            classes={{ body: local.body }}
        >
            <SelectInput
                inputProperties={{
                    onChange: (value) => { setGroupId(value); },
                    value: groupId
                }}
                items={groups}
                disabled={isOperationInProgress}
                className={local.groupSelect}
            />
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button disabled={isOperationInProgress} className='btn-flat' onClick={onClose}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <div className='ls-button'>
                    <button disabled={!groupId || isOperationInProgress} onClick={onSubmit}>
                        <FormattedMessage id='common.submit' />
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default GroupMembershipDialog;
