import { connect } from 'react-redux';

import { getOwnersListLayoutProps } from '../../selectors';
import { getOwners, resetOwners, goToAddOwnerPage, ownersListSearch } from '../../actions';
import OwnersListLayout from './OwnersListLayout';

const mapDispatchToProps = {
    loadData: getOwners,
    onSearch: ownersListSearch,
    resetTable: resetOwners,
    goToAddOwnerPage
};

export default connect(getOwnersListLayoutProps, mapDispatchToProps)(OwnersListLayout);
