import React, { useEffect, useCallback, FunctionComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import SearchInput from 'modules/common/components/SearchInput';
import { LoadStatus } from 'modules/common/interfaces';
import ClientsList from '../ClientsList';

import local from './local.module.scss';

interface ClientsListLayoutProps {
    canView: boolean;
    canManage: boolean;
    status: LoadStatus;
    loadData: Function;
    onSearch: Function;
    resetTable: Function;
    goToAddClientPage: Function;
}

const ClientsListLayout: FunctionComponent<ClientsListLayoutProps> = (props) => {
    const { loadData, resetTable, canView, canManage, status, onSearch, goToAddClientPage } = props;

    useEffect(() => {
        if (canView) {
            resetTable();
            loadData();
        }
    }, []);

    const newClientButtonCallback = useCallback(() => { goToAddClientPage(); }, []);

    if (!canView) {
        return (
            <ErrorPage error='403' />
        );
    }

    const isLoaded = status === LOAD_STATUS.LOADED;

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='common.clients' />
            </div>
            <div className={classnames(local.tableControls, 'container-row')}>
                <SearchInput inputId={AUTOMATION_ID.CLIENTS_SEARCH} disabled={!isLoaded} onSearch={onSearch} />
                {
                    canManage &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.NEW_CLIENT_BTN}
                            onClick={newClientButtonCallback}
                            disabled={!isLoaded}
                        >
                            <FormattedMessage id='admin.newClient' />
                        </button>
                    </div>
                }
            </div>
            <div className={local.card}>
                <ClientsList />
            </div>
        </Fragment>
    );
};

export default ClientsListLayout;
