import React, { ReactNode } from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import local from './local.module.scss';

interface RadioOptions {
    name: ReactNode;
    value: any;
    disabled?: boolean;
}

interface RadioSwitchProps {
    fieldName: string;
    options: RadioOptions[];
    classes?: { [key: string]: string };
    disabled?: boolean;
    onChange?: Function;
}

const renderSwitchComponent = ({ input, value, name, disabled, className, onChange }) => {
    const onClick = () => {
        if (!disabled) {
            onChange && onChange(value);
            input.onChange(value);
        }
    };

    return (
        <span
            onClick={onClick}
            className={classnames({ [local.selectedOption]: input.checked, [local.disabledOption]: disabled }, local.option, className)}
        >
            {name}
        </span>
    );
};

const RadioSwitch = ({ fieldName, onChange, options, classes = {}, disabled = false }: RadioSwitchProps) => (
    <div className={classnames(local.switch, classes.switch)}>
        {
            options.map(({ name, value, disabled: optionDisabled }, key) => {
                const props = {
                    disabled: disabled || optionDisabled,
                    className: classes.option,
                    value,
                    name,
                    onChange
                };

                return (
                    <Field
                        key={key}
                        name={fieldName}
                        type='radio'
                        value={value}
                        props={props}
                        component={renderSwitchComponent}
                    />
                );
            })
        }
    </div>
);

export default RadioSwitch;
