import React, { FunctionComponent, useEffect } from 'react';
import classnames from 'classnames';
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { intl } from 'modules/i18n';
import { useAppDispatch } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import { LoadStatus } from 'modules/common/interfaces';
import Input from 'modules/common/components/Input';
import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import {
    getSourceDetails,
    getSourceFormDatastreamTypes,
    getSourceFormDatastreamTypesLoadStatus,
    getSourceFormOperationInProgress
} from '../../selectors';
import { getDatastreamTypes } from '../../actions';

import local from './local.module.scss';

interface AdverityCollectorSpecificConfigurationFieldsProps {
    editMode: boolean;
    change: InjectedFormProps['change'];
}

type DatastreamTypeFieldRendererProps = WrappedFieldProps & {
    items: SelectInputItem[];
    itemsLoadStatus: LoadStatus;
    disabled: boolean;
    editMode: boolean;
};

type DatastreamConfigurationTemplateFieldRendererProps = WrappedFieldProps & {
    disabled: boolean;
};

const { REACT_APP_ADVERITY_COLLECTOR_ID } = process.env;

const validationErrorMessage = intl.formatMessage({ id: 'sources.invalidDatastreamConfigurationTemplate' });
const validateDatastreamConfiguration = (value) => {
    if (!value) {
        return;
    }

    try {
        const parsedValue = JSON.parse(value);

        if (!parsedValue || typeof parsedValue !== 'object' || Array.isArray(parsedValue)) {
            return validationErrorMessage;
        }
    } catch {
        return validationErrorMessage;
    }
};

const DatastreamTypeFieldRenderer: FunctionComponent<DatastreamTypeFieldRendererProps> = ({
    input,
    items,
    itemsLoadStatus,
    disabled,
    editMode
}) => {
    const isLoading = itemsLoadStatus === LOAD_STATUS.LOADING;
    const placeholderMessageId = editMode && isLoading ? 'common.loading' : 'common.select';

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.datastreamType' />
            </div>
            <SelectInput
                inputProperties={input}
                items={items}
                isLoading={itemsLoadStatus === LOAD_STATUS.LOADING}
                isClearable={true}
                disabled={disabled}
                searchable={true}
                placeholder={<FormattedMessage id={placeholderMessageId} />}
            />
        </div>
    );
};

const DatastreamConfigurationTemplateFieldRenderer: FunctionComponent<DatastreamConfigurationTemplateFieldRendererProps> = ({
    input,
    meta,
    disabled
}) => (
    <div className={local.field}>
        <div className={local.label}>
            <FormattedMessage id='sources.datastreamConfigurationTemplate' />
        </div>
        <Input
            inputProperties={input}
            multiline={true}
            disabled={disabled}
            className={classnames({ 'error-input': Boolean(meta.error) })}
        />
        {
            meta.error &&
            <div className='form-error-message'>
                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
            </div>
        }
    </div>
);

const AdverityCollectorSpecificConfigurationFields: FunctionComponent<AdverityCollectorSpecificConfigurationFieldsProps> = ({
    editMode,
    change
}) => {
    const dispatch = useAppDispatch();

    const details = useSelector(getSourceDetails);
    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);
    const datastreamTypes = useSelector(getSourceFormDatastreamTypes)
    const datastreamTypesLoadStatus = useSelector(getSourceFormDatastreamTypesLoadStatus);

    const datastreamTypeFieldName = `${REACT_APP_ADVERITY_COLLECTOR_ID}.collectorSpecificConfiguration.datastreamTypeId`;
    const datastreamConfigurationTemplateFieldName = `${REACT_APP_ADVERITY_COLLECTOR_ID}.collectorSpecificConfiguration.datastreamConfigurationTemplate`;

    useEffect(() => {
        let typeIsSet = false;
        let templateIsSet = false;

        if (editMode) {
            const { dataCollectorConfigurations } = details;

            if (dataCollectorConfigurations?.length) {
                const configuration = dataCollectorConfigurations.find(({ collectorId }) => collectorId === REACT_APP_ADVERITY_COLLECTOR_ID);

                if (configuration && configuration.collectorSpecificConfiguration) {
                    const { datastreamTypeId, datastreamConfigurationTemplate } = configuration.collectorSpecificConfiguration;

                    if (datastreamTypeId) {
                        typeIsSet = true;
                        change(datastreamTypeFieldName, datastreamTypeId);
                    }

                    if (datastreamConfigurationTemplate) {
                        templateIsSet = true;
                        change(datastreamConfigurationTemplateFieldName, JSON.stringify(datastreamConfigurationTemplate, null, 4));
                    }
                }
            }
        }

        if (!typeIsSet) {
            change(datastreamTypeFieldName, undefined);
        }

        if (!templateIsSet) {
            change(datastreamConfigurationTemplateFieldName, undefined);
        }
    }, []);

    useEffect(() => {
        if (datastreamTypesLoadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getDatastreamTypes());
        }
    }, [ datastreamTypesLoadStatus ]);

    return (
        <div className={local.container}>
            <Field
                name={datastreamTypeFieldName}
                component={DatastreamTypeFieldRenderer}
                disabled={isOperationInProgress}
                items={datastreamTypes}
                itemsLoadStatus={datastreamTypesLoadStatus}
                editMode={editMode}
            />
            <Field
                name={datastreamConfigurationTemplateFieldName}
                component={DatastreamConfigurationTemplateFieldRenderer}
                disabled={isOperationInProgress}
                validate={validateDatastreamConfiguration}
            />
        </div>
    );
};

export default AdverityCollectorSpecificConfigurationFields;
