import { formatString } from 'modules/common/utils';
import { COLLECTOR_CREDENTIALS_ROUTES } from './constants';
import CollectorCredentialFormLayout from './components/CollectorCredentialFormLayout';
import CollectorCredentialDetailsLayout from './components/CollectorCredentialDetailsLayout';

export default [
    {
        path: COLLECTOR_CREDENTIALS_ROUTES.NEW,
        component: CollectorCredentialFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(COLLECTOR_CREDENTIALS_ROUTES.DETAILS, ':ownerId', ':credentialId'),
        component: CollectorCredentialDetailsLayout
    },
    {
        path: formatString(COLLECTOR_CREDENTIALS_ROUTES.EDIT, ':ownerId', ':credentialId'),
        component: CollectorCredentialFormLayout,
        params: {
            isEditMode: true
        }
    }
];
