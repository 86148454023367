export const REDUCER_NAME = 'uploads';

export const UPLOADS_ROUTES = {
    LIST: '/uploads'
};

export const UPLOADS_TYPE = {
    INPUTS: 'INPUTS',
    DELIVERY: 'DELIVERY',
    DELETE: 'DELETE'
};

export const UPLOAD_MAX_SIZE = 20 * 1024 * 1024;
