// groups list
export const APPEND_GROUPS_LIST_ITEMS = 'admin/groups/APPEND_GROUPS_LIST_ITEMS';
export const SET_GROUPS_LIST_ITEMS = 'admin/groups/SET_GROUPS_LIST_ITEMS';
export const SET_GROUPS_LIST_PAGE = 'admin/groups/SET_GROUPS_LIST_PAGE';
export const SET_GROUPS_LIST_COUNT = 'admin/groups/SET_GROUPS_LIST_COUNT';
export const SET_GROUPS_LIST_LOAD_STATUS = 'admin/groups/SET_GROUPS_LIST_LOAD_STATUS';
export const SET_GROUPS_LIST_SORT_COLUMN = 'admin/groups/SET_GROUPS_LIST_SORT_COLUMN';
export const SET_GROUPS_LIST_SORT_ORDER = 'admin/groups/SET_GROUPS_LIST_SORT_ORDER';
export const SET_GROUPS_LIST_SEARCH_TEXT = 'admin/groups/SET_GROUPS_LIST_SEARCH_TEXT';
export const RESET_GROUPS_LIST = 'admin/groups/RESET_GROUPS_LIST';

// group form
export const RESET_GROUP_FORM = 'admin/groups/RESET_GROUP_FORM';
export const SET_GROUP_FORM_OPERATION_IN_PROGRESS = 'admin/groups/SET_GROUP_FORM_OPERATION_IN_PROGRESS';
export const ADD_GROUP_CLIENT = 'admin/groups/ADD_GROUP_CLIENT';
export const REMOVE_GROUP_CLIENT = 'admin/groups/REMOVE_GROUP_CLIENT';
export const UPDATE_GROUP_CLIENT = 'admin/groups/UPDATE_GROUP_CLIENT';
export const ADD_GROUP_OWNER = 'admin/groups/ADD_GROUP_OWNER';
export const REMOVE_GROUP_OWNER = 'admin/groups/REMOVE_GROUP_OWNER';
export const UPDATE_GROUP_OWNER = 'admin/groups/UPDATE_GROUP_OWNER';
export const INIT_CLIENTS_AND_OWNERS = 'admin/groups/INIT_CLIENTS_AND_OWNERS';

// group details
export const SET_GROUP_DETAILS = 'admin/groups/SET_GROUP_DETAILS';
export const SET_GROUP_DETAILS_LOAD_STATUS = 'admin/groups/SET_GROUP_DETAILS_LOAD_STATUS';
export const RESET_GROUP_DETAILS = 'admin/groups/RESET_GROUP_DETAILS';
export const SET_GROUP_DETAILS_OPERATION_IN_PROGRESS = 'admin/groups/SET_GROUP_DETAILS_OPERATION_IN_PROGRESS';
export const REMOVE_GROUP_MEMBERSHIP = 'admin/groups/REMOVE_GROUP_MEMBERSHIP';
export const OPEN_ADD_MEMBERSHIPS_DIALOG = 'admin/groups/OPEN_ADD_MEMBERSHIPS_DIALOG';
export const CLOSE_ADD_MEMBERSHIPS_DIALOG = 'admin/groups/CLOSE_ADD_MEMBERSHIPS_DIALOG';
