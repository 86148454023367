import { push } from 'redux-first-history';
import { SubmissionError } from 'redux-form';

import service from 'modules/service';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { formatString, getErrorText, prepareFormErrors, showErrorSnackbar } from 'modules/common/utils';
import { getRouterPath } from 'modules/common/selectors';
import { transformationApiDefinitions } from 'modules/service/types';
import { PIPELINES_ROUTES } from '../constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

export const getPipelinesList = () => async (dispatch, getState) => {
    dispatch(storeActions.setPipelinesListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const payload = selectors.getPipelinesListSearchPayload(getState());
        const list: transformationApiDefinitions['ApiPaginatedCollectionContainerDto«PipelineSearchResponseDto»'] = await service.api.getPipelinesSearchList(payload);

        dispatch(storeActions.appendPipelinesListItems(list.objects));
        dispatch(storeActions.setPipelinesListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setPipelinesListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMorePipelines = () => async (dispatch, getState) => {
    const page = selectors.getPipelinesListPage(getState()) + 1;

    dispatch(storeActions.setPipelinesListPage(page));
    await dispatch(getPipelinesList());

    service.analytics.trackEvent('Load more items', EVENT_CATEGORY.PIPELINES);
};

export const pipelinesListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const currentSortColumn = selectors.getPipelinesListSortColumn(storeState);
    const currentSortOrder = selectors.getPipelinesListSortOrder(storeState);

    if (column === currentSortColumn) {
        const order = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setPipelinesListSortOrder(order));
    } else {
        dispatch(storeActions.setPipelinesListSortColumn(column));

        if (currentSortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setPipelinesListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setPipelinesListPage(0));
    dispatch(storeActions.setPipelinesListItems([]));
    dispatch(storeActions.setPipelinesListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('List sort', EVENT_CATEGORY.PIPELINES);
};

export const pipelinesListSearch = (text) => (dispatch, getState) => {
    const currentSearchText = selectors.getPipelinesListSearchText(getState());

    if (text !== currentSearchText) {
        dispatch(storeActions.setPipelinesListPage(0));
        dispatch(storeActions.setPipelinesListItems([]));
        dispatch(storeActions.setPipelinesListSearchText(text));
        dispatch(storeActions.setPipelinesListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('List search', EVENT_CATEGORY.PIPELINES);
    }
};

export const goToPipelineDetailsPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(PIPELINES_ROUTES.DETAILS, ownerId, id)));
};

export const goToEditPipelinePage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(PIPELINES_ROUTES.EDIT, ownerId, id)));
};

export const goToPipelinesListPage = () => (dispatch) => {
    dispatch(push(PIPELINES_ROUTES.LIST));
};

export const getPipelineDetails = (ownerId, pipelineId) => async (dispatch) => {
    dispatch(storeActions.setPipelineDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details = await service.api.getPipelineDetails(ownerId, pipelineId);
        dispatch(storeActions.setPipelineDetails(details));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setPipelineDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const editPipeline = (ownerId, pipelineId/*, formValues*/) => async (dispatch, getState) => {
    dispatch(storeActions.setPipelineFormOperationInProgress(true));

    try {
        // currently this method is not used
        // TODO remove fake promise and call API when it will be implemented
        // await service.api.editPipeline(ownerId, pipelineId, formValues);
        await new Promise<void>((resolve) => resolve());

        service.analytics.trackEvent('Edit pipeline', EVENT_CATEGORY.PIPELINES);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(PIPELINES_ROUTES.EDIT, ownerId, pipelineId)) {
            dispatch(goToPipelineDetailsPage(pipelineId, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setPipelineFormOperationInProgress(false));
    }
};
