import * as t from '../actionTypes';

export const setUploadsType = (data) => ({
    type: t.SET_UPLOADS_TYPE,
    payload: data
});

export const setUploadsListDate = (data) => ({
    type: t.SET_UPLOADS_LIST_DATE,
    payload: data
});

export const setUploadsListItems = (data) => ({
    type: t.SET_UPLOADS_LIST_ITEMS,
    payload: data
});

export const setUploadsListItemsLoadStatus = (data) => ({
    type: t.SET_UPLOADS_LIST_ITEMS_LOAD_STATUS,
    payload: data
});

export const setUploadInProgress = (data) => ({
    type: t.SET_UPLOAD_IN_PROGRESS,
    payload: data
});

export const setUploadSuccess = (data) => ({
    type: t.SET_UPLOAD_SUCCESS,
    payload: data
});

export const setUploadErrors = (data) => ({
    type: t.SET_UPLOAD_ERRORS,
    payload: data
});

export const setUploadsListSortOrder = (data) => ({
    type: t.SET_UPLOADS_LIST_SORT_ORDER,
    payload: data
});

export const setUploadsListSortColumn = (data) => ({
    type: t.SET_UPLOADS_LIST_SORT_COLUMN,
    payload: data
});

export const resetUploadsList = () => ({
    type: t.RESET_UPLOADS_LIST
});
