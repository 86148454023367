import React, { FunctionComponent, MouseEventHandler, ReactNode, useCallback, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { AUTOMATION_ID, LOAD_STATUS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import { SOURCE_DETAILS_VIEW, SOURCE_STATE, SOURCE_TRANSIENT_STATE } from '../../constants';
import { getSourceDetailsButtonsProps } from '../../selectors';
import {
    confirmDeleteSource,
    confirmArchiveSource,
    confirmPromoteTestSource,
    cloneSource,
    createFeedBasedOnCurrentSource,
    deleteSourceVersion,
    deletePendingSourceVersion,
    goToEditSourcePage
} from '../../actions';

import local from './local.module.scss';

interface ButtonProps {
    id?: string;
    className?: string;
    label: ReactNode;
    disabled?: boolean;
    onClick: MouseEventHandler;
    condition: boolean;
}

interface SourceDetailsButtonsProps {
    canUse: boolean;
    canManage: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({ id, className, label, disabled, onClick, condition }) => condition ? (
    <div className='ls-button'>
        <button id={id} className={className} disabled={disabled} onClick={onClick}>
            {label}
        </button>
    </div>
) : null;

const SourceDetailsButtons: FunctionComponent<SourceDetailsButtonsProps> = ({ canUse, canManage }) => {
    const dispatch = useAppDispatch();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuClick = useCallback((event) => {
        setMenuAnchorEl(event.currentTarget);
    }, [ setMenuAnchorEl ]);

    const handleClose = useCallback(() => {
        setMenuAnchorEl(null);
    }, [ setMenuAnchorEl ]);

    const isOpen = Boolean(menuAnchorEl);

    const {
        view,
        isUsed,
        loadStatus,
        ownerId,
        sourceId,
        sourceVersionId,
        sourceState,
        canManageAnyOwner,
        canManageCurrentClient,
        isOperationInProgress,
        mainSourceVersionState,
        additionalSourceVersionState
    } = useSelector(getSourceDetailsButtonsProps);

    const isMainView = view === SOURCE_DETAILS_VIEW.MAIN;
    const isLoaded = loadStatus === LOAD_STATUS.LOADED;
    const isArchived = sourceState === SOURCE_STATE.ARCHIVED;
    const selectedSourceVersionState = isMainView ? mainSourceVersionState : additionalSourceVersionState;

    const showEditButton =
        isLoaded && canManage && !isArchived && (
            [ SOURCE_TRANSIENT_STATE.DRAFT, SOURCE_TRANSIENT_STATE.VALIDATION_FAILED ].includes(selectedSourceVersionState) ||
            (selectedSourceVersionState === SOURCE_TRANSIENT_STATE.VALID && (!additionalSourceVersionState || additionalSourceVersionState === SOURCE_TRANSIENT_STATE.VALIDATION_FAILED))
        );

    const showDeleteButton =
        isLoaded && canManage && isMainView && (!isUsed || sourceState === SOURCE_STATE.TEST) &&
        ![ SOURCE_TRANSIENT_STATE.DRAFT, SOURCE_TRANSIENT_STATE.PENDING, SOURCE_TRANSIENT_STATE.VALIDATING ].includes(selectedSourceVersionState);

    const showArchiveButton =
        isLoaded && canManage && isMainView && sourceState === SOURCE_STATE.ACTIVE &&
        ![ SOURCE_TRANSIENT_STATE.DRAFT, SOURCE_TRANSIENT_STATE.PENDING, SOURCE_TRANSIENT_STATE.VALIDATING ].includes(selectedSourceVersionState);

    const showCloneButton = isLoaded && (canManage || canManageAnyOwner);

    const showCreateFeedButton = isLoaded && isMainView && !isArchived && canUse && canManageCurrentClient &&
        selectedSourceVersionState === SOURCE_TRANSIENT_STATE.VALID;

    const showDeleteDraftButton = isLoaded && canManage && !isArchived &&
        selectedSourceVersionState === SOURCE_TRANSIENT_STATE.DRAFT;

    const showCancelPendingUpgradeButton = isLoaded && canManage && !isArchived &&
        selectedSourceVersionState === SOURCE_TRANSIENT_STATE.PENDING;

    const showPromoteButton = isLoaded && isMainView && canManage &&
        sourceState === SOURCE_STATE.TEST && selectedSourceVersionState === SOURCE_TRANSIENT_STATE.VALID &&
        additionalSourceVersionState !== SOURCE_TRANSIENT_STATE.VALIDATING;

    const items: any[] = [];

    if (showPromoteButton) {
        items.push({
            id: AUTOMATION_ID.SOURCE_PROMOTE_BTN,
            label: <FormattedMessage id='sources.promoteToLive' />,
            onClick: () => {
                handleClose();
                dispatch(confirmPromoteTestSource(ownerId, sourceId));
            }
        });
    }

    if (showCreateFeedButton) {
        items.push({
            id: AUTOMATION_ID.SOURCE_CREATE_FEED_BTN,
            label: <FormattedMessage id='feeds.createFeed' />,
            onClick: () => { dispatch(createFeedBasedOnCurrentSource()); }
        });
    }

    if (showCloneButton) {
        items.push({
            id: AUTOMATION_ID.SOURCE_CLONE_LIVE_BTN,
            label: <FormattedMessage id='sources.cloneAsLive' />,
            onClick: () => { dispatch(cloneSource()); }
        });

        items.push({
            id: AUTOMATION_ID.SOURCE_CLONE_TEST_BTN,
            label: <FormattedMessage id='sources.cloneAsTest' />,
            onClick: () => { dispatch(cloneSource(true)); }
        });
    }

    if (showArchiveButton) {
        items.push({
            id: AUTOMATION_ID.SOURCE_ARCHIVE_BTN,
            label: <FormattedMessage id='common.archive' />,
            onClick: () => {
                handleClose();
                dispatch(confirmArchiveSource(ownerId, sourceId));
            },
            classname: local.negative
        });
    }

    if (showDeleteButton) {
        items.push({
            id: AUTOMATION_ID.SOURCE_DELETE_BTN,
            label: <FormattedMessage id='common.delete' />,
            onClick: () => {
                handleClose();
                dispatch(confirmDeleteSource(ownerId, sourceId));
            },
            classname: local.negative
        });
    }

    if (showDeleteDraftButton) {
        items.push({
            id: AUTOMATION_ID.SOURCE_DELETE_DRAFT_BTN,
            label: <FormattedMessage id='common.deleteDraft' />,
            onClick: () => { dispatch(deleteSourceVersion(ownerId, sourceId, sourceVersionId)); },
            classname: local.negative
        });
    }

    return (
        <div className={classnames(local.buttonsContainer, 'container-row')}>
            <Button
                id={AUTOMATION_ID.SOURCE_EDIT_BTN}
                label={<FormattedMessage id='common.edit' />}
                disabled={isOperationInProgress}
                condition={showEditButton}
                onClick={() => { dispatch(goToEditSourcePage(ownerId, sourceId, sourceVersionId)); }}
            />
            <Button
                id={AUTOMATION_ID.SOURCE_CANCEL_PENDING_UPGRADE_BTN}
                className='btn-negative-transparent'
                label={<FormattedMessage id='sources.cancelPendingUpgrade' />}
                disabled={isOperationInProgress}
                condition={showCancelPendingUpgradeButton}
                onClick={() => { dispatch(deletePendingSourceVersion(ownerId, sourceId, sourceVersionId)); }}
            />
            <Button
                id={AUTOMATION_ID.SOURCE_ADDITIONAL_ACTIONS_MENU_BTN}
                className='btn-transparent'
                label='...'
                disabled={isOperationInProgress}
                condition={Boolean(isLoaded && items.length)}
                onClick={handleMenuClick}
            />
            <Menu
                anchorEl={menuAnchorEl}
                keepMounted={true}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ paper: local.paper }}
            >
                {
                    items.map(({ id, label, onClick, classname }, index) => (
                        <MenuItem
                            id={id}
                            key={index}
                            onClick={onClick}
                            classes={{
                                root: classnames(local.item, classname),
                                focusVisible: local.focusedItem
                            }}
                        >
                            {label}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
};

export default SourceDetailsButtons;
