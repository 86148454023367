import React, { Component, ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';

import local from './local.module.scss';

interface TextWithTooltipProps {
    children: ReactNode;
}

interface TextWithTooltipState {
    hasOverflowingChildren: boolean;
    prevPropsChildren: ReactNode;
    text: string;
}

class TextWithTooltip extends Component<TextWithTooltipProps, TextWithTooltipState> {
    public static getDerivedStateFromProps(props, state) {
        return props.children !== state.prevPropsChildren ?
            {
                hasOverflowingChildren: false,
                prevPropsChildren: props.children
            } :
            null;
    }

    constructor(props) {
        super(props);

        this.state = {
            hasOverflowingChildren: false,
            prevPropsChildren: props.children,
            text: ''
        };
    }

    public render() {
        const { hasOverflowingChildren, text } = this.state;
        const { children } = this.props;

        return hasOverflowingChildren ?
            (
                <Tooltip
                    title={text}
                    classes={{ tooltip: local.tooltip }}
                >
                    <div className='ellipsis'>
                        {children}
                    </div>
                </Tooltip>
            ) :
            (
                <div className='ellipsis' onMouseEnter={this.updateOverflow}>
                    {children}
                </div>
            );
    }

    private updateOverflow = (event) => {
        const { target } = event;
        const element = target.closest instanceof Function ? target.closest('.ellipsis') : target;

        const { hasOverflowingChildren, text } = this.state;

        if (!hasOverflowingChildren && element.scrollWidth > element.clientWidth) {
            this.setState({ hasOverflowingChildren: true });

            if (element.textContent !== text) {
                this.setState({ text: element.textContent });
            }
        } else {
            this.setState({ hasOverflowingChildren: false });
        }
    }
}

export default TextWithTooltip;
