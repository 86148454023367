import * as t from '../actionTypes';

export const setCollectorCredentialsListItems = (data) => ({
    type: t.SET_CREDENTIALS_LIST_ITEMS,
    payload: data
});

export const appendCollectorCredentialsListItems = (data) => ({
    type: t.APPEND_CREDENTIALS_LIST_ITEMS,
    payload: data
});

export const setCollectorCredentialsListPage = (data) => ({
    type: t.SET_CREDENTIALS_LIST_PAGE,
    payload: data
});

export const setCollectorCredentialsListCount = (data) => ({
    type: t.SET_CREDENTIALS_LIST_COUNT,
    payload: data
});

export const setCollectorCredentialsListLoadStatus = (data) => ({
    type: t.SET_CREDENTIALS_LIST_LOAD_STATUS,
    payload: data
});

export const setCollectorCredentialsListSortColumn = (data) => ({
    type: t.SET_CREDENTIALS_LIST_SORT_COLUMN,
    payload: data
});

export const setCollectorCredentialsListSortOrder = (data) => ({
    type: t.SET_CREDENTIALS_LIST_SORT_ORDER,
    payload: data
});

export const setCollectorCredentialsListSearchText = (data) => ({
    type: t.SET_CREDENTIALS_LIST_SEARCH_TEXT,
    payload: data
});

export const resetCollectorCredentialsList = () => ({
    type: t.RESET_CREDENTIALS_LIST
});

export const setCollectorCredentialFormCollectors = (data) => ({
    type: t.SET_CREDENTIAL_FORM_COLLECTORS,
    payload: data
});

export const setCollectorCredentialFormCollectorsLoadStatus = (data) => ({
    type: t.SET_CREDENTIAL_FORM_COLLECTORS_LOAD_STATUS,
    payload: data
});

export const setCollectorCredentialFormManifest = (data) => ({
    type: t.SET_CREDENTIAL_FORM_MANIFEST,
    payload: data
});

export const setCollectorCredentialFormManifestLoadStatus = (data) => ({
    type: t.SET_CREDENTIAL_FORM_MANIFEST_LOAD_STATUS,
    payload: data
});

export const resetCollectorCredentialForm = () => ({
    type: t.RESET_CREDENTIAL_FORM
});

export const setCollectorCredentialFormOperationInProgress = (data) => ({
    type: t.SET_CREDENTIAL_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setCollectorCredentialDetailsLoadStatus = (data) => ({
    type: t.SET_CREDENTIAL_DETAILS_LOAD_STATUS,
    payload: data
});

export const setCollectorCredentialDetails = (data) => ({
    type: t.SET_CREDENTIAL_DETAILS,
    payload: data
});

export const resetCollectorCredentialDetails = () => ({
    type: t.RESET_CREDENTIAL_DETAILS
});
