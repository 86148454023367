import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import Input from 'modules/common/components/Input';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import { useAppDispatch } from 'modules/common/hooks';
import { closeAddMembershipsDialog } from '../../actions';
import { getGroupDetailsOperationInProgress, isAddMembershipsFormReadyForSubmission } from '../../selectors';

import local from './local.module.scss';

const InputFieldRenderer = ({ input, meta, disabled, placeholder }) => {
    const showError = meta.touched && meta.error;

    return (
        <Fragment>
            <Input
                inputProperties={input}
                disabled={disabled}
                placeholder={placeholder}
                className={classnames(local.textArea, { 'error-input': Boolean(showError) })}
                multiline={true}
            />
            {
                showError &&
                <div className={classnames(local.errorMessage, 'form-error-message')}>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

const AddMembershipsForm = ({ handleSubmit }) => {
    const dispatch = useAppDispatch();

    const close = useCallback(() => {
        dispatch(closeAddMembershipsDialog());
    }, []);

    const isOperationInProgress = useSelector(getGroupDetailsOperationInProgress);
    const isFormReadyForSubmission = useSelector(isAddMembershipsFormReadyForSubmission);

    const intl = useIntl();

    return (
        <Fragment>
            <form id='addMembershipsForm' onSubmit={handleSubmit} autoComplete='off'>
                <Field
                    name='usernames'
                    component={InputFieldRenderer}
                    disabled={isOperationInProgress}
                    placeholder={intl.formatMessage({ id: 'admin.addGroupMembershipsPlaceholder' })}
                />
            </form>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button disabled={isOperationInProgress} className='btn-flat' onClick={close}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <FormSubmitButton
                    form='addMembershipsForm'
                    width={130}
                    disabled={!isFormReadyForSubmission || isOperationInProgress}
                    text={<FormattedMessage id='common.submit' />}
                    isSubmitting={isOperationInProgress}
                />
            </div>
        </Fragment>
    );
};

export default AddMembershipsForm;
