import { connect } from 'react-redux';

import { getOwnersListProps } from '../../selectors';
import { goToOwnerMembershipsPage, loadMoreOwners, ownersListSort } from '../../actions';
import OwnersList from './OwnersList';

const mapDispatchToProps = {
    goToOwnerMembershipsPage,
    loadMore: loadMoreOwners,
    onSort: ownersListSort
};

export default connect(getOwnersListProps, mapDispatchToProps)(OwnersList);
