import { push } from 'redux-first-history';
import { SubmissionError } from 'redux-form';

import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import {
    formatString,
    getErrorText,
    normalizeManifest,
    prefixManifestErrors,
    showErrorSnackbar,
    prepareFormErrors,
    prepareFormValues
} from 'modules/common/utils';
import { intl } from 'modules/i18n';
import { getRouterPath } from 'modules/common/selectors';
import { goToCredentialsListPage } from 'modules/credentials/actions';
import { COLLECTOR_CREDENTIALS_TAB_INDEX } from 'modules/credentials/constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';
import { COLLECTOR_CREDENTIALS_ROUTES } from '../constants';

export const getCollectorCredentialsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setCollectorCredentialsListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const payload = selectors.getCollectorCredentialsListSearchPayload(getState());
        const list: ingestionApiDefinitions['ApiPaginatedCollectionContainerDtoCredentialSearchResponseDto'] = await service.api.getCollectorCredentialsSearchList(payload);

        dispatch(storeActions.appendCollectorCredentialsListItems(list.objects));
        dispatch(storeActions.setCollectorCredentialsListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setCollectorCredentialsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreCollectorCredentials = () => async (dispatch, getState) => {
    const page = selectors.getCollectorCredentialsListPage(getState()) + 1;

    dispatch(storeActions.setCollectorCredentialsListPage(page));
    await dispatch(getCollectorCredentialsList());

    service.analytics.trackEvent('Load more collector credentials', EVENT_CATEGORY.CREDENTIALS);
};

export const collectorCredentialsListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const currentSortColumn = selectors.getCollectorCredentialsListSortColumn(storeState);
    const currentSortOrder = selectors.getCollectorCredentialsListSortOrder(storeState);

    if (column === currentSortColumn) {
        const order = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setCollectorCredentialsListSortOrder(order));
    } else {
        dispatch(storeActions.setCollectorCredentialsListSortColumn(column));

        if (currentSortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setCollectorCredentialsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setCollectorCredentialsListPage(0));
    dispatch(storeActions.setCollectorCredentialsListItems([]));
    dispatch(storeActions.setCollectorCredentialsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('Collector credentials list sort', EVENT_CATEGORY.CREDENTIALS);
};

export const collectorCredentialsListSearch = (text) => (dispatch, getState) => {
    const currentSearchText = selectors.getCollectorCredentialsListSearchText(getState());

    if (text !== currentSearchText) {
        dispatch(storeActions.setCollectorCredentialsListPage(0));
        dispatch(storeActions.setCollectorCredentialsListItems([]));
        dispatch(storeActions.setCollectorCredentialsListSearchText(text));
        dispatch(storeActions.setCollectorCredentialsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('Collector credentials list search', EVENT_CATEGORY.CREDENTIALS);
    }
};

export const goToCollectorCredentialDetailsPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(COLLECTOR_CREDENTIALS_ROUTES.DETAILS, ownerId, id)));
};

export const goToEditCollectorCredentialPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(COLLECTOR_CREDENTIALS_ROUTES.EDIT, ownerId, id)));
};

export const goToAddCollectorCredentialPage = () => (dispatch) => {
    dispatch(push(COLLECTOR_CREDENTIALS_ROUTES.NEW));
};

export const goToCollectorCredentialsListPage = () => (dispatch) => {
    dispatch(goToCredentialsListPage(COLLECTOR_CREDENTIALS_TAB_INDEX));
};

export const getCollectorsList = () => async (dispatch) => {
    dispatch(storeActions.setCollectorCredentialFormCollectorsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response: ingestionApiDefinitions['ApiCollectionContainerDtoCollectorSummaryResponseDto'] = await service.api.getCollectorsList();
        dispatch(storeActions.setCollectorCredentialFormCollectors(
            response.objects?.sort(({ description: name1 }, { description: name2 }) => (name1 > name2 ? 1 : -1)))
        );
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setCollectorCredentialFormCollectorsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getCollectorCredentialManifest = (collectorId) => async (dispatch) => {
    dispatch(storeActions.setCollectorCredentialFormManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const manifest = await service.api.getCollectorCredentialManifest(collectorId);
        dispatch(storeActions.setCollectorCredentialFormManifest(normalizeManifest(manifest)));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setCollectorCredentialFormManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

const validateCollectorCredentialFormValues = (formValues) => {
    const requiredFields = [ 'name', 'ownerId', 'collectorId' ];
    const errors = {};

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

export const addCollectorCredential = (formValues) => async (dispatch, getState) => {
    const errors = validateCollectorCredentialFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setCollectorCredentialFormOperationInProgress(true));

    try {
        const manifest = selectors.getCollectorCredentialFormManifest(getState());
        const { name, ownerId, collectorId, ...collectorConfigurations } = formValues;
        const credentialContext = collectorConfigurations[collectorId];

        const credentialDetails: ingestionApiDefinitions['CredentialDetailResponseDto'] = await service.api.addCollectorCredential(
            ownerId,
            {
                name,
                collectorId,
                credentialContext: prepareFormValues(manifest, credentialContext)
            }
        );

        service.analytics.trackEvent('Create collector credential', EVENT_CATEGORY.CREDENTIALS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === COLLECTOR_CREDENTIALS_ROUTES.NEW) {
            dispatch(goToCollectorCredentialDetailsPage(credentialDetails.id, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            // TODO remove following line when manifest validation error field will be prefixed with collector ID
            data.validationErrors = prefixManifestErrors(data.validationErrors, formValues.collectorId);

            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setCollectorCredentialFormOperationInProgress(false));
    }
};

export const getCollectorCredentialDetails = (ownerId, credentialId) => async (dispatch) => {
    dispatch(storeActions.setCollectorCredentialDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details = await service.api.getCollectorCredentialDetails(ownerId, credentialId);
        dispatch(storeActions.setCollectorCredentialDetails(details));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setCollectorCredentialDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getCollectorCredentialDataForEditing = (ownerId, credentialId) => async (dispatch) => {
    dispatch(storeActions.setCollectorCredentialDetailsLoadStatus(LOAD_STATUS.LOADING));
    dispatch(storeActions.setCollectorCredentialFormManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details = await service.api.getCollectorCredentialDetails(ownerId, credentialId);
        const manifest = await service.api.getCollectorCredentialManifest(details.collectorId);
        dispatch(storeActions.setCollectorCredentialDetails(details));
        dispatch(storeActions.setCollectorCredentialFormManifest(manifest));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setCollectorCredentialDetailsLoadStatus(LOAD_STATUS.LOADED));
        dispatch(storeActions.setCollectorCredentialFormManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const editCollectorCredential = (credentialId, formValues) => async (dispatch, getState) => {
    const errors = validateCollectorCredentialFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setCollectorCredentialFormOperationInProgress(true));

    try {
        const manifest = selectors.getCollectorCredentialFormManifest(getState());
        const { name, ownerId, collectorId, ...collectorConfigurations } = formValues;
        const credentialContext = collectorConfigurations[collectorId];

        const credentialDetails: ingestionApiDefinitions['CredentialDetailResponseDto'] = await service.api.editCollectorCredential(
            ownerId,
            credentialId,
            {
                name,
                credentialContext: prepareFormValues(manifest, credentialContext)
            }
        );

        service.analytics.trackEvent('Edit collector credential', EVENT_CATEGORY.CREDENTIALS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(COLLECTOR_CREDENTIALS_ROUTES.EDIT, ownerId, credentialId)) {
            dispatch(goToCollectorCredentialDetailsPage(credentialDetails.id, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            // TODO remove following line when manifest validation error field will be prefixed with collector ID
            data.validationErrors = prefixManifestErrors(data.validationErrors, formValues.collectorId);

            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setCollectorCredentialFormOperationInProgress(false));
    }
};
