import { connect } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';

import { withRouteParams } from 'modules/common/hoc';
import {
    addCollectorCredential,
    editCollectorCredential,
    goToCollectorCredentialDetailsPage,
    goToCollectorCredentialsListPage
} from '../../actions';
import { getCollectorCredentialFormLayoutProps } from '../../selectors';
import CollectorCredentialFormLayout from './CollectorCredentialFormLayout';

const mapDispatchToProps = {
    goToCollectorCredentialsListPage,
    goToCollectorCredentialDetailsPage,
    addCollectorCredential,
    editCollectorCredential,
    clearSubmitErrors
};

export default withRouteParams(connect(getCollectorCredentialFormLayoutProps, mapDispatchToProps)(CollectorCredentialFormLayout));
