import { formatString } from 'modules/common/utils';
import { TARGETS_ROUTES } from './constants';
import TargetsListLayout from './components/TargetsListLayout';
import TargetDetailsLayout from './components/TargetDetailsLayout';
import TargetFormLayout from './components/TargetFormLayout';

export default [
    {
        path: TARGETS_ROUTES.LIST,
        component: TargetsListLayout
    },
    {
        path: formatString(TARGETS_ROUTES.DETAILS, ':ownerId', ':targetId'),
        component: TargetDetailsLayout
    },
    {
        path: TARGETS_ROUTES.NEW,
        component: TargetFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(TARGETS_ROUTES.EDIT, ':ownerId', ':targetId'),
        component: TargetFormLayout,
        params: {
            isEditMode: true
        }
    }
];
