import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ingestionApiDefinitions } from 'modules/service/types';
import { SOURCE_STATE } from 'modules/sources/constants';
import { LoadStatus } from 'modules/common/interfaces';
import { LOAD_STATUS } from 'modules/common/constants';
import RadioSwitch from 'modules/common/components/RadioSwitch';
import FeedSchedule from '../FeedSchedule';
import { FEED_SCHEDULES_MAX_COUNT, METHOD_SWITCH_OPTIONS_VALUES } from '../../constants';

import local from '../FeedFormLayout/local.module.scss';

interface DataCollectionSectionProps {
    getCollectionOffsetTemplate: Function;
    isOperationInProgress: boolean;
    isEditMode: boolean;
    data: ingestionApiDefinitions['FeedVersionDetailResponseDto'];
    dataLoadStatus: LoadStatus;
    method?: typeof METHOD_SWITCH_OPTIONS_VALUES[keyof typeof METHOD_SWITCH_OPTIONS_VALUES];
    change: Function;
    collectionOffsetTemplate: Array<ingestionApiDefinitions['CollectionOffsetTemplateDetailResponseDto']>;
    collectionOffsetTemplateLoadStatus: LoadStatus;
    schedules: Array<{ id: string }>;
    addFeedSchedule: Function;
    clearFeedSchedules: Function;
    removeSubmitError: Function;
    sourceState: typeof SOURCE_STATE[keyof typeof SOURCE_STATE];
}

class DataCollectionSection extends Component<DataCollectionSectionProps> {
    private METHOD_SWITCH_OPTIONS = [
        {
            name: <FormattedMessage id='common.schedule' />,
            value: METHOD_SWITCH_OPTIONS_VALUES.SCHEDULE
        },
        {
            name: <FormattedMessage id='common.manual' />,
            value: METHOD_SWITCH_OPTIONS_VALUES.MANUAL
        }
    ];

    public componentDidMount() {
        const { getCollectionOffsetTemplate, collectionOffsetTemplateLoadStatus } = this.props;

        if (collectionOffsetTemplateLoadStatus === LOAD_STATUS.REQUIRED) {
            getCollectionOffsetTemplate();
        }
    }

    public componentDidUpdate(prevProps) {
        const { data, change, isEditMode } = this.props;

        if (isEditMode) {
            const { schedules } = data;
            const { data: { schedules: prevSchedules } } = prevProps;

            if (schedules !== prevSchedules) {
                if (schedules?.length) {
                    change('method', METHOD_SWITCH_OPTIONS_VALUES.SCHEDULE);
                } else {
                    change('method', METHOD_SWITCH_OPTIONS_VALUES.MANUAL);
                }
            }
        }
    }

    public render() {
        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='feeds.dataCollection' />
                </div>
                {this.renderMethodSwitch()}
                {this.renderSchedules()}
            </Fragment>
        );
    }

    private renderSchedules = () => {
        const {
            isEditMode,
            isOperationInProgress,
            method,
            dataLoadStatus,
            schedules,
            change,
            sourceState,
            collectionOffsetTemplate,
            collectionOffsetTemplateLoadStatus
        } = this.props;

        const disabled = isOperationInProgress || (isEditMode && dataLoadStatus !== LOAD_STATUS.LOADED) ||
            schedules.length >= FEED_SCHEDULES_MAX_COUNT;

        if (method === METHOD_SWITCH_OPTIONS_VALUES.MANUAL) {
            return null;
        }

        return (
            <Fragment>
                {
                    sourceState === SOURCE_STATE.TEST &&
                    <div>
                        <div className={local.schedulesWarning}>
                            <FormattedMessage id='feeds.schedulesWarningMessage' />
                        </div>
                    </div>
                }
                {
                    schedules.map((schedule, index) =>
                        <FeedSchedule
                            key={schedule.id}
                            index={index}
                            fieldPrefix={`schedules.schedule_${index}`}
                            isOperationInProgress={isOperationInProgress}
                            isEditMode={isEditMode}
                            change={change}
                            collectionOffsetTemplate={collectionOffsetTemplate}
                            collectionOffsetTemplateLoadStatus={collectionOffsetTemplateLoadStatus}
                        />
                    )
                }
                <Field name='schedules' component={this.renderSchedulesError} />
                <div className={classnames('plus-button', 'ls-button')}>
                    <button disabled={disabled} className='btn-transparent' onClick={this.addNewSchedule}>
                        <FontAwesomeIcon icon={faPlus} />
                        <FormattedMessage id='feeds.addSchedule' />
                    </button>
                </div>
            </Fragment>
        );
    }

    private renderSchedulesError = ({ meta }) => (
        <Fragment>
            {
                // ignore error if it belongs to nested field
                meta.error && (typeof meta.error === 'string') &&
                <div className={local.globalError}>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    )

    private addNewSchedule = (event) => {
        const { removeSubmitError, addFeedSchedule } = this.props;

        event.preventDefault();
        removeSubmitError({
            form: 'feedForm',
            field: 'schedules'
        });
        addFeedSchedule();
    }

    private renderMethodSwitch = () => {
        const { isOperationInProgress, isEditMode, dataLoadStatus } = this.props;

        return (
            <Fragment>
                <div className={local.label}>
                    <FormattedMessage id='common.method' />
                </div>
                <RadioSwitch
                    fieldName='method'
                    options={this.METHOD_SWITCH_OPTIONS}
                    classes={{
                        switch: local.switchContainer,
                        option: local.option
                    }}
                    disabled={isOperationInProgress || (isEditMode && dataLoadStatus !== LOAD_STATUS.LOADED)}
                    onChange={this.onMethodChange}
                />
            </Fragment>
        );
    }

    private onMethodChange = (value) => {
        const { change, clearFeedSchedules } = this.props;

        if (value === METHOD_SWITCH_OPTIONS_VALUES.MANUAL) {
            clearFeedSchedules();
            change('schedules', undefined);
        }
    }
}

export default DataCollectionSection;
