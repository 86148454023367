import React, { Fragment, FunctionComponent } from 'react';
import { InjectedFormProps } from 'redux-form';

import AdverityDynamicField from '../AdverityDynamicField';

interface AdverityDynamicFieldsSectionProps {
    change: InjectedFormProps['change'];
    config: { [key: string]: object };
    disabled: boolean;
    prefix: string;
}

const AdverityDynamicFieldsSection: FunctionComponent<AdverityDynamicFieldsSectionProps> = ({
    change,
    config,
    disabled,
    prefix
}) => (
    <Fragment>
        {
            Object.keys(config).map((key) => (
                <AdverityDynamicField
                    key={key}
                    config={{ id: key, ...config[key] }}
                    prefix={prefix}
                    disabled={disabled}
                    change={change}
                />
            ))
        }
    </Fragment>
);

export default AdverityDynamicFieldsSection;
