import * as t from '../actionTypes';

export const setMembersListItems = (data) => ({
    type: t.SET_MEMBERS_LIST_ITEMS,
    payload: data
});

export const appendMembersListItems = (data) => ({
    type: t.APPEND_MEMBERS_LIST_ITEMS,
    payload: data
});

export const setMembersListPage = (data) => ({
    type: t.SET_MEMBERS_LIST_PAGE,
    payload: data
});

export const setMembersListCount = (data) => ({
    type: t.SET_MEMBERS_LIST_COUNT,
    payload: data
});

export const setMembersListLoadStatus = (data) => ({
    type: t.SET_MEMBERS_LIST_LOAD_STATUS,
    payload: data
});

export const setMembersListSortColumn = (data) => ({
    type: t.SET_MEMBERS_LIST_SORT_COLUMN,
    payload: data
});

export const setMembersListSortOrder = (data) => ({
    type: t.SET_MEMBERS_LIST_SORT_ORDER,
    payload: data
});

export const setMembersListSearchText = (data) => ({
    type: t.SET_MEMBERS_LIST_SEARCH_TEXT,
    payload: data
});

export const resetMembersList = () => ({
    type: t.RESET_MEMBERS_LIST
});

export const setMembersListOperationInProgress = (data) => ({
    type: t.SET_MEMBERS_LIST_OPERATION_IN_PROGRESS,
    payload: data
});

export const openMemberDialog = () => ({
    type: t.OPEN_MEMBER_DIALOG
});

export const closeMemberDialog = () => ({
    type: t.CLOSE_MEMBER_DIALOG
});

export const setMemberDetails = (data) => ({
    type: t.SET_MEMBER_DETAILS,
    payload: data
});

export const setMemberDetailsLoadStatus = (data) => ({
    type: t.SET_MEMBER_DETAILS_LOAD_STATUS,
    payload: data
});

export const resetMemberDetails = () => ({
    type: t.RESET_MEMBER_DETAILS
});

export const setMemberDetailsOperationInProgress = (data) => ({
    type: t.SET_MEMBER_DETAILS_OPERATION_IN_PROGRESS,
    payload: data
});

export const addOwnerMemberRole = (data) => ({
    type: t.ADD_OWNER_MEMBER_ROLE,
    payload: data
});

export const removeOwnerMemberRole = (data) => ({
    type: t.REMOVE_OWNER_MEMBER_ROLE,
    payload: data
});

export const removeOwnerMembership = (data) => ({
    type: t.REMOVE_OWNER_MEMBERSHIP,
    payload: data
});

export const addClientMemberRole = (data) => ({
    type: t.ADD_CLIENT_MEMBER_ROLE,
    payload: data
});

export const removeClientMemberRole = (data) => ({
    type: t.REMOVE_CLIENT_MEMBER_ROLE,
    payload: data
});

export const removeClientMembership = (data) => ({
    type: t.REMOVE_CLIENT_MEMBERSHIP,
    payload: data
});

export const openOwnerMembershipDialog = () => ({
    type: t.OPEN_OWNER_MEMBERSHIP_DIALOG
});

export const closeOwnerMembershipDialog = () => ({
    type: t.CLOSE_OWNER_MEMBERSHIP_DIALOG
});

export const openClientMembershipDialog = () => ({
    type: t.OPEN_CLIENT_MEMBERSHIP_DIALOG
});

export const closeClientMembershipDialog = () => ({
    type: t.CLOSE_CLIENT_MEMBERSHIP_DIALOG
});

export const setGroupsList = (data) => ({
    type: t.SET_GROUPS_LIST,
    payload: data
});

export const openGroupMembershipDialog = () => ({
    type: t.OPEN_GROUP_MEMBERSHIP_DIALOG
});

export const closeGroupMembershipDialog = () => ({
    type: t.CLOSE_GROUP_MEMBERSHIP_DIALOG
});
