import { reduxForm } from 'redux-form';
import moment from 'moment';

import FeedAdHocRunForm from './FeedAdHocRunForm';
import { AD_HOC_RUN_REPORT_RANGE } from '../../constants';

export default reduxForm({
    form: 'feedAdHocRunForm',
    initialValues: {
        reportRangeType: AD_HOC_RUN_REPORT_RANGE.COLLECTION_OFFSET_TEMPLATE,
        collectionStartDate: moment(),
        collectionEndDate: moment()
    }
})(FeedAdHocRunForm);
