import React, { Component, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Tab from './Tab';
import TabWithSubItems from './TabWithSubItems';

import local from './local.module.scss';

interface TabsProps {
    tabs: any[];
    tabIndex: number;
    tabSubIndex: number;
}

interface TabsState {
    pointer: {
        top?: string;
        left?: string;
        width?: string;
    };
}

export default class Tabs extends Component<PropsWithChildren<TabsProps>, TabsState> {
    private navigationNode: HTMLElement | null = null;
    private updatePointerAnimationFrame: number = 0;
    private resizeTimeout: number = 0;

    constructor(props) {
        super(props);

        this.state = {
            pointer: {}
        };
    }

    public componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        this.updatePointer(this.props.tabIndex);
    }

    public componentDidUpdate(prevProps) {
        const { tabIndex, children } = this.props;
        const { tabIndex: prevIndex, children: prevChildren } = prevProps;

        if (tabIndex !== prevIndex || children !== prevChildren) {
            this.updatePointer(tabIndex);
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.clearTimeout(this.resizeTimeout);

        if (this.updatePointerAnimationFrame) {
            window.cancelAnimationFrame(this.updatePointerAnimationFrame);
        }
    }

    public render() {
        return (
            <nav
                className={classnames(local.navigation, 'container-row')}
                ref={(node) => { this.navigationNode = node; }}
            >
                {this.renderTabs()}
                <span className={local.pointer} style={this.state.pointer} />
            </nav>
        );
    }

    private updatePointer = (index: number) => {
        // hide pointer if tab was not found
        if (index === -1) {
            this.setState({ pointer: {} });

            return;
        }

        if (this.navigationNode && this.navigationNode.children[index]) {
            this.updatePointerAnimationFrame = window.requestAnimationFrame(() => {
                // additional check to avoid TS2531 errors
                if (this.navigationNode) {
                    const nav = this.navigationNode.getBoundingClientRect();
                    const link = this.navigationNode.children[index].getBoundingClientRect();
                    const { scrollLeft } = this.navigationNode;

                    this.setState({
                        pointer: {
                            top: `${nav.height}px`,
                            left: `${(link.left + scrollLeft) - nav.left}px`,
                            width: `${link.width}px`
                        }
                    });
                }
            });
        }
    }

    private handleResize = () => {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }

        this.resizeTimeout = window.setTimeout(() => {
            this.updatePointer(this.props.tabIndex);
        }, 100);
    }

    private renderTabs = () => {
        const { tabs, tabIndex, tabSubIndex } = this.props;

        return tabs.map((tabProps, index) => (
            tabProps.subItems ?
                <TabWithSubItems
                    {...tabProps}
                    key={tabProps.id}
                    selectedSubItemIndex={index === tabIndex ? tabSubIndex : -1}
                /> :
                <Tab {...tabProps} key={tabProps.id} />
        ));
    }
}
