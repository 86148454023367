import { formatString } from 'modules/common/utils';
import { INSTANCES_ROUTES } from './constants';
import InstancesListLayout from './components/InstancesListLayout';
import InstanceDetailsLayout from './components/InstanceDetailsLayout';
import InstanceFormLayout from './components/InstanceFormLayout';

export default [
    {
        path: INSTANCES_ROUTES.LIST,
        component: InstancesListLayout
    },
    {
        path: formatString(INSTANCES_ROUTES.DETAILS, ':clientId', ':instanceId'),
        component: InstanceDetailsLayout
    },
    {
        path: formatString(INSTANCES_ROUTES.EDIT, ':clientId', ':instanceId'),
        component: InstanceFormLayout,
        params: {
            isEditMode: true
        }
    },
    {
        path: INSTANCES_ROUTES.NEW,
        component: InstanceFormLayout,
        params: {
            isEditMode: false
        }
    }
];
