import React, { FunctionComponent } from 'react';

import { SVGIconProps } from 'modules/common/interfaces';

const SnapshotIcon: FunctionComponent<SVGIconProps> = ({ className }) => (
    <svg className={className} width='100%' height='100%' viewBox='0 0 18 18' preserveAspectRatio='xMidYMid meet' focusable='false'>
        <path
            fill='currentColor'
            fillRule='evenodd'
            d='M9 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM3 3.007A1.01 1.01 0 0 1 4.002 2h9.996C14.55 2 15 2.45 15 3.007v11.986A1.01 1.01 0 0 1 13.998 16H4.002C3.45 16 3 15.55 3 14.993V3.007zM5 4h8v10H5V4zm1 8.5a.5.5 0 0 1 .49-.5h5.02a.5.5 0 0 1 0 1H6.49a.5.5 0 0 1-.49-.5zM9 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z'
        />
    </svg>
);

export default SnapshotIcon;
