import { MANIFEST_FIELD_TYPE, OVERRIDABLE_FIELD_SUFFIX, SECRET_VALUE_PLACEHOLDER } from 'modules/common/constants';

const getManifestPasswordFields = (manifest) => {
    const passwordFields: string[] = [];

    manifest.panels?.forEach(({ id: panelId, fields }) => {
        fields?.forEach(({ id: fieldId, type }) => {
            if (type === MANIFEST_FIELD_TYPE.PASSWORD) {
                passwordFields.push(`${panelId}.${fieldId}`);
            }
        });
    });

    return passwordFields;
};

export const prepareFormValues = (manifest, values, plain = true) => {
    const processedValues = {};

    for (const panelId in values) {
        if (values.hasOwnProperty(panelId)) {
            if (values[panelId] instanceof Object) {
                for (const fieldId in values[panelId]) {
                    if (values[panelId].hasOwnProperty(fieldId)) {
                        const key = `${panelId}.${fieldId}`.replace(`-${OVERRIDABLE_FIELD_SUFFIX}`, '');
                        const value = values[panelId][fieldId];

                        if (plain) {
                            processedValues[key] = value;
                        } else {
                            if (!processedValues[key]) {
                                processedValues[key] = {};
                            }

                            if (fieldId.endsWith(OVERRIDABLE_FIELD_SUFFIX)) {
                                processedValues[key].overridable = value;
                            } else {
                                processedValues[key].value = value;
                            }
                        }
                    }
                }
            }
        }
    }

    // in order to save entity correctly, we need to explicitly set values for all password fields
    // if value is absent, it means input is empty, and we need to set an empty string
    // if value is equal to placeholder, we need to skip it to let BE know that previous value should be persisted
    getManifestPasswordFields(manifest).forEach((key) => {
        if (plain) {
            if (processedValues[key] === undefined) {
                processedValues[key] = '';
            } else if (processedValues[key] === SECRET_VALUE_PLACEHOLDER) {
                delete processedValues[key];
            }
        } else {
            const object = processedValues[key];

            if (object) {
                if (object.value === undefined) {
                    object.value = '';
                } else if (object.value === SECRET_VALUE_PLACEHOLDER) {
                    delete object.value;
                }
            }
        }
    });

    return processedValues;
};

export const prepareFormValuesAsArray = (manifest, values) => {
    const processedValues: Array<{ key: string; value: any }> = [];

    for (const panelId in values) {
        if (values.hasOwnProperty(panelId)) {
            if (values[panelId] instanceof Object) {
                for (const fieldId in values[panelId]) {
                    if (values[panelId].hasOwnProperty(fieldId)) {
                        const key = `${panelId}.${fieldId}`;
                        const value = values[panelId][fieldId];

                        processedValues.push({ key, value });
                    }
                }
            }
        }
    }

    // in order to save entity correctly, we need to explicitly set values for all password fields
    // if value is absent, it means input is empty, and we need to set an empty string
    // if value is equal to placeholder, we need to skip it to let BE know that previous value should be persisted
    getManifestPasswordFields(manifest).forEach((passwordFieldKey) => {
        const index = processedValues.findIndex(({ key }) => key === passwordFieldKey);

        if (index !== -1) {
            const object = processedValues[index];

            if (object.value === undefined) {
                object.value = '';
            } else if (object.value === SECRET_VALUE_PLACEHOLDER) {
                processedValues.splice(index, 1);
            }
        } else {
            processedValues.push({ key: passwordFieldKey, value: '' });
        }
    });

    return processedValues;
};

export const normalizeManifest = ({ panels }) => {
    if (Array.isArray(panels)) {
        panels.sort(({ position: pos1 }, { position: pos2 }) => pos1 - pos2);

        panels.forEach(({ fields }) => {
            if (Array.isArray(fields)) {
                fields.sort(({ position: pos1 }, { position: pos2 }) => pos1 - pos2);
            }
        });
    }

    return { panels };
};

export const ERROR_ALL_FIELDS = '*';

export const prepareFormErrors = ({ validationErrors }) => {
    const errors = {
        _error: null
    };

    // For validation errors, which descriptions are dynamic, we cannot
    // show hardcoded messages from translations map, we need to pass full
    // error descriptions to the form component.
    const dynamicErrorsList = [
        'VLD000',
        'VLD001',
        'VLD003',
        'MMB001',
        'TRG005',
        'TRG006',
        'ADV001',
        'ADV002'
    ];

    if (Array.isArray(validationErrors)) {
        validationErrors.forEach(({ field, code, description }) => {
            if (field === ERROR_ALL_FIELDS) {
                errors._error = dynamicErrorsList.includes(code) && description ? description : code;

                return;
            }

            const keys = field.split('.');
            let currErrorsObject = errors;

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];

                if (i === keys.length - 1) {
                    currErrorsObject[key] = dynamicErrorsList.includes(code) && description ? description : code;
                } else {
                    if (!currErrorsObject[key]) {
                        currErrorsObject[key] = {};
                    }

                    currErrorsObject = currErrorsObject[key];
                }
            }
        });
    }

    return errors;
};

export const prefixManifestErrors = (validationErrors, prefix) => {
    const manifestErrorPrefix = 'MNF';

    validationErrors = validationErrors.map(({ code, field, description }) => ({
        code,
        description,
        field: code.startsWith(manifestErrorPrefix) ? `${prefix}.${field}` : field
    }));

    return validationErrors;
};
