import { reduxForm } from 'redux-form';

import InstanceAdHocRunForm from './InstanceAdHocRunForm';

export default reduxForm({
    form: 'instanceAdHocRunForm',
    initialValues: {
        producesFullDataset: false,
        recreateTable: false
    }
})(InstanceAdHocRunForm);
