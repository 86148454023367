import React, { FunctionComponent, useState } from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Moment } from 'moment';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';

import local from './local.module.scss';

type MonthPickerProps = Partial<MuiDatePickerProps<Moment, Moment>> & Pick<MuiDatePickerProps<Moment, Moment>, 'value' | 'onChange'>;

const VIEW = {
    MONTH: 'month',
    YEAR: 'year'
} as const;

const ArrowDownIcon: FunctionComponent = () => (
    <span className={local.icon}>
        <FontAwesomeIcon icon={faChevronDown} />
    </span>
);

const ArrowUpIcon: FunctionComponent = () => (
    <span className={local.icon}>
        <FontAwesomeIcon icon={faChevronUp} />
    </span>
);

const MonthPicker: FunctionComponent<MonthPickerProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [view, setView] = useState<string>(VIEW.MONTH);

    const intl = useIntl();

    return (
        <MuiDatePicker
            {...props}
            open={open}
            openTo={VIEW.MONTH}
            inputFormat='MMMM yyyy'
            onClose={() => {
                setOpen(false);
                setView(VIEW.MONTH);
            }}
            onChange={(value) => {
                if (view === VIEW.YEAR) {
                    props.onChange(value);
                }
            }}
            onViewChange={(newView) => { setView(newView); }}
            renderInput={(textFieldProps) => (
                <TextField
                    {...textFieldProps}
                    inputProps={{
                        ...textFieldProps.inputProps,
                        placeholder: intl.formatMessage({ id: 'common.selectMonth' }),
                        readOnly: true
                    }}
                    onClick={() => { setOpen(true); }}
                    classes={{ root: props.disabled ? local.textInputDisabled : '' }}
                />
            )}
            components={{ OpenPickerIcon: open ? ArrowUpIcon : ArrowDownIcon }}
            views={[ 'month', 'year' ]}
            disableMaskedInput={true}
            InputProps={{ classes: { root: local.datepickerInput } }}
        />
    );
};

export default MonthPicker;
