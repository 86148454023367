import React, { Fragment, FunctionComponent } from 'react';
import classnames from 'classnames';
import { WrappedFieldProps } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { removeSyncError } from 'modules/form/actions';
import { LOAD_STATUS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import { LoadStatus } from 'modules/common/interfaces';
import Input from 'modules/common/components/Input';

import local from './local.module.scss';

type NameFieldProps = WrappedFieldProps & {
    isEditMode: boolean;
    disabled: boolean;
    dataLoadStatus: LoadStatus;
};

export const NameFieldRenderer: FunctionComponent<NameFieldProps> = ({
    input,
    meta,
    disabled,
    isEditMode,
    dataLoadStatus
}) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const placeholderMessageId = isEditMode && dataLoadStatus !== LOAD_STATUS.LOADED ? 'common.loading' : 'common.name';

    return (
        <Fragment>
            <Input
                inputProperties={{
                    ...input,
                    onChange: (value) => {
                        if (meta.error) {
                            dispatch(removeSyncError({
                                form: 'reportForm',
                                field: input.name
                            }));
                        }

                        input.onChange(value);
                    }
                }}
                disabled={disabled}
                maxLength={255}
                className={classnames({ 'error-input': Boolean(meta.error) }, local.nameInput)}
                placeholder={intl.formatMessage({ id: placeholderMessageId })}
            />
            {
                meta.error ?
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div> :
                    !isEditMode ?
                        <div className={local.warning}>
                            <FormattedMessage id='reports.valueCannotBeChanged' />
                        </div> : null
            }
        </Fragment>
    );
};

export const PlatformsErrorFieldRenderer: FunctionComponent<WrappedFieldProps> = ({ meta }) => (
    <Fragment>
        {
            // ignore error if it belongs to nested field
            meta.error && (typeof meta.error === 'string') &&
            <div className={local.platformsError}>
                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
            </div>
        }
    </Fragment>
);
