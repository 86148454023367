import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import MemberForm from '../MemberForm';
import { getIsMemberDialogOpen } from '../../selectors';
import { closeMemberDialog, addMember } from '../../actions';

const MemberDialog = () => {
    const dispatch = useAppDispatch();
    const isMemberDialogOpen = useSelector(getIsMemberDialogOpen);

    const onSubmit = useCallback((formValues) => dispatch(addMember(formValues)), []);
    const onClose = useCallback(() => dispatch(closeMemberDialog()), []);

    return (
        <Dialog
            open={isMemberDialogOpen}
            title={<FormattedMessage id='admin.newMember' />}
            onClose={onClose}
        >
            <MemberForm onSubmit={onSubmit} />
        </Dialog>
    );
};

export default MemberDialog;
