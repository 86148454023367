export const REDUCER_NAME = 'dataCatalog';

export const CATALOG_ROUTES = {
    LIST: '/data-catalog'
};

export const SCHEMA_FIELD_TYPE = {
    BYTES: 'BYTES',
    STRING: 'STRING',
    INTEGER: 'INTEGER',
    FLOAT: 'FLOAT',
    NUMERIC: 'NUMERIC',
    BIGNUMERIC: 'BIGNUMERIC',
    BOOLEAN: 'BOOLEAN',
    TIMESTAMP: 'TIMESTAMP',
    DATE: 'DATE',
    GEOGRAPHY: 'GEOGRAPHY',
    TIME: 'TIME',
    DATETIME: 'DATETIME',
    RECORD: 'RECORD'
};

export const SCHEMA_FIELD_MODE = {
    REQUIRED: 'REQUIRED',
    NULLABLE: 'NULLABLE',
    REPEATED: 'REPEATED'
};

export const CATALOG_ITEM_TYPE = {
    DATASET: 'DATASET',
    TABLE: 'TABLE',
    VIEW: 'VIEW',
    SNAPSHOT: 'SNAPSHOT',
    EXTERNAL: 'EXTERNAL',
    MODEL: 'MODEL',
    MATERIALIZED_VIEW: 'MATERIALIZED_VIEW'
} as const;
