import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import { intl } from 'modules/i18n';

const IntlProviderWrapper: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => (
    <IntlProvider locale={intl.locale} messages={intl.messages}>
        {children}
    </IntlProvider>
);

export default IntlProviderWrapper;
