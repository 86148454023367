export const REDUCER_NAME = 'members';
export const MEMBERS_LIST_PAGE_SIZE = 50;

export const MEMBERS_ROUTES = {
    LIST: '/admin/members',
    DETAILS: '/admin/members/view/{0}'
};

export const MEMBERS_LIST_SORT_ATTRIBUTES_MAP = {
    username: 'USER_NAME',
    createTimestamp: 'CREATE_TIME',
    updateTimestamp: 'UPDATE_TIME'
};
