import React, { FunctionComponent } from 'react';

import { SVGIconProps } from 'modules/common/interfaces';

const ViewIcon: FunctionComponent<SVGIconProps> = ({ className }) => (
    <svg className={className} width='100%' height='100%' viewBox='0 0 18 18' preserveAspectRatio='xMidYMid meet' focusable='false'>
        <path
            fill='currentColor'
            d='M4 4H2V2h2zm12 0h-2V2h2zM4 12H2v-2h2zm8 0h-2v-2h2zm-4 0H6v-2h2zm8 0h-2v-2h2zM4 8H2V6h2zm8 0h-2V6h2zM8 8H6V6h2zm8 0h-2V6h2zM4 16H2v-2h2zm8 0h-2v-2h2zm-4 0H6v-2h2z'
        />
    </svg>
);

export default ViewIcon;
