import * as t from '../actionTypes';

const ACTION_HANDLERS = {
    [t.SET_JWT_DATA]: (state, action) => ({
        ...state,
        tokens: action.payload.tokens
    }),
    [t.SET_USER_PROFILE]: (state, action) => ({
        ...state,
        userProfile: action.payload.userProfile,
        clientsList: action.payload.clientsList
    }),
    [t.SET_SELECTED_CLIENT]: (state, action) => ({
        ...state,
        selectedClientId: action.payload
    }),
    [t.SET_DATA_LOADED]: (state, action) => ({
        ...state,
        isUserDataLoaded: action.payload
    }),
    [t.SET_ACCESS_ALLOWED]: (state, action) => ({
        ...state,
        isUserAccessAllowed: action.payload
    }),
    [t.SET_CLIENT_TO_CHECK_ON_LOAD]: (state, action) => ({
        ...state,
        clientIdToCheckOnLoad: action.payload
    })
};

export const initialState = {
    isUserDataLoaded: false,
    isUserAccessAllowed: false,
    userProfile: {
        id: null,
        username: null,
        owners: [],
        clients: [],
        odpMemberAdministrator: false
    },
    tokens: {},
    clientsList: [],
    selectedClientId: null,
    clientIdToCheckOnLoad: null
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
