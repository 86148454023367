// common
export const SET_AUTHORIZATION_TYPES = 'adverityAuthorizations/SET_AUTHORIZATION_TYPES';
export const SET_AUTHORIZATION_TYPES_LOAD_STATUS = 'adverityAuthorizations/SET_AUTHORIZATION_TYPES_LOAD_STATUS';

// authorization form
export const SET_AUTHORIZATION_TYPE_OPTIONS = 'adverityAuthorizations/SET_AUTHORIZATION_TYPE_OPTIONS';
export const SET_AUTHORIZATION_TYPE_OPTIONS_LOAD_STATUS = 'adverityAuthorizations/SET_AUTHORIZATION_TYPE_OPTIONS_LOAD_STATUS';
export const SET_AUTHORIZATION_FORM_OPERATION_IN_PROGRESS = 'adverityAuthorizations/SET_AUTHORIZATION_FORM_OPERATION_IN_PROGRESS';

// authorizations list
export const APPEND_AUTHORIZATIONS_LIST_ITEMS = 'adverityAuthorizations/APPEND_AUTHORIZATIONS_LIST_ITEMS';
export const SET_AUTHORIZATIONS_LIST_ITEMS = 'adverityAuthorizations/SET_AUTHORIZATIONS_LIST_ITEMS';
export const SET_AUTHORIZATIONS_LIST_TYPE = 'adverityAuthorizations/SET_AUTHORIZATIONS_LIST_TYPE';
export const SET_AUTHORIZATIONS_LIST_PAGE = 'adverityAuthorizations/SET_AUTHORIZATIONS_LIST_PAGE';
export const SET_AUTHORIZATIONS_LIST_COUNT = 'adverityAuthorizations/SET_AUTHORIZATIONS_LIST_COUNT';
export const SET_AUTHORIZATIONS_LIST_LOAD_STATUS = 'adverityAuthorizations/SET_AUTHORIZATIONS_LIST_LOAD_STATUS';
export const SET_AUTHORIZATIONS_LIST_OPERATION_IN_PROGRESS = 'adverityAuthorizations/SET_AUTHORIZATIONS_LIST_OPERATION_IN_PROGRESS';
export const RESET_AUTHORIZATIONS_LIST = 'adverityAuthorizations/RESET_AUTHORIZATIONS_LIST';
