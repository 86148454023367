import { connect } from 'react-redux';

import { getGroupsListProps } from '../../selectors';
import { groupsListSort, goToGroupDetailsPage } from '../../actions';
import GroupsList from './GroupsList';

const mapDispatchToProps = {
    goToGroupDetailsPage,
    onSort: groupsListSort
};

export default connect(getGroupsListProps, mapDispatchToProps)(GroupsList);
