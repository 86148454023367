import React, { Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Checkbox from '../Checkbox';
import Input from '../Input';
import SelectInput from '../SelectInput';
import DateTimePicker from '../DateTimePicker';

import local from './local.module.scss';

const getLabel = ({ label, id }) => (
    (label || id) &&
    <div className={local.fieldLabel}>
        { label || id }
    </div>
);

const getMessageBlock = ({ help_text }, { error }) => (
    (help_text || error) ?
        <div className={local.fieldMessage}>
            {
                error ?
                    <span className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                    </span> :
                    // `dangerouslySetInnerHTML` is needed as `help_text` may contain HTML tags, e.g., links
                    <span dangerouslySetInnerHTML={{ __html: help_text }} />
            }
        </div> :
        null
);

export const renderCheckboxField = ({ config, input, meta, disabled }) => (
    <Fragment>
        <Checkbox
            inputProperties={input}
            disabled={disabled}
            label={config.label}
        />
        {getMessageBlock(config, meta)}
    </Fragment>
);

export const renderTextField = ({ config, input, meta, disabled }) => (
    <Fragment>
        {getLabel(config)}
        <Input
            id={input.name}
            inputProperties={input}
            disabled={disabled}
            className={classnames({ 'error-input': Boolean(meta.error) }, local.textInput)}
            maxLength={config.max_length}
        />
        {getMessageBlock(config, meta)}
    </Fragment>
);

export const renderTextAreaField = ({ config, input, meta, disabled }) => (
    <Fragment>
        {getLabel(config)}
        <Input
            id={input.name}
            inputProperties={input}
            disabled={disabled}
            className={classnames({ 'error-input': Boolean(meta.error) })}
            multiline={true}
        />
        {getMessageBlock(config, meta)}
    </Fragment>
);

export const renderSelectField = ({ config, input, meta, disabled }) => (
    <Fragment>
        {getLabel(config)}
        <SelectInput
            items={config.choices.map(({ value, display_name }) => ({
                id: value,
                name: display_name
            }))}
            inputProperties={input}
            disabled={disabled}
            error={Boolean(meta.error)}
        />
        {getMessageBlock(config, meta)}
    </Fragment>
);

export const renderDateTimeField = ({ config, input, meta, disabled }) => (
    <Fragment>
        {getLabel(config)}
        <DateTimePicker
            value={input.value}
            onChange={input.onChange}
            disabled={disabled}
        />
        {getMessageBlock(config, meta)}
    </Fragment>
);
