import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import { AUTOMATION_ID, EVENT_CATEGORY } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import OwnerForm from '../OwnerForm';

import local from './local.module.scss';

interface OwnerFormLayoutProps {
    isOperationInProgress: boolean;
    canAccess: boolean;
    goToOwnersListPage: Function;
    addOwner: Function;
    clearSubmitErrors: Function;
}

class OwnerFormLayout extends Component<OwnerFormLayoutProps> {
    public render() {
        const { isOperationInProgress, canAccess } = this.props;

        if (!canAccess) {
            return (
                <ErrorPage error='403' />
            );
        }

        return (
            <Fragment>
                {this.renderBreadcrumbs()}
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            <FormattedMessage id='admin.newOwner' />
                        </div>
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.OWNER_FORM_CANCEL_BTN}
                                    disabled={isOperationInProgress}
                                    className='btn-flat'
                                    onClick={this.onCancelButtonClick}
                                >
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <FormSubmitButton
                                id={AUTOMATION_ID.OWNER_FORM_SAVE_BTN}
                                form='ownerForm'
                                width={130}
                                disabled={isOperationInProgress}
                                text={<FormattedMessage id='common.saveChanges' />}
                                isSubmitting={isOperationInProgress}
                            />
                        </div>
                    </div>
                    <OwnerForm onSubmit={this.onFormSubmit} />
                </div>
            </Fragment>
        );
    }

    private onFormSubmit = (values) => {
        const { addOwner, clearSubmitErrors } = this.props;

        clearSubmitErrors('ownerForm');

        return addOwner(values);
    }

    private renderBreadcrumbs = () => {
        const { goToOwnersListPage } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_OWNERS_BREADCRUMB,
                        label: (<FormattedMessage id='admin.allOwners' />),
                        onClick: () => { goToOwnersListPage(); }
                    },
                    {
                        label: <FormattedMessage id='admin.newOwner' />
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private onCancelButtonClick = () => {
        const { goToOwnersListPage } = this.props;

        goToOwnersListPage();

        service.analytics.trackEvent('Cancel owner creation', EVENT_CATEGORY.ADMIN);
    }
}

export default OwnerFormLayout;
