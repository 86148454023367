import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withRouteParams } from 'modules/common/hoc';
import { getFeedDetailsProps } from 'modules/feeds/selectors';
import * as actions from 'modules/feeds/actions';
import FeedDetailsLayout from './FeedDetailsLayout';

const mapDispatchToProps = {
    resetData: actions.resetFeedDetails,
    loadData: actions.getFeedDetails,
    setView: actions.setFeedDetailsView,
    goToFeedsListPage: actions.goToFeedsListPage,
    togglePauseFeedSchedule: actions.togglePauseFeedSchedule,
    toggleEnableFeed: actions.toggleEnableFeed,
    goToEditFeedPage: actions.goToEditFeedPage,
    goToPendingFeedPage: actions.goToPendingFeedPage,
    openAdHocRunDialog: actions.openAdHocRunDialog,
    showFeedDeleteDialog: actions.showFeedDeleteDialog
};

export default withRouteParams(injectIntl(connect(getFeedDetailsProps, mapDispatchToProps)(FeedDetailsLayout)));
