import { validate as validateEmail } from 'email-validator';

import { intl } from 'modules/i18n';
import { formatString } from '../../utils';

const integerNumberRegExp = new RegExp('^(0|-?[1-9]\\d*)$', 'i');
const integerNumberValidatorCache = {};

export const getValidatorForIntegerValue = (minValue: number, maxValue: number) => {
    const key = `${minValue};${maxValue}`;

    if (!integerNumberValidatorCache[key]) {
        integerNumberValidatorCache[key] = (value: string) => {
            let error: string | undefined;

            if (value) {
                if (!integerNumberRegExp.test(value)) {
                    error = intl.formatMessage({ id: 'validationErrors.custom.IntegerField' });
                }

                const intVal = parseInt(value, 10);
                if (intVal > maxValue) {
                    error = formatString(intl.formatMessage({ id: 'validationErrors.custom.IntegerFieldMaxValue' }), maxValue);
                } else if (intVal < minValue) {
                    error = formatString(intl.formatMessage({ id: 'validationErrors.custom.IntegerFieldMinValue' }), minValue);
                }
            }

            return error;
        };
    }

    return integerNumberValidatorCache[key];
};

export const emailValidator = (value: string) => {
    return value && !validateEmail(value) ? intl.formatMessage({ id: 'custom.InvalidEmail' }) : undefined;
};
