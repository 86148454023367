import { formatString } from 'modules/common/utils';
import { SOURCES_ROUTES } from './constants';
import SourcesListLayout from './components/SourcesListLayout';
import SourceDetailsLayout from './components/SourceDetailsLayout';
import SourceFormLayout from './components/SourceFormLayout';

export default [
    {
        path: SOURCES_ROUTES.LIST,
        component: SourcesListLayout
    },
    {
        path: formatString(SOURCES_ROUTES.DETAILS, ':ownerId', ':sourceId'),
        component: SourceDetailsLayout
    },
    {
        path: SOURCES_ROUTES.NEW,
        component: SourceFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(SOURCES_ROUTES.EDIT, ':ownerId', ':sourceId', ':versionId'),
        component: SourceFormLayout,
        params: {
            isEditMode: true
        }
    }
];
