import { connect } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';

import { withLocationState, withRouteParams } from 'modules/common/hoc';
import { goToSourcesListPage, goToSourceDetailsPage, confirmAddSource, confirmEditSource } from '../../actions';
import { getSourceFormLayoutProps } from '../../selectors';
import SourceFormLayout from './SourceFormLayout';

const mapDispatchToProps = {
    goToSourcesListPage,
    goToSourceDetailsPage,
    confirmAddSource,
    confirmEditSource,
    clearSubmitErrors
};

export default withLocationState(withRouteParams(connect(getSourceFormLayoutProps, mapDispatchToProps)(SourceFormLayout)));
