import React, { FunctionComponent } from 'react';

import { SVGIconProps } from 'modules/common/interfaces';

const TableIcon: FunctionComponent<SVGIconProps> = ({ className }) => (
    <svg className={className} width='100%' height='100%' viewBox='0 0 18 18' preserveAspectRatio='xMidYMid meet' focusable='false'>
        <path
            fill='currentColor'
            fillRule='evenodd'
            d='M2 2h14v14H2V2zm2 6v2h2V8H4zm0-4v2h10V4H4zm0 8v2h2v-2H4zm4-4v2h2V8H8zm4 0v2h2V8h-2zm-4 4v2h2v-2H8zm4 0v2h2v-2h-2z'
        />
    </svg>
);

export default TableIcon;
