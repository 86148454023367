import React, { FunctionComponent, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { memberApiDefinitions } from 'modules/service/types';
import { formatString } from 'modules/common/utils';
import { useAppDispatch } from 'modules/common/hooks';
import Table from 'modules/common/components/Table';
import { getUserProfile } from 'modules/auth/selectors';
import { MEMBERS_ROUTES } from 'modules/admin/members/constants';
import { goToMemberDetailsPage } from 'modules/admin/members/actions';
import { getGroupDetailsOperationInProgress } from '../../selectors';
import { deleteGroupMembership } from '../../actions';

import local from './local.module.scss';

interface GroupMembershipsTableProps {
    groupId: string;
    items: Array<memberApiDefinitions['GroupMemberDetails']>;
    canManage: boolean;
    isLoaded: boolean;
}

const LIST_SKELETON_ROWS_COUNT = 5;
const ICON_COLUMN_WIDTH = 38;

const GroupMembershipsTable: FunctionComponent<GroupMembershipsTableProps> = ({ groupId, items, canManage, isLoaded }) => {
    const { odpMemberAdministrator } = useSelector(getUserProfile);
    const isOperationInProgress = useSelector(getGroupDetailsOperationInProgress);

    const dispatch = useAppDispatch();

    const onTableRowClick = useCallback(({ id }) => {
        if (isLoaded && odpMemberAdministrator) {
            dispatch(goToMemberDetailsPage(id));
        }
    }, [ isLoaded, odpMemberAdministrator ]);

    const tableConfig = useMemo(() => {
        const columns: object[] = [
            {
                Header: <FormattedMessage id='common.name' />,
                id: 'username',
                accessor: ({ id, username }) => odpMemberAdministrator ?
                    (
                        <a href={`#${formatString(MEMBERS_ROUTES.DETAILS, id)}`} className={local.name}>
                            {username}
                        </a>
                    ) : username,
                width: `calc(100% - ${ICON_COLUMN_WIDTH}px)`,
                skeletonWidth: '100%',
                showTooltip: true
            }
        ];

        if (canManage) {
            columns.push({
                id: 'controls',
                accessor: ({ id }) => (
                    <div
                        className={classnames(local.deleteIconContainer, { [local.disabled]: Boolean(isOperationInProgress) })}
                        onClick={(event) => {
                            if (!isOperationInProgress) {
                                dispatch(deleteGroupMembership(groupId, id));
                            }

                            event.stopPropagation();
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
                    </div>
                ),
                width: ICON_COLUMN_WIDTH,
                skeletonWidth: '100%',
                skeletonCellWidth: 70
            });
        }

        return columns;
    }, [ odpMemberAdministrator, isOperationInProgress, canManage ]);

    return (
        <Table
            columns={tableConfig}
            data={items}
            skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
            isLoaded={isLoaded}
            onRowClick={onTableRowClick}
        />
    );
};

export default GroupMembershipsTable;
