import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import { formatString } from 'modules/common/utils';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH, AUTOMATION_ID } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { memberApiDefinitions } from 'modules/service/types';
import { OWNERS_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface OwnersListProps {
    items: Array<memberApiDefinitions['OwnerLite']>;
    status: LoadStatus;
    goToOwnerMembershipsPage: Function;
    sortBy: string;
    sortOrder: SortOrder;
    onSort: Function;
    totalCount: number;
    loadMore: Function;
}

class OwnersList extends Component<OwnersListProps> {
    public render() {
        const { items, status, onSort, totalCount, loadMore } = this.props;
        const isLoaded = status === LOAD_STATUS.LOADED;

        return (
            <Fragment>
                <Table
                    columns={this.getTableConfig()}
                    data={items}
                    skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                    isLoaded={isLoaded}
                    onRowClick={this.onTableRowClick}
                    onSort={onSort}
                />
                {
                    isLoaded && (totalCount > items.length) &&
                    <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                }
            </Fragment>
        );
    }

    private getTableConfig = () => {
        const { sortBy, sortOrder } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.name' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'ownerName',
                accessor: ({ ownerId, ownerName }) => (
                    <a href={`#${formatString(OWNERS_ROUTES.DETAILS, ownerId)}`} className={local.name}>
                        {ownerName}
                    </a>
                ),
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '100%',
                cellClassName: AUTOMATION_ID.OWNERS_TABLE_NAME,
                showTooltip: true,
                sortable: true
            }
        ];
    }

    private onTableRowClick = ({ ownerId }) => {
        const { status, goToOwnerMembershipsPage } = this.props;

        if (status === LOAD_STATUS.LOADED) {
            goToOwnerMembershipsPage(ownerId);
        }
    }
}

export default OwnersList;
