import React, { FunctionComponent, useState, useCallback } from 'react';
import classnames from 'classnames';
import Dialog from '@mui/material/Dialog';

import { PromptOptions } from 'modules/common/interfaces';
import Input from '../Input';

import local from './local.module.scss';

type PromptDialogProps = {
    resolve: Function;
    cleanup: Function;
} & PromptOptions;

const PromptDialog: FunctionComponent<PromptDialogProps> = ({
    title,
    message,
    defaultValue = '',
    placeholder,
    confirmButtonText = 'OK',
    confirmButtonClass,
    cancelButtonText,
    cancelButtonClass,
    normalize,
    resolve,
    cleanup
}) => {
    const [open, setOpen] = useState(true);
    const [value, setValue] = useState(defaultValue);

    const onChange = useCallback((event) => {
        const newValue = event.target.value;

        setValue(normalize ? normalize(newValue) : newValue);
    }, [ setValue, normalize ]);

    const close = useCallback(() => {
        setOpen(false);
        resolve(value);

        setTimeout(() => {
            cleanup();
        }, 1000);
    }, [ value, setOpen, resolve, cleanup ]);

    const cancel = useCallback(() => {
        setOpen(false);
        resolve(null);

        setTimeout(() => {
            cleanup();
        }, 1000);
    }, [ setOpen, resolve, cleanup ]);

    return (
        <Dialog open={open}>
            <div className={local.card}>
                <div className={local.title}>
                    {title}
                </div>
                {
                    message ?
                        <div className={local.paragraph}>{message}</div> :
                        null
                }
                <div className={local.input}>
                    <Input
                        placeholder={placeholder}
                        inputProperties={{ value, onChange }}
                    />
                </div>
                <div className={classnames(local.buttonsContainer, 'container-row')}>
                    {
                        cancelButtonText ?
                            <div className='ls-button'>
                                <button className={cancelButtonClass} onClick={cancel}>{cancelButtonText}</button>
                            </div> :
                            null
                    }
                    <div className='ls-button'>
                        <button disabled={!value} className={confirmButtonClass} onClick={close}>{confirmButtonText}</button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default PromptDialog;
