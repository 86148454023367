import { formatString } from 'modules/common/utils';
import { OWNERS_ROUTES } from './constants';
import OwnersListLayout from './components/OwnersListLayout';
import OwnerFormLayout from './components/OwnerFormLayout';
import OwnerDetailsLayout from './components/OwnerDetailsLayout';

export default [
    {
        path: OWNERS_ROUTES.LIST,
        component: OwnersListLayout
    },
    {
        path: OWNERS_ROUTES.NEW,
        component: OwnerFormLayout
    },
    {
        path: formatString(OWNERS_ROUTES.DETAILS, ':ownerId'),
        component: OwnerDetailsLayout
    }
];
