import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { intl } from 'modules/i18n';
import { LOAD_STATUS, OVERRIDABLE_FIELD_SUFFIX } from 'modules/common/constants';
import { normalizeCollectionOffsetDaysField } from 'modules/common/utils';
import { useAppDispatch } from 'modules/common/hooks';
import Accordion from 'modules/common/components/Accordion';
import Input from 'modules/common/components/Input';
import SelectInput from 'modules/common/components/SelectInput';
import RadioSwitch from 'modules/common/components/RadioSwitch';
import DatePicker from 'modules/common/components/DatePicker';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import {
    getCollectionOffsetTemplate,
    getCollectionOffsetTemplateLoadStatus,
    getFeedDetails,
    getFeedAdHocRunCollectionOffsetTemplateId,
    getReportRangeType,
    getFeedAdHocRunStartDate,
    getFeedAdHocRunEndDate,
    getCollectorConfigurationManifest,
    getFeedFormSourceDetails,
    getCollectorConfigurationManifestLoadStatus,
    getFeedFormSourceDetailsLoadStatus,
    getFeedDetailsOperationInProgress,
    getFeedAdHocRunCollectionOffsetDays
} from '../../selectors';
import {
    closeAdHocRunDialog,
    loadAdHocRunConfigurationData,
    getCollectionOffsetTemplate as loadSelectOptions
} from '../../actions';
import { AD_HOC_RUN_REPORT_RANGE } from '../../constants';

import local from './local.module.scss';

const reportRangeOptions = [
    {
        name: <FormattedMessage id='feeds.collectionOffsetTemplate' />,
        value: AD_HOC_RUN_REPORT_RANGE.COLLECTION_OFFSET_TEMPLATE
    },
    {
        name: <FormattedMessage id='feeds.customRange' />,
        value: AD_HOC_RUN_REPORT_RANGE.CUSTOM_RANGE
    }
];

const CollectionOffsetTemplateSelect = ({
    input,
    collectionOffsetTemplate,
    collectionOffsetTemplateLoadStatus,
    disabled
}) => {
    const items = collectionOffsetTemplate.map(({ id, description }) => ({
        id,
        name: intl.formatMessage({ id: `collectionOffsetTemplate.${id}`, defaultMessage: description })
    }));

    return (
        <SelectInput
            placeholder={<FormattedMessage id='feeds.selectReportRange' />}
            items={items}
            inputProperties={input}
            disabled={disabled}
            isLoading={collectionOffsetTemplateLoadStatus !== LOAD_STATUS.LOADED}
        />
    );
};

const CollectionOffsetDaysInput = ({ input, disabled }) => (
    <Input
        className={local.offsetDaysInput}
        inputProperties={input}
        placeholder={intl.formatMessage({ id: 'feeds.collectionOffsetDays' })}
        disabled={disabled}
    />
);

const StartDatePicker = ({ input, disabled }) => {
    const startDate = useSelector(getFeedAdHocRunStartDate);
    const endDate = useSelector(getFeedAdHocRunEndDate);

    return (
        <DatePicker
            value={startDate}
            onChange={input.onChange}
            disabled={disabled}
            disableFuture={true}
            shouldDisableDate={(date) => Boolean(date && date.isAfter(endDate, 'day'))}
        />
    );
};

const EndDatePicker = ({ input, disabled }) => {
    const startDate = useSelector(getFeedAdHocRunStartDate);
    const endDate = useSelector(getFeedAdHocRunEndDate);

    return (
        <DatePicker
            value={endDate}
            onChange={input.onChange}
            disabled={disabled}
            disableFuture={true}
            shouldDisableDate={(date) => Boolean(date && date.isBefore(startDate, 'day'))}
        />
    );
};

const FeedAdHocRunForm = ({ change, handleSubmit, reset }) => {
    const {
        name,
        suffix,
        collectorId,
        configurationContext,
        collectionOffsetTemplateId,
        collectionOffsetDays
    } = useSelector(getFeedDetails);
    const collectionOffsetTemplate = useSelector(getCollectionOffsetTemplate);
    const collectionOffsetTemplateLoadStatus = useSelector(getCollectionOffsetTemplateLoadStatus);
    const selectedCollectionOffsetTemplateId = useSelector(getFeedAdHocRunCollectionOffsetTemplateId);
    const formCollectionOffsetDays = useSelector(getFeedAdHocRunCollectionOffsetDays);

    const dispatch = useAppDispatch();

    useEffect(() => {
        switch (collectionOffsetTemplateLoadStatus) {
            case LOAD_STATUS.REQUIRED:
                dispatch(loadSelectOptions());
                break;
            case LOAD_STATUS.LOADED:
                collectionOffsetTemplateId && change('collectionOffsetTemplateId', collectionOffsetTemplateId);
                break;
        }
    }, [ collectionOffsetTemplateLoadStatus ]);

    useEffect(() => {
        reset();

        change('collectionOffsetDays', collectionOffsetDays || 0);
        dispatch(loadAdHocRunConfigurationData());
    }, []);

    const close = useCallback(() => {
        dispatch(closeAdHocRunDialog());
    }, []);

    const manifest = useSelector(getCollectorConfigurationManifest);
    const manifestLoadStatus = useSelector(getCollectorConfigurationManifestLoadStatus);
    const { dataCollectorConfigurations } = useSelector(getFeedFormSourceDetails);
    const sourceDetailsLoadLoadStatus = useSelector(getFeedFormSourceDetailsLoadStatus);

    const defaultValues = {};
    const displayDynamicSection = manifestLoadStatus === LOAD_STATUS.LOADED && sourceDetailsLoadLoadStatus === LOAD_STATUS.LOADED;

    if (displayDynamicSection) {
        const configuration = dataCollectorConfigurations.find((collectorConfiguration) => collectorConfiguration.collectorId === collectorId);

        configuration && configuration.configurationContext.forEach(({ key, overridable }) => {
            defaultValues[key] = configurationContext.find((field) => field.key === key)?.value;
            defaultValues[`${key}-${OVERRIDABLE_FIELD_SUFFIX}`] = overridable;
        });

        // process configuration values, which may be in present in the saved feed configuration, but absent in the
        // source collector configuration
        configurationContext
            .filter(({ key }) => !defaultValues.hasOwnProperty(key))
            .forEach(({ key, value }) => {
                defaultValues[key] = value;
                defaultValues[`${key}-${OVERRIDABLE_FIELD_SUFFIX}`] = true;
            });
    }

    const isOperationInProgress = useSelector(getFeedDetailsOperationInProgress);

    const reportRangeType = useSelector(getReportRangeType);
    const isRunAllowed = displayDynamicSection && !isOperationInProgress && ((reportRangeType === AD_HOC_RUN_REPORT_RANGE.CUSTOM_RANGE) ||
        (collectionOffsetTemplateLoadStatus === LOAD_STATUS.LOADED && selectedCollectionOffsetTemplateId && formCollectionOffsetDays !== null));

    return (
        <Fragment>
            <form id='feedAdHocRunForm' onSubmit={handleSubmit} autoComplete='off'>
                <div className={local.text}>
                    <FormattedMessage id='feeds.runConfirmationMessage' />
                </div>
                <div className={local.text}>
                    {name}_{suffix}
                </div>
                <div className={local.field}>
                    <div className={local.label}>
                        <FormattedMessage id='feeds.reportRangeType' />
                    </div>
                    <RadioSwitch
                        fieldName='reportRangeType'
                        options={reportRangeOptions}
                        classes={{
                            option: local.option
                        }}
                        disabled={isOperationInProgress}
                    />
                </div>
                {
                    reportRangeType === AD_HOC_RUN_REPORT_RANGE.COLLECTION_OFFSET_TEMPLATE &&
                    <Fragment>
                        <div className={local.field}>
                            <div className={local.label}>
                                <FormattedMessage id='feeds.reportRange' />
                            </div>
                            <Field
                                name='collectionOffsetTemplateId'
                                disabled={isOperationInProgress}
                                collectionOffsetTemplateId={collectionOffsetTemplateId}
                                collectionOffsetTemplate={collectionOffsetTemplate}
                                collectionOffsetTemplateLoadStatus={collectionOffsetTemplateLoadStatus}
                                component={CollectionOffsetTemplateSelect}
                            />
                        </div>
                        <div className={local.field}>
                            <div className={local.label}>
                                <FormattedMessage id='feeds.collectionOffsetDays' />
                            </div>
                            <Field
                                name='collectionOffsetDays'
                                disabled={isOperationInProgress}
                                component={CollectionOffsetDaysInput}
                                normalize={normalizeCollectionOffsetDaysField}
                            />
                            <div className={local.warning}>
                                <FormattedMessage id='feeds.collectionOffsetDaysMessage' />
                            </div>
                        </div>
                    </Fragment>
                }
                {
                    reportRangeType === AD_HOC_RUN_REPORT_RANGE.CUSTOM_RANGE &&
                    <Fragment>
                        <div className='container-row'>
                            <div className={local.field}>
                                <div className={local.label}>
                                    <FormattedMessage id='feeds.collectionStartDate' />
                                </div>
                                <Field
                                    name='collectionStartDate'
                                    component={StartDatePicker}
                                    disabled={isOperationInProgress}
                                />
                            </div>
                            <div className={local.field}>
                                <div className={local.label}>
                                    <FormattedMessage id='feeds.collectionEndDate' />
                                </div>
                                <Field
                                    name='collectionEndDate'
                                    component={EndDatePicker}
                                    disabled={isOperationInProgress}
                                />
                            </div>
                        </div>
                    </Fragment>
                }
                <div className={local.configurationContainer}>
                    <Accordion label={<FormattedMessage id='common.configuration' />}>
                        {
                            displayDynamicSection ?
                                <DynamicFieldsSection
                                    change={change}
                                    config={manifest}
                                    disabled={isOperationInProgress}
                                    prefix='configurationOverrides'
                                    editMode={true}
                                    defaultValues={defaultValues}
                                    viewOnlyForNonEditableFields={true}
                                    form='feedAdHocRunForm'
                                /> :
                                <div className={local.loading}>
                                    <FormattedMessage id='common.loading' />
                                </div>
                        }
                    </Accordion>
                </div>
            </form>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button className='btn-flat' onClick={close}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <div className='ls-button'>
                    <button
                        type='submit'
                        form='feedAdHocRunForm'
                        disabled={!isRunAllowed}
                    >
                        <FormattedMessage id='feeds.runFeedNow' />
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default FeedAdHocRunForm;
