import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { removeSubmitError } from 'modules/form/actions';
import { removeSchedule } from '../../actions';
import { makeFeedScheduleProps } from '../../selectors';

import FeedSchedule from './FeedSchedule';

const mapDispatchToProps = {
    removeSchedule,
    removeSubmitError
};

export default injectIntl(connect(makeFeedScheduleProps, mapDispatchToProps)(FeedSchedule)) as any;
