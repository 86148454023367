import service from 'modules/service';
import { dataCatalogApiDefinitions } from 'modules/service/types';
import { EVENT_CATEGORY, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { getErrorText, showErrorSnackbar, sort } from 'modules/common/utils';
import { getSelectedClientId } from 'modules/auth/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';

export const loadDatasets = () => async (dispatch, getState) => {
    dispatch(actions.setDatasetsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const isMasterProject = selectors.getIsMasterProject(storeState);
        const { datasets }: dataCatalogApiDefinitions['ListDatasetsResponse'] = await service.api.getDatasets(isMasterProject ? null : clientId);

        dispatch(actions.setDatasets(datasets));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(actions.setDatasetsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const selectDataset = (datasetId) => async (dispatch, getState) => {
    dispatch(actions.setSelectedDatasetId(datasetId));
    dispatch(actions.setSelectedTableId(null));
    dispatch(actions.setDatasetLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const isMasterProject = selectors.getIsMasterProject(storeState);

        const [metadata, { tables }] = await Promise.all([
            service.api.getDatasetMetadata(isMasterProject ? null : clientId, datasetId),
            service.api.getDatasetTables(isMasterProject ? null : clientId, datasetId)
        ]);

        dispatch(actions.setDatasetMetadata(metadata));
        dispatch(actions.setDatasetTables(tables));
    } catch (err) {
        dispatch(actions.setDatasetMetadata({}));
        dispatch(actions.setDatasetTables([]));

        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(actions.setDatasetLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const selectTable = (tableId) => async (dispatch, getState) => {
    dispatch(actions.setSelectedTableId(tableId));
    dispatch(actions.setTableDataLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const datasetId = selectors.getSelectedDatasetId(storeState);
        const isMasterProject = selectors.getIsMasterProject(storeState);
        const data: dataCatalogApiDefinitions['TableMetadataResponse'] = await service.api.getTableData(isMasterProject ? null : clientId, datasetId, tableId);

        dispatch(actions.setTableData(data));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(actions.setTableDataLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const schemaFieldsSort = (newSortBy) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getTableInfo(getState());

    if (newSortBy === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(actions.setSchemaSortOrder(order));
    } else {
        dispatch(actions.setSchemaSortBy(newSortBy));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(actions.setSchemaSortOrder(SORT_ORDER.ASC));
        }
    }
};

export const setIsMasterProjectAndReload = (isMasterProject) => async (dispatch, getState) => {
    dispatch(actions.setIsMasterProject(isMasterProject));

    const query = selectors.getSearchQuery(getState());

    await Promise.all([
        dispatch(actions.loadDatasets()),
        dispatch(actions.performSearch(query))
    ]);

    service.analytics.trackEvent(isMasterProject ? 'Set mode to master project' : 'Set mode to client project', EVENT_CATEGORY.DATA_CATALOG);
};

export const performSearch = (query) => async (dispatch, getState) => {
    dispatch(actions.setSearchInProgress(true));
    dispatch(actions.setSelectedDatasetId(null));
    dispatch(actions.setSelectedTableId(null));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const isMasterProject = selectors.getIsMasterProject(storeState);

        dispatch(actions.setSearchQuery(query));

        if (query) {
            const { assets }: dataCatalogApiDefinitions['ListAssetResponse'] = await service.api.searchDataCatalog(isMasterProject ? null : clientId, query);
            service.analytics.trackEvent('Data catalogue search', EVENT_CATEGORY.DATA_CATALOG);

            dispatch(actions.setSearchResult(sort(assets!, 'id')));
        } else {
            dispatch(actions.setSearchResult(null));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(actions.setSearchInProgress(false));
    }
};
