import React, { FunctionComponent, ReactNode } from 'react';
import { default as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import local from './local.module.scss';

interface DialogProps {
    open: boolean;
    title?: string | JSX.Element;
    children?: ReactNode;
    onClose?: Function;
    classes?: { [key: string]: string };
    rootDialogProps?: Partial<MuiDialogProps>;
}

const Dialog: FunctionComponent<DialogProps> = ({
    open,
    title,
    children,
    onClose,
    classes = {},
    rootDialogProps
}) => (
    <MuiDialog
        open={open}
        classes={{ paper: classes.paper || local.paper }}
        maxWidth={false}
        onClose={(event, reason) => { onClose && onClose(event, reason); }}
        {...rootDialogProps}
    >
        <div className={classes.body}>
            {
                title &&
                <DialogTitle classes={{ root: local.titleRoot }}>
                    {title}
                </DialogTitle>
            }
            <DialogContent classes={{ root: local.contentRoot }}>
                {children}
            </DialogContent>
        </div>
    </MuiDialog>
);

export default Dialog;
