import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';

import { store } from '../../../store';

export const useReduxFormValue = (formName: string, fieldName: string): any => {
    const selector = formValueSelector(formName);

    return useSelector<{}>((state) => selector(state, fieldName));
};

export const useDidUpdateEffect = (effect, deps) => {
    const ref = useRef(false);

    useEffect(() => {
        if (ref.current) {
            return effect();
        } else {
            ref.current = true;
        }
    }, deps);
};

type AppAction = ReturnType<typeof store.dispatch>;
type AppDispatch = ThunkDispatch<any, any, AppAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
