import * as t from '../actionTypes';

export const setSelectedDatasetId = (data) => ({
    type: t.SET_SELECTED_DATASET_ID,
    payload: data
});

export const setSelectedTableId = (data) => ({
    type: t.SET_SELECTED_TABLE_ID,
    payload: data
});

export const setDatasetsLoadStatus = (data) => ({
    type: t.SET_DATASETS_LOAD_STATUS,
    payload: data
});

export const setDatasets = (data) => ({
    type: t.SET_DATASETS,
    payload: data
});

export const setDatasetLoadStatus = (data) => ({
    type: t.SET_DATASET_LOAD_STATUS,
    payload: data
});

export const setDatasetTables = (data) => ({
    type: t.SET_DATASET_TABLES,
    payload: data
});

export const setDatasetMetadata = (data) => ({
    type: t.SET_DATASET_METADATA,
    payload: data
});

export const setTableDataLoadStatus = (data) => ({
    type: t.SET_TABLE_DATA_LOAD_STATUS,
    payload: data
});

export const setTableData = (data) => ({
    type: t.SET_TABLE_DATA,
    payload: data
});

export const setSchemaSortBy = (data) => ({
    type: t.SET_SCHEMA_SORT_BY,
    payload: data
});

export const setSchemaSortOrder = (data) => ({
    type: t.SET_SCHEMA_SORT_ORDER,
    payload: data
});

export const setIsMasterProject = (data) => ({
    type: t.SET_IS_MASTER_PROJECT,
    payload: data
});

export const setSearchInProgress = (data) => ({
    type: t.SET_IS_SEARCH_IN_PROGRESS,
    payload: data
});

export const setSearchResult = (data) => ({
    type: t.SET_SEARCH_RESULT,
    payload: data
});

export const setSearchQuery = (data) => ({
    type: t.SET_SEARCH_QUERY,
    payload: data
});

export const resetCatalog = () => ({
    type: t.RESET_CATALOG
});
