import React, { Fragment, FunctionComponent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import { formatString } from 'modules/common/utils';
import { LOAD_STATUS } from 'modules/common/constants';
import { GROUPS_ROUTES } from 'modules/admin/groups/constants';
import { ROLE_TYPE } from 'modules/admin/constants';
import RolesChips from 'modules/admin/components/RolesChips';
import { ownerGroupsListSort, loadMoreOwnerGroups } from '../../actions';
import { getOwnerGroupsListProps } from '../../selectors';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const OwnerGroupsList: FunctionComponent = () => {
    const { loadStatus, items, sortBy, sortOrder, totalCount } = useSelector(getOwnerGroupsListProps);
    const isLoaded = loadStatus === LOAD_STATUS.LOADED;

    const tableConfig = useMemo(() => [
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.name' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'name',
            accessor: ({ id, name, memberCanGetGroupDetails }) => memberCanGetGroupDetails ? (
                <a href={`#${formatString(GROUPS_ROUTES.DETAILS, id)}`} className={local.name}>
                    {name}
                </a>
            ) : name,
            width: '25%',
            skeletonWidth: '100%',
            showTooltip: true,
            sortable: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='admin.roles' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'roles',
            accessor: ({ roles }) => (
                <RolesChips
                    editable={false}
                    type={ROLE_TYPE.OWNER}
                    selectedRoles={roles}
                />
            ),
            width: '75%',
            skeletonWidth: '100%',
            sortable: false
        }
    ], [ sortBy, sortOrder ]);

    const dispatch = useAppDispatch();

    const onSort = useCallback((column) => {
        dispatch(ownerGroupsListSort(column));
    }, []);

    const loadMore = useCallback(() => {
        dispatch(loadMoreOwnerGroups());
    }, []);

    return (
        <Fragment>
            <Table
                columns={tableConfig}
                data={items}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={isLoaded}
                onSort={onSort}
            />
            {
                isLoaded && (totalCount > items.length) &&
                <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
            }
        </Fragment>
    );
};

export default OwnerGroupsList;
