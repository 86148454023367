import { connect } from 'react-redux';

import { withRouteParams } from 'modules/common/hoc';
import * as actions from '../../actions';
import { getMemberDetailsLayoutProps } from '../../selectors';
import MemberDetailsLayout from './MemberDetailsLayout';

const mapDispatchToProps = {
    resetData: actions.resetMemberDetails,
    loadData: actions.getMemberDetails,
    goToMembersListPage: actions.goToMembersListPage,
    addOwnerRole: actions.addOwnerRole,
    deleteOwnerRole: actions.deleteOwnerRole,
    deleteOwnerMembership: actions.deleteOwnerMembership,
    addClientRole: actions.addClientRole,
    deleteClientRole: actions.deleteClientRole,
    deleteClientMembership: actions.deleteClientMembership,
    openOwnerMembershipDialog: actions.openOwnerMembershipDialog,
    openClientMembershipDialog: actions.openClientMembershipDialog,
    openGroupMembershipDialog: actions.openGroupMembershipDialog
};

export default withRouteParams(connect(getMemberDetailsLayoutProps, mapDispatchToProps)(MemberDetailsLayout));
