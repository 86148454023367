import React, { Fragment, FunctionComponent, useCallback, useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRepeat } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

import { useAppDispatch } from 'modules/common/hooks';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH } from 'modules/common/constants';
import ReportDetailsDrawer from '../ReportDetailsDrawer';
import { getReportsListState } from '../../selectors';
import { goToReportAssetsPage, loadMoreReports, reportsListSort, rerunReportFromListPage } from '../../actions';
import { INTERIM_REPORT_STATES, REPORT_EXECUTION_STATUS, REPORT_STATE } from '../../constants';
import { ReportState } from '../../interfaces';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const ReportsList: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const {
        items,
        totalCount,
        sortBy,
        sortOrder,
        loadStatus,
        reportOperationInProgress
    } = useSelector(getReportsListState);

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [selectedReportData, setSelectedReportData] = useState<{ id: string; name: string; state: ReportState }>();

    const isLoaded = loadStatus === LOAD_STATUS.LOADED;

    const onTableRowClick = useCallback(({ id, name, state }) => {
        if (!INTERIM_REPORT_STATES.includes(state) && !reportOperationInProgress) {
            setDrawerOpen(true);
            setSelectedReportData({ id, name, state });
        }
    }, [ reportOperationInProgress ]);

    const tableConfig = [
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.name' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            accessor: 'name',
            width: CONTENT_CONTAINER_MAX_WIDTH,
            skeletonWidth: '74%',
            sortable: true,
            showTooltip: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.status' />}
                />
            ),
            id: 'status',
            accessor: ({ id, state, executionStatus }) => {
                let stateToDisplay = state;
                let className = '';

                switch (state) {
                    case REPORT_STATE.ACTIVE:
                        stateToDisplay = executionStatus;

                        switch (executionStatus) {
                            case REPORT_EXECUTION_STATUS.FAILED:
                                className = local.failed;
                                break;
                            case REPORT_EXECUTION_STATUS.SUCCEEDED:
                                className = local.succeeded;
                                break;
                            case REPORT_EXECUTION_STATUS.RUNNING:
                                className = local.running;
                                break;
                            default:
                                stateToDisplay = state;
                                className = local.active;
                                break;
                        }
                        break;
                    case REPORT_STATE.CREATING:
                    case REPORT_STATE.UPDATING:
                    case REPORT_STATE.DELETING:
                        className = local.generating;
                        break;
                    case REPORT_STATE.CREATION_FAILED:
                    case REPORT_STATE.UPDATE_FAILED:
                    case REPORT_STATE.DELETION_FAILED:
                        className = local.failed;
                        break;
                }

                return (
                    <div className='container-row'>
                        <Tooltip
                            title={<FormattedMessage id={`reports.state.${stateToDisplay}.message`} />}
                            classes={{ tooltip: local.tooltip }}
                        >
                            <span className={classnames(local.label, className)}>
                                <FormattedMessage id={`reports.state.${stateToDisplay}`} defaultMessage={stateToDisplay} />
                            </span>
                        </Tooltip>
                        {
                            executionStatus === REPORT_EXECUTION_STATUS.FAILED &&
                            <Tooltip
                                title={<FormattedMessage id='reports.reportRerunTooltip' />}
                                classes={{ tooltip: local.tooltip }}
                            >
                                <FontAwesomeIcon
                                    icon={faRepeat}
                                    className={classnames(local.rerunIcon, { [local.disabled]: reportOperationInProgress })}
                                    onClick={(event) => {
                                        event.stopPropagation();

                                        if (!reportOperationInProgress) {
                                            dispatch(rerunReportFromListPage(id));
                                        }
                                    }}
                                />
                            </Tooltip>
                        }
                    </div>
                );
            },
            width: '20%',
            skeletonWidth: '100%',
            headerCellClassName: 'secondary-column-cell'
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'updateTimestamp',
            accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
            width: '20%',
            skeletonWidth: '100%',
            sortable: true,
            headerCellClassName: 'secondary-column-cell'
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<Fragment><FormattedMessage id='common.nextRun' /> (UTC)</Fragment>}
                />
            ),
            id: 'nextRun',
            accessor: ({ nextRunTimestamp }) => nextRunTimestamp ?
                moment.utc(nextRunTimestamp).format('DD MMM YYYY [at] HH:mm') : '-',
            width: '20%',
            skeletonWidth: '100%',
            headerCellClassName: 'secondary-column-cell'
        },
        {
            id: 'rowControls',
            accessor: ({ id, state }) => {
                const canViewDetails = !INTERIM_REPORT_STATES.includes(state);
                const buttonIconAndTitle = (
                    <Fragment>
                        <FontAwesomeIcon className={local.icon} icon={faInfoCircle} />
                        <FormattedMessage id='common.details' />
                    </Fragment>
                );

                return (
                    <div className={local.tableRowControlsContainer}>
                        <div
                            className={classnames(local.detailsButton, { [local.disabled]: !canViewDetails })}
                            onClick={() => {
                                if (canViewDetails) {
                                    dispatch(goToReportAssetsPage(id));
                                }
                            }}
                        >
                            {
                                canViewDetails ?
                                    buttonIconAndTitle :
                                    <Tooltip
                                        title={<FormattedMessage id='reports.disabledDetailsMessage' />}
                                        classes={{ tooltip: local.tooltip }}
                                    >
                                        <span>{buttonIconAndTitle}</span>
                                    </Tooltip>
                            }
                        </div>
                    </div>
                );
            },
            width: '10%',
            skeletonWidth: '100%',
            headerCellClassName: 'secondary-column-cell'
        }
    ];

    return (
        <Fragment>
            <Table
                columns={tableConfig}
                data={items}
                onSort={(columnName: string) => {
                    if (!reportOperationInProgress) {
                        dispatch(reportsListSort(columnName));
                    }
                }}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={isLoaded}
                onRowClick={onTableRowClick}
            />
            {
                isLoaded && (totalCount > items.length) &&
                <LoadMoreButton
                    load={() => { dispatch(loadMoreReports()); }}
                    count={items.length}
                    totalCount={totalCount}
                />
            }
            <ReportDetailsDrawer baseData={selectedReportData} isOpen={isDrawerOpen} setOpen={setDrawerOpen} />
        </Fragment>
    );
};

export default ReportsList;
