import moment, { Moment } from 'moment';
import xorWith from 'lodash/xorWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { intl } from 'modules/i18n';
import { ERROR_ALL_FIELDS } from './formUtils';
import { SORT_ORDER } from '../constants';
import { SortOrder } from '../interfaces';

export const getUniqueElements = (array: any[]) => Array.from(new Set(array));

export const formatString = (...args): string => {
    let str = '';

    if (args.length) {
        str = args[0];

        for (let i = 1; i < args.length; i++) {
            str = str.replace(RegExp('\\{' + (i - 1) + '\\}', 'g'), args[i]);
        }
    }

    return str;
};

export const readTextFile = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
        resolve(reader.result);
    };

    reader.onerror = () => {
        reject(reader.error);
    };

    reader.readAsText(file);
});

export const buildQueryString = (parameters: object): string => {
    return '?' + Object.keys(parameters)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]))
        .join('&');
};

export const getFirstWorkingDayOfNextMonth = (): Moment => {
    const date = moment().add(1, 'month').startOf('month');
    const day = date.day();

    let dayIncrement = 0;
    switch (day) {
        case 0: // Sunday
            dayIncrement = 1;
            break;
        case 6: // Saturday
            dayIncrement = 2;
            break;
    }

    return date.add(dayIncrement, 'day');
};

export const isDeepEqual = (arr1: any[], arr2: any[]): boolean => isEmpty(xorWith(arr1, arr2, isEqual));

export const getErrorText = (error) => {
    if (error?.isAxiosError && error?.response?.data) {
        const { message, validationErrors } = error.response.data;

        if (validationErrors?.length) {
            const { code, description } = validationErrors.find(({ field }) => field === ERROR_ALL_FIELDS) || validationErrors[0];

            return intl.formatMessage({ id: `validationErrors.${code}`, defaultMessage: description || `Error code: ${code}` });
        } else if (message) {
            return message;
        }
    }

    return error?.toString ? error.toString() : error;
};

export const sort = (
    itemsArray: object[],
    sortBy: string,
    sortOrder: SortOrder = SORT_ORDER.ASC,
    compareFn?: (a, b) => number
): object[] => itemsArray.sort((item1, item2) => {
    let returnValue = 0;

    if (sortOrder === SORT_ORDER.ASC) {
        returnValue = compareFn ? compareFn(item1[sortBy], item2[sortBy]) : (item1[sortBy] > item2[sortBy] ? 1 : -1);
    } else if (sortOrder === SORT_ORDER.DESC) {
        returnValue = compareFn ? compareFn(item2[sortBy], item1[sortBy]) : (item2[sortBy] > item1[sortBy] ? 1 : -1);
    }

    return returnValue;
});

const isAvroDateOrTimestampLogicalType = ({ logicalType }) =>
    Boolean(logicalType) && [ 'date', 'timestamp-millis', 'timestamp-micros' ].includes(logicalType);

export const isAvroDateOrTimestampType = (type) =>
    Array.isArray(type) ?
        type.some(isAvroDateOrTimestampLogicalType) :
        isAvroDateOrTimestampLogicalType(type);

export const normalizeCollectionOffsetDaysField = (value) => {
    const min = 0;
    const max = 365;

    const allowedText = value.replace(/[^0-9]/g, '');
    let numericValue = parseInt(allowedText, 10);

    if (isNaN(numericValue)) {
        return null;
    }

    if (numericValue > max) {
        numericValue = max;
    } else if (numericValue < min) {
        numericValue = min;
    }

    return numericValue;
};

export const getUniqueId = (): string => Math.random().toString(16).slice(2);
