export const REDUCER_NAME = 'pipelines';
export const PIPELINES_LIST_PAGE_SIZE = 50;

export const PIPELINES_LIST_SORT_ATTRIBUTES_MAP = {
    name: 'PURPOSE',
    lastUpdated: 'LAST_UPDATE',
    ownerName: 'OWNER',
    airflowDagId: 'DAG_ID'
};

export const PIPELINE_STATE = {
    ACTIVE: 'ACTIVE',
    DRAFT: 'DRAFT',
    ARCHIVED: 'ARCHIVED'
};

export const PIPELINES_ROUTES = {
    LIST: '/transformation-pipelines',
    DETAILS: '/transformation-pipelines/view/{0}/{1}',
    EDIT: '/transformation-pipelines/edit/{0}/{1}'
};
