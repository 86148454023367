import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Drawer from 'modules/common/components/Drawer';
import DatePicker from 'modules/common/components/DatePicker';
import SelectInput from 'modules/common/components/SelectInput';
import { getFeedRunsListFilters } from '../../selectors';
import { applyFeedRunsFilters, resetFeedRunsFilters } from '../../actions';
import { FEED_EXECUTION_FAILED_STATUS_FILTER_ITEMS } from '../../constants';

import local from './local.module.scss';

interface FeedRunsFiltersProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
}

const FeedRunsFilters: FunctionComponent<FeedRunsFiltersProps> = ({ isOpen, setOpen }) => {
    const dispatch = useAppDispatch();
    const savedFilters = useSelector(getFeedRunsListFilters);

    const [statuses, setStatuses] = useState(savedFilters.statuses);
    const [fetchStart, setFetchStart] = useState(savedFilters.fetchStart);
    const [fetchEnd, setFetchEnd] = useState(savedFilters.fetchEnd);
    const [runDate, setRunDate] = useState(savedFilters.runDate);

    useEffect(() => {
        dispatch(resetFeedRunsFilters());
    }, []);

    useEffect(() => {
        setStatuses(savedFilters.statuses);
    }, [ isOpen ]);

    const onApply = useCallback(() => {
        dispatch(applyFeedRunsFilters(statuses, fetchStart, fetchEnd, runDate));
        setOpen(false);
    }, [ statuses, fetchStart, fetchEnd, runDate ]);

    return (
        <Drawer
            isOpen={isOpen}
            setOpen={setOpen}
            title={
                <FormattedMessage id='common.filter' />
            }
            buttons={
                <Fragment>
                    <div className='ls-button'>
                        <button
                            className='btn-flat'
                            onClick={() => { setOpen(false); }}
                        >
                            <FormattedMessage id='common.cancel' />
                        </button>
                    </div>
                    <div className='ls-button'>
                        <button onClick={onApply}>
                            <FormattedMessage id='common.apply' />
                        </button>
                    </div>
                </Fragment>
            }
        >
            <div className={local.filterTitle}>
                <FormattedMessage id='feeds.fetchStart' />
            </div>
            <DatePicker
                value={fetchStart}
                onChange={setFetchStart}
                disableFuture={true}
                shouldDisableDate={(date) => Boolean(date && date.isAfter(fetchEnd, 'day'))}
                componentsProps={{ actionBar: { actions: [ 'clear' ] } }}
            />
            <div className={local.filterTitle}>
                <FormattedMessage id='feeds.fetchEnd' />
            </div>
            <DatePicker
                value={fetchEnd}
                onChange={setFetchEnd}
                disableFuture={true}
                shouldDisableDate={(date) => Boolean(date && date.isBefore(fetchStart, 'day'))}
                componentsProps={{ actionBar: { actions: [ 'clear' ] } }}
            />
            <div className={local.filterTitle}>
                <FormattedMessage id='common.status' />
            </div>
            <SelectInput
                multiple={true}
                inputProperties={{
                    value: statuses,
                    onChange: setStatuses
                }}
                items={FEED_EXECUTION_FAILED_STATUS_FILTER_ITEMS}
                isClearable={true}
                width={190}
            />
            <div className={local.filterTitle}>
                <FormattedMessage id='feeds.runDate' />
            </div>
            <DatePicker
                value={runDate}
                onChange={setRunDate}
                disableFuture={true}
                componentsProps={{ actionBar: { actions: [ 'clear' ] } }}
            />
        </Drawer>
    );
};

export default FeedRunsFilters;
