import React, { FunctionComponent, ReactNode } from 'react';
import { Tabs as MuiTabs, Tab, TabClasses, TabsClasses } from '@mui/material';
import classnames from 'classnames';

import local from './local.module.scss';

interface TabsProps {
    value: number;
    onChange: (event: React.SyntheticEvent, value: number) => void;
    disabled?: boolean;
    labels: ReactNode[];
    classes?: Partial<TabsClasses>;
    tabClasses?: Partial<TabClasses>;
}

const Tabs: FunctionComponent<TabsProps> = ({
    value,
    onChange,
    disabled,
    labels,
    classes,
    tabClasses
}) => {
    const { root, scroller, indicator, ...otherClasses } = classes || {};

    return (
        <MuiTabs
            value={value}
            onChange={onChange}
            classes={{
                root: classnames(local.tabsRoot, root),
                scroller: classnames(local.tabsScroller, scroller),
                indicator: classnames(local.tabsIndicator, indicator),
                ...otherClasses
            }}
        >
            {
                labels.map((label, idx) => {
                    const { root: tabRoot, selected, ...otherTabClasses } = tabClasses || {};

                    return (
                        <Tab
                            key={idx}
                            label={label}
                            disabled={disabled}
                            classes={{
                                root: classnames(local.tabRoot, tabRoot),
                                selected: classnames(local.tabSelected, selected),
                                ...otherTabClasses
                            }}
                        />
                    );
                })
            }
        </MuiTabs>
    );
};

export default Tabs;
