import { formatString } from 'modules/common/utils';
import { PIPELINES_ROUTES } from './constants';
import PipelinesListLayout from './components/PipelinesListLayout';
import PipelineDetailsLayout from './components/PipelineDetailsLayout';
// import PipelineFormLayout from './components/PipelineFormLayout';

export default [
    {
        path: PIPELINES_ROUTES.LIST,
        component: PipelinesListLayout
    },
    {
        path: formatString(PIPELINES_ROUTES.DETAILS, ':ownerId', ':pipelineId'),
        component: PipelineDetailsLayout
    }
    // currently only advanced pipelines logic is implemented (and they should not be editable from UI)
    // TODO uncomment this route when simple pipelines logic will be implemented
    // {
    //     path: formatString(PIPELINES_ROUTES.EDIT, ':ownerId', ':pipelineId'),
    //     component: PipelineFormLayout
    // }
];
