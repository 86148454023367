import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import SearchInput from 'modules/common/components/SearchInput';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import Checkbox from 'modules/common/components/Checkbox';
import InstancesList from 'modules/transformationInstances/components/InstancesList';
import ErrorPage from 'modules/common/components/ErrorPage';
import { LoadStatus } from 'modules/common/interfaces';
import { transformationApiDefinitions } from 'modules/service/types';

import local from './local.module.scss';

interface InstancesListLayoutProps {
    items: Array<transformationApiDefinitions['InstanceSearchResponseDto']>;
    status: LoadStatus;
    totalCount: number;
    canView: boolean;
    canManage: boolean;
    loadData: Function;
    onSearch: Function;
    resetTable: Function;
    loadMore: Function;
    goToAddInstancePage: Function;
    showArchived: boolean;
    toggleArchivedInstances: Function;
}

class InstancesListLayout extends Component<InstancesListLayoutProps> {
    public componentDidMount() {
        const { loadData, resetTable, canView } = this.props;

        if (canView) {
            resetTable();
            loadData();
        }
    }

    public componentDidUpdate() {
        const { status, canView, loadData } = this.props;

        if (canView && status === LOAD_STATUS.REQUIRED) {
            loadData();
        }
    }

    public render() {
        const {
            status,
            canView,
            totalCount,
            items,
            loadMore,
            onSearch,
            canManage,
            goToAddInstancePage,
            showArchived,
            toggleArchivedInstances
        } = this.props;

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const isLoaded = status === LOAD_STATUS.LOADED;

        return (
            <Fragment>
                <div className={local.title}>
                    <FormattedMessage id='common.transformationInstances' />
                </div>
                <div className={classnames(local.tableControls, 'container-row')}>
                    <SearchInput inputId={AUTOMATION_ID.INSTANCES_SEARCH} disabled={!isLoaded} onSearch={onSearch} />
                    <div className='container-row'>
                        <div className={local.filterCheckbox}>
                            <Checkbox
                                label={<FormattedMessage id='instances.showArchived' />}
                                disabled={!isLoaded}
                                inputProperties={{
                                    value: showArchived,
                                    onChange: (event, value) => { toggleArchivedInstances(value); }
                                }}
                            />
                        </div>
                        {
                            canManage &&
                            <div className='ls-button'>
                                <button id={AUTOMATION_ID.NEW_INSTANCE_BTN} disabled={!isLoaded} onClick={() => { goToAddInstancePage(); }}>
                                    <FormattedMessage id='instances.newInstance' />
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <div className={local.card}>
                    <InstancesList />
                    {
                        isLoaded && (totalCount > items.length) &&
                        <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                    }
                </div>
            </Fragment>
        );
    }
}

export default InstancesListLayout;
