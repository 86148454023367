import { createSelector } from 'reselect';

import { sort } from 'modules/common/utils';
import { REDUCER_NAME, REPORTS_LIST_SORT_ATTRIBUTES_MAP } from '../constants';

// reports list
export const getReportsListState = (state) => state[REDUCER_NAME].reportsList;

// report details
export const getReportDetails = (state) => state[REDUCER_NAME].reportDetails.data;
export const getReportDetailsLoadStatus = (state) => state[REDUCER_NAME].reportDetails.loadStatus;
export const getReportDetailsOperationInProgress = (state) => state[REDUCER_NAME].reportDetails.reportOperationInProgress;
export const getReportTableState = (state) => state[REDUCER_NAME].reportDetails.table;

// report assets
export const getReportAssetsData = (state) => state[REDUCER_NAME].reportAssets;

// report form
export const getReportFormTemplates = (state) => state[REDUCER_NAME].reportForm.templates.list;
export const getReportFormTemplatesLoadStatus = (state) => state[REDUCER_NAME].reportForm.templates.loadStatus;
export const getReportFormTemplate = (state) => state[REDUCER_NAME].reportForm.template.data;
export const getReportFormTemplateLoadStatus = (state) => state[REDUCER_NAME].reportForm.template.loadStatus;
export const getReportFormMarkets = (state) => state[REDUCER_NAME].reportForm.markets.list;
export const getReportFormMarketsLoadStatus = (state) => state[REDUCER_NAME].reportForm.markets.loadStatus;
export const getReportFormCollectorCredentials = (state) => state[REDUCER_NAME].reportForm.collectorCredentials.list;
export const getReportFormCollectorCredentialsLoadStatus = (state) => state[REDUCER_NAME].reportForm.collectorCredentials.loadStatus;
export const getReportCollectorCredentialForm = (state) => state[REDUCER_NAME].reportForm.collectorCredentials.form;
export const getReportFormSelectedPlatforms = (state) => state[REDUCER_NAME].reportForm.reportPlatforms;
export const getReportFormOperationInProgress = (state) => state[REDUCER_NAME].reportForm.reportOperationInProgress;
export const getReportFormStage = (state) => state[REDUCER_NAME].reportForm.stage;

export const getReportsListSearchPayload = createSelector(
    getReportsListState,
    ({ page, pageSize, sortBy, sortOrder, searchBy }) => ({
        orderBy: [
            {
                attribute: REPORTS_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering: sortOrder
            }
        ],
        searchString: searchBy,
        page,
        pageSize
    })
);

export const getReportDetailsDrawerProps = createSelector(
    getReportDetails,
    getReportDetailsLoadStatus,
    getReportDetailsOperationInProgress,
    getReportTableState,
    (data, dataLoadStatus, isOperationInProgress, { sortBy, sortOrder }) => ({
        sortBy,
        sortOrder,
        dataLoadStatus,
        isOperationInProgress,
        data: {
            ...data,
            platforms: data.platforms?.length ? sort(data.platforms, sortBy, sortOrder) : []
        }
    })
);

export const getReportFormValues = (state) => {
    const { reportForm } = state.form;

    return reportForm?.values || {};
};

export const getReportAssetsListItems = createSelector(
    getReportAssetsData,
    ({ data: { platforms, instances }, platformsTable, instancesTable }) => ({
        platforms: platforms?.length ? sort(platforms, platformsTable.sortBy, platformsTable.sortOrder) : [],
        instances: instances?.length ? sort(instances, instancesTable.sortBy, instancesTable.sortOrder) : []
    })
);
