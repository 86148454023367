import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { push } from 'redux-first-history';

import { useAppDispatch } from 'modules/common/hooks';

import local from './local.module.scss';

const ODP_SUPPORT_URL = 'https://support.odp.groupm.tech';

const errorDescription = {
    401: [ 'errors.401.description', 'errors.pleaseContactSupport' ],
    403: [ 'errors.403.description', 'errors.pleaseContactSupport' ],
    404: [ 'errors.404.description' ]
};

interface ErrorPageProps {
    error: string;
    buttonText?: ReactNode;
    callback?: Function;
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ error, buttonText, callback }) => {
    const dispatch = useAppDispatch();

    const description = errorDescription[error].map((line, index) => (
        <p key={index}>
            <FormattedMessage id={line} />
        </p>
    ));

    const buttonOnClick = useCallback(() => {
        typeof callback === 'function' ? callback() : dispatch(push('/'));
    }, [ callback ]);

    return (
        <div className={classnames(local.errorPage, 'container-column')}>
            <h4 className={local.error}>
                {error} <FormattedMessage id='errors.error' />
            </h4>
            <h1 className={local.title}>
                <FormattedMessage id={`errors.${error}.title`} />
            </h1>
            <div className={local.description}>{description}</div>
            <div className='ls-button'>
                <button onClick={buttonOnClick}>
                    {
                        buttonText || <FormattedMessage id='errors.backToHome' />
                    }
                </button>
            </div>
            <p className={local.needHelp}>
                <FormattedMessage id='errors.needHelp' />
                &nbsp;
                <a href={ODP_SUPPORT_URL} target='_blank' rel='noreferrer'>
                    <FormattedMessage id='errors.contactSupport' />
                </a>
            </p>
        </div>
    );
};

export default ErrorPage;
