import { push } from 'redux-first-history';
import { SubmissionError } from 'redux-form';

import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import {
    formatString,
    getErrorText,
    normalizeManifest,
    prepareFormErrors,
    showErrorSnackbar,
    prepareFormValues
} from 'modules/common/utils';
import { intl } from 'modules/i18n';
import { getRouterPath } from 'modules/common/selectors';
import { TARGET_CREDENTIALS_TAB_INDEX } from 'modules/credentials/constants';
import { goToCredentialsListPage } from 'modules/credentials/actions';
import { TARGET_CREDENTIALS_ROUTES } from '../constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

export const goToTargetCredentialDetailsPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(TARGET_CREDENTIALS_ROUTES.DETAILS, ownerId, id)));
};

export const goToTargetCredentialsListPage = () => (dispatch) => {
    dispatch(goToCredentialsListPage(TARGET_CREDENTIALS_TAB_INDEX));
};

export const loadTargetCredentialManifest = (targetType, authMethod) => async (dispatch) => {
    dispatch(storeActions.setTargetCredentialManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const manifest = await service.api.getTargetCredentialManifest(targetType, authMethod);
        dispatch(storeActions.setTargetCredentialManifest(normalizeManifest(manifest)));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setTargetCredentialManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

const validateTargetCredentialFormValues = (formValues) => {
    const requiredFields = [ 'name', 'targetType', 'ownerId' ];
    const errors = {};

    if (formValues.targetType) {
        requiredFields.push('authMethod');
    }

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

export const addTargetCredential = (formValues) => async (dispatch, getState) => {
    const errors = validateTargetCredentialFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setTargetCredentialFormOperationInProgress(true));

    try {
        const storeState = getState();
        const manifest = selectors.getTargetCredentialManifest(storeState);
        const { name, targetType, authMethod, credentialContext, ownerId } = formValues;

        const details: ingestionApiDefinitions['TargetCredentialDetailResponseDto'] = await service.api.addTargetCredential(
            ownerId,
            {
                name,
                targetType,
                authMethod,
                credentialContext: prepareFormValues(manifest, credentialContext)
            }
        );

        service.analytics.trackEvent('Create target credential', EVENT_CATEGORY.CREDENTIALS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === TARGET_CREDENTIALS_ROUTES.NEW) {
            dispatch(goToTargetCredentialDetailsPage(details.id, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setTargetCredentialFormOperationInProgress(false));
    }
};

export const getTargetCredentialData = (ownerId, credentialId) => async (dispatch) => {
    dispatch(storeActions.setTargetCredentialDetailsLoadStatus(LOAD_STATUS.LOADING));
    dispatch(storeActions.setTargetCredentialManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details: ingestionApiDefinitions['TargetCredentialDetailResponseDto'] = await service.api.getTargetCredentialDetails(ownerId, credentialId);
        dispatch(storeActions.setTargetCredentialDetails(details));

        const manifest = await service.api.getTargetCredentialManifest(details.targetType, details.authMethod);
        dispatch(storeActions.setTargetCredentialManifest(manifest));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setTargetCredentialDetailsLoadStatus(LOAD_STATUS.LOADED));
        dispatch(storeActions.setTargetCredentialManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const editTargetCredential = (credentialId, formValues) => async (dispatch, getState) => {
    const errors = validateTargetCredentialFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setTargetCredentialFormOperationInProgress(true));

    try {
        const storeState = getState();
        const manifest = selectors.getTargetCredentialManifest(storeState);
        const { name, credentialContext, ownerId } = formValues;

        await service.api.editTargetCredential(
            ownerId,
            credentialId,
            {
                name,
                credentialContext: prepareFormValues(manifest, credentialContext)
            }
        );

        service.analytics.trackEvent('Edit target credential', EVENT_CATEGORY.CREDENTIALS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(TARGET_CREDENTIALS_ROUTES.EDIT, ownerId, credentialId)) {
            dispatch(goToTargetCredentialDetailsPage(credentialId, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setTargetCredentialFormOperationInProgress(false));
    }
};

export const goToEditTargetCredentialPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(TARGET_CREDENTIALS_ROUTES.EDIT, ownerId, id)));
};

export const goToAddTargetCredentialPage = () => (dispatch) => {
    dispatch(push(TARGET_CREDENTIALS_ROUTES.NEW));
};

export const getTargetCredentialsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setTargetCredentialsListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const payload = selectors.getTargetCredentialsListSearchPayload(storeState);
        const list: ingestionApiDefinitions['ApiPaginatedCollectionContainerDtoTargetCredentialSearchResponseDto'] = await service.api.getTargetCredentialsSearchList(payload);

        dispatch(storeActions.appendTargetCredentialsListItems(list.objects));
        dispatch(storeActions.setTargetCredentialsListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setTargetCredentialsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreTargetCredentials = () => async (dispatch, getState) => {
    const page = selectors.getTargetCredentialsListState(getState()).page + 1;

    dispatch(storeActions.setTargetCredentialsListPage(page));
    await dispatch(getTargetCredentialsList());

    service.analytics.trackEvent('Load more target credentials', EVENT_CATEGORY.CREDENTIALS);
};

export const targetCredentialsListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const { sortBy, sortOrder } = selectors.getTargetCredentialsListState(storeState);

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setTargetCredentialsListSortOrder(order));
    } else {
        dispatch(storeActions.setTargetCredentialsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setTargetCredentialsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setTargetCredentialsListPage(0));
    dispatch(storeActions.setTargetCredentialsListItems([]));
    dispatch(storeActions.setTargetCredentialsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('Target credentials list sort', EVENT_CATEGORY.CREDENTIALS);
};

export const targetCredentialsListSearch = (text) => (dispatch, getState) => {
    const { searchBy } = selectors.getTargetCredentialsListState(getState());

    if (text !== searchBy) {
        dispatch(storeActions.setTargetCredentialsListPage(0));
        dispatch(storeActions.setTargetCredentialsListItems([]));
        dispatch(storeActions.setTargetCredentialsListSearchText(text));
        dispatch(storeActions.setTargetCredentialsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('Target credentials list search', EVENT_CATEGORY.CREDENTIALS);
    }
};
