import React, { FunctionComponent, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Field } from 'redux-form';

import { removeSubmitError } from 'modules/form/actions';
import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import { SOURCE_STATE } from 'modules/sources/constants';
import { FEED_TARGETS_MAX_COUNT } from '../../constants';
import {
    getFeedDetails,
    getFeedDetailsLoadStatus,
    getFeedFormOperationInProgress,
    getFeedFormSelectedTargets
} from '../../selectors';
import { addFeedTarget, getTargetCredentialsList, getTargetsList } from '../../actions';
import FeedTarget from '../FeedTarget';

import local from '../FeedFormLayout/local.module.scss';

interface FeedTargetsProps {
    isEditMode: boolean;
    change: InjectedFormProps['change'];
}

const FeedTargets: FunctionComponent<FeedTargetsProps> = ({
    isEditMode,
    change
}) => {
    const dispatch = useAppDispatch();

    const selectedTargets = useSelector(getFeedFormSelectedTargets);
    const { targets } = useSelector(getFeedDetails);
    const dataLoadStatus = useSelector(getFeedDetailsLoadStatus);
    const isOperationInProgress = useSelector(getFeedFormOperationInProgress);

    const sourceState = useReduxFormValue('feedForm', 'sourceState');

    useEffect(() => {
        dispatch(getTargetsList());
        dispatch(getTargetCredentialsList());
    }, []);

    const addNewTarget = (event) => {
        event.preventDefault();

        dispatch(removeSubmitError({
            form: 'feedForm',
            field: 'targets'
        }));

        dispatch(addFeedTarget());
    };

    const isDataLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
    const addButtonDisabled = isOperationInProgress || (isEditMode && !isDataLoaded) ||
        selectedTargets.length >= FEED_TARGETS_MAX_COUNT;

    return (
        <Fragment>
            <div className={classnames(local.label, local.targetsLabel)}>
                <FormattedMessage id='common.destinations' />
                <IconWithTooltip className={local.targetsTooltip}>
                    <FormattedMessage id='feeds.destinationsTip' />
                </IconWithTooltip>
            </div>
            {
                sourceState === SOURCE_STATE.TEST &&
                <div>
                    <div className={local.targetsWarning}>
                        <FormattedMessage id='feeds.destinationsWarningMessage' />
                    </div>
                </div>
            }
            <Field
                name='targets'
                component={
                    // ignore error if it belongs to nested field
                    ({ meta }) => meta.error && (typeof meta.error === 'string') ?
                        <div className={classnames(local.error, 'form-error-message')}>
                            <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                        </div> :
                        null
                }
            />
            {
                selectedTargets.map((target, index) => {
                    let targetData;
                    if (isEditMode && isDataLoaded) {
                        targetData = targets.find(({ id }) => id === target.id);
                    }

                    return (
                        <FeedTarget
                            key={target.id}
                            index={index}
                            isEditMode={isEditMode}
                            data={targetData}
                            change={change}
                        />
                    );
                })
            }
            <div className={classnames('plus-button', 'ls-button')}>
                <button disabled={addButtonDisabled} className='btn-transparent' onClick={addNewTarget}>
                    <FontAwesomeIcon icon={faPlus} />
                    <FormattedMessage id='feeds.addDestination' />
                </button>
            </div>
        </Fragment>
    );
};

export default FeedTargets;
