import React, { Fragment, FunctionComponent } from 'react';
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useReduxFormValue } from 'modules/common/hooks';
import { AUTOMATION_ID, INGEST_MODE } from 'modules/common/constants';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import Checkbox from 'modules/common/components/Checkbox';
import { formatString } from 'modules/common/utils';
import { isCorrectDateColumnType } from '../../utils';
import { getSourceFormMode, getSourceFormOperationInProgress, getSourceFormSchema } from '../../selectors';
import SchemaDeduplicationSettings from '../SchemaDeduplicationSettings';

import local from '../SourceFormLayout/local.module.scss';

type IngestionSettingsFieldProps = WrappedFieldProps & {
    isOperationInProgress: boolean;
    items?: SelectInputItem[];
};

type DateColumnFieldProps = IngestionSettingsFieldProps & {
    overwriteByColumns: Array<{ [key: string]: any }>;
    change: InjectedFormProps['change'];
};

interface SourceIngestionSettingsProps {
    isEditMode: boolean;
    change: InjectedFormProps['change'];
}

const USE_DEDUPLICATE_COLUMNS = process.env.REACT_APP_SOURCE_DEDUPLICATE_COLUMNS === 'yes';

const PrimaryKeyField: FunctionComponent<IngestionSettingsFieldProps> = ({
    input,
    meta,
    items,
    isOperationInProgress
}) => (
    <div className={local.field}>
        <div className={local.label}>
            <FormattedMessage id='common.primaryKey' />
            <IconWithTooltip className={local.labelTooltip}>
                <FormattedMessage id='common.primaryKeyTip' />
            </IconWithTooltip>
        </div>
        <SelectInput
            inputId={AUTOMATION_ID.SOURCE_FORM_PRIMARY_KEY}
            optionClassName={AUTOMATION_ID.SOURCE_FORM_PRIMARY_KEY_OPTION}
            placeholder={<FormattedMessage id='sources.selectColumns' />}
            items={items!}
            inputProperties={input}
            disabled={isOperationInProgress}
            multiple={true}
            searchable={true}
            error={meta.error}
            trackTiming={true}
            trackingName='Source schema primary key'
        />
        {
            meta.error &&
            <div className='form-error-message'>
                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
            </div>
        }
    </div>
);

const DateColumnField: FunctionComponent<DateColumnFieldProps> = ({
    input,
    meta,
    items,
    overwriteByColumns,
    isOperationInProgress,
    change
}) => {
    const onChange = (value) => {
        if (overwriteByColumns) {
            const index = overwriteByColumns.indexOf(value);

            if (index !== -1) {
                overwriteByColumns.splice(index, 1);
                change('overwriteByColumns', [ ...overwriteByColumns ]);
            }
        }

        input.onChange(value);
    };

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.dateColumn' />
                <span className={local.additionalLabel}>
                    <FormattedMessage id='common.optional' />
                </span>
                <IconWithTooltip className={local.labelTooltip}>
                    <FormattedMessage id='common.dateColumnTip' />
                </IconWithTooltip>
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.SOURCE_FORM_DATE_COLUMN}
                optionClassName={AUTOMATION_ID.SOURCE_FORM_DATE_COLUMN_OPTION}
                placeholder={<FormattedMessage id='sources.selectColumn' />}
                items={items!}
                inputProperties={{ ...input, onChange }}
                disabled={isOperationInProgress}
                searchable={true}
                error={meta.error}
                isClearable={true}
                trackTiming={true}
                trackingName='Source schema date column'
            />
            {
                meta.error ?
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div> :
                    <div className={local.warning}>
                        <FormattedMessage id='sources.dateColumnWarning' />
                    </div>
            }
        </div>
    );
};

const ClusterColumnsField: FunctionComponent<IngestionSettingsFieldProps> = ({
    input,
    meta,
    items,
    isOperationInProgress
}) => {
    const intl = useIntl();
    const limit = 4;

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='sources.bqClusterColumns' />
                <span className={local.additionalLabel}>
                    <FormattedMessage id='common.optional' />
                </span>
                <IconWithTooltip className={local.labelTooltip}>
                    {
                        formatString(intl.formatMessage({ id: 'common.clusterColumnsTip' }), limit)
                    }
                </IconWithTooltip>
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.SOURCE_FORM_CLUSTER_COLUMNS}
                optionClassName={AUTOMATION_ID.SOURCE_FORM_CLUSTER_COLUMNS_OPTION}
                placeholder={<FormattedMessage id='sources.selectColumns' />}
                items={items!}
                inputProperties={input}
                disabled={isOperationInProgress}
                multiple={true}
                limit={limit}
                searchable={true}
                error={meta.error}
                trackTiming={true}
                trackingName='Source schema cluster columns for BQ'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const ClusteringKeyField: FunctionComponent<IngestionSettingsFieldProps> = ({
    input,
    meta,
    items,
    isOperationInProgress
}) => {
    const intl = useIntl();
    const limit = 4;

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='sources.sfClusteringKey' />
                <span className={local.additionalLabel}>
                    <FormattedMessage id='common.optional' />
                </span>
                <IconWithTooltip className={local.labelTooltip}>
                    {
                        formatString(intl.formatMessage({ id: 'common.clusterColumnsTip' }), limit)
                    }
                </IconWithTooltip>
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.SOURCE_FORM_CLUSTERING_KEY}
                optionClassName={AUTOMATION_ID.SOURCE_FORM_CLUSTERING_KEY_OPTION}
                placeholder={<FormattedMessage id='sources.selectColumns' />}
                items={items!}
                inputProperties={input}
                disabled={isOperationInProgress}
                multiple={true}
                limit={limit}
                searchable={true}
                error={meta.error}
                trackTiming={true}
                trackingName='Source schema cluster columns for SF'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const IngestModeField: FunctionComponent<IngestionSettingsFieldProps> = ({
    input,
    isOperationInProgress
}) => {
    const intl = useIntl();
    const items = Object.values(INGEST_MODE).map((id) => ({
        id,
        name: intl.formatMessage({ id: `common.ingestMode.${id}` })
    }));

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.ingestMode' />
                <IconWithTooltip className={local.labelTooltip}>
                    <FormattedMessage id='common.ingestModeTip' />
                </IconWithTooltip>
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.SOURCE_FORM_INGEST_MODE}
                optionClassName={AUTOMATION_ID.SOURCE_FORM_INGEST_MODE_OPTION}
                items={items}
                inputProperties={input}
                disabled={isOperationInProgress}
                trackTiming={true}
                trackingName='Source ingest mode'
            />
        </div>
    );
};

const OverwriteByColumnsField: FunctionComponent<IngestionSettingsFieldProps> = ({
    input,
    meta,
    items,
    isOperationInProgress
}) => {
    const intl = useIntl();
    const limit = 4;

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.overwriteByColumns' />
                <span className={local.additionalLabel}>
                    {formatString(intl.formatMessage({ id: 'common.max' }), limit)}
                </span>
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.SOURCE_FORM_OVERWRITE_BY_COLUMNS}
                optionClassName={AUTOMATION_ID.SOURCE_FORM_OVERWRITE_BY_COLUMNS_OPTION}
                placeholder={<FormattedMessage id='sources.selectColumns' />}
                items={items!}
                inputProperties={input}
                multiple={true}
                limit={limit}
                disabled={isOperationInProgress}
                searchable={true}
                error={meta.error}
                trackTiming={true}
                trackingName='Source schema overwrite by columns'
            />
            {
                meta.error ?
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div> :
                    <div className={local.warning}>
                        <FormattedMessage id='sources.overwriteByColumnsWarning' />
                    </div>
            }
        </div>
    );
};

const PartitionByDateColumnField: FunctionComponent<IngestionSettingsFieldProps> = ({
    input,
    meta,
    isOperationInProgress
}) => (
    <div className={local.field}>
        <Checkbox
            inputProperties={input}
            label={<FormattedMessage id='sources.partitionByDateColumn' />}
            disabled={isOperationInProgress}
        />
        {
            meta.error &&
            <div className='form-error-message'>
                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
            </div>
        }
    </div>
);

const SourceIngestionSettings: FunctionComponent<SourceIngestionSettingsProps> = ({
    isEditMode,
    change
}) => {
    const mode = useSelector(getSourceFormMode);
    const { fields = [] } = useSelector(getSourceFormSchema);

    const formName = 'sourceForm';
    const ingestMode = useReduxFormValue(formName, 'ingestMode');
    const dateColumn = useReduxFormValue(formName, 'dateColumn');
    const overwriteByColumns = useReduxFormValue(formName, 'overwriteByColumns');

    const showOverwriteByColumns = ingestMode === INGEST_MODE.INSERT_OR_OVERWRITE_BY_COLUMNS;

    const avroFieldItems = fields ?
        fields
            .filter(({ name }) => Boolean(name))
            .map(({ name }) => ({ id: name, name })) :
        [];

    const dateColumnItems = fields ?
        fields
            .filter(({ name, type }) =>
                Boolean(name && type) && isCorrectDateColumnType(type, mode) &&
                (showOverwriteByColumns ? !overwriteByColumns?.includes(name) : true)
            )
            .map(({ name }) => ({ id: name, name })) :
        [];

    const overwriteByColumnsItems = fields ?
        fields
            .filter(({ name }) => Boolean(name) && name !== dateColumn)
            .map(({ name }) => ({ id: name, name })) :
        [];

    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);

    return (
        <Fragment>
            <Field
                name='primaryKeyColumns'
                component={PrimaryKeyField}
                items={avroFieldItems}
                isOperationInProgress={isOperationInProgress}
            />
            <Field
                name='dateColumn'
                component={DateColumnField}
                items={dateColumnItems}
                overwriteByColumns={overwriteByColumns}
                isOperationInProgress={isOperationInProgress}
                change={change}
            />
            <Field
                name='ingestMode'
                component={IngestModeField}
                isOperationInProgress={isOperationInProgress}
            />
            {
                showOverwriteByColumns &&
                <Field
                    name='overwriteByColumns'
                    component={OverwriteByColumnsField}
                    items={overwriteByColumnsItems}
                    isOperationInProgress={isOperationInProgress}
                />
            }
            <div className={local.field}>
                <SchemaDeduplicationSettings
                    isEditMode={isEditMode}
                    selectColumns={USE_DEDUPLICATE_COLUMNS}
                />
            </div>
            <Field
                name='bigquery.partitionByDateColumn'
                component={PartitionByDateColumnField}
                isOperationInProgress={isOperationInProgress}
            />
            <Field
                name='bigquery.clusterColumns'
                component={ClusterColumnsField}
                items={avroFieldItems}
                isOperationInProgress={isOperationInProgress}
            />
            <Field
                name='snowflake.clusteringKey'
                component={ClusteringKeyField}
                items={avroFieldItems}
                isOperationInProgress={isOperationInProgress}
            />
        </Fragment>
    );
};

export default SourceIngestionSettings;
