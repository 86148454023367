import * as t from '../actionTypes';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { CLIENT_GROUPS_LIST_PAGE_SIZE, CLIENT_MEMBERSHIPS_LIST_PAGE_SIZE, CLIENTS_LIST_PAGE_SIZE } from '../constants';

const ACTION_HANDLERS = {
    [t.SET_CLIENTS]: (state, action) => ({
        ...state,
        clientsList: {
            ...state.clientsList,
            items: action.payload
        }
    }),
    [t.SET_CLIENTS_LOAD_STATUS]: (state, action) => ({
        ...state,
        clientsList: {
            ...state.clientsList,
            loadStatus: action.payload
        }
    }),
    [t.RESET_CLIENTS]: (state) => ({
        ...state,
        clientsList: initialState.clientsList
    }),
    [t.SET_CLIENT_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        clientForm: {
            ...state.clientForm,
            clientOperationInProgress: action.payload
        }
    }),
    [t.SET_CLIENT_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        clientMembershipsList: {
            ...state.clientMembershipsList,
            clientMembershipOperationInProgress: action.payload
        }
    }),
    [t.ADD_CLIENT_MEMBER_ROLE]: (state, action) => {
        const { memberId, role } = action.payload;

        const updatedMemberships = state.clientDetails.data.memberships.map((membership) => {
            if (membership.memberId === memberId) {
                return {
                    ...membership,
                    roles: [ ...membership.roles, role ]
                };
            }

            return membership;
        });

        return {
            ...state,
            clientDetails: {
                ...state.clientDetails,
                data: {
                    ...state.clientDetails.data,
                    memberships: updatedMemberships
                }
            }
        };
    },
    [t.REMOVE_CLIENT_MEMBERSHIP]: (state, action) => {
        const { memberId } = action.payload;

        const updatedMemberships = state.clientDetails.data.memberships.filter((membership) => membership.memberId !== memberId);

        return {
            ...state,
            clientDetails: {
                ...state.clientDetails,
                data: {
                    ...state.clientDetails.data,
                    memberships: updatedMemberships
                }
            }
        };
    },
    [t.REMOVE_CLIENT_MEMBER_ROLE]: (state, action) => {
        const { memberId, role } = action.payload;

        const updatedItems = state.clientDetails.data.memberships?.map((membership) => {
            if (membership.memberId === memberId) {
                const { roles } = membership;
                const index = roles.indexOf(role);

                return roles.length === 1 ?
                    null :
                    {
                        ...membership,
                        roles: [ ...roles.slice(0, index), ...roles.slice(index + 1, roles.length) ]
                    };
            }

            return membership;
        }).filter(Boolean);

        return {
            ...state,
            clientDetails: {
                ...state.clientDetails,
                data: updatedItems ?
                    { ...state.clientDetails.data, memberships: updatedItems } :
                    state.clientDetails.data
            }
        };
    },
    [t.OPEN_CLIENT_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        clientMembershipsList: {
            ...state.clientMembershipsList,
            isClientMembershipDialogOpen: true
        }
    }),
    [t.CLOSE_CLIENT_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        clientMembershipsList: {
            ...state.clientMembershipsList,
            isClientMembershipDialogOpen: false
        }
    }),
    [t.SET_CLIENTS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        clientsList: {
            ...state.clientsList,
            tableState: {
                ...state.clientsList.tableState,
                searchBy: action.payload
            }
        }
    }),
    [t.SET_CLIENTS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        clientsList: {
            ...state.clientsList,
            tableState: {
                ...state.clientsList.tableState,
                sortBy: action.payload
            }
        }
    }),
    [t.SET_CLIENTS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        clientsList: {
            ...state.clientsList,
            tableState: {
                ...state.clientsList.tableState,
                sortOrder: action.payload
            }
        }
    }),
    [t.SET_CLIENTS_LIST_PAGE]: (state, action) => ({
        ...state,
        clientsList: {
            ...state.clientsList,
            tableState: {
                ...state.clientsList.tableState,
                page: action.payload
            }
        }
    }),
    [t.SET_CLIENT_MEMBERSHIPS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        clientMembershipsList: {
            ...state.clientMembershipsList,
            tableState: {
                ...state.clientMembershipsList.tableState,
                searchBy: action.payload
            }
        }
    }),
    [t.SET_CLIENT_MEMBERSHIPS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        clientMembershipsList: {
            ...state.clientMembershipsList,
            tableState: {
                ...state.clientMembershipsList.tableState,
                sortBy: action.payload
            }
        }
    }),
    [t.SET_CLIENT_MEMBERSHIPS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        clientMembershipsList: {
            ...state.clientMembershipsList,
            tableState: {
                ...state.clientMembershipsList.tableState,
                sortOrder: action.payload
            }
        }
    }),
    [t.SET_CLIENT_MEMBERSHIPS_LIST_PAGE]: (state, action) => ({
        ...state,
        clientMembershipsList: {
            ...state.clientMembershipsList,
            tableState: {
                ...state.clientMembershipsList.tableState,
                page: action.payload
            }
        }
    }),
    [t.SET_CLIENT_DETAILS]: (state, action) => ({
        ...state,
        clientDetails: {
            ...state.clientDetails,
            data: action.payload
        }
    }),
    [t.SET_CLIENT_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        clientDetails: {
            ...state.clientDetails,
            loadStatus: action.payload
        }
    }),
    [t.RESET_CLIENT_DETAILS]: (state) => ({
        ...state,
        clientDetails: initialState.clientDetails
    }),
    [t.SET_CLIENT_GROUPS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        clientGroupsList: {
            ...state.clientGroupsList,
            tableState: {
                ...state.clientGroupsList.tableState,
                sortBy: action.payload
            }
        }
    }),
    [t.SET_CLIENT_GROUPS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        clientGroupsList: {
            ...state.clientGroupsList,
            tableState: {
                ...state.clientGroupsList.tableState,
                sortOrder: action.payload
            }
        }
    }),
    [t.SET_CLIENT_GROUPS_LIST_PAGE]: (state, action) => ({
        ...state,
        clientGroupsList: {
            ...state.clientGroupsList,
            tableState: {
                ...state.clientGroupsList.tableState,
                page: action.payload
            }
        }
    })
};

export const initialState = {
    clientsList: {
        tableState: {
            page: 0,
            pageSize: CLIENTS_LIST_PAGE_SIZE,
            sortBy: 'clientName',
            sortOrder: SORT_ORDER.ASC,
            searchBy: ''
        },
        items: [],
        loadStatus: LOAD_STATUS.REQUIRED
    },
    clientForm: {
        clientOperationInProgress: false
    },
    clientDetails: {
        data: {},
        loadStatus: LOAD_STATUS.REQUIRED
    },
    clientMembershipsList: {
        tableState: {
            page: 0,
            pageSize: CLIENT_MEMBERSHIPS_LIST_PAGE_SIZE,
            sortBy: 'username',
            sortOrder: SORT_ORDER.ASC,
            searchBy: ''
        },
        isClientMembershipDialogOpen: false,
        clientMembershipOperationInProgress: false
    },
    clientGroupsList: {
        tableState: {
            page: 0,
            pageSize: CLIENT_GROUPS_LIST_PAGE_SIZE,
            sortBy: 'name',
            sortOrder: SORT_ORDER.ASC
        }
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
