import React, { Fragment, FunctionComponent } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { AUTOMATION_ID, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import AuthorizationForm from '../AuthorizationForm';
import {
    getAuthorizationFormOperationInProgress,
    getAuthorizationTypeOptionsLoadStatus,
    getAuthorizationTypesLoadStatus
} from '../../selectors';
import { addAuthorization, goToAuthorizationsListPage } from '../../actions';

import local from './local.module.scss';

const AuthorizationFormLayout: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const isOperationInProgress = useSelector(getAuthorizationFormOperationInProgress);
    const authorizationTypesLoadStatus = useSelector(getAuthorizationTypesLoadStatus);
    const authorizationTypeOptionsLoadStatus = useSelector(getAuthorizationTypeOptionsLoadStatus);

    const { clients } = useSelector(getUserProfile);
    const selectedClientId = useSelector(getSelectedClientId);
    const client = clients.find(({ id }) => id === selectedClientId);

    const authorizationTypeId = useReduxFormValue('authorizationForm', 'authorizationTypeId');

    if (!client || !client.permissions.includes(PERMISSIONS.ADVERITY_AUTHORIZATIONS.MANAGE)) {
        return (
            <ErrorPage error='403' />
        );
    }

    const onCancelButtonClick = () => {
        dispatch(goToAuthorizationsListPage(authorizationTypeId));
    };

    const onFormSubmit = (values) => {
        dispatch(clearSubmitErrors('authorizationForm'));

        return dispatch(addAuthorization(values));
    };

    return (
        <Fragment>
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_CREDENTIALS_BREADCRUMB,
                        label: (<FormattedMessage id='credentials.allCredentials' />),
                        onClick: () => { dispatch(goToAuthorizationsListPage(authorizationTypeId)); }
                    },
                    {
                        label: <FormattedMessage id='credentials.newAdverityAuthorization' />
                    }
                ]}
                selectedItemIndex={1}
            />
            <div className={local.card}>
                <div className={classnames(local.titleContainer, 'container-row')}>
                    <div className={local.title}>
                        <FormattedMessage id='credentials.newAdverityAuthorization' />
                    </div>
                    <div className={classnames(local.buttonsContainer, 'container-row')}>
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.ADVERITY_AUTHORIZATION_FORM_CANCEL_BTN}
                                disabled={isOperationInProgress}
                                className='btn-flat'
                                onClick={onCancelButtonClick}
                            >
                                <FormattedMessage id='common.cancel' />
                            </button>
                        </div>
                        <FormSubmitButton
                            id={AUTOMATION_ID.ADVERITY_AUTHORIZATION_FORM_SAVE_BTN}
                            form='authorizationForm'
                            width={130}
                            disabled={isOperationInProgress || [ authorizationTypesLoadStatus, authorizationTypeOptionsLoadStatus ].includes(LOAD_STATUS.LOADING)}
                            text={<FormattedMessage id='common.saveChanges' />}
                            isSubmitting={isOperationInProgress}
                        />
                    </div>
                </div>
                <AuthorizationForm onSubmit={onFormSubmit} />
            </div>
        </Fragment>
    );
};

export default AuthorizationFormLayout;
