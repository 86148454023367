import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { getUserProfile } from 'modules/auth/selectors';
import { ingestionApiDefinitions } from 'modules/service/types';
import {
    AUTOMATION_ID,
    LOAD_STATUS,
    PERMISSIONS,
    TARGET_AUTH_METHODS_MAP,
    TARGET_TYPE
} from 'modules/common/constants';
import { TargetType } from 'modules/common/interfaces';
import { useAppDispatch, useDidUpdateEffect, useReduxFormValue } from 'modules/common/hooks';
import SelectInput from 'modules/common/components/SelectInput';
import Input from 'modules/common/components/Input';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import {
    getTargetCredentialData,
    loadTargetCredentialManifest,
    resetTargetCredentialDetails,
    resetTargetCredentialForm,
    resetTargetCredentialManifest,
    setTargetCredentialManifest,
    setTargetCredentialManifestLoadStatus
} from '../../actions';
import {
    getTargetCredentialDetailsLoadStatus,
    getTargetCredentialManifest,
    getTargetCredentialManifestLoadStatus,
    getTargetCredentialFormOperationInProgress,
    getTargetCredentialDetails
} from '../../selectors';

import local from '../TargetCredentialFormLayout/local.module.scss';

interface TargetCredentialFormOwnProps {
    isEditMode: boolean;
}

type RouteMatchParams = Record<'ownerId' | 'credentialId', string>;

type CredentialFormProps = TargetCredentialFormOwnProps & InjectedFormProps<{}, TargetCredentialFormOwnProps>;

type FormFieldProps = WrappedFieldProps & {
    isEditMode: boolean;
    isOperationInProgress: boolean;
    change?: InjectedFormProps['change'];
};

const NameField: FunctionComponent<FormFieldProps> = ({ input, meta, isEditMode, isOperationInProgress }) => {
    const intl = useIntl();

    const isLoaded = useSelector(getTargetCredentialDetailsLoadStatus) === LOAD_STATUS.LOADED;
    const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'credentials.namePlaceholder';
    const placeholder = intl.formatMessage({ id: placeholderMessageId });

    const isDisabled = isEditMode ?
        (isOperationInProgress || !isLoaded) :
        isOperationInProgress;

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.name' />
            </div>
            <Input
                inputProperties={input}
                id={AUTOMATION_ID.TARGET_CREDENTIAL_FORM_NAME}
                className={classnames({ 'error-input': Boolean(meta.error) })}
                placeholder={placeholder}
                disabled={isDisabled}
                maxLength={255}
                trackTiming={true}
                trackingName='Target credential name'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const TargetTypeField: FunctionComponent<FormFieldProps> = ({ input, meta, isEditMode, isOperationInProgress, change }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const targetType = useReduxFormValue('targetCredentialForm', 'targetType');

    const items = [
        {
            id: TARGET_TYPE.BIGQUERY,
            name: intl.formatMessage({ id: `common.targetType.${TARGET_TYPE.BIGQUERY}` })
        },
        {
            id: TARGET_TYPE.SNOWFLAKE,
            name: intl.formatMessage({ id: `common.targetType.${TARGET_TYPE.SNOWFLAKE}` })
        }
    ];

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.targetType' />
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.TARGET_CREDENTIAL_FORM_TYPE}
                items={items}
                inputProperties={{
                    ...input,
                    onChange: (value) => {
                        if (value !== targetType) {
                            change && change('authMethod', undefined);
                            dispatch(setTargetCredentialManifest({}));
                            dispatch(setTargetCredentialManifestLoadStatus(LOAD_STATUS.REQUIRED));
                            input.onChange(value);
                        }
                    }
                }}
                disabled={isEditMode || isOperationInProgress}
                error={meta.error}
                trackTiming={true}
                trackingName='Target credential type'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const AuthMethodField: FunctionComponent<FormFieldProps> = ({ input, meta, isEditMode, isOperationInProgress }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const targetType: TargetType = useReduxFormValue('targetCredentialForm', 'targetType');

    const items = targetType ? Object.values(TARGET_AUTH_METHODS_MAP[targetType]).map((id) => ({
        id,
        name: intl.formatMessage({ id: `common.authMethod.${id}` })
    })) : [];

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.authMethod' />
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.TARGET_CREDENTIAL_FORM_AUTH_METHOD}
                items={items}
                inputProperties={{
                    ...input,
                    onChange: (value) => {
                        dispatch(loadTargetCredentialManifest(targetType, value));
                        input.onChange(value);
                    }
                }}
                disabled={isEditMode || isOperationInProgress || !targetType}
                error={meta.error}
                trackTiming={true}
                trackingName='Target credential auth method'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const OwnerField: FunctionComponent<FormFieldProps> = ({ input, meta, isEditMode, isOperationInProgress }) => {
    const { owners } = useSelector(getUserProfile);
    const items = owners.reduce((acc, { id, name, permissions }) => {
        if (permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.MANAGE)) {
            acc.push({ id, name });
        }

        return acc;
    }, []);

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.owner' />
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.TARGET_CREDENTIAL_FORM_OWNER}
                placeholder={<FormattedMessage id='credentials.ownerPlaceholder' />}
                items={items}
                inputProperties={input}
                disabled={isEditMode || isOperationInProgress}
                error={meta.error}
                trackTiming={true}
                trackingName='Target credential owner'
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

const TargetCredentialForm: FunctionComponent<CredentialFormProps> = ({
    isEditMode,
    change,
    handleSubmit
}) => {
    const dispatch = useAppDispatch();

    const params = useParams<RouteMatchParams>();

    const isOperationInProgress = useSelector(getTargetCredentialFormOperationInProgress);
    const manifestLoadStatus = useSelector(getTargetCredentialManifestLoadStatus);
    const manifest = useSelector(getTargetCredentialManifest);
    const data: ingestionApiDefinitions['TargetCredentialDetailResponseDto'] = useSelector(getTargetCredentialDetails);

    let defaultValues: { [key: string]: string } | undefined;
    if (isEditMode) {
        defaultValues = data.credentialContext ? data.credentialContext.reduce((acc, { key, value }) => {
            acc[key] = value;

            return acc;
        }, {}) : {};
    }

    useEffect(() => {
        dispatch(resetTargetCredentialForm());
        dispatch(resetTargetCredentialManifest());

        if (isEditMode) {
            const { ownerId, credentialId } = params;

            dispatch(resetTargetCredentialDetails());
            dispatch(getTargetCredentialData(ownerId, credentialId));
        }
    }, [ isEditMode, params ]);

    useDidUpdateEffect(() => {
        if (isEditMode) {
            change('name', data.name);
            change('targetType', data.targetType);
            change('authMethod', data.authMethod);
            change('ownerId', data.ownerId);
        }
    }, [ isEditMode, data ]);

    return (
        <Fragment>
            <form id='targetCredentialForm' onSubmit={handleSubmit} autoComplete='off'>
                <Field
                    name='name'
                    isEditMode={isEditMode}
                    isOperationInProgress={isOperationInProgress}
                    component={NameField}
                />
                <Field
                    name='ownerId'
                    isEditMode={isEditMode}
                    isOperationInProgress={isOperationInProgress}
                    component={OwnerField}
                />
                <Field
                    name='targetType'
                    isEditMode={isEditMode}
                    isOperationInProgress={isOperationInProgress}
                    change={change}
                    component={TargetTypeField}
                />
                <Field
                    name='authMethod'
                    isEditMode={isEditMode}
                    isOperationInProgress={isOperationInProgress}
                    component={AuthMethodField}
                />
                {manifestLoadStatus === LOAD_STATUS.LOADING && <SkeletonSectionLoaderIndicator />}
                {manifestLoadStatus === LOAD_STATUS.LOADED &&
                    <DynamicFieldsSection
                        prefix='credentialContext'
                        change={change}
                        config={manifest}
                        disabled={isOperationInProgress}
                        editMode={isEditMode}
                        defaultValues={defaultValues}
                        form='targetCredentialForm'
                    />
                }
            </form>
        </Fragment>
    );
};

export default TargetCredentialForm;
