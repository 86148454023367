import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import { ingestionApiDefinitions } from 'modules/service/types';
import { LOAD_STATUS, PERMISSIONS, AUTOMATION_ID } from 'modules/common/constants';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { LoadStatus, Owner, RouteComponentProps } from 'modules/common/interfaces';

import local from './local.module.scss';

const SKELETON_PROPERTIES_COUNT = 2;

interface CollectorCredentialDetailsLayoutProps {
    owners: Owner[];
    data: ingestionApiDefinitions['CredentialDetailResponseDto'];
    loadStatus: LoadStatus;
    loadData: Function;
    resetData: Function;
    goToEditCollectorCredentialPage: Function;
    goToCollectorCredentialsListPage: Function;
}

interface RouteMatchParams {
    ownerId: string;
    credentialId: string;
}

class CollectorCredentialDetailsLayout extends Component<CollectorCredentialDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public componentDidMount() {
        const { loadData, resetData, match: { params: { ownerId, credentialId } } } = this.props;
        const { canView } = this.checkAccess();

        resetData();

        if (canView) {
            loadData(ownerId, credentialId);
        }
    }

    public componentDidUpdate(prevProps) {
        const { match: { params: { ownerId: prevOwnerId, credentialId: prevCredentialId } } } = prevProps;
        const { loadData, resetData, match: { params: { ownerId, credentialId } } } = this.props;

        // in case when owner or credential ID in page URL is changed by user, component is not re-mounted, so need to check access again
        if ((ownerId !== prevOwnerId) || (credentialId !== prevCredentialId)) {
            const { canView } = this.checkAccess();

            resetData();

            if (canView) {
                loadData(ownerId, credentialId);
            }
        }
    }

    public render() {
        const { canView, canManage } = this.checkAccess();

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const { name: ownerName } = this.getOwner() as Owner;
        const {
            data: { name, collector, updateTimestamp = 0 },
            match: { params: { ownerId, credentialId } },
            goToEditCollectorCredentialPage,
            loadStatus
        } = this.props;

        const isLoaded = loadStatus === LOAD_STATUS.LOADED;

        return (
            <div>
                {this.renderBreadcrumbs()}
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            {
                                isLoaded ?
                                    name :
                                    <SkeletonItem width='320px' height='24px' />
                            }
                        </div>
                        {
                            canManage && <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.COLLECTOR_CREDENTIAL_EDIT_BTN}
                                    disabled={!isLoaded}
                                    onClick={() => { goToEditCollectorCredentialPage(credentialId, ownerId); }}
                                >
                                    <FormattedMessage id='common.edit' />
                                </button>
                            </div>
                        }
                    </div>
                    <div className={local.ownerName}>
                        {
                            isLoaded ?
                                ownerName :
                                <SkeletonItem width='160px' height='20px' />
                        }
                    </div>
                    <div className={local.collector}>
                        <FormattedMessage id='common.collector' />
                    </div>
                    <div className={local.collectorType}>
                        {
                            isLoaded ?
                                collector :
                                <SkeletonItem width='160px' height='24px' />
                        }
                    </div>
                    <div>
                        {
                            isLoaded ?
                                this.renderCredentialContext() :
                                Array.apply(null, Array(SKELETON_PROPERTIES_COUNT)).map((el, index) => (
                                    <div key={index}>
                                        <div className={local.propertyName}>
                                            <SkeletonItem width='160px' height='20px' />
                                        </div>
                                        <div className={local.propertyValue}>
                                            <SkeletonItem width='320px' height='16px' />
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                    {
                        isLoaded &&
                        <Fragment>
                            <div className={local.divider} />
                            <div className={local.lastUpdatedTitle}>
                                <FormattedMessage id='common.lastUpdated' />
                            </div>
                            <div className={local.lastUpdatedValue}>
                                {moment.utc(updateTimestamp).format('DD MMMM YYYY [at] HH:mm UTC')}
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        );
    }

    private getOwner = () => {
        const { owners, match: { params: { ownerId } } } = this.props;

        return owners.find(({ id }) => id === ownerId);
    }

    private checkAccess = () => {
        const owner = this.getOwner();

        const canView = owner && owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.VIEW);
        const canManage = owner && owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE);

        return { canView, canManage };
    }

    private renderCredentialContext = () => {
        const { credentialContext, collectorId } = this.props.data;
        const properties: JSX.Element[] = [];

        credentialContext?.forEach(({ key, value, isSecret }) => {
            properties.push(
                <div key={key}>
                    <div className={local.propertyName}>
                        <FormattedMessage id={`${collectorId}.${key}`} defaultMessage={key} />
                    </div>
                    <div className={local.propertyValue}>
                        {isSecret && value ? '*'.repeat(12) : <FormattedMessage id={`${collectorId}.${key}.option.${value}`} defaultMessage={value || ' '} />}
                    </div>
                </div>
            );
        });

        return properties;
    }

    private renderBreadcrumbs = () => {
        const { goToCollectorCredentialsListPage, data: { name = '' } } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_CREDENTIALS_BREADCRUMB,
                        label: (<FormattedMessage id='credentials.allCredentials' />),
                        onClick: () => { goToCollectorCredentialsListPage(); }
                    },
                    {
                        label: name
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }
}

export default CollectorCredentialDetailsLayout;
