import * as t from '../actionTypes';

const ACTION_HANDLERS = {
    [t.ADD_SYNC_ERROR]: (state, action) => {
        const { field, error } = action.payload;
        const { syncErrors } = state;

        const retVal = syncErrors ? JSON.parse(JSON.stringify(syncErrors)) : {};

        let current = retVal;

        field.split('.').forEach((part, idx, arr) => {
            if (idx === arr.length - 1) {
                current[part] = error;
            } else {
                if (!current.hasOwnProperty(part)) {
                    current[part] = {};
                }

                current = current[part];
            }
        });

        return { ...state, syncErrors: retVal };
    },
    [t.REMOVE_SYNC_ERROR]: (state, action) => {
        const { field } = action.payload;
        const { syncErrors } = state;

        const retVal = syncErrors ? JSON.parse(JSON.stringify(syncErrors)) : {};

        let current = retVal;
        let notFound = false;

        field.split('.').forEach((part, idx, arr) => {
            if (notFound) {
                return;
            }

            if (idx === arr.length - 1) {
                if (current.hasOwnProperty(part)) {
                    delete current[part];
                } else {
                    notFound = true;
                }
            } else {
                if (!current.hasOwnProperty(part)) {
                    notFound = true;
                }

                current = current[part];
            }
        });

        return notFound ? state : { ...state, syncErrors: retVal };
    },
    [t.REMOVE_SUBMIT_ERROR]: (state, action) => {
        const { field } = action.payload;
        const { submitErrors } = state;

        const retVal = submitErrors ? JSON.parse(JSON.stringify(submitErrors)) : {};

        let current = retVal;
        let notFound = false;

        field.split('.').forEach((part, idx, arr) => {
            if (notFound) {
                return;
            }

            if (idx === arr.length - 1) {
                if (current.hasOwnProperty(part)) {
                    delete current[part];
                } else {
                    notFound = true;
                }
            } else {
                if (!current.hasOwnProperty(part)) {
                    notFound = true;
                }

                current = current[part];
            }
        });

        return notFound ? state : { ...state, submitErrors: retVal };
    }
};

export default (form, state, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler && action?.payload?.form === form ? handler(state, action) : state;
};
