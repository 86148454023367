import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import { ExpandMore } from '@mui/icons-material';

import service from 'modules/service';
import { useAppDispatch } from 'modules/common/hooks';
import { getClientsList, getSelectedClientId } from 'modules/auth/selectors';
import { saveSelectedClientId } from 'modules/auth/actions';

import local from './local.module.scss';

const ClientSelect: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const clients = useSelector(getClientsList);
    const selectedClientId = useSelector(getSelectedClientId);

    const clientsItems = clients.map(({ id, name }, index) =>
        <MenuItem
            key={index}
            value={id}
            classes={{
                root: local.item,
                selected: local.selected
            }}
        >
            {name}
        </MenuItem>
    );

    return (
        <Select
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                classes: {
                    paper: local.paper
                }
            }}
            classes={{
                select: local.select,
                icon: local.icon
            }}
            input={<Input classes={{
                root: local.root,
                underline: local.underline
            }} />}
            IconComponent={ExpandMore}
            value={selectedClientId}
            onChange={(event) => {
                const { value } = event.target;

                if (value !== selectedClientId) {
                    service.analytics.trackEvent('Change active client');

                    dispatch(saveSelectedClientId(value));
                    dispatch(push('/'));
                }
            }}
        >
            {clientsItems}
        </Select>
    );
};

export default ClientSelect;
