import React, { Fragment, FunctionComponent, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch } from 'modules/common/hooks';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import LoadingSpinner from 'modules/common/components/LoadingSpinner';
import { LOAD_STATUS } from 'modules/common/constants';
import { getDatasetsListProps } from '../../selectors';
import { loadDatasets, resetCatalog, selectDataset } from '../../actions';

import local from './local.module.scss';

interface ListItemProps {
    id: string;
    isSelected: boolean;
    isDataLoading: boolean;
}

const ListItem: FunctionComponent<ListItemProps> = ({ id, isSelected, isDataLoading }) => {
    const dispatch = useAppDispatch();

    const clickHandler = useCallback(() => {
        if (!isSelected && !isDataLoading) {
            dispatch(selectDataset(id));
        }
    }, [ isSelected, isDataLoading ]);

    return (
        <div
            className={classnames(local.item, { [local.selected]: isSelected, [local.disabled]: isDataLoading }, 'container-row')}
            onClick={clickHandler}
        >
            <TextWithTooltip>{id}</TextWithTooltip>
            <FontAwesomeIcon icon={faChevronRight} className={local.itemIcon} />
        </div>
    );
};

const DatasetsList: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const {
        items,
        loadStatus,
        selectedDatasetId,
        isDataLoading,
        isSearchInProgress
    } = useSelector(getDatasetsListProps);

    useEffect(() => {
        dispatch(resetCatalog());
        dispatch(loadDatasets());
    }, []);

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='common.platforms' />
            </div>
            {
                isSearchInProgress || loadStatus === LOAD_STATUS.LOADING ?
                    <LoadingSpinner className={local.spinner} /> :
                    items.length ?
                        items.map((id) => (
                            <ListItem
                                key={id}
                                id={id}
                                isSelected={id === selectedDatasetId}
                                isDataLoading={isDataLoading}
                            />
                        )) :
                        <div className={local.noDataMessage}>
                            <FormattedMessage id='common.noData' />
                        </div>
            }
        </Fragment>
    );
};

export default DatasetsList;
