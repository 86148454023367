import { createSelector } from 'reselect';

import { REDUCER_NAME, TARGETS_LIST_SORT_ATTRIBUTES_MAP } from '../constants';

// targets list
export const getTargetsListState = (state) => state[REDUCER_NAME].targetsList;

// common
export const getTargetManifest = (state) => state[REDUCER_NAME].common.manifest.object;
export const getTargetManifestLoadStatus = (state) => state[REDUCER_NAME].common.manifest.loadStatus;

// target details
export const getTargetDetails = (state) => state[REDUCER_NAME].targetDetails.data;
export const getTargetDetailsLoadStatus = (state) => state[REDUCER_NAME].targetDetails.loadStatus;
export const getTargetDetailsOperationInProgress = (state) => state[REDUCER_NAME].targetDetails.targetOperationInProgress;

// target form
export const getTargetFormOperationInProgress = (state) => state[REDUCER_NAME].targetForm.targetOperationInProgress;

export const getTargetsListSearchPayload = createSelector(
    getTargetsListState,
    ({ page, pageSize, sortBy, sortOrder, searchBy }) => ({
        orderBy: [
            {
                attribute: TARGETS_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering: sortOrder
            }
        ],
        searchString: searchBy,
        page,
        pageSize
    })
);
