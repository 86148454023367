import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';

import { ingestionApiDefinitions } from 'modules/service/types';
import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import SelectInput from 'modules/common/components/SelectInput';
import Input from 'modules/common/components/Input';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import { LoadStatus, Owner, RouteComponentProps } from 'modules/common/interfaces';

import local from '../CollectorCredentialFormLayout/local.module.scss';

interface CollectorCredentialFormProps {
    intl: IntlShape;
    resetForm: Function;
    resetData: Function;
    getCollectors: Function;
    getManifest: Function;
    getCredentialDataForEditing: Function;
    data: ingestionApiDefinitions['CredentialDetailResponseDto'];
    dataLoadStatus: LoadStatus;
    collectorsLoadStatus: LoadStatus;
    collectors: Array<ingestionApiDefinitions['CollectorSummaryResponseDto']>;
    collectorId: string;
    manifestLoadStatus: LoadStatus;
    manifest: ingestionApiDefinitions['ManifestDto'];
    isOperationInProgress: boolean;
    owners: Owner[];
    isEditMode: boolean;
}

interface RouteMatchParams {
    ownerId: string;
    credentialId: string;
}

class CollectorCredentialForm extends Component<CollectorCredentialFormProps & InjectedFormProps<{}, CollectorCredentialFormProps> & RouteComponentProps<RouteMatchParams>> {
    public componentDidMount() {
        const {
            isEditMode,
            match,
            resetForm,
            resetData,
            getCollectors,
            getCredentialDataForEditing,
            change
        } = this.props;

        resetForm();

        if (isEditMode) {
            const { ownerId, credentialId } = match.params;

            resetData();
            getCredentialDataForEditing(ownerId, credentialId);

            change('ownerId', ownerId);
        } else {
            getCollectors();
        }
    }

    public componentDidUpdate(prevProps) {
        const {
            isEditMode,
            match,
            data,
            change,
            resetData,
            resetForm,
            getCredentialDataForEditing
        } = this.props;

        if (isEditMode) {
            const { match: { params: { ownerId: prevOwnerId, credentialId: prevCredentialId } }, data: prevData } = prevProps;
            const { params: { ownerId, credentialId } } = match;

            if ((ownerId !== prevOwnerId) || (credentialId !== prevCredentialId)) {
                resetForm();
                resetData();
                getCredentialDataForEditing(ownerId, credentialId);

                change('ownerId', ownerId);
            }

            if (data !== prevData) {
                change('name', data.name);
                change('collectorId', data.collectorId);
            }
        }
    }

    public render() {
        const {
            isOperationInProgress,
            handleSubmit,
            collectorsLoadStatus,
            collectors,
            data,
            manifestLoadStatus,
            dataLoadStatus
        } = this.props;

        return (
            <Fragment>
                <form id='collectorCredentialForm' onSubmit={handleSubmit} autoComplete='off'>
                    <Field
                        name='name'
                        dataLoadStatus={dataLoadStatus}
                        isOperationInProgress={isOperationInProgress}
                        component={this.renderNameField}
                    />
                    <Field
                        name='ownerId'
                        isOperationInProgress={isOperationInProgress}
                        component={this.renderOwnerField}
                    />
                    <Field
                        name='collectorId'
                        collectorsLoadStatus={collectorsLoadStatus}
                        collectors={collectors}
                        data={data}
                        isOperationInProgress={isOperationInProgress}
                        component={this.renderCollectorField}
                    />
                    {manifestLoadStatus === LOAD_STATUS.LOADING && (<SkeletonSectionLoaderIndicator />)}
                    {this.getDynamicSection()}
                </form>
            </Fragment>
        );
    }

    private getDynamicSection = () => {
        const {
            isEditMode,
            isOperationInProgress,
            change,
            manifest,
            data: { credentialContext },
            manifestLoadStatus,
            collectorId
        } = this.props;

        let defaultValues: { [key: string]: string } | undefined;
        if (isEditMode) {
            defaultValues = credentialContext ? credentialContext.reduce((acc, { key, value }) => {
                acc[key] = value;

                return acc;
            }, {}) : {};
        }

        return (
            (manifestLoadStatus === LOAD_STATUS.LOADED && manifest.panels) ?
                <DynamicFieldsSection
                    prefix={collectorId}
                    change={change}
                    config={manifest}
                    disabled={isOperationInProgress}
                    editMode={isEditMode}
                    defaultValues={defaultValues}
                    form='collectorCredentialForm'
                /> :
                null
        );
    }

    private renderNameField = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { isEditMode, intl } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'credentials.namePlaceholder';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.name' />
                </div>
                <Input
                    inputProperties={input}
                    id={AUTOMATION_ID.COLLECTOR_CREDENTIAL_FORM_NAME}
                    className={classnames({ 'error-input': Boolean(meta.error) })}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    maxLength={255}
                    trackTiming={true}
                    trackingName='Credential name'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderOwnerField = ({ input, isOperationInProgress, meta }) => {
        const { isEditMode, owners } = this.props;

        const items = owners.map(({ id, name }) => ({ id, name }));
        const placeholder = <FormattedMessage id='credentials.ownerPlaceholder' />;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.owner' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.COLLECTOR_CREDENTIAL_FORM_OWNER}
                    optionClassName={AUTOMATION_ID.COLLECTOR_CREDENTIAL_FORM_OWNER_OPTION}
                    placeholder={placeholder}
                    items={items}
                    inputProperties={input}
                    disabled={isEditMode || isOperationInProgress}
                    error={meta.error}
                    trackTiming={true}
                    trackingName='Credential owner'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderCollectorField = ({ input, collectors, collectorsLoadStatus, data, isOperationInProgress, meta }) => {
        const { isEditMode } = this.props;

        const onChange = (value) => {
            this.onCollectorSelect(value);
            input.onChange(value);
        };

        let items;

        if (isEditMode) {
            const { collectorId: id, collector: name } = data;

            items = [ { id, name } ];
        } else {
            items = collectors.map(({ id, description }) => ({
                id,
                name: description
            }));
        }

        const placeholderMessageId = isEditMode ? 'common.loading' : 'credentials.collectorPlaceholder';
        const placeholder = <FormattedMessage id={placeholderMessageId} />;

        return (
            <div>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.collector' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.COLLECTOR_CREDENTIAL_FORM_COLLECTOR}
                    optionClassName={AUTOMATION_ID.COLLECTOR_CREDENTIAL_FORM_COLLECTOR_OPTION}
                    placeholder={placeholder}
                    items={items}
                    inputProperties={{ ...input, onChange }}
                    disabled={isEditMode || isOperationInProgress}
                    error={meta.error}
                    isLoading={isEditMode ? collectorsLoadStatus !== LOAD_STATUS.LOADED : false}
                    searchable={true}
                    trackTiming={true}
                    trackingName='Credential collector'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private onCollectorSelect = (value) => {
        const { getManifest } = this.props;

        getManifest(value);
    }
}

export default CollectorCredentialForm;
