import { createIntl } from 'react-intl';

import messages_en from './translations/en/main.json';

const messages = {
    en: messages_en
};

const supportedLanguages = Object.keys(messages);

const defaultLocale = 'en';
const { language } = navigator;

// select language from user agent (if it's not supported - use default locale)
export const locale = supportedLanguages.includes(language) ? language : defaultLocale;

// fetch static asset with messages for manifests (only for current locale)
(async () => {
    let json = {};

    try {
        const response = await fetch(`static/translations/${locale}.json`);
        json = await response.json();
    } catch (err) {
        console.error(err);
    }

    for (const id in json) {
        if (json.hasOwnProperty(id)) {
            messages[locale][id] = json[id];
        }
    }
})();

export const intl = createIntl({ messages: messages[locale], locale });

export { default as IntlProviderWrapper } from './components/IntlProviderWrapper';
