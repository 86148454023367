import arrayMove from 'array-move';

import * as t from '../actionTypes';
import { SOURCE_DETAILS_VIEW, SCHEMA_MODE, SOURCES_LIST_PAGE_SIZE, SOURCE_STATE } from 'modules/sources/constants';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';

const ACTION_HANDLERS = {
    [t.SET_SOURCES_LIST_ITEMS]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            items: action.payload
        }
    }),
    [t.APPEND_SOURCES_LIST_ITEMS]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            items: state.sourcesList.items.concat(action.payload)
        }
    }),
    [t.SET_SOURCES_LIST_PAGE]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            page: action.payload
        }
    }),
    [t.SET_SOURCES_LIST_COUNT]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            totalCount: action.payload
        }
    }),
    [t.SET_SOURCES_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            sortBy: action.payload
        }
    }),
    [t.SET_SOURCES_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            sortOrder: action.payload
        }
    }),
    [t.SET_SOURCES_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            loadStatus: action.payload
        }
    }),
    [t.SET_SOURCES_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            searchBy: action.payload
        }
    }),
    [t.RESET_SOURCES_LIST]: (state) => ({
        ...state,
        sourcesList: initialState.sourcesList
    }),
    [t.SET_SOURCE_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        sourceDetails: {
            ...state.sourceDetails,
            loadStatus: action.payload
        }
    }),
    [t.SET_SOURCE_DETAILS]: (state, action) => ({
        ...state,
        sourceDetails: {
            ...state.sourceDetails,
            data: action.payload
        }
    }),
    [t.SET_ADDITIONAL_SOURCE_DETAILS]: (state, action) => ({
        ...state,
        sourceDetails: {
            ...state.sourceDetails,
            additionalData: action.payload
        }
    }),
    [t.RESET_SOURCE_DETAILS]: (state) => ({
        ...state,
        sourceDetails: initialState.sourceDetails
    }),
    [t.SET_SOURCE_DETAILS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        sourceDetails: {
            ...state.sourceDetails,
            sourceOperationInProgress: action.payload
        }
    }),
    [t.SET_SOURCE_DETAILS_VIEW]: (state, action) => ({
        ...state,
        sourceDetails: {
            ...state.sourceDetails,
            view: action.payload
        }
    }),
    [t.SET_SOURCE_FORM_COLLECTORS]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            collectors: {
                ...state.sourceForm.collectors,
                list: action.payload
            }
        }
    }),
    [t.SET_SOURCE_FORM_COLLECTORS_LOAD_STATUS]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            collectors: {
                ...state.sourceForm.collectors,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_SOURCE_FORM_SCHEMA]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            schema: action.payload
        }
    }),
    [t.SET_SOURCE_FORM_SCHEMA_BACKUP]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            schemaBackup: action.payload
        }
    }),
    [t.SET_SOURCE_FORM_MANIFESTS]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            manifests: {
                ...state.sourceForm.manifests,
                objects: action.payload
            }
        }
    }),
    [t.SET_SOURCE_FORM_MANIFESTS_LOAD_STATUS]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            manifests: {
                ...state.sourceForm.manifests,
                loadStatus: action.payload
            }
        }
    }),
    [t.RESET_SOURCE_FORM]: (state) => ({
        ...state,
        sourceForm: initialState.sourceForm
    }),
    [t.SET_SOURCE_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            sourceOperationInProgress: action.payload
        }
    }),
    [t.SET_SOURCE_FORM_MODE]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            mode: action.payload
        }
    }),
    [t.ADD_DEDUPLICATE_COLUMN]: (state) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            deduplicateColumns: [ ...state.sourceForm.deduplicateColumns, { field: '', order: SORT_ORDER.ASC }]
        }
    }),
    [t.SET_DEDUPLICATE_COLUMN_NAME]: (state, action) => {
        const { index, field } = action.payload;
        const deduplicateColumns = [ ...state.sourceForm.deduplicateColumns ];
        deduplicateColumns[index] = { field, order: deduplicateColumns[index].order };

        return {
            ...state,
            sourceForm: {
                ...state.sourceForm,
                deduplicateColumns
            }
        };
    },
    [t.SET_DEDUPLICATE_COLUMN_ORDER]: (state, action) => {
        const { index, order } = action.payload;
        const deduplicateColumns = [ ...state.sourceForm.deduplicateColumns ];
        deduplicateColumns[index] = { field: deduplicateColumns[index].field, order };

        return {
            ...state,
            sourceForm: {
                ...state.sourceForm,
                deduplicateColumns
            }
        };
    },
    [t.MOVE_DEDUPLICATE_COLUMN]: (state, action) => {
        const { oldIndex, newIndex } = action.payload;
        const deduplicateColumns = arrayMove([ ...state.sourceForm.deduplicateColumns ], oldIndex, newIndex);

        return {
            ...state,
            sourceForm: {
                ...state.sourceForm,
                deduplicateColumns
            }
        };
    },
    [t.REMOVE_DEDUPLICATE_COLUMN]: (state, action) => {
        const deduplicateColumns = [ ...state.sourceForm.deduplicateColumns ];
        deduplicateColumns.splice(action.payload, 1);

        return {
            ...state,
            sourceForm: {
                ...state.sourceForm,
                deduplicateColumns
            }
        };
    },
    [t.SET_DEDUPLICATE_COLUMNS]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            deduplicateColumns: action.payload
        }
    }),
    [t.SET_SOURCE_FORM_DRAFT_FLAG]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            isOnlyDraftExisting: action.payload
        }
    }),
    [t.SET_SOURCES_LIST_FILTERS_STATES]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            filters: {
                ...state.sourcesList.filters,
                states: action.payload
            }
        }
    }),
    [t.SET_SOURCES_LIST_FILTERS_OWNER_IDS]: (state, action) => ({
        ...state,
        sourcesList: {
            ...state.sourcesList,
            filters: {
                ...state.sourcesList.filters,
                ownerIds: action.payload
            }
        }
    }),
    [t.SET_SOURCE_FORM_DATASTREAM_TYPES]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            datastreamTypes: {
                ...state.sourceForm.datastreamTypes,
                list: action.payload
            }
        }
    }),
    [t.SET_SOURCE_FORM_DATASTREAM_TYPES_LOAD_STATUS]: (state, action) => ({
        ...state,
        sourceForm: {
            ...state.sourceForm,
            datastreamTypes: {
                ...state.sourceForm.datastreamTypes,
                loadStatus: action.payload
            }
        }
    })
};

export const initialState = {
    sourcesList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: SOURCES_LIST_PAGE_SIZE,
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED,
        filters: {
            states: [ SOURCE_STATE.ACTIVE ],
            ownerIds: []
        }
    },
    sourceDetails: {
        loadStatus: LOAD_STATUS.REQUIRED,
        data: {},
        additionalData: {},
        view: SOURCE_DETAILS_VIEW.MAIN,
        sourceOperationInProgress: false
    },
    sourceForm: {
        mode: SCHEMA_MODE.SIMPLE,
        collectors: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        manifests: {
            objects: {},
            loadStatus: LOAD_STATUS.REQUIRED
        },
        datastreamTypes: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        schema: {},
        schemaBackup: {},
        deduplicateColumns: [],
        sourceOperationInProgress: false,
        isOnlyDraftExisting: false
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
