import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getUserProfile } from 'modules/auth/selectors';
import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { AUTOMATION_ID, EVENT_TYPE_GROUP, LOAD_STATUS, PERMISSIONS, SERVICE } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import { TabPanel, Tabs } from 'modules/common/components/Tabs';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import ButtonWithTooltip from 'modules/common/components/ButtonWithTooltip';
import SubscriptionsMenu from 'modules/common/components/SubscriptionsMenu';
import ReportPlatformsList from './ReportPlatformsList';
import ReportInstancesList from './ReportInstancesList';
import { INSTANCE_ASSETS_TAB_INDEX, PLATFORM_ASSETS_TAB_INDEX, REPORT_EXECUTION_STATUS } from '../../constants';
import { goToReportsListPage, loadReportAssets, rerunReportFromAssetsPage } from '../../actions';
import { getReportAssetsData } from '../../selectors';

import local from './local.module.scss';

type RouteMatchParams = Record<'clientId' | 'reportId', string>;

const ReportAssetsLayout: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const params = useParams<RouteMatchParams>();

    const [
        selectedTabIndex,
        setSelectedTabIndex
    ] = useState(PLATFORM_ASSETS_TAB_INDEX);

    const { clients } = useSelector(getUserProfile);
    const client = clients.find(({ id }) => id === (params.clientId));
    const canAccess = client?.permissions.includes(PERMISSIONS.REPORTS.MANAGE);

    const {
        data: { name, platforms, instances },
        loadStatus,
        reportOperationInProgress
    } = useSelector(getReportAssetsData);

    const isAnyAssetRunning = platforms?.some(({ executionStatus }) => executionStatus === REPORT_EXECUTION_STATUS.RUNNING) ||
        instances?.some(({ executionStatus }) => executionStatus === REPORT_EXECUTION_STATUS.RUNNING);

    const statusesToCheck = [ REPORT_EXECUTION_STATUS.SUCCEEDED, REPORT_EXECUTION_STATUS.UNKNOWN ];
    const showRerunConfirmationMessage =
        (platforms?.length ? platforms.every(({ executionStatus }) => statusesToCheck.includes(executionStatus)) : true) &&
        (instances?.length ? instances.every(({ executionStatus }) => statusesToCheck.includes(executionStatus)) : true);

    const isDisabled = (loadStatus !== LOAD_STATUS.LOADED) || reportOperationInProgress;

    useEffect(() => {
        dispatch(loadReportAssets(params.reportId));
    }, [ params ]);

    useDidUpdateEffect(() => {
        if (loadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(loadReportAssets(params.reportId));
        }
    }, [ loadStatus ]);

    if (!canAccess) {
        return (
            <ErrorPage error='403' />
        );
    }

    return (
        <Fragment>
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_REPORTS_BREADCRUMB,
                        label: (<FormattedMessage id='reports.allReports' />),
                        onClick: () => { dispatch(goToReportsListPage()); }
                    },
                    {
                        label: name || ''
                    }
                ]}
                selectedItemIndex={1}
            />
            <div className={classnames(local.tabsContainer, 'container-row')}>
                <Tabs
                    tabClasses={{ root: local.tabRoot }}
                    value={selectedTabIndex}
                    onChange={(event, idx) => {
                        setSelectedTabIndex(idx);
                    }}
                    labels={[
                        <FormattedMessage id='common.platforms' />,
                        <FormattedMessage id='common.transformationInstances' />
                    ]}
                    disabled={isDisabled}
                />
                <div className={classnames(local.controlsContainer, 'container-row')}>
                    <SubscriptionsMenu
                        referenceEntityId={params.reportId!}
                        serviceName={SERVICE.REPORTING}
                        eventTypeGroup={EVENT_TYPE_GROUP.REPORT}
                        disabled={isDisabled}
                    />
                    <ButtonWithTooltip
                        buttonText={<FormattedMessage id='reports.rerunReport' />}
                        tooltipText={<FormattedMessage id='reports.rerunReportDisabledMessage' />}
                        tooltipCondition={isAnyAssetRunning}
                        disabled={isDisabled || isAnyAssetRunning}
                        onClick={() => {
                            dispatch(rerunReportFromAssetsPage(params.reportId, showRerunConfirmationMessage));
                        }}
                    />
                </div>
            </div>
            <TabPanel index={PLATFORM_ASSETS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <ReportPlatformsList />
            </TabPanel>
            <TabPanel index={INSTANCE_ASSETS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <ReportInstancesList />
            </TabPanel>
        </Fragment>
    );
};

export default ReportAssetsLayout;
