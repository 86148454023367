import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import FeedAdHocRunForm from '../FeedAdHocRunForm';
import { getFeedDetails, getIsAdHocRunDialogOpen } from '../../selectors';
import { closeAdHocRunDialog, runFeed } from '../../actions';

import local from './local.module.scss';

const FeedAdHocRunDialog = () => {
    const dispatch = useAppDispatch();

    const isAdHocRunDialogOpen = useSelector(getIsAdHocRunDialogOpen);
    const { feedId, feedVersionId } = useSelector(getFeedDetails);

    const onSubmit = useCallback((values) => {
        dispatch(clearSubmitErrors('feedAdHocRunForm'));

        return dispatch(runFeed(feedId, feedVersionId, values));
    }, [ feedId, feedVersionId ]);

    return (
        <Dialog
            open={isAdHocRunDialogOpen}
            title={<FormattedMessage id='common.runNow' />}
            onClose={() => { dispatch(closeAdHocRunDialog()); }}
            classes={{ body: local.body, paper: local.paper }}
        >
            <FeedAdHocRunForm onSubmit={onSubmit} />
        </Dialog>
    );
};

export default FeedAdHocRunDialog;
