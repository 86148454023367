import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { RouteItem } from '../interfaces';

export const renderRoutes = (routes: RouteItem[]) => (
    <Routes>
        {
            routes.map((route, index) => {
                const { path, params } = route;

                return (
                    <Route key={index} path={path} element={
                        <route.component {...params} />
                    } />
                );
            })
        }
    </Routes>
);
