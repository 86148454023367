import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { push } from 'redux-first-history';

import { useAppDispatch } from 'modules/common/hooks';

import local from './local.module.scss';

interface TabProps {
    id: string;
    name: string;
    route: string;
}

const Tab: FunctionComponent<TabProps> = ({ id, name, route }) => {
    const dispatch = useAppDispatch();

    return (
        <a
            id={id}
            className={local.tab}
            onClick={(event) => {
                if (!event.ctrlKey) {
                    dispatch(push(route));
                }
            }}
            href={`#${route}`}
        >
            <FormattedMessage id={name} />
        </a>
    );
};

export default Tab;
