import { createSelector } from 'reselect';

import { getUserProfile } from 'modules/auth/selectors';
import { sort } from 'modules/common/utils';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { REDUCER_NAME as moduleReducerName } from '../../constants';
import { REDUCER_NAME } from '../constants';

// clients list
export const getClientsListItems = (state) => state[moduleReducerName][REDUCER_NAME].clientsList.items;
export const getClientsListLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].clientsList.loadStatus;
export const getClientsListTableState = (state) => state[moduleReducerName][REDUCER_NAME].clientsList.tableState;

// client form
export const getClientFormOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].clientForm.clientOperationInProgress;

// client memberships list
export const getClientMembershipsListTableState = (state) => state[moduleReducerName][REDUCER_NAME].clientMembershipsList.tableState;
export const getClientMembershipOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].clientMembershipsList.clientMembershipOperationInProgress;
export const getIsClientMembershipDialogOpen = (state) => state[moduleReducerName][REDUCER_NAME].clientMembershipsList.isClientMembershipDialogOpen;

// client details
export const getClientDetails = (state) => state[moduleReducerName][REDUCER_NAME].clientDetails.data;
export const getClientDetailsLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].clientDetails.loadStatus;

// client groups list
export const getClientGroupsListTableState = (state) => state[moduleReducerName][REDUCER_NAME].clientGroupsList.tableState;

export const getClientsListLayoutProps = createSelector(
    getUserProfile,
    getClientsListLoadStatus,
    ({ clients, odpMemberAdministrator }, status) => ({
        canView: odpMemberAdministrator || clients.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW)),
        canManage: odpMemberAdministrator,
        status
    })
);

export const getClientsListProps = createSelector(
    getClientsListItems,
    getClientsListLoadStatus,
    getClientsListTableState,
    (items, status, { searchBy, sortBy, sortOrder, page, pageSize }) => {
        const filteredItems = items
            .filter(({ clientName }) => clientName.toLowerCase().includes(searchBy.toLowerCase()));

        return {
            status,
            sortBy,
            sortOrder,
            totalCount: filteredItems.length,
            items: sort(filteredItems, sortBy, sortOrder).slice(0, (page + 1) * pageSize)
        };
    }
);

export const getClientFormLayoutProps = createSelector(
    getUserProfile,
    getClientFormOperationInProgress,
    getClientDetails,
    getClientDetailsLoadStatus,
    ({ odpMemberAdministrator }, isOperationInProgress, data, dataLoadStatus) => ({
        isOperationInProgress,
        isDataLoading: dataLoadStatus === LOAD_STATUS.LOADING,
        canAccess: odpMemberAdministrator,
        displayName: data.name || ''
    })
);

const getClientDetailsLayoutProps = (state, { match: { params: { clientId } } }) => {
    const status = getClientDetailsLoadStatus(state);
    const isOperationInProgress = getClientMembershipOperationInProgress(state);
    const { name: clientName } = getClientDetails(state);
    const {
        clients,
        id: currentMemberId,
        odpMemberAdministrator: isOdpMemberAdministrator
    } = getUserProfile(state);

    const currentUserClient = clients.find(({ id }) => id === clientId);
    const canView = Boolean(currentUserClient?.permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW));
    const canManage = Boolean(currentUserClient?.permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE));

    // important: checking direct membership is essential here, user profile contains inherited memberships too,
    // so search is performed on client data, which contains only direct memberships
    const { memberships } = getClientDetails(state);
    const currentUserHasMembership = Boolean(memberships?.find(({ memberId }) => memberId === currentMemberId));

    return {
        status,
        canView,
        canManage,
        clientName,
        isOperationInProgress,
        isOdpMemberAdministrator,
        currentUserHasMembership
    };
};

export const makeClientDetailsLayoutProps = () => createSelector(
    getClientDetailsLayoutProps,
    (object) => object
);

export const getClientMembershipsListProps = createSelector(
    getUserProfile,
    getClientDetails,
    getClientDetailsLoadStatus,
    getClientMembershipsListTableState,
    getClientMembershipOperationInProgress,
    ({ id }, { memberships = [] }, status, { searchBy, sortBy, sortOrder, page, pageSize }, isOperationInProgress) => {
        const filteredItems = memberships.filter(({ username }) => username.toLowerCase().includes(searchBy.toLowerCase()));

        return {
            status,
            sortBy,
            sortOrder,
            isOperationInProgress,
            currentUserId: id,
            totalCount: filteredItems.length,
            items: sort(filteredItems, sortBy, sortOrder).slice(0, (page + 1) * pageSize)
        };
    }
);

export const getClientGroupsListProps = createSelector(
    getClientDetails,
    getClientDetailsLoadStatus,
    getClientGroupsListTableState,
    ({ groups = [] }, loadStatus, { sortBy, sortOrder, page, pageSize }) => ({
        loadStatus,
        sortBy,
        sortOrder,
        totalCount: groups?.length,
        items: sort(groups, sortBy, sortOrder).slice(0, (page + 1) * pageSize)
    })
);
