import moment from 'moment';

import service from 'modules/service';
import { getErrorText, showErrorSnackbar } from 'modules/common/utils';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { getRouterPath } from 'modules/common/selectors';
import * as authSelectors from 'modules/auth/selectors';
import * as selectors from '../selectors';
import * as storeActions from './storeActions';
import { UPLOADS_ROUTES } from '../constants';

export const getUploads = () => async (dispatch, getState) => {
    dispatch(storeActions.setUploadsListItemsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const clientId = authSelectors.getSelectedClientId(storeState);
        const uploadsType = selectors.getSelectedUploadsType(storeState);
        const { date } = selectors.getUploadsListState(storeState);

        const uploads = await service.api.getUploads(clientId, uploadsType.toLowerCase(), date.format('YYYY'), date.format('MM'));

        dispatch(storeActions.setUploadsListItems(uploads));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setUploadsListItemsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getUploadsByDate = (date) => async (dispatch) => {
    dispatch(storeActions.setUploadsListItems([]));
    dispatch(storeActions.setUploadsListDate(date));

    await dispatch(getUploads());
};

export const uploadFile = (file: File) => async (dispatch, getState) => {
    dispatch(storeActions.setUploadInProgress(true));
    dispatch(storeActions.setUploadSuccess(null));
    dispatch(storeActions.setUploadErrors([]));

    try {
        const storeState = getState();

        const clientId = authSelectors.getSelectedClientId(storeState);
        const uploadsType = selectors.getSelectedUploadsType(storeState);

        await service.api.uploadFile(clientId, uploadsType.toLowerCase(), file);
        dispatch(storeActions.setUploadSuccess(true));

        service.analytics.trackEvent('Upload new file', EVENT_CATEGORY.UPLOADS, uploadsType);

        const newStoreState = getState();
        // reload uploads list only if user is still on uploads page, has same client, uploads type and current month selected
        if (getRouterPath(newStoreState) === UPLOADS_ROUTES.LIST &&
            authSelectors.getSelectedClientId(newStoreState) === clientId &&
            selectors.getSelectedUploadsType(newStoreState) === uploadsType &&
            selectors.getUploadsListState(newStoreState).date.month() === moment().month()) {
            dispatch(storeActions.setUploadsListItemsLoadStatus(LOAD_STATUS.REQUIRED));
        }
    } catch (error) {
        dispatch(storeActions.setUploadSuccess(false));

        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            dispatch(storeActions.setUploadErrors(data.validationErrors.map(({ description }) => description)));

            service.analytics.trackEvent('Upload validation fail', EVENT_CATEGORY.UPLOADS);
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setUploadInProgress(false));
    }
};

export const setSelectedUploadsType = (type) => (dispatch) => {
    dispatch(storeActions.setUploadsType(type));
    dispatch(storeActions.setUploadSuccess(null));
    dispatch(storeActions.setUploadErrors([]));
    dispatch(storeActions.setUploadsListItemsLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('Set uploads type', EVENT_CATEGORY.UPLOADS, type);
};

export const resetUploadsPage = () => (dispatch) => {
    dispatch(storeActions.setUploadsType(null));
    dispatch(storeActions.resetUploadsList());
};

export const uploadsListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const { sortBy, sortOrder } = selectors.getUploadsListState(storeState);

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setUploadsListSortOrder(order));
    } else {
        dispatch(storeActions.setUploadsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setUploadsListSortOrder(SORT_ORDER.ASC));
        }
    }

    service.analytics.trackEvent('Uploads list sort', EVENT_CATEGORY.UPLOADS);
};
