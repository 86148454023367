import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { getPermissionsForSelectedClient, getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { LOAD_STATUS, PERMISSIONS, TEMP_ID_PREFIX } from 'modules/common/constants';
import { REDUCER_NAME } from '../constants';

// feeds list
export const getFeedsListItems = (state) => state[REDUCER_NAME].feedsList.items;
export const getFeedsListPage = (state) => state[REDUCER_NAME].feedsList.page;
export const getFeedsListPageSize = (state) => state[REDUCER_NAME].feedsList.pageSize;
export const getFeedsListTotalCount = (state) => state[REDUCER_NAME].feedsList.totalCount;
export const getFeedsListLoadStatus = (state) => state[REDUCER_NAME].feedsList.loadStatus;
export const getFeedsListSortColumn = (state) => state[REDUCER_NAME].feedsList.sortBy;
export const getFeedsListSortOrder = (state) => state[REDUCER_NAME].feedsList.sortOrder;
export const getFeedsListSearchText = (state) => state[REDUCER_NAME].feedsList.searchBy;

// feed details
export const getFeedDetails = (state) => state[REDUCER_NAME].feedDetails.data;
export const getAdditionalFeedDetails = (state) => state[REDUCER_NAME].feedDetails.additionalData;
export const getFeedDetailsLoadStatus = (state) => state[REDUCER_NAME].feedDetails.loadStatus;
export const getFeedDetailsOperationInProgress = (state) => state[REDUCER_NAME].feedDetails.feedOperationInProgress;
export const getFeedDetailsOperationInProgressReporterData = (state) => state[REDUCER_NAME].feedDetails.feedOperationInProgressReporterData;
export const getFeedDetailsView = (state) => state[REDUCER_NAME].feedDetails.view;
export const getIsAdHocRunDialogOpen = (state) => state[REDUCER_NAME].feedDetails.isAdHocRunDialogOpen;
export const getIsFeedDeleteDialogOpen = (state) => state[REDUCER_NAME].feedDetails.isFeedDeleteDialogOpen;
export const getFeedDependentInstances = (state) => state[REDUCER_NAME].feedDetails.dependentInstances;

// feed form
export const getFeedFormSources = (state) => state[REDUCER_NAME].feedForm.sources.list;
export const getFeedFormSourcesLoadStatus = (state) => state[REDUCER_NAME].feedForm.sources.loadStatus;
export const getFeedFormSourceDetails = (state) => state[REDUCER_NAME].feedForm.sourceDetails.object;
export const getFeedFormSourceDetailsLoadStatus = (state) => state[REDUCER_NAME].feedForm.sourceDetails.loadStatus;
export const getCollectorConfigurationManifest = (state) => state[REDUCER_NAME].feedForm.collectorConfigurationManifest.manifest;
export const getCollectorConfigurationManifestLoadStatus = (state) => state[REDUCER_NAME].feedForm.collectorConfigurationManifest.loadStatus;
export const getFeedFormCollectorCredentials = (state) => state[REDUCER_NAME].feedForm.collectorCredentials.list;
export const getFeedFormCollectorCredentialsLoadStatus = (state) => state[REDUCER_NAME].feedForm.collectorCredentials.loadStatus;
export const getFeedFormOperationInProgress = (state) => state[REDUCER_NAME].feedForm.feedOperationInProgress;
export const getFeedFormSchedules = (state) => state[REDUCER_NAME].feedForm.schedules;
export const getFeedFormSelectedTargets = (state) => state[REDUCER_NAME].feedForm.feedTargets;
export const getFeedFormTargets = (state) => state[REDUCER_NAME].feedForm.targets.list;
export const getFeedFormTargetsLoadStatus = (state) => state[REDUCER_NAME].feedForm.targets.loadStatus;
export const getFeedFormTargetCredentials = (state) => state[REDUCER_NAME].feedForm.targetCredentials.list;
export const getFeedFormTargetCredentialsLoadStatus = (state) => state[REDUCER_NAME].feedForm.targetCredentials.loadStatus;
export const getFeedFormAdverityAuthorizations = (state) => state[REDUCER_NAME].feedForm.adverityAuthorizations.list;
export const getFeedFormAdverityAuthorizationsLoadStatus = (state) => state[REDUCER_NAME].feedForm.adverityAuthorizations.loadStatus;

// feed runs
export const getFeedRunsListData = (state) => state[REDUCER_NAME].feedRuns;
export const getFeedRunsListLoadStatus = (state) => state[REDUCER_NAME].feedRuns.loadStatus;
export const getFeedRunsListSelectedItemsIds = (state) => state[REDUCER_NAME].feedRuns.selectedItemsIds;
export const getFeedRunsListOperationInProgress = (state) => state[REDUCER_NAME].feedRuns.feedRunsOperationInProgress;
export const getFeedRunsListItems = (state) => state[REDUCER_NAME].feedRuns.items;
export const getFeedRunsListTotalCount = (state) => state[REDUCER_NAME].feedRuns.totalCount;
export const getFeedRunsListSortBy = (state) => state[REDUCER_NAME].feedRuns.sortBy;
export const getFeedRunsListSortOrder = (state) => state[REDUCER_NAME].feedRuns.sortOrder;
export const getFeedRunsListFilters = (state) => state[REDUCER_NAME].feedRuns.filters;

// common
export const getCollectionOffsetTemplate = (state) => state[REDUCER_NAME].common.collectionOffsetTemplate.list;
export const getCollectionOffsetTemplateLoadStatus = (state) => state[REDUCER_NAME].common.collectionOffsetTemplate.loadStatus;

export const getFeedsListSearchPayload = createSelector(
    getFeedsListPage,
    getFeedsListPageSize,
    getFeedsListSortOrder,
    getFeedsListSearchText,
    (page, pageSize, ordering, searchString) => ({
        // feed item title consists of name and suffix, but backend orders results by these fields separately,
        // so we need to pass array with two objects
        orderBy: [
            {
                attribute: 'NAME',
                ordering
            },
            {
                attribute: 'SUFFIX',
                ordering
            }
        ],
        searchString,
        page,
        pageSize
    })
);

export const getFeedsListLayoutProps = createSelector(
    getUserProfile,
    getSelectedClientId,
    getFeedsListItems,
    getFeedsListLoadStatus,
    getFeedsListTotalCount,
    ({ clients }, clientId, items, status, totalCount) => {
        const client = clients.find(({ id }) => id === clientId);
        const permissions = client?.permissions || [];

        return {
            items,
            status,
            totalCount,
            canView: permissions.includes(PERMISSIONS.FEEDS.VIEW),
            canManage: permissions.includes(PERMISSIONS.FEEDS.MANAGE)
        };
    }
);

export const getFeedsListProps = createSelector(
    getFeedsListItems,
    getFeedsListLoadStatus,
    getFeedsListSortOrder,
    getFeedsListSortColumn,
    getSelectedClientId,
    (items, status, sortOrder, sortBy, clientId) => ({
        items,
        status,
        sortOrder,
        sortBy,
        clientId
    })
);

export const getFeedDetailsProps = createSelector(
    getUserProfile,
    getFeedDetailsLoadStatus,
    getFeedDetails,
    getAdditionalFeedDetails,
    getFeedDetailsView,
    getIsAdHocRunDialogOpen,
    getFeedDetailsOperationInProgress,
    getFeedDetailsOperationInProgressReporterData,
    (
        { clients, owners },
        loadStatus,
        data,
        additionalData,
        view,
        isAdHocRunDialogOpen,
        isOperationInProgress,
        operationInProgressReporterData
    ) => ({
        clients,
        owners,
        loadStatus,
        data,
        additionalData,
        view,
        isAdHocRunDialogOpen,
        isOperationInProgress,
        operationInProgressReporterData
    })
);

export const getFeedFormValues = (state) => {
    const { feedForm } = state.form;

    return feedForm?.values || {};
};

export const getFeedFormLayoutProps = createSelector(
    getFeedFormOperationInProgress,
    getUserProfile,
    getSelectedClientId,
    getFeedDetails,
    getFeedDetailsLoadStatus,
    getFeedFormSourceDetailsLoadStatus,
    getCollectorConfigurationManifestLoadStatus,
    getFeedFormSourcesLoadStatus,
    getFeedFormCollectorCredentialsLoadStatus,
    (
        isOperationInProgress,
        { clients },
        selectedClientId,
        data,
        dataLoadStatus,
        sourceDataLoadStatus,
        manifestLoadStatus,
        sourcesLoadStatus,
        credentialsLoadStatus
    ) => ({
        isOperationInProgress,
        client: clients.find(({ id }) => id === selectedClientId),
        displayName: (data.name && data.suffix) ? `${data.name}_${data.suffix}` : '',
        isDataLoading: [ dataLoadStatus, manifestLoadStatus, sourceDataLoadStatus ].includes(LOAD_STATUS.LOADING) ||
            sourcesLoadStatus !== LOAD_STATUS.LOADED || credentialsLoadStatus !== LOAD_STATUS.LOADED
    })
);

const getCollectorId = (state) => {
    const selector = formValueSelector('feedForm');

    const collectorId = selector(state, 'collectorId');

    return collectorId && collectorId.split('_')[0];
};

const getSourceId = (state) => {
    const selector = formValueSelector('feedForm');

    return selector(state, 'sourceId');
};

const getFeedFormDetails = (state, { isPendingVersion }) => {
    const additionalData = getAdditionalFeedDetails(state);

    return isPendingVersion && Object.keys(additionalData).length ?
        additionalData :
        getFeedDetails(state);
};

export const getFeedFormProps = createSelector(
    getFeedFormDetails,
    getFeedFormOperationInProgress,
    getFeedDetailsLoadStatus,
    (
        data,
        isOperationInProgress,
        dataLoadStatus
    ) => ({
        data,
        isOperationInProgress,
        dataLoadStatus
    })
);

export const getDataCollectionSectionProps = createSelector(
    getCollectionOffsetTemplate,
    getCollectionOffsetTemplateLoadStatus,
    getFeedFormSchedules,
    getFeedFormValues,
    (
        collectionOffsetTemplate,
        collectionOffsetTemplateLoadStatus,
        schedules,
        { method, sourceState }
    ) => ({
        collectionOffsetTemplate,
        collectionOffsetTemplateLoadStatus,
        schedules,
        sourceState,
        method
    })
);

export const getConfigurationSectionProps = createSelector(
    getFeedFormSources,
    getFeedFormSourcesLoadStatus,
    getFeedFormSourceDetails,
    getFeedFormSourceDetailsLoadStatus,
    getFeedFormCollectorCredentials,
    getFeedFormCollectorCredentialsLoadStatus,
    getCollectorConfigurationManifest,
    getCollectorConfigurationManifestLoadStatus,
    getCollectorId,
    getSourceId,
    (
        sources,
        sourcesLoadStatus,
        sourceDetails,
        sourceDetailsLoadStatus,
        credentials,
        credentialsLoadStatus,
        collectorConfigurationManifest,
        collectorConfigurationManifestLoadStatus,
        collectorId,
        sourceId
    ) => ({
        sources,
        sourcesLoadStatus,
        sourceDetails,
        sourceDetailsLoadStatus,
        credentials,
        credentialsLoadStatus,
        collectorConfigurationManifest,
        collectorConfigurationManifestLoadStatus,
        collectorId,
        sourceId
    })
);

export const getFeedAdHocRunCollectionOffsetTemplateId = (state) => {
    const selector = formValueSelector('feedAdHocRunForm');

    return selector(state, 'collectionOffsetTemplateId');
};

export const getReportRangeType = (state) => {
    const selector = formValueSelector('feedAdHocRunForm');

    return selector(state, 'reportRangeType');
};

export const getFeedAdHocRunStartDate = (state) => {
    const selector = formValueSelector('feedAdHocRunForm');

    return selector(state, 'collectionStartDate');
};

export const getFeedAdHocRunEndDate = (state) => {
    const selector = formValueSelector('feedAdHocRunForm');

    return selector(state, 'collectionEndDate');
};

export const getFeedAdHocRunCollectionOffsetDays = (state) => {
    const selector = formValueSelector('feedAdHocRunForm');

    return selector(state, 'collectionOffsetDays');
};

export const getFeedRunsListSearchPayload = createSelector(
    getFeedRunsListData,
    ({ page, pageSize, /*sortBy, ordering, searchBy,*/ filters }) => {
        const { statuses, fetchStart, fetchEnd, runDate } = filters;

        let executionStart;
        let executionEnd;

        if (runDate) {
            executionStart = runDate.clone().startOf('day').format('YYYY-MM-DD HH:mm:ss');
            executionEnd = runDate.clone().add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
        }

        return {
            // TODO add orderBy when sorting will be implemented
            page,
            pageSize,
            executionStart,
            executionEnd,
            statuses,
            // TODO uncomment when search and filtering by status will be implemented
            // searchString: searchBy,
            fetchStart: fetchStart?.format('YYYY-MM-DD'),
            fetchEnd: fetchEnd?.format('YYYY-MM-DD')
        };
    }
);

export const getFeedRunsLayoutProps = createSelector(
    getFeedRunsListFilters,
    getFeedRunsListLoadStatus,
    getFeedRunsListSelectedItemsIds,
    getFeedRunsListOperationInProgress,
    getPermissionsForSelectedClient,
    ({ status }, loadStatus, selectedItemsIds, isOperationInProgress, clientPermissions) => ({
        status,
        loadStatus,
        selectedItemsIds,
        isOperationInProgress,
        clientPermissions
    })
);

export const getFeedRunsListProps = createSelector(
    getFeedRunsListItems,
    getFeedRunsListTotalCount,
    getFeedRunsListSortBy,
    getFeedRunsListSortOrder,
    getFeedRunsListLoadStatus,
    getFeedRunsListOperationInProgress,
    getSelectedClientId,
    getPermissionsForSelectedClient,
    (items, totalCount, sortBy, sortOrder, loadStatus, isOperationInProgress, clientId, clientPermissions) => ({
        items,
        totalCount,
        sortBy,
        sortOrder,
        loadStatus,
        clientId,
        clientPermissions,
        isOperationInProgress
    })
);

const getFeedScheduleProps = (state, { index }) => {
    const { id } = getFeedFormSchedules(state)[index] || {};
    const scheduleFormValues = state.form.feedForm.values.schedules?.[`schedule_${index}`];

    let data;
    if (id && !id.startsWith(TEMP_ID_PREFIX)) {
        data = getFeedDetails(state).schedules?.find((schedule) => schedule.id === id);
    }

    return { id, data, ...scheduleFormValues };
};

export const makeFeedScheduleProps = () => createSelector(
    getFeedScheduleProps,
    (object) => object
);
