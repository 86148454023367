import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import MuiDrawer from '@mui/material/Drawer';
import { Scrollbars } from 'react-custom-scrollbars-2';

import local from './local.module.scss';

interface ScrollableDrawerProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    title: ReactNode;
    buttons?: ReactNode;
    width?: number;
}

const DEFAULT_WIDTH = 424;

const ScrollableDrawer: FunctionComponent<PropsWithChildren<ScrollableDrawerProps>> = ({
    isOpen,
    setOpen,
    title,
    buttons,
    width,
    children
}) => (
    <MuiDrawer
        anchor='right'
        open={isOpen}
        ModalProps={{
            BackdropProps: {
                classes: { root: local.backdropRoot }
            }
        }}
        PaperProps={{
            classes: { root: local.paperRoot },
            sx: { width: width || DEFAULT_WIDTH }
        }}
    >
        <div className={classnames(local.container, 'container-column')}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className={local.title}>
                    {title}
                </div>
                <div
                    className={local.closeIconContainer}
                    onClick={() => { setOpen(false); }}
                >
                    <FontAwesomeIcon icon={faClose} className={local.closeIcon} />
                </div>
                <Scrollbars style={{ flexGrow: 1 }}>
                    {children}
                </Scrollbars>
            </div>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                {buttons}
            </div>
        </div>
    </MuiDrawer>
);

export default ScrollableDrawer;
