import React, { FunctionComponent, PropsWithChildren } from 'react';

import local from './local.module.scss';

interface TabPanelProps {
    index: number;
    selectedTabIndex: number;
}

const TabPanel: FunctionComponent<PropsWithChildren<TabPanelProps>> = ({ children, index, selectedTabIndex }) => (
    <div className={local.card} style={{ display: index === selectedTabIndex ? 'block' : 'none' }}>
        {children}
    </div>
);

export default TabPanel;
