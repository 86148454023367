import { push } from 'redux-first-history';

import { COLLECTOR_CREDENTIALS_TAB_INDEX, CREDENTIALS_ROUTES } from '../constants';

export const goToCredentialsListPage = (
    tabIndex: number | undefined = COLLECTOR_CREDENTIALS_TAB_INDEX,
    adverityAuthorizationTypeId: number | undefined = undefined
) => (dispatch) => {
    dispatch(push(CREDENTIALS_ROUTES.LIST, { tabIndex, adverityAuthorizationTypeId }));
};
