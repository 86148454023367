import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

export const getRouterPath = (state) => state.router.location.pathname;

const getDynamicFieldDependOnValue = (state, { form, prefix, panelId, config: { dependOn } }) => {
    const selector = formValueSelector(form);

    return selector(state, `${prefix}.${panelId}.${dependOn}`);
};

export const getDynamicFieldProps = () => createSelector(
    getDynamicFieldDependOnValue,
    (dependOnValue) => ({ dependOnValue })
);
