import { push } from 'redux-first-history';
import { SubmissionError } from 'redux-form';

import service from 'modules/service';
import { memberApiDefinitions } from 'modules/service/types';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, PROMISE_STATUS, SORT_ORDER } from 'modules/common/constants';
import { showErrorSnackbar, getErrorText, prepareFormErrors, formatString } from 'modules/common/utils';
import { getUserProfile } from 'modules/auth/selectors';
import { loadUserProfile } from 'modules/auth/actions';
import { MEMBERS_ROUTES } from '../constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

export const getMembersList = () => async (dispatch, getState) => {
    dispatch(storeActions.setMembersListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const payload = selectors.getMembersListSearchPayload(getState());
        const list: memberApiDefinitions['ApiPaginatedCollectionContainerDto«MemberSearchResponseDto»'] = await service.api.getMembersSearchList(payload);

        dispatch(storeActions.appendMembersListItems(list.objects));
        dispatch(storeActions.setMembersListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setMembersListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreMembers = () => async (dispatch, getState) => {
    const page = selectors.getMembersListPage(getState()) + 1;

    dispatch(storeActions.setMembersListPage(page));
    await dispatch(getMembersList());

    service.analytics.trackEvent('Load more members', EVENT_CATEGORY.ADMIN);
};

export const membersListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const currentSortColumn = selectors.getMembersListSortColumn(storeState);
    const currentSortOrder = selectors.getMembersListSortOrder(storeState);

    if (column === currentSortColumn) {
        const order = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setMembersListSortOrder(order));
    } else {
        dispatch(storeActions.setMembersListSortColumn(column));

        if (currentSortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setMembersListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setMembersListPage(0));
    dispatch(storeActions.setMembersListItems([]));
    dispatch(storeActions.setMembersListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('Members list sort', EVENT_CATEGORY.ADMIN);
};

export const membersListSearch = (text) => (dispatch, getState) => {
    const currentSearchText = selectors.getMembersListSearchText(getState());

    if (text !== currentSearchText) {
        dispatch(storeActions.setMembersListPage(0));
        dispatch(storeActions.setMembersListItems([]));
        dispatch(storeActions.setMembersListSearchText(text));
        dispatch(storeActions.setMembersListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('Members list search', EVENT_CATEGORY.ADMIN);
    }
};

export const goToMembersListPage = () => (dispatch) => {
    dispatch(push(MEMBERS_ROUTES.LIST));
};

export const addMember = (formValues) => async (dispatch) => {
    dispatch(storeActions.setMembersListOperationInProgress(true));

    try {
        await service.api.addMember(formValues);
        service.analytics.trackEvent('Add member', EVENT_CATEGORY.ADMIN);

        dispatch(storeActions.setMembersListPage(0));
        dispatch(storeActions.setMembersListItems([]));
        dispatch(storeActions.setMembersListLoadStatus(LOAD_STATUS.REQUIRED));

        dispatch(storeActions.closeMemberDialog());
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            throw new SubmissionError(prepareFormErrors(data));
        } else if (status === HTTP_CODE.CONFLICT) {
            // TODO remove this when proper validation error will be implemented on BE
            throw new SubmissionError({ username: 'custom.MemberAlreadyExists' });
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setMembersListOperationInProgress(false));
    }
};

export const getMemberDetails = (memberId) => async (dispatch) => {
    dispatch(storeActions.setMemberDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const [memberDetailsResult, groupsResult] = await Promise.allSettled([
            service.api.getMember(memberId),
            service.api.getGroupsList()
        ]);

        if (memberDetailsResult.status === PROMISE_STATUS.FULFILLED) {
            const { value: memberDetails } = memberDetailsResult as PromiseFulfilledResult<memberApiDefinitions['MemberProfileResponseDto']>;

            memberDetails.owners?.sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1));
            memberDetails.clients?.sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1));
            memberDetails.groups?.sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1));

            dispatch(storeActions.setMemberDetails(memberDetails));
        } else {
            const { reason } = memberDetailsResult as PromiseRejectedResult;
            showErrorSnackbar(getErrorText(reason));
        }

        if (groupsResult.status === PROMISE_STATUS.FULFILLED) {
            const { value: { groups } } = groupsResult as PromiseFulfilledResult<memberApiDefinitions['ListGroupLiteResponseDto']>;

            groups!.sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1));
            dispatch(storeActions.setGroupsList(groups));
        } else {
            const { reason } = groupsResult as PromiseRejectedResult;
            showErrorSnackbar(getErrorText(reason));
        }
    } catch (error) {
        showErrorSnackbar(getErrorText(error));
    } finally {
        dispatch(storeActions.setMemberDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const addOwnerMembership = (memberId, formValues) => async (dispatch, getState) => {
    dispatch(storeActions.setMemberDetailsOperationInProgress(true));

    try {
        const { ownerId, roles } = formValues;
        await service.api.addOwnerMembership(memberId, ownerId, roles);

        service.analytics.trackEvent('Add owner member', EVENT_CATEGORY.ADMIN);

        const { id } = getUserProfile(getState());
        if (id === memberId) {
            await dispatch(loadUserProfile());
        }

        dispatch(storeActions.resetMemberDetails());
        dispatch(storeActions.closeOwnerMembershipDialog());
    } catch (error) {
        showErrorSnackbar(getErrorText(error));
    } finally {
        dispatch(storeActions.setMemberDetailsOperationInProgress(false));
    }
};

export const addClientMembership = (memberId, formValues) => async (dispatch, getState) => {
    dispatch(storeActions.setMemberDetailsOperationInProgress(true));

    try {
        const { clientId, roles } = formValues;
        await service.api.addClientMembership(memberId, clientId, roles);

        service.analytics.trackEvent('Add client member', EVENT_CATEGORY.ADMIN);

        const { id } = getUserProfile(getState());
        if (id === memberId) {
            await dispatch(loadUserProfile());
        }

        dispatch(storeActions.resetMemberDetails());
        dispatch(storeActions.closeClientMembershipDialog());
    } catch (error) {
        showErrorSnackbar(getErrorText(error));
    } finally {
        dispatch(storeActions.setMemberDetailsOperationInProgress(false));
    }
};

export const goToMemberDetailsPage = (id) => (dispatch) => {
    dispatch(push(formatString(MEMBERS_ROUTES.DETAILS, id)));
};

export const addGroupMembership = (groupId) => async (dispatch, getState) => {
    dispatch(storeActions.setMemberDetailsOperationInProgress(true));

    try {
        const storeState = getState();
        const { id, username } = selectors.getMemberDetails(storeState);

        await service.api.addGroupMemberships(groupId, { usernames: [ username ] });

        service.analytics.trackEvent('Add group member', EVENT_CATEGORY.ADMIN);

        const { id: currentMemberId } = getUserProfile(storeState);
        if (id === currentMemberId) {
            await dispatch(loadUserProfile());
        }

        dispatch(storeActions.resetMemberDetails());
        dispatch(storeActions.closeGroupMembershipDialog());
    } catch (error) {
        showErrorSnackbar(getErrorText(error));
    } finally {
        dispatch(storeActions.setMemberDetailsOperationInProgress(false));
    }
};
