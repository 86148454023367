import React, { FunctionComponent, Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ExpandMore } from '@mui/icons-material';

import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import { getSourcesListLoadStatus } from '../../selectors';
import { goToAddSourcePage } from '../../actions';

import local from './local.module.scss';

const NewSourceMenu: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleMenuClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [ setAnchorEl ]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [ setAnchorEl ]);

    const isOpen = Boolean(anchorEl);

    const disabled = useSelector(getSourcesListLoadStatus) !== LOAD_STATUS.LOADED;

    const itemClasses = {
        root: local.item,
        focusVisible: local.focusedItem
    };

    return (
        <Fragment>
            <div className='ls-button'>
                <button
                    id={AUTOMATION_ID.NEW_SOURCE_BTN}
                    disabled={disabled}
                    onClick={handleMenuClick}
                    className={classnames(local.newSourceButton, 'container-row')}
                >
                    <FormattedMessage id='sources.newSource' />
                    <ExpandMore className={classnames(local.icon, { [local.iconOpen]: isOpen })} />
                </button>
            </div>
            <Menu
                anchorEl={anchorEl}
                keepMounted={true}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ paper: local.paper }}
                MenuListProps={{
                    sx: { width: anchorEl?.offsetWidth }
                }}
            >
                <MenuItem
                    id={AUTOMATION_ID.NEW_SOURCE_BTN_LIVE}
                    onClick={() => { dispatch(goToAddSourcePage()); }}
                    classes={itemClasses}
                >
                    <FormattedMessage id='common.live' />
                </MenuItem>
                <MenuItem
                    id={AUTOMATION_ID.NEW_SOURCE_BTN_TEST}
                    onClick={() => { dispatch(goToAddSourcePage(true)); }}
                    classes={itemClasses}
                >
                    <FormattedMessage id='common.test' />
                    <IconWithTooltip className={local.searchTooltip}>
                        <FormattedMessage id='sources.testOptionTooltip' />
                    </IconWithTooltip>
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default NewSourceMenu;
