import React, { FunctionComponent, PropsWithChildren } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import styleConstants from 'assets/styles/theme/exports.module.scss';

const theme = createTheme({
    palette: {
        text: {
            primary: styleConstants.textColor
        },
        primary: {
            light: styleConstants.highlightColorLight,
            main: styleConstants.highlightColor,
            dark: styleConstants.highlightColorDark
        }
    },
    typography: {
        fontFamily: 'inter400',
        fontSize: 14
    }
});

const ThemeProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => (
    <MuiThemeProvider theme={theme}>
        {children}
    </MuiThemeProvider>
);

export default ThemeProvider;
