import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { getSelectedUploadsType } from '../../selectors';
import UploadTypesList from '../UploadTypesList';
import NewUpload from '../NewUpload';
import UploadsHistory from '../UploadsHistory';

import local from './local.module.scss';

const UploadsLayout: FunctionComponent = () => {
    const type = useSelector(getSelectedUploadsType);

    const { clients } = useSelector(getUserProfile);
    const selectedClientId = useSelector(getSelectedClientId);
    const client = clients.find(({ id }) => id === selectedClientId);

    if (!client || !client.permissions.includes(PERMISSIONS.UPLOADS.VIEW)) {
        return (
            <ErrorPage error='403' />
        );
    }

    return (
        <div className='container-row'>
            <div className={local.uploadTypes}>
                <UploadTypesList />
            </div>
            {
                type ?
                    <div className={local.uploads}>
                        {
                            client.permissions.includes(PERMISSIONS.UPLOADS.UPLOAD) && <NewUpload />
                        }
                        <UploadsHistory />
                    </div> :
                    <div className={local.selectType}>
                        <FormattedMessage id='uploads.selectTypeMessage' />
                    </div>
            }
        </div>
    );
};

export default UploadsLayout;
