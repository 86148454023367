import { createSelector } from 'reselect';

import { getRouterPath } from 'modules/common/selectors';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { PERMISSIONS } from 'modules/common/constants';
import {
    ADMIN_MENU_CLIENTS_ITEM,
    ADMIN_MENU_GROUPS_ITEM,
    ADMIN_MENU_OWNERS_ITEM,
    ADMIN_MENU_MEMBERS_ITEM,
    REDUCER_NAME
} from '../constants';
import { isMenuItemAccessible, isMenuItemSelected } from '../utils';

const getMenuItems = (state) => state[REDUCER_NAME].menuItems;

export const getMenuItemsForUser = createSelector(
    getMenuItems,
    getUserProfile,
    getSelectedClientId,
    (items, userProfile, selectedClientId) =>
        items.map((item) => {
            if (item.subItems) {
                const accessibleSubItems = item.subItems.filter((subItem) => isMenuItemAccessible(subItem, userProfile, selectedClientId));

                return accessibleSubItems.length ? { ...item, subItems: accessibleSubItems } : null;
            } else {
                return isMenuItemAccessible(item, userProfile, selectedClientId) ? item : null;
            }
        }).filter(Boolean)
);

export const getTabs = createSelector(
    getMenuItemsForUser,
    getRouterPath,
    (menuItems, routerPath) => {
        const tabIndex = menuItems.findIndex((item) =>
            item.subItems ?
                item.subItems.some((subItem) => isMenuItemSelected(subItem, routerPath)) :
                isMenuItemSelected(item, routerPath)
        );

        return {
            tabs: menuItems,
            tabIndex,
            tabSubIndex: tabIndex !== -1 && menuItems[tabIndex].subItems ?
                menuItems[tabIndex].subItems.findIndex((subItem) => isMenuItemSelected(subItem, routerPath)) : -1
        };
    }
);

export const getAdminMenuItems = createSelector(
    getUserProfile,
    ({ odpMemberAdministrator, clients, owners, groups }) => {
        const items: object[] = [];
        const userCanViewMembershipsForAnyClient = clients.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW));
        const userCanViewMembershipsForAnyOwner = owners.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW));

        if (odpMemberAdministrator) {
            items.push(ADMIN_MENU_MEMBERS_ITEM);
        }

        if (groups.length || userCanViewMembershipsForAnyClient || userCanViewMembershipsForAnyOwner) {
            items.push(ADMIN_MENU_GROUPS_ITEM);
        }

        if (odpMemberAdministrator || userCanViewMembershipsForAnyClient) {
            items.push(ADMIN_MENU_CLIENTS_ITEM);
        }

        if (odpMemberAdministrator || userCanViewMembershipsForAnyOwner) {
            items.push(ADMIN_MENU_OWNERS_ITEM);
        }

        return items;
    }
);

export const isAdminMenuAccessible = createSelector(
    getAdminMenuItems,
    ({ length }) => Boolean(length)
);
