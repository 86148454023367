export const REDUCER_NAME = 'targetCredentials';

export const BASE_PATH = '/target-credentials';

export const TARGET_CREDENTIALS_ROUTES = {
    DETAILS: `${BASE_PATH}/view/{0}/{1}`,
    NEW: `${BASE_PATH}/add`,
    EDIT: `${BASE_PATH}/edit/{0}/{1}`
};

export const TARGET_CREDENTIALS_LIST_PAGE_SIZE = 50;

export const TARGET_CREDENTIALS_LIST_SORT_ATTRIBUTES_MAP = {
    name: 'NAME',
    targetType: 'TARGET_TYPE',
    authMethod: 'AUTH_METHOD',
    updateTimestamp: 'LAST_UPDATE',
    ownerName: 'OWNER'
};
