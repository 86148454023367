import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import { SORT_ORDER } from 'modules/common/constants';
import { SortOrder } from 'modules/common/interfaces';

import local from './local.module.scss';

interface SortableTableHeaderProps {
    title: ReactNode;
    column: any;
    sortBy?: string;
    sortOrder?: SortOrder;
}

const SortableTableHeader = ({ title, column, sortBy, sortOrder }: SortableTableHeaderProps) => (
    <div>
        {title}
        {sortBy === column.id && <FontAwesomeIcon icon={sortOrder === SORT_ORDER.ASC ? faArrowUp : faArrowDown} className={local.sortOrderIcon} />}
    </div>
);

export default SortableTableHeader;
