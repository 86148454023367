import { connect } from 'react-redux';

import * as actions from 'modules/transformationPipelines/actions';
import { getPipelinesListProps } from 'modules/transformationPipelines/selectors';
import PipelinesList from './PipelinesList';

const mapDispatchToProps = {
    onSort: actions.pipelinesListSort,
    goToPipelineDetailsPage: actions.goToPipelineDetailsPage
};

export default connect(getPipelinesListProps, mapDispatchToProps)(PipelinesList);
