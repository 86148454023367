import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Field, InjectedFormProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { intl } from 'modules/i18n';
import { memberApiDefinitions } from 'modules/service/types';
import { LoadStatus, RouteComponentProps } from 'modules/common/interfaces';
import { LOAD_STATUS } from 'modules/common/constants';
import Input from 'modules/common/components/Input';
import { GROUP_ITEM_TYPE } from '../../constants';
import GroupItem, { GroupItemData } from '../GroupItem';

import local from './local.module.scss';

interface RouteMatchParams {
    groupId: string;
}

interface GroupFormProps {
    isOperationInProgress: boolean;
    isEditMode: boolean;
    canAssignClients: boolean;
    canAssignOwners: boolean;
    groupClients: GroupItemData[];
    groupOwners: GroupItemData[];
    data: memberApiDefinitions['GroupDetailsResponseDto'];
    dataLoadStatus: LoadStatus;
    resetForm: Function;
    resetData: Function;
    getData: Function;
    addItem: Function;
    initClientsAndOwners: Function;
}

const normalizeGroupName = (value) => value.replace(/^[^a-zA-Z]+|[^a-zA-Z0-9_]/g, '');

class GroupForm extends Component<GroupFormProps & InjectedFormProps<{}, GroupFormProps> & RouteComponentProps<RouteMatchParams>> {
    public componentDidMount() {
        const { isEditMode, resetForm, resetData, getData, match: { params: { groupId } } } = this.props;

        resetForm();

        if (isEditMode) {
            resetData();
            getData(groupId);
        }
    }

    public componentDidUpdate(prevProps) {
        const {
            isEditMode,
            match,
            data,
            getData,
            dataLoadStatus,
            change,
            resetData,
            resetForm,
            initClientsAndOwners
        } = this.props;

        if (isEditMode) {
            const { match: { params: { groupId: prevGroupId } }, data: prevData } = prevProps;
            const { params: { groupId } } = match;

            if (groupId !== prevGroupId) {
                resetForm();
                resetData();
                getData(groupId);
            }

            if (data !== prevData && dataLoadStatus === LOAD_STATUS.LOADED) {
                change('name', data.name);
                change('description', data.description);
                initClientsAndOwners(data);
            }
        }
    }

    public render() {
        const { dataLoadStatus, isOperationInProgress, handleSubmit } = this.props;

        return (
            <form id='groupForm' onSubmit={handleSubmit} autoComplete='off'>
                <Field
                    name='name'
                    dataLoadStatus={dataLoadStatus}
                    isOperationInProgress={isOperationInProgress}
                    component={this.renderNameField}
                    normalize={normalizeGroupName}
                />
                <Field
                    name='description'
                    dataLoadStatus={dataLoadStatus}
                    isOperationInProgress={isOperationInProgress}
                    component={this.renderDescriptionField}
                />
                {this.renderClientsSection()}
                {this.renderOwnersSection()}
                <Field name='owners,clients' component={this.renderCommonClientsAndOwnersError} />
            </form>
        );
    }

    private renderNameField = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { isEditMode } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'admin.groupNamePlaceholder';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.name' />
                </div>
                <Input
                    inputProperties={input}
                    className={classnames({ 'error-input': Boolean(meta.error) })}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    maxLength={64}
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderDescriptionField = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { isEditMode } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'admin.groupDescriptionPlaceholder';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.description' />
                </div>
                <Input
                    inputProperties={input}
                    className={classnames({ 'error-input': Boolean(meta.error) })}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    multiline={true}
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderClientsSection = () => {
        const { canAssignClients, groupClients, isOperationInProgress } = this.props;

        if (!canAssignClients) {
            return null;
        }

        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.clients' />
                </div>
                <div>
                    {groupClients.map((groupClient, index) =>
                        <GroupItem data={groupClient} type={GROUP_ITEM_TYPE.CLIENT} index={index} key={groupClient.key} />
                    )}
                </div>
                <div className={classnames({ [local.withItems]: groupClients.length }, local.addButtonContainer, 'plus-button', 'ls-button')}>
                    <button
                        disabled={isOperationInProgress}
                        className='btn-transparent'
                        onClick={this.addNewGroupItem.bind(this, GROUP_ITEM_TYPE.CLIENT)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <FormattedMessage id='admin.addClient' />
                    </button>
                </div>
            </Fragment>
        );
    }

    private renderOwnersSection = () => {
        const { canAssignOwners, groupOwners, isOperationInProgress } = this.props;

        if (!canAssignOwners) {
            return null;
        }

        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.owners' />
                </div>
                <div>
                    {groupOwners.map((groupOwner, index) =>
                        <GroupItem data={groupOwner} type={GROUP_ITEM_TYPE.OWNER} index={index} key={groupOwner.key} />
                    )}
                </div>
                <div className={classnames({ [local.withItems]: groupOwners.length }, local.addButtonContainer, 'plus-button', 'ls-button')}>
                    <button
                        disabled={isOperationInProgress}
                        className='btn-transparent'
                        onClick={this.addNewGroupItem.bind(this, GROUP_ITEM_TYPE.OWNER)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <FormattedMessage id='admin.addOwner' />
                    </button>
                </div>
            </Fragment>
        );
    }

    private addNewGroupItem = (type, event) => {
        const { isEditMode, isOperationInProgress, dataLoadStatus, addItem, change } = this.props;

        const isDisabled = isEditMode ?
            (isOperationInProgress || dataLoadStatus !== LOAD_STATUS.LOADED) :
            isOperationInProgress;

        if (!isDisabled) {
            addItem(type);
        }

        // to clear form submission error for owners & clients
        change('owners,clients', undefined);

        // prevent form submission
        event.preventDefault();
    }

    private renderCommonClientsAndOwnersError = ({ meta }) => (
        <Fragment>
            {
                meta.error &&
                <div className={classnames(local.error, 'form-error-message')}>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
}

export default GroupForm as any;
