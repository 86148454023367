import { createSelector } from 'reselect';

import { getUserProfile } from 'modules/auth/selectors';
import { PERMISSIONS } from 'modules/common/constants';
import { REDUCER_NAME, PIPELINES_LIST_SORT_ATTRIBUTES_MAP } from '../constants';

// pipelines list
export const getPipelinesListItems = (state) => state[REDUCER_NAME].pipelinesList.items;
export const getPipelinesListPage = (state) => state[REDUCER_NAME].pipelinesList.page;
export const getPipelinesListPageSize = (state) => state[REDUCER_NAME].pipelinesList.pageSize;
export const getPipelinesListTotalCount = (state) => state[REDUCER_NAME].pipelinesList.totalCount;
export const getPipelinesListLoadStatus = (state) => state[REDUCER_NAME].pipelinesList.loadStatus;
export const getPipelinesListSortColumn = (state) => state[REDUCER_NAME].pipelinesList.sortBy;
export const getPipelinesListSortOrder = (state) => state[REDUCER_NAME].pipelinesList.sortOrder;
export const getPipelinesListSearchText = (state) => state[REDUCER_NAME].pipelinesList.searchBy;

// pipeline details
export const getPipelineDetails = (state) => state[REDUCER_NAME].pipelineDetails.data;
export const getPipelineDetailsLoadStatus = (state) => state[REDUCER_NAME].pipelineDetails.loadStatus;

// pipeline form
export const getPipelineFormOperationInProgress = (state) => state[REDUCER_NAME].pipelineForm.pipelineOperationInProgress;

export const getPipelinesListSearchPayload = createSelector(
    getPipelinesListPage,
    getPipelinesListPageSize,
    getPipelinesListSortColumn,
    getPipelinesListSortOrder,
    getPipelinesListSearchText,
    (page, pageSize, sortBy, ordering, searchString) => ({
        orderBy: [
            {
                attribute: PIPELINES_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering
            }
        ],
        searchString,
        page,
        pageSize
    })
);

export const getPipelinesListLayoutProps = createSelector(
    getUserProfile,
    getPipelinesListItems,
    getPipelinesListLoadStatus,
    getPipelinesListTotalCount,
    (profile, items, status, totalCount) => ({
        items,
        status,
        totalCount,
        canView: profile.owners.some((owner) => owner.permissions.includes(PERMISSIONS.PIPELINES.VIEW))
    })
);

export const getPipelinesListProps = createSelector(
    getPipelinesListItems,
    getPipelinesListLoadStatus,
    getPipelinesListSortOrder,
    getPipelinesListSortColumn,
    (items, status, sortOrder, sortBy) => ({
        items,
        status,
        sortOrder,
        sortBy
    })
);

export const getPipelineDetailsProps = createSelector(
    getUserProfile,
    getPipelineDetailsLoadStatus,
    getPipelineDetails,
    ({ owners }, loadStatus, data) => ({
        owners,
        loadStatus,
        data
    })
);

export const getPipelineFormLayoutProps = createSelector(
    getPipelineFormOperationInProgress,
    getPipelineDetails,
    getUserProfile,
    (
        isOperationInProgress,
        { purpose },
        profile
    ) => ({
        isFormReadyForSubmission: true, // TODO adjust this code when pipelines edit/creation will be implemented
        isOperationInProgress,
        owners: profile.owners.filter((owner) => owner.permissions.includes(PERMISSIONS.PIPELINES.MANAGE)),
        displayName: purpose
    })
);

export const getPipelineFormProps = createSelector(
    getPipelineDetails,
    getPipelineDetailsLoadStatus,
    getPipelineFormOperationInProgress,
    (data, dataLoadStatus, isOperationInProgress) => ({
        data,
        dataLoadStatus,
        isOperationInProgress
    })
);
