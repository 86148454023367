import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { useAppDispatch } from 'modules/common/hooks';
import Accordion from 'modules/common/components/Accordion';
import Checkbox from 'modules/common/components/Checkbox';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import { LOAD_STATUS } from 'modules/common/constants';
import {
    getInstanceDetails,
    getInstanceFormConfigurationTemplate,
    getInstanceFormPipelineDetailsLoadStatus,
    getExportTargetsData,
    getExportTargetsDataLoadStatus,
    getInstanceRunMode,
    getInstanceDetailsOperationInProgress
} from '../../selectors';
import { closeAdHocRunDialog, getPipelineDetails, loadExportTargetsData } from '../../actions';
import { INSTANCE_RUN_MODE } from '../../constants';

import local from './local.module.scss';

const FormCheckbox = ({ input, label, disabled }) => (
    <Checkbox
        inputProperties={input}
        label={label}
        disabled={disabled}
    />
);

const InstanceAdHocRunForm = ({ change, handleSubmit, reset }) => {
    const {
        name: instanceName,
        producesFullDataset,
        transformationInstanceExportTargets,
        pipelineId,
        pipelineOwnerId,
        airflowConfiguration
    } = useSelector(getInstanceDetails);

    const runMode = useSelector(getInstanceRunMode);
    const airflowConfigurationTemplate = useSelector(getInstanceFormConfigurationTemplate);
    const airflowConfigurationTemplateLoadStatus = useSelector(getInstanceFormPipelineDetailsLoadStatus);
    const exportTargetsData = useSelector(getExportTargetsData);
    const exportTargetsDataLoadStatus = useSelector(getExportTargetsDataLoadStatus);
    const isOperationInProgress = useSelector(getInstanceDetailsOperationInProgress);

    const dispatch = useAppDispatch();

    const isTestMode = runMode === INSTANCE_RUN_MODE.TEST;
    const runButtonMessageId = isTestMode ? 'common.runNowInTestMode' : 'common.runNow';
    const isExportTargetsDataLoaded = exportTargetsDataLoadStatus === LOAD_STATUS.LOADED;
    const isConfigurationTemplateLoaded = airflowConfigurationTemplateLoadStatus === LOAD_STATUS.LOADED;
    const airflowDefaultValues = airflowConfiguration.reduce((acc, { key, value }) => {
        acc[key] = value;

        return acc;
    }, {});

    useEffect(() => {
        reset();
        dispatch(getPipelineDetails(pipelineOwnerId, pipelineId));
        dispatch(loadExportTargetsData());
    }, [ pipelineId, pipelineOwnerId ]);

    useEffect(() => {
        change('producesFullDataset', producesFullDataset);
    }, []);

    const close = useCallback(() => {
        dispatch(closeAdHocRunDialog());
    }, []);

    const exportTargetsSections: JSX.Element[] = [];
    if (transformationInstanceExportTargets?.length && isExportTargetsDataLoaded) {
        transformationInstanceExportTargets.forEach(({ exportTargetName, configuration }) => {
            const { configurationTemplate } = exportTargetsData.find(({ name }) => name === exportTargetName);

            const exportTargetDefaultValues = configuration.reduce((acc, { key, value }) => {
                acc[key] = value;

                return acc;
            }, {});

            exportTargetsSections.push(
                <Fragment key={exportTargetName}>
                    <div className={local.exportTargetTitle}>
                        {exportTargetName}
                    </div>
                    <DynamicFieldsSection
                        change={change}
                        config={configurationTemplate}
                        disabled={isOperationInProgress}
                        prefix={`transformationInstanceExportTargets.${exportTargetName}`}
                        editMode={true}
                        defaultValues={exportTargetDefaultValues}
                        form='instanceAdHocRunForm'
                    />
                </Fragment>
            );
        });
    }

    return (
        <Fragment>
            <form id='instanceAdHocRunForm' onSubmit={handleSubmit} autoComplete='off'>
                <div className={local.text}>
                    <FormattedMessage id='instances.runConfirmationMessage' />
                </div>
                <div className={classnames(local.text, 'ellipsis')}>
                    {instanceName}
                </div>
                <Accordion label={<FormattedMessage id='instances.airflowConfiguration' />}>
                    {
                        isConfigurationTemplateLoaded ?
                            <DynamicFieldsSection
                                change={change}
                                config={airflowConfigurationTemplate}
                                disabled={isOperationInProgress}
                                prefix='airflowConfiguration'
                                editMode={true}
                                defaultValues={airflowDefaultValues}
                                form='instanceAdHocRunForm'
                            /> :
                            <div className={local.loading}>
                                <FormattedMessage id='common.loading' />
                            </div>
                    }
                </Accordion>
                {
                    transformationInstanceExportTargets?.length ?
                        <Accordion label={<FormattedMessage id='instances.exportTargets' />}>
                            {
                                exportTargetsSections.length ?
                                    <div className='container-column'>
                                        {exportTargetsSections}
                                    </div> :
                                    <div className={local.loading}>
                                        <FormattedMessage id='common.loading' />
                                    </div>
                            }
                        </Accordion> :
                        null
                }
                <div className={local.field}>
                    <Field
                        name='producesFullDataset'
                        label={<FormattedMessage id='instances.fullDataset' />}
                        component={FormCheckbox}
                        disabled={isOperationInProgress}
                    />
                </div>
                <div className={local.field}>
                    <Field
                        name='recreateTable'
                        label={<FormattedMessage id='instances.recreateTable' />}
                        component={FormCheckbox}
                        disabled={isOperationInProgress}
                    />
                </div>
            </form>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button
                        className='btn-flat'
                        onClick={close}
                        disabled={isOperationInProgress}
                    >
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <FormSubmitButton
                    form='instanceAdHocRunForm'
                    disabled={!isConfigurationTemplateLoaded || !isExportTargetsDataLoaded || isOperationInProgress}
                    text={<FormattedMessage id={runButtonMessageId} />}
                />
            </div>
        </Fragment>
    );
};

export default InstanceAdHocRunForm;
