import { formatString } from 'modules/common/utils';
import { REPORTS_ROUTES } from './constants';
import ReportsListLayout from './components/ReportsListLayout';
import ReportFormLayout from './components/ReportFormLayout';
import ReportAssetsLayout from './components/ReportAssetsLayout';

export default [
    {
        path: REPORTS_ROUTES.LIST,
        component: ReportsListLayout
    },
    {
        path: REPORTS_ROUTES.NEW,
        component: ReportFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(REPORTS_ROUTES.EDIT, ':clientId', ':reportId'),
        component: ReportFormLayout,
        params: {
            isEditMode: true
        }
    },
    {
        path: formatString(REPORTS_ROUTES.ASSETS, ':clientId', ':reportId'),
        component: ReportAssetsLayout
    }
];
