// collector credentials list
export const APPEND_CREDENTIALS_LIST_ITEMS = 'collectorCredentials/APPEND_CREDENTIALS_LIST_ITEMS';
export const SET_CREDENTIALS_LIST_ITEMS = 'collectorCredentials/SET_CREDENTIALS_LIST_ITEMS';
export const SET_CREDENTIALS_LIST_PAGE = 'collectorCredentials/SET_CREDENTIALS_LIST_PAGE';
export const SET_CREDENTIALS_LIST_COUNT = 'collectorCredentials/SET_CREDENTIALS_LIST_COUNT';
export const SET_CREDENTIALS_LIST_LOAD_STATUS = 'collectorCredentials/SET_CREDENTIALS_LIST_LOAD_STATUS';
export const SET_CREDENTIALS_LIST_SORT_COLUMN = 'collectorCredentials/SET_CREDENTIALS_LIST_SORT_COLUMN';
export const SET_CREDENTIALS_LIST_SORT_ORDER = 'collectorCredentials/SET_CREDENTIALS_LIST_SORT_ORDER';
export const SET_CREDENTIALS_LIST_SEARCH_TEXT = 'collectorCredentials/SET_CREDENTIALS_LIST_SEARCH_TEXT';
export const RESET_CREDENTIALS_LIST = 'collectorCredentials/RESET_CREDENTIALS_LIST';

// collector credential form
export const SET_CREDENTIAL_FORM_COLLECTORS = 'collectorCredentials/SET_CREDENTIAL_FORM_COLLECTORS';
export const SET_CREDENTIAL_FORM_COLLECTORS_LOAD_STATUS = 'collectorCredentials/SET_CREDENTIAL_FORM_COLLECTORS_LOAD_STATUS';
export const SET_CREDENTIAL_FORM_MANIFEST = 'collectorCredentials/SET_CREDENTIAL_FORM_MANIFEST';
export const SET_CREDENTIAL_FORM_MANIFEST_LOAD_STATUS = 'collectorCredentials/SET_CREDENTIAL_FORM_MANIFEST_LOAD_STATUS';
export const SET_CREDENTIAL_FORM_OPERATION_IN_PROGRESS = 'collectorCredentials/SET_CREDENTIAL_FORM_OPERATION_IN_PROGRESS';
export const RESET_CREDENTIAL_FORM = 'collectorCredentials/RESET_CREDENTIAL_FORM';

// collector credential details
export const SET_CREDENTIAL_DETAILS = 'collectorCredentials/SET_CREDENTIAL_DETAILS';
export const SET_CREDENTIAL_DETAILS_LOAD_STATUS = 'collectorCredentials/SET_CREDENTIAL_DETAILS_LOAD_STATUS';
export const RESET_CREDENTIAL_DETAILS = 'collectorCredentials/RESET_CREDENTIAL_DETAILS';
