import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import Input from 'modules/common/components/Input';
import SelectInput from 'modules/common/components/SelectInput';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { LoadStatus } from 'modules/common/interfaces';
import { useAppDispatch } from 'modules/common/hooks';
import { ingestionApiDefinitions } from 'modules/service/types';
import { getUserProfile } from 'modules/auth/selectors';
import { getCollectorCredentialManifest } from '../../actions';

import local from './local.module.scss';

type NameFieldProps = WrappedFieldProps & {
    isOperationInProgress: boolean;
};

type OwnerFieldProps = NameFieldProps;

type CollectorFieldProps = WrappedFieldProps & {
    isOperationInProgress: boolean;
    collectors: Array<ingestionApiDefinitions['CollectorSummaryResponseDto']>;
    collectorsLoadStatus: LoadStatus;
};

export const NameFieldRenderer: FunctionComponent<NameFieldProps> = ({
    input,
    isOperationInProgress,
    meta
}) => {
    const intl = useIntl();

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.name' />
            </div>
            <Input
                inputProperties={input}
                className={classnames({ 'error-input': Boolean(meta.error) })}
                placeholder={intl.formatMessage({ id: 'credentials.namePlaceholder' })}
                disabled={isOperationInProgress}
                maxLength={255}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

export const OwnerFieldRenderer: FunctionComponent<OwnerFieldProps> = ({
    input,
    isOperationInProgress,
    meta
}) => {
    const items = useSelector(getUserProfile)
        .owners
        .filter(({ permissions }) => permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE_FROM_REPORT))
        .map(({ id, name }) => ({ id, name }));

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.owner' />
            </div>
            <SelectInput
                placeholder={<FormattedMessage id='credentials.ownerPlaceholder' />}
                items={items}
                inputProperties={input}
                disabled={isOperationInProgress}
                error={meta.error}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};

export const CollectorFieldRenderer: FunctionComponent<CollectorFieldProps> = ({
    input,
    collectors,
    collectorsLoadStatus,
    isOperationInProgress,
    meta
}) => {
    const dispatch = useAppDispatch();

    const onChange = (value) => {
        dispatch(getCollectorCredentialManifest(value));
        input.onChange(value);
    };

    const items = collectors.map(({ id, description }) => ({
        id,
        name: description
    }));

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.collector' />
            </div>
            <SelectInput
                placeholder={<FormattedMessage id='credentials.collectorPlaceholder' />}
                items={items}
                inputProperties={{ ...input, onChange }}
                disabled={isOperationInProgress}
                error={meta.error}
                isLoading={collectorsLoadStatus !== LOAD_STATUS.LOADED}
                searchable={true}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </div>
    );
};
