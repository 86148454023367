import * as t from '../actionTypes';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { OWNER_MEMBERSHIPS_LIST_PAGE_SIZE, OWNERS_LIST_PAGE_SIZE, OWNER_GROUPS_LIST_PAGE_SIZE } from '../constants';

const ACTION_HANDLERS = {
    [t.SET_OWNERS]: (state, action) => ({
        ...state,
        ownersList: {
            ...state.ownersList,
            items: action.payload
        }
    }),
    [t.SET_OWNERS_LOAD_STATUS]: (state, action) => ({
        ...state,
        ownersList: {
            ...state.ownersList,
            loadStatus: action.payload
        }
    }),
    [t.RESET_OWNERS]: (state) => ({
        ...state,
        ownersList: initialState.ownersList
    }),
    [t.SET_OWNER_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        ownerForm: {
            ...state.ownerForm,
            ownerOperationInProgress: action.payload
        }
    }),
    [t.SET_OWNER_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        ownerMembershipsList: {
            ...state.ownerMembershipsList,
            ownerMembershipOperationInProgress: action.payload
        }
    }),
    [t.ADD_OWNER_MEMBER_ROLE]: (state, action) => {
        const { memberId, role } = action.payload;

        const updatedMemberships = state.ownerDetails.data.memberships.map((membership) => {
            if (membership.memberId === memberId) {
                return {
                    ...membership,
                    roles: [ ...membership.roles, role ]
                };
            }

            return membership;
        });

        return {
            ...state,
            ownerDetails: {
                ...state.ownerDetails,
                data: {
                    ...state.ownerDetails.data,
                    memberships: updatedMemberships
                }
            }
        };
    },
    [t.REMOVE_OWNER_MEMBERSHIP]: (state, action) => {
        const { memberId } = action.payload;

        const updatedMemberships = state.ownerDetails.data.memberships.filter((membership) => membership.memberId !== memberId);

        return {
            ...state,
            ownerDetails: {
                ...state.ownerDetails,
                data: {
                    ...state.ownerDetails.data,
                    memberships: updatedMemberships
                }
            }
        };
    },
    [t.REMOVE_OWNER_MEMBER_ROLE]: (state, action) => {
        const { memberId, role } = action.payload;

        const updatedItems = state.ownerDetails.data.memberships?.map((membership) => {
            if (membership.memberId === memberId) {
                const { roles } = membership;
                const index = roles.indexOf(role);

                return roles.length === 1 ?
                    null :
                    {
                        ...membership,
                        roles: [ ...roles.slice(0, index), ...roles.slice(index + 1, roles.length) ]
                    };
            }

            return membership;
        }).filter(Boolean);

        return {
            ...state,
            ownerDetails: {
                ...state.ownerDetails,
                data: updatedItems ?
                    { ...state.ownerDetails.data, memberships: updatedItems } :
                    state.ownerDetails.data
            }
        };
    },
    [t.OPEN_OWNER_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        ownerMembershipsList: {
            ...state.ownerMembershipsList,
            isOwnerMembershipDialogOpen: true
        }
    }),
    [t.CLOSE_OWNER_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        ownerMembershipsList: {
            ...state.ownerMembershipsList,
            isOwnerMembershipDialogOpen: false
        }
    }),
    [t.SET_OWNERS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        ownersList: {
            ...state.ownersList,
            tableState: {
                ...state.ownersList.tableState,
                searchBy: action.payload
            }
        }
    }),
    [t.SET_OWNERS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        ownersList: {
            ...state.ownersList,
            tableState: {
                ...state.ownersList.tableState,
                sortBy: action.payload
            }
        }
    }),
    [t.SET_OWNERS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        ownersList: {
            ...state.ownersList,
            tableState: {
                ...state.ownersList.tableState,
                sortOrder: action.payload
            }
        }
    }),
    [t.SET_OWNERS_LIST_PAGE]: (state, action) => ({
        ...state,
        ownersList: {
            ...state.ownersList,
            tableState: {
                ...state.ownersList.tableState,
                page: action.payload
            }
        }
    }),
    [t.SET_OWNER_MEMBERSHIPS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        ownerMembershipsList: {
            ...state.ownerMembershipsList,
            tableState: {
                ...state.ownerMembershipsList.tableState,
                searchBy: action.payload
            }
        }
    }),
    [t.SET_OWNER_MEMBERSHIPS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        ownerMembershipsList: {
            ...state.ownerMembershipsList,
            tableState: {
                ...state.ownerMembershipsList.tableState,
                sortBy: action.payload
            }
        }
    }),
    [t.SET_OWNER_MEMBERSHIPS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        ownerMembershipsList: {
            ...state.ownerMembershipsList,
            tableState: {
                ...state.ownerMembershipsList.tableState,
                sortOrder: action.payload
            }
        }
    }),
    [t.SET_OWNER_MEMBERSHIPS_LIST_PAGE]: (state, action) => ({
        ...state,
        ownerMembershipsList: {
            ...state.ownerMembershipsList,
            tableState: {
                ...state.ownerMembershipsList.tableState,
                page: action.payload
            }
        }
    }),
    [t.SET_OWNER_GROUPS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        ownerGroupsList: {
            ...state.ownerGroupsList,
            tableState: {
                ...state.ownerGroupsList.tableState,
                sortBy: action.payload
            }
        }
    }),
    [t.SET_OWNER_GROUPS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        ownerGroupsList: {
            ...state.ownerGroupsList,
            tableState: {
                ...state.ownerGroupsList.tableState,
                sortOrder: action.payload
            }
        }
    }),
    [t.SET_OWNER_GROUPS_LIST_PAGE]: (state, action) => ({
        ...state,
        ownerGroupsList: {
            ...state.ownerGroupsList,
            tableState: {
                ...state.ownerGroupsList.tableState,
                page: action.payload
            }
        }
    }),
    [t.SET_OWNER_DETAILS]: (state, action) => ({
        ...state,
        ownerDetails: {
            ...state.ownerDetails,
            data: action.payload
        }
    }),
    [t.SET_OWNER_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        ownerDetails: {
            ...state.ownerDetails,
            loadStatus: action.payload
        }
    }),
    [t.RESET_OWNER_DETAILS]: (state) => ({
        ...state,
        ownerDetails: initialState.ownerDetails
    })
};

export const initialState = {
    ownersList: {
        tableState: {
            page: 0,
            pageSize: OWNERS_LIST_PAGE_SIZE,
            sortBy: 'ownerName',
            sortOrder: SORT_ORDER.ASC,
            searchBy: ''
        },
        items: [],
        loadStatus: LOAD_STATUS.REQUIRED
    },
    ownerForm: {
        ownerOperationInProgress: false
    },
    ownerMembershipsList: {
        tableState: {
            page: 0,
            pageSize: OWNER_MEMBERSHIPS_LIST_PAGE_SIZE,
            sortBy: 'username',
            sortOrder: SORT_ORDER.ASC,
            searchBy: ''
        },
        isOwnerMembershipDialogOpen: false,
        ownerMembershipOperationInProgress: false
    },
    ownerGroupsList: {
        tableState: {
            page: 0,
            pageSize: OWNER_GROUPS_LIST_PAGE_SIZE,
            sortBy: 'name',
            sortOrder: SORT_ORDER.ASC
        }
    },
    ownerDetails: {
        data: {},
        loadStatus: LOAD_STATUS.REQUIRED
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
