import React, { FunctionComponent } from 'react';
import { PickersDay as MuiPickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
import { Moment } from 'moment';

import styleConstants from 'assets/styles/theme/exports.module.scss';

const PickersDay: FunctionComponent<PickersDayProps<Moment>> = (props) => (
    <MuiPickersDay
        {...props}
        sx={{
            [`&&.${pickersDayClasses.root}`]: {
                fontSize: '14px'
            },
            [`&&.${pickersDayClasses.selected}`]: {
                backgroundColor: styleConstants.highlightColorDark
            },
            [`&&.${pickersDayClasses.today}`]: {
                color: styleConstants.highlightColorDark,
                border: 'none'
            },
            [`&&.${pickersDayClasses.today}.${pickersDayClasses.selected}`]: {
                color: '#fff'
            }
        }}
    />
);

export default PickersDay;
