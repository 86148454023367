import { connect } from 'react-redux';

import { withRouteParams } from 'modules/common/hoc';
import {
    goToGroupsListPage,
    goToEditGroupPage,
    resetGroupDetails,
    getGroupDetails,
    deleteGroup,
    openAddMembershipsDialog
} from '../../actions';
import { makeGroupDetailsLayoutProps } from '../../selectors';
import GroupDetailsLayout from './GroupDetailsLayout';

const mapDispatchToProps = {
    resetData: resetGroupDetails,
    loadData: getGroupDetails,
    goToEditGroupPage,
    goToGroupsListPage,
    openAddMembershipsDialog,
    deleteGroup
};

export default withRouteParams(connect(makeGroupDetailsLayoutProps, mapDispatchToProps)(GroupDetailsLayout));
