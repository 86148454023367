import { connect } from 'react-redux';

import * as actions from '../../actions';
import { getMembersListProps } from '../../selectors';
import MembersList from './MembersList';

const mapDispatchToProps = {
    onSort: actions.membersListSort,
    deleteMember: actions.deleteMember
};

export default connect(getMembersListProps, mapDispatchToProps)(MembersList);
