import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { intl } from 'modules/i18n';
import { useAppDispatch } from 'modules/common/hooks';
import { PERMISSIONS } from 'modules/common/constants';
import Drawer from 'modules/common/components/Drawer';
import SelectInput from 'modules/common/components/SelectInput';
import { getUserProfile } from 'modules/auth/selectors';
import { SOURCE_STATE } from '../../constants';
import { getSourcesListFilters } from '../../selectors';
import { applySourcesListFilters } from '../../actions';

import local from './local.module.scss';

interface SourcesListFiltersProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
}

const SOURCE_STATE_FILTER_ITEMS = Object.keys(SOURCE_STATE).map((state) => ({
    id: state,
    name: intl.formatMessage({ id: `sources.state.${state}` })
}));

const SourcesListFilters: FunctionComponent<SourcesListFiltersProps> = ({ isOpen, setOpen }) => {
    const dispatch = useAppDispatch();
    const savedFilters = useSelector(getSourcesListFilters);

    const [states, setStates] = useState(savedFilters.states);
    const [ownerIds, setOwnerIds] = useState(savedFilters.ownerIds);

    useEffect(() => {
        setStates(savedFilters.states);
        setOwnerIds(savedFilters.ownerIds);
    }, [ isOpen ]);

    const onApply = useCallback(() => {
        dispatch(applySourcesListFilters(states, ownerIds));
        setOpen(false);
    }, [ states, ownerIds ]);

    const { owners } = useSelector(getUserProfile);
    const ownerSelectItems = owners
        .filter(({ permissions }) => permissions.includes(PERMISSIONS.SOURCES.VIEW))
        .map(({ id, name }) => ({ id, name }));

    return (
        <Drawer
            isOpen={isOpen}
            setOpen={setOpen}
            title={
                <FormattedMessage id='common.filter' />
            }
            buttons={
                <Fragment>
                    <div className='ls-button'>
                        <button
                            className='btn-flat'
                            onClick={() => { setOpen(false); }}
                        >
                            <FormattedMessage id='common.cancel' />
                        </button>
                    </div>
                    <div className='ls-button'>
                        <button onClick={onApply}>
                            <FormattedMessage id='common.apply' />
                        </button>
                    </div>
                </Fragment>
            }
        >
            <div className={local.filterTitle}>
                <FormattedMessage id='common.owners' />
            </div>
            <SelectInput
                inputProperties={{
                    value: ownerIds,
                    onChange: setOwnerIds
                }}
                items={ownerSelectItems}
                multiple={true}
                width={344}
            />
            <div className={local.filterTitle}>
                <FormattedMessage id='sources.sourceStates' />
            </div>
            <SelectInput
                inputProperties={{
                    value: states,
                    onChange: setStates
                }}
                items={SOURCE_STATE_FILTER_ITEMS}
                multiple={true}
                width={344}
            />
        </Drawer>
    );
};

export default SourcesListFilters;
