import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH, AUTOMATION_ID } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { formatString } from 'modules/common/utils';
import { transformationApiDefinitions } from 'modules/service/types';
import { INSTANCE_STATE, INSTANCES_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface InstancesListProps {
    items: Array<transformationApiDefinitions['InstanceSearchResponseDto']>;
    status: LoadStatus;
    sortOrder: SortOrder;
    sortBy: string;
    onSort: Function;
    goToInstanceDetailsPage: Function;
    clientId: string;
}

class InstancesList extends Component<InstancesListProps> {
    public render() {
        const { items, status, onSort } = this.props;

        return (
            <Table
                columns={this.getTableConfig()}
                data={items}
                onSort={onSort}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={status === LOAD_STATUS.LOADED}
                onRowClick={this.onTableRowClick}
            />
        );
    }

    private getTableConfig = () => {
        const { sortBy, sortOrder, clientId } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.name' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'name',
                accessor: ({ id, name, state }) => {
                    let className = '';
                    switch (state) {
                        case INSTANCE_STATE.DRAFT:
                            className = local.draft;
                            break;
                        case INSTANCE_STATE.ARCHIVED:
                            className = local.archived;
                            break;
                    }

                    return (
                        <Fragment>
                            <a href={`#${formatString(INSTANCES_ROUTES.DETAILS, clientId, id)}`} className={local.name}>
                                {name}
                            </a>
                            {
                                ![ INSTANCE_STATE.ENABLED, INSTANCE_STATE.DISABLED ].includes(state) &&
                                <span className={classnames(local.label, className)}>
                                    <FormattedMessage id={`instances.state.${state}`} />
                                </span>
                            }
                        </Fragment>
                    );
                },
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '74%',
                skeletonCellWidth: 800,
                sortable: true,
                showTooltip: true,
                cellClassName: AUTOMATION_ID.INSTANCES_TABLE_NAME
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.lastRun' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'lastRunTime',
                accessor: ({ lastRunTimestamp }) => lastRunTimestamp ? moment.utc(lastRunTimestamp).format('DD MMM YYYY [at] HH:mm') : '-',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 161,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.INSTANCES_TABLE_LAST_RUN}`
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.nextRun' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'nextRunTime',
                accessor: ({ nextRunTimestamp }) => nextRunTimestamp ? moment.utc(nextRunTimestamp).format('DD MMM YYYY [at] HH:mm') : '-',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 161,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.INSTANCES_TABLE_NEXT_RUN}`
            }
        ];
    }

    private onTableRowClick = ({ id }) => {
        const { status, goToInstanceDetailsPage } = this.props;

        if (status === LOAD_STATUS.LOADED) {
            goToInstanceDetailsPage(id);
        }
    }
}

export default InstancesList;
