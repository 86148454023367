// common
export const SET_OWNERS = 'admin/owners/SET_OWNERS';
export const SET_OWNERS_LOAD_STATUS = 'admin/owners/SET_OWNERS_LOAD_STATUS';
export const RESET_OWNERS = 'admin/owners/RESET_OWNERS';

// owners list
export const SET_OWNERS_LIST_SEARCH_TEXT = 'admin/owners/SET_OWNERS_LIST_SEARCH_TEXT';
export const SET_OWNERS_LIST_SORT_COLUMN = 'admin/owners/SET_OWNERS_LIST_SORT_COLUMN';
export const SET_OWNERS_LIST_SORT_ORDER = 'admin/owners/SET_OWNERS_LIST_SORT_ORDER';
export const SET_OWNERS_LIST_PAGE = 'admin/owners/SET_OWNERS_LIST_PAGE';

// owner form
export const SET_OWNER_FORM_OPERATION_IN_PROGRESS = 'admin/owners/SET_OWNER_FORM_OPERATION_IN_PROGRESS';

// owner memberships list
export const SET_OWNER_MEMBERSHIPS_LIST_SEARCH_TEXT = 'admin/owners/SET_OWNER_MEMBERSHIPS_LIST_SEARCH_TEXT';
export const SET_OWNER_MEMBERSHIPS_LIST_SORT_COLUMN = 'admin/owners/SET_OWNER_MEMBERSHIPS_LIST_SORT_COLUMN';
export const SET_OWNER_MEMBERSHIPS_LIST_SORT_ORDER = 'admin/owners/SET_OWNER_MEMBERSHIPS_LIST_SORT_ORDER';
export const SET_OWNER_MEMBERSHIPS_LIST_PAGE = 'admin/owners/SET_OWNER_MEMBERSHIPS_LIST_PAGE';
export const SET_OWNER_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS = 'admin/owners/SET_OWNER_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS';
export const ADD_OWNER_MEMBER_ROLE = 'admin/owners/ADD_OWNER_MEMBER_ROLE';
export const REMOVE_OWNER_MEMBER_ROLE = 'admin/owners/REMOVE_OWNER_MEMBER_ROLE';
export const REMOVE_OWNER_MEMBERSHIP = 'admin/owners/REMOVE_OWNER_MEMBERSHIP';
export const OPEN_OWNER_MEMBERSHIP_DIALOG = 'admin/owners/OPEN_OWNER_MEMBERSHIP_DIALOG';
export const CLOSE_OWNER_MEMBERSHIP_DIALOG = 'admin/owners/CLOSE_OWNER_MEMBERSHIP_DIALOG';

// owner groups list
export const SET_OWNER_GROUPS_LIST_SORT_COLUMN = 'admin/owners/SET_OWNER_GROUPS_LIST_SORT_COLUMN';
export const SET_OWNER_GROUPS_LIST_SORT_ORDER = 'admin/owners/SET_OWNER_GROUPS_LIST_SORT_ORDER';
export const SET_OWNER_GROUPS_LIST_PAGE = 'admin/owners/SET_OWNER_GROUPS_LIST_PAGE';

// owner details
export const SET_OWNER_DETAILS = 'admin/owners/SET_OWNER_DETAILS';
export const SET_OWNER_DETAILS_LOAD_STATUS = 'admin/owners/SET_OWNER_DETAILS_LOAD_STATUS';
export const RESET_OWNER_DETAILS = 'admin/owners/RESET_OWNER_DETAILS';
