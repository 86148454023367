import { createSelector } from 'reselect';

import { getUniqueElements, sort } from 'modules/common/utils';
import { LOAD_STATUS } from 'modules/common/constants';
import { CATALOG_ITEM_TYPE, REDUCER_NAME } from '../constants';

export const getDatasets = (state) => state[REDUCER_NAME].datasets;
export const getSelectedDatasetId = (state) => state[REDUCER_NAME].selectedDatasetId;
export const getDatasetInfo = (state) => state[REDUCER_NAME].dataset;
export const getSelectedTabletId = (state) => state[REDUCER_NAME].selectedTableId;
export const getTableInfo = (state) => state[REDUCER_NAME].table;
export const getIsMasterProject = (state) => state[REDUCER_NAME].isMasterProject;
export const getIsSearchInProgress = (state) => state[REDUCER_NAME].isSearchInProgress;
export const getSearchResult = (state) => state[REDUCER_NAME].searchResult;
export const getSearchQuery = (state) => state[REDUCER_NAME].searchQuery;

export const getCatalogLayoutProps = createSelector(
    getDatasets,
    getDatasetInfo,
    getTableInfo,
    getIsMasterProject,
    getIsSearchInProgress,
    (datasets, datasetInfo, tableInfo, isMasterProject, isSearchInProgress) => ({
        isDataLoading: isSearchInProgress || [ datasets.loadStatus, datasetInfo.loadStatus, tableInfo.loadStatus ].includes(LOAD_STATUS.LOADING),
        isMasterProject
    })
);

export const getDatasetsListProps = createSelector(
    getDatasets,
    getDatasetInfo,
    getTableInfo,
    getSelectedDatasetId,
    getIsSearchInProgress,
    getSearchResult,
    ({ items, loadStatus }, datasetInfo, tableInfo, selectedDatasetId, isSearchInProgress, searchResult) => ({
        loadStatus,
        selectedDatasetId,
        isSearchInProgress,
        isDataLoading: [ datasetInfo.loadStatus, tableInfo.loadStatus ].includes(LOAD_STATUS.LOADING),
        items: searchResult ?
            getUniqueElements(searchResult.map(({ type, id, parentId }) => type === CATALOG_ITEM_TYPE.DATASET ? id : parentId)) :
            items
    })
);

export const getTablesListProps = createSelector(
    getDatasetInfo,
    getTableInfo,
    getSelectedDatasetId,
    getSelectedTabletId,
    getSearchResult,
    ({ data, tables, loadStatus }, tableInfo, selectedDatasetId, selectedTableId, searchResult) => {
        const filteredTables = tables.length && searchResult ?
            searchResult
                .filter(({ parentId }) => parentId === selectedDatasetId)
                // TODO revisit this later
                // nested loops here are ok to keep if number of tables is relatively small,
                // but otherwise it may cause performance issues
                .map(({ id }) => tables.find(({ id: tableId }) => id === tableId)) :
            [];

        return {
            data,
            loadStatus,
            selectedTableId,
            selectedDatasetId,
            isDataLoading: tableInfo.loadStatus === LOAD_STATUS.LOADING,
            tables: filteredTables.length ? filteredTables : tables
        };
    }
);

export const getTableSchemaProps = createSelector(
    getTableInfo,
    getSelectedTabletId,
    ({ data, loadStatus, sortBy, sortOrder }, selectedTableId) => ({
        sortBy,
        sortOrder,
        loadStatus,
        selectedTableId,
        data: {
            ...data,
            schemaFields: data.schemaFields?.length ? sort(data.schemaFields, sortBy, sortOrder) : []
        }
    })
);
