import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import * as t from '../actionTypes';

const ACTION_HANDLERS = {
    [t.SET_DATASETS]: (state, action) => ({
        ...state,
        datasets: {
            ...state.datasets,
            items: action.payload
        }
    }),
    [t.SET_DATASETS_LOAD_STATUS]: (state, action) => ({
        ...state,
        datasets: {
            ...state.datasets,
            loadStatus: action.payload
        }
    }),
    [t.SET_DATASET_METADATA]: (state, action) => ({
        ...state,
        dataset: {
            ...state.dataset,
            data: action.payload
        }
    }),
    [t.SET_DATASET_TABLES]: (state, action) => ({
        ...state,
        dataset: {
            ...state.dataset,
            tables: action.payload
        }
    }),
    [t.SET_DATASET_LOAD_STATUS]: (state, action) => ({
        ...state,
        dataset: {
            ...state.dataset,
            loadStatus: action.payload
        }
    }),
    [t.SET_SELECTED_DATASET_ID]: (state, action) => ({
        ...state,
        selectedDatasetId: action.payload
    }),
    [t.SET_SELECTED_TABLE_ID]: (state, action) => ({
        ...state,
        selectedTableId: action.payload
    }),
    [t.SET_TABLE_DATA]: (state, action) => ({
        ...state,
        table: {
            ...state.table,
            data: action.payload
        }
    }),
    [t.SET_TABLE_DATA_LOAD_STATUS]: (state, action) => ({
        ...state,
        table: {
            ...state.table,
            loadStatus: action.payload
        }
    }),
    [t.SET_SCHEMA_SORT_BY]: (state, action) => ({
        ...state,
        table: {
            ...state.table,
            sortBy: action.payload
        }
    }),
    [t.SET_SCHEMA_SORT_ORDER]: (state, action) => ({
        ...state,
        table: {
            ...state.table,
            sortOrder: action.payload
        }
    }),
    [t.SET_IS_MASTER_PROJECT]: (state, action) => ({
        ...state,
        isMasterProject: action.payload
    }),
    [t.SET_IS_SEARCH_IN_PROGRESS]: (state, action) => ({
        ...state,
        isSearchInProgress: action.payload
    }),
    [t.SET_SEARCH_RESULT]: (state, action) => ({
        ...state,
        searchResult: action.payload
    }),
    [t.SET_SEARCH_QUERY]: (state, action) => ({
        ...state,
        searchQuery: action.payload
    }),
    [t.RESET_CATALOG]: () => ({ ...initialState })
};

export const initialState = {
    datasets: {
        items: [],
        loadStatus: LOAD_STATUS.REQUIRED
    },
    dataset: {
        data: {},
        tables: [],
        loadStatus: LOAD_STATUS.REQUIRED
    },
    table: {
        data: {},
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        loadStatus: LOAD_STATUS.REQUIRED
    },
    selectedDatasetId: null,
    selectedTableId: null,
    isMasterProject: true,
    searchResult: null,
    isSearchInProgress: false,
    searchQuery: ''
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
