export const REDUCER_NAME = 'instances';
export const INSTANCES_LIST_PAGE_SIZE = 50;

export const INSTANCES_LIST_SORT_ATTRIBUTES_MAP = {
    name: 'NAME'
};

export const INSTANCE_STATE = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    DRAFT: 'DRAFT',
    ARCHIVED: 'ARCHIVED'
};

export const INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER = {
    TOGGLE_PAUSE: 'TOGGLE_PAUSE',
    TOGGLE_STATE: 'TOGGLE_STATE'
};

export const SAVE_MODE = {
    NORMAL: 'NORMAL',
    NEW: 'NEW',
    DRAFT: 'DRAFT'
};

export const TRIGGER_TYPE = {
    UPSTREAM_FEED: 'UPSTREAM_FEED',
    UPSTREAM_INSTANCE: 'UPSTREAM_INSTANCE'
} as const;

export const METHOD_SWITCH_OPTIONS = {
    SCHEDULE: 'SCHEDULE',
    TRIGGER: 'TRIGGER',
    SCHEDULE_AND_TRIGGER: 'SCHEDULE_AND_TRIGGER',
    MANUAL: 'MANUAL'
} as const;

export const INSTANCES_ROUTES = {
    LIST: '/transformation-instances',
    DETAILS: '/transformation-instances/view/{0}/{1}',
    NEW: '/transformation-instances/add',
    EDIT: '/transformation-instances/edit/{0}/{1}'
};

export const INSTANCE_RUN_MODE = {
    NORMAL: 'NORMAL',
    TEST: 'TEST'
};

export const TRIGGERS_LOGIC_TYPE_SWITCH_OPTIONS = {
    OR: 'OR',
    AND: 'AND'
} as const;

export const INSTANCE_RERUN_STATUS = {
    RESTARTED: 'RESTARTED',
    FAILED: 'FAILED'
};
