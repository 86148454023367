import React, { FunctionComponent, MouseEventHandler, ReactChild, ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ConditionalWrapper from '../ConditionalWrapper';

import local from './local.module.scss';

interface ButtonWithTooltipProps {
    id?: string;
    buttonText: ReactNode;
    tooltipText: ReactChild;
    tooltipCondition: boolean;
    disabled?: boolean;
    className?: string;
    onClick: MouseEventHandler;
}

const ButtonWithTooltip: FunctionComponent<ButtonWithTooltipProps> = ({ id, buttonText, tooltipText, tooltipCondition, disabled, className, onClick }) => (
    <div className='ls-button'>
        <ConditionalWrapper
            condition={tooltipCondition}
            wrap={(children) => (
                <Tooltip
                    title={tooltipText}
                    placement='top'
                    classes={{ tooltip: local.tooltip }}
                >
                    {/* important to add this span: see https://mui.com/material-ui/react-tooltip/#disabled-elements */}
                    <span style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                        {children}
                    </span>
                </Tooltip>
            )}
        >
            <button
                id={id}
                disabled={disabled}
                className={className}
                onClick={onClick}
                style={disabled ? { pointerEvents: 'none' } : {}} // see https://material-ui.com/components/tooltips/, section 'Disabled Elements'
            >
                {buttonText}
            </button>
        </ConditionalWrapper>
    </div>
);

export default ButtonWithTooltip;
