import React, { Fragment, FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import SelectInput from 'modules/common/components/SelectInput';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import { AUTOMATION_ID } from 'modules/common/constants';
import RolesSelectField from 'modules/admin/components/RolesSelectField';
import { ROLE_TYPE } from 'modules/admin/constants';
import { getMemberDetailsOperationInProgress, getMembershipDialogClients } from '../../selectors';
import { closeClientMembershipDialog } from '../../actions';

import local from './local.module.scss';

const ClientSelect = ({ input, disabled, items }) => {
    const intl = useIntl();

    return (
        <Fragment>
            <div className={local.label}>
                <FormattedMessage id='common.client' />
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.MEMBER_CLIENT}
                placeholder={intl.formatMessage({ id: 'admin.selectClient' })}
                items={items}
                inputProperties={input}
                disabled={disabled}
                searchable={true}
                trackTiming={true}
                trackingName='Member client'
            />
        </Fragment>
    );
};

const ClientMembershipForm: FunctionComponent<InjectedFormProps> = ({ handleSubmit }) => {
    const dispatch = useAppDispatch();

    const close = useCallback(() => {
        dispatch(closeClientMembershipDialog());
    }, []);

    const clientId = useReduxFormValue('addClientForm', 'clientId');
    const roles = useReduxFormValue('addClientForm', 'roles');

    const isOperationInProgress = useSelector(getMemberDetailsOperationInProgress);
    const clients = useSelector(getMembershipDialogClients);

    return (
        <Fragment>
            <form id='addClientForm' onSubmit={handleSubmit} autoComplete='off'>
                <Field
                    name='clientId'
                    disabled={isOperationInProgress}
                    component={ClientSelect}
                    items={clients}
                />
                <div className={local.label}>
                    <FormattedMessage id='admin.roles' />
                </div>
                <RolesSelectField
                    fieldName='roles'
                    type={ROLE_TYPE.CLIENT}
                    disabled={isOperationInProgress}
                />
            </form>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button disabled={isOperationInProgress} className='btn-flat' onClick={close}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <FormSubmitButton
                    form='addClientForm'
                    width={130}
                    disabled={!clientId || !roles?.length || isOperationInProgress}
                    text={<FormattedMessage id='common.submit' />}
                    isSubmitting={isOperationInProgress}
                />
            </div>
        </Fragment>
    );
};

export default ClientMembershipForm;
