import React from 'react';
import { createRoot } from 'react-dom/client';

import { IntlProviderWrapper } from 'modules/i18n';
import { AlertOptions, ConfirmOptions, PromptOptions } from 'modules/common/interfaces';
import AlertDialog from '../components/AlertDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import PromptDialog from '../components/PromptDialog';

const __TEST__ = process.env.NODE_ENV === 'test';

export const confirm = (options: ConfirmOptions) => {
    if (__TEST__) {
        return Promise.resolve(true);
    }

    const div = document.createElement('div');
    document.body.appendChild(div);

    const root = createRoot(div);

    const cleanup = () => {
        root.unmount();
        div.remove();
    };

    return new Promise((resolve) => {
        root.render(
            <IntlProviderWrapper>
                <ConfirmDialog
                    {...options}
                    resolve={resolve}
                    cleanup={cleanup}
                />
            </IntlProviderWrapper>
        );
    });
};

export const alert = (options: AlertOptions) => {
    if (__TEST__) {
        return Promise.resolve(true);
    }

    const div = document.createElement('div');
    document.body.appendChild(div);

    const root = createRoot(div);

    const cleanup = () => {
        root.unmount();
        div.remove();
    };

    return new Promise((resolve) => {
        root.render(
            <IntlProviderWrapper>
                <AlertDialog
                    {...options}
                    resolve={resolve}
                    cleanup={cleanup}
                />
            </IntlProviderWrapper>
        );
    });
};

export const prompt = (options: PromptOptions) => {
    if (__TEST__) {
        return Promise.resolve('test prompted value');
    }

    const div = document.createElement('div');
    document.body.appendChild(div);

    const root = createRoot(div);

    const cleanup = () => {
        root.unmount();
        div.remove();
    };

    return new Promise((resolve) => {
        root.render(
            <IntlProviderWrapper>
                <PromptDialog
                    {...options}
                    resolve={resolve}
                    cleanup={cleanup}
                />
            </IntlProviderWrapper>
        );
    });
};
