import React, { Fragment, FunctionComponent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useDidUpdateEffect } from 'modules/common/hooks';
import { AUTOMATION_ID, LOAD_STATUS } from 'modules/common/constants';
import { TabPanel, Tabs } from 'modules/common/components/Tabs';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import SearchInput from 'modules/common/components/SearchInput';
import { LoadStatus, RouteComponentProps } from 'modules/common/interfaces';
import ErrorPage from 'modules/common/components/ErrorPage';
import ClientDetails from '../ClientDetails';
import ClientMembershipsList from '../ClientMembershipsList';
import ClientGroupsList from '../ClientGroupsList';
import ClientMembershipDialog from '../ClientMembershipDialog';

import local from './local.module.scss';

interface ClientDetailsLayoutProps {
    clientName: string;
    isOdpMemberAdministrator: boolean;
    isOperationInProgress: boolean;
    currentUserHasMembership: boolean;
    canView: boolean;
    canManage: boolean;
    status: LoadStatus;
    loadData: Function;
    openClientMembershipDialog: Function;
    goToClientsListPage: Function;
    goToEditClientPage: Function;
    onSearch: Function;
    resetTable: Function;
    resetData: Function;
    deleteClient: Function;
}

interface RouteMatchParams {
    clientId: string;
}

const DETAILS_TAB_INDEX = 0;
const MEMBERSHIPS_TAB_INDEX = 1;
const GROUPS_TAB_INDEX = 2;

const ClientDetailsLayout: FunctionComponent<ClientDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> = (props) => {
    const {
        status,
        clientName,
        loadData,
        canView,
        canManage,
        isOdpMemberAdministrator,
        isOperationInProgress,
        currentUserHasMembership,
        openClientMembershipDialog,
        goToClientsListPage,
        goToEditClientPage,
        onSearch,
        resetData,
        resetTable,
        deleteClient,
        match
    } = props;

    const { clientId } = match.params;

    const searchInputRef = useRef() as MutableRefObject<HTMLInputElement>;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        resetData();
        resetTable();
        loadData(clientId);
    }, []);

    useDidUpdateEffect(() => {
        const { current: input } = searchInputRef;

        if (input) {
            input.value = '';
            input.blur();
        }

        resetData();
        resetTable();
    }, [ clientId ]);

    useDidUpdateEffect(() => {
        if (status === LOAD_STATUS.REQUIRED) {
            loadData(clientId);
        }
    }, [ status ]);

    const grantMembershipButtonCallback = useCallback(() => { openClientMembershipDialog(); }, []);
    const editButtonCallback = useCallback(() => { goToEditClientPage(clientId); }, [ clientId ]);
    const deleteButtonCallback = useCallback(() => { deleteClient(clientId); }, [ clientId ]);
    const breadcrumbsCallback = useCallback(() => { goToClientsListPage(); }, []);

    if (!isOdpMemberAdministrator && !canView) {
        return (
            <ErrorPage error='403' />
        );
    }

    const isLoaded = status === LOAD_STATUS.LOADED;

    if (isLoaded && !clientName) {
        return (
            <ErrorPage error='404' />
        );
    }

    const breadcrumbsItems = [
        {
            id: AUTOMATION_ID.ALL_CLIENTS_BREADCRUMB,
            label: (<FormattedMessage id='admin.allClients' />),
            onClick: breadcrumbsCallback
        },
        {
            label: clientName
        }
    ];

    const areControlsDisabled = !isLoaded || isOperationInProgress;

    return (
        <Fragment>
            <Breadcrumbs
                items={breadcrumbsItems}
                selectedItemIndex={1}
            />
            <div className={classnames(local.controls, 'container-row')}>
                <Tabs
                    value={selectedTabIndex}
                    onChange={(event, idx) => {
                        setSelectedTabIndex(idx);

                        if (idx === MEMBERSHIPS_TAB_INDEX) {
                            resetTable();
                        }
                    }}
                    labels={[
                        <FormattedMessage id='common.details' />,
                        <FormattedMessage id='common.memberships' />,
                        <FormattedMessage id='common.groups' />
                    ]}
                    disabled={areControlsDisabled}
                />
                <div className={classnames(local.tableControls, 'container-row')}>
                    {
                        selectedTabIndex === MEMBERSHIPS_TAB_INDEX &&
                        <Fragment>
                            <div className={classnames(local.tableControls, 'container-row')}>
                                <SearchInput
                                    width={250}
                                    inputId={AUTOMATION_ID.CLIENT_MEMBERSHIPS_SEARCH}
                                    disabled={areControlsDisabled}
                                    onSearch={onSearch}
                                    ref={searchInputRef}
                                />
                            </div>
                            {
                                (isOdpMemberAdministrator || canManage) &&
                                <div className='ls-button'>
                                    <button
                                        id={AUTOMATION_ID.ADD_CLIENT_MEMBER_BTN}
                                        onClick={grantMembershipButtonCallback}
                                        // disable button when user is super-admin, already has role(s) for current client,
                                        // but doesn't have membership viewer or admin role
                                        disabled={areControlsDisabled || (isOdpMemberAdministrator && !canView && currentUserHasMembership)}
                                    >
                                        <FormattedMessage id='admin.addMember' />
                                    </button>
                                </div>
                            }
                        </Fragment>
                    }
                    {
                        isOdpMemberAdministrator && selectedTabIndex === DETAILS_TAB_INDEX &&
                        <Fragment>
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.DELETE_CLIENT_BTN}
                                    onClick={deleteButtonCallback}
                                    className='btn-negative'
                                    disabled={areControlsDisabled}
                                >
                                    <FormattedMessage id='common.delete' />
                                </button>
                            </div>
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.EDIT_CLIENT_BTN}
                                    onClick={editButtonCallback}
                                    disabled={areControlsDisabled}
                                >
                                    <FormattedMessage id='common.edit' />
                                </button>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
            <TabPanel index={DETAILS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <ClientDetails />
            </TabPanel>
            <TabPanel index={MEMBERSHIPS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <ClientMembershipsList
                    isOdpMemberAdministrator={isOdpMemberAdministrator}
                    canView={canView}
                    canManage={canManage}
                    match={match}
                />
            </TabPanel>
            <TabPanel index={GROUPS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <ClientGroupsList />
            </TabPanel>
            <ClientMembershipDialog clientId={match.params.clientId} canManage={canManage} />
        </Fragment>
    );
};

export default ClientDetailsLayout;
