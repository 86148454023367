import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import AddMembershipsForm from '../AddMembershipsForm';
import { getIsAddMembershipsDialogOpen } from '../../selectors';
import { closeAddMembershipsDialog, addMemberships } from '../../actions';

const AddMembershipsDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const isAddMembershipsDialogOpen = useSelector(getIsAddMembershipsDialogOpen);

    const onSubmit = useCallback((formValues) => dispatch(addMemberships(formValues)), []);
    const onClose = useCallback(() => dispatch(closeAddMembershipsDialog()), []);

    return (
        <Dialog
            open={isAddMembershipsDialogOpen}
            title={<FormattedMessage id='admin.addGroupMembers' />}
            onClose={onClose}
        >
            <AddMembershipsForm onSubmit={onSubmit} />
        </Dialog>
    );
};

export default AddMembershipsDialog;
