import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

import { ingestionApiDefinitions } from 'modules/service/types';
import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import SearchInput from 'modules/common/components/SearchInput';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import SourcesList from 'modules/sources/components/SourcesList';
import SourcesListFilters from 'modules/sources/components/SourcesListFilters';
import NewSourceMenu from 'modules/sources/components/NewSourceMenu';
import ErrorPage from 'modules/common/components/ErrorPage';
import { LoadStatus } from 'modules/common/interfaces';

import local from './local.module.scss';

interface SourcesListLayoutProps {
    items: Array<ingestionApiDefinitions['SourceSearchResponseDto']>;
    status: LoadStatus;
    totalCount: number;
    canView: boolean;
    canManage: boolean;
    loadData: Function;
    onSearch: Function;
    resetTable: Function;
    loadMore: Function;
}

interface SourcesListLayoutState {
    isDrawerOpen: boolean;
}

class SourcesListLayout extends Component<SourcesListLayoutProps, SourcesListLayoutState> {
    public state = { isDrawerOpen: false };

    public componentDidMount() {
        const { loadData, resetTable, canView } = this.props;

        if (canView) {
            resetTable();
            loadData();
        }
    }

    public componentDidUpdate() {
        const { status, canView, loadData } = this.props;

        if (canView && status === LOAD_STATUS.REQUIRED) {
            loadData();
        }
    }

    public render() {
        const {
            status,
            canView,
            canManage,
            totalCount,
            items,
            loadMore,
            onSearch
        } = this.props;

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const isLoaded = status === LOAD_STATUS.LOADED;

        return (
            <Fragment>
                <div className={local.title}>
                    <FormattedMessage id='common.sources' />
                </div>
                <div className={classnames(local.tableControls, 'container-row')}>
                    <SearchInput inputId={AUTOMATION_ID.SOURCES_SEARCH} disabled={!isLoaded} onSearch={onSearch} />
                    <div className='container-row'>
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.SOURCES_FILTER_BTN}
                                className={local.filterButton}
                                onClick={() => { this.setDrawerOpen(true); }}
                                disabled={!isLoaded}
                            >
                                <FontAwesomeIcon icon={faSliders} className={local.filterIcon} />
                                <FormattedMessage id='common.filter' />
                            </button>
                        </div>
                        {
                            canManage && <NewSourceMenu />
                        }
                    </div>
                </div>
                <div className={local.card}>
                    <SourcesList />
                    {
                        isLoaded && (totalCount > items.length) &&
                        <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                    }
                </div>
                <SourcesListFilters isOpen={this.state.isDrawerOpen} setOpen={this.setDrawerOpen} />
            </Fragment>
        );
    }

    private setDrawerOpen = (isDrawerOpen) => {
        this.setState({ isDrawerOpen });
    }
}

export default SourcesListLayout;
