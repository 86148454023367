import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import local from './local.module.scss';

interface LoadingSpinnerProps {
    className?: string;
    size?: number;
    thickness?: number;
}

const SIZE = 48;
const THICKNESS = 4;
const MARGIN = 8;

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({ className, size = SIZE, thickness = THICKNESS }) => {
    const innerDivStyle = {
        width: size - 2 * MARGIN,
        height: size - 2 * MARGIN,
        margin: MARGIN,
        borderWidth: thickness
    };

    return (
        <div
            className={classnames(local.ldsRing, className)}
            style={{
                width: size,
                height: size
            }}
        >
            <div style={innerDivStyle} />
            <div style={innerDivStyle} />
            <div style={innerDivStyle} />
            <div style={innerDivStyle} />
        </div>
    );
};

export default LoadingSpinner;
