import React, { FunctionComponent, useEffect } from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import { ADVERITY_OPTIONS_FIELD_TYPE } from '../../constants';
import {
    renderCheckboxField,
    renderDateTimeField,
    renderSelectField,
    renderTextAreaField,
    renderTextField
} from './fieldRenderers';
import { emailValidator, getValidatorForIntegerValue } from './utils';

import local from './local.module.scss';

interface AdverityDynamicFieldProps {
    prefix: string;
    config: { [key: string]: any };
    disabled: boolean;
    change: InjectedFormProps['change'];
}

const AdverityDynamicField: FunctionComponent<AdverityDynamicFieldProps> = ({
    prefix,
    config,
    disabled,
    change
}) => {
    useEffect(() => {
        if (config.type === ADVERITY_OPTIONS_FIELD_TYPE.BOOLEAN) {
            change(`${prefix}.${config.id}`, false);
        }
    }, [ config ]);

    let renderer: Function | null = null;
    let validate: Function | null = null;

    switch (config.type) {
        case ADVERITY_OPTIONS_FIELD_TYPE.BOOLEAN:
            renderer = renderCheckboxField;
            break;
        case ADVERITY_OPTIONS_FIELD_TYPE.STRING:
        case ADVERITY_OPTIONS_FIELD_TYPE.URL:
            renderer = renderTextField;
            break;
        case ADVERITY_OPTIONS_FIELD_TYPE.CHOICE:
            renderer = renderSelectField;
            break;
        case ADVERITY_OPTIONS_FIELD_TYPE.FIELD:
        case ADVERITY_OPTIONS_FIELD_TYPE.BASE64:
            renderer = renderTextAreaField;
            break;
        case ADVERITY_OPTIONS_FIELD_TYPE.INTEGER:
            renderer = renderTextField;
            validate = getValidatorForIntegerValue(config.min_value, config.max_value);
            break;
        case ADVERITY_OPTIONS_FIELD_TYPE.EMAIL:
            renderer = renderTextField;
            validate = emailValidator;
            break;
        case ADVERITY_OPTIONS_FIELD_TYPE.DATETIME:
            renderer = renderDateTimeField;
    }

    return (
        <div className={local.field}>
            <Field
                name={`${prefix}.${config.id}`}
                component={renderer}
                validate={validate}
                config={config}
                disabled={disabled}
            />
        </div>
    );
};

export default AdverityDynamicField;
