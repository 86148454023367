import React, { Fragment, FunctionComponent, useState } from 'react';
import classnames from 'classnames';
import { filesize } from 'filesize';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import LoadingSpinner from 'modules/common/components/LoadingSpinner';
import { LOAD_STATUS } from 'modules/common/constants';
import SortableHeader from 'modules/common/components/SortableHeader';
import TableData from '../TableData';
import { SCHEMA_FIELD_MODE } from '../../constants';
import { getTableSchemaProps } from '../../selectors';
import { schemaFieldsSort } from '../../actions';

import local from './local.module.scss';

const TableSchema: FunctionComponent = () => {
    const {
        loadStatus,
        selectedTableId,
        sortBy,
        sortOrder,
        data: {
            numRows,
            numBytes,
            location,
            schemaFields
        }
    } = useSelector(getTableSchemaProps);
    const [isTableDataOpen, setTableDataOpen] = useState(false);

    return selectedTableId ?
        <Fragment>
            <div className={local.title}>
                <TextWithTooltip>{selectedTableId}</TextWithTooltip>
            </div>
            {
                loadStatus === LOAD_STATUS.LOADING ?
                    <LoadingSpinner className={local.spinner} /> :
                    <Fragment>
                        <div className='container-row'>
                            {
                                Number.isInteger(numRows) &&
                                <div className={classnames(local.column, 'container-column')}>
                                    <div className={local.propertyName}>
                                        <FormattedMessage id='common.rows' />
                                    </div>
                                    <div className={local.propertyValue}>
                                        {numRows}
                                    </div>
                                </div>
                            }
                            {
                                Number.isInteger(numBytes) &&
                                <div className={classnames(local.column, 'container-column')}>
                                    <div className={local.propertyName}>
                                        <FormattedMessage id='common.size' />
                                    </div>
                                    <div className={local.propertyValue}>
                                        {filesize(numBytes, { base: 2, standard: 'jedec', output: 'string' })}
                                    </div>
                                </div>
                            }
                            {
                                location &&
                                <div className={classnames(local.column, 'container-column')}>
                                    <div className={local.propertyName}>
                                        <FormattedMessage id='common.location' />
                                    </div>
                                    <div className={local.propertyValue}>
                                        {location}
                                    </div>
                                </div>
                            }
                            <div className='ls-button'>
                                <button className={local.moreButton} onClick={() => { setTableDataOpen(true); }}>
                                    <FormattedMessage id='common.moreDetails' />
                                </button>
                            </div>
                        </div>
                        <div className={local.tableTitle}>
                            <FormattedMessage id='common.schema' />
                        </div>
                        <div className={classnames(local.schemaRow, local.header, 'container-row')}>
                            <SortableHeader
                                id='name'
                                title={<FormattedMessage id='common.field' />}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                sortCallback={schemaFieldsSort}
                                className={local.columnName}
                            />
                            <SortableHeader
                                id='type'
                                title={<FormattedMessage id='common.type' />}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                sortCallback={schemaFieldsSort}
                                className={local.columnType}
                            />
                            <div className={local.columnNullable}>
                                <FormattedMessage id='common.nullable' />
                            </div>
                        </div>
                        {
                            schemaFields.map(({ name, type, mode }, idx) => (
                                <div key={idx} className={classnames(local.schemaRow, 'container-row')}>
                                    <div className={local.columnName}>
                                        <TextWithTooltip>{name}</TextWithTooltip>
                                    </div>
                                    <div className={local.columnType}>
                                        <FormattedMessage id={`dataCatalog.fieldType.${type}`} defaultMessage={type} />
                                    </div>
                                    <div className={local.columnNullable}>
                                        {mode === SCHEMA_FIELD_MODE.NULLABLE && <FontAwesomeIcon icon={faCheck} className={local.label} />}
                                    </div>
                                </div>
                            ))
                        }
                        <TableData isOpen={isTableDataOpen} setOpen={setTableDataOpen} />
                    </Fragment>
            }
        </Fragment> :
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='dataCatalog.tableDetails' />
            </div>
            <div className={local.noDataMessage}>
                <FormattedMessage id='dataCatalog.noTableSelected' />
            </div>
        </Fragment>;
};

export default TableSchema;
