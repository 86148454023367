import React from 'react';
import { useParams } from 'react-router-dom';

export const withRouteParams = (WrappedComponent) => (props) => {
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            match={{ params }}
        />
    );
};
