import React, { FunctionComponent } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

import local from './local.module.scss';

interface CheckboxProps {
    inputProperties: any;
    disabled?: boolean;
    label: JSX.Element | string;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({ inputProperties, label, disabled = false }) => (
    <FormControlLabel
        classes={{
            root: local.checkboxLabelRoot,
            label: local.checkboxLabel,
            disabled: local.checkboxLabelDisabled
        }}
        control={
            <MuiCheckbox
                {...inputProperties}
                checked={Boolean(inputProperties.value)}
                classes={{
                    root: local.checkboxRoot,
                    checked: local.checkboxChecked,
                    disabled: local.checkboxDisabled
                }}
                icon={<FontAwesomeIcon className={local.checkboxUncheckedIcon} icon={faSquare} />}
                checkedIcon={<FontAwesomeIcon className={local.checkboxCheckedIcon} icon={faCheckSquare} />}
                disableRipple={true}
                disabled={disabled}
            />
        }
        label={label}
    />
);

export default Checkbox;
