import React, { Fragment, FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react';

interface ConditionalWrapperProps {
    condition: boolean;
    wrap: (children?: ReactNode) => ReactElement;
}

const ConditionalWrapper: FunctionComponent<PropsWithChildren<ConditionalWrapperProps>> = ({ condition = false, wrap, children = null }) => {
    if (condition) {
        return wrap(children);
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    );
};

export default ConditionalWrapper;
