import { connect } from 'react-redux';

import * as actions from 'modules/transformationInstances/actions';
import { getInstancesListProps } from 'modules/transformationInstances/selectors';
import InstancesList from './InstancesList';

const mapDispatchToProps = {
    onSort: actions.instancesListSort,
    goToInstanceDetailsPage: actions.goToInstanceDetailsPage
};

export default connect(getInstancesListProps, mapDispatchToProps)(InstancesList);
