import * as t from '../actionTypes';

export const setInstancesListItems = (data) => ({
    type: t.SET_INSTANCES_LIST_ITEMS,
    payload: data
});

export const appendInstancesListItems = (data) => ({
    type: t.APPEND_INSTANCES_LIST_ITEMS,
    payload: data
});

export const setInstancesListPage = (data) => ({
    type: t.SET_INSTANCES_LIST_PAGE,
    payload: data
});

export const setInstancesListCount = (data) => ({
    type: t.SET_INSTANCES_LIST_COUNT,
    payload: data
});

export const setInstancesListLoadStatus = (data) => ({
    type: t.SET_INSTANCES_LIST_LOAD_STATUS,
    payload: data
});

export const setInstancesListSortColumn = (data) => ({
    type: t.SET_INSTANCES_LIST_SORT_COLUMN,
    payload: data
});

export const setInstancesListSortOrder = (data) => ({
    type: t.SET_INSTANCES_LIST_SORT_ORDER,
    payload: data
});

export const setInstancesListSearchText = (data) => ({
    type: t.SET_INSTANCES_LIST_SEARCH_TEXT,
    payload: data
});

export const setInstancesListShowArchived = (data) => ({
    type: t.SET_INSTANCES_LIST_SHOW_ARCHIVED,
    payload: data
});

export const resetInstancesList = () => ({
    type: t.RESET_INSTANCES_LIST
});

export const setInstanceDetailsLoadStatus = (data) => ({
    type: t.SET_INSTANCE_DETAILS_LOAD_STATUS,
    payload: data
});

export const setInstanceDetails = (data) => ({
    type: t.SET_INSTANCE_DETAILS,
    payload: data
});

export const resetInstanceDetails = () => ({
    type: t.RESET_INSTANCE_DETAILS
});

export const setInstanceDetailsOperationInProgress = (data) => ({
    type: t.SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS,
    payload: data
});

export const setInstanceDetailsOperationInProgressReporterData = (data) => ({
    type: t.SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA,
    payload: data
});

export const unsetInstanceDetailsOperationInProgressReporterData = () => ({
    type: t.UNSET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA
});

export const resetInstanceForm = () => ({
    type: t.RESET_INSTANCE_FORM
});

export const setInstanceFormOperationInProgress = (data) => ({
    type: t.SET_INSTANCE_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const addTrigger = () => ({
    type: t.ADD_TRIGGER
});

export const removeTrigger = (data) => ({
    type: t.REMOVE_TRIGGER,
    payload: data
});

export const updateTrigger = (data) => ({
    type: t.UPDATE_TRIGGER,
    payload: data
});

export const initTriggers = (data) => ({
    type: t.INIT_TRIGGERS,
    payload: data
});

export const setInstanceFormTriggersInstances = (data) => ({
    type: t.SET_INSTANCE_FORM_TRIGGERS_INSTANCES,
    payload: data
});

export const setInstanceFormTriggersInstancesLoadStatus = (data) => ({
    type: t.SET_INSTANCE_FORM_TRIGGERS_INSTANCES_LOAD_STATUS,
    payload: data
});

export const setInstanceFormTriggersFeeds = (data) => ({
    type: t.SET_INSTANCE_FORM_TRIGGERS_FEEDS,
    payload: data
});

export const setInstanceFormTriggersFeedsLoadStatus = (data) => ({
    type: t.SET_INSTANCE_FORM_TRIGGERS_FEEDS_LOAD_STATUS,
    payload: data
});

export const setExportTargetsData = (data) => ({
    type: t.SET_EXPORT_TARGETS_DATA,
    payload: data
});

export const setExportTargetsDataLoadStatus = (data) => ({
    type: t.SET_EXPORT_TARGETS_DATA_LOAD_STATUS,
    payload: data
});

export const setInstanceFormPipelines = (data) => ({
    type: t.SET_INSTANCE_FORM_PIPELINES,
    payload: data
});

export const setInstanceFormPipelinesLoadStatus = (data) => ({
    type: t.SET_INSTANCE_FORM_PIPELINES_LOAD_STATUS,
    payload: data
});

export const setInstanceFormPipelineDetailsLoadStatus = (data) => ({
    type: t.SET_INSTANCE_FORM_PIPELINE_DETAILS_LOAD_STATUS,
    payload: data
});

export const setInstanceFormConfigurationTemplate = (data) => ({
    type: t.SET_INSTANCE_FORM_CONFIGURATION_TEMPLATE,
    payload: data
});

export const setInstanceFormFlags = (data) => ({
    type: t.SET_INSTANCE_FORM_FLAGS,
    payload: data
});

export const openAdHocRunDialog = () => ({
    type: t.OPEN_AD_HOC_RUN_DIALOG
});

export const closeAdHocRunDialog = () => ({
    type: t.CLOSE_AD_HOC_RUN_DIALOG
});

export const setInstanceRunMode = (data) => ({
    type: t.SET_RUN_MODE,
    payload: data
});

export const openInstanceDeleteDialog = () => ({
    type: t.OPEN_INSTANCE_DELETE_DIALOG
});

export const setInstanceDeleteDialogDependentInstances = (data) => ({
    type: t.SET_INSTANCE_DELETE_DIALOG_INSTANCES,
    payload: data
});

export const closeInstanceDeleteDialog = () => ({
    type: t.CLOSE_INSTANCE_DELETE_DIALOG
});
