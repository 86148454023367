import * as t from '../actionTypes';

export const setAuthorizationTypes = (data) => ({
    type: t.SET_AUTHORIZATION_TYPES,
    payload: data
});

export const setAuthorizationTypesLoadStatus = (data) => ({
    type: t.SET_AUTHORIZATION_TYPES_LOAD_STATUS,
    payload: data
});

export const setAuthorizationTypeOptions = (data) => ({
    type: t.SET_AUTHORIZATION_TYPE_OPTIONS,
    payload: data
});

export const setAuthorizationTypeOptionsLoadStatus = (data) => ({
    type: t.SET_AUTHORIZATION_TYPE_OPTIONS_LOAD_STATUS,
    payload: data
});

export const setAuthorizationFormOperationInProgress = (data) => ({
    type: t.SET_AUTHORIZATION_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const appendAuthorizationsListItems = (data) => ({
    type: t.APPEND_AUTHORIZATIONS_LIST_ITEMS,
    payload: data
});

export const setAuthorizationsListItems = (data) => ({
    type: t.SET_AUTHORIZATIONS_LIST_ITEMS,
    payload: data
});

export const setAuthorizationsListType = (data) => ({
    type: t.SET_AUTHORIZATIONS_LIST_TYPE,
    payload: data
});

export const setAuthorizationsListPage = (data) => ({
    type: t.SET_AUTHORIZATIONS_LIST_PAGE,
    payload: data
});

export const setAuthorizationsListCount = (data) => ({
    type: t.SET_AUTHORIZATIONS_LIST_COUNT,
    payload: data
});

export const setAuthorizationsListLoadStatus = (data) => ({
    type: t.SET_AUTHORIZATIONS_LIST_LOAD_STATUS,
    payload: data
});

export const setAuthorizationsListOperationInProgress = (data) => ({
    type: t.SET_AUTHORIZATIONS_LIST_OPERATION_IN_PROGRESS,
    payload: data
});

export const resetAuthorizationsList = () => ({
    type: t.RESET_AUTHORIZATIONS_LIST
});
