import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import classnames from 'classnames';

import { LOAD_STATUS, PERMISSIONS, AUTOMATION_ID } from 'modules/common/constants';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { LoadStatus, Owner, RouteComponentProps } from 'modules/common/interfaces';
import { transformationApiDefinitions } from 'modules/service/types';
import { PIPELINE_STATE } from '../../constants';

import local from './local.module.scss';

interface PipelineDetailsLayoutProps {
    owners: Owner[];
    data: transformationApiDefinitions['PipelineDetailsResponseDto'];
    loadStatus: LoadStatus;
    loadData: Function;
    resetData: Function;
    goToEditPipelinePage: Function;
    goToPipelinesListPage: Function;
}

interface RouteMatchParams {
    ownerId: string;
    pipelineId: string;
}

class PipelineDetailsLayout extends Component<PipelineDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> {
    private link;

    public componentDidMount() {
        const { loadData, resetData, match: { params: { ownerId, pipelineId } } } = this.props;
        const { canView } = this.checkAccess();

        resetData();

        if (canView) {
            loadData(ownerId, pipelineId);
        }
    }

    public componentDidUpdate(prevProps) {
        const { match: { params: { ownerId: prevOwnerId, pipelineId: prevPipelineId } } } = prevProps;
        const { loadData, resetData, match: { params: { ownerId, pipelineId } } } = this.props;

        // in case when owner or pipeline ID in page URL is changed by user, component is not re-mounted, so need to check access again
        if ((ownerId !== prevOwnerId) || (pipelineId !== prevPipelineId)) {
            const { canView } = this.checkAccess();

            resetData();

            if (canView) {
                loadData(ownerId, pipelineId);
            }
        }
    }

    public render() {
        const { canView } = this.checkAccess();

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const { name: ownerName } = this.getOwner() as Owner;
        const {
            data: {
                purpose,
                description,
                airflowDagId,
                updateTimestamp,
                author,
                supportsIngestionSettings,
                supportsExportTargets,
                updatedBy = 0
            },
            // match: { params: { ownerId, pipelineId } },
            // goToEditPipelinePage,
            loadStatus
        } = this.props;

        const isLoaded = loadStatus === LOAD_STATUS.LOADED;

        return (
            <div>
                {this.renderBreadcrumbs()}
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            {isLoaded ? purpose : <SkeletonItem width='320px' height='24px' />}
                        </div>
                        {
                            // currently only advanced pipelines logic is implemented (they are not editable from UI)
                            // TODO uncomment this code when simple pipelines logic will be implemented
                            // canManage && <div className='ls-button'>
                            //     <button id={AUTOMATION_ID.PIPELINE_EDIT_BTN} disabled={!isLoaded} onClick={() => { goToEditPipelinePage(pipelineId, ownerId); }}>
                            //         <FormattedMessage id='common.edit' />
                            //     </button>
                            // </div>
                        }
                    </div>
                    <div className={local.ownerName}>
                        {
                            isLoaded ?
                                ownerName :
                                <SkeletonItem width='160px' height='20px' />
                        }
                    </div>
                    {
                        this.renderPipelineLabel()
                    }
                    {
                        isLoaded ?
                            airflowDagId &&
                            <Fragment>
                                <div className={local.propertyName}>
                                    <FormattedMessage id='pipelines.dagId' />
                                </div>
                                <div className={local.propertyValue}>
                                    {airflowDagId}
                                </div>
                            </Fragment> :
                            <Fragment>
                                <div className={local.propertyName}>
                                    <SkeletonItem width='160px' height='24px' />
                                </div>
                                <div className={local.propertyValue}>
                                    <SkeletonItem width='320px' height='20px' />
                                </div>
                            </Fragment>
                    }
                    {
                        isLoaded ?
                            description &&
                            <Fragment>
                                <div className={local.propertyName}>
                                    <FormattedMessage id='common.description' />
                                </div>
                                <div className={local.propertyValue}>
                                    {description}
                                </div>
                            </Fragment> :
                            <Fragment>
                                <div className={local.propertyName}>
                                    <SkeletonItem width='160px' height='24px' />
                                </div>
                                <div className={local.propertyValue}>
                                    <SkeletonItem width='320px' height='20px' />
                                </div>
                            </Fragment>
                    }
                    {
                        isLoaded &&
                        <Fragment>
                            {this.renderAirflowTemplateDownloadLink()}
                            <div className={local.propertyName}>
                                <FormattedMessage id='pipelines.supportsIngestionSettings' />
                                <FontAwesomeIcon icon={supportsIngestionSettings ? faCheck : faTimes} className={local.icon} />
                            </div>
                            <div className={local.propertyName}>
                                <FormattedMessage id='pipelines.supportsExportTargets' />
                                <FontAwesomeIcon icon={supportsExportTargets ? faCheck : faTimes} className={local.icon} />
                            </div>
                            <div className={local.propertyName}>
                                <FormattedMessage id='common.author' />
                            </div>
                            <div className={local.propertyValue}>
                                {author}
                            </div>
                            <div className={local.divider} />
                            <div className={local.lastUpdatedTitle}>
                                <FormattedMessage id='common.lastUpdated' />
                            </div>
                            <div className={local.lastUpdatedValue}>
                                {`${moment.utc(updateTimestamp).format('DD MMMM YYYY [at] HH:mm UTC')} by ${updatedBy}`}
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        );
    }

    private getOwner = () => {
        const { owners, match: { params: { ownerId } } } = this.props;

        return owners.find(({ id }) => id === ownerId);
    }

    private checkAccess = () => {
        const owner = this.getOwner();

        const canView = owner && owner.permissions.includes(PERMISSIONS.PIPELINES.VIEW);
        const canManage = owner && owner.permissions.includes(PERMISSIONS.PIPELINES.MANAGE);

        return { canView, canManage };
    }

    private renderBreadcrumbs = () => {
        const { loadStatus, goToPipelinesListPage, data: { purpose } } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_PIPELINES_BREADCRUMB,
                        label: (<FormattedMessage id='pipelines.allPipelines' />),
                        onClick: () => { goToPipelinesListPage(); }
                    },
                    {
                        label: loadStatus === LOAD_STATUS.LOADED ? purpose! : ''
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private renderPipelineLabel = () => {
        const { data: { state }, loadStatus } = this.props;

        return loadStatus === LOAD_STATUS.LOADED && state !== PIPELINE_STATE.ACTIVE ?
            <div className={local.label}>
                <FormattedMessage id={`pipelines.state.${state}`} />
            </div> :
            null;
    }

    private renderAirflowTemplateDownloadLink = () => {
        const { airflowConfigurationTemplate } = this.props.data;

        return airflowConfigurationTemplate ?
            <Fragment>
                <div className={local.propertyName}>
                    <FormattedMessage id='pipelines.airflowConfigurationTemplate' />
                </div>
                <div className={local.download}>
                    <a
                        href='/#'
                        ref={(ref) => { this.link = ref; }}
                        style={{ display: 'none' }}
                    >
                        Download configuration template
                    </a>
                    <FontAwesomeIcon icon={faFileAlt} />
                    <span onClick={this.downloadAirflowTemplate}>
                        configurationTemplate.json
                    </span>
                </div>
            </Fragment> :
            null;
    }

    private downloadAirflowTemplate = () => {
        const { link, props: { data: { airflowConfigurationTemplate } } } = this;

        if (link && airflowConfigurationTemplate) {
            const blob = new Blob(
                [ airflowConfigurationTemplate ],
                { type: 'application/json;charset=utf-8;' }
            );

            link.href = URL.createObjectURL(blob);
            link.download = 'configurationTemplate.json';

            return link.click();
        }
    }
}

export default PipelineDetailsLayout;
