import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createHashHistory } from 'history';
import reduxLogger from 'redux-logger';

import { createRootReducer } from './reducers';
import { handleClientRoute, trackPageForAnalytics, saveLocation } from './middlewares';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createHashHistory()
});

const configureStore = (state) => {
    const __LOCAL__ = process.env.NODE_ENV === 'development';

    let middleWares = [ reduxThunk, handleClientRoute, trackPageForAnalytics, saveLocation ];

    if (__LOCAL__) {
        middleWares = [ ...middleWares, reduxLogger ];
    }

    const composeEnhancers = (typeof window === 'object' && __LOCAL__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    return createStore(createRootReducer(routerReducer), state, composeEnhancers(applyMiddleware(routerMiddleware, ...middleWares)));
};

const initialState = window.__INITIAL_REDUX_STATE__ || {};

export const store = configureStore(initialState);

export const reduxHistory = createReduxHistory(store);
