import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import * as t from '../actionTypes';

const ACTION_HANDLERS = {
    [t.SET_UPLOADS_TYPE]: (state, action) => ({
        ...state,
        selectedUploadsType: action.payload
    }),
    [t.SET_UPLOADS_LIST_ITEMS]: (state, action) => ({
        ...state,
        uploadsList: {
            ...state.uploadsList,
            items: action.payload
        }
    }),
    [t.SET_UPLOADS_LIST_ITEMS_LOAD_STATUS]: (state, action) => ({
        ...state,
        uploadsList: {
            ...state.uploadsList,
            loadStatus: action.payload
        }
    }),
    [t.SET_UPLOADS_LIST_DATE]: (state, action) => ({
        ...state,
        uploadsList: {
            ...state.uploadsList,
            date: action.payload
        }
    }),
    [t.SET_UPLOAD_IN_PROGRESS]: (state, action) => ({
        ...state,
        uploadInProgress: action.payload
    }),
    [t.SET_UPLOAD_SUCCESS]: (state, action) => ({
        ...state,
        uploadSuccess: action.payload
    }),
    [t.SET_UPLOAD_ERRORS]: (state, action) => ({
        ...state,
        uploadErrors: action.payload
    }),
    [t.SET_UPLOADS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        uploadsList: {
            ...state.uploadsList,
            sortBy: action.payload
        }
    }),
    [t.SET_UPLOADS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        uploadsList: {
            ...state.uploadsList,
            sortOrder: action.payload
        }
    }),
    [t.RESET_UPLOADS_LIST]: (state) => ({
        ...state,
        uploadsList: initialState.uploadsList
    })
};

export const initialState = {
    selectedUploadsType: null,
    uploadInProgress: false,
    uploadSuccess: null,
    uploadErrors: [],
    uploadsList: {
        items: [],
        loadStatus: LOAD_STATUS.REQUIRED,
        date: null,
        sortBy: 'created',
        sortOrder: SORT_ORDER.DESC
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
