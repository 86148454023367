import { formatString } from 'modules/common/utils';
import { TARGET_CREDENTIALS_ROUTES } from './constants';
import TargetCredentialFormLayout from './components/TargetCredentialFormLayout';
import TargetCredentialDetailsLayout from './components/TargetCredentialDetailsLayout';

export default [
    {
        path: TARGET_CREDENTIALS_ROUTES.NEW,
        component: TargetCredentialFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(TARGET_CREDENTIALS_ROUTES.EDIT, ':ownerId', ':credentialId'),
        component: TargetCredentialFormLayout,
        params: {
            isEditMode: true
        }
    },
    {
        path: formatString(TARGET_CREDENTIALS_ROUTES.DETAILS, ':ownerId', ':credentialId'),
        component: TargetCredentialDetailsLayout
    }
];
