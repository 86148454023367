import React, { FunctionComponent, useCallback, useState } from 'react';
import classnames from 'classnames';

import service from 'modules/service';
import { EVENT_CATEGORY } from 'modules/common/constants';

interface InputProps {
    id?: string;
    inputProperties: { [key: string]: any };
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    className?: string;
    trackTiming?: boolean;
    trackingName?: string;
    multiline?: boolean;
    password?: boolean;
}

const isTextSecurityAttributeSupported = window.getComputedStyle(document.body).hasOwnProperty('webkitTextSecurity');

const timestamp = () => (new Date()).getTime();

const Input: FunctionComponent<InputProps> = ({
    id,
    inputProperties,
    placeholder,
    maxLength,
    className,
    trackingName,
    trackTiming = false,
    disabled = false,
    multiline = false,
    password = false
}) => {
    const [focusTimestamp, setFocusTimestamp] = useState<number | null>(null);

    const onFocus = useCallback((...args) => {
        if (trackTiming && (id || trackingName)) {
            setFocusTimestamp(timestamp());
        }

        inputProperties.onFocus && inputProperties.onFocus(...args);
    }, [ trackTiming, id, trackingName, inputProperties, setFocusTimestamp ]);

    const onBlur = useCallback((...args) => {
        if (trackTiming && (focusTimestamp !== null)) {
            const diff = Math.ceil((timestamp() - focusTimestamp) / 1000);
            setFocusTimestamp(null);

            service.analytics.trackEvent(`Input focused (${trackingName || id})`, EVENT_CATEGORY.TIMING, '', diff);
        }

        inputProperties.onBlur && inputProperties.onBlur(...args);
    }, [ trackTiming, id, trackingName, inputProperties, focusTimestamp, setFocusTimestamp ]);

    const props = {
        ...inputProperties,
        id,
        placeholder,
        disabled,
        maxLength,
        onFocus,
        onBlur
    };

    return (
        <div className={classnames(className, 'ls-input', { password })}>
            {
                multiline ?
                    <textarea {...props} /> :
                    <input type={password && !isTextSecurityAttributeSupported ? 'password' : 'text'} {...props} />
            }
        </div>
    );
};

export default Input;
