import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { clearSubmitErrors, WrappedFieldProps } from 'redux-form';

import { LoadStatus } from 'modules/common/interfaces';
import { useAppDispatch } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import { loadAuthorizationTypeOptions } from '../../actions';

import local from '../AuthorizationFormLayout/local.module.scss';

type AuthorizationTypeFieldProps = WrappedFieldProps & {
    disabled: boolean;
    items: SelectInputItem[];
    itemsLoadStatus: LoadStatus;
};

export const AuthorizationTypeFieldRenderer: FunctionComponent<AuthorizationTypeFieldProps> = ({
    input,
    meta,
    disabled,
    items,
    itemsLoadStatus
}) => {
    const dispatch = useAppDispatch();

    return (
        <Fragment>
            <SelectInput
                inputProperties={{
                    ...input,
                    onChange: (value) => {
                        if (value !== input.value) {
                            dispatch(clearSubmitErrors('authorizationForm'));
                            dispatch(loadAuthorizationTypeOptions(value));
                        }

                        input.onChange(value);
                    }
                }}
                width={440}
                disabled={disabled}
                items={items}
                isLoading={itemsLoadStatus !== LOAD_STATUS.LOADED}
                error={Boolean(meta.error)}
                searchable={true}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

export const ErrorFieldRenderer: FunctionComponent<WrappedFieldProps> = ({ meta: { error } }) =>
    error ?
        <div className={local.error} dangerouslySetInnerHTML={{ __html: error }} /> :
        null;
