import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { ingestionApiDefinitions } from 'modules/service/types';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH, AUTOMATION_ID } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { formatString } from 'modules/common/utils';
import { COLLECTOR_CREDENTIALS_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface CollectorCredentialsListProps {
    items: Array<ingestionApiDefinitions['CredentialSearchResponseDto']>;
    totalCount: number;
    status: LoadStatus;
    sortOrder: SortOrder;
    sortBy: string;
    onSort: Function;
    goToCollectorCredentialDetailsPage: Function;
    loadMore: Function;
}

class CollectorCredentialsList extends Component<CollectorCredentialsListProps> {
    public render() {
        const { items, totalCount, status, onSort, loadMore } = this.props;
        const isLoaded = status === LOAD_STATUS.LOADED;

        return (
            <Fragment>
                <Table
                    columns={this.getTableConfig()}
                    data={items}
                    onSort={onSort}
                    skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                    isLoaded={isLoaded}
                    onRowClick={this.onTableRowClick}
                />
                {
                    isLoaded && (totalCount > items.length) &&
                    <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                }
            </Fragment>
        );
    }

    private getTableConfig = () => {
        const { sortBy, sortOrder } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.name' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'name',
                accessor: ({ id, ownerId, name }) => (
                    <a href={`#${formatString(COLLECTOR_CREDENTIALS_ROUTES.DETAILS, ownerId, id)}`} className={local.name}>
                        {name}
                    </a>
                ),
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '74%',
                skeletonCellWidth: 698,
                sortable: true,
                showTooltip: true,
                cellClassName: AUTOMATION_ID.COLLECTOR_CREDENTIALS_TABLE_NAME
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.collector' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                accessor: 'collector',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 192,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.COLLECTOR_CREDENTIALS_TABLE_COLLECTOR}`
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.owner' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                accessor: 'ownerName',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 96,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.COLLECTOR_CREDENTIALS_TABLE_OWNER}`
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'lastUpdated',
                accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 166,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.COLLECTOR_CREDENTIALS_TABLE_LAST_UPDATED}`
            }
        ];
    }

    private onTableRowClick = ({ id, ownerId }) => {
        const { status, goToCollectorCredentialDetailsPage } = this.props;

        if (status === LOAD_STATUS.LOADED) {
            goToCollectorCredentialDetailsPage(id, ownerId);
        }
    }
}

export default CollectorCredentialsList;
