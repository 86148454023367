export const REDUCER_NAME = 'owners';
export const OWNERS_LIST_PAGE_SIZE = 50;
export const OWNER_MEMBERSHIPS_LIST_PAGE_SIZE = 50;
export const OWNER_GROUPS_LIST_PAGE_SIZE = 50;

export const OWNERS_ROUTES = {
    LIST: '/admin/owners',
    NEW: '/admin/owners/add',
    DETAILS: '/admin/owners/details/{0}'
};
