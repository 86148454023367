import React, { FunctionComponent, useEffect } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import { getReportCollectorCredentialForm } from '../../selectors';
import { getCollectorCredentialManifest, getCollectorsList } from '../../actions';
import { NameFieldRenderer, OwnerFieldRenderer, CollectorFieldRenderer } from './fieldRenderers';

const CollectorCredentialForm: FunctionComponent<InjectedFormProps> = ({
    change,
    handleSubmit
}) => {
    const dispatch = useAppDispatch();

    const {
        collectors: {
            list: collectors,
            loadStatus: collectorsLoadStatus
        },
        manifest: {
            object: manifest,
            loadStatus: manifestLoadStatus
        },
        credentialOperationInProgress: isOperationInProgress,
        data: { vieCollectorId }
    } = useSelector(getReportCollectorCredentialForm);

    const formValuesVieCollectorId = useReduxFormValue('reportCollectorCredentialForm', 'collectorId');

    useEffect(() => {
        if (collectorsLoadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getCollectorsList());
        }
    }, [ collectorsLoadStatus ]);

    useEffect(() => {
        change('collectorId', vieCollectorId);
        dispatch(getCollectorCredentialManifest(vieCollectorId));
    }, []);

    return (
        <form id='reportCollectorCredentialForm' onSubmit={handleSubmit} autoComplete='off'>
            <Field
                name='name'
                isOperationInProgress={isOperationInProgress}
                component={NameFieldRenderer}
            />
            <Field
                name='ownerId'
                isOperationInProgress={isOperationInProgress}
                component={OwnerFieldRenderer}
            />
            <Field
                name='collectorId'
                collectors={collectors}
                collectorsLoadStatus={collectorsLoadStatus}
                isOperationInProgress={isOperationInProgress}
                component={CollectorFieldRenderer}
            />
            {
                manifestLoadStatus === LOAD_STATUS.LOADING && <SkeletonSectionLoaderIndicator />
            }
            {
                manifestLoadStatus === LOAD_STATUS.LOADED && manifest.panels ?
                    <DynamicFieldsSection
                        prefix={formValuesVieCollectorId}
                        change={change}
                        config={manifest}
                        disabled={isOperationInProgress}
                        form='reportCollectorCredentialForm'
                    /> :
                    null
            }
        </form>
    );
};

export default CollectorCredentialForm;
