import React, { FunctionComponent } from 'react';
import { default as MuiSwitch, SwitchProps } from '@mui/material/Switch';

import local from './local.module.scss';

const Switch: FunctionComponent<SwitchProps> = (props) => (
    <MuiSwitch
        classes={{
            root: local.root,
            switchBase: local.switchBase,
            checked: local.checked,
            track: local.track,
            thumb: local.thumb,
            disabled: local.disabled
        }}
        {...props}
    />
);

export default Switch;
