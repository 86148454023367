import { LOCATION_CHANGE } from 'redux-first-history';

import service from 'modules/service';
import { saveSelectedClientId, setClientIdToCheckOnLoad } from 'modules/auth/actions';
import { getSelectedClientId, getUserProfile, isUserDataLoaded } from 'modules/auth/selectors';
import { FEEDS_ROUTES } from 'modules/feeds/constants';
import { INSTANCES_ROUTES } from 'modules/transformationInstances/constants';
import { REPORTS_ROUTES } from 'modules/reports/constants';

let currentLocation;

export const saveLocation = () => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        currentLocation = action?.payload?.location?.pathname;
    }

    next(action);
};

const { DETAILS: FEED_DETAILS, EDIT: FEED_EDIT, PENDING: FEED_PENDING, RUNS: FEED_RUNS } = FEEDS_ROUTES;
const { DETAILS: INSTANCE_DETAILS, EDIT: INSTANCE_EDIT } = INSTANCES_ROUTES;
const { ASSETS: REPORT_ASSETS, EDIT: REPORT_EDIT } = REPORTS_ROUTES;
const CLIENT_SPECIFIC_ROUTES = [
    FEED_DETAILS.substring(0, FEED_DETAILS.indexOf('{')),
    FEED_EDIT.substring(0, FEED_EDIT.indexOf('{')),
    FEED_RUNS.substring(0, FEED_RUNS.indexOf('{')),
    FEED_PENDING.substring(0, FEED_PENDING.indexOf('{')),
    INSTANCE_DETAILS.substring(0, INSTANCE_DETAILS.indexOf('{')),
    INSTANCE_EDIT.substring(0, INSTANCE_EDIT.indexOf('{')),
    REPORT_ASSETS.substring(0, REPORT_ASSETS.indexOf('{')),
    REPORT_EDIT.substring(0, REPORT_EDIT.indexOf('{'))
];

export const handleClientRoute = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        const location = action?.payload?.location?.pathname;

        if (location === currentLocation) {
            next(action);

            return;
        }

        const matchingRoute = CLIENT_SPECIFIC_ROUTES.find((route) => location.startsWith(route));

        if (matchingRoute) {
            const regExp = new RegExp(`${matchingRoute}([a-z0-9-]+)(/|$)`);
            const match = location.match(regExp);

            if (match && match[1]) {
                const routeClientId = match[1];

                const storeState = getState();

                if (isUserDataLoaded(storeState)) {
                    const selectedClientId = getSelectedClientId(storeState);
                    const { clients } = getUserProfile(storeState);

                    if (routeClientId !== selectedClientId && clients.some(({ id }) => id === routeClientId)) {
                        dispatch(saveSelectedClientId(routeClientId));
                    }
                } else {
                    dispatch(setClientIdToCheckOnLoad(routeClientId));
                }
            }
        }
    }

    next(action);
};

const guidPattern = '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';
const idsRegExp = new RegExp(`(/${guidPattern})*$`);

export const trackPageForAnalytics = () => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        const location = action?.payload?.location?.pathname;

        // ignore root location ('/'), as it's automatically redirects user to home page
        // if it is recorded, bounce rate statistics will be incorrect
        if (location === currentLocation || location === '/') {
            next(action);

            return;
        }

        const pathWithoutIds = location.replace(idsRegExp, '');

        service.analytics.trackPage(pathWithoutIds);
    }

    next(action);
};
