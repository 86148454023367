// clients list
export const SET_CLIENTS = 'admin/clients/SET_CLIENTS';
export const SET_CLIENTS_LOAD_STATUS = 'admin/clients/SET_CLIENTS_LOAD_STATUS';
export const RESET_CLIENTS = 'admin/clients/RESET_CLIENTS';
export const SET_CLIENTS_LIST_SEARCH_TEXT = 'admin/clients/SET_CLIENTS_LIST_SEARCH_TEXT';
export const SET_CLIENTS_LIST_SORT_COLUMN = 'admin/clients/SET_CLIENTS_LIST_SORT_COLUMN';
export const SET_CLIENTS_LIST_SORT_ORDER = 'admin/clients/SET_CLIENTS_LIST_SORT_ORDER';
export const SET_CLIENTS_LIST_PAGE = 'admin/clients/SET_CLIENTS_LIST_PAGE';

// client form
export const SET_CLIENT_FORM_OPERATION_IN_PROGRESS = 'admin/clients/SET_CLIENT_FORM_OPERATION_IN_PROGRESS';

// client memberships list
export const SET_CLIENT_MEMBERSHIPS_LIST_SEARCH_TEXT = 'admin/clients/SET_CLIENT_MEMBERSHIPS_LIST_SEARCH_TEXT';
export const SET_CLIENT_MEMBERSHIPS_LIST_SORT_COLUMN = 'admin/clients/SET_CLIENT_MEMBERSHIPS_LIST_SORT_COLUMN';
export const SET_CLIENT_MEMBERSHIPS_LIST_SORT_ORDER = 'admin/clients/SET_CLIENT_MEMBERSHIPS_LIST_SORT_ORDER';
export const SET_CLIENT_MEMBERSHIPS_LIST_PAGE = 'admin/clients/SET_CLIENT_MEMBERSHIPS_LIST_PAGE';
export const SET_CLIENT_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS = 'admin/clients/SET_CLIENT_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS';
export const ADD_CLIENT_MEMBER_ROLE = 'admin/clients/ADD_CLIENT_MEMBER_ROLE';
export const REMOVE_CLIENT_MEMBER_ROLE = 'admin/clients/REMOVE_CLIENT_MEMBER_ROLE';
export const REMOVE_CLIENT_MEMBERSHIP = 'admin/clients/REMOVE_CLIENT_MEMBERSHIP';
export const OPEN_CLIENT_MEMBERSHIP_DIALOG = 'admin/clients/OPEN_CLIENT_MEMBERSHIP_DIALOG';
export const CLOSE_CLIENT_MEMBERSHIP_DIALOG = 'admin/clients/CLOSE_CLIENT_MEMBERSHIP_DIALOG';

// client details
export const SET_CLIENT_DETAILS = 'admin/clients/SET_CLIENT_DETAILS';
export const SET_CLIENT_DETAILS_LOAD_STATUS = 'admin/clients/SET_CLIENT_DETAILS_LOAD_STATUS';
export const RESET_CLIENT_DETAILS = 'admin/clients/RESET_CLIENT_DETAILS';

// client groups list
export const SET_CLIENT_GROUPS_LIST_SORT_COLUMN = 'admin/clients/SET_CLIENT_GROUPS_LIST_SORT_COLUMN';
export const SET_CLIENT_GROUPS_LIST_SORT_ORDER = 'admin/clients/SET_CLIENT_GROUPS_LIST_SORT_ORDER';
export const SET_CLIENT_GROUPS_LIST_PAGE = 'admin/clients/SET_CLIENT_GROUPS_LIST_PAGE';
