import * as t from '../actionTypes';

export const setTargetsListItems = (data) => ({
    type: t.SET_TARGETS_LIST_ITEMS,
    payload: data
});

export const appendTargetsListItems = (data) => ({
    type: t.APPEND_TARGETS_LIST_ITEMS,
    payload: data
});

export const setTargetsListPage = (data) => ({
    type: t.SET_TARGETS_LIST_PAGE,
    payload: data
});

export const setTargetsListCount = (data) => ({
    type: t.SET_TARGETS_LIST_COUNT,
    payload: data
});

export const setTargetsListLoadStatus = (data) => ({
    type: t.SET_TARGETS_LIST_LOAD_STATUS,
    payload: data
});

export const setTargetsListSortColumn = (data) => ({
    type: t.SET_TARGETS_LIST_SORT_COLUMN,
    payload: data
});

export const setTargetsListSortOrder = (data) => ({
    type: t.SET_TARGETS_LIST_SORT_ORDER,
    payload: data
});

export const setTargetsListSearchText = (data) => ({
    type: t.SET_TARGETS_LIST_SEARCH_TEXT,
    payload: data
});

export const resetTargetsList = () => ({
    type: t.RESET_TARGETS_LIST
});

export const setTargetManifest = (data) => ({
    type: t.SET_TARGET_MANIFEST,
    payload: data
});

export const setTargetManifestLoadStatus = (data) => ({
    type: t.SET_TARGET_MANIFEST_LOAD_STATUS,
    payload: data
});

export const resetTargetManifest = () => ({
    type: t.RESET_TARGET_MANIFEST
});

export const setTargetDetailsLoadStatus = (data) => ({
    type: t.SET_TARGET_DETAILS_LOAD_STATUS,
    payload: data
});

export const setTargetDetailsOperationInProgress = (data) => ({
    type: t.SET_TARGET_DETAILS_OPERATION_IN_PROGRESS,
    payload: data
});

export const setTargetDetails = (data) => ({
    type: t.SET_TARGET_DETAILS,
    payload: data
});

export const resetTargetDetails = () => ({
    type: t.RESET_TARGET_DETAILS
});

export const setTargetFormOperationInProgress = (data) => ({
    type: t.SET_TARGET_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const resetTargetForm = () => ({
    type: t.RESET_TARGET_FORM
});
