import React, { Fragment, FunctionComponent, useState, useCallback } from 'react';
import classnames from 'classnames';
import { Chip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

import { CLIENT_ROLE_GROUPS, OWNER_ROLE_GROUPS, ROLE_TYPE } from '../../constants';
import RolesSelectMenu from '../RolesSelectMenu';

import local from './local.module.scss';

interface RolesSelectProps {
    value: string[];
    onChange: Function;
    type: typeof ROLE_TYPE[keyof typeof ROLE_TYPE];
    disabled?: boolean;
    width?: number;
    error?: string;
}

const RolesSelect: FunctionComponent<RolesSelectProps> = ({ type, value, onChange, width, disabled, error }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleClick = useCallback((event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    }, [ setAnchorEl, setOpen, disabled ]);

    return (
        <Fragment>
            <div
                className={classnames(local.container, 'container-row', { [local.disabled]: disabled, [local.error]: error })}
                style={{ width: width ? `${width}px` : 'auto' }}
                onClick={handleClick}
            >
                <div>
                    {
                        value.length ?
                            value.map((role, index) =>
                                <Chip
                                    classes={{
                                        root: local.chipRoot,
                                        disabled: local.chipDisabled
                                    }}
                                    key={role}
                                    label={<FormattedMessage id={`admin.roles.${role}`} defaultMessage={role} />}
                                    size='small'
                                    onDelete={() => {
                                        if (!disabled) {
                                            onChange([ ...value.slice(0, index), ...value.slice(index + 1, value.length) ]);
                                        }
                                    }}
                                    disabled={disabled}
                                    deleteIcon={
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={local.deleteIcon}
                                        />
                                    }
                                />
                            ) :
                            <span className={local.placeholder}>
                                <FormattedMessage id='admin.selectRoles' />
                            </span>
                    }
                </div>
                <FontAwesomeIcon
                    icon={open ? faChevronUp : faChevronDown}
                    className={local.arrow}
                />
            </div>
            {
                error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                </div>
            }
            <RolesSelectMenu
                open={open}
                anchorEl={anchorEl}
                disabled={disabled}
                itemGroups={type === ROLE_TYPE.CLIENT ? CLIENT_ROLE_GROUPS : OWNER_ROLE_GROUPS}
                width={anchorEl ? anchorEl.getBoundingClientRect().width : 0}
                selectedItems={value}
                onClose={() => { setOpen(false); }}
                onSelect={(id) => { onChange([ ...value, id ]); }}
            />
        </Fragment>
    );
};

export default RolesSelect;
