import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Input from 'modules/common/components/Input';
import SelectInput from 'modules/common/components/SelectInput';
import RadioSwitch from 'modules/common/components/RadioSwitch';
import Accordion from 'modules/common/components/Accordion';
import CronField from 'modules/common/components/CronField';
import { detectCronFrequency, parseCronExpression, normalizeCollectionOffsetDaysField } from 'modules/common/utils';
import { ingestionApiDefinitions } from 'modules/service/types';
import { LoadStatus } from 'modules/common/interfaces';
import {
    LOAD_STATUS,
    WEEKDAY_SELECT_OPTIONS,
    DAY_OF_MONTH_SELECT_OPTIONS,
    SCHEDULE_FREQUENCY,
    HOUR_SELECT_OPTIONS,
    MINUTE_SELECT_OPTIONS,
    CRON_FREQUENCY_SWITCH_OPTIONS,
    TEMP_ID_PREFIX
} from 'modules/common/constants';

import local from '../FeedFormLayout/local.module.scss';

interface FeedScheduleProps {
    id: string;
    index: number;
    fieldPrefix: string;
    intl: IntlShape;
    isOperationInProgress: boolean;
    isEditMode: boolean;
    data: ingestionApiDefinitions['FeedScheduleDetailsDto'];
    change: Function;
    removeSubmitError: Function;
    removeSchedule: Function;
    collectionOffsetTemplate: Array<ingestionApiDefinitions['CollectionOffsetTemplateDetailResponseDto']>;
    collectionOffsetTemplateLoadStatus: LoadStatus;
    cron: { [key: string]: any };
}

class FeedSchedule extends Component<FeedScheduleProps> {
    public componentDidMount() {
        const { fieldPrefix, change, data } = this.props;

        if (!data || !Object.keys(data).length) {
            // set default values
            change(`${fieldPrefix}.cron.frequency`, SCHEDULE_FREQUENCY.WEEKLY);
            change(`${fieldPrefix}.collectionOffsetDays`, 0);
        } else {
            this.setCron(data.cron);
            change(`${fieldPrefix}.collectionOffsetDays`, data.collectionOffsetDays || 0);
            change(`${fieldPrefix}.collectionOffsetTemplateId`, data.collectionOffsetTemplateId);
        }
    }

    public componentDidUpdate(prevProps) {
        const { data, change, isEditMode, fieldPrefix, id } = this.props;

        if (isEditMode && !id.startsWith(TEMP_ID_PREFIX)) {
            const { collectionOffsetTemplateId, collectionOffsetDays, cron } = data;
            const {
                data: {
                    cron: prevCron,
                    collectionOffsetTemplateId: prevCollectionOffsetTemplateId,
                    collectionOffsetDays: prevCollectionOffsetDays
                }
            } = prevProps;

            if (collectionOffsetTemplateId && collectionOffsetTemplateId !== prevCollectionOffsetTemplateId) {
                change(`${fieldPrefix}.collectionOffsetTemplateId`, collectionOffsetTemplateId);
            }

            if (cron !== prevCron) {
                this.setCron(cron);
            }

            if (collectionOffsetDays !== prevCollectionOffsetDays) {
                change(`${fieldPrefix}.collectionOffsetDays`, collectionOffsetDays);
            }
        }
    }

    public render() {
        const { fieldPrefix, index } = this.props;

        return (
            <div className={local.scheduleContainer}>
                <Accordion classes={{ summaryContent: local.scheduleLabel, detailsRoot: local.scheduleDetails }}
                    label={
                        <Fragment>
                            <FormattedMessage id='common.schedule' /> #{index + 1}
                            <Field name={fieldPrefix} component={this.renderErrorIcon} />
                        </Fragment>
                    }
                >
                    {this.renderFrequencySwitch()}
                    {this.renderFrequencyOptions()}
                    {this.renderCollectionOffsetTemplateField()}
                    <div className={classnames('container-row', local.bottomContainer)}>
                        {this.renderCollectionOffsetDaysField()}
                        {this.renderDeleteButton()}
                    </div>
                </Accordion>
            </div>
        );
    }

    private renderErrorIcon = ({ meta }) =>
        meta.error ? <FontAwesomeIcon icon={faCircleExclamation} className={local.errorIcon} /> : null

    private setCron(cron) {
        const { fieldPrefix, change } = this.props;
        const cronValues = parseCronExpression(cron);
        const frequency = detectCronFrequency(cronValues);

        change(`${fieldPrefix}.cron.expression`, cron);
        change(`${fieldPrefix}.cron.frequency`, frequency);

        if (frequency !== SCHEDULE_FREQUENCY.CUSTOM) {
            if (frequency === SCHEDULE_FREQUENCY.MONTHLY) {
                change(`${fieldPrefix}.cron.dayOfMonth`, cronValues.dayOfMonth);
            } else if (frequency === SCHEDULE_FREQUENCY.WEEKLY) {
                change(`${fieldPrefix}.cron.weekday`, cronValues.weekday);
            }

            change(`${fieldPrefix}.cron.hour`, cronValues.hour);
            change(`${fieldPrefix}.cron.minute`, cronValues.minute);
        }
    }

    private renderCollectionOffsetTemplateField = () => {
        const {
            collectionOffsetTemplateLoadStatus,
            collectionOffsetTemplate,
            isOperationInProgress,
            fieldPrefix
        } = this.props;

        const props = {
            disabled: isOperationInProgress,
            collectionOffsetTemplateLoadStatus,
            collectionOffsetTemplate
        };

        return (
            <Field
                name={`${fieldPrefix}.collectionOffsetTemplateId`}
                props={props}
                component={this.collectionOffsetTemplateFieldComponent}
            />
        );
    }

    private collectionOffsetTemplateFieldComponent = ({
        input,
        collectionOffsetTemplate,
        collectionOffsetTemplateLoadStatus,
        disabled,
        meta
    }) => {
        const items = collectionOffsetTemplate.map(({ id, description }) => ({
            id,
            name: this.props.intl.formatMessage({ id: `collectionOffsetTemplate.${id}`, defaultMessage: description })
        }));

        const placeholder = <FormattedMessage id='feeds.selectReportRange' />;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='feeds.reportRange' />
                </div>
                <SelectInput
                    placeholder={placeholder}
                    items={items}
                    inputProperties={input}
                    disabled={disabled}
                    isLoading={collectionOffsetTemplateLoadStatus !== LOAD_STATUS.LOADED}
                    error={meta.error}
                    trackTiming={true}
                    trackingName='Feed report range'
                />
                {
                    meta.error ?
                        <div className='form-error-message'>
                            <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                        </div> :
                        <div className={local.warning}>
                            <FormattedMessage id='feeds.selectReportRangeMessage' />
                        </div>
                }
            </div>
        );
    }

    private renderCollectionOffsetDaysField = () => {
        const { isOperationInProgress, fieldPrefix } = this.props;

        return (
            <Field
                name={`${fieldPrefix}.collectionOffsetDays`}
                disabled={isOperationInProgress}
                component={this.collectionOffsetDaysFieldComponent}
                normalize={normalizeCollectionOffsetDaysField}
            />
        );
    }

    private collectionOffsetDaysFieldComponent = ({ input, disabled, meta }) => {
        const fieldText = this.props.intl.formatMessage({ id: 'feeds.collectionOffsetDays' });

        return (
            <div className={local.field}>
                <div className={local.label}>
                    {fieldText}
                </div>
                <Input
                    className={classnames(local.offsetDaysInput, { 'error-input': Boolean(meta.error) })}
                    inputProperties={input}
                    placeholder={fieldText}
                    disabled={disabled}
                />
                {
                    meta.error ?
                        <div className='form-error-message'>
                            <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                        </div> :
                        <div className={local.warning}>
                            <FormattedMessage id='feeds.collectionOffsetDaysMessage' />
                        </div>
                }
            </div>
        );
    }

    private renderFrequencySwitch = () => {
        const { isOperationInProgress, removeSubmitError, fieldPrefix } = this.props;

        return (
            <Fragment>
                <div className={local.label}>
                    <FormattedMessage id='common.frequency' />
                </div>
                <RadioSwitch
                    fieldName={`${fieldPrefix}.cron.frequency`}
                    options={CRON_FREQUENCY_SWITCH_OPTIONS}
                    onChange={() => {
                        removeSubmitError({
                            form: 'feedForm',
                            field: `${fieldPrefix}.cron`
                        });
                    }}
                    classes={{
                        switch: local.switchContainer,
                        option: local.option
                    }}
                    disabled={isOperationInProgress}
                />
            </Fragment>
        );
    }

    private renderFrequencyOptions = () => {
        const { cron, isOperationInProgress } = this.props;

        switch (cron?.frequency) {
            case SCHEDULE_FREQUENCY.DAILY:
                return (
                    <div className={classnames(local.cronControls, 'container-row')}>
                        {this.renderTimeSelects(isOperationInProgress)}
                    </div>
                );
            case SCHEDULE_FREQUENCY.WEEKLY:
                return (
                    <div className={classnames(local.cronControls, 'container-row')}>
                        {this.renderWeekdaySelect(isOperationInProgress)}
                        {this.renderTimeSelects(isOperationInProgress)}
                    </div>
                );
            case SCHEDULE_FREQUENCY.MONTHLY:
                return (
                    <div className={classnames(local.cronControls, 'container-row')}>
                        {this.renderDayOfMonthSelect(isOperationInProgress)}
                        {this.renderTimeSelects(isOperationInProgress)}
                    </div>
                );
            case SCHEDULE_FREQUENCY.CUSTOM:
                return (
                    <div className={classnames(local.cronControls)}>
                        <CronField
                            name={`${this.props.fieldPrefix}.cron.expression`}
                            disabled={isOperationInProgress}
                        />
                    </div>
                );
        }
    }

    private renderTimeSelects = (disabled) => {
        const { change, fieldPrefix, cron: { minute } } = this.props;

        const hourSelectConfig = {
            onChange: () => {
                if (!minute) {
                    change('cron.minute', MINUTE_SELECT_OPTIONS[0].id);
                }
            },
            fieldName: `${fieldPrefix}.cron.hour`,
            placeholderId: 'common.hour',
            labelId: 'common.hour',
            options: HOUR_SELECT_OPTIONS,
            width: 100,
            searchable: true,
            disabled
        };

        const minuteSelectConfig = {
            fieldName: `${fieldPrefix}.cron.minute`,
            placeholderId: 'common.minute',
            labelId: 'common.minute',
            options: MINUTE_SELECT_OPTIONS,
            width: 100,
            searchable: true,
            disabled
        };

        return (
            <Fragment>
                {this.renderSelect(hourSelectConfig)}
                {this.renderSelect(minuteSelectConfig)}
            </Fragment>
        );
    }

    private renderWeekdaySelect = (disabled) => {
        const config = {
            fieldName: `${this.props.fieldPrefix}.cron.weekday`,
            placeholderId: 'common.weekday',
            labelId: 'common.weekday',
            options: WEEKDAY_SELECT_OPTIONS,
            width: 229,
            disabled
        };

        return this.renderSelect(config);
    }

    private renderDayOfMonthSelect = (disabled) => {
        const config = {
            fieldName: `${this.props.fieldPrefix}.cron.dayOfMonth`,
            placeholderId: 'common.dayOfMonth',
            labelId: 'common.dayOfMonth',
            options: DAY_OF_MONTH_SELECT_OPTIONS,
            width: 138,
            searchable: true,
            disabled
        };

        return this.renderSelect(config);
    }

    private renderSelect = ({ fieldName, ...rest }) => (
        <Field
            name={fieldName}
            component={this.selectComponent}
            props={rest}
        />
    )

    private selectComponent = ({
        input,
        inputId,
        onChange,
        placeholderId,
        labelId,
        options,
        width,
        searchable,
        disabled,
        meta
    }) => {
        const placeholder = this.props.intl.formatMessage({ id: placeholderId });

        return (
            <div>
                {labelId && (<div className={local.label}>
                    <FormattedMessage id={labelId} />
                </div>)}
                <SelectInput
                    inputId={inputId}
                    placeholder={placeholder}
                    items={options}
                    width={width}
                    inputProperties={
                        onChange ?
                            {
                                ...input,
                                onChange: (value) => {
                                    onChange(value);
                                    input.onChange(value);
                                }
                            } :
                            input
                    }
                    disabled={disabled}
                    searchable={searchable}
                    error={meta.error}
                    trackTiming={true}
                />
            </div>
        );
    }

    private renderDeleteButton = () => {
        const { index, removeSchedule, isOperationInProgress } = this.props;

        return (
            <div
                className={classnames(local.deleteIconContainer, { [local.disabled]: Boolean(isOperationInProgress) })}
                onClick={(event) => {
                    event.preventDefault();
                    removeSchedule(index);
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
            </div>
        );
    }
}

export default FeedSchedule;
