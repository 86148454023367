import React, { Component } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';
import classnames from 'classnames';

import { ingestionApiDefinitions } from 'modules/service/types';
import { LOAD_STATUS } from 'modules/common/constants';
import { LoadStatus, RouteComponentProps } from 'modules/common/interfaces';
import Input from 'modules/common/components/Input';
import DataCollectionSection from '../DataCollectionSection';
import ConfigurationSection from '../ConfigurationSection';

import local from '../FeedFormLayout/local.module.scss';

interface FeedFormProps {
    intl: IntlShape;
    resetForm: Function;
    resetData: Function;
    getFeedDataForEditing: Function;
    data: ingestionApiDefinitions['FeedVersionDetailResponseDto'];
    isOperationInProgress: boolean;
    isEditMode: boolean;
    isPendingVersion: boolean;
    dataLoadStatus: LoadStatus;
}

interface RouteMatchParams {
    clientId: string;
    feedId: string;
}

class FeedForm extends Component<FeedFormProps & InjectedFormProps<{}, FeedFormProps> & RouteComponentProps<RouteMatchParams>> {
    public componentDidMount() {
        const {
            isEditMode,
            resetForm,
            resetData,
            match,
            getFeedDataForEditing
        } = this.props;

        resetForm();

        if (isEditMode) {
            const { clientId, feedId } = match.params;
            resetData();
            getFeedDataForEditing(clientId, feedId);
        }
    }

    public componentDidUpdate(prevProps) {
        const {
            isEditMode,
            match,
            resetData,
            resetForm,
            change,
            data,
            getFeedDataForEditing
        } = this.props;

        if (isEditMode) {
            const { match: { params: { clientId: prevClientId, feedId: prevFeedId } }, data: prevData } = prevProps;
            const { params: { clientId, feedId } } = match;

            if ((clientId !== prevClientId) || (feedId !== prevFeedId)) {
                resetForm();
                resetData();
                getFeedDataForEditing(clientId, feedId);
            }

            if (!Object.keys(prevData).length && data !== prevData) {
                const { suffix, name } = data;
                change('name', name);
                change('suffix', suffix);
            }
        }
    }

    public render() {
        const {
            handleSubmit,
            isEditMode,
            isPendingVersion,
            isOperationInProgress,
            change,
            data,
            dataLoadStatus
        } = this.props;

        return (
            <form id='feedForm' onSubmit={handleSubmit} autoComplete='off'>
                {this.renderGlobalError()}
                {this.renderIdentifierField()}
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.configuration' />
                </div>
                <ConfigurationSection
                    isEditMode={isEditMode}
                    isPendingVersion={isPendingVersion}
                    data={data}
                    change={change}
                    isOperationInProgress={isOperationInProgress}
                />
                <DataCollectionSection
                    isEditMode={isEditMode}
                    data={data}
                    dataLoadStatus={dataLoadStatus}
                    change={change}
                    isOperationInProgress={isOperationInProgress}
                />
            </form>
        );
    }

    private renderGlobalError = () => {
        const { error } = this.props;

        return error ?
            <div className={local.globalError}>
                <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
            </div> :
            null;
    }

    private renderIdentifierField = () => {
        const { isOperationInProgress, dataLoadStatus } = this.props;

        return (
            <Field
                name='suffix'
                isOperationInProgress={isOperationInProgress}
                dataLoadStatus={dataLoadStatus}
                component={this.identifierFieldComponent}
            />
        );
    }

    private identifierFieldComponent = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { intl, isEditMode } = this.props;
        const placeholder = intl.formatMessage({ id: isEditMode && dataLoadStatus !== LOAD_STATUS.LOADED ? 'common.loading' : 'feeds.identifier' });
        const disabled = isOperationInProgress || (isEditMode && dataLoadStatus !== LOAD_STATUS.LOADED);

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='feeds.feedIdentifier' />
                </div>
                <Input
                    inputProperties={input}
                    placeholder={placeholder}
                    disabled={disabled || isEditMode}
                    maxLength={255}
                    className={classnames({ 'error-input': Boolean(meta.error) })}
                    trackTiming={true}
                    trackingName='Feed identifier'
                />
                {
                    meta.error ?
                        <div className='form-error-message'>
                            <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                        </div> :
                        <div className={local.warning}>
                            <FormattedMessage id='feeds.identifierMessage' />
                        </div>
                }
            </div>
        );
    }
}

export default FeedForm;
