import { isAvroDateOrTimestampType } from 'modules/common/utils';
import { AVRO_FIELD_TYPES, SCHEMA_MODE } from '../constants';

export const buildSourceReportFieldPrefix = (ownerName) => {
    if (!ownerName) {
        return '';
    }

    // `replace` call can be removed in case if owner name validation will be implemented
    return `${ownerName.toUpperCase().replace(/[^A-Z_]/g, '')}_`;
};

export const isCorrectDateColumnType = (type, mode) =>
    mode === SCHEMA_MODE.SIMPLE ?
        [ AVRO_FIELD_TYPES.DATE, AVRO_FIELD_TYPES.TIMESTAMP ].includes(type) :
        isAvroDateOrTimestampType(type);
