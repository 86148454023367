import React, { FunctionComponent } from 'react';
import { Field } from 'redux-form';

import { ROLE_TYPE } from '../../constants';
import RolesSelect from '../RolesSelect';

interface RolesSelectFieldProps {
    fieldName: string;
    type: typeof ROLE_TYPE[keyof typeof ROLE_TYPE];
    disabled?: boolean;
    width?: number;
}

const RolesSelectComponent = ({ input, meta, type, width, disabled }) => (
    <RolesSelect
        value={input.value || []}
        onChange={input.onChange}
        type={type}
        width={width}
        disabled={disabled}
        error={meta.error}
    />
);

const RolesSelectField: FunctionComponent<RolesSelectFieldProps> = ({ fieldName, type, width, disabled = false }) => (
    <Field
        name={fieldName}
        type={type}
        disabled={disabled}
        width={width}
        component={RolesSelectComponent}
    />
);

export default RolesSelectField;
