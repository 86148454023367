import React, { useEffect, useCallback, FunctionComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { getUserProfile } from 'modules/auth/selectors';
import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { LOAD_STATUS, AUTOMATION_ID, PERMISSIONS } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import SearchInput from 'modules/common/components/SearchInput';
import TargetsList from '../TargetsList';
import { getTargetsList, goToAddTargetPage, resetTargetsList, targetsListSearch } from '../../actions';
import { getTargetsListState } from '../../selectors';

import local from './local.module.scss';

const TargetsListLayout: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const { loadStatus } = useSelector(getTargetsListState);
    const { owners } = useSelector(getUserProfile);

    const canView = owners?.some(({ permissions }) => permissions.includes(PERMISSIONS.TARGETS.VIEW));
    const canManage = owners?.some(({ permissions }) => permissions.includes(PERMISSIONS.TARGETS.MANAGE));

    useEffect(() => {
        if (canView) {
            dispatch(resetTargetsList());
            dispatch(getTargetsList());
        }
    }, []);

    useDidUpdateEffect(() => {
        if (canView && loadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getTargetsList());
        }
    }, [ loadStatus ]);

    const newButtonCallback = useCallback(() => {
        dispatch(goToAddTargetPage());
    }, []);

    const searchCallback = useCallback((searchString: string) => {
        dispatch(targetsListSearch(searchString));
    }, []);

    if (!canView) {
        return (
            <ErrorPage error='403' />
        );
    }

    const isLoaded = loadStatus === LOAD_STATUS.LOADED;

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='common.destinations' />
            </div>
            <div className={classnames(local.tableControls, 'container-row')}>
                <SearchInput disabled={!isLoaded} onSearch={searchCallback} />
                {
                    canManage &&
                    <div className='ls-button'>
                        <button
                            id={AUTOMATION_ID.NEW_TARGET_BTN}
                            onClick={newButtonCallback}
                            disabled={!isLoaded}
                        >
                            <FormattedMessage id='targets.newDestination' />
                        </button>
                    </div>
                }
            </div>
            <div className={local.card}>
                <TargetsList />
            </div>
        </Fragment>
    );
};

export default TargetsListLayout;
