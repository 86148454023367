import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import { SAVE_MODE } from 'modules/sources/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { AUTOMATION_ID, EVENT_CATEGORY } from 'modules/common/constants';
import { Owner, RouteComponentProps } from 'modules/common/interfaces';
import { getFirstWorkingDayOfNextMonth } from 'modules/common/utils';
import SourceForm from '../SourceForm';

import local from './local.module.scss';

interface LocationState {
    testMode?: boolean;
    dataToClone?: ingestionApiDefinitions['SourceVersionDetailDto'];
}

interface SourceFormLayoutProps {
    isEditMode: boolean;
    isOperationInProgress: boolean;
    displayName: string;
    goToSourcesListPage: Function;
    goToSourceDetailsPage: Function;
    confirmAddSource: Function;
    confirmEditSource: Function;
    clearSubmitErrors: Function;
    owners: Owner[];
    isDataLoading: boolean;
    locationState?: LocationState;
}

interface RouteMatchParams {
    ownerId: string;
    sourceId: string;
    versionId: string;
}

interface SourceFormLayoutState {
    saveMode: typeof SAVE_MODE[keyof typeof SAVE_MODE];
}

class SourceFormLayout extends Component<SourceFormLayoutProps & RouteComponentProps<RouteMatchParams>, SourceFormLayoutState> {
    constructor(props) {
        super(props);

        this.state = {
            saveMode: SAVE_MODE.LIVE
        };
    }

    public render() {
        if (!this.checkAccess()) {
            return (
                <ErrorPage error='403' />
            );
        }

        const { isEditMode, isOperationInProgress, isDataLoading, match, locationState } = this.props;
        const { saveMode } = this.state;

        const isSavingInProgress = isOperationInProgress && (saveMode === SAVE_MODE.LIVE);
        const isSavingAsDraftInProgress = isOperationInProgress && (saveMode === SAVE_MODE.DRAFT);

        return (
            <Fragment>
                {
                    this.renderBreadcrumbs()
                }
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            <FormattedMessage id={isEditMode ? 'sources.editSource' : 'sources.newSource'} />
                        </div>
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.SOURCE_FORM_CANCEL_BTN}
                                    disabled={isOperationInProgress}
                                    className='btn-flat'
                                    onClick={this.onCancelButtonClick}
                                >
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <FormSubmitButton
                                id={AUTOMATION_ID.SOURCE_FORM_SAVE_AS_DRAFT_BTN}
                                form='sourceForm'
                                width={126}
                                onClick={this.onSaveAsDraftButtonClick}
                                disabled={isOperationInProgress || isDataLoading}
                                text={<FormattedMessage id='common.saveAsDraft' />}
                                isSubmitting={isSavingAsDraftInProgress}
                                secondary={true}
                            />
                            <FormSubmitButton
                                id={AUTOMATION_ID.SOURCE_FORM_SUBMIT_BTN}
                                form='sourceForm'
                                width={79}
                                onClick={this.onSaveButtonClick}
                                disabled={isOperationInProgress || isDataLoading}
                                text={<FormattedMessage id='common.submit' />}
                                isSubmitting={isSavingInProgress}
                            />
                        </div>
                    </div>
                    <SourceForm
                        onSubmit={this.onFormSubmit}
                        isEditMode={isEditMode}
                        match={match}
                        dataToClone={locationState?.dataToClone}
                        testMode={Boolean(locationState?.testMode)}
                        initialValues={{
                            deduplicate: false,
                            goLiveDate: getFirstWorkingDayOfNextMonth(),
                            bigquery: { partitionByDateColumn: false }
                        }}
                    />
                </div>
            </Fragment>
        );
    }

    private renderBreadcrumbs = () => {
        const { goToSourcesListPage, isEditMode, displayName } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_SOURCES_BREADCRUMB,
                        label: (<FormattedMessage id='sources.allSources' />),
                        onClick: () => { goToSourcesListPage(); }
                    },
                    {
                        label: isEditMode ? displayName : (<FormattedMessage id='sources.newSource' />)
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private onSaveButtonClick = () => {
        this.setState({ saveMode: SAVE_MODE.LIVE });
    }

    private onSaveAsDraftButtonClick = () => {
        this.setState({ saveMode: SAVE_MODE.DRAFT });
    }

    private checkAccess = () => {
        const { isEditMode, owners, match: { params: { ownerId } } } = this.props;

        return isEditMode ?
            Boolean(owners.find(({ id }) => id === ownerId)) :
            Boolean(owners.length);
    }

    private onFormSubmit = (values) => {
        const {
            isEditMode,
            locationState,
            confirmEditSource,
            confirmAddSource,
            clearSubmitErrors,
            match: {
                params: {
                    sourceId,
                    versionId
                }
            }
        } = this.props;

        const { saveMode } = this.state;

        clearSubmitErrors('sourceForm');

        return isEditMode ?
            confirmEditSource(sourceId, versionId, values, saveMode) :
            confirmAddSource(values, saveMode, Boolean(locationState?.testMode));
    }

    private onCancelButtonClick = () => {
        const { isEditMode, goToSourceDetailsPage, goToSourcesListPage, match } = this.props;

        if (isEditMode) {
            const { sourceId, ownerId } = match.params;
            goToSourceDetailsPage(sourceId, ownerId);

            service.analytics.trackEvent('Cancel source edit', EVENT_CATEGORY.SOURCES);
        } else {
            goToSourcesListPage();

            service.analytics.trackEvent('Cancel source creation', EVENT_CATEGORY.SOURCES);
        }
    }
}

export default SourceFormLayout;
