import React, { Fragment, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import { getUserProfile } from 'modules/auth/selectors';
import { useAppDispatch } from 'modules/common/hooks';
import { AUTOMATION_ID, EVENT_CATEGORY, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import {
    getTargetDetails,
    getTargetDetailsLoadStatus,
    getTargetManifestLoadStatus,
    getTargetFormOperationInProgress
} from '../../selectors';
import {
    addTarget,
    editTarget,
    goToTargetDetailsPage,
    goToTargetsListPage
} from '../../actions';
import TargetForm from '../TargetForm';

import local from './local.module.scss';

interface TargetFormLayoutProps {
    isEditMode: boolean;
}

type RouteMatchParams = Record<'ownerId' | 'targetId', string>;

const TargetFormLayout: FunctionComponent<TargetFormLayoutProps> = ({ isEditMode }) => {
    const dispatch = useAppDispatch();

    const { ownerId, targetId } = useParams<RouteMatchParams>();

    const data: ingestionApiDefinitions['TargetDetailResponseDto'] = useSelector(getTargetDetails);
    const dataLoadStatus = useSelector(getTargetDetailsLoadStatus);
    const manifestLoadStatus = useSelector(getTargetManifestLoadStatus);
    const isOperationInProgress = useSelector(getTargetFormOperationInProgress);

    const { owners } = useSelector(getUserProfile);
    const canAccess = isEditMode ?
        owners.find(({ id }) => id === ownerId)?.permissions.includes(PERMISSIONS.TARGETS.MANAGE) :
        owners.some(({ permissions }) => permissions.includes(PERMISSIONS.TARGETS.MANAGE));

    const isSubmitDisabled = isOperationInProgress || [ dataLoadStatus, manifestLoadStatus ].includes(LOAD_STATUS.LOADING);
    const showSubmitButton = !isEditMode || isSubmitDisabled || (dataLoadStatus === LOAD_STATUS.LOADED && data.feedCount === 0);

    if (!canAccess) {
        return (
            <ErrorPage error='403' />
        );
    }

    const onCancelButtonClick = () => {
        if (isEditMode) {
            dispatch(goToTargetDetailsPage(targetId, ownerId));

            service.analytics.trackEvent('Cancel target edit', EVENT_CATEGORY.TARGETS);
        } else {
            dispatch(goToTargetsListPage());

            service.analytics.trackEvent('Cancel target creation', EVENT_CATEGORY.TARGETS);
        }
    };

    const onFormSubmit = (values) => {
        dispatch(clearSubmitErrors('targetForm'));

        return isEditMode ?
            dispatch(editTarget(targetId, values)) :
            dispatch(addTarget(values));
    };

    return (
        <Fragment>
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_TARGETS_BREADCRUMB,
                        label: (<FormattedMessage id='targets.allDestinations' />),
                        onClick: () => { dispatch(goToTargetsListPage()); }
                    },
                    {
                        label: isEditMode ? data.name : (<FormattedMessage id='targets.newDestination' />)
                    }
                ]}
                selectedItemIndex={1}
            />
            <div className={local.card}>
                <div className={classnames(local.titleContainer, 'container-row')}>
                    <div className={local.title}>
                        <FormattedMessage id={isEditMode ? 'targets.editDestination' : 'targets.newDestination'} />
                    </div>
                    <div className={classnames(local.buttonsContainer, 'container-row')}>
                        <div className='ls-button'>
                            <button
                                id={AUTOMATION_ID.TARGET_FORM_CANCEL_BTN}
                                disabled={isOperationInProgress}
                                className='btn-flat'
                                onClick={onCancelButtonClick}
                            >
                                <FormattedMessage id='common.cancel' />
                            </button>
                        </div>
                        {
                            showSubmitButton &&
                            <FormSubmitButton
                                id={AUTOMATION_ID.TARGET_FORM_SAVE_BTN}
                                form='targetForm'
                                width={130}
                                disabled={isSubmitDisabled}
                                text={<FormattedMessage id='common.saveChanges' />}
                                isSubmitting={isOperationInProgress}
                            />
                        }
                    </div>
                </div>
                <TargetForm onSubmit={onFormSubmit} isEditMode={isEditMode} />
            </div>
        </Fragment>
    );
};

export default TargetFormLayout;
