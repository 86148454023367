import { connect } from 'react-redux';

import { withRouteParams } from 'modules/common/hoc';
import { getSourceDetails, goToSourcesListPage, setSourceDetailsView, resetSourceDetails } from '../../actions';
import { getSourceDetailsProps } from '../../selectors';
import SourceDetailsLayout from './SourceDetailsLayout';

const mapDispatchToProps = {
    resetData: resetSourceDetails,
    loadData: getSourceDetails,
    setView: setSourceDetailsView,
    goToSourcesListPage
};

export default withRouteParams(connect(getSourceDetailsProps, mapDispatchToProps)(SourceDetailsLayout));
