import { connect } from 'react-redux';

import { withRouteParams } from 'modules/common/hoc';
import { getPipelineDetails, resetPipelineDetails, goToEditPipelinePage, goToPipelinesListPage } from '../../actions';
import { getPipelineDetailsProps } from '../../selectors';
import PipelineDetailsLayout from './PipelineDetailsLayout';

const mapDispatchToProps = {
    resetData: resetPipelineDetails,
    loadData: getPipelineDetails,
    goToEditPipelinePage,
    goToPipelinesListPage
};

export default withRouteParams(connect(getPipelineDetailsProps, mapDispatchToProps)(PipelineDetailsLayout));
