import React, { Fragment, FunctionComponent, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';

import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import Table from 'modules/common/components/Table';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { formatString } from 'modules/common/utils';
import { getFeedRunsListProps } from '../../selectors';
import { feedRunsListSort, getFeedRuns, loadMoreFeedRuns, resetFeedRunsList, setSelectedFeedRuns } from '../../actions';
import { FEED_EXECUTION_STATUS, FEEDS_ROUTES } from '../../constants';

import local from '../FeedRunsLayout/local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const FeedRunsList: FunctionComponent = () => {
    const {
        items,
        totalCount,
        sortBy,
        sortOrder,
        loadStatus,
        clientId,
        clientPermissions,
        isOperationInProgress
    } = useSelector(getFeedRunsListProps);

    const canRun = clientPermissions.includes(PERMISSIONS.FEEDS.RUN);

    const dispatch = useAppDispatch();

    useEffect(() => () => {
        dispatch(resetFeedRunsList());
    }, []);

    useEffect(() => {
        if (loadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getFeedRuns());
        }
    }, [ loadStatus ]);

    // TODO enable sorting when it will be implemented on BE side
    const tableConfig = [
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.feed' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'name',
            accessor: ({ name, suffix, feedId, adHoc, rerunTime }) => name && suffix ?
                <div className='container-row'>
                    <TextWithTooltip>
                        <a
                            href={`#${formatString(FEEDS_ROUTES.DETAILS, clientId, feedId)}`}
                            className={local.name}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {name}_{suffix}
                        </a>
                    </TextWithTooltip>
                    {
                        rerunTime &&
                        <Tooltip
                            title={
                                <Fragment>
                                    <FormattedMessage id='feeds.restartedAt' />
                                    &nbsp;
                                    {moment(rerunTime).format('YYYY-MM-DD HH:mm:ss')}
                                    &nbsp;
                                    (UTC)
                                </Fragment>
                            }
                            classes={{ tooltip: local.tooltip }}
                        >
                            <span className={classnames(local.label, local.restartedLabel)}>
                                <FormattedMessage id='common.restarted' />
                            </span>
                        </Tooltip>
                    }
                    {
                        adHoc && !rerunTime && <span className={classnames(local.label, local.adHocLabel)}>
                            <FormattedMessage id='feeds.adHoc' />
                        </span>
                    }
                </div> :
                '-',
            width: 240,
            sortable: false,
            showTooltip: false
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.collector' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'collectorName',
            accessor: ({ collectorName }) => collectorName || '-',
            width: 130,
            sortable: false,
            showTooltip: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='feeds.fetchStart' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'fetchStart',
            accessor: ({ fetchStart }) => fetchStart ? moment(fetchStart).format('DD MMM YYYY') : '-',
            width: 120,
            sortable: false,
            showTooltip: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='feeds.fetchEnd' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'fetchEnd',
            accessor: ({ fetchEnd }) => fetchEnd ? moment(fetchEnd).format('DD MMM YYYY') : '-',
            width: 120,
            sortable: false,
            showTooltip: true
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.status' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'status',
            accessor: ({ status, failedComponentName, failedEventGroup, failureReason }) => {
                let className = '';

                switch (status) {
                    case FEED_EXECUTION_STATUS.EMPTY:
                        className = local.empty;
                        break;
                    case FEED_EXECUTION_STATUS.FAILED:
                        className = local.failed;
                        break;
                    case FEED_EXECUTION_STATUS.CANCELLED:
                        className = local.cancelled;
                        break;
                    case FEED_EXECUTION_STATUS.SUCCEEDED:
                        className = local.succeeded;
                        break;
                    case FEED_EXECUTION_STATUS.STARTED:
                        className = local.started;
                        break;
                    case FEED_EXECUTION_STATUS.UNRECONCILED:
                        className = local.unreconciled;
                        break;
                }

                const cellContent = (
                    <span className={classnames(local.statusContainer, className)}>
                        <span className={classnames(local.statusIcon)} />
                        <FormattedMessage id={`feeds.executionStatus.${status}`} defaultMessage={status} />
                    </span>
                );

                if (status === FEED_EXECUTION_STATUS.FAILED && (failedComponentName || failedEventGroup || failureReason)) {
                    return (
                        <Tooltip
                            title={
                                <Fragment>
                                    {
                                        failedComponentName && <div>
                                            <span className={local.tooltipTitle}>
                                                <FormattedMessage id='feeds.failedComponentName' />:&nbsp;
                                            </span>
                                            {failedComponentName}
                                        </div>
                                    }
                                    {
                                        failedEventGroup && <div>
                                            <span className={local.tooltipTitle}>
                                                <FormattedMessage id='feeds.failedEventGroup' />:&nbsp;
                                            </span>
                                            {failedEventGroup}
                                        </div>
                                    }
                                    {
                                        failureReason && <div>
                                            <span className={local.tooltipTitle}>
                                                <FormattedMessage id='feeds.failureReason' />:&nbsp;
                                            </span>
                                            {failureReason}
                                        </div>
                                    }
                                </Fragment>
                            }
                            classes={{ tooltip: local.tooltip }}
                        >
                            {cellContent}
                        </Tooltip>
                    );
                } else {
                    return cellContent;
                }
            },
            width: 120,
            sortable: false,
            showTooltip: false
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='feeds.runDate' />}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
            ),
            id: 'runDate',
            accessor: ({ executionStart }) => executionStart ? moment(executionStart).format('DD MMM YYYY') : '-',
            width: 120,
            sortable: false,
            showTooltip: true
        }
    ];

    const canSelectRow = useCallback(({ feedVersionId, rerunTime }) => Boolean(feedVersionId && !rerunTime), []);

    const isLoaded = loadStatus === LOAD_STATUS.LOADED;

    return (
        <Fragment>
            <Table
                columns={tableConfig}
                data={items}
                onSort={(column) => { dispatch(feedRunsListSort(column)); }}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={isLoaded}
                isSelectionEnabled={canRun}
                onRowsSelect={canRun ? (selectedRowIds) => { dispatch(setSelectedFeedRuns(selectedRowIds)); } : undefined}
                rowIdentifierColumn='correlationId'
                disableCheckboxes={isOperationInProgress}
                canSelectRow={canSelectRow}
            />
            {
                isLoaded && (totalCount > items.length) &&
                <LoadMoreButton
                    load={() => { dispatch(loadMoreFeedRuns()); }}
                    count={items.length}
                    totalCount={totalCount}
                />
            }
        </Fragment>
    );
};

export default FeedRunsList;
