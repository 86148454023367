import { formatString } from 'modules/common/utils';
import { MEMBERS_ROUTES } from './constants';
import MembersListLayout from './components/MembersListLayout';
import MemberDetailsLayout from './components/MemberDetailsLayout';

export default [
    {
        path: MEMBERS_ROUTES.LIST,
        component: MembersListLayout
    },
    {
        path: formatString(MEMBERS_ROUTES.DETAILS, ':memberId'),
        component: MemberDetailsLayout
    }
];
