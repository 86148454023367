// sources list
export const APPEND_SOURCES_LIST_ITEMS = 'sources/APPEND_SOURCES_LIST_ITEMS';
export const SET_SOURCES_LIST_ITEMS = 'sources/SET_SOURCES_LIST_ITEMS';
export const SET_SOURCES_LIST_PAGE = 'sources/SET_SOURCES_LIST_PAGE';
export const SET_SOURCES_LIST_COUNT = 'sources/SET_SOURCES_LIST_COUNT';
export const SET_SOURCES_LIST_LOAD_STATUS = 'sources/SET_SOURCES_LIST_LOAD_STATUS';
export const SET_SOURCES_LIST_SORT_COLUMN = 'sources/SET_SOURCES_LIST_SORT_COLUMN';
export const SET_SOURCES_LIST_SORT_ORDER = 'sources/SET_SOURCES_LIST_SORT_ORDER';
export const SET_SOURCES_LIST_SEARCH_TEXT = 'sources/SET_SOURCES_LIST_SEARCH_TEXT';
export const RESET_SOURCES_LIST = 'sources/RESET_SOURCES_LIST';
export const SET_SOURCES_LIST_FILTERS_STATES = 'sources/SET_SOURCES_LIST_FILTERS_STATES';
export const SET_SOURCES_LIST_FILTERS_OWNER_IDS = 'sources/SET_SOURCES_LIST_FILTERS_OWNER_IDS';

// source details
export const SET_SOURCE_DETAILS = 'sources/SET_SOURCE_DETAILS';
export const SET_ADDITIONAL_SOURCE_DETAILS = 'sources/SET_ADDITIONAL_SOURCE_DETAILS';
export const SET_SOURCE_DETAILS_LOAD_STATUS = 'sources/SET_SOURCE_DETAILS_LOAD_STATUS';
export const RESET_SOURCE_DETAILS = 'sources/RESET_SOURCE_DETAILS';
export const SET_SOURCE_DETAILS_OPERATION_IN_PROGRESS = 'sources/SET_SOURCE_DETAILS_OPERATION_IN_PROGRESS';
export const SET_SOURCE_DETAILS_VIEW = 'sources/SET_SOURCE_DETAILS_VIEW';

// source form
export const SET_SOURCE_FORM_COLLECTORS = 'sources/SET_SOURCE_FORM_COLLECTORS';
export const SET_SOURCE_FORM_COLLECTORS_LOAD_STATUS = 'sources/SET_SOURCE_FORM_COLLECTORS_LOAD_STATUS';
export const SET_SOURCE_FORM_MANIFESTS = 'sources/SET_SOURCE_FORM_MANIFESTS';
export const SET_SOURCE_FORM_MANIFESTS_LOAD_STATUS = 'sources/SET_SOURCE_FORM_MANIFESTS_LOAD_STATUS';
export const SET_SOURCE_FORM_OPERATION_IN_PROGRESS = 'sources/SET_SOURCE_FORM_OPERATION_IN_PROGRESS';
export const SET_SOURCE_FORM_SCHEMA = 'sources/SET_SOURCE_FORM_SCHEMA';
export const SET_SOURCE_FORM_SCHEMA_BACKUP = 'sources/SET_SOURCE_FORM_SCHEMA_BACKUP';
export const SET_SOURCE_FORM_MODE = 'sources/SET_SOURCE_FORM_MODE';
export const RESET_SOURCE_FORM = 'sources/RESET_SOURCE_FORM';
export const ADD_DEDUPLICATE_COLUMN = 'sources/ADD_DEDUPLICATE_COLUMN';
export const REMOVE_DEDUPLICATE_COLUMN = 'sources/REMOVE_DEDUPLICATE_COLUMN';
export const SET_DEDUPLICATE_COLUMNS = 'sources/SET_DEDUPLICATE_COLUMNS';
export const MOVE_DEDUPLICATE_COLUMN = 'sources/MOVE_DEDUPLICATE_COLUMN';
export const SET_DEDUPLICATE_COLUMN_NAME = 'sources/SET_DEDUPLICATE_COLUMN_NAME';
export const SET_DEDUPLICATE_COLUMN_ORDER = 'sources/SET_DEDUPLICATE_COLUMN_ORDER';
export const SET_SOURCE_FORM_DRAFT_FLAG = 'sources/SET_SOURCE_FORM_DRAFT_FLAG';
export const SET_SOURCE_FORM_DATASTREAM_TYPES = 'sources/SET_SOURCE_FORM_DATASTREAM_TYPES';
export const SET_SOURCE_FORM_DATASTREAM_TYPES_LOAD_STATUS = 'sources/SET_SOURCE_FORM_DATASTREAM_TYPES_LOAD_STATUS';
