import React, { Fragment, FunctionComponent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { AUTOMATION_ID, LOAD_STATUS } from 'modules/common/constants';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import SearchInput from 'modules/common/components/SearchInput';
import { TabPanel, Tabs } from 'modules/common/components/Tabs';
import { LoadStatus, RouteComponentProps } from 'modules/common/interfaces';
import { useDidUpdateEffect } from 'modules/common/hooks';
import ErrorPage from 'modules/common/components/ErrorPage';
import OwnerMembershipsList from '../OwnerMembershipsList';
import OwnerGroupsList from '../OwnerGroupsList';
import OwnerMembershipDialog from '../OwnerMembershipDialog';

import local from './local.module.scss';

interface OwnerDetailsLayoutProps {
    ownerName: string;
    isOdpMemberAdministrator: boolean;
    isOperationInProgress: boolean;
    currentUserHasMembership: boolean;
    canView: boolean;
    canManage: boolean;
    status: LoadStatus;
    loadData: Function;
    openOwnerMembershipDialog: Function;
    goToOwnersListPage: Function;
    onSearch: Function;
    resetTable: Function;
    resetData: Function;
    deleteOwner: Function;
}

interface RouteMatchParams {
    ownerId: string;
}

const MEMBERSHIPS_TAB_INDEX = 0;
const GROUPS_TAB_INDEX = 1;

const OwnerDetailsLayout: FunctionComponent<OwnerDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> = (props) => {
    const {
        status,
        ownerName,
        loadData,
        canView,
        canManage,
        isOdpMemberAdministrator,
        isOperationInProgress,
        currentUserHasMembership,
        openOwnerMembershipDialog,
        goToOwnersListPage,
        onSearch,
        resetTable,
        resetData,
        deleteOwner,
        match: { params: { ownerId } }
    } = props;

    const searchInputRef = useRef() as MutableRefObject<HTMLInputElement>;

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const isLoaded = status === LOAD_STATUS.LOADED;
    const areControlsDisabled = !isLoaded || isOperationInProgress;

    useEffect(() => {
        resetData();
        resetTable();
        loadData(ownerId);
    }, []);

    useDidUpdateEffect(() => {
        const { current: input } = searchInputRef;

        if (input) {
            input.value = '';
            input.blur();
        }

        resetData();
        resetTable();
    }, [ ownerId ]);

    useDidUpdateEffect(() => {
        if (status === LOAD_STATUS.REQUIRED) {
            loadData(ownerId);
        }
    }, [ status ]);

    const grantMembershipButtonCallback = useCallback(() => { openOwnerMembershipDialog(); }, []);
    const deleteButtonCallback = useCallback(() => { deleteOwner(ownerId); }, [ ownerId ]);
    const breadcrumbsCallback = useCallback(() => { goToOwnersListPage(); }, []);

    if (!isOdpMemberAdministrator && !canView) {
        return (
            <ErrorPage error='403' />
        );
    }

    if (isLoaded && !ownerName) {
        return (
            <ErrorPage error='404' />
        );
    }

    const breadcrumbsItems = [
        {
            id: AUTOMATION_ID.ALL_OWNERS_BREADCRUMB,
            label: (<FormattedMessage id='admin.allOwners' />),
            onClick: breadcrumbsCallback
        },
        {
            label: ownerName
        }
    ];

    return (
        <Fragment>
            <Breadcrumbs
                items={breadcrumbsItems}
                selectedItemIndex={1}
            />
            <div className={classnames(local.controls, 'container-row')}>
                <Tabs
                    value={selectedTabIndex}
                    onChange={(event, idx) => {
                        setSelectedTabIndex(idx);

                        if (idx === MEMBERSHIPS_TAB_INDEX) {
                            resetTable();
                        }
                    }}
                    labels={[
                        <FormattedMessage id='common.memberships' />,
                        <FormattedMessage id='common.groups' />
                    ]}
                    disabled={areControlsDisabled}
                />
                <div className={classnames(local.tableControls, 'container-row')}>
                    {
                        selectedTabIndex === MEMBERSHIPS_TAB_INDEX &&
                        <Fragment>
                            <SearchInput
                                width={250}
                                inputId={AUTOMATION_ID.OWNER_MEMBERSHIPS_SEARCH}
                                disabled={areControlsDisabled}
                                onSearch={onSearch}
                                ref={searchInputRef}
                            />
                            {
                                isOdpMemberAdministrator &&
                                <div className='ls-button'>
                                    <button
                                        id={AUTOMATION_ID.DELETE_OWNER_BTN}
                                        className='btn-negative'
                                        onClick={deleteButtonCallback}
                                        disabled={areControlsDisabled}
                                    >
                                        <FormattedMessage id='admin.deleteOwner' />
                                    </button>
                                </div>
                            }
                            {
                                (isOdpMemberAdministrator || canManage) &&
                                <div className='ls-button'>
                                    <button
                                        id={AUTOMATION_ID.ADD_OWNER_MEMBER_BTN}
                                        onClick={grantMembershipButtonCallback}
                                        // disable button when user is super-admin, already has role(s) for current owner,
                                        // but doesn't have membership viewer or admin role
                                        disabled={areControlsDisabled || (isOdpMemberAdministrator && !canView && currentUserHasMembership)}
                                    >
                                        <FormattedMessage id='admin.addMember' />
                                    </button>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            </div>
            <TabPanel index={MEMBERSHIPS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <OwnerMembershipsList
                    isOdpMemberAdministrator={isOdpMemberAdministrator}
                    canView={canView}
                    canManage={canManage}
                    ownerId={ownerId}
                />
            </TabPanel>
            <TabPanel index={GROUPS_TAB_INDEX} selectedTabIndex={selectedTabIndex}>
                <OwnerGroupsList />
            </TabPanel>
            <OwnerMembershipDialog ownerId={ownerId} canManage={canManage} />
        </Fragment>
    );
};

export default OwnerDetailsLayout;
