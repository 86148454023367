import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { store, reduxHistory } from './store';

import Layout from 'modules/main/components/Layout';
import MaintenanceLayout from 'modules/main/components/MaintenanceLayout';
import SnackbarProvider from 'modules/main/components/SnackbarProvider';
import ThemeProvider from 'modules/main/components/ThemeProvider';
import PendoProvider from 'modules/main/components/PendoProvider';
import { IntlProviderWrapper } from 'modules/i18n';
import KeycloakProviderWrapper from 'modules/auth/components/KeycloakProviderWrapper';

import './assets/styles/app/index.scss';

const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === 'yes';

const render = () => {
    const rootContainer = document.getElementById('root')!;
    const root = createRoot(rootContainer);

    root.render(
        <IntlProviderWrapper>
            <Provider store={store}>
                <Router history={reduxHistory}>
                    {
                        MAINTENANCE_MODE ?
                            <MaintenanceLayout /> :
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <ThemeProvider>
                                    <PendoProvider>
                                        <SnackbarProvider>
                                            <KeycloakProviderWrapper>
                                                <Layout />
                                            </KeycloakProviderWrapper>
                                        </SnackbarProvider>
                                    </PendoProvider>
                                </ThemeProvider>
                            </LocalizationProvider>
                    }
                </Router>
            </Provider>
        </IntlProviderWrapper>
    );
};

render();
