import React, { FunctionComponent, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import classnames from 'classnames';

import { AlertOptions } from 'modules/common/interfaces';

import local from './local.module.scss';

type AlertDialogProps = {
    resolve: Function;
    cleanup: Function;
} & AlertOptions;

const AlertDialog: FunctionComponent<AlertDialogProps> = ({
    title,
    content,
    buttonText = 'OK',
    buttonClass,
    resolve,
    cleanup
}) => {
    const [open, setOpen] = useState(true);

    const close = useCallback(() => {
        setOpen(false);
        resolve();

        setTimeout(() => {
            cleanup();
        }, 1000);
    }, [ setOpen, resolve, cleanup ]);

    return (
        <Dialog open={open}>
            <div className={local.card}>
                <div className={local.title}>
                    {title}
                </div>
                <div className={local.content}>
                    {content}
                </div>
                <div className={classnames(local.buttonsContainer, 'container-row')}>
                    <div className='ls-button'>
                        <button className={buttonClass} onClick={close}>{buttonText}</button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AlertDialog;
