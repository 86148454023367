import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { clearSubmitErrors } from 'redux-form';

import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { AUTOMATION_ID, PERMISSIONS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import ErrorPage from 'modules/common/components/ErrorPage';
import CollectorCredentialDrawer from '../CollectorCredentialDrawer';
import ReportForm from '../ReportForm';
import {
    addReport,
    editReport,
    goToReportsListPage,
    loadReportDetailsForEditing,
    loadReportFormData,
    setReportFormStage
} from '../../actions';
import { getReportDetails } from '../../selectors';
import { REPORT_FORM_FIRST_STAGE, REPORT_FORM_SECOND_STAGE } from '../../constants';

type RouteMatchParams = Record<'clientId' | 'reportId', string>;

interface ReportFormLayoutProps {
    isEditMode: boolean;
}

const ReportFormLayout: FunctionComponent<ReportFormLayoutProps> = ({ isEditMode }) => {
    const dispatch = useAppDispatch();
    const params = useParams<RouteMatchParams>();

    const { clients, owners } = useSelector(getUserProfile);
    const selectedClientId = useSelector(getSelectedClientId);
    const client = clients.find(({ id }) => id === (isEditMode ? params.clientId : selectedClientId));
    const canAccess = client?.permissions.includes(PERMISSIONS.REPORTS.MANAGE) &&
        owners.some(({ permissions }) => permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE_FROM_REPORT));
    const { name } = useSelector(getReportDetails);

    useEffect(() => {
        if (canAccess) {
            dispatch(loadReportFormData());
            // skip template selection stage in case of report editing
            dispatch(setReportFormStage(isEditMode ? REPORT_FORM_SECOND_STAGE : REPORT_FORM_FIRST_STAGE));
            isEditMode && dispatch(loadReportDetailsForEditing(params.reportId));
        }
    }, [ canAccess, params ]);

    if (!canAccess) {
        return (
            <ErrorPage error='403' />
        );
    }

    const onFormSubmit = (values) => {
        dispatch(clearSubmitErrors('targetForm'));

        return isEditMode ?
            dispatch(editReport(params.reportId, values)) :
            dispatch(addReport(values));
    };

    return (
        <Fragment>
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_REPORTS_BREADCRUMB,
                        label: (<FormattedMessage id='reports.allReports' />),
                        onClick: () => { dispatch(goToReportsListPage()); }
                    },
                    {
                        label: isEditMode ? (name || '') : (<FormattedMessage id='reports.newReport' />)
                    }
                ]}
                selectedItemIndex={1}
            />
            <ReportForm isEditMode={isEditMode} onSubmit={onFormSubmit} />
            <CollectorCredentialDrawer />
        </Fragment>
    );
};

export default ReportFormLayout;
