import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import { getIsOwnerMembershipDialogOpen } from '../../selectors';
import { closeOwnerMembershipDialog, addOwnerMember } from '../../actions';
import OwnerMembershipForm from '../OwnerMembershipForm';

interface OwnerMembershipDialogProps {
    ownerId: string;
    canManage: boolean;
}

const OwnerMembershipDialog: FunctionComponent<OwnerMembershipDialogProps> = ({ ownerId, canManage }) => {
    const dispatch = useAppDispatch();
    const isOwnerMembershipDialogOpen = useSelector(getIsOwnerMembershipDialogOpen);
    const onSubmit = useCallback((formValues) => dispatch(addOwnerMember(ownerId, formValues)), [ ownerId ]);
    const onClose = useCallback(() => dispatch(closeOwnerMembershipDialog()), []);

    return (
        <Dialog
            open={isOwnerMembershipDialogOpen}
            title={<FormattedMessage id='admin.addMember' />}
            onClose={onClose}
        >
            <OwnerMembershipForm onSubmit={onSubmit} canManage={canManage} />
        </Dialog>
    );
};

export default OwnerMembershipDialog;
