import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { addGroupItem, getGroupDetails, resetGroupForm, resetGroupDetails, initClientsAndOwners } from '../../actions';
import { getGroupFormProps } from '../../selectors';
import GroupForm from './GroupForm';

const mapDispatchToProps = {
    addItem: addGroupItem,
    resetForm: resetGroupForm,
    resetData: resetGroupDetails,
    getData: getGroupDetails,
    initClientsAndOwners
};

export default reduxForm({ form: 'groupForm' })(connect(getGroupFormProps, mapDispatchToProps)(GroupForm) as any) as any;
