import { push } from 'redux-first-history';
import { SubmissionError } from 'redux-form';

import service from 'modules/service';
import { intl } from 'modules/i18n';
import { ingestionApiDefinitions } from 'modules/service/types';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import {
    confirm,
    formatString,
    getErrorText,
    normalizeManifest,
    prepareFormErrors,
    prepareFormValues,
    showErrorSnackbar
} from 'modules/common/utils';
import { getRouterPath } from 'modules/common/selectors';
import { TARGETS_ROUTES } from '../constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

export const goToTargetsListPage = () => (dispatch) => {
    dispatch(push(TARGETS_ROUTES.LIST));
};

export const goToAddTargetPage = () => (dispatch) => {
    dispatch(push(TARGETS_ROUTES.NEW));
};

export const goToTargetDetailsPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(TARGETS_ROUTES.DETAILS, ownerId, id)));
};

export const getTargetsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setTargetsListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const payload = selectors.getTargetsListSearchPayload(storeState);
        const list: ingestionApiDefinitions['ApiPaginatedCollectionContainerDtoTargetSearchResponseDto'] = await service.api.getTargetsSearchList(payload);

        dispatch(storeActions.appendTargetsListItems(list.objects));
        dispatch(storeActions.setTargetsListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setTargetsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreTargets = () => async (dispatch, getState) => {
    const page = selectors.getTargetsListState(getState()).page + 1;

    dispatch(storeActions.setTargetsListPage(page));
    await dispatch(getTargetsList());

    service.analytics.trackEvent('Load more targets', EVENT_CATEGORY.TARGETS);
};

export const targetsListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const { sortBy, sortOrder } = selectors.getTargetsListState(storeState);

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setTargetsListSortOrder(order));
    } else {
        dispatch(storeActions.setTargetsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setTargetsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setTargetsListPage(0));
    dispatch(storeActions.setTargetsListItems([]));
    dispatch(storeActions.setTargetsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('Targets list sort', EVENT_CATEGORY.TARGETS);
};

export const targetsListSearch = (text) => (dispatch, getState) => {
    const { searchBy } = selectors.getTargetsListState(getState());

    if (text !== searchBy) {
        dispatch(storeActions.setTargetsListPage(0));
        dispatch(storeActions.setTargetsListItems([]));
        dispatch(storeActions.setTargetsListSearchText(text));
        dispatch(storeActions.setTargetsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('Targets list search', EVENT_CATEGORY.TARGETS);
    }
};

export const goToEditTargetPage = (id, ownerId) => (dispatch) => {
    dispatch(push(formatString(TARGETS_ROUTES.EDIT, ownerId, id)));
};

export const getTargetData = (ownerId, targetId) => async (dispatch) => {
    dispatch(storeActions.setTargetDetailsLoadStatus(LOAD_STATUS.LOADING));
    dispatch(storeActions.setTargetManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details: ingestionApiDefinitions['TargetDetailResponseDto'] = await service.api.getTargetDetails(ownerId, targetId);
        dispatch(storeActions.setTargetDetails(details));

        const manifest = await service.api.getTargetManifest(details.targetType);
        dispatch(storeActions.setTargetManifest(manifest));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setTargetDetailsLoadStatus(LOAD_STATUS.LOADED));
        dispatch(storeActions.setTargetManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadTargetManifest = (targetType) => async (dispatch) => {
    dispatch(storeActions.setTargetManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const manifest = await service.api.getTargetManifest(targetType);
        dispatch(storeActions.setTargetManifest(normalizeManifest(manifest)));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setTargetManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

const validateTargetFormValues = (formValues) => {
    const requiredFields = [ 'name', 'targetType', 'ownerId' ];
    const errors = {};

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

const prepareTargetConfigurationContext = (manifest, configurationContext) => Object.entries(
    prepareFormValues(manifest, configurationContext)
).map(
    // it was agreed, that on initial release of targets functionality parameters couldn't be overridden,
    // so `overridable` flag is currently hard-coded
    ([ key, value ]) => ({ key, value, overridable: false })
);

export const addTarget = (formValues) => async (dispatch, getState) => {
    const errors = validateTargetFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setTargetFormOperationInProgress(true));

    try {
        const storeState = getState();
        const manifest = selectors.getTargetManifest(storeState);
        const { name, targetType, configurationContext, ownerId } = formValues;

        const details: ingestionApiDefinitions['TargetDetailResponseDto'] = await service.api.addTarget(
            ownerId,
            {
                name,
                targetType,
                configurationContext: prepareTargetConfigurationContext(manifest, configurationContext)
            }
        );

        service.analytics.trackEvent('Create target', EVENT_CATEGORY.TARGETS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === TARGETS_ROUTES.NEW) {
            dispatch(goToTargetDetailsPage(details.id, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setTargetFormOperationInProgress(false));
    }
};

export const editTarget = (targetId, formValues) => async (dispatch, getState) => {
    const errors = validateTargetFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setTargetFormOperationInProgress(true));

    try {
        const storeState = getState();
        const manifest = selectors.getTargetManifest(storeState);
        const { name, configurationContext, ownerId } = formValues;

        await service.api.editTarget(
            ownerId,
            targetId,
            {
                name,
                configurationContext: prepareTargetConfigurationContext(manifest, configurationContext)
            }
        );

        service.analytics.trackEvent('Edit target', EVENT_CATEGORY.TARGETS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(TARGETS_ROUTES.EDIT, ownerId, targetId)) {
            dispatch(goToTargetDetailsPage(targetId, ownerId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setTargetFormOperationInProgress(false));
    }
};

export const deleteTarget = (ownerId, targetId) => async (dispatch) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'targets.deleteDestination' }),
        messages: [ intl.formatMessage({ id: 'targets.deleteDestinationConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'targets.deleteDestination' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setTargetDetailsOperationInProgress(true));

        try {
            await service.api.deleteTarget(ownerId, targetId);

            service.analytics.trackEvent('Delete target', EVENT_CATEGORY.TARGETS);

            dispatch(goToTargetsListPage());
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setTargetDetailsOperationInProgress(false));
        }
    }
};
