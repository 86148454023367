import React, { Fragment, FunctionComponent, useState, isValidElement } from 'react';
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Type as AvroType } from 'avsc/lib/types';
import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import { AUTOMATION_ID } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import { readTextFile } from 'modules/common/utils';
import { AVRO_FIELD_TYPES, SCHEMA_MODE } from '../../constants';
import { getSourceFormMode, getSourceFormOperationInProgress, getSourceFormSchema } from '../../selectors';
import { setMode, setSourceFormSchema } from '../../actions';
import AvroSchemaField from '../AvroSchemaField';

import local from './local.module.scss';

interface SchemaModeSwitchProps {
    change: InjectedFormProps['change'];
    setSchemaFileName: (value: string) => void;
}

type SchemaUploadProps = WrappedFieldProps & {
    isOperationInProgress: boolean;
    change: InjectedFormProps['change'];
    schemaFileName: string;
    setSchemaFileName: (value: string) => void;
};

const INVALID_SCHEMA = 'INVALID_SCHEMA';

const validateSchema = (value) => {
    return value === INVALID_SCHEMA ? <FormattedMessage id='sources.invalidSchema' /> : undefined;
};

const SchemaModeSwitch: FunctionComponent<SchemaModeSwitchProps> = ({
    change,
    setSchemaFileName
}) => {
    const dispatch = useAppDispatch();

    const mode = useSelector(getSourceFormMode);
    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);

    const isInSimpleMode = mode === SCHEMA_MODE.SIMPLE;

    const toggleMode = () => {
        if (!isOperationInProgress) {
            change('primaryKeyColumns', []);
            change('overwriteByColumns', []);
            change('dateColumn', undefined);
            change('bigquery.clusterColumns', []);
            change('snowflake.clusteringKey', []);

            setSchemaFileName('');

            dispatch(setMode(mode === SCHEMA_MODE.SIMPLE ? SCHEMA_MODE.ADVANCED : SCHEMA_MODE.SIMPLE));
        }
    };

    return (
        <Fragment>
            <div className={local.modeLabel}>
                <FormattedMessage id='sources.schemaModeLabel' />
            </div>
            <div className={local.modeSwitchContainer}>
                <span className={local.modeSwitch}>
                    <span
                        id={AUTOMATION_ID.SOURCE_FORM_SAI_BASIC_BTN}
                        onClick={!isInSimpleMode ? toggleMode : undefined}
                        className={classnames({ [local.selectedModeOption]: isInSimpleMode }, local.modeOption)}
                    >
                        <FormattedMessage id='common.basic' />
                    </span>
                    <span
                        id={AUTOMATION_ID.SOURCE_FORM_SAI_ADVANCED_BTN}
                        onClick={isInSimpleMode ? toggleMode : undefined}
                        className={classnames({ [local.selectedModeOption]: !isInSimpleMode }, local.modeOption)}
                    >
                        <FormattedMessage id='common.advanced' />
                    </span>
                </span>
            </div>
        </Fragment>
    );
};

const SchemaUpload: FunctionComponent<SchemaUploadProps> = ({
    meta,
    isOperationInProgress,
    change,
    schemaFileName,
    setSchemaFileName
}) => {
    const dispatch = useAppDispatch();
    const { error } = meta;

    const onFileSelect = async (event) => {
        const { files } = event.target;

        if (files.length) {
            try {
                const file = files[0];

                setSchemaFileName(file.name);

                const data = await readTextFile(file);
                const parsedData = JSON.parse(data as string);
                const parsedType = AvroType.forSchema(parsedData);

                if (parsedType) {
                    const schema = {
                        fields: parsedData.fields.map(({ name, type }) => ({ name, type }))
                    };

                    dispatch(setSourceFormSchema(schema));
                    change('schema', data);
                } else {
                    change('schema', INVALID_SCHEMA);
                }
            } catch (err) {
                dispatch(setSourceFormSchema({}));
                change('schema', INVALID_SCHEMA);
            } finally {
                // clear inputs which depend on schema fields
                change('primaryKeyColumns', []);
                change('overwriteByColumns', []);
                change('dateColumn', undefined);
                change('bigquery.clusterColumns', []);
                change('snowflake.clusteringKey', []);
            }
        }
    };

    return (
        <Fragment>
            {
                schemaFileName &&
                <div>
                    <span className={local.schemaFileName}>
                        <FontAwesomeIcon icon={faFileAlt} />
                        <span>
                            {schemaFileName}
                        </span>
                    </span>
                </div>
            }
            <label
                className={classnames({ [local.uploadDisabled]: isOperationInProgress }, local.upload)}
                htmlFor='file-upload'
            >
                <FontAwesomeIcon icon={faUpload} className={local.uploadIcon} />
                <FormattedMessage id='sources.uploadSchema' />
            </label>
            <span className={local.avroFormat}>
                <FormattedMessage id='sources.avroFormat' />
            </span>
            {
                error &&
                <span className={local.schemaError}>
                    {
                        isValidElement(error) ? error : <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                    }
                </span>
            }
            <input
                id='file-upload'
                style={{ display: 'none' }}
                accept='.avsc, .json'
                type='file'
                onChange={onFileSelect}
                disabled={isOperationInProgress}
            />
        </Fragment>
    );
};

const SchemaFieldsError: FunctionComponent<WrappedFieldProps> = ({ meta }) => (
    <Fragment>
        {
            meta.error &&
            <div className='form-error-message'>
                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
            </div>
        }
    </Fragment>
);

const SchemaConstructor: FunctionComponent<{ change: InjectedFormProps['change'] }> = ({ change }) => {
    const dispatch = useAppDispatch();

    const schema = useSelector(getSourceFormSchema);
    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);

    const fields = schema.fields ? schema.fields.map((field, index) => (
        <AvroSchemaField
            key={index}
            change={change}
            fieldIndex={index}
            fieldData={field}
            disabled={isOperationInProgress}
        />
    )) : [];

    const addNewColumn = (event) => {
        if (!isOperationInProgress) {
            const newSchema = cloneDeep(schema);

            if (!newSchema.fields) {
                newSchema.fields = [];
            }

            newSchema.fields.push({
                name: '',
                type: AVRO_FIELD_TYPES.STRING,
                nullable: false
            });

            dispatch(setSourceFormSchema(newSchema));
        }

        // prevent form submission
        event.preventDefault();
    };

    return (
        <Fragment>
            {
                fields.length ?
                    <div>
                        <span className={local.tableLabel}>
                            <FormattedMessage id='sources.columnName' />
                        </span>
                        <span className={local.tableLabel}>
                            <FormattedMessage id='sources.dataType' />
                        </span>
                    </div> :
                    null
            }
            <div>
                {fields}
            </div>
            <div className={classnames(local.addColumnButtonContainer, 'plus-button', 'ls-button')}>
                <button id={AUTOMATION_ID.SOURCE_FORM_ADD_COLUMN_BTN} disabled={isOperationInProgress} className='btn-transparent' onClick={addNewColumn}>
                    <FontAwesomeIcon icon={faPlus} />
                    <FormattedMessage id='common.addColumn' />
                </button>
            </div>
            <Field name='schemaFields' component={SchemaFieldsError} />
        </Fragment>
    );
};

const SourceSchema: FunctionComponent<{ change: InjectedFormProps['change'] }> = ({ change }) => {
    const mode = useSelector(getSourceFormMode);
    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);

    const [schemaFileName, setSchemaFileName] = useState('');

    const isInSimpleMode = mode === SCHEMA_MODE.SIMPLE;

    return (
        <Fragment>
            <SchemaModeSwitch change={change} setSchemaFileName={setSchemaFileName} />
            <div className={local.schemaContainer}>
                {
                    isInSimpleMode ?
                        <SchemaConstructor change={change} /> :
                        <Field
                            name='schema'
                            component={SchemaUpload}
                            validate={validateSchema}
                            isOperationInProgress={isOperationInProgress}
                            change={change}
                            schemaFileName={schemaFileName}
                            setSchemaFileName={setSchemaFileName}
                        />
                }
            </div>
        </Fragment>
    );
};

export default SourceSchema;
