// targets list
export const APPEND_TARGETS_LIST_ITEMS = 'targets/APPEND_TARGETS_LIST_ITEMS';
export const SET_TARGETS_LIST_ITEMS = 'targets/SET_TARGETS_LIST_ITEMS';
export const SET_TARGETS_LIST_PAGE = 'targets/SET_TARGETS_LIST_PAGE';
export const SET_TARGETS_LIST_COUNT = 'targets/SET_TARGETS_LIST_COUNT';
export const SET_TARGETS_LIST_LOAD_STATUS = 'targets/SET_TARGETS_LIST_LOAD_STATUS';
export const SET_TARGETS_LIST_SORT_COLUMN = 'targets/SET_TARGETS_LIST_SORT_COLUMN';
export const SET_TARGETS_LIST_SORT_ORDER = 'targets/SET_TARGETS_LIST_SORT_ORDER';
export const SET_TARGETS_LIST_SEARCH_TEXT = 'targets/SET_TARGETS_LIST_SEARCH_TEXT';
export const RESET_TARGETS_LIST = 'targets/RESET_TARGETS_LIST';

// common
export const SET_TARGET_MANIFEST = 'targets/SET_TARGET_MANIFEST';
export const SET_TARGET_MANIFEST_LOAD_STATUS = 'targets/SET_TARGET_MANIFEST_LOAD_STATUS';
export const RESET_TARGET_MANIFEST = 'targets/RESET_TARGET_MANIFEST';

// target details
export const SET_TARGET_DETAILS = 'targets/SET_TARGET_DETAILS';
export const SET_TARGET_DETAILS_LOAD_STATUS = 'targets/SET_TARGET_DETAILS_LOAD_STATUS';
export const SET_TARGET_DETAILS_OPERATION_IN_PROGRESS = 'targets/SET_TARGET_DETAILS_OPERATION_IN_PROGRESS';
export const RESET_TARGET_DETAILS = 'targets/RESET_TARGET_DETAILS';

// target form
export const SET_TARGET_FORM_OPERATION_IN_PROGRESS = 'targets/SET_TARGET_FORM_OPERATION_IN_PROGRESS';
export const RESET_TARGET_FORM = 'targets/RESET_TARGET_FORM';
