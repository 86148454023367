import { connect } from 'react-redux';

import { withRouteParams } from 'modules/common/hoc';
import { makeOwnerDetailsLayoutProps } from '../../selectors';
import {
    getOwnerDetails,
    goToOwnersListPage,
    openOwnerMembershipDialog,
    ownerMembershipsListSearch,
    resetOwnerMembershipsTable,
    resetOwnerDetails,
    deleteOwner
} from '../../actions';
import OwnerDetailsLayout from './OwnerDetailsLayout';

const mapDispatchToProps = {
    loadData: getOwnerDetails,
    resetTable: resetOwnerMembershipsTable,
    resetData: resetOwnerDetails,
    onSearch: ownerMembershipsListSearch,
    openOwnerMembershipDialog,
    goToOwnersListPage,
    deleteOwner
};

export default withRouteParams(connect(makeOwnerDetailsLayoutProps, mapDispatchToProps)(OwnerDetailsLayout));
