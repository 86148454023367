import * as t from '../actionTypes';
import { FEEDS_LIST_PAGE_SIZE, FEED_DETAILS_VIEW, FEED_RUNS_LIST_PAGE_SIZE, FEED_EXECUTION_STATUS } from '../constants';
import { LOAD_STATUS, SORT_ORDER, TEMP_ID_PREFIX } from 'modules/common/constants';

const ACTION_HANDLERS = {
    [t.SET_FEEDS_LIST_ITEMS]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            items: action.payload
        }
    }),
    [t.APPEND_FEEDS_LIST_ITEMS]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            items: state.feedsList.items.concat(action.payload)
        }
    }),
    [t.SET_FEEDS_LIST_PAGE]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            page: action.payload
        }
    }),
    [t.SET_FEEDS_LIST_COUNT]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            totalCount: action.payload
        }
    }),
    [t.SET_FEEDS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            sortBy: action.payload
        }
    }),
    [t.SET_FEEDS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            sortOrder: action.payload
        }
    }),
    [t.SET_FEEDS_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            loadStatus: action.payload
        }
    }),
    [t.SET_FEEDS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        feedsList: {
            ...state.feedsList,
            searchBy: action.payload
        }
    }),
    [t.RESET_FEEDS_LIST]: (state) => ({
        ...state,
        feedsList: initialState.feedsList
    }),
    [t.SET_FEED_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            loadStatus: action.payload
        }
    }),
    [t.SET_FEED_DETAILS]: (state, action) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            data: action.payload
        }
    }),
    [t.SET_ADDITIONAL_FEED_DETAILS]: (state, action) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            additionalData: action.payload
        }
    }),
    [t.RESET_FEED_DETAILS]: (state) => ({
        ...state,
        feedDetails: initialState.feedDetails
    }),
    [t.SET_FEED_DETAILS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            feedOperationInProgress: action.payload
        }
    }),
    [t.SET_FEED_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            feedOperationInProgress: action.payload
        }
    }),
    [t.SET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA]: (state, action) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            feedOperationInProgressReporterData: action.payload
        }
    }),
    [t.UNSET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA]: (state) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            feedOperationInProgressReporterData: { ...FEED_OPERATION_IN_PROGRESS_INITIAL_STATE }
        }
    }),
    [t.SET_FEED_DETAILS_VIEW]: (state, action) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            view: action.payload
        }
    }),
    [t.OPEN_AD_HOC_RUN_DIALOG]: (state) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            isAdHocRunDialogOpen: true
        }
    }),
    [t.CLOSE_AD_HOC_RUN_DIALOG]: (state) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            isAdHocRunDialogOpen: false
        }
    }),
    [t.RESET_FEED_FORM]: (state) => ({
        ...state,
        feedForm: initialState.feedForm
    }),
    [t.SET_FEED_FORM_SOURCES]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            sources: {
                ...state.feedForm.sources,
                list: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_SOURCES_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            sources: {
                ...state.feedForm.sources,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_COLLECTOR_CREDENTIALS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            collectorCredentials: {
                ...state.feedForm.collectorCredentials,
                list: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            collectorCredentials: {
                ...state.feedForm.collectorCredentials,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_SOURCE_DETAILS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            sourceDetails: {
                ...state.feedForm.sourceDetails,
                object: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_SOURCE_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            sourceDetails: {
                ...state.feedForm.sourceDetails,
                loadStatus: action.payload
            }
        }
    }),
    [t.RESET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST]: (state) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            collectorConfigurationManifest: initialState.feedForm.collectorConfigurationManifest
        }
    }),
    [t.SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            collectorConfigurationManifest: {
                ...state.feedForm.collectorConfigurationManifest,
                manifest: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            collectorConfigurationManifest: {
                ...state.feedForm.collectorConfigurationManifest,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_FEED_COLLECTION_OFFSET_TEMPLATE]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            collectionOffsetTemplate: {
                ...state.common.collectionOffsetTemplate,
                list: action.payload
            }
        }
    }),
    [t.SET_FEED_COLLECTION_OFFSET_TEMPLATE_LOAD_STATUS]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            collectionOffsetTemplate: {
                ...state.common.collectionOffsetTemplate,
                loadStatus: action.payload
            }
        }
    }),
    [t.OPEN_FEED_DELETE_DIALOG]: (state) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            isFeedDeleteDialogOpen: true
        }
    }),
    [t.SET_FEED_DELETE_DIALOG_INSTANCES]: (state, action) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            dependentInstances: action.payload.sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1))
        }
    }),
    [t.CLOSE_FEED_DELETE_DIALOG]: (state) => ({
        ...state,
        feedDetails: {
            ...state.feedDetails,
            isFeedDeleteDialogOpen: false
        }
    }),
    [t.SET_FEED_RUNS_LIST_ITEMS]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            items: action.payload
        }
    }),
    [t.APPEND_FEED_RUNS_LIST_ITEMS]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            items: state.feedRuns.items.concat(action.payload)
        }
    }),
    [t.SET_FEED_RUNS_LIST_PAGE]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            page: action.payload
        }
    }),
    [t.SET_FEED_RUNS_LIST_COUNT]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            totalCount: action.payload
        }
    }),
    [t.SET_FEED_RUNS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            sortBy: action.payload
        }
    }),
    [t.SET_FEED_RUNS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            sortOrder: action.payload
        }
    }),
    [t.SET_FEED_RUNS_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            loadStatus: action.payload
        }
    }),
    [t.SET_FEED_RUNS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            searchBy: action.payload
        }
    }),
    [t.SET_FEED_RUNS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            feedRunsOperationInProgress: action.payload
        }
    }),
    [t.SET_SELECTED_FEED_RUNS]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            selectedItemsIds: action.payload
        }
    }),
    [t.RESET_FEED_RUNS_LIST]: (state) => ({
        ...state,
        feedRuns: initialState.feedRuns
    }),
    [t.SET_FEED_RUNS_FILTERS_STATUS]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            filters: {
                ...state.feedRuns.filters,
                statuses: action.payload
            }
        }
    }),
    [t.SET_FEED_RUNS_FILTERS_FETCH_START]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            filters: {
                ...state.feedRuns.filters,
                fetchStart: action.payload
            }
        }
    }),
    [t.SET_FEED_RUNS_FILTERS_FETCH_END]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            filters: {
                ...state.feedRuns.filters,
                fetchEnd: action.payload
            }
        }
    }),
    [t.SET_FEED_RUNS_FILTERS_RUN_DATE]: (state, action) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            filters: {
                ...state.feedRuns.filters,
                runDate: action.payload
            }
        }
    }),
    [t.RESET_FEED_RUNS_FILTERS]: (state) => ({
        ...state,
        feedRuns: {
            ...state.feedRuns,
            filters: initialState.feedRuns.filters
        }
    }),
    [t.ADD_FEED_SCHEDULE]: (state) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            schedules: [
                ...state.feedForm.schedules,
                { id: TEMP_ID_PREFIX + Date.now() }
            ]
        }
    }),
    [t.REMOVE_FEED_SCHEDULE]: (state, action) => {
        const schedules = [ ...state.feedForm.schedules ];
        schedules.splice(action.payload, 1);

        return {
            ...state,
            feedForm: {
                ...state.feedForm,
                schedules
            }
        };
    },
    [t.SET_FEED_SCHEDULES]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            schedules: action.payload
        }
    }),
    [t.CLEAR_FEED_SCHEDULES]: (state) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            schedules: []
        }
    }),
    [t.ADD_FEED_TARGET]: (state) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            feedTargets: [
                ...state.feedForm.feedTargets,
                { id: TEMP_ID_PREFIX + Date.now() }
            ]
        }
    }),
    [t.REMOVE_FEED_TARGET]: (state, action) => {
        const feedTargets = [ ...state.feedForm.feedTargets ];
        feedTargets.splice(action.payload, 1);

        return {
            ...state,
            feedForm: {
                ...state.feedForm,
                feedTargets
            }
        };
    },
    [t.SET_FEED_TARGETS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            feedTargets: action.payload
        }
    }),
    [t.SET_FEED_FORM_TARGETS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            targets: {
                ...state.feedForm.targets,
                list: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_TARGETS_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            targets: {
                ...state.feedForm.targets,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_TARGET_CREDENTIALS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            targetCredentials: {
                ...state.feedForm.targetCredentials,
                list: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_TARGET_CREDENTIALS_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            targetCredentials: {
                ...state.feedForm.targetCredentials,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_ADVERITY_AUTHORIZATIONS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            adverityAuthorizations: {
                ...state.feedForm.adverityAuthorizations,
                list: action.payload
            }
        }
    }),
    [t.SET_FEED_FORM_ADVERITY_AUTHORIZATIONS_LOAD_STATUS]: (state, action) => ({
        ...state,
        feedForm: {
            ...state.feedForm,
            adverityAuthorizations: {
                ...state.feedForm.adverityAuthorizations,
                loadStatus: action.payload
            }
        }
    })
};

const FEED_OPERATION_IN_PROGRESS_INITIAL_STATE = {
    reporter: null,
    prevStateData: {}
};

export const initialState = {
    feedsList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: FEEDS_LIST_PAGE_SIZE,
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED
    },
    feedDetails: {
        loadStatus: LOAD_STATUS.REQUIRED,
        data: {},
        additionalData: {},
        view: FEED_DETAILS_VIEW.MAIN,
        feedOperationInProgress: false,
        feedOperationInProgressReporterData: { ...FEED_OPERATION_IN_PROGRESS_INITIAL_STATE },
        isAdHocRunDialogOpen: false,
        isFeedDeleteDialogOpen: false,
        dependentInstances: []
    },
    feedForm: {
        sources: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        collectorCredentials: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        sourceDetails: {
            object: {},
            loadStatus: LOAD_STATUS.REQUIRED
        },
        collectorConfigurationManifest: {
            manifest: {
                panels: []
            },
            loadStatus: LOAD_STATUS.REQUIRED
        },
        targets: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        targetCredentials: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        adverityAuthorizations: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        feedOperationInProgress: false,
        schedules: [],
        feedTargets: []
    },
    feedRuns: {
        items: [],
        selectedItemsIds: [],
        totalCount: 0,
        page: 0,
        pageSize: FEED_RUNS_LIST_PAGE_SIZE,
        // TODO uncomment when sorting will be implemented on BE side
        // sortBy: 'name',
        sortBy: 'foobar',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED,
        feedRunsOperationInProgress: false,
        filters: {
            statuses: [FEED_EXECUTION_STATUS.FAILED, FEED_EXECUTION_STATUS.UNRECONCILED],
            fetchStart: null,
            fetchEnd: null,
            runDate: null
        }
    },
    common: {
        collectionOffsetTemplate: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        }
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
