import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { getUserProfile } from 'modules/auth/selectors';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { REDUCER_NAME, COLLECTOR_CREDENTIALS_LIST_SORT_ATTRIBUTES_MAP } from '../constants';

// collector credentials list
export const getCollectorCredentialsListItems = (state) => state[REDUCER_NAME].credentialsList.items;
export const getCollectorCredentialsListPage = (state) => state[REDUCER_NAME].credentialsList.page;
export const getCollectorCredentialsListPageSize = (state) => state[REDUCER_NAME].credentialsList.pageSize;
export const getCollectorCredentialsListTotalCount = (state) => state[REDUCER_NAME].credentialsList.totalCount;
export const getCollectorCredentialsListLoadStatus = (state) => state[REDUCER_NAME].credentialsList.loadStatus;
export const getCollectorCredentialsListSortColumn = (state) => state[REDUCER_NAME].credentialsList.sortBy;
export const getCollectorCredentialsListSortOrder = (state) => state[REDUCER_NAME].credentialsList.sortOrder;
export const getCollectorCredentialsListSearchText = (state) => state[REDUCER_NAME].credentialsList.searchBy;

// collector credential form
export const getCollectorCredentialFormCollectors = (state) => state[REDUCER_NAME].credentialForm.collectors.list;
export const getCollectorCredentialFormCollectorsLoadStatus = (state) => state[REDUCER_NAME].credentialForm.collectors.loadStatus;
export const getCollectorCredentialFormManifest = (state) => state[REDUCER_NAME].credentialForm.manifest.object;
export const getCollectorCredentialFormManifestLoadStatus = (state) => state[REDUCER_NAME].credentialForm.manifest.loadStatus;
export const getCollectorCredentialFormOperationInProgress = (state) => state[REDUCER_NAME].credentialForm.credentialOperationInProgress;

// collector credential details
export const getCollectorCredentialDetails = (state) => state[REDUCER_NAME].credentialDetails.data;
export const getCollectorCredentialDetailsLoadStatus = (state) => state[REDUCER_NAME].credentialDetails.loadStatus;

export const getCollectorCredentialsListSearchPayload = createSelector(
    getCollectorCredentialsListPage,
    getCollectorCredentialsListPageSize,
    getCollectorCredentialsListSortColumn,
    getCollectorCredentialsListSortOrder,
    getCollectorCredentialsListSearchText,
    (page, pageSize, sortBy, ordering, searchString) => ({
        orderBy: [
            {
                attribute: COLLECTOR_CREDENTIALS_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering
            }
        ],
        searchString,
        page,
        pageSize
    })
);

export const getCollectorCredentialsListLayoutProps = createSelector(
    getUserProfile,
    getCollectorCredentialsListItems,
    getCollectorCredentialsListLoadStatus,
    getCollectorCredentialsListTotalCount,
    (profile, items, status, totalCount) => ({
        items,
        status,
        totalCount,
        canView: profile.owners.some((owner) => owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.VIEW)),
        canManage: profile.owners.some((owner) => owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE))
    })
);

export const getCollectorCredentialsListProps = createSelector(
    getCollectorCredentialsListItems,
    getCollectorCredentialsListTotalCount,
    getCollectorCredentialsListLoadStatus,
    getCollectorCredentialsListSortOrder,
    getCollectorCredentialsListSortColumn,
    (items, totalCount, status, sortOrder, sortBy) => ({
        items,
        totalCount,
        status,
        sortOrder,
        sortBy
    })
);

export const getCollectorCredentialFormLayoutProps = createSelector(
    getCollectorCredentialFormManifestLoadStatus,
    getCollectorCredentialFormOperationInProgress,
    getCollectorCredentialDetails,
    getCollectorCredentialDetailsLoadStatus,
    getUserProfile,
    (manifestLoadStatus, isOperationInProgress, data, dataLoadStatus, profile) => ({
        isOperationInProgress,
        isDataLoading: [ dataLoadStatus, manifestLoadStatus ].includes(LOAD_STATUS.LOADING),
        owners: profile.owners.filter((owner) => owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE)),
        displayName: data.name
    })
);

const getSelectedCollectorId = (state) => {
    const selector = formValueSelector('collectorCredentialForm');

    return selector(state, 'collectorId');
};

export const getCollectorCredentialFormProps = createSelector(
    getUserProfile,
    getCollectorCredentialDetails,
    getCollectorCredentialDetailsLoadStatus,
    getCollectorCredentialFormCollectors,
    getCollectorCredentialFormCollectorsLoadStatus,
    getSelectedCollectorId,
    getCollectorCredentialFormManifest,
    getCollectorCredentialFormManifestLoadStatus,
    getCollectorCredentialFormOperationInProgress,
    (profile, data, dataLoadStatus, collectors, collectorsLoadStatus, collectorId, manifest, manifestLoadStatus, isOperationInProgress) => ({
        data,
        dataLoadStatus,
        collectors,
        collectorsLoadStatus,
        collectorId,
        manifest,
        manifestLoadStatus,
        isOperationInProgress,
        owners: profile.owners.filter((owner) => owner.permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE))
    })
);

export const getCollectorCredentialDetailsProps = createSelector(
    getUserProfile,
    getCollectorCredentialDetailsLoadStatus,
    getCollectorCredentialDetails,
    ({ owners }, loadStatus, data) => ({
        owners,
        loadStatus,
        data
    })
);
