import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import { AUTOMATION_ID, EVENT_CATEGORY } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { Owner, RouteComponentProps } from 'modules/common/interfaces';
import CollectorCredentialForm from '../CollectorCredentialForm';

import local from './local.module.scss';

interface CollectorCredentialFormLayoutProps {
    isEditMode: boolean;
    isOperationInProgress: boolean;
    goToCollectorCredentialsListPage: Function;
    goToCollectorCredentialDetailsPage: Function;
    addCollectorCredential: Function;
    editCollectorCredential: Function;
    clearSubmitErrors: Function;
    owners: Owner[];
    displayName: string;
    isDataLoading: boolean;
}

interface RouteMatchParams {
    ownerId: string;
    credentialId: string;
}

class CollectorCredentialFormLayout extends Component<CollectorCredentialFormLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public render() {
        if (!this.checkAccess()) {
            return (
                <ErrorPage error='403' />
            );
        }

        const { isEditMode, isOperationInProgress, isDataLoading, match } = this.props;

        return (
            <Fragment>
                {this.renderBreadcrumbs()}
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            <FormattedMessage id={isEditMode ? 'credentials.editCollectorCredential' : 'credentials.newCollectorCredential'} />
                        </div>
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.COLLECTOR_CREDENTIAL_FORM_CANCEL_BTN}
                                    disabled={isOperationInProgress}
                                    className='btn-flat'
                                    onClick={this.onCancelButtonClick}
                                >
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <FormSubmitButton
                                id={AUTOMATION_ID.COLLECTOR_CREDENTIAL_FORM_SAVE_BTN}
                                form='collectorCredentialForm'
                                width={130}
                                disabled={isOperationInProgress || isDataLoading}
                                text={<FormattedMessage id='common.saveChanges' />}
                                isSubmitting={isOperationInProgress}
                            />
                        </div>
                    </div>
                    <CollectorCredentialForm onSubmit={this.onFormSubmit} isEditMode={isEditMode} match={match} />
                </div>
            </Fragment>
        );
    }

    private checkAccess = () => {
        const { isEditMode, owners, match: { params: { ownerId } } } = this.props;

        return isEditMode ?
            Boolean(owners.find(({ id }) => id === ownerId)) :
            Boolean(owners.length);
    }

    private onFormSubmit = (values) => {
        const {
            isEditMode,
            editCollectorCredential,
            addCollectorCredential,
            clearSubmitErrors,
            match: {
                params: {
                    credentialId
                }
            }
        } = this.props;

        clearSubmitErrors('collectorCredentialForm');

        return isEditMode ?
            editCollectorCredential(credentialId, values) :
            addCollectorCredential(values);
    }

    private renderBreadcrumbs = () => {
        const { goToCollectorCredentialsListPage, isEditMode, displayName = '' } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_CREDENTIALS_BREADCRUMB,
                        label: (<FormattedMessage id='credentials.allCredentials' />),
                        onClick: () => { goToCollectorCredentialsListPage(); }
                    },
                    {
                        label: isEditMode ? displayName : (<FormattedMessage id='credentials.newCollectorCredential' />)
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private onCancelButtonClick = () => {
        const {
            isEditMode,
            goToCollectorCredentialDetailsPage,
            goToCollectorCredentialsListPage,
            match
        } = this.props;

        if (isEditMode) {
            const { credentialId, ownerId } = match.params;
            goToCollectorCredentialDetailsPage(credentialId, ownerId);

            service.analytics.trackEvent('Cancel credential edit', EVENT_CATEGORY.CREDENTIALS);
        } else {
            goToCollectorCredentialsListPage();

            service.analytics.trackEvent('Cancel credential creation', EVENT_CATEGORY.CREDENTIALS);
        }
    }
}

export default CollectorCredentialFormLayout;
