import React, { FC } from 'react';
import { useSnackbar, VariantType } from 'notistack';

interface InnerSnackbarUtilsConfiguratorProps {
    setUseSnackbarRef: (showSnackbar) => void;
}

const InnerSnackbarUtilsConfigurator: FC<InnerSnackbarUtilsConfiguratorProps> = ({ setUseSnackbarRef }) => {
    setUseSnackbarRef(useSnackbar());

    return null;
};

let useSnackbarRef;
const setRef = (useSnackbarRefProp) => {
    useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setRef} />
);

const __TEST__ = process.env.NODE_ENV === 'test';

const toast = (msg: string, variant: VariantType = 'default') => {
    // don't attempt to show snackbar when tests are running
    if (!__TEST__) {
        useSnackbarRef.enqueueSnackbar(msg, { variant });
    }
};

export const showSuccessSnackbar = (msg: string) => {
    toast(msg, 'success');
};

export const showWarningSnackbar = (msg: string) => {
    toast(msg, 'warning');
};

export const showInfoSnackbar = (msg: string) => {
    toast(msg, 'info');
};

export const showErrorSnackbar = (msg: string) => {
    toast(msg, 'error');
};
