import React, { Fragment, FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { UserProfile } from 'modules/common/interfaces';
import { AUTOMATION_ID, PERMISSIONS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import SearchInput from 'modules/common/components/SearchInput';
import DatasetsList from '../DatasetsList';
import TablesList from '../TablesList';
import TableSchema from '../TableSchema';
import { performSearch, setIsMasterProjectAndReload } from '../../actions';
import { getCatalogLayoutProps } from '../../selectors';

import local from './local.module.scss';

interface SwitchItemProps {
    onClick: MouseEventHandler;
    isSelected: boolean;
    isDisabled: boolean;
}

const DATA_CATALOG_SEARCH_REFERENCE_URL = 'https://cloud.google.com/data-catalog/docs/how-to/search-reference';

const SwitchItem: FunctionComponent<PropsWithChildren<SwitchItemProps>> = ({
    onClick,
    isSelected,
    isDisabled,
    children
}) => (
    <span
        onClick={(...args) => { !isDisabled && !isSelected && onClick(...args); }}
        className={classnames({ [local.selectedOption]: isSelected, [local.disabledOption]: isDisabled }, local.option)}
    >
        {children}
    </span>
);

const CatalogLayout: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { isDataLoading, isMasterProject } = useSelector(getCatalogLayoutProps);

    const { clients = [] }: UserProfile = useSelector(getUserProfile);
    const clientId = useSelector(getSelectedClientId);
    const client = clients.find((item) => item.id === clientId);

    const isClientProjectAccessAllowed = client && client.permissions.includes(PERMISSIONS.CATALOGUE.VIEW);

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='common.catalog' />
            </div>
            <div className={classnames(local.controlsContainer, 'container-row')}>
                <div className='container-row'>
                    <SearchInput
                        inputId={AUTOMATION_ID.DATA_CATALOG_SEARCH}
                        onSearch={(query) => { dispatch(performSearch(query)); }}
                        disabled={isDataLoading}
                    />
                    <IconWithTooltip className={local.searchTooltip}>
                        <FormattedMessage id='dataCatalog.searchTooltip' />:
                        <br />
                        <a
                            href={DATA_CATALOG_SEARCH_REFERENCE_URL}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <u>{DATA_CATALOG_SEARCH_REFERENCE_URL}</u>
                        </a>
                    </IconWithTooltip>
                </div>
                <div className={classnames(local.switch, 'container-row')}>
                    <SwitchItem
                        onClick={() => { dispatch(setIsMasterProjectAndReload(true)); }}
                        isSelected={isMasterProject}
                        isDisabled={isDataLoading}
                    >
                        <FormattedMessage id='dataCatalog.masterProject' />
                    </SwitchItem>
                    <SwitchItem
                        onClick={() => { dispatch(setIsMasterProjectAndReload(false)); }}
                        isSelected={!isMasterProject}
                        isDisabled={isDataLoading || !isClientProjectAccessAllowed}
                    >
                        <FormattedMessage id='dataCatalog.clientProject' />
                    </SwitchItem>
                </div>
            </div>
            <div className='container-row'>
                <div className={local.datasetsColumn}>
                    <DatasetsList />
                </div>
                <div className={local.tablesColumn}>
                    <TablesList />
                </div>
                <div className={local.schemaColumn}>
                    <TableSchema />
                </div>
            </div>
        </Fragment>
    );
};

export default CatalogLayout;
