import React, { Component } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';

import { intl } from 'modules/i18n';
import { AVRO_FIELD_TYPES } from 'modules/sources/constants';
import SelectInput from 'modules/common/components/SelectInput';
import Checkbox from 'modules/common/components/Checkbox';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';

import local from './local.module.scss';

const FIELD_TYPE_ITEMS = Object.values(AVRO_FIELD_TYPES).map((type) => ({
    id: type,
    name: intl.formatMessage({ id: `sources.avroType.${type}` })
}));

const NAME_INPUT_DELAY = 500;

interface FieldData {
    name: string;
    type: typeof AVRO_FIELD_TYPES[keyof typeof AVRO_FIELD_TYPES];
    nullable: boolean;
}

interface AvroSchemaFieldProps {
    schema: any;
    setSchema: Function;
    change: InjectedFormProps['change'];
    fieldIndex: number;
    fieldData: FieldData;
    formValues: { [key: string]: any };
    disabled: boolean;
    removeSubmitError: Function;
}

interface AvroSchemaFieldState {
    name: string;
    error?: string;
}

class AvroSchemaField extends Component<AvroSchemaFieldProps, AvroSchemaFieldState> {
    private static nameRegExp = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

    private timeout;

    public constructor(props) {
        super(props);

        this.state = {
            name: props.fieldData.name,
            error: undefined
        };
    }

    public shouldComponentUpdate(nextProps, nextState) {
        const { fieldData, disabled } = this.props;
        const { name, error } = this.state;
        const { fieldData: nextFieldData, disabled: nextDisabled } = nextProps;
        const { name: nextName, error: nextError } = nextState;

        return (fieldData !== nextFieldData) ||
            (name !== nextName) ||
            (error !== nextError) ||
            (disabled !== nextDisabled);
    }

    public componentDidUpdate(prevProps) {
        const { fieldData: { name }, schema, fieldIndex } = this.props;
        const { name: prevName } = prevProps.fieldData;

        if (prevName !== name) {
            this.setState({ name });
        }

        const areDuplicatesExist = schema.fields.some(({ name: otherName }, index) =>
            otherName && otherName.toLowerCase() === name.toLowerCase() && fieldIndex !== index);
        const duplicatesErrorCode = 'custom.DuplicatedSchemaField';

        if (areDuplicatesExist) {
            this.setState({ error: duplicatesErrorCode });
        } else if (this.state.error === duplicatesErrorCode) {
            this.setState({ error: undefined });
        }
    }

    public render() {
        const { fieldData: { type, nullable }, disabled, fieldIndex } = this.props;
        const { name, error } = this.state;

        return (
            <div className={classnames(local.fieldContainer, 'container-column')}>
                <div className={classnames(local.field, 'container-row')}>
                    <div className={classnames({ 'error-input': error }, 'ls-input')}>
                        <input className={local.fieldNameInput} onChange={this.onNameChange} value={name} disabled={disabled} />
                    </div>
                    <div className={local.fieldTypeSelectContainer}>
                        <SelectInput
                            items={FIELD_TYPE_ITEMS}
                            width={200}
                            inputProperties={{ onChange: this.onTypeChange, value: type }}
                            disabled={disabled}
                        />
                    </div>
                    <div className={local.fieldNullableContainer}>
                        <Checkbox
                            inputProperties={{
                                value: nullable,
                                onChange: this.onNullableChange
                            }}
                            label={<FormattedMessage id='common.nullable' />}
                            disabled={disabled}
                        />
                        <IconWithTooltip className={local.fieldNullableTooltip}>
                            <FormattedMessage id='common.nullableTip' />
                        </IconWithTooltip>
                    </div>
                    <div className={local.fieldDeleteIconContainer} onClick={this.onFieldDelete}>
                        <FontAwesomeIcon icon={faTrashAlt} className={local.fieldDeleteIcon} />
                    </div>
                </div>
                {
                    error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                    </div>
                }
                <Field name={`schemaFields_${fieldIndex}`} component={this.renderFieldError} />
            </div>
        );
    }

    private renderFieldError = ({ meta }) => {
        this.setState({ error: meta.error });

        return null;
    }

    private onNameChange = (event) => {
        const { value } = event.target;

        if (value && !AvroSchemaField.nameRegExp.test(value)) {
            return;
        }

        this.setState({ name: value });

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.changeName(value);
        }, NAME_INPUT_DELAY);
    }

    private changeName = (value) => {
        const {
            schema,
            setSchema,
            fieldIndex,
            change,
            removeSubmitError,
            formValues: {
                primaryKeyColumns,
                dateColumn,
                overwriteByColumns,
                bigquery,
                snowflake
            }
        } = this.props;

        const clusterColumns = bigquery?.clusterColumns;
        const clusteringKey = snowflake?.clusteringKey;

        const field = schema.fields[fieldIndex];
        const newSchema = cloneDeep(schema);

        const oldName = field.name;
        const areDuplicatesExist = schema.fields.some(({ name }, index) => name && name === oldName && fieldIndex !== index);

        if (!areDuplicatesExist) {
            const primaryIndex = primaryKeyColumns ? primaryKeyColumns.indexOf(oldName) : -1;
            const bqClusterIndex = clusterColumns ? clusterColumns.indexOf(oldName) : -1;
            const sfClusterIndex = clusteringKey ? clusteringKey.indexOf(oldName) : -1;
            const overwriteIndex = overwriteByColumns ? overwriteByColumns.indexOf(oldName) : -1;

            if (value) {
                if (primaryIndex !== -1) {
                    primaryKeyColumns[primaryIndex] = value;
                    change('primaryKeyColumns', [ ...primaryKeyColumns ]);
                }

                if (bqClusterIndex !== -1) {
                    clusterColumns[bqClusterIndex] = value;
                    change('bigquery.clusterColumns', [ ...clusterColumns ]);
                }

                if (sfClusterIndex !== -1) {
                    clusteringKey[sfClusterIndex] = value;
                    change('snowflake.clusteringKey', [ ...clusteringKey ]);
                }

                if (overwriteIndex !== -1) {
                    overwriteByColumns[overwriteIndex] = value;
                    change('overwriteByColumns', [ ...overwriteByColumns ]);
                }
            } else {
                if (primaryIndex !== -1) {
                    primaryKeyColumns.splice(primaryIndex, 1);
                    change('primaryKeyColumns', [ ...primaryKeyColumns ]);
                }

                if (bqClusterIndex !== -1) {
                    clusterColumns.splice(bqClusterIndex, 1);
                    change('bigquery.clusterColumns', [ ...clusterColumns ]);
                }

                if (sfClusterIndex !== -1) {
                    clusteringKey.splice(sfClusterIndex, 1);
                    change('snowflake.clusteringKey', [ ...clusteringKey ]);
                }

                if (overwriteIndex !== -1) {
                    overwriteByColumns.splice(overwriteIndex, 1);
                    change('overwriteByColumns', [ ...overwriteByColumns ]);
                }
            }

            if (dateColumn === oldName) {
                change('dateColumn', value || undefined);
            }
        }

        removeSubmitError({
            form: 'sourceForm',
            field: `schemaFields_${fieldIndex}`
        });

        newSchema.fields[fieldIndex] = { ...field, name: value };
        setSchema(newSchema);
    }

    private onTypeChange = (value) => {
        const { schema, setSchema, fieldIndex } = this.props;
        const field = schema.fields[fieldIndex];
        const newSchema = cloneDeep(schema);

        newSchema.fields[fieldIndex] = { ...field, type: value };
        setSchema(newSchema);
    }

    private onNullableChange = (event, value) => {
        const { schema, setSchema, fieldIndex } = this.props;
        const field = schema.fields[fieldIndex];
        const newSchema = cloneDeep(schema);

        newSchema.fields[fieldIndex] = { ...field, nullable: value };
        setSchema(newSchema);
    }

    private onFieldDelete = () => {
        const {
            disabled,
            schema,
            setSchema,
            fieldIndex,
            change,
            removeSubmitError,
            formValues: {
                primaryKeyColumns,
                dateColumn,
                overwriteByColumns,
                bigquery,
                snowflake
            }
        } = this.props;

        const clusterColumns = bigquery?.clusterColumns;
        const clusteringKey = snowflake?.clusteringKey;

        if (!disabled) {
            const newSchema = cloneDeep(schema);
            const name = newSchema.fields[fieldIndex].name;

            const primaryIndex = primaryKeyColumns ? primaryKeyColumns.indexOf(name) : -1;
            const bqClusterIndex = clusterColumns ? clusterColumns.indexOf(name) : -1;
            const sfClusterIndex = clusteringKey ? clusteringKey.indexOf(name) : -1;
            const overwriteIndex = overwriteByColumns ? overwriteByColumns.indexOf(name) : -1;

            if (primaryIndex !== -1) {
                primaryKeyColumns.splice(primaryIndex, 1);
                change('primaryKeyColumns', [ ...primaryKeyColumns ]);
            }

            if (bqClusterIndex !== -1) {
                clusterColumns.splice(bqClusterIndex, 1);
                change('bigquery.clusterColumns', [ ...clusterColumns ]);
            }

            if (sfClusterIndex !== -1) {
                clusteringKey.splice(sfClusterIndex, 1);
                change('snowflake.clusteringKey', [ ...clusteringKey ]);
            }

            if (overwriteIndex !== -1) {
                overwriteByColumns.splice(overwriteIndex, 1);
                change('overwriteByColumns', [ ...overwriteByColumns ]);
            }

            if (dateColumn === name) {
                change('dateColumn', undefined);
            }

            newSchema.fields.splice(fieldIndex, 1);
            setSchema(newSchema);

            newSchema.fields.forEach((field, index) => {
                removeSubmitError({
                    form: 'sourceForm',
                    field: `schemaFields_${index}`
                });
            });
        }
    }
}

export default AvroSchemaField;
