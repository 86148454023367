import * as t from '../actionTypes';
import { INSTANCE_RUN_MODE, INSTANCES_LIST_PAGE_SIZE, TRIGGER_TYPE } from 'modules/transformationInstances/constants';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';

const ACTION_HANDLERS = {
    [t.SET_INSTANCES_LIST_ITEMS]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            items: action.payload
        }
    }),
    [t.APPEND_INSTANCES_LIST_ITEMS]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            items: state.instancesList.items.concat(action.payload)
        }
    }),
    [t.SET_INSTANCES_LIST_PAGE]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            page: action.payload
        }
    }),
    [t.SET_INSTANCES_LIST_COUNT]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            totalCount: action.payload
        }
    }),
    [t.SET_INSTANCES_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            sortBy: action.payload
        }
    }),
    [t.SET_INSTANCES_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            sortOrder: action.payload
        }
    }),
    [t.SET_INSTANCES_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            loadStatus: action.payload
        }
    }),
    [t.SET_INSTANCES_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            searchBy: action.payload
        }
    }),
    [t.SET_INSTANCES_LIST_SHOW_ARCHIVED]: (state, action) => ({
        ...state,
        instancesList: {
            ...state.instancesList,
            showArchived: action.payload
        }
    }),
    [t.RESET_INSTANCES_LIST]: (state) => ({
        ...state,
        instancesList: {
            ...initialState.instancesList,
            showArchived: state.instancesList.showArchived
        }
    }),
    [t.SET_INSTANCE_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            loadStatus: action.payload
        }
    }),
    [t.SET_INSTANCE_DETAILS]: (state, action) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            data: action.payload
        }
    }),
    [t.RESET_INSTANCE_DETAILS]: (state) => ({
        ...state,
        instanceDetails: initialState.instanceDetails
    }),
    [t.SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            instanceOperationInProgress: action.payload
        }
    }),
    [t.SET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA]: (state, action) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            instanceOperationInProgressReporterData: action.payload
        }
    }),
    [t.UNSET_INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA]: (state) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            instanceOperationInProgressReporterData: { ...INSTANCE_OPERATION_IN_PROGRESS_INITIAL_STATE }
        }
    }),
    [t.SET_INSTANCE_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            instanceOperationInProgress: action.payload
        }
    }),
    [t.RESET_INSTANCE_FORM]: (state) => ({
        ...state,
        instanceForm: initialState.instanceForm
    }),
    [t.ADD_TRIGGER]: (state) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            triggers: [ ...state.instanceForm.triggers, { type: null, id: null } ]
        }
    }),
    [t.REMOVE_TRIGGER]: (state, action) => {
        const triggers = [ ...state.instanceForm.triggers ];
        triggers.splice(action.payload, 1);

        return {
            ...state,
            instanceForm: {
                ...state.instanceForm,
                triggers
            }
        };
    },
    [t.UPDATE_TRIGGER]: (state, action) => {
        const triggers = [ ...state.instanceForm.triggers ];
        triggers[action.payload.index] = action.payload.data;

        return {
            ...state,
            instanceForm: {
                ...state.instanceForm,
                triggers
            }
        };
    },
    [t.INIT_TRIGGERS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            triggers: action.payload.map(({ upstreamFeedId, upstreamInstanceId, airflowConfigurationOverrides }) => ({
                id: upstreamInstanceId || upstreamFeedId,
                type: upstreamInstanceId ? TRIGGER_TYPE.UPSTREAM_INSTANCE : TRIGGER_TYPE.UPSTREAM_FEED,
                overrideConfiguration: Boolean(airflowConfigurationOverrides?.length),
                savedConfiguration: airflowConfigurationOverrides?.length ? airflowConfigurationOverrides.reduce((acc, { key, value }) => {
                    acc[key] = value;

                    return acc;
                }, {}) : null
            }))
        }
    }),
    [t.SET_INSTANCE_FORM_TRIGGERS_INSTANCES]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            triggersData: {
                ...state.instanceForm.triggersData,
                instances: {
                    ...state.instanceForm.triggersData.instances,
                    list: action.payload
                }
            }
        }
    }),
    [t.SET_INSTANCE_FORM_TRIGGERS_INSTANCES_LOAD_STATUS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            triggersData: {
                ...state.instanceForm.triggersData,
                instances: {
                    ...state.instanceForm.triggersData.instances,
                    loadStatus: action.payload
                }
            }
        }
    }),
    [t.SET_INSTANCE_FORM_TRIGGERS_FEEDS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            triggersData: {
                ...state.instanceForm.triggersData,
                feeds: {
                    ...state.instanceForm.triggersData.feeds,
                    list: action.payload
                }
            }
        }
    }),
    [t.SET_INSTANCE_FORM_TRIGGERS_FEEDS_LOAD_STATUS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            triggersData: {
                ...state.instanceForm.triggersData,
                feeds: {
                    ...state.instanceForm.triggersData.feeds,
                    loadStatus: action.payload
                }
            }
        }
    }),
    [t.SET_EXPORT_TARGETS_DATA]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            exportTargetsData: {
                ...state.common.exportTargetsData,
                list: action.payload
            }
        }
    }),
    [t.SET_EXPORT_TARGETS_DATA_LOAD_STATUS]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            exportTargetsData: {
                ...state.common.exportTargetsData,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_INSTANCE_FORM_PIPELINES]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            pipelines: {
                ...state.instanceForm.pipelines,
                list: action.payload
            }
        }
    }),
    [t.SET_INSTANCE_FORM_PIPELINES_LOAD_STATUS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            pipelines: {
                ...state.instanceForm.pipelines,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_INSTANCE_FORM_CONFIGURATION_TEMPLATE]: (state, action) => {
        const configurationTemplate = action.payload;
        const filteredPanels = configurationTemplate?.panels?.length ?
            configurationTemplate.panels
                .map((panel) => {
                    const fields = panel.fields.filter((field) => field.editable);

                    return fields.length ? { ...panel, fields } : null;
                })
                .filter(Boolean) :
            [];

        return {
            ...state,
            instanceForm: {
                ...state.instanceForm,
                pipelineDetails: {
                    ...state.instanceForm.pipelineDetails,
                    configurationTemplate,
                    filteredConfigurationTemplate: filteredPanels.length ? { panels: filteredPanels } : null
                }
            }
        };
    },
    [t.SET_INSTANCE_FORM_FLAGS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            pipelineDetails: {
                ...state.instanceForm.pipelineDetails,
                flags: action.payload
            }
        }
    }),
    [t.SET_INSTANCE_FORM_PIPELINE_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        instanceForm: {
            ...state.instanceForm,
            pipelineDetails: {
                ...state.instanceForm.pipelineDetails,
                loadStatus: action.payload
            }
        }
    }),
    [t.OPEN_AD_HOC_RUN_DIALOG]: (state) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            isAdHocRunDialogOpen: true
        }
    }),
    [t.CLOSE_AD_HOC_RUN_DIALOG]: (state) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            isAdHocRunDialogOpen: false
        }
    }),
    [t.SET_RUN_MODE]: (state, action) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            runMode: action.payload
        }
    }),
    [t.OPEN_INSTANCE_DELETE_DIALOG]: (state) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            isInstanceDeleteDialogOpen: true
        }
    }),
    [t.SET_INSTANCE_DELETE_DIALOG_INSTANCES]: (state, action) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            dependentInstances: action.payload.sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1))
        }
    }),
    [t.CLOSE_INSTANCE_DELETE_DIALOG]: (state) => ({
        ...state,
        instanceDetails: {
            ...state.instanceDetails,
            isInstanceDeleteDialogOpen: false
        }
    })
};

const INSTANCE_OPERATION_IN_PROGRESS_INITIAL_STATE = {
    reporter: null,
    prevStateData: {}
};

export const initialState = {
    instancesList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: INSTANCES_LIST_PAGE_SIZE,
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED,
        showArchived: false
    },
    instanceDetails: {
        loadStatus: LOAD_STATUS.REQUIRED,
        data: {},
        instanceOperationInProgress: false,
        instanceOperationInProgressReporterData: { ...INSTANCE_OPERATION_IN_PROGRESS_INITIAL_STATE },
        isAdHocRunDialogOpen: false,
        runMode: INSTANCE_RUN_MODE.NORMAL,
        isInstanceDeleteDialogOpen: false,
        dependentInstances: []
    },
    instanceForm: {
        pipelines: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        pipelineDetails: {
            flags: {},
            configurationTemplate: {},
            filteredConfigurationTemplate: null,
            loadStatus: LOAD_STATUS.REQUIRED
        },
        triggers: [],
        triggersData: {
            feeds: {
                list: [],
                loadStatus: LOAD_STATUS.REQUIRED
            },
            instances: {
                list: [],
                loadStatus: LOAD_STATUS.REQUIRED
            }
        },
        instanceOperationInProgress: false
    },
    common: {
        exportTargetsData: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        }
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
