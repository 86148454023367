import React, { Component, Fragment } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';
import cronstrue from 'cronstrue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import { FEED_DETAILS_VIEW, FEED_VERSION_STATE, FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER, FEED_STATE } from '../../constants';
import { ingestionApiDefinitions } from 'modules/service/types';
import { AUTOMATION_ID, EVENT_TYPE_GROUP, LOAD_STATUS, PERMISSIONS, SERVICE } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import Switch from 'modules/common/components/Switch';
import Breadcrumbs, { BreadcrumbItemType } from 'modules/common/components/Breadcrumbs';
import ButtonWithTooltip from 'modules/common/components/ButtonWithTooltip';
import SubscriptionsMenu from 'modules/common/components/SubscriptionsMenu';
import { formatString } from 'modules/common/utils';
import { Client, LoadStatus, Owner, RouteComponentProps } from 'modules/common/interfaces';
import { locale } from 'modules/i18n';
import { COLLECTOR_CREDENTIALS_ROUTES } from 'modules/collectorCredentials/constants';
import { SOURCE_TRANSIENT_STATE, SOURCES_ROUTES } from 'modules/sources/constants';
import { TARGETS_ROUTES } from 'modules/targets/constants';
import { TARGET_CREDENTIALS_ROUTES } from 'modules/targetCredentials/constants';
import FeedAdHocRunDialog from '../FeedAdHocRunDialog';
import FeedDeleteDialog from '../FeedDeleteDialog';

import local from './local.module.scss';

const MESSAGE = Object.freeze({
    SCHEDULE_ACTION_INCORRECT_FEED_STATE: 'feeds.scheduleActionIncorrectFeedState'
});

type Schedule = ingestionApiDefinitions['FeedScheduleDetailsDto'] & {
    collectionOffsetTemplateDescription?: string;
};

type FeedVersionDetails = Omit<ingestionApiDefinitions['FeedVersionDetailResponseDto'], 'schedules'> & {
    schedules?: Schedule[];
};

interface FeedDetailsLayoutProps {
    clients: Client[];
    owners: Owner[];
    data: FeedVersionDetails;
    additionalData: FeedVersionDetails;
    resetData: Function;
    loadData: Function;
    view: typeof FEED_DETAILS_VIEW[keyof typeof FEED_DETAILS_VIEW];
    isOperationInProgress: boolean;
    operationInProgressReporterData: { reporter: string | null; prevStateData: object };
    loadStatus: LoadStatus;
    goToFeedsListPage: Function;
    setView: Function;
    intl: IntlShape;
    togglePauseFeedSchedule: Function;
    toggleEnableFeed: Function;
    goToEditFeedPage: Function;
    goToPendingFeedPage: Function;
    openAdHocRunDialog: Function;
    showFeedDeleteDialog: Function;
}

interface RouteMatchParams {
    clientId: string;
    feedId: string;
}

class FeedDetailsLayout extends Component<FeedDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public componentDidMount() {
        const { loadData, resetData, match: { params: { clientId, feedId } } } = this.props;
        const { canView } = this.checkAccess();

        resetData();

        if (canView) {
            loadData(clientId, feedId);
        }
    }

    public componentDidUpdate(prevProps) {
        const { match: { params: { clientId: prevClientId, feedId: prevFeedId } } } = prevProps;
        const { loadData, resetData, match: { params: { clientId, feedId } } } = this.props;

        // in case when client or feed ID in page URL is changed by user, component is not re-mounted, so need to check access again
        if ((clientId !== prevClientId) || (feedId !== prevFeedId)) {
            const { canView } = this.checkAccess();

            resetData();

            if (canView) {
                loadData(clientId, feedId);
            }
        }
    }

    public render() {
        const { canView, canManage } = this.checkAccess();

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const {
            view,
            data,
            additionalData,
            loadStatus,
            isOperationInProgress,
            goToEditFeedPage,
            goToPendingFeedPage,
            showFeedDeleteDialog,
            match: { params: { clientId, feedId } }
        } = this.props;

        const {
            name,
            suffix,
            state,
            feedState,
            usedByOdpReportingApi,
            sourceVersionInWorkflowState
        } = view === FEED_DETAILS_VIEW.MAIN ? data : additionalData;

        const isLoaded = loadStatus === LOAD_STATUS.LOADED;

        const showEditButton =
            view === FEED_DETAILS_VIEW.MAIN ||
            (view === FEED_DETAILS_VIEW.ADDITIONAL && sourceVersionInWorkflowState === SOURCE_TRANSIENT_STATE.PENDING);

        const showUpgradeButton =
            isLoaded &&
            view === FEED_DETAILS_VIEW.MAIN &&
            state === FEED_VERSION_STATE.VALID &&
            sourceVersionInWorkflowState === SOURCE_TRANSIENT_STATE.PENDING &&
            !Object.keys(additionalData).length;

        const showDeleteButton = isLoaded && !usedByOdpReportingApi;

        return (
            <div>
                <FeedAdHocRunDialog />
                {
                    this.renderBreadcrumbs()
                }
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            {
                                isLoaded ?
                                    <Fragment>
                                        {
                                            usedByOdpReportingApi &&
                                            <div className={local.usedInReportLabel}>
                                                <FormattedMessage id='common.usedInReport' />
                                            </div>
                                        }
                                        {name}_{suffix}
                                    </Fragment> :
                                    <SkeletonItem width='320px' height='24px' />
                            }
                        </div>
                        <div className={classnames(local.controlsContainer, 'container-row')}>
                            <SubscriptionsMenu
                                referenceEntityId={feedId}
                                serviceName={SERVICE.INGESTION}
                                eventTypeGroup={EVENT_TYPE_GROUP.FEED}
                                disabled={isOperationInProgress || !isLoaded}
                            />
                            {
                                canManage &&
                                <div className={classnames(local.buttonsContainer, 'container-row')}>
                                    {
                                        showDeleteButton &&
                                        <div className='ls-button'>
                                            <button
                                                id={AUTOMATION_ID.FEED_DELETE_BTN}
                                                className='btn-negative-transparent'
                                                disabled={!isLoaded || isOperationInProgress}
                                                onClick={() => {
                                                    if (!isOperationInProgress) {
                                                        showFeedDeleteDialog(clientId, feedId);
                                                    }
                                                }}>
                                                <FormattedMessage id='common.delete' />
                                            </button>
                                        </div>
                                    }
                                    {
                                        showUpgradeButton &&
                                        <div className='ls-button'>
                                            <button
                                                id={AUTOMATION_ID.FEED_UPGRADE_BTN}
                                                disabled={!isLoaded || isOperationInProgress}
                                                onClick={() => {
                                                    if (!isOperationInProgress) {
                                                        goToPendingFeedPage(clientId, feedId);
                                                    }
                                                }}>
                                                <FormattedMessage id='common.upgrade' />
                                            </button>
                                        </div>
                                    }
                                    {
                                        showEditButton &&
                                        <div className='ls-button'>
                                            <button
                                                id={AUTOMATION_ID.FEED_EDIT_BTN}
                                                disabled={!isLoaded || isOperationInProgress}
                                                onClick={() => {
                                                    if (!isOperationInProgress) {
                                                        view === FEED_DETAILS_VIEW.MAIN ?
                                                            goToEditFeedPage(clientId, feedId) :
                                                            goToPendingFeedPage(clientId, feedId);
                                                    }
                                                }}>
                                                <FormattedMessage id='common.edit' />
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {
                        [ FEED_STATE.ENABLED, FEED_STATE.DISABLED ].includes(feedState as string) &&
                        state === FEED_VERSION_STATE.VALID &&
                        <div className={classnames(local.switch, 'container-row')}>
                            <FormattedMessage id='common.off' />
                            <Switch
                                disabled={!isLoaded || isOperationInProgress || !canManage}
                                checked={feedState === FEED_STATE.ENABLED}
                                onChange={this.toggleEnableFeed}
                            />
                            <FormattedMessage id='common.on' />
                        </div>
                    }
                    {
                        isLoaded ?
                            this.renderDetails() :
                            this.renderSkeletonRows()
                    }
                </div>
                <FeedDeleteDialog />
            </div>
        );
    }

    private checkAccess = () => {
        const client = this.getClient();
        const owner = this.getSourceOwner();

        const canView = client && client.permissions.includes(PERMISSIONS.FEEDS.VIEW);
        const canRun = client && client.permissions.includes(PERMISSIONS.FEEDS.RUN) && owner &&
            owner.permissions.includes(PERMISSIONS.SOURCES.USE);
        const canManage = client && client.permissions.includes(PERMISSIONS.FEEDS.MANAGE) && owner &&
            owner.permissions.includes(PERMISSIONS.SOURCES.USE);

        return { canView, canRun, canManage };
    }

    private getClient = () => {
        const { clients, match: { params: { clientId } } } = this.props;

        return clients.find(({ id }) => id === clientId);
    }

    private getSourceOwner = () => {
        const { owners, data: { sourceOwnerId } } = this.props;

        return owners.find(({ id }) => id === sourceOwnerId);
    }

    private renderBreadcrumbs = () => {
        const {
            view,
            goToFeedsListPage,
            setView,
            isOperationInProgress,
            additionalData: { state: additionalState },
            data: { name, suffix, state }
        } = this.props;

        const displayName = (name && suffix) ? `${name}_${suffix}` : '';
        const isMainView = view === FEED_DETAILS_VIEW.MAIN;

        const breadcrumbsItems: BreadcrumbItemType[] = [
            {
                id: AUTOMATION_ID.ALL_FEEDS_BREADCRUMB,
                label: (<FormattedMessage id='feeds.allFeeds' />),
                onClick: () => { goToFeedsListPage(); }
            },
            {
                label: (
                    <Fragment>
                        {displayName}
                        {
                            isMainView && state && state !== FEED_VERSION_STATE.VALID &&
                            <Fragment>
                                &nbsp;[<FormattedMessage id={`feeds.feedVersionState.${state}`} defaultMessage='Unknown' />]
                            </Fragment>
                        }
                    </Fragment>
                ),
                onClick: () => {
                    if (!isOperationInProgress) {
                        setView(FEED_DETAILS_VIEW.MAIN);
                    }
                }
            }
        ];

        if (!isMainView) {
            breadcrumbsItems.push(
                {
                    label: (
                        <Fragment>
                            <span className={classnames(local.breadcrumbsAdditionalName, 'ellipsis')}>
                                {displayName}
                            </span>
                            [<FormattedMessage id={`feeds.feedVersionState.${additionalState}`} defaultMessage='Unknown' />]
                        </Fragment>
                    )
                }
            );
        }

        return (
            <Breadcrumbs
                items={breadcrumbsItems}
                selectedItemIndex={isMainView ? 1 : 2}
            />
        );
    }

    private renderAdditionalVersionLabel = () => {
        const {
            view,
            setView,
            isOperationInProgress,
            additionalData: { state }
        } = this.props;

        return view === FEED_DETAILS_VIEW.MAIN && state ?
            <div className={local.pendingStateLabel}>
                <span className={local.message}>
                    <FormattedMessage id='feeds.pendingStateMessage' />
                </span>
                <span
                    className={local.link}
                    onClick={() => {
                        if (!isOperationInProgress) {
                            setView(FEED_DETAILS_VIEW.ADDITIONAL);
                        }
                    }}
                >
                    <FormattedMessage id='feeds.linkMessage' />
                </span>
            </div> :
            null;
    }

    private renderConfigurationBlock = () => {
        const { view, data, additionalData } = this.props;

        const {
            origin,
            report,
            sourceOwnerName,
            sourceOwnerId,
            sourceId,
            collectorName,
            collectorId,
            configurationContext,
            credentialName,
            credentialId,
            credentialOwnerId,
            targets
        } = view === FEED_DETAILS_VIEW.MAIN ? data : additionalData;

        const configurationDetails: JSX.Element[] = [];
        configurationContext?.forEach(({ key, value, isSecret }) => {
            configurationDetails.push(
                <div key={key} className={local.configurationDetailsRow}>
                    <FormattedMessage id={`${collectorId}.${key}`} defaultMessage={key} />
                    : {isSecret && value ? '*'.repeat(12) : <FormattedMessage id={`${collectorId}.${key}.option.${value}`} defaultMessage={value || ' '} />}
                </div>
            );
        });

        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.configuration' />
                </div>
                <div className={classnames(local.smallLabel, 'ellipsis')}>
                    {
                        origin && report &&
                        <a href={`#${formatString(SOURCES_ROUTES.DETAILS, sourceOwnerId, sourceId)}`}>
                            {`${origin}_${report}`}
                        </a>
                    }
                </div>
                <div className={local.ownerName}>
                    {sourceOwnerName}
                </div>
                <div className={local.collectorName}>
                    {collectorName}
                </div>
                {configurationDetails}
                {
                    credentialName && credentialOwnerId && credentialId &&
                    <div className={local.credentialLink}>
                        <FormattedMessage id='common.collectorCredentials' />: <a href={`#${formatString(COLLECTOR_CREDENTIALS_ROUTES.DETAILS, credentialOwnerId, credentialId)}`}>{credentialName}</a>
                    </div>
                }
                {
                    targets?.length ?
                        <Fragment>
                            <div className={local.targetsLabel}>
                                <FormattedMessage id='common.destinations' />
                            </div>
                            <div className={classnames(local.targetsContainer, 'container-row')}>
                                {
                                    targets.map(({
                                        id,
                                        targetId,
                                        targetOwnerId,
                                        targetName,
                                        targetCredentialId,
                                        targetCredentialOwnerId,
                                        targetCredentialName
                                    }) => (
                                        <div className={local.targetContainer} key={id}>
                                            <div className='ellipsis'>
                                                <FormattedMessage id='common.destination' />
                                                :&nbsp;
                                                <a href={`#${formatString(TARGETS_ROUTES.DETAILS, targetOwnerId, targetId)}`}>
                                                    {targetName}
                                                </a>
                                            </div>
                                            <div className='ellipsis'>
                                                <FormattedMessage id='common.destinationCredential' />
                                                :&nbsp;
                                                <a href={`#${formatString(TARGET_CREDENTIALS_ROUTES.DETAILS, targetCredentialOwnerId, targetCredentialId)}`}>
                                                    {targetCredentialName}
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Fragment> : null
                }
            </Fragment>
        );
    }

    private renderDetails = () => {
        const { view, data, additionalData } = this.props;

        const {
            state,
            schedules,
            updatedBy,
            updateTimestamp = 0
        } = view === FEED_DETAILS_VIEW.MAIN ? data : additionalData;

        const { canManage, canRun } = this.checkAccess();

        return (
            <Fragment>
                {
                    this.renderFeedStateLabel()
                }
                {
                    this.renderFeedVersionStateLabel()
                }
                {
                    this.renderAdditionalVersionLabel()
                }
                {
                    this.renderConfigurationBlock()
                }
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.dataCollection' />
                </div>
                <div className={classnames(local.schedulesContainer, 'container-row')}>
                    {schedules?.map(this.renderSchedule)}
                </div>
                {
                    view === FEED_DETAILS_VIEW.MAIN && state === FEED_VERSION_STATE.VALID && (canManage || canRun) &&
                    <div className={classnames(local.scheduleControlsBlock, 'container-row')}>
                        {Boolean(schedules?.length) && this.renderTogglePauseScheduleButton()}
                        {this.renderRunButton()}
                    </div>
                }
                <div className={local.divider} />
                <div className={local.lastUpdatedTitle}>
                    <FormattedMessage id='common.lastUpdated' />
                </div>
                <div className={local.lastUpdatedValue}>
                    {`${moment.utc(updateTimestamp).format('DD MMMM YYYY [at] HH:mm UTC')} by ${updatedBy}`}
                </div>
            </Fragment>
        );
    }

    private renderSchedule = ({
        id,
        cron,
        collectionOffsetTemplateDescription,
        collectionOffsetDays,
        lastRunTime,
        nextRunTime
    }: Schedule) => {
        const { intl } = this.props;

        let parsedCronExpression;
        try {
            parsedCronExpression = cronstrue.toString(cron || '', { locale, use24HourTimeFormat: true, verbose: true });
        } catch (error) {
            parsedCronExpression = <FormattedMessage id='common.invalidCronExpression' />;
        }

        return (
            <div className={local.scheduleContainer} key={id}>
                {
                    collectionOffsetTemplateDescription &&
                    <Fragment>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='feeds.reportRange' />
                        </div>
                        <div className={local.reportRange}>
                            {collectionOffsetTemplateDescription}
                        </div>
                        {
                            collectionOffsetDays ?
                                <Fragment>
                                    <div className={local.smallLabel}>
                                        <FormattedMessage id='feeds.collectionOffsetDays' />
                                    </div>
                                    <div className={local.collectionOffsetDays}>
                                        {collectionOffsetDays} <FormattedMessage id='common.days' />
                                    </div>
                                </Fragment> :
                                null
                        }
                    </Fragment>
                }
                {
                    cron &&
                    <Fragment>
                        <div className={local.smallLabel}>
                            <FormattedMessage id='common.schedule' />
                        </div>
                        {
                            cron &&
                            <div className={local.schedule}>
                                {parsedCronExpression}
                            </div>
                        }
                    </Fragment>
                }
                {
                    (lastRunTime || nextRunTime) &&
                    <div className={local.runs}>
                        {
                            lastRunTime &&
                            <div className={local.lastRun}>
                                {formatString(intl.formatMessage({ id: 'common.lastRunOn' }), moment.utc(lastRunTime).format('DD MMMM YYYY, HH:mm UTC'))}
                            </div>
                        }
                        {
                            nextRunTime &&
                            <div className={local.nextRun}>
                                {formatString(intl.formatMessage({ id: 'common.nextRunOn' }), moment.utc(nextRunTime).format('DD MMMM YYYY, HH:mm UTC'))}
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }

    private renderSkeletonRows = () => {
        const blocksCount = 3;
        const rowsCount = 2;

        return Array.apply(null, Array(blocksCount)).map((el1, idx1) => (
            <div key={idx1} className={local.skeletonBlock}>
                <div className={local.skeletonItem}>
                    <SkeletonItem width='160px' height='24px' />
                </div>
                {
                    Array.apply(null, Array(rowsCount)).map((el2, idx2) => (
                        <div key={`${idx1}.${idx2}`} className={local.skeletonItem}>
                            <SkeletonItem width='320px' height='16px' />
                        </div>
                    ))
                }
            </div>
        ));
    }

    private renderFeedStateLabel = () => {
        const { feedState } = this.props.data;

        return feedState === FEED_STATE.TEST ?
            <div className={classnames(local.label, this.getLabelClassName(feedState))}>
                <FormattedMessage id={`feeds.feedState.${feedState}`} />
            </div> :
            null;
    }

    private renderFeedVersionStateLabel = () => {
        const { data, additionalData, view } = this.props;
        const { state, sourceVersionInWorkflowState } = view === FEED_DETAILS_VIEW.MAIN ? data : additionalData;

        return state !== FEED_VERSION_STATE.VALID ?
            <Fragment>
                <div className={classnames(local.label, this.getLabelClassName(state))}>
                    <FormattedMessage id={`feeds.feedVersionState.${state}`} />
                </div>
                {
                    view === FEED_DETAILS_VIEW.ADDITIONAL && sourceVersionInWorkflowState === SOURCE_TRANSIENT_STATE.VALIDATING &&
                    <span className={local.labelMessage}>
                        <FormattedMessage id='feeds.pendingVersionMessage' />
                    </span>
                }
            </Fragment> :
            null;
    }

    // determines CSS class for both feed and feed version state label
    private getLabelClassName = (state) => {
        let className = '';

        switch (state) {
            case FEED_VERSION_STATE.PENDING:
                className = local.pending;
                break;
            case FEED_VERSION_STATE.SUPERSEDED:
                className = local.superseded;
                break;
            case FEED_VERSION_STATE.VALIDATION_FAILED:
                className = local.validationFailed;
                break;
            case FEED_VERSION_STATE.CANCELLED:
                className = local.cancelled;
                break;
            case FEED_STATE.TEST:
                className = local.test;
                break;
        }

        return className;
    }

    private renderTogglePauseScheduleButton = () => {
        const { data: { feedState, schedulerPaused }, isOperationInProgress } = this.props;
        const isBlocked = feedState !== FEED_STATE.ENABLED;
        const isDisabled = isOperationInProgress || isBlocked;

        return (
            <ButtonWithTooltip
                id={AUTOMATION_ID.FEED_SCHEDULE_TOGGLE_BTN}
                buttonText={<FormattedMessage id={this.getTogglePauseSchedulerButtonLabel(schedulerPaused)} />}
                tooltipText={<FormattedMessage id={this.getScheduleButtonTooltipMessage(feedState)} />}
                tooltipCondition={isBlocked}
                disabled={isDisabled}
                className='btn-transparent'
                onClick={this.togglePauseSchedule}
            />
        );
    }

    private renderRunButton = () => {
        const {
            openAdHocRunDialog,
            isOperationInProgress,
            data: { feedState }
        } = this.props;

        const isBlocked = ![ FEED_STATE.ENABLED, FEED_STATE.TEST ].includes(feedState as string);
        const isDisabled = isOperationInProgress || isBlocked;

        return (
            <ButtonWithTooltip
                id={AUTOMATION_ID.FEED_RUN_BTN}
                buttonText={
                    <Fragment>
                        <FontAwesomeIcon icon={faPlay} className={local.runIcon} />
                        <FormattedMessage id='common.runNow' />
                    </Fragment>
                }
                tooltipText={<FormattedMessage id={this.getScheduleButtonTooltipMessage(feedState)} />}
                tooltipCondition={isBlocked}
                disabled={isDisabled}
                className='btn-transparent'
                onClick={() => { openAdHocRunDialog(); }}
            />
        );
    }

    private getTogglePauseSchedulerButtonLabel = (schedulerPaused) => {
        const { isOperationInProgress, operationInProgressReporterData: { reporter, prevStateData = {} } } = this.props;
        if (isOperationInProgress && reporter === FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER.TOGGLE_PAUSE) {
            return (prevStateData as any).schedulerPaused ? 'common.restarting' : 'common.pausing';
        }

        return schedulerPaused ? 'common.restartSchedule' : 'common.pauseSchedule';
    }

    private getScheduleButtonTooltipMessage = (feedState) => {
        if (feedState !== FEED_STATE.ENABLED) {
            return MESSAGE.SCHEDULE_ACTION_INCORRECT_FEED_STATE;
        }

        return '';
    }

    private togglePauseSchedule = () => {
        const { togglePauseFeedSchedule, data: { feedId, schedulerPaused } } = this.props;
        togglePauseFeedSchedule(feedId, schedulerPaused);
    }

    private toggleEnableFeed = () => {
        const { toggleEnableFeed, data: { feedId, feedState } } = this.props;
        toggleEnableFeed(feedId, feedState);
    }
}

export default FeedDetailsLayout;
