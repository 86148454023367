import { createSelector } from 'reselect';

import { getUserProfile } from 'modules/auth/selectors';
import { sort } from 'modules/common/utils';
import { PERMISSIONS } from 'modules/common/constants';
import { REDUCER_NAME as moduleReducerName } from '../../constants';
import { REDUCER_NAME } from '../constants';

// common
export const getOwnersListItems = (state) => state[moduleReducerName][REDUCER_NAME].ownersList.items;
export const getOwnersListLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].ownersList.loadStatus;

// owners list
export const getOwnersListTableState = (state) => state[moduleReducerName][REDUCER_NAME].ownersList.tableState;

// owner form
export const getOwnerFormOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].ownerForm.ownerOperationInProgress;

// owner memberships list
export const getOwnerMembershipsListTableState = (state) => state[moduleReducerName][REDUCER_NAME].ownerMembershipsList.tableState;
export const getOwnerMembershipOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].ownerMembershipsList.ownerMembershipOperationInProgress;
export const getIsOwnerMembershipDialogOpen = (state) => state[moduleReducerName][REDUCER_NAME].ownerMembershipsList.isOwnerMembershipDialogOpen;

// owner groups list
export const getOwnerGroupsListTableState = (state) => state[moduleReducerName][REDUCER_NAME].ownerGroupsList.tableState;

// owner details
export const getOwnerDetails = (state) => state[moduleReducerName][REDUCER_NAME].ownerDetails.data;
export const getOwnerDetailsLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].ownerDetails.loadStatus;

export const getOwnersListLayoutProps = createSelector(
    getUserProfile,
    getOwnersListLoadStatus,
    ({ owners, odpMemberAdministrator }, status) => ({
        canView: odpMemberAdministrator || owners.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW)),
        canManage: odpMemberAdministrator,
        status
    })
);

export const getOwnersListProps = createSelector(
    getOwnersListItems,
    getOwnersListLoadStatus,
    getOwnersListTableState,
    (items, status, { searchBy, sortBy, sortOrder, page, pageSize }) => {
        const filteredItems = items
            .filter(({ ownerName }) => ownerName.toLowerCase().includes(searchBy.toLowerCase()))
            .map(({ ownerId, ownerName }) => ({ ownerId, ownerName }));

        return {
            status,
            sortBy,
            sortOrder,
            totalCount: filteredItems.length,
            items: sort(filteredItems, sortBy, sortOrder).slice(0, (page + 1) * pageSize)
        };
    }
);

export const getOwnerFormLayoutProps = createSelector(
    getUserProfile,
    getOwnerFormOperationInProgress,
    ({ odpMemberAdministrator }, isOperationInProgress) => ({
        isOperationInProgress,
        canAccess: odpMemberAdministrator
    })
);

const getOwnerDetailsLayoutProps = (state, { match: { params: { ownerId } } }) => {
    const status = getOwnerDetailsLoadStatus(state);
    const isOperationInProgress = getOwnerMembershipOperationInProgress(state);
    const { name: ownerName } = getOwnerDetails(state);
    const {
        owners,
        id: currentMemberId,
        odpMemberAdministrator: isOdpMemberAdministrator
    } = getUserProfile(state);

    const currentUserOwner = owners.find(({ id }) => id === ownerId);
    const canView = Boolean(currentUserOwner?.permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW));
    const canManage = Boolean(currentUserOwner?.permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE));

    // important: checking direct membership is essential here, user profile contains inherited memberships too,
    // so search is performed on owner data, which contains only direct memberships
    const { memberships } = getOwnerDetails(state);
    const currentUserHasMembership = Boolean(memberships?.find(({ memberId }) => memberId === currentMemberId));

    return {
        status,
        canView,
        canManage,
        ownerName,
        isOperationInProgress,
        isOdpMemberAdministrator,
        currentUserHasMembership
    };
};

export const makeOwnerDetailsLayoutProps = () => createSelector(
    getOwnerDetailsLayoutProps,
    (object) => object
);

export const getOwnerMembershipsListProps = createSelector(
    getUserProfile,
    getOwnerDetails,
    getOwnerDetailsLoadStatus,
    getOwnerMembershipsListTableState,
    getOwnerMembershipOperationInProgress,
    ({ id }, { memberships = [] }, status, { searchBy, sortBy, sortOrder, page, pageSize }, isOperationInProgress) => {
        const filteredItems = memberships.filter(({ username }) => username.toLowerCase().includes(searchBy.toLowerCase()));

        return {
            status,
            sortBy,
            sortOrder,
            isOperationInProgress,
            currentUserId: id,
            totalCount: filteredItems.length,
            items: sort(filteredItems, sortBy, sortOrder).slice(0, (page + 1) * pageSize)
        };
    }
);

export const getOwnerGroupsListProps = createSelector(
    getOwnerDetails,
    getOwnerDetailsLoadStatus,
    getOwnerGroupsListTableState,
    ({ groups = [] }, loadStatus, { sortBy, sortOrder, page, pageSize }) => ({
        loadStatus,
        sortBy,
        sortOrder,
        totalCount: groups?.length,
        items: sort(groups, sortBy, sortOrder).slice(0, (page + 1) * pageSize)
    })
);
