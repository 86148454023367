import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { Moment } from 'moment';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import PickersDay from '../PickersDay';

import local from './local.module.scss';

type DatePickerProps = Partial<MuiDatePickerProps<Moment, Moment>> & Pick<MuiDatePickerProps<Moment, Moment>, 'value' | 'onChange'>;

const OpenPickerIcon = () => (
    <span className={local.icon}>
        <FontAwesomeIcon icon={faCalendar} />
    </span>
);

const DatePicker: FunctionComponent<DatePickerProps> = (props) => (
    <MuiDatePicker
        inputFormat='DD MMMM yyyy'
        renderInput={(textFieldProps) => (
            <TextField
                {...textFieldProps}
                inputProps={{ ...textFieldProps.inputProps, placeholder: 'DD/MM/YYYY' }}
            />
        )}
        components={{ OpenPickerIcon }}
        views={[ 'day' ]}
        disableMaskedInput={true}
        renderDay={
            (date, selectedDates, { key, ...otherPickersDayProps }) =>
                <PickersDay key={key} {...otherPickersDayProps} />
        }
        InputProps={{
            classes: {
                root: local.datepickerInput,
                disabled: local.datepickerInputDisabled
            }
        }}
        {...props}
    />
);

export default DatePicker;
