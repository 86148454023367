import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { formatString } from 'modules/common/utils';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH, AUTOMATION_ID } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { transformationApiDefinitions } from 'modules/service/types';
import { PIPELINE_STATE, PIPELINES_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface PipelinesListProps {
    items: Array<transformationApiDefinitions['PipelineSearchResponseDto']>;
    status: LoadStatus;
    sortOrder: SortOrder;
    sortBy: string;
    onSort: Function;
    goToPipelineDetailsPage: Function;
}

class PipelinesList extends Component<PipelinesListProps> {
    public render() {
        const { items, status, onSort } = this.props;

        return (
            <Table
                columns={this.getTableConfig()}
                data={items}
                onSort={onSort}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={status === LOAD_STATUS.LOADED}
                onRowClick={this.onTableRowClick}
            />
        );
    }

    private getTableConfig = () => {
        const { sortBy, sortOrder } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.name' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'name',
                accessor: ({ id, ownerId, purpose, state }) => (
                    <Fragment>
                        <a href={`#${formatString(PIPELINES_ROUTES.DETAILS, ownerId, id)}`} className={local.name}>
                            {purpose}
                        </a>
                        {
                            state !== PIPELINE_STATE.ACTIVE &&
                            <span className={local.label}>
                                <FormattedMessage id={`pipelines.state.${state}`} />
                            </span>
                        }
                    </Fragment>
                ),
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '74%',
                skeletonCellWidth: 800,
                sortable: true,
                showTooltip: true,
                cellClassName: AUTOMATION_ID.PIPELINES_TABLE_NAME
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='pipelines.dagId' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                accessor: 'airflowDagId',
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '74%',
                skeletonCellWidth: 800,
                sortable: true,
                showTooltip: true,
                cellClassName: AUTOMATION_ID.PIPELINES_TABLE_DAG_ID
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.owner' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                accessor: 'ownerName',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 116,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.PIPELINES_TABLE_OWNER}`
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'lastUpdated',
                accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 161,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.PIPELINES_TABLE_LAST_UPDATED}`
            }
        ];
    }

    private onTableRowClick = ({ id, ownerId }) => {
        const { status, goToPipelineDetailsPage } = this.props;

        if (status === LOAD_STATUS.LOADED) {
            goToPipelineDetailsPage(id, ownerId);
        }
    }
}

export default PipelinesList;
