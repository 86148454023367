import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { LOAD_STATUS, AUTOMATION_ID } from 'modules/common/constants';
import SearchInput from 'modules/common/components/SearchInput';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import PipelinesList from 'modules/transformationPipelines/components/PipelinesList';
import ErrorPage from 'modules/common/components/ErrorPage';
import { LoadStatus } from 'modules/common/interfaces';
import { transformationApiDefinitions } from 'modules/service/types';

import local from './local.module.scss';

interface PipelinesListLayoutProps {
    items: Array<transformationApiDefinitions['PipelineSearchResponseDto']>;
    status: LoadStatus;
    totalCount: number;
    canView: boolean;
    loadData: Function;
    onSearch: Function;
    resetTable: Function;
    loadMore: Function;
}

class PipelinesListLayout extends Component<PipelinesListLayoutProps> {
    public componentDidMount() {
        const { loadData, resetTable, canView } = this.props;

        if (canView) {
            resetTable();
            loadData();
        }
    }

    public componentDidUpdate() {
        const { status, canView, loadData } = this.props;

        if (canView && status === LOAD_STATUS.REQUIRED) {
            loadData();
        }
    }

    public render() {
        const { status, canView, totalCount, items, loadMore, onSearch } = this.props;

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const isLoaded = status === LOAD_STATUS.LOADED;

        return (
            <Fragment>
                <div className={local.title}>
                    <FormattedMessage id='common.transformationPipelines' />
                </div>
                <div className={classnames(local.tableControls, 'container-row')}>
                    <SearchInput inputId={AUTOMATION_ID.PIPELINES_SEARCH} disabled={!isLoaded} onSearch={onSearch} />
                </div>
                <div className={local.card}>
                    <PipelinesList />
                    {
                        isLoaded && (totalCount > items.length) &&
                        <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                    }
                </div>
            </Fragment>
        );
    }
}

export default PipelinesListLayout;
