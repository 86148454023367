import { createSelector } from 'reselect';

import { getUserProfile } from 'modules/auth/selectors';
import { PERMISSIONS } from 'modules/common/constants';
import { REDUCER_NAME as moduleReducerName } from '../../constants';
import { MEMBERS_LIST_SORT_ATTRIBUTES_MAP, REDUCER_NAME } from '../constants';

// members list
export const getMembersListItems = (state) => state[moduleReducerName][REDUCER_NAME].membersList.items;
export const getMembersListPage = (state) => state[moduleReducerName][REDUCER_NAME].membersList.page;
export const getMembersListPageSize = (state) => state[moduleReducerName][REDUCER_NAME].membersList.pageSize;
export const getMembersListTotalCount = (state) => state[moduleReducerName][REDUCER_NAME].membersList.totalCount;
export const getMembersListLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].membersList.loadStatus;
export const getMembersListSortColumn = (state) => state[moduleReducerName][REDUCER_NAME].membersList.sortBy;
export const getMembersListSortOrder = (state) => state[moduleReducerName][REDUCER_NAME].membersList.sortOrder;
export const getMembersListSearchText = (state) => state[moduleReducerName][REDUCER_NAME].membersList.searchBy;
export const getMembersListOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].membersList.memberOperationInProgress;
export const getIsMemberDialogOpen = (state) => state[moduleReducerName][REDUCER_NAME].membersList.isMemberDialogOpen;

// member details
export const getMemberDetails = (state) => state[moduleReducerName][REDUCER_NAME].memberDetails.data;
export const getMemberDetailsLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].memberDetails.loadStatus;
export const getMemberDetailsOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].memberDetails.memberOperationInProgress;
export const getIsOwnerMembershipDialogOpen = (state) => state[moduleReducerName][REDUCER_NAME].memberDetails.isOwnerMembershipDialogOpen;
export const getIsClientMembershipDialogOpen = (state) => state[moduleReducerName][REDUCER_NAME].memberDetails.isClientMembershipDialogOpen;
export const getGroupsList = (state) => state[moduleReducerName][REDUCER_NAME].memberDetails.groupsList;
export const getIsGroupMembershipDialogOpen = (state) => state[moduleReducerName][REDUCER_NAME].memberDetails.isGroupMembershipDialogOpen;

export const getMembersListSearchPayload = createSelector(
    getMembersListPage,
    getMembersListPageSize,
    getMembersListSortColumn,
    getMembersListSortOrder,
    getMembersListSearchText,
    (page, pageSize, sortBy, ordering, searchString) => ({
        orderBy: [
            {
                attribute: MEMBERS_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering
            }
        ],
        searchString,
        page,
        pageSize
    })
);

export const getMembersListLayoutProps = createSelector(
    getUserProfile,
    getMembersListItems,
    getMembersListLoadStatus,
    getMembersListTotalCount,
    getMembersListOperationInProgress,
    ({ odpMemberAdministrator }, items, status, totalCount, isOperationInProgress) => ({
        items,
        status,
        totalCount,
        isOperationInProgress,
        canView: odpMemberAdministrator,
        canManage: odpMemberAdministrator
    })
);

export const getMembersListProps = createSelector(
    getMembersListItems,
    getMembersListLoadStatus,
    getMembersListSortOrder,
    getMembersListSortColumn,
    getMembersListOperationInProgress,
    (items, status, sortOrder, sortBy, isOperationInProgress) => ({
        items,
        status,
        sortOrder,
        sortBy,
        isOperationInProgress
    })
);

const getPossibleNewOwners = (currentMemberOwners, targetMemberOwners) => currentMemberOwners?.filter(
    (owner) => owner.permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE) &&
        targetMemberOwners?.every(({ id }) => id !== owner.id)
);

const getPossibleNewClients = (currentMemberClients, targetMemberClients) => currentMemberClients?.filter(
    (client) => client.permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE) &&
        targetMemberClients?.every(({ id }) => id !== client.id)
);

const getPossibleNewGroups = (manageableGroups, targetMemberGroups) => manageableGroups?.filter(
    (client) => targetMemberGroups?.every(({ id }) => id !== client.id)
);

export const getMemberDetailsLayoutProps = createSelector(
    getUserProfile,
    getGroupsList,
    getMemberDetails,
    getMemberDetailsLoadStatus,
    getMemberDetailsOperationInProgress,
    (profile, manageableGroups, data, loadStatus, isOperationInProgress) => ({
        profile,
        data,
        loadStatus,
        isOperationInProgress,
        canAddOwner: Boolean(getPossibleNewOwners(profile.owners, data.owners).length),
        canAddClient: Boolean(getPossibleNewClients(profile.clients, data.clients).length),
        canAddGroup: Boolean(getPossibleNewGroups(manageableGroups, data.groups).length)
    })
);

export const getMembershipDialogOwners = createSelector(
    getUserProfile,
    getMemberDetails,
    (profile, data) => getPossibleNewOwners(profile.owners, data.owners)
);

export const getMembershipDialogClients = createSelector(
    getUserProfile,
    getMemberDetails,
    (profile, data) => getPossibleNewClients(profile.clients, data.clients)
);

export const getMembershipDialogGroups = createSelector(
    getGroupsList,
    getMemberDetails,
    (manageableGroups, data) => getPossibleNewGroups(manageableGroups, data.groups)
);

const getMemberFormValues = (state) => {
    const { memberForm } = state.form;

    return memberForm?.values || {};
};

export const isMemberFormReadyForSubmission = createSelector(
    getMemberFormValues,
    ({ firstName, lastName, username }) => Boolean(firstName && lastName && username)
);
