import { connect } from 'react-redux';

import * as actions from 'modules/feeds/actions';
import { getFeedsListProps } from 'modules/feeds/selectors';
import FeedsList from './FeedsList';

const mapDispatchToProps = {
    onSort: actions.feedsListSort,
    goToFeedDetailsPage: actions.goToFeedDetailsPage
};

export default connect(getFeedsListProps, mapDispatchToProps)(FeedsList);
