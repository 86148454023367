import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';

import { MAIN_ROUTES } from 'modules/main/constants';
import { MEMBERS_ROUTES } from 'modules/admin/members/constants';
import { getUserProfile } from 'modules/auth/selectors';
import { getMenuItemsForUser } from 'modules/main/selectors';

const HomeLayout: FunctionComponent = () => {
    const { odpMemberAdministrator } = useSelector(getUserProfile);
    const tabs = useSelector(getMenuItemsForUser);

    const path = tabs.length ?
        (tabs[0].subItems?.length ? tabs[0].subItems[0].route : tabs[0].route) :
        odpMemberAdministrator ? MEMBERS_ROUTES.LIST : MAIN_ROUTES.FORBIDDEN;

    return (
        <Navigate to={path} />
    );
};

export default HomeLayout;
