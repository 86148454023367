import { connect } from 'react-redux';

import { getSourceFormSchema, getSourceFormValues } from 'modules/sources/selectors';
import { setSourceFormSchema } from 'modules/sources/actions';
import { removeSubmitError } from 'modules/form/actions';
import AvroSchemaField from './AvroSchemaField';

const mapStateToProps = (state) => ({
    formValues: getSourceFormValues(state),
    schema: getSourceFormSchema(state)
});

const mapDispatchToProps = {
    removeSubmitError,
    setSchema: setSourceFormSchema
};

export default connect(mapStateToProps, mapDispatchToProps)(AvroSchemaField);
