import * as t from '../actionTypes';

export const setFeedsListLoadStatus = (data) => ({
    type: t.SET_FEEDS_LIST_LOAD_STATUS,
    payload: data
});

export const setFeedsListItems = (data) => ({
    type: t.SET_FEEDS_LIST_ITEMS,
    payload: data
});

export const appendFeedsListItems = (data) => ({
    type: t.APPEND_FEEDS_LIST_ITEMS,
    payload: data
});

export const setFeedsListCount = (data) => ({
    type: t.SET_FEEDS_LIST_COUNT,
    payload: data
});

export const resetFeedsList = () => ({
    type: t.RESET_FEEDS_LIST
});

export const setFeedsListSortOrder = (data) => ({
    type: t.SET_FEEDS_LIST_SORT_ORDER,
    payload: data
});

export const setFeedsListSortColumn = (data) => ({
    type: t.SET_FEEDS_LIST_SORT_COLUMN,
    payload: data
});

export const setFeedsListPage = (data) => ({
    type: t.SET_FEEDS_LIST_PAGE,
    payload: data
});

export const setFeedsListSearchText = (data) => ({
    type: t.SET_FEEDS_LIST_SEARCH_TEXT,
    payload: data
});

export const setFeedDetailsLoadStatus = (data) => ({
    type: t.SET_FEED_DETAILS_LOAD_STATUS,
    payload: data
});

export const setFeedDetails = (data) => ({
    type: t.SET_FEED_DETAILS,
    payload: data
});

export const setAdditionalFeedDetails = (data) => ({
    type: t.SET_ADDITIONAL_FEED_DETAILS,
    payload: data
});

export const resetFeedDetails = () => ({
    type: t.RESET_FEED_DETAILS
});

export const setFeedDetailsOperationInProgress = (data) => ({
    type: t.SET_FEED_DETAILS_OPERATION_IN_PROGRESS,
    payload: data
});

export const setFeedFormOperationInProgress = (data) => ({
    type: t.SET_FEED_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setFeedDetailsOperationInProgressReporterData = (data) => ({
    type: t.SET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA,
    payload: data
});

export const unsetFeedDetailsOperationInProgressReporterData = () => ({
    type: t.UNSET_FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER_DATA
});

export const setFeedDetailsView = (data) => ({
    type: t.SET_FEED_DETAILS_VIEW,
    payload: data
});

export const resetFeedForm = () => ({
    type: t.RESET_FEED_FORM
});

export const setFeedFormSources = (data) => ({
    type: t.SET_FEED_FORM_SOURCES,
    payload: data
});

export const setFeedFormSourcesLoadStatus = (data) => ({
    type: t.SET_FEED_FORM_SOURCES_LOAD_STATUS,
    payload: data
});

export const setFeedFormCollectorCredentials = (data) => ({
    type: t.SET_FEED_FORM_COLLECTOR_CREDENTIALS,
    payload: data
});

export const setFeedFormCollectorCredentialsLoadStatus = (data) => ({
    type: t.SET_FEED_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS,
    payload: data
});

export const setFeedFormSourceDetails = (data) => ({
    type: t.SET_FEED_FORM_SOURCE_DETAILS,
    payload: data
});

export const setFeedFormSourceDetailsLoadStatus = (data) => ({
    type: t.SET_FEED_FORM_SOURCE_DETAILS_LOAD_STATUS,
    payload: data
});

export const resetFeedFormCollectorConfigurationManifest = () => ({
    type: t.RESET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST
});

export const setFeedFormCollectorConfigurationManifest = (data) => ({
    type: t.SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST,
    payload: data
});

export const setFeedFormCollectorConfigurationManifestLoadStatus = (data) => ({
    type: t.SET_FEED_FORM_COLLECTOR_CONFIGURATION_MANIFEST_LOAD_STATUS,
    payload: data
});

export const setFeedCollectionOffsetTemplate = (data) => ({
    type: t.SET_FEED_COLLECTION_OFFSET_TEMPLATE,
    payload: data
});

export const setFeedCollectionOffsetTemplateLoadStatus = (data) => ({
    type: t.SET_FEED_COLLECTION_OFFSET_TEMPLATE_LOAD_STATUS,
    payload: data
});

export const openAdHocRunDialog = () => ({
    type: t.OPEN_AD_HOC_RUN_DIALOG
});

export const closeAdHocRunDialog = () => ({
    type: t.CLOSE_AD_HOC_RUN_DIALOG
});

export const openFeedDeleteDialog = () => ({
    type: t.OPEN_FEED_DELETE_DIALOG
});

export const setFeedDeleteDialogDependentInstances = (data) => ({
    type: t.SET_FEED_DELETE_DIALOG_INSTANCES,
    payload: data
});

export const closeFeedDeleteDialog = () => ({
    type: t.CLOSE_FEED_DELETE_DIALOG
});

export const setFeedRunsListLoadStatus = (data) => ({
    type: t.SET_FEED_RUNS_LIST_LOAD_STATUS,
    payload: data
});

export const setFeedRunsListItems = (data) => ({
    type: t.SET_FEED_RUNS_LIST_ITEMS,
    payload: data
});

export const appendFeedRunsListItems = (data) => ({
    type: t.APPEND_FEED_RUNS_LIST_ITEMS,
    payload: data
});

export const setFeedRunsListCount = (data) => ({
    type: t.SET_FEED_RUNS_LIST_COUNT,
    payload: data
});

export const resetFeedRunsList = () => ({
    type: t.RESET_FEED_RUNS_LIST
});

export const setFeedRunsListSortOrder = (data) => ({
    type: t.SET_FEED_RUNS_LIST_SORT_ORDER,
    payload: data
});

export const setFeedRunsListSortColumn = (data) => ({
    type: t.SET_FEED_RUNS_LIST_SORT_COLUMN,
    payload: data
});

export const setFeedRunsListPage = (data) => ({
    type: t.SET_FEED_RUNS_LIST_PAGE,
    payload: data
});

export const setSelectedFeedRuns = (data) => ({
    type: t.SET_SELECTED_FEED_RUNS,
    payload: data
});

export const setFeedRunsListSearchText = (data) => ({
    type: t.SET_FEED_RUNS_LIST_SEARCH_TEXT,
    payload: data
});

export const setFeedRunsOperationInProgress = (data) => ({
    type: t.SET_FEED_RUNS_OPERATION_IN_PROGRESS,
    payload: data
});

export const setFeedRunsFiltersStatus = (data) => ({
    type: t.SET_FEED_RUNS_FILTERS_STATUS,
    payload: data
});

export const setFeedRunsFiltersFetchStart = (data) => ({
    type: t.SET_FEED_RUNS_FILTERS_FETCH_START,
    payload: data
});

export const setFeedRunsFiltersFetchEnd = (data) => ({
    type: t.SET_FEED_RUNS_FILTERS_FETCH_END,
    payload: data
});

export const setFeedRunsFiltersRunDate = (data) => ({
    type: t.SET_FEED_RUNS_FILTERS_RUN_DATE,
    payload: data
});

export const resetFeedRunsFilters = () => ({
    type: t.RESET_FEED_RUNS_FILTERS
});

export const addFeedSchedule = () => ({
    type: t.ADD_FEED_SCHEDULE
});

export const removeFeedSchedule = (data) => ({
    type: t.REMOVE_FEED_SCHEDULE,
    payload: data
});

export const setFeedSchedules = (data) => ({
    type: t.SET_FEED_SCHEDULES,
    payload: data
});

export const clearFeedSchedules = () => ({
    type: t.CLEAR_FEED_SCHEDULES
});

export const addFeedTarget = () => ({
    type: t.ADD_FEED_TARGET
});

export const removeFeedTarget = (data) => ({
    type: t.REMOVE_FEED_TARGET,
    payload: data
});

export const setFeedTargets = (data) => ({
    type: t.SET_FEED_TARGETS,
    payload: data
});

export const setFeedFormTargets = (data) => ({
    type: t.SET_FEED_FORM_TARGETS,
    payload: data
});

export const setFeedFormTargetsLoadStatus = (data) => ({
    type: t.SET_FEED_FORM_TARGETS_LOAD_STATUS,
    payload: data
});

export const setFeedFormTargetCredentials = (data) => ({
    type: t.SET_FEED_FORM_TARGET_CREDENTIALS,
    payload: data
});

export const setFeedFormTargetCredentialsLoadStatus = (data) => ({
    type: t.SET_FEED_FORM_TARGET_CREDENTIALS_LOAD_STATUS,
    payload: data
});

export const setFeedFormAdverityAuthorizations = (data) => ({
    type: t.SET_FEED_FORM_ADVERITY_AUTHORIZATIONS,
    payload: data
});

export const setFeedFormAdverityAuthorizationsLoadStatus = (data) => ({
    type: t.SET_FEED_FORM_ADVERITY_AUTHORIZATIONS_LOAD_STATUS,
    payload: data
});
