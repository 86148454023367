import * as t from '../actionTypes';

export const setClients = (data) => ({
    type: t.SET_CLIENTS,
    payload: data
});

export const setClientsLoadStatus = (data) => ({
    type: t.SET_CLIENTS_LOAD_STATUS,
    payload: data
});

export const resetClients = () => ({
    type: t.RESET_CLIENTS
});

export const setClientFormOperationInProgress = (data) => ({
    type: t.SET_CLIENT_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setClientMembershipsListOperationInProgress = (data) => ({
    type: t.SET_CLIENT_MEMBERSHIPS_LIST_OPERATION_IN_PROGRESS,
    payload: data
});

export const addClientMemberRole = (data) => ({
    type: t.ADD_CLIENT_MEMBER_ROLE,
    payload: data
});

export const removeClientMembership = (data) => ({
    type: t.REMOVE_CLIENT_MEMBERSHIP,
    payload: data
});

export const removeClientMemberRole = (data) => ({
    type: t.REMOVE_CLIENT_MEMBER_ROLE,
    payload: data
});

export const openClientMembershipDialog = () => ({
    type: t.OPEN_CLIENT_MEMBERSHIP_DIALOG
});

export const closeClientMembershipDialog = () => ({
    type: t.CLOSE_CLIENT_MEMBERSHIP_DIALOG
});

export const setClientsListSearchText = (data) => ({
    type: t.SET_CLIENTS_LIST_SEARCH_TEXT,
    payload: data
});

export const setClientsListSortOrder = (data) => ({
    type: t.SET_CLIENTS_LIST_SORT_ORDER,
    payload: data
});

export const setClientsListSortColumn = (data) => ({
    type: t.SET_CLIENTS_LIST_SORT_COLUMN,
    payload: data
});

export const setClientsListPage = (data) => ({
    type: t.SET_CLIENTS_LIST_PAGE,
    payload: data
});

export const setClientMembershipsListSearchText = (data) => ({
    type: t.SET_CLIENT_MEMBERSHIPS_LIST_SEARCH_TEXT,
    payload: data
});

export const setClientMembershipsListSortOrder = (data) => ({
    type: t.SET_CLIENT_MEMBERSHIPS_LIST_SORT_ORDER,
    payload: data
});

export const setClientMembershipsListSortColumn = (data) => ({
    type: t.SET_CLIENT_MEMBERSHIPS_LIST_SORT_COLUMN,
    payload: data
});

export const setClientMembershipsListPage = (data) => ({
    type: t.SET_CLIENT_MEMBERSHIPS_LIST_PAGE,
    payload: data
});

export const setClientDetails = (data) => ({
    type: t.SET_CLIENT_DETAILS,
    payload: data
});

export const setClientDetailsLoadStatus = (data) => ({
    type: t.SET_CLIENT_DETAILS_LOAD_STATUS,
    payload: data
});

export const resetClientDetails = () => ({
    type: t.RESET_CLIENT_DETAILS
});

export const setClientGroupsListSortOrder = (data) => ({
    type: t.SET_CLIENT_GROUPS_LIST_SORT_ORDER,
    payload: data
});

export const setClientGroupsListSortColumn = (data) => ({
    type: t.SET_CLIENT_GROUPS_LIST_SORT_COLUMN,
    payload: data
});

export const setClientGroupsListPage = (data) => ({
    type: t.SET_CLIENT_GROUPS_LIST_PAGE,
    payload: data
});
