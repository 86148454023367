import React, { Fragment, FunctionComponent, ReactElement, useEffect, useMemo } from 'react';
import { useTable, useRowSelect } from 'react-table';
import { FormattedMessage } from 'react-intl';

import SkeletonTableRows from 'modules/common/components/SkeletonTableRows';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import Checkbox from 'modules/common/components/Checkbox';

import local from './local.module.scss';

interface TableProps {
    isLoaded: boolean;
    skeletonRowsCount: number;
    columns: Array<{ [key: string]: any }>;
    data: Array<{ [key: string]: any }>;
    onSort?: Function;
    onRowClick?: Function;
    noDataMessage?: ReactElement;
    isSelectionEnabled?: boolean;
    onRowsSelect?: Function;
    rowIdentifierColumn?: string;
    disableCheckboxes?: boolean;
    canSelectRow?: Function;
}

const Table: FunctionComponent<TableProps> = ({
    isLoaded,
    skeletonRowsCount,
    columns,
    data,
    onSort,
    onRowClick,
    noDataMessage,
    isSelectionEnabled,
    onRowsSelect,
    rowIdentifierColumn,
    disableCheckboxes,
    canSelectRow
}) => {
    const memoizedColumns = useMemo(() => isSelectionEnabled ? [
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps, toggleRowSelected, rows: updatedRows }) => {
                const { checked, onChange } = getToggleAllRowsSelectedProps();

                const canToggleSomeCheckbox = canSelectRow ? updatedRows.some((row) => canSelectRow(row.original)) : updatedRows.length;
                const disabled = !isLoaded || disableCheckboxes || !canToggleSomeCheckbox;

                const toggleAllRowsSelection = canSelectRow ?
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                        updatedRows.forEach((row) => {
                            if (canSelectRow(row.original)) {
                                toggleRowSelected(row.id, event.currentTarget.checked);
                            }
                        });
                    } : onChange;

                const value = canSelectRow ?
                    canToggleSomeCheckbox && updatedRows.every((row) => row.isSelected || !canSelectRow(row.original)) :
                    checked;

                return (
                    <Checkbox
                        inputProperties={{
                            onChange: toggleAllRowsSelection,
                            value
                        }}
                        label=''
                        disabled={disabled}
                    />
                );
            },
            Cell: ({ row }) => {
                const { checked, onChange } = row.getToggleRowSelectedProps();

                return (
                    <Checkbox
                        inputProperties={{
                            onChange,
                            value: checked
                        }}
                        label=''
                        disabled={!isLoaded || disableCheckboxes || (canSelectRow && !canSelectRow(row.original))}
                    />
                );
            },
            headerCellClassName: local.checkboxCell,
            cellClassName: local.checkboxCell
        },
        ...columns
    ] : columns, [ isSelectionEnabled, columns ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds }
    } = useTable(
        {
            columns: memoizedColumns,
            data,
            getRowId: (row, relativeIndex) => rowIdentifierColumn ? row[rowIdentifierColumn] : relativeIndex
        },
        useRowSelect
    );

    useEffect(() => {
        if (isSelectionEnabled && onRowsSelect) {
            onRowsSelect(Object.keys(selectedRowIds));
        }
    }, [ selectedRowIds ]);

    const showDataCells = rows.length || isLoaded;

    return (
        <Fragment>
            <table className={local.table} {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map((headerGroup, groupIndex) =>
                            (
                                <tr {...headerGroup.getHeaderGroupProps()} key={groupIndex}>
                                    {
                                        headerGroup.headers.map((column, columnIndex) => {
                                            const { id, sortable, width, headerCellClassName, skeletonCellWidth } = column;

                                            return (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    className={headerCellClassName}
                                                    key={columnIndex}
                                                    width={(!showDataCells && skeletonCellWidth) ? skeletonCellWidth : width}
                                                    onClick={() => {
                                                        if (sortable && isLoaded && onSort) {
                                                            onSort(id);
                                                        }
                                                    }}>
                                                    {column.render('Header')}
                                                </th>
                                            );
                                        })
                                    }
                                </tr>
                            )
                        )
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        (showDataCells) ?
                            rows.map((row, rowIndex) => {
                                prepareRow(row);

                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        key={rowIndex}
                                        onClick={() => onRowClick && onRowClick(row.original)}
                                    >
                                        {
                                            row.cells.map((cell, cellIndex) => {
                                                const { getCellProps, render, column = {} } = cell;

                                                return (
                                                    <td {...getCellProps()} key={cellIndex} className={column.cellClassName}>
                                                        {
                                                            memoizedColumns[cellIndex].showTooltip ?
                                                                <TextWithTooltip>
                                                                    <Fragment>
                                                                        {render('Cell')}
                                                                    </Fragment>
                                                                </TextWithTooltip> :
                                                                render('Cell')
                                                        }
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            }) :
                            <SkeletonTableRows count={skeletonRowsCount} columns={memoizedColumns} />
                    }
                </tbody>
            </table>
            {
                isLoaded && rows.length === 0 &&
                <div className={local.noData}>
                    {
                        noDataMessage || <FormattedMessage id='common.noData' />
                    }
                </div>
            }
        </Fragment>
    );
};

export default Table;
