import { connect } from 'react-redux';

import * as actions from 'modules/transformationPipelines/actions';
import { getPipelinesListLayoutProps } from 'modules/transformationPipelines/selectors';
import PipelinesListLayout from './PipelinesListLayout';

const mapDispatchToProps = {
    loadData: actions.getPipelinesList,
    onSearch: actions.pipelinesListSearch,
    resetTable: actions.resetPipelinesList,
    loadMore: actions.loadMorePipelines
};

export default connect(getPipelinesListLayoutProps, mapDispatchToProps)(PipelinesListLayout);
