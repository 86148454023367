import service from 'modules/service';
import { addSyncError, removeSubmitError, removeSyncError } from 'modules/form/actions';
import { getErrorText, showErrorSnackbar } from '../utils';
import { HTTP_CODE } from '../constants';

export const validateApSql = (formName, fieldName, query) => async (dispatch, getState) => {
    try {
        const formValues = getState().form[formName]?.values;

        const path = fieldName.split('.');
        const fieldId = path.pop();

        let temp = formValues;
        for (const pathPart of path) {
            temp = isNaN(pathPart) ? temp[pathPart] : temp[parseInt(pathPart, 10)];
        }

        const variables = { ...temp };
        delete variables[fieldId];

        await service.api.validateApSql(query, variables);

        // clear previous errors
        dispatch(removeSyncError({
            form: formName,
            field: fieldName
        }));

        dispatch(removeSubmitError({
            form: formName,
            field: fieldName
        }));
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            dispatch(addSyncError({
                form: formName,
                field: fieldName,
                error: data.validationErrors[0].description
            }));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    }
};
