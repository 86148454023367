import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';

import { ingestionApiDefinitions } from 'modules/service/types';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { formatString } from 'modules/common/utils';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH, AUTOMATION_ID } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { SOURCE_STATE, SOURCE_TRANSIENT_STATE, SOURCES_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface SourcesListProps {
    items: Array<ingestionApiDefinitions['SourceSearchResponseDto']>;
    status: LoadStatus;
    sortOrder: SortOrder;
    sortBy: string;
    onSort: Function;
    goToSourceDetailsPage: Function;
}

class SourcesList extends Component<SourcesListProps> {
    public render() {
        const { items, status, onSort } = this.props;

        return (
            <Table
                columns={this.getTableConfig()}
                data={items}
                onSort={onSort}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={status === LOAD_STATUS.LOADED}
                onRowClick={this.onTableRowClick}
            />
        );
    }

    private getTableConfig = () => {
        const { sortBy, sortOrder } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.name' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'name',
                accessor: ({ id, ownerId, report, origin, state, transientState }) => {
                    let className = '';

                    const stateToDisplay = state !== SOURCE_STATE.ACTIVE ?
                        <FormattedMessage id={`sources.state.${state}`} /> :
                        transientState && transientState !== SOURCE_TRANSIENT_STATE.VALID ?
                            <FormattedMessage id={`sources.transientState.${transientState}`} /> :
                            null;

                    if (state !== SOURCE_STATE.ACTIVE) {
                        switch (state) {
                            case SOURCE_STATE.ARCHIVED:
                                className = local.archived;
                                break;
                            case SOURCE_STATE.TEST:
                                className = local.test;
                                break;
                            case SOURCE_STATE.PROMOTING:
                                className = local.promoting;
                                break;
                        }
                    } else {
                        switch (transientState) {
                            case SOURCE_TRANSIENT_STATE.DRAFT:
                                className = local.draft;
                                break;
                            case SOURCE_TRANSIENT_STATE.PENDING:
                                className = local.pending;
                                break;
                            case SOURCE_TRANSIENT_STATE.VALIDATING:
                                className = local.validating;
                                break;
                            case SOURCE_TRANSIENT_STATE.VALIDATION_FAILED:
                                className = local.validationFailed;
                                break;
                            case SOURCE_TRANSIENT_STATE.CANCELLED:
                                className = local.cancelled;
                                break;
                            case SOURCE_TRANSIENT_STATE.SUPERSEDED:
                                className = local.superseded;
                                break;
                        }
                    }

                    return (
                        <Fragment>
                            <a href={`#${formatString(SOURCES_ROUTES.DETAILS, ownerId, id)}`} className={local.name}>
                                {`${origin}_${report}`}
                            </a>
                            {
                                stateToDisplay &&
                                <span className={classnames(local.label, className)}>
                                    {stateToDisplay}
                                </span>
                            }
                        </Fragment>
                    );
                },
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '74%',
                skeletonCellWidth: 800,
                sortable: true,
                showTooltip: true,
                cellClassName: AUTOMATION_ID.SOURCES_TABLE_NAME
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='common.owner' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                accessor: 'ownerName',
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 116,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.SOURCES_TABLE_OWNER}`
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'lastUpdated',
                accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 161,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: `secondary-column-cell ${AUTOMATION_ID.SOURCES_TABLE_LAST_UPDATED}`
            }
        ];
    }

    private onTableRowClick = ({ id, ownerId }) => {
        const { status, goToSourceDetailsPage } = this.props;

        if (status === LOAD_STATUS.LOADED) {
            goToSourceDetailsPage(id, ownerId);
        }
    }
}

export default SourcesList;
