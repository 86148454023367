import React, { Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { LOAD_STATUS } from 'modules/common/constants';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import { getClientDetails, getClientDetailsLoadStatus } from '../../selectors';

import local from './local.module.scss';

const ClientDetails: FunctionComponent = () => {
    const {
        name,
        projectId,
        projectRegion,
        region,
        dataCollection,
        adverityCredentials,
        cloudComposerEnvironment
    } = useSelector(getClientDetails);
    const loadStatus = useSelector(getClientDetailsLoadStatus);

    const isLoaded = loadStatus === LOAD_STATUS.LOADED;

    return (
        <Fragment>
            <div className={local.title}>
                {isLoaded ? name : <SkeletonItem width='160px' height='24px' />}
            </div>
            <div className={local.secondaryTitle}>
                <FormattedMessage id='admin.projectId' />
            </div>
            <div className={local.propertyValue}>
                {isLoaded ? projectId : <SkeletonItem width='320px' height='16px' />}
            </div>
            <div className={local.secondaryTitle}>
                <FormattedMessage id='admin.projectRegion' />
            </div>
            <div className={local.propertyValue}>
                {isLoaded ? projectRegion : <SkeletonItem width='320px' height='16px' />}
            </div>
            <div className={local.secondaryTitle}>
                <FormattedMessage id='common.bucketName' />
            </div>
            <div className={local.propertyValue}>
                {isLoaded ? dataCollection?.bucketName : <SkeletonItem width='320px' height='16px' />}
            </div>
            <div className={local.secondaryTitle}>
                <FormattedMessage id='common.serviceAccount' />
            </div>
            <div className={local.propertyValue}>
                {isLoaded ? '*'.repeat(12) : <SkeletonItem width='320px' height='16px' />}
            </div>
            <div className={local.secondaryTitle}>
                <FormattedMessage id='admin.schemaEvolutionDagResourcesRegion' />
            </div>
            <div className={local.propertyValue}>
                {isLoaded ? region : <SkeletonItem width='320px' height='16px' />}
            </div>
            <div className={local.secondaryTitle}>
                <FormattedMessage id='admin.transformationServiceAccount' />
            </div>
            <div className={local.propertyValue}>
                {isLoaded ? '*'.repeat(12) : <SkeletonItem width='320px' height='16px' />}
            </div>
            {
                isLoaded && adverityCredentials &&
                <Fragment>
                    <div className={local.secondaryTitle}>
                        <FormattedMessage id='admin.adverityUsername' />
                    </div>
                    <div className={local.propertyValue}>
                        {adverityCredentials.username}
                    </div>
                    <div className={local.secondaryTitle}>
                        <FormattedMessage id='admin.adverityPassword' />
                    </div>
                    <div className={local.propertyValue}>
                        {'*'.repeat(12)}
                    </div>
                </Fragment>
            }
            <div className={local.secondaryTitle}>
                <FormattedMessage id='admin.composerEnvironmentName' />
            </div>
            <div className={local.propertyValue}>
                {isLoaded ? cloudComposerEnvironment : <SkeletonItem width='320px' height='16px' />}
            </div>
        </Fragment>
    );
};

export default ClientDetails;
