import React, { Fragment, FunctionComponent, useCallback, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ExpandMore } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { push } from 'redux-first-history';

import { AUTOMATION_ID } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import { getRouterPath } from 'modules/common/selectors';
import { getAdminMenuItems } from '../../selectors';

import local from './local.module.scss';

const AdminMenu: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [ setAnchorEl ]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [ setAnchorEl ]);

    const items: any[] = useSelector(getAdminMenuItems);
    const routerPath = useSelector(getRouterPath);

    const selectedItemIndex = items.findIndex(({ route }) => routerPath.startsWith(route));
    const isOpen = Boolean(anchorEl);

    return (
        <Fragment>
            <div id={AUTOMATION_ID.ADMIN_MENU} className={local.menuAnchor} onClick={handleClick}>
                <span className={local.menuName}>
                    <FormattedMessage id='admin.admin' />
                </span>
                <ExpandMore className={classnames({ [local.iconOpen]: isOpen })} />
            </div>
            <Menu
                anchorEl={anchorEl}
                keepMounted={true}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ paper: local.paper }}
            >
                {
                    items.map(({ id, messageId, route }, index) => (
                        <MenuItem
                            id={id}
                            key={index}
                            onClick={(event) => {
                                if (!event.ctrlKey) {
                                    dispatch(push(route));
                                    handleClose();
                                }
                            }}
                            classes={{
                                root: local.item,
                                focusVisible: local.focusedItem,
                                selected: local.selectedItem
                            }}
                            selected={index === selectedItemIndex}
                        >
                            <a href={`#${route}`}>
                                <FormattedMessage id={messageId} />
                            </a>
                        </MenuItem>
                    ))
                }
            </Menu>
        </Fragment>
    );
};

export default AdminMenu;
