import { intl } from 'modules/i18n';

export const REDUCER_NAME = 'feeds';
export const FEEDS_LIST_PAGE_SIZE = 50;
export const FEED_RUNS_LIST_PAGE_SIZE = 50;

export const FEED_DETAILS_VIEW = {
    MAIN: 'MAIN',
    ADDITIONAL: 'ADDITIONAL'
} as const;

export const FEED_STATE = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    ARCHIVED: 'ARCHIVED',
    TEST: 'TEST'
};

export const FEED_VERSION_STATE = {
    PENDING: 'PENDING',
    VALID: 'VALID',
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    SUPERSEDED: 'SUPERSEDED',
    CANCELLED: 'CANCELLED'
};

export const FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER = Object.freeze({
    TOGGLE_PAUSE: 'TOGGLE_PAUSE',
    TOGGLE_STATE: 'TOGGLE_STATE'
});

export const METHOD_SWITCH_OPTIONS_VALUES = {
    SCHEDULE: 'SCHEDULE',
    MANUAL: 'MANUAL'
} as const;

export const AD_HOC_RUN_REPORT_RANGE = {
    CUSTOM_RANGE: 'CUSTOM_RANGE',
    COLLECTION_OFFSET_TEMPLATE: 'COLLECTION_OFFSET_TEMPLATE'
};

export const FEEDS_ROUTES = {
    LIST: '/feeds',
    DETAILS: '/feeds/view/{0}/{1}',
    NEW: '/feeds/add',
    EDIT: '/feeds/edit/{0}/{1}',
    PENDING: '/feeds/pending/{0}/{1}',
    RUNS: '/feeds/runs/{0}'
};

export const FEED_EXECUTION_STATUS = {
    EMPTY: 'EMPTY',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
    SUCCEEDED: 'SUCCEEDED',
    STARTED: 'STARTED',
    UNRECONCILED: 'UNRECONCILED'
};

export const FEED_EXECUTION_FAILED_STATUS = {
    FAILED: 'FAILED',
    UNRECONCILED: 'UNRECONCILED'
};

export const FEED_EXECUTION_FAILED_STATUS_FILTER_ITEMS = Object.keys(FEED_EXECUTION_FAILED_STATUS).map((status) => ({
    id: status,
    name: intl.formatMessage({ id: `feeds.executionStatus.${status}` })
}));

export const FEED_RERUN_STATUS = {
    RESTARTED: 'RESTARTED',
    FAILED: 'FAILED',
    RUNNING: 'RUNNING'
};

export const FEED_SCHEDULES_MAX_COUNT = 10;

export const FEED_TARGETS_MAX_COUNT = 10;
