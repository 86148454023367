import { connect } from 'react-redux';

import * as actions from 'modules/sources/actions';
import { getSourcesListLayoutProps } from 'modules/sources/selectors';
import SourcesListLayout from './SourcesListLayout';

const mapDispatchToProps = {
    loadData: actions.getSourcesList,
    onSearch: actions.sourcesListSearch,
    resetTable: actions.resetSourcesList,
    loadMore: actions.loadMoreSources
};

export default connect(getSourcesListLayoutProps, mapDispatchToProps)(SourcesListLayout);
