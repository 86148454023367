import * as t from '../actionTypes';

export const setGroupsListItems = (data) => ({
    type: t.SET_GROUPS_LIST_ITEMS,
    payload: data
});

export const appendGroupsListItems = (data) => ({
    type: t.APPEND_GROUPS_LIST_ITEMS,
    payload: data
});

export const setGroupsListPage = (data) => ({
    type: t.SET_GROUPS_LIST_PAGE,
    payload: data
});

export const setGroupsListCount = (data) => ({
    type: t.SET_GROUPS_LIST_COUNT,
    payload: data
});

export const setGroupsListLoadStatus = (data) => ({
    type: t.SET_GROUPS_LIST_LOAD_STATUS,
    payload: data
});

export const setGroupsListSortColumn = (data) => ({
    type: t.SET_GROUPS_LIST_SORT_COLUMN,
    payload: data
});

export const setGroupsListSortOrder = (data) => ({
    type: t.SET_GROUPS_LIST_SORT_ORDER,
    payload: data
});

export const setGroupsListSearchText = (data) => ({
    type: t.SET_GROUPS_LIST_SEARCH_TEXT,
    payload: data
});

export const resetGroupsList = () => ({
    type: t.RESET_GROUPS_LIST
});

export const resetGroupForm = () => ({
    type: t.RESET_GROUP_FORM
});

export const addGroupClient = () => ({
    type: t.ADD_GROUP_CLIENT
});

export const updateGroupClient = (data) => ({
    type: t.UPDATE_GROUP_CLIENT,
    payload: data
});

export const removeGroupClient = (data) => ({
    type: t.REMOVE_GROUP_CLIENT,
    payload: data
});

export const addGroupOwner = () => ({
    type: t.ADD_GROUP_OWNER
});

export const updateGroupOwner = (data) => ({
    type: t.UPDATE_GROUP_OWNER,
    payload: data
});

export const removeGroupOwner = (data) => ({
    type: t.REMOVE_GROUP_OWNER,
    payload: data
});

export const setGroupFormOperationInProgress = (data) => ({
    type: t.SET_GROUP_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setGroupDetailsLoadStatus = (data) => ({
    type: t.SET_GROUP_DETAILS_LOAD_STATUS,
    payload: data
});

export const setGroupDetails = (data) => ({
    type: t.SET_GROUP_DETAILS,
    payload: data
});

export const resetGroupDetails = () => ({
    type: t.RESET_GROUP_DETAILS
});

export const initClientsAndOwners = (data) => ({
    type: t.INIT_CLIENTS_AND_OWNERS,
    payload: data
});

export const setGroupDetailsOperationInProgress = (data) => ({
    type: t.SET_GROUP_DETAILS_OPERATION_IN_PROGRESS,
    payload: data
});

export const removeGroupMembership = (data) => ({
    type: t.REMOVE_GROUP_MEMBERSHIP,
    payload: data
});

export const openAddMembershipsDialog = () => ({
    type: t.OPEN_ADD_MEMBERSHIPS_DIALOG
});

export const closeAddMembershipsDialog = () => ({
    type: t.CLOSE_ADD_MEMBERSHIPS_DIALOG
});
