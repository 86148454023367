export const REDUCER_NAME = 'clients';
export const CLIENTS_LIST_PAGE_SIZE = 50;
export const CLIENT_MEMBERSHIPS_LIST_PAGE_SIZE = 50;
export const CLIENT_GROUPS_LIST_PAGE_SIZE = 50;

export const CLIENTS_ROUTES = {
    LIST: '/admin/clients',
    NEW: '/admin/clients/add',
    DETAILS: '/admin/clients/view/{0}',
    EDIT: '/admin/clients/edit/{0}'
};
