import React, { FunctionComponent, useState, useCallback } from 'react';
import classnames from 'classnames';
import Dialog from '@mui/material/Dialog';

import { ConfirmOptions } from 'modules/common/interfaces';

import local from './local.module.scss';

type ConfirmDialogProps = {
    resolve: Function;
    cleanup: Function;
} & ConfirmOptions;

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
    title,
    messages,
    confirmButtonText = 'OK',
    confirmButtonClass,
    cancelButtonText = 'Cancel',
    cancelButtonClass,
    resolve,
    cleanup
}) => {
    const [open, setOpen] = useState(true);

    const close = useCallback((value) => {
        setOpen(false);
        resolve(value);

        setTimeout(() => {
            cleanup();
        }, 1000);
    }, [ setOpen, resolve, cleanup ]);

    return (
        <Dialog open={open}>
            <div className={local.card}>
                <div className={local.title}>
                    {title}
                </div>
                {messages.map((message, index) => message ? <div className={local.paragraph} key={index}>{message}</div> : null)}
                <div className={classnames(local.buttonsContainer, 'container-row')}>
                    <div className='ls-button'>
                        <button className={cancelButtonClass} onClick={() => close(false)}>{cancelButtonText}</button>
                    </div>
                    <div className='ls-button'>
                        <button className={confirmButtonClass} onClick={() => close(true)}>{confirmButtonText}</button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
