import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, InjectedFormProps } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import { RouteComponentProps } from 'modules/common/interfaces';
import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { LOAD_STATUS, SECRET_VALUE_PLACEHOLDER } from 'modules/common/constants';
import Input from 'modules/common/components/Input';
import { getClientDetails, getClientDetailsLoadStatus, getClientFormOperationInProgress } from '../../selectors';
import { getClientDetails as loadData, resetClientDetails } from '../../actions';

import local from './local.module.scss';

interface RouteMatchParams {
    clientId: string;
}

interface ClientFormOwnProps {
    isEditMode: boolean;
}

type ClientFormProps = ClientFormOwnProps & InjectedFormProps<{}, ClientFormOwnProps> & Pick<RouteComponentProps<RouteMatchParams>, 'match'>;

interface TextFieldProps {
    fieldName: string;
    labelId: string;
    placeholderId: string;
    maxLength?: number;
    disabled?: boolean;
    multiline?: boolean;
    password?: boolean;
    isLoading?: boolean;
}

const TextFieldRenderer = ({ input, meta, placeholder, maxLength, multiline, disabled }) => (
    <Fragment>
        <Input
            inputProperties={input}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            multiline={multiline}
            className={classnames({ 'error-input': Boolean(meta.error) })}
        />
        {
            meta.error &&
            <div className='form-error-message'>
                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
            </div>
        }
    </Fragment>
);

const PasswordFieldRenderer = ({ input, meta, placeholder, maxLength, disabled }) => (
    <Fragment>
        <Input
            inputProperties={{
                ...input,
                onPaste: (event) => {
                    if (input.value === SECRET_VALUE_PLACEHOLDER) {
                        // clear placeholder value, pasted text will be set by default browser behaviour
                        event.target.value = '';
                    }
                },
                onInput: (event) => {
                    if (input.value === SECRET_VALUE_PLACEHOLDER &&
                        event.nativeEvent.inputType !== 'insertFromPaste') {
                        event.target.value = event.nativeEvent.data;
                    }
                }
            }}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            className={classnames({ 'error-input': Boolean(meta.error) })}
            password={true}
        />
        {
            meta.error &&
            <div className='form-error-message'>
                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
            </div>
        }
    </Fragment>
);

const TextField = ({
    fieldName,
    labelId,
    placeholderId,
    maxLength,
    disabled = false,
    multiline = false,
    password = false,
    isLoading = false
}: TextFieldProps) => {
    const intl = useIntl();

    const placeholderText = isLoading ?
        intl.formatMessage({ id: 'common.loading' }) :
        intl.formatMessage({ id: placeholderId });

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id={labelId} />
            </div>
            <Field
                name={fieldName}
                component={password ? PasswordFieldRenderer : TextFieldRenderer}
                placeholder={placeholderText}
                maxLength={maxLength}
                multiline={multiline}
                disabled={disabled || isLoading}
            />
        </div>
    );
};

const ClientForm: FunctionComponent<ClientFormProps> = ({
    isEditMode,
    change,
    handleSubmit,
    match,
    reset
}) => {
    const data = useSelector(getClientDetails);
    const dataLoadStatus = useSelector(getClientDetailsLoadStatus);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isEditMode) {
            reset();
            dispatch(resetClientDetails());
            dispatch(loadData(match.params.clientId));
        }
    }, [ match.params.clientId ]);

    useDidUpdateEffect(() => {
        if (isEditMode && Object.keys(data).length) {
            const {
                name,
                projectId,
                projectRegion,
                region,
                cloudComposerEnvironment,
                adverityCredentials,
                dataCollection: {
                    bucketName
                }
            } = data;

            change('name', name);
            change('projectId', projectId);
            change('projectRegion', projectRegion);
            change('region', region);
            change('bucketName', bucketName);
            change('cloudComposerEnvironment', cloudComposerEnvironment);
            change('serviceAccount', SECRET_VALUE_PLACEHOLDER);
            change('transformationServiceAccount', SECRET_VALUE_PLACEHOLDER);

            if (adverityCredentials) {
                change('username', adverityCredentials.username);
                change('password', SECRET_VALUE_PLACEHOLDER);
            }
        }
    }, [ data ]);

    const isOperationInProgress = useSelector(getClientFormOperationInProgress);
    const isLoading = isEditMode && (dataLoadStatus !== LOAD_STATUS.LOADED);
    const isEditDisabled = isEditMode && data.hasDependencies;

    return (
        <form id='clientForm' onSubmit={handleSubmit} autoComplete='off'>
            <TextField
                fieldName='name'
                labelId='common.name'
                placeholderId='common.name'
                maxLength={255}
                disabled={isOperationInProgress}
                isLoading={isLoading}
            />
            <TextField
                fieldName='projectId'
                labelId='admin.projectId'
                placeholderId='admin.projectId'
                maxLength={30}
                disabled={isOperationInProgress || isEditDisabled}
                isLoading={isLoading}
            />
            <TextField
                fieldName='projectRegion'
                labelId='admin.projectRegion'
                placeholderId='admin.projectRegion'
                maxLength={255}
                disabled={isOperationInProgress || isEditDisabled}
                isLoading={isLoading}
            />
            <TextField
                fieldName='bucketName'
                labelId='common.bucketName'
                placeholderId='common.bucketName'
                maxLength={222}
                disabled={isOperationInProgress || isEditDisabled}
                isLoading={isLoading}
            />
            <TextField
                fieldName='serviceAccount'
                labelId='common.serviceAccount'
                placeholderId='common.serviceAccount'
                disabled={isOperationInProgress}
                password={true}
                isLoading={isLoading}
            />
            <TextField
                fieldName='region'
                labelId='admin.schemaEvolutionDagResourcesRegion'
                placeholderId='admin.schemaEvolutionDagResourcesRegion'
                maxLength={255}
                disabled={isOperationInProgress || isEditDisabled}
                isLoading={isLoading}
            />
            <TextField
                fieldName='transformationServiceAccount'
                labelId='admin.transformationServiceAccount'
                placeholderId='admin.transformationServiceAccount'
                disabled={isOperationInProgress}
                password={true}
                isLoading={isLoading}
            />
            <TextField
                fieldName='username'
                labelId='admin.adverityUsername'
                placeholderId='admin.adverityUsername'
                disabled={isOperationInProgress}
                isLoading={isLoading}
            />
            <TextField
                fieldName='password'
                labelId='admin.adverityPassword'
                placeholderId='admin.adverityPassword'
                disabled={isOperationInProgress}
                password={true}
                isLoading={isLoading}
            />
            <TextField
                fieldName='cloudComposerEnvironment'
                labelId='admin.composerEnvironmentName'
                placeholderId='admin.composerEnvironmentName'
                maxLength={64}
                disabled={isOperationInProgress}
                isLoading={isLoading}
            />
        </form>
    );
};

export default ClientForm;
