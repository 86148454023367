import { connect } from 'react-redux';

import * as actions from '../../actions';
import { getInstanceTriggerProps } from '../../selectors';
import InstanceTrigger from './InstanceTrigger';

const mapDispatchToProps = {
    updateTrigger: actions.updateTrigger,
    removeTrigger: actions.removeInstanceTrigger,
    validateApSql: actions.validateTriggerConfigurationApSql
};

export default connect(getInstanceTriggerProps, mapDispatchToProps)(InstanceTrigger);
