import React, { Fragment, FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import SelectInput from 'modules/common/components/SelectInput';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import { AUTOMATION_ID } from 'modules/common/constants';
import RolesSelectField from 'modules/admin/components/RolesSelectField';
import { ROLE_TYPE } from 'modules/admin/constants';
import { getMemberDetailsOperationInProgress, getMembershipDialogOwners } from '../../selectors';
import { closeOwnerMembershipDialog } from '../../actions';

import local from './local.module.scss';

const OwnerSelect = ({ input, disabled, items }) => {
    const intl = useIntl();

    return (
        <Fragment>
            <div className={local.label}>
                <FormattedMessage id='common.owner' />
            </div>
            <SelectInput
                inputId={AUTOMATION_ID.MEMBER_OWNER}
                placeholder={intl.formatMessage({ id: 'admin.selectOwner' })}
                items={items}
                inputProperties={input}
                disabled={disabled}
                searchable={true}
                trackTiming={true}
                trackingName='Member owner'
            />
        </Fragment>
    );
};

const OwnerMembershipForm: FunctionComponent<InjectedFormProps> = ({ handleSubmit }) => {
    const dispatch = useAppDispatch();

    const close = useCallback(() => {
        dispatch(closeOwnerMembershipDialog());
    }, []);

    const ownerId = useReduxFormValue('addOwnerForm', 'ownerId');
    const roles = useReduxFormValue('addOwnerForm', 'roles');

    const isOperationInProgress = useSelector(getMemberDetailsOperationInProgress);
    const owners = useSelector(getMembershipDialogOwners);

    return (
        <Fragment>
            <form id='addOwnerForm' onSubmit={handleSubmit} autoComplete='off'>
                <Field
                    name='ownerId'
                    disabled={isOperationInProgress}
                    component={OwnerSelect}
                    items={owners}
                />
                <div className={local.label}>
                    <FormattedMessage id='admin.roles' />
                </div>
                <RolesSelectField
                    fieldName='roles'
                    type={ROLE_TYPE.OWNER}
                    disabled={isOperationInProgress}
                />
            </form>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button disabled={isOperationInProgress} className='btn-flat' onClick={close}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <FormSubmitButton
                    form='addOwnerForm'
                    width={130}
                    disabled={!ownerId || !roles?.length || isOperationInProgress}
                    text={<FormattedMessage id='common.submit' />}
                    isSubmitting={isOperationInProgress}
                />
            </div>
        </Fragment>
    );
};

export default OwnerMembershipForm;
