import * as t from '../actionTypes';

export const setData = (data) => ({
    type: t.SET_JWT_DATA,
    payload: data
});

export const setUserProfile = (data) => ({
    type: t.SET_USER_PROFILE,
    payload: data
});

export const setSelectedClientId = (clientId) => ({
    type: t.SET_SELECTED_CLIENT,
    payload: clientId
});

export const setDataLoaded = (status) => ({
    type: t.SET_DATA_LOADED,
    payload: status
});

export const setAccessAllowed = (status) => ({
    type: t.SET_ACCESS_ALLOWED,
    payload: status
});

export const setClientIdToCheckOnLoad = (clientId) => ({
    type: t.SET_CLIENT_TO_CHECK_ON_LOAD,
    payload: clientId
});
