import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import * as actions from '../../actions';
import { getSourceFormProps } from '../../selectors';
import SourceForm from './SourceForm';

const mapDispatchToProps = {
    getCollectors: actions.getCollectorsList,
    setCollectors: actions.setSourceCollectors,
    resetForm: actions.resetSourceForm,
    resetData: actions.resetSourceDetails,
    getSourceVersionDataForEditing: actions.getSourceVersionDataForEditing,
    getManifests: actions.getManifests,
    populateFormValues: actions.populateSourceFormValues
};

export default reduxForm({
    form: 'sourceForm'
})(connect(getSourceFormProps, mapDispatchToProps)(SourceForm) as any) as any;
