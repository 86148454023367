import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import service from 'modules/service';
import { intl } from 'modules/i18n';
import { showErrorSnackbar } from 'modules/common/utils';
import { EVENT_CATEGORY, PERMISSIONS } from 'modules/common/constants';
import ErrorPage from 'modules/common/components/ErrorPage';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { Client, RouteComponentProps } from 'modules/common/interfaces';
import { AUTOMATION_ID } from 'modules/common/constants';
import InstanceForm from '../InstanceForm';
import { SAVE_MODE } from '../../constants';

import local from './local.module.scss';

interface InstanceFormLayoutProps {
    isEditMode: boolean;
    isOperationInProgress: boolean;
    goToInstancesListPage: Function;
    goToInstanceDetailsPage: Function;
    client: Client;
    addInstance: Function;
    editInstance: Function;
    clearSubmitErrors: Function;
    displayName: string;
    isDataLoading: boolean;
}

interface InstanceFormLayoutState {
    saveMode: string;
}

interface RouteMatchParams {
    clientId: string;
    instanceId: string;
}

class InstanceFormLayout extends Component<InstanceFormLayoutProps & RouteComponentProps<RouteMatchParams>, InstanceFormLayoutState> {
    constructor(props) {
        super(props);

        this.state = {
            saveMode: SAVE_MODE.NORMAL
        };
    }

    public render() {
        const { canManage, canViewFeeds } = this.checkAccess();

        if (!canManage) {
            return (
                <ErrorPage error='403' />
            );
        }

        const { isEditMode, isDataLoading, isOperationInProgress, match } = this.props;
        const { saveMode } = this.state;

        const isSavingInProgress = isOperationInProgress && (saveMode === SAVE_MODE.NORMAL);
        const isSavingAsNewInProgress = isOperationInProgress && (saveMode === SAVE_MODE.NEW);
        const isSavingAsDraftInProgress = isOperationInProgress && (saveMode === SAVE_MODE.DRAFT);

        return (
            <Fragment>
                {this.renderBreadcrumbs()}
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            <FormattedMessage id={isEditMode ? 'instances.editInstance' : 'instances.newInstance'} />
                        </div>
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <button
                                    disabled={isOperationInProgress}
                                    className='btn-flat'
                                    onClick={this.onCancelButtonClick}
                                >
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <FormSubmitButton
                                id={AUTOMATION_ID.INSTANCE_FORM_SAVE_AS_DRAFT_BTN}
                                form='instanceForm'
                                width={126}
                                onClick={this.onSaveAsDraftButtonClick}
                                disabled={isDataLoading || isOperationInProgress}
                                text={<FormattedMessage id='common.saveAsDraft' />}
                                isSubmitting={isSavingAsDraftInProgress}
                                secondary={true}
                            />
                            {
                                isEditMode &&
                                <FormSubmitButton
                                    id={AUTOMATION_ID.INSTANCE_FORM_SAVE_AS_NEW_BTN}
                                    form='instanceForm'
                                    width={126}
                                    onClick={this.onSaveAsNewButtonClick}
                                    disabled={isDataLoading || isOperationInProgress}
                                    text={<FormattedMessage id='common.saveAsNew' />}
                                    isSubmitting={isSavingAsNewInProgress}
                                />
                            }
                            <FormSubmitButton
                                id={AUTOMATION_ID.INSTANCE_FORM_SAVE_BTN}
                                form='instanceForm'
                                width={79}
                                onClick={this.onSaveButtonClick}
                                disabled={isDataLoading || isOperationInProgress}
                                text={<FormattedMessage id='common.submit' />}
                                isSubmitting={isSavingInProgress}
                            />
                        </div>
                    </div>
                    <InstanceForm
                        onSubmit={this.onFormSubmit}
                        onSubmitFail={this.onFormSubmitFail}
                        isEditMode={isEditMode}
                        match={match}
                        canViewFeeds={canViewFeeds}
                    />
                </div>
            </Fragment>
        );
    }

    private checkAccess = () => {
        const { client } = this.props;

        return {
            canManage: client.permissions.includes(PERMISSIONS.INSTANCES.MANAGE),
            canViewFeeds: client.permissions.includes(PERMISSIONS.FEEDS.VIEW)
        };
    }

    private onFormSubmit = (values) => {
        const {
            isEditMode,
            editInstance,
            addInstance,
            clearSubmitErrors
        } = this.props;

        const { saveMode } = this.state;

        clearSubmitErrors('instanceForm');

        return isEditMode ?
            editInstance(values, saveMode) :
            addInstance(values, saveMode);
    }

    private onFormSubmitFail = () => {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));
    }

    private renderBreadcrumbs = () => {
        const { displayName, goToInstancesListPage, isEditMode } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        label: <FormattedMessage id='instances.allInstances' />,
                        onClick: () => { goToInstancesListPage(); }
                    },
                    {
                        label: isEditMode ? displayName : (<FormattedMessage id='instances.newInstance' />)
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private onSaveButtonClick = () => {
        this.setState({ saveMode: SAVE_MODE.NORMAL });
    }

    private onSaveAsDraftButtonClick = () => {
        this.setState({ saveMode: SAVE_MODE.DRAFT });
    }

    private onSaveAsNewButtonClick = () => {
        this.setState({ saveMode: SAVE_MODE.NEW });
    }

    private onCancelButtonClick = () => {
        const { isEditMode, goToInstanceDetailsPage, goToInstancesListPage, match } = this.props;

        if (isEditMode) {
            const { instanceId } = match.params;
            goToInstanceDetailsPage(instanceId);

            service.analytics.trackEvent('Cancel instance edit', EVENT_CATEGORY.INSTANCES);
        } else {
            goToInstancesListPage();

            service.analytics.trackEvent('Cancel instance creation', EVENT_CATEGORY.INSTANCES);
        }
    }
}

export default InstanceFormLayout;
