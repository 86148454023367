import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';

import { ingestionApiDefinitions } from 'modules/service/types';
import { LOAD_STATUS, OVERRIDABLE_FIELD_SUFFIX, AUTOMATION_ID, INGEST_MODE } from 'modules/common/constants';
import { GO_LIVE_TYPE, SOURCE_STATE } from 'modules/sources/constants';
import { LoadStatus, RouteComponentProps } from 'modules/common/interfaces';
import SelectInput from 'modules/common/components/SelectInput';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import GoLiveSection from 'modules/sources/components/GoLiveSection';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import SourceSchema from '../SourceSchema';
import SourceIngestionSettings from '../SourceIngestionSettings';
import SourceBaseConfiguration from '../SourceBaseConfiguration';
import AdverityCollectorSpecificConfigurationFields from '../AdverityCollectorSpecificConfigurationFields';

import local from '../SourceFormLayout/local.module.scss';

interface SourceFormProps {
    resetForm: Function;
    resetData: Function;
    getCollectors: Function;
    setCollectors: Function;
    getSourceVersionDataForEditing: Function;
    collectorsLoadStatus: LoadStatus;
    collectors: Array<ingestionApiDefinitions['CollectorSummaryResponseDto']>;
    manifestsLoadStatus: LoadStatus;
    manifests: { [key: string]: ingestionApiDefinitions['ManifestDto'] };
    isOperationInProgress: boolean;
    isEditMode: boolean;
    data: ingestionApiDefinitions['SourceVersionDetailDto'];
    getManifests: Function;
    dataToClone?: ingestionApiDefinitions['SourceVersionDetailDto'];
    testMode: boolean;
    populateFormValues: (data: ingestionApiDefinitions['SourceVersionDetailDto']) => void;
}

interface RouteMatchParams {
    ownerId: string;
    sourceId: string;
    versionId: string;
}

const { REACT_APP_ADVERITY_COLLECTOR_ID } = process.env;

class SourceForm extends Component<SourceFormProps & InjectedFormProps<{}, SourceFormProps> & RouteComponentProps<RouteMatchParams>> {
    public componentDidMount() {
        const {
            isEditMode,
            match,
            resetForm,
            getCollectors,
            getSourceVersionDataForEditing,
            dataToClone,
            testMode,
            getManifests,
            resetData,
            change,
            populateFormValues
        } = this.props;

        resetForm();

        if (isEditMode) {
            const { ownerId, sourceId, versionId } = match.params;

            resetData();
            getSourceVersionDataForEditing(ownerId, sourceId, versionId);
        } else {
            if (dataToClone) {
                getManifests(dataToClone.dataCollectorConfigurations?.map(({ collectorId }) => collectorId));

                populateFormValues(dataToClone);
            } else {
                getCollectors();

                // set default option for ingest mode
                change('ingestMode', INGEST_MODE.APPEND);
            }

            if (testMode) {
                change('goLiveType', GO_LIVE_TYPE.NOW);
            }
        }
    }

    public componentDidUpdate(prevProps) {
        const {
            isEditMode,
            match,
            resetData,
            resetForm,
            getSourceVersionDataForEditing,
            change,
            data,
            populateFormValues
        } = this.props;

        if (isEditMode) {
            const { match: { params: { ownerId: prevOwnerId, sourceId: prevSourceId, versionId: prevVersionId } }, data: prevData } = prevProps;
            const { params: { ownerId, sourceId, versionId } } = match;

            if ((ownerId !== prevOwnerId) || (sourceId !== prevSourceId) || (versionId !== prevVersionId)) {
                resetForm();
                resetData();
                getSourceVersionDataForEditing(ownerId, sourceId, versionId);

                change('ownerId', ownerId);
            }

            if (!Object.keys(prevData).length && data !== prevData) {
                populateFormValues(data);

                if (data.sourceState === SOURCE_STATE.TEST) {
                    change('goLiveType', GO_LIVE_TYPE.NOW);
                }
            }
        }
    }

    public render() {
        const {
            isEditMode,
            isOperationInProgress,
            handleSubmit,
            collectorsLoadStatus,
            collectors,
            manifestsLoadStatus,
            testMode,
            change
        } = this.props;

        return (
            <Fragment>
                {
                    this.renderTestStateLabel()
                }
                <form id='sourceForm' onSubmit={handleSubmit} autoComplete='off'>
                    {this.renderGlobalError()}
                    <SourceBaseConfiguration isEditMode={isEditMode} change={change} />
                    <div className={local.bigLabel}>
                        <FormattedMessage id='sources.schemaAndIngestion' />
                    </div>
                    <SourceSchema change={change} />
                    <SourceIngestionSettings isEditMode={isEditMode} change={change} />
                    <Field
                        name='dataCollectorConfigurations'
                        collectorsLoadStatus={collectorsLoadStatus}
                        collectors={collectors}
                        isOperationInProgress={isOperationInProgress}
                        manifestsLoadStatus={manifestsLoadStatus}
                        component={this.renderCollectorsField}
                    />
                    {manifestsLoadStatus === LOAD_STATUS.LOADING && (<SkeletonSectionLoaderIndicator />)}
                    {manifestsLoadStatus === LOAD_STATUS.LOADED && this.getDynamicSections()}
                    <GoLiveSection
                        isEditMode={isEditMode}
                        isOperationInProgress={isOperationInProgress}
                        isTestMode={testMode}
                    />
                </form>
            </Fragment>
        );
    }

    private renderGlobalError = () => {
        const { error } = this.props;

        return error ?
            <div className={local.globalError}>
                <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
            </div> :
            null;
    }

    private getDynamicSections = () => {
        const {
            isEditMode,
            isOperationInProgress,
            change,
            manifests,
            manifestsLoadStatus,
            collectors,
            dataToClone,
            data
        } = this.props;

        const sections: JSX.Element[] = [];

        for (const collectorId in manifests) {
            if (manifests.hasOwnProperty(collectorId)) {
                let defaultValues: any;
                if ((isEditMode && data.dataCollectorConfigurations) ||
                    (!isEditMode && dataToClone?.dataCollectorConfigurations)) {
                    const { dataCollectorConfigurations } = isEditMode ? data : dataToClone!;
                    const configuration = dataCollectorConfigurations.find(({ collectorId: id }) => id === collectorId);

                    if (configuration) {
                        defaultValues = {};
                        configuration.configurationContext.forEach(({ key, value, overridable }) => {
                            defaultValues[key] = value;
                            defaultValues[`${key}-${OVERRIDABLE_FIELD_SUFFIX}`] = overridable;
                        });
                    }
                }

                sections.push(
                    <Fragment key={collectorId}>
                        <div className={local.collectorTitle}>
                            {collectors.find(({ id }) => id === collectorId)!.description}
                        </div>
                        <DynamicFieldsSection
                            change={change}
                            config={manifests[collectorId]}
                            disabled={isOperationInProgress || (manifestsLoadStatus !== LOAD_STATUS.LOADED)}
                            prefix={collectorId}
                            editMode={Boolean(isEditMode && defaultValues)}
                            defaultValues={defaultValues}
                            renderEditableCheckboxes={true}
                            form='sourceForm'
                        />
                    </Fragment>
                );

                if (collectorId === REACT_APP_ADVERITY_COLLECTOR_ID) {
                    sections.push(
                        <AdverityCollectorSpecificConfigurationFields change={change} editMode={isEditMode} />
                    );
                }
            }
        }

        return sections.length ? sections : null;
    }

    private renderCollectorsField = ({ input, collectors, collectorsLoadStatus, isOperationInProgress, manifestsLoadStatus, meta }) => {
        const { setCollectors } = this.props;

        const items = collectors.map(({ id, description }) => ({
            id,
            name: description
        }));

        const placeholderMessageId = 'sources.collectorsPlaceholder';
        const placeholder = <FormattedMessage id={placeholderMessageId} />;

        const onChange = (...args) => {
            // prevent reloading collectors if previous loading is still in progress
            if (manifestsLoadStatus !== LOAD_STATUS.LOADING) {
                input.onChange(...args);
                setCollectors();
            }
        };

        return (
            <div>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.collectors' />
                </div>
                <div className={local.label}>
                    <FormattedMessage id='sources.collectorsLabel' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.SOURCE_FORM_COLLECTORS}
                    optionClassName={AUTOMATION_ID.SOURCE_FORM_COLLECTORS_OPTION}
                    placeholder={placeholder}
                    items={items}
                    inputProperties={{ ...input, onChange }}
                    disabled={isOperationInProgress}
                    multiple={true}
                    isLoading={collectorsLoadStatus !== LOAD_STATUS.LOADED}
                    error={meta.error}
                    searchable={true}
                    trackTiming={true}
                    trackingName='Source collectors'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderTestStateLabel = () => {
        const { testMode } = this.props;

        return testMode ?
            <span className={local.testStateLabel}>
                <FormattedMessage id='sources.newSourceTestLabelMessage' />
                <IconWithTooltip className={local.searchTooltip}>
                    <FormattedMessage id='sources.sourceTestLabelTooltip' />
                </IconWithTooltip>
            </span> :
            null;
    }
}

export default SourceForm;
