import axios, { AxiosProgressEvent, AxiosResponse, Method, InternalAxiosRequestConfig, AxiosError } from 'axios';

import { store } from '../../../store';
import { getTokens } from 'modules/auth/selectors';
import { HTTP_CODE } from 'modules/common/constants';
import keycloak from 'modules/auth/components/KeycloakProviderWrapper/keycloak';

const authInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (!config.headers.Authorization) {
        const tokens = getTokens(store.getState());

        if (tokens.token) {
            config.headers.Authorization = `Bearer ${tokens.token}`;
        }
    }

    return config;
};

const responseErrorInterceptor = async (error: AxiosError) => {
    try {
        if (error.response?.status === HTTP_CODE.UNAUTHORIZED) {
            const tokenUpdated = await keycloak.updateToken(5);

            if (tokenUpdated) {
                const tokens = getTokens(store.getState());

                if (tokens.token) {
                    const config = error.config!;
                    config.headers.Authorization = `Bearer ${tokens.token}`;

                    return new Promise((resolve, reject) => {
                        axios
                            .request(config)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                }
            }
        }

        return Promise.reject(error);
    } catch (err) {
        return Promise.reject(err);
    }
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.request.use(authInterceptor);
api.interceptors.response.use((response: AxiosResponse) => response, responseErrorInterceptor);

export const get = async (url: string) =>
    sendRequest(buildRequestConfig('GET', url));

export const post = async (url: string, data: object = {}) =>
    sendRequest(buildRequestConfig('POST', url, data));

export const put = async (url: string, data: object = {}) =>
    sendRequest(buildRequestConfig('PUT', url, data));

export const del = async (url: string, data?: object) =>
    sendRequest(buildRequestConfig('DELETE', url, data));

export const patch = async (url: string, data: object = {}) =>
    sendRequest(buildRequestConfig('PATCH', url, data));

export const uploadFile = async (
    url: string,
    formFieldName: string = 'file',
    file: File,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
    const formData = new FormData();
    formData.append(formFieldName, file);

    const res = await api.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
    });

    return processResponse(res);
};

const sendRequest = async (config: InternalAxiosRequestConfig) => {
    const res = await api.request(config);

    return processResponse(res);
};

const buildRequestConfig = (requestType: Method, requestUrl: string, requestData: any = {}) => ({
    method: requestType,
    url: requestUrl,
    data: requestData
} as InternalAxiosRequestConfig);

const processResponse = (response: AxiosResponse<any>) => {
    const { headers, status } = response;
    const contentType = headers['content-type'];

    if (contentType && !contentType.includes('application/json')) {
        throw new Error('Invalid JSON response');
    }

    if (!isValidStatus(status)) {
        throw new Error(buildErrorResponse(response.data));
    }

    return response.data;
};

const buildErrorResponse = (data: { message: string }) => {
    const { message } = data;

    return message;
};

const isValidStatus = (status: number) => {
    return status >= HTTP_CODE.OK && status < HTTP_CODE.MULTIPLE_CHOICES;
};
