import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { SortableElement, SortableElementProps } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faArrowsAltV } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import { ingestionApiDefinitions } from 'modules/service/types';
import { SORT_ORDER } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import SelectInput from 'modules/common/components/SelectInput';
import { removeDeduplicateColumn, setDeduplicateColumnName, setDeduplicateColumnOrder } from '../../actions';
import {
    getSourceFormDeduplicateColumns,
    getSourceFormOperationInProgress,
    getSourceFormSchema
} from '../../selectors';

import local from './local.module.scss';

interface SchemaDeduplicateColumnProps {
    data: ingestionApiDefinitions['DeduplicateColumnsRequestDto'];
    itemIndex: number;
}

const ORDER_ITEMS = [
    { id: SORT_ORDER.ASC, name: SORT_ORDER.ASC },
    { id: SORT_ORDER.DESC, name: SORT_ORDER.DESC }
];

const SchemaDeduplicateColumn: FunctionComponent<SchemaDeduplicateColumnProps> = ({ data, itemIndex }) => {
    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);
    const { fields } = useSelector(getSourceFormSchema);
    const columns = useSelector(getSourceFormDeduplicateColumns);

    const dispatch = useAppDispatch();

    const onColumnChange = useCallback((value) => {
        dispatch(setDeduplicateColumnName({ field: value, index: itemIndex }));
    }, []);

    const onOrderChange = useCallback((value) => {
        dispatch(setDeduplicateColumnOrder({ order: value, index: itemIndex }));
    }, []);

    const columnOptions = fields ?
        fields
            .filter(({ name }) => name && (columns.every((col) => col.field !== name) || columns[itemIndex].field === name))
            .map(({ name }) => ({ id: name, name })) :
        [];

    const removeItem = useCallback(() => {
        dispatch(removeDeduplicateColumn(itemIndex));
    }, [ itemIndex ]);

    return (
        <div className={classnames(local.container, 'container-row')}>
            <SelectInput
                items={columnOptions}
                placeholder={<FormattedMessage id='sources.selectColumn' />}
                inputProperties={{ onChange: onColumnChange, value: data.field }}
                disabled={isOperationInProgress}
                searchable={true}
                trackTiming={true}
                trackingName='Source schema de-duplicate column name'
            />
            <div className={local.orderSelectContainer}>
                <SelectInput
                    items={ORDER_ITEMS}
                    width={200}
                    inputProperties={{ onChange: onOrderChange, value: data.order }}
                    disabled={isOperationInProgress}
                    trackTiming={true}
                    trackingName='Source schema de-duplicate column order'
                />
            </div>
            <div className={local.deleteIconContainer} onClick={removeItem}>
                <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
            </div>
            <div className={local.dragIconContainer}>
                <FontAwesomeIcon icon={faArrowsAltV} className={local.dragIcon} />
            </div>
        </div>
    );
};

// TODO remove type conversion workaround when typings will be fixed in 'react-sortable-hoc'
export default SortableElement(({ data, itemIndex }) => <SchemaDeduplicateColumn itemIndex={itemIndex} data={data} />) as unknown as FunctionComponent<SchemaDeduplicateColumnProps & SortableElementProps>;
