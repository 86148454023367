import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import LoadingSpinner from 'modules/common/components/LoadingSpinner';
import { REPORT_FORM_SECOND_STAGE } from '../../constants';
import { goToReportsListPage, setReportFormStage } from '../../actions';
import { getReportFormTemplates, getReportFormTemplatesLoadStatus } from '../../selectors';
import TemplateItem from './TemplateItem';

import local from './local.module.scss';

const FormFirstStage: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const templates = useSelector(getReportFormTemplates);
    const templatesLoadStatus = useSelector(getReportFormTemplatesLoadStatus);

    const selectedTemplateId = useReduxFormValue('reportForm', 'templateId');

    return (
        <div className={local.card}>
            <div className={classnames(local.titleContainer, 'container-row')}>
                <div className={local.title}>
                    <FormattedMessage id='reports.templates' />
                </div>
                <div className={classnames(local.buttonsContainer, 'container-row')}>
                    <div className='ls-button'>
                        <button
                            className='btn-flat'
                            onClick={() => {
                                dispatch(goToReportsListPage());
                            }}
                        >
                            <FormattedMessage id='common.cancel' />
                        </button>
                    </div>
                    <div className='ls-button'>
                        <button
                            disabled={!selectedTemplateId}
                            className={local.nextButton}
                            onClick={(event) => {
                                event.preventDefault();
                                dispatch(setReportFormStage(REPORT_FORM_SECOND_STAGE));
                            }}
                        >
                            <FormattedMessage id='common.next' />
                        </button>
                    </div>
                </div>
            </div>
            {
                templatesLoadStatus === LOAD_STATUS.LOADED ?
                    <div className={classnames(local.templatesContainer, 'container-row')}>
                        {
                            templates.map((template) => <TemplateItem key={template.id} {...template} />)
                        }
                    </div> :
                    <div className={local.spinnerContainer}>
                        <LoadingSpinner className={local.spinner} />
                    </div>
            }
        </div>
    );
};

export default FormFirstStage;
