import React, { FunctionComponent, Fragment } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Field, WrappedFieldProps, InjectedFormProps } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import { removeSyncError } from 'modules/form/actions';
import { LOAD_STATUS } from 'modules/common/constants';
import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import { CUSTOM_VALUE } from '../../constants';
import {
    getReportFormMarkets,
    getReportFormMarketsLoadStatus,
    getReportFormOperationInProgress,
    getReportFormTemplate
} from '../../selectors';
import { removePlatform } from '../../actions';

import local from './local.module.scss';

type SelectFieldProps = WrappedFieldProps & {
    items: SelectInputItem[];
    disabled: boolean;
    isLoading: boolean;
};

interface ReportPlatformProps {
    index: number;
    change: InjectedFormProps['change'];
}

const SelectField: FunctionComponent<SelectFieldProps> = ({
    input,
    meta,
    items,
    disabled,
    isLoading
}) => {
    const dispatch = useAppDispatch();

    return (
        <Fragment>
            <SelectInput
                inputProperties={{
                    ...input,
                    onChange: (value) => {
                        if (meta.error) {
                            dispatch(removeSyncError({
                                form: 'reportForm',
                                field: input.name
                            }));
                        }

                        input.onChange(value);
                    }
                }}
                items={items}
                disabled={disabled}
                error={meta.error}
                isLoading={isLoading}
                width={240}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

const WarningTooltip: FunctionComponent<WrappedFieldProps> = ({ input }) => input.value ? (
    <div className={local.warningIconContainer}>
        <IconWithTooltip className={local.warningIcon} icon={faTriangleExclamation}>
            <FormattedMessage id='reports.platformMarketWarning' />
        </IconWithTooltip>
    </div>
) : null;

const ReportPlatform: FunctionComponent<ReportPlatformProps> = ({
    index,
    change
}) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const template = useSelector(getReportFormTemplate);
    const markets = useSelector(getReportFormMarkets);
    const marketsLoadStatus = useSelector(getReportFormMarketsLoadStatus);
    const isOperationInProgress = useSelector(getReportFormOperationInProgress);

    const fieldPrefix = `platforms.platform_${index}`;
    const platformsFormObject = useReduxFormValue('reportForm', 'platforms');
    const currentPlatformId = useReduxFormValue('reportForm', `${fieldPrefix}.platformId`);
    const hasCustomChanges = useReduxFormValue('reportForm', `${fieldPrefix}.scheduleCustomChanges`);

    const selectedPlatformIds = Object.values<{ [key: string]: any }>(platformsFormObject || {})
        .map(({ platformId }) => platformId).filter(Boolean);

    const platformItems = template.platforms?.map(({ platformId, platformName }) =>
        (platformId === currentPlatformId || !selectedPlatformIds.includes(platformId)) ?
            { id: platformId, name: platformName } :
            null
    ).filter(Boolean) || [];

    const marketItems = markets.map(({ id, name }) => ({ id, name }));
    if (hasCustomChanges) {
        marketItems.push({
            id: CUSTOM_VALUE,
            name: intl.formatMessage({ id: 'common.custom' })
        });
    }

    return (
        <div className={classnames('container-row', local.platformContainer)}>
            <div className={local.field}>
                <Field
                    name={`${fieldPrefix}.platformId`}
                    component={SelectField}
                    items={platformItems}
                    disabled={isOperationInProgress}
                    onChange={() => {
                        change(`${fieldPrefix}.collectors`, undefined);
                    }}
                />
            </div>
            <div className={local.field}>
                <Field
                    name={`${fieldPrefix}.marketId`}
                    component={SelectField}
                    items={marketItems}
                    disabled={isOperationInProgress}
                    isLoading={marketsLoadStatus !== LOAD_STATUS.LOADED}
                />
            </div>
            <div
                className={classnames(local.platformDeleteIconContainer, { [local.disabled]: Boolean(isOperationInProgress) })}
                onClick={(event) => {
                    event.preventDefault();
                    dispatch(removePlatform(index));
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
            </div>
            <Field
                name={`${fieldPrefix}.scheduleCustomChanges`}
                component={WarningTooltip}
            />
        </div>
    );
};

export default ReportPlatform;
