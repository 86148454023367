import { connect } from 'react-redux';
import { clearSubmitErrors } from 'redux-form';

import { withRouteParams } from 'modules/common/hoc';
import { getClientFormLayoutProps } from '../../selectors';
import { goToClientDetailsPage, goToClientsListPage, addClient, editClient } from '../../actions';
import ClientFormLayout from './ClientFormLayout';

const mapDispatchToProps = {
    goToClientsListPage,
    goToClientDetailsPage,
    addClient,
    editClient,
    clearSubmitErrors
};

export default withRouteParams(connect(getClientFormLayoutProps, mapDispatchToProps)(ClientFormLayout));
