import React, { FunctionComponent, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Field, WrappedFieldProps, InjectedFormProps } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { ingestionApiDefinitions } from 'modules/service/types';
import SelectInput, { SelectInputItem, GroupedInputItem } from 'modules/common/components/SelectInput';
import { LOAD_STATUS } from 'modules/common/constants';
import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import {
    getFeedFormOperationInProgress,
    getFeedFormTargetCredentials,
    getFeedFormTargetCredentialsLoadStatus,
    getFeedFormTargets,
    getFeedFormTargetsLoadStatus
} from '../../selectors';
import { removeTarget } from '../../actions';

import local from './local.module.scss';

type SelectFieldProps = WrappedFieldProps & {
    items: SelectInputItem[] | GroupedInputItem[];
    disabled: boolean;
    isLoading: boolean;
};

interface FeedTargetProps {
    index: number;
    isEditMode: boolean;
    data: ingestionApiDefinitions['FeedVersionTargetDetailsDto'];
    change: InjectedFormProps['change'];
}

const SelectField: FunctionComponent<SelectFieldProps> = ({
    input,
    meta,
    items,
    disabled,
    isLoading
}) => {
    return (
        <Fragment>
            <SelectInput
                inputProperties={input}
                items={items}
                disabled={disabled}
                error={meta.error}
                isLoading={isLoading}
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

const FeedTarget: FunctionComponent<FeedTargetProps> = ({
    index,
    isEditMode,
    data,
    change
}) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const targets = useSelector(getFeedFormTargets);
    const targetsLoadStatus = useSelector(getFeedFormTargetsLoadStatus);
    const targetCredentials = useSelector(getFeedFormTargetCredentials);
    const targetCredentialsLoadStatus = useSelector(getFeedFormTargetCredentialsLoadStatus);
    const isOperationInProgress = useSelector(getFeedFormOperationInProgress);

    const fieldPrefix = `targets.target_${index}`;
    const targetsFormObject = useReduxFormValue('feedForm', 'targets');
    const currentTargetId = useReduxFormValue('feedForm', `${fieldPrefix}.targetId`);
    const currentTargetType = targets.find(({ id }) => id === currentTargetId)?.targetType;

    useEffect(() => {
        if (isEditMode && data) {
            change(`${fieldPrefix}.id`, data.id);
            change(`${fieldPrefix}.targetId`, data.targetId);
            change(`${fieldPrefix}.targetCredentialId`, data.targetCredentialId);
        }
    }, [ data ]);

    const targetIdsToExclude = Object.values<{ [key: string]: any }>(targetsFormObject || {}).reduce((acc, { targetId }) => {
        if (targetId && targetId !== currentTargetId) {
            acc.push(targetId);
        }

        return acc;
    }, []);

    const targetItemsMap = targets.reduce((acc, { id, name, ownerName, targetType }) => {
        if (!targetIdsToExclude.includes(id)) {
            if (!acc[targetType]) {
                acc[targetType] = {
                    label: intl.formatMessage({ id: `common.targetType.${targetType}` }),
                    options: []
                };
            }

            acc[targetType].options.push({
                id,
                name: `[${ownerName}] ${name}`
            });
        }

        return acc;
    }, {});
    const targetItems = Object.values(targetItemsMap);

    const targetCredentialsItems = currentTargetId && currentTargetType ?
        targetCredentials.reduce((acc, { id, name, ownerName, targetType }) => {
            if (targetType === currentTargetType) {
                acc.push({
                    id,
                    name: `[${ownerName}] ${name}`
                });
            }

            return acc;
        }, []) : [];

    return (
        <div className={classnames('container-row', local.targetContainer)}>
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.destination' />
                </div>
                <Field
                    name={`${fieldPrefix}.targetId`}
                    component={SelectField}
                    items={targetItems}
                    disabled={isOperationInProgress || (isEditMode && data)}
                    isLoading={targetsLoadStatus !== LOAD_STATUS.LOADED}
                />
            </div>
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.destinationCredential' />
                </div>
                <Field
                    name={`${fieldPrefix}.targetCredentialId`}
                    component={SelectField}
                    items={targetCredentialsItems}
                    disabled={isOperationInProgress || !currentTargetId}
                    isLoading={targetCredentialsLoadStatus !== LOAD_STATUS.LOADED}
                />
            </div>
            <div
                className={classnames(local.targetDeleteIconContainer, { [local.disabled]: Boolean(isOperationInProgress) })}
                onClick={(event) => {
                    event.preventDefault();
                    dispatch(removeTarget(index));
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
            </div>
        </div>
    );
};

export default FeedTarget;
