import { getUniqueElements } from './otherUtils';
import { PERMISSIONS, CLIENT_ROLES, OWNER_ROLES } from '../constants';

const PERMISSIONS_MAP = {
    CLIENT: {
        [CLIENT_ROLES.INGESTION_VIEWER]: [ PERMISSIONS.FEEDS.VIEW, PERMISSIONS.ADVERITY_AUTHORIZATIONS.VIEW ],
        [CLIENT_ROLES.INGESTION_RUNNER]: [
            PERMISSIONS.FEEDS.VIEW,
            PERMISSIONS.FEEDS.RUN,
            PERMISSIONS.ADVERITY_AUTHORIZATIONS.VIEW
        ],
        [CLIENT_ROLES.INGESTION_MANAGER]: [
            PERMISSIONS.FEEDS.VIEW,
            PERMISSIONS.FEEDS.RUN,
            PERMISSIONS.FEEDS.MANAGE,
            PERMISSIONS.ADVERITY_AUTHORIZATIONS.VIEW,
            PERMISSIONS.ADVERITY_AUTHORIZATIONS.MANAGE
        ],
        [CLIENT_ROLES.TRANSFORMATION_VIEWER]: [ PERMISSIONS.INSTANCES.VIEW ],
        [CLIENT_ROLES.TRANSFORMATION_RUNNER]: [ PERMISSIONS.INSTANCES.VIEW, PERMISSIONS.INSTANCES.RUN ],
        [CLIENT_ROLES.TRANSFORMATION_MANAGER]: [
            PERMISSIONS.INSTANCES.VIEW,
            PERMISSIONS.INSTANCES.RUN,
            PERMISSIONS.INSTANCES.MANAGE
        ],
        [CLIENT_ROLES.MEMBERSHIPS_VIEWER]: [ PERMISSIONS.MEMBERSHIPS.VIEW ],
        [CLIENT_ROLES.MEMBERSHIPS_ADMIN]: [ PERMISSIONS.MEMBERSHIPS.VIEW, PERMISSIONS.MEMBERSHIPS.MANAGE ],
        [CLIENT_ROLES.DATA_CATALOG_VIEWER]: [ PERMISSIONS.CATALOGUE.VIEW ],
        [CLIENT_ROLES.MANUAL_UPLOAD_VIEWER]: [ PERMISSIONS.UPLOADS.VIEW ],
        [CLIENT_ROLES.MANUAL_UPLOAD_MANAGER]: [ PERMISSIONS.UPLOADS.VIEW, PERMISSIONS.UPLOADS.UPLOAD ],
        [CLIENT_ROLES.REPORTING_MANAGER]: [ PERMISSIONS.REPORTS.VIEW, PERMISSIONS.REPORTS.MANAGE ]
    },
    OWNER: {
        [OWNER_ROLES.INGESTION_VIEWER]: [
            PERMISSIONS.COLLECTOR_CREDENTIALS.VIEW,
            PERMISSIONS.TARGET_CREDENTIALS.VIEW,
            PERMISSIONS.TARGETS.VIEW,
            PERMISSIONS.SOURCES.VIEW
        ],
        [OWNER_ROLES.INGESTION_USER]: [
            PERMISSIONS.COLLECTOR_CREDENTIALS.VIEW,
            PERMISSIONS.COLLECTOR_CREDENTIALS.USE,
            PERMISSIONS.TARGET_CREDENTIALS.VIEW,
            PERMISSIONS.TARGET_CREDENTIALS.USE,
            PERMISSIONS.TARGETS.VIEW,
            PERMISSIONS.TARGETS.USE,
            PERMISSIONS.SOURCES.VIEW,
            PERMISSIONS.SOURCES.USE
        ],
        [OWNER_ROLES.INGESTION_MANAGER]: [
            PERMISSIONS.COLLECTOR_CREDENTIALS.VIEW,
            PERMISSIONS.COLLECTOR_CREDENTIALS.USE,
            PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE,
            PERMISSIONS.TARGET_CREDENTIALS.VIEW,
            PERMISSIONS.TARGET_CREDENTIALS.USE,
            PERMISSIONS.TARGET_CREDENTIALS.MANAGE,
            PERMISSIONS.TARGETS.VIEW,
            PERMISSIONS.TARGETS.USE,
            PERMISSIONS.TARGETS.MANAGE,
            PERMISSIONS.SOURCES.VIEW,
            PERMISSIONS.SOURCES.USE,
            PERMISSIONS.SOURCES.MANAGE
        ],
        [OWNER_ROLES.TRANSFORMATION_VIEWER]: [ PERMISSIONS.PIPELINES.VIEW ],
        [OWNER_ROLES.TRANSFORMATION_USER]: [ PERMISSIONS.PIPELINES.VIEW, PERMISSIONS.PIPELINES.USE ],
        [OWNER_ROLES.TRANSFORMATION_MANAGER]: [
            PERMISSIONS.PIPELINES.VIEW,
            PERMISSIONS.PIPELINES.USE,
            PERMISSIONS.PIPELINES.MANAGE
        ],
        [OWNER_ROLES.MEMBERSHIPS_VIEWER]: [ PERMISSIONS.MEMBERSHIPS.VIEW ],
        [OWNER_ROLES.MEMBERSHIPS_ADMIN]: [ PERMISSIONS.MEMBERSHIPS.VIEW, PERMISSIONS.MEMBERSHIPS.MANAGE ],
        [OWNER_ROLES.REPORTING_MANAGER]: [ PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE_FROM_REPORT ]
    }
};

export const processUserProfile = (userProfile) => {
    const profile = { ...userProfile };

    profile.clients.forEach((client) => {
        const permissions: string[] = [];
        client.roles = client.roles.filter((role) => Boolean(CLIENT_ROLES[role]));
        client.roles.forEach((role) => {
            const clientPermissions = PERMISSIONS_MAP.CLIENT[role];
            clientPermissions?.length && permissions.push(...clientPermissions);
        });
        client.permissions = getUniqueElements(permissions);
    });
    profile.clients = profile.clients.filter(({ roles }) => Boolean(roles.length));

    profile.owners.forEach((owner) => {
        const permissions: string[] = [];
        owner.roles = owner.roles.filter((role) => Boolean(OWNER_ROLES[role]));
        owner.roles.forEach((role) => {
            const ownerPermissions = PERMISSIONS_MAP.OWNER[role];
            ownerPermissions?.length && permissions.push(...ownerPermissions);
        });
        owner.permissions = getUniqueElements(permissions);
    });
    profile.owners = profile.owners.filter(({ roles }) => Boolean(roles.length));

    return profile;
};
