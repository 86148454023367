import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import { RouteComponentProps } from 'modules/common/interfaces';
import { AUTOMATION_ID, EVENT_CATEGORY } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import GroupForm from '../GroupForm';

import local from './local.module.scss';

interface GroupFormLayoutProps {
    isOperationInProgress: boolean;
    canAccess: boolean;
    isEditMode: boolean;
    displayName: string;
    isDataLoading: boolean;
    goToGroupsListPage: Function;
    goToGroupDetailsPage: Function;
    addGroup: Function;
    editGroup: Function;
    clearSubmitErrors: Function;
}

interface RouteMatchParams {
    groupId: string;
}

class GroupFormLayout extends Component<GroupFormLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public render() {
        const { isEditMode, isOperationInProgress, isDataLoading, match, canAccess } = this.props;

        if (!canAccess) {
            return (
                <ErrorPage error='403' />
            );
        }

        return (
            <Fragment>
                {this.renderBreadcrumbs()}
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            <FormattedMessage id={isEditMode ? 'admin.editGroup' : 'admin.newGroup'} />
                        </div>
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <button
                                    id={AUTOMATION_ID.GROUP_FORM_CANCEL_BTN}
                                    disabled={isOperationInProgress}
                                    className='btn-flat'
                                    onClick={this.onCancelButtonClick}
                                >
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <FormSubmitButton
                                id={AUTOMATION_ID.GROUP_FORM_SAVE_BTN}
                                form='groupForm'
                                width={130}
                                disabled={isOperationInProgress || isDataLoading}
                                text={<FormattedMessage id='common.saveChanges' />}
                                isSubmitting={isOperationInProgress}
                            />
                        </div>
                    </div>
                    <GroupForm isEditMode={isEditMode} match={match} onSubmit={this.onFormSubmit} />
                </div>
            </Fragment>
        );
    }

    private onFormSubmit = (values) => {
        const { isEditMode, addGroup, editGroup, clearSubmitErrors, match: { params: { groupId } } } = this.props;

        clearSubmitErrors('groupForm');

        return isEditMode ?
            editGroup(groupId, values) :
            addGroup(values);
    }

    private renderBreadcrumbs = () => {
        const { isEditMode, displayName, goToGroupsListPage } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_GROUPS_BREADCRUMB,
                        label: (<FormattedMessage id='admin.allGroups' />),
                        onClick: () => { goToGroupsListPage(); }
                    },
                    {
                        label: isEditMode ? displayName : <FormattedMessage id='admin.newGroup' />
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private onCancelButtonClick = () => {
        const { isEditMode, goToGroupDetailsPage, goToGroupsListPage, match } = this.props;

        if (isEditMode) {
            goToGroupDetailsPage(match.params.groupId);

            service.analytics.trackEvent('Cancel group edit', EVENT_CATEGORY.ADMIN);
        } else {
            goToGroupsListPage();

            service.analytics.trackEvent('Cancel group creation', EVENT_CATEGORY.ADMIN);
        }
    }
}

export default GroupFormLayout;
