import * as t from '../actionTypes';
import { GROUPS_LIST_PAGE_SIZE } from '../constants';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { getUniqueId, sort } from 'modules/common/utils';

const ACTION_HANDLERS = {
    [t.SET_GROUPS_LIST_ITEMS]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            items: action.payload
        }
    }),
    [t.APPEND_GROUPS_LIST_ITEMS]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            items: state.groupsList.items.concat(action.payload)
        }
    }),
    [t.SET_GROUPS_LIST_PAGE]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            page: action.payload
        }
    }),
    [t.SET_GROUPS_LIST_COUNT]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            totalCount: action.payload
        }
    }),
    [t.SET_GROUPS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            sortBy: action.payload
        }
    }),
    [t.SET_GROUPS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            sortOrder: action.payload
        }
    }),
    [t.SET_GROUPS_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            loadStatus: action.payload
        }
    }),
    [t.SET_GROUPS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        groupsList: {
            ...state.groupsList,
            searchBy: action.payload
        }
    }),
    [t.RESET_GROUPS_LIST]: (state) => ({
        ...state,
        groupsList: initialState.groupsList
    }),
    [t.RESET_GROUP_FORM]: (state) => ({
        ...state,
        groupForm: initialState.groupForm
    }),
    [t.ADD_GROUP_CLIENT]: (state) => ({
        ...state,
        groupForm: {
            ...state.groupForm,
            clients: [ ...state.groupForm.clients, { id: null, roles: [], key: getUniqueId() } ]
        }
    }),
    [t.ADD_GROUP_OWNER]: (state) => ({
        ...state,
        groupForm: {
            ...state.groupForm,
            owners: [ ...state.groupForm.owners, { id: null, roles: [], key: getUniqueId() } ]
        }
    }),
    [t.REMOVE_GROUP_CLIENT]: (state, action) => {
        const clients = [ ...state.groupForm.clients ];
        clients.splice(action.payload, 1);

        return {
            ...state,
            groupForm: {
                ...state.groupForm,
                clients
            }
        };
    },
    [t.REMOVE_GROUP_OWNER]: (state, action) => {
        const owners = [ ...state.groupForm.owners ];
        owners.splice(action.payload, 1);

        return {
            ...state,
            groupForm: {
                ...state.groupForm,
                owners
            }
        };
    },
    [t.UPDATE_GROUP_CLIENT]: (state, action) => {
        const clients = [ ...state.groupForm.clients ];
        const client = clients[action.payload.index];
        clients[action.payload.index] = { key: client.key, ...action.payload.data };

        return {
            ...state,
            groupForm: {
                ...state.groupForm,
                clients
            }
        };
    },
    [t.UPDATE_GROUP_OWNER]: (state, action) => {
        const owners = [ ...state.groupForm.owners ];
        const owner = owners[action.payload.index];
        owners[action.payload.index] = { key: owner.key, ...action.payload.data };

        return {
            ...state,
            groupForm: {
                ...state.groupForm,
                owners
            }
        };
    },
    [t.SET_GROUP_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        groupForm: {
            ...state.groupForm,
            groupOperationInProgress: action.payload
        }
    }),
    [t.INIT_CLIENTS_AND_OWNERS]: (state, action) => ({
        ...state,
        groupForm: {
            ...state.groupForm,
            clients: action.payload.clients.map(({ id, roles }) => ({ id, roles, key: getUniqueId() })),
            owners: action.payload.owners.map(({ id, roles }) => ({ id, roles, key: getUniqueId() }))
        }
    }),
    [t.SET_GROUP_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        groupDetails: {
            ...state.groupDetails,
            loadStatus: action.payload
        }
    }),
    [t.SET_GROUP_DETAILS]: (state, action) => {
        const data = action.payload;

        if (data.clients) {
            data.clients = sort(data.clients, 'name', SORT_ORDER.ASC);
        }

        if (data.owners) {
            data.owners = sort(data.owners, 'name', SORT_ORDER.ASC);
        }

        if (data.members) {
            data.members = sort(data.members, 'username', SORT_ORDER.ASC);
        }

        return {
            ...state,
            groupDetails: {
                ...state.groupDetails,
                data: action.payload
            }
        };
    },
    [t.RESET_GROUP_DETAILS]: (state) => ({
        ...state,
        groupDetails: initialState.groupDetails
    }),
    [t.SET_GROUP_DETAILS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        groupDetails: {
            ...state.groupDetails,
            groupOperationInProgress: action.payload
        }
    }),
    [t.REMOVE_GROUP_MEMBERSHIP]: (state, action) => {
        const updatedMemberships = state.groupDetails.data.members.filter(({ id }) => id !== action.payload);

        return {
            ...state,
            groupDetails: {
                ...state.groupDetails,
                data: {
                    ...state.groupDetails.data,
                    members: updatedMemberships
                }
            }
        };
    },
    [t.OPEN_ADD_MEMBERSHIPS_DIALOG]: (state) => ({
        ...state,
        groupDetails: {
            ...state.groupDetails,
            isAddMembershipsDialogOpen: true
        }
    }),
    [t.CLOSE_ADD_MEMBERSHIPS_DIALOG]: (state) => ({
        ...state,
        groupDetails: {
            ...state.groupDetails,
            isAddMembershipsDialogOpen: false
        }
    })
};

export const initialState = {
    groupsList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: GROUPS_LIST_PAGE_SIZE,
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED
    },
    groupForm: {
        groupOperationInProgress: false,
        clients: [],
        owners: []
    },
    groupDetails: {
        loadStatus: LOAD_STATUS.REQUIRED,
        data: {},
        groupOperationInProgress: false,
        isAddMembershipsDialogOpen: false
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
