import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { validate as validateEmail } from 'email-validator';

import { useAppDispatch } from 'modules/common/hooks';
import Input from 'modules/common/components/Input';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import { closeMemberDialog } from '../../actions';
import { getMembersListOperationInProgress, isMemberFormReadyForSubmission } from '../../selectors';

import local from './local.module.scss';

const emailValidator = (value) => {
    return value && !validateEmail(value) ? 'custom.InvalidEmail' : undefined;
};

const InputFieldRenderer = ({ input, meta, disabled, maxLength, placeholder }) => {
    const showError = meta.touched && meta.error;

    return (
        <Fragment>
            <Input
                inputProperties={input}
                disabled={disabled}
                placeholder={placeholder}
                maxLength={maxLength}
                className={classnames({ 'error-input': Boolean(showError) })}
            />
            {
                showError &&
                <div className={classnames(local.errorMessage, 'form-error-message')}>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

const MemberForm = ({ handleSubmit }) => {
    const dispatch = useAppDispatch();

    const close = useCallback(() => {
        dispatch(closeMemberDialog());
    }, []);

    const isOperationInProgress = useSelector(getMembersListOperationInProgress);
    const isFormReadyForSubmission = useSelector(isMemberFormReadyForSubmission);

    const intl = useIntl();

    return (
        <Fragment>
            <form id='memberForm' onSubmit={handleSubmit} autoComplete='off'>
                <div className={local.field}>
                    <div className={local.label}>
                        <FormattedMessage id='common.firstName' />
                    </div>
                    <Field
                        name='firstName'
                        component={InputFieldRenderer}
                        disabled={isOperationInProgress}
                        placeholder={intl.formatMessage({ id: 'common.firstName' })}
                        maxLength={255}
                    />
                </div>
                <div className={local.field}>
                    <div className={local.label}>
                        <FormattedMessage id='common.lastName' />
                    </div>
                    <Field
                        name='lastName'
                        component={InputFieldRenderer}
                        disabled={isOperationInProgress}
                        placeholder={intl.formatMessage({ id: 'common.lastName' })}
                        maxLength={255}
                    />
                </div>
                <div className={local.field}>
                    <div className={local.label}>
                        <FormattedMessage id='admin.memberEmail' />
                    </div>
                    <Field
                        name='username'
                        component={InputFieldRenderer}
                        validate={emailValidator}
                        disabled={isOperationInProgress}
                        placeholder={intl.formatMessage({ id: 'admin.newUserPlaceholder' })}
                        maxLength={255}
                    />
                </div>
            </form>
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button disabled={isOperationInProgress} className='btn-flat' onClick={close}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <FormSubmitButton
                    form='memberForm'
                    width={130}
                    disabled={!isFormReadyForSubmission || isOperationInProgress}
                    text={<FormattedMessage id='common.submit' />}
                    isSubmitting={isOperationInProgress}
                />
            </div>
        </Fragment>
    );
};

export default MemberForm;
