import { createSelector } from 'reselect';

import { REDUCER_NAME, TARGET_CREDENTIALS_LIST_SORT_ATTRIBUTES_MAP } from '../constants';

// target credential form
export const getTargetCredentialFormOperationInProgress = (state) => state[REDUCER_NAME].credentialForm.credentialOperationInProgress;

// target credential details
export const getTargetCredentialDetails = (state) => state[REDUCER_NAME].credentialDetails.data;
export const getTargetCredentialDetailsLoadStatus = (state) => state[REDUCER_NAME].credentialDetails.loadStatus;

// common
export const getTargetCredentialManifest = (state) => state[REDUCER_NAME].common.manifest.object;
export const getTargetCredentialManifestLoadStatus = (state) => state[REDUCER_NAME].common.manifest.loadStatus;

// target credentials list
export const getTargetCredentialsListState = (state) => state[REDUCER_NAME].credentialsList;

export const getTargetCredentialsListSearchPayload = createSelector(
    getTargetCredentialsListState,
    ({ page, pageSize, sortBy, sortOrder, searchBy }) => ({
        orderBy: [
            {
                attribute: TARGET_CREDENTIALS_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering: sortOrder
            }
        ],
        searchString: searchBy,
        page,
        pageSize
    })
);
