import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH } from 'modules/common/constants';
import { LoadStatus, SortOrder } from 'modules/common/interfaces';
import { formatString } from 'modules/common/utils';
import { memberApiDefinitions } from 'modules/service/types';
import { MEMBERS_ROUTES } from '../../constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface MembersListProps {
    items: Array<memberApiDefinitions['MemberSearchResponseDto']>;
    status: LoadStatus;
    sortOrder: SortOrder;
    sortBy: string;
    onSort: Function;
    deleteMember: Function;
    isOperationInProgress: boolean;
}

class MembersList extends Component<MembersListProps> {
    public render() {
        const { items, status, onSort } = this.props;

        return (
            <Table
                columns={this.getTableConfig()}
                data={items}
                onSort={onSort}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={status === LOAD_STATUS.LOADED}
            />
        );
    }

    private getTableConfig = () => {
        const { deleteMember, sortBy, sortOrder } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='admin.memberEmail' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'username',
                accessor: ({ id, username }) => (
                    <a href={`#${formatString(MEMBERS_ROUTES.DETAILS, id)}`} className={local.name}>
                        {username}
                    </a>
                ),
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '74%',
                sortable: true,
                showTooltip: true
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.created' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'createTimestamp',
                accessor: ({ createTimestamp }) => moment.utc(createTimestamp).format('DD MMM YYYY [at] HH:mm'),
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 180,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: 'secondary-column-cell'
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<Fragment><FormattedMessage id='common.lastUpdated' /> (UTC)</Fragment>}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'updateTimestamp',
                accessor: ({ updateTimestamp }) => moment.utc(updateTimestamp).format('DD MMM YYYY [at] HH:mm'),
                width: 0,
                skeletonWidth: '100%',
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: 'secondary-column-cell'
            },
            {
                id: 'controls',
                accessor: ({ id }) => {
                    const { isOperationInProgress } = this.props;

                    return (
                        <div
                            className={classnames(local.deleteIconContainer, { [local.disabled]: Boolean(isOperationInProgress) })}
                            onClick={() => { deleteMember(id); }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
                        </div>
                    );
                },
                width: 0,
                skeletonWidth: '100%',
                skeletonCellWidth: 70,
                sortable: true,
                showTooltip: true,
                headerCellClassName: 'secondary-column-cell',
                cellClassName: 'secondary-column-cell'
            }
        ];
    }
}

export default MembersList;
