import React from 'react';
import { FormattedMessage } from 'react-intl';

import service from 'modules/service';
import { intl } from 'modules/i18n';
import { ingestionApiDefinitions } from 'modules/service/types';
import { getSelectedClientId } from 'modules/auth/selectors';
import { LOAD_STATUS } from 'modules/common/constants';
import { alert, formatString, getErrorText, showErrorSnackbar, showSuccessSnackbar } from 'modules/common/utils';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';
import { FEED_RERUN_STATUS, FEEDS_ROUTES } from '../constants';

import styleConstants from 'assets/styles/theme/exports.module.scss';

export const rerunFeeds = () => async (dispatch, getState) => {
    dispatch(storeActions.setFeedRunsOperationInProgress(true));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const { selectedItemsIds, items } = selectors.getFeedRunsListData(storeState);

        if (selectedItemsIds.length) {
            const itemsMap = {};
            items.forEach(({ correlationId, feedVersionId }) => {
                itemsMap[correlationId] = { correlationId, feedVersionId };
            });

            const failedRuns: any[] = [];
            selectedItemsIds.forEach((correlationId) => {
                failedRuns.push(itemsMap[correlationId]);
            });

            const { restarts }: ingestionApiDefinitions['FeedRerunResponseDto'] = await service.api.rerunFeeds(clientId, { failedRuns });

            dispatch(storeActions.setFeedRunsListPage(0));
            dispatch(storeActions.setFeedRunsListItems([]));
            dispatch(storeActions.setFeedRunsListLoadStatus(LOAD_STATUS.REQUIRED));

            const failedRestarts = restarts?.filter(({ status }) => status !== FEED_RERUN_STATUS.RESTARTED);

            if (failedRestarts?.length) {
                alert({
                    title: <FormattedMessage id='feeds.rerunFeeds' />,
                    content: (
                        <div style={{ fontSize: 14, paddingTop: 8 }}>
                            <FormattedMessage id='feeds.failedRerunMessage' />
                            <div style={{ paddingTop: 8, textAlign: 'justify' }}>
                                {
                                    failedRestarts.map(({ correlationId, details }) => {
                                        const { feedId, name, suffix } = items.find(({ correlationId: itemCorrelationId }) => itemCorrelationId === correlationId);

                                        return (
                                            <div key={correlationId}>
                                                {
                                                    feedId && name && suffix ?
                                                        <a
                                                            href={`#${formatString(FEEDS_ROUTES.DETAILS, clientId, feedId)}`}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                            style={{
                                                                fontFamily: 'inter500',
                                                                color: styleConstants.highlightColorDark
                                                            }}
                                                        >
                                                            {name}_{suffix}
                                                        </a> :
                                                        correlationId
                                                } ({details})
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )
                });
            } else {
                showSuccessSnackbar(intl.formatMessage({ id: 'feeds.successfulRerunMessage' }));
            }
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedRunsOperationInProgress(false));
    }
};
