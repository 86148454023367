import React, { Fragment, FunctionComponent, ReactElement } from 'react';
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import { getUserProfile } from 'modules/auth/selectors';
import { ingestionApiDefinitions } from 'modules/service/types';
import { Owner } from 'modules/common/interfaces';
import { AUTOMATION_ID, PERMISSIONS } from 'modules/common/constants';
import Input from 'modules/common/components/Input';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import SelectInput from 'modules/common/components/SelectInput';
import {
    getSourceDetails,
    getSourceFormDraftFlag,
    getSourceFormOperationInProgress,
    getSourceFormValues
} from '../../selectors';
import { SOURCE_ORIGIN } from '../../constants';
import { buildSourceReportFieldPrefix } from '../../utils';

import local from '../SourceFormLayout/local.module.scss';

type ReportFieldProps = WrappedFieldProps & {
    isEditable: boolean;
    isOperationInProgress: boolean;
    data: ingestionApiDefinitions['SourceVersionDetailDto'];
};

type OwnerFieldProps = WrappedFieldProps & {
    isEditable: boolean;
    isOperationInProgress: boolean;
    owners: Owner[];
    currentOwner: Owner;
    change: InjectedFormProps['change'];
};

type OriginFieldProps = WrappedFieldProps & {
    isEditable: boolean;
    isOperationInProgress: boolean;
    currentOwner: Owner;
    data: ingestionApiDefinitions['SourceVersionDetailDto'];
};

interface SourceBaseConfigurationProps {
    isEditMode: boolean;
    change: InjectedFormProps['change'];
}

// ODP is a special owner, which contains standard sources. Report and origin naming rules are different for ODP and
// non-ODP sources. As it exists on different environments with different UUIDs, we need to rely on checking the name.
const ODP_OWNER_NAME = 'ODP';

const normalizeOriginField = (value) => {
    if (!value) {
        return value;
    }

    const allowedText = value.replace(/^[^a-zA-Z]+|[^a-zA-Z0-9]/g, '');

    return allowedText.toUpperCase();
};

const normalizeReportField = (origin, ownerName) => (value, prevValue) => {
    const showPrefix = origin === SOURCE_ORIGIN.CUSTOM && ownerName;
    const prefix = buildSourceReportFieldPrefix(ownerName);

    if (!value) {
        return showPrefix ? prefix : value;
    }

    if (showPrefix) {
        if (!value.startsWith(prefix) && prevValue && prevValue.startsWith(prefix)) { // user tries to edit prefix
            return prevValue;
        }
    }

    const allowedText = value.replace(/^[^a-zA-Z]+|\W/g, '');

    return allowedText.toUpperCase();
};

const ReportField: FunctionComponent<ReportFieldProps> = ({
    input,
    meta,
    isEditable,
    isOperationInProgress,
    data
}) => {
    const intl = useIntl();

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.report' />
            </div>
            {
                isEditable ?
                    <Fragment>
                        <Input
                            id={AUTOMATION_ID.SOURCE_FORM_REPORT}
                            inputProperties={input}
                            placeholder={intl.formatMessage({ id: 'sources.reportPlaceholder' })}
                            disabled={isOperationInProgress}
                            maxLength={255}
                            className={classnames({ 'error-input': Boolean(meta.error) })}
                            trackTiming={true}
                            trackingName='Source name'
                        />
                        {
                            meta.error ?
                                <div className='form-error-message'>
                                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                                </div> :
                                <div className={local.warning}>
                                    <FormattedMessage id='sources.valueCannotBeChanged' />
                                </div>
                        }
                    </Fragment> :
                    <div id={AUTOMATION_ID.SOURCE_FORM_REPORT} className={local.nonEditableField}>
                        {
                            data.report || <SkeletonItem width='160px' height='20px' />
                        }
                    </div>
            }
        </div>
    );
};

const OwnerField: FunctionComponent<OwnerFieldProps> = ({
    input,
    meta,
    isEditable,
    isOperationInProgress,
    owners,
    currentOwner,
    change
}) => {
    const onChange = (value) => {
        const owner = owners.find(({ id }) => id === value);

        if (owner) {
            const { name } = owner;

            if (name === ODP_OWNER_NAME) {
                change('origin', '');
                change('report', '');
            } else {
                change('origin', SOURCE_ORIGIN.CUSTOM);
                change('report', buildSourceReportFieldPrefix(name));
            }
        }

        input.onChange(value);
    };

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='common.owner' />
            </div>
            {
                isEditable ?
                    <Fragment>
                        <SelectInput
                            inputId={AUTOMATION_ID.SOURCE_FORM_OWNER}
                            optionClassName={AUTOMATION_ID.SOURCE_FORM_OWNER_OPTION}
                            placeholder={<FormattedMessage id='sources.ownerPlaceholder' />}
                            items={owners.map(({ id, name }) => ({ id, name }))}
                            inputProperties={{ ...input, onChange }}
                            disabled={isOperationInProgress}
                            error={meta.error}
                            trackTiming={true}
                            trackingName='Source owner'
                        />
                        {
                            meta.error ? (
                                <div className='form-error-message'>
                                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                                </div>
                            ) : (
                                <div className={local.warning}>
                                    <FormattedMessage id='sources.ownerPrompt' />
                                </div>
                            )
                        }
                    </Fragment> :
                    <div className={local.nonEditableField}>
                        {currentOwner?.name}
                    </div>
            }
        </div>
    );
};

const OriginField: FunctionComponent<OriginFieldProps> = ({
    input,
    meta,
    isEditable,
    isOperationInProgress,
    currentOwner,
    data
}) => {
    let InputElement: ReactElement | null = null;

    if (isEditable) {
        const disabled = isOperationInProgress || !currentOwner || currentOwner.name !== ODP_OWNER_NAME;

        InputElement = (
            <Fragment>
                <Input
                    id={AUTOMATION_ID.SOURCE_FORM_ORIGIN}
                    inputProperties={input}
                    disabled={disabled}
                    maxLength={255}
                    className={classnames({ 'error-input': Boolean(meta.error) })}
                    trackTiming={true}
                    trackingName='Source origin'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </Fragment>
        );
    }

    return (
        <div className={local.field}>
            <div className={local.label}>
                <FormattedMessage id='sources.origin' />
            </div>
            {
                isEditable ?
                    InputElement :
                    <div className={local.nonEditableField}>
                        {
                            data.origin || <SkeletonItem width='160px' height='20px' />
                        }
                    </div>
            }
        </div>
    );
};

const SourceBaseConfiguration: FunctionComponent<SourceBaseConfigurationProps> = ({
    isEditMode,
    change
}) => {
    const isOperationInProgress = useSelector(getSourceFormOperationInProgress);
    const isOnlyDraftExisting = useSelector(getSourceFormDraftFlag);
    const data = useSelector(getSourceDetails);
    const profile = useSelector(getUserProfile);
    const { ownerId, origin } = useSelector(getSourceFormValues);

    const owners = profile.owners.filter((owner) => owner.permissions.includes(PERMISSIONS.SOURCES.MANAGE));
    const currentOwner = owners.find((item) => item.id === ownerId);
    const isBaseDataEditable = !isEditMode || isOnlyDraftExisting;

    return (
        <Fragment>
            <Field
                name='ownerId'
                component={OwnerField}
                isOperationInProgress={isOperationInProgress}
                isEditable={isBaseDataEditable}
                owners={owners}
                currentOwner={currentOwner}
                change={change}
            />
            <Field
                name='origin'
                component={OriginField}
                normalize={normalizeOriginField}
                isOperationInProgress={isOperationInProgress}
                isEditable={isBaseDataEditable}
                currentOwner={currentOwner}
                data={data}
            />
            <Field
                name='report'
                component={ReportField}
                normalize={normalizeReportField(origin, currentOwner?.name)}
                isOperationInProgress={isOperationInProgress}
                isEditable={isBaseDataEditable}
                data={data}
            />
        </Fragment>
    );
};

export default SourceBaseConfiguration;
