import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ingestionApiDefinitions } from 'modules/service/types';
import { MANIFEST_FIELD_TYPE, OVERRIDABLE_FIELD_SUFFIX, SECRET_VALUE_PLACEHOLDER } from 'modules/common/constants';
import {
    renderTextField,
    renderPasswordField,
    renderCheckboxField,
    renderRadioField,
    renderSelectField,
    renderMultiSelectField,
    renderHiddenField,
    renderNonEditableField,
    renderApSqlField,
    renderIntegerField
} from './fieldRenderers';
import { validateIntegerValue } from './utils';

import local from './local.module.scss';

export interface DynamicFieldProps {
    disabled: boolean;
    config: ingestionApiDefinitions['Field'];
    prefix: string;
    panelId: string;
    defaultValue?: string;
    defaultEditableValue?: boolean;
    change: Function;
    renderEditableCheckboxes?: boolean;
    dependOnValue?: string;
    form: string;
    viewOnly: boolean;
    customSqlValidator?: Function;
    editMode: boolean;
}

interface DynamicFieldState {
    visible: boolean;
}

class DynamicField extends Component<DynamicFieldProps, DynamicFieldState> {
    constructor(props) {
        super(props);

        this.state = { visible: true };
        this.init();
    }

    public componentDidMount() {
        this.checkDependency();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.dependOnValue !== prevProps.dependOnValue) {
            this.checkDependency();
        }
    }

    public render() {
        const { form, config, panelId, prefix, renderEditableCheckboxes, customSqlValidator, disabled } = this.props;

        const fieldName = `${prefix}.${panelId}.${config.id}`;
        const { renderer, validate } = this.getRendererProperties();

        return renderer ?
            (
                <div className={local.field} key={fieldName}>
                    <Field
                        name={fieldName}
                        config={config}
                        component={renderer}
                        validate={validate}
                        disabled={disabled}
                        renderEditableCheckboxes={renderEditableCheckboxes}
                        customSqlValidator={customSqlValidator}
                        formName={form}
                    />
                </div>
            ) :
            null;
    }

    private init = () => {
        const {
            config,
            panelId,
            prefix,
            defaultValue,
            renderEditableCheckboxes,
            defaultEditableValue,
            editMode,
            change
        } = this.props;

        const value = editMode ?
            defaultValue :
            defaultValue !== undefined ? defaultValue : config.defaultValue;

        const fieldName = `${prefix}.${panelId}.${config.id}`;

        if (value !== undefined && value !== null) {
            let valueToSet;

            switch (config.type) {
                case MANIFEST_FIELD_TYPE.PASSWORD:
                    valueToSet = editMode && value ? SECRET_VALUE_PLACEHOLDER : value;
                    break;
                case MANIFEST_FIELD_TYPE.CHECKBOX:
                    valueToSet = value === 'true';
                    break;
                default:
                    valueToSet = value;
                    break;
            }

            change(fieldName, valueToSet);
        } else {
            const valueToSet = config.type === MANIFEST_FIELD_TYPE.CHECKBOX ? false : '';

            change(fieldName, valueToSet);
        }

        if (renderEditableCheckboxes) {
            change(`${fieldName}-${OVERRIDABLE_FIELD_SUFFIX}`, defaultEditableValue || false);
        }
    }

    private checkDependency = () => {
        const { config: { id, dependOn, dependOnValues }, panelId, prefix, dependOnValue, change } = this.props;
        const { visible } = this.state;

        if (dependOn && dependOnValues) {
            if (dependOnValue === undefined || !dependOnValues.includes(dependOnValue.toString())) {
                if (visible) {
                    const fieldName = `${prefix}.${panelId}.${id}`;

                    change(fieldName, undefined);
                    change(`${fieldName}-${OVERRIDABLE_FIELD_SUFFIX}`, undefined);
                    this.setState({ visible: false });
                }
            } else if (!visible) {
                this.setState({ visible: true });
                this.init();
            }
        }
    }

    private getRendererProperties = () => {
        const { config: { type, visible }, viewOnly } = this.props;

        // explicit check for `false` value as `visible` property is optional and can be undefined
        if (!this.state.visible || visible === false) {
            return { renderer: renderHiddenField, validate: null };
        }

        if (viewOnly) {
            return { renderer: renderNonEditableField, validate: null };
        }

        let renderer: Function | null = null;
        let validate: Function | null = null;

        switch (type) {
            case MANIFEST_FIELD_TYPE.TEXT:
                renderer = renderTextField;
                break;
            case MANIFEST_FIELD_TYPE.PASSWORD:
                renderer = renderPasswordField;
                break;
            case MANIFEST_FIELD_TYPE.RADIO:
                renderer = renderRadioField;
                break;
            case MANIFEST_FIELD_TYPE.CHECKBOX:
                renderer = renderCheckboxField;
                break;
            case MANIFEST_FIELD_TYPE.DROPDOWN:
                renderer = renderSelectField;
                break;
            case MANIFEST_FIELD_TYPE.MULTI_SELECTION:
                renderer = renderMultiSelectField;
                break;
            case MANIFEST_FIELD_TYPE.AP_SQL:
                renderer = renderApSqlField;
                break;
            case MANIFEST_FIELD_TYPE.INTEGER:
                renderer = renderIntegerField;
                validate = validateIntegerValue;
                break;
        }

        return { renderer, validate };
    }
}

export default DynamicField;
