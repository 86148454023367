import * as t from '../actionTypes';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { TARGET_CREDENTIALS_LIST_PAGE_SIZE } from '../constants';

const ACTION_HANDLERS = {
    [t.SET_CREDENTIAL_MANIFEST]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            manifest: {
                ...state.common.manifest,
                object: action.payload
            }
        }
    }),
    [t.SET_CREDENTIAL_MANIFEST_LOAD_STATUS]: (state, action) => ({
        ...state,
        common: {
            ...state.common,
            manifest: {
                ...state.common.manifest,
                loadStatus: action.payload
            }
        }
    }),
    [t.RESET_CREDENTIAL_MANIFEST]: (state) => ({
        ...state,
        common: {
            ...state.common,
            manifest: initialState.common.manifest
        }
    }),
    [t.SET_CREDENTIAL_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        credentialForm: {
            ...state.credentialForm,
            credentialOperationInProgress: action.payload
        }
    }),
    [t.RESET_CREDENTIAL_FORM]: (state) => ({
        ...state,
        credentialForm: initialState.credentialForm
    }),
    [t.SET_CREDENTIAL_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        credentialDetails: {
            ...state.credentialDetails,
            loadStatus: action.payload
        }
    }),
    [t.SET_CREDENTIAL_DETAILS]: (state, action) => ({
        ...state,
        credentialDetails: {
            ...state.credentialDetails,
            data: action.payload
        }
    }),
    [t.RESET_CREDENTIAL_DETAILS]: (state) => ({
        ...state,
        credentialDetails: initialState.credentialDetails
    }),
    [t.SET_CREDENTIALS_LIST_ITEMS]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            items: action.payload
        }
    }),
    [t.APPEND_CREDENTIALS_LIST_ITEMS]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            items: state.credentialsList.items.concat(action.payload)
        }
    }),
    [t.SET_CREDENTIALS_LIST_PAGE]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            page: action.payload
        }
    }),
    [t.SET_CREDENTIALS_LIST_COUNT]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            totalCount: action.payload
        }
    }),
    [t.SET_CREDENTIALS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            sortBy: action.payload
        }
    }),
    [t.SET_CREDENTIALS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            sortOrder: action.payload
        }
    }),
    [t.SET_CREDENTIALS_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            loadStatus: action.payload
        }
    }),
    [t.SET_CREDENTIALS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        credentialsList: {
            ...state.credentialsList,
            searchBy: action.payload
        }
    }),
    [t.RESET_CREDENTIALS_LIST]: (state) => ({
        ...state,
        credentialsList: initialState.credentialsList
    })
};

export const initialState = {
    common: {
        manifest: {
            object: {},
            loadStatus: LOAD_STATUS.REQUIRED
        }
    },
    credentialForm: {
        credentialOperationInProgress: false
    },
    credentialDetails: {
        loadStatus: LOAD_STATUS.REQUIRED,
        data: {}
    },
    credentialsList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: TARGET_CREDENTIALS_LIST_PAGE_SIZE,
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
