import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Dialog from 'modules/common/components/Dialog';
import { useAppDispatch } from 'modules/common/hooks';
import { formatString } from 'modules/common/utils';
import { getSelectedClientId } from 'modules/auth/selectors';
import { INSTANCES_ROUTES, INSTANCE_STATE } from '../../constants';
import { getInstanceDependentInstances, getInstanceDetails, getIsInstanceDeleteDialogOpen } from '../../selectors';
import { deleteInstance, closeInstanceDeleteDialog } from '../../actions';

import local from './local.module.scss';

const InstanceDeleteDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const isDialogOpen = useSelector(getIsInstanceDeleteDialogOpen);
    const instances = useSelector(getInstanceDependentInstances);
    const clientId = useSelector(getSelectedClientId);
    const { id: instanceId } = useSelector(getInstanceDetails);

    const closeCallback = useCallback(() => { dispatch(closeInstanceDeleteDialog()); }, []);
    const deleteCallback = useCallback(() => { dispatch(deleteInstance(clientId, instanceId)); }, [ clientId, instanceId ]);

    const intl = useIntl();

    return (
        <Dialog
            open={isDialogOpen}
            title={<FormattedMessage id='instances.deleteInstance' />}
            onClose={closeCallback}
            classes={{ body: local.body }}
        >
            <div className={local.paragraph}>
                <FormattedMessage id='instances.deleteInstanceConfirmation' />
            </div>
            {
                instances.length ?
                    <div className={local.paragraph}>
                        <FormattedMessage id='instances.deleteInstanceDependentTriggers' />
                        <ul className={local.linksContainer}>
                            {instances.map(({ id, name, state }, idx) => (
                                <li key={idx}>
                                    <div className='ellipsis'>
                                        <a className={local.link} href={`#${formatString(INSTANCES_ROUTES.DETAILS, clientId, id)}`}>
                                            {name}
                                        </a>
                                        {
                                            state !== INSTANCE_STATE.ENABLED && ` [${intl.formatMessage({ id: `instances.state.${state}` })}]`
                                        }
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div> :
                    null
            }
            <div className={classnames(local.buttonsContainer, 'container-row')}>
                <div className='ls-button'>
                    <button className='btn-flat' onClick={closeCallback}>
                        <FormattedMessage id='common.cancel' />
                    </button>
                </div>
                <div className='ls-button'>
                    <button className='btn-negative' onClick={deleteCallback}>
                        <FormattedMessage id='common.confirm' />
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default InstanceDeleteDialog;
