import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'modules/common/hooks';
import Dialog from 'modules/common/components/Dialog';
import OwnerMembershipForm from '../OwnerMembershipForm';
import { getIsOwnerMembershipDialogOpen, getMemberDetails } from '../../selectors';
import { addOwnerMembership, closeOwnerMembershipDialog } from '../../actions';

import local from './local.module.scss';

const OwnerMembershipDialog: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const isOwnerMembershipDialogOpen = useSelector(getIsOwnerMembershipDialogOpen);
    const { id } = useSelector(getMemberDetails);

    const onSubmit = useCallback((formValues) => dispatch(addOwnerMembership(id, formValues)), [ id ]);
    const onClose = useCallback(() => { dispatch(closeOwnerMembershipDialog()); }, []);

    return (
        <Dialog
            open={isOwnerMembershipDialogOpen}
            title={<FormattedMessage id='admin.addOwner' />}
            onClose={onClose}
            classes={{ body: local.body }}
        >
            <OwnerMembershipForm onSubmit={onSubmit} />
        </Dialog>
    );
};

export default OwnerMembershipDialog;
