import React, { FunctionComponent, Fragment, ReactElement, useCallback } from 'react';
import { useDropzone, DropzoneState, DropzoneOptions } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import LoadingSpinner from '../LoadingSpinner';

import local from './local.module.scss';

interface FileUploadAreaProps {
    mimeType?: string;
    className?: string;
    additionalText?: string | ReactElement;
    onFileAccepted: Function;
    onFileRejected?: Function;
    uploadInProgress: boolean;
    error?: boolean;
    maxSize?: number;
}

const FileUploadArea: FunctionComponent<FileUploadAreaProps> = ({
    mimeType,
    className,
    additionalText,
    onFileAccepted,
    onFileRejected,
    uploadInProgress,
    error,
    maxSize
}) => {
    const intl = useIntl();

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (uploadInProgress) {
            return;
        }

        if (rejectedFiles.length > 1 || acceptedFiles.length > 1) {
            onFileRejected && onFileRejected([ intl.formatMessage({ id: 'common.uploadMultipleFilesError' }) ]);
        } else if (rejectedFiles.length) {
            const errors = rejectedFiles[0].errors.map(({ message }) => message);
            onFileRejected && onFileRejected(errors);
        } else if (acceptedFiles.length === 1) {
            onFileAccepted(acceptedFiles[0]);
        }
    }, [ uploadInProgress ]);

    const dropzoneOptions: DropzoneOptions = {
        maxFiles: 1,
        maxSize,
        onDrop
    };

    if (mimeType) {
        dropzoneOptions.accept = { [mimeType]: [] };
    }

    const { getRootProps, getInputProps, isDragActive }: DropzoneState = useDropzone(dropzoneOptions);
    const rootProps = getRootProps();

    return (
        <div
            {...rootProps}
            className={classnames(
                local.dropzone,
                className,
                { [local.dragActive]: isDragActive },
                { [local.uploadInProgress]: uploadInProgress },
                { [local.error]: error }
            )}
            onClick={() => {
                // overwrite `onClick` with empty function to prevent opening file browse dialog
            }}
        >
            <input {...getInputProps()} />
            {
                uploadInProgress ?
                    <LoadingSpinner size={34} /> :
                    <FontAwesomeIcon className={local.icon} icon={faFile} />
            }
            <div className={local.uploadText}>
                {
                    uploadInProgress ?
                        <span className={local.uploadInProgress}>
                            <FormattedMessage id='common.uploadInProgress' />
                        </span> :
                        <Fragment>
                            <span className={local.uploadLink} onClick={rootProps.onClick}>
                                <FormattedMessage id='common.uploadMessagePart1' />
                            </span>
                            &nbsp;<FormattedMessage id='common.uploadMessagePart2' />
                        </Fragment>
                }
            </div>
            {
                additionalText && !uploadInProgress ?
                    <div className={local.additionalText}>
                        {additionalText}
                    </div> :
                    null
            }
        </div>
    );
};

export default FileUploadArea;
