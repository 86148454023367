import { connect } from 'react-redux';

import { withLocationState } from 'modules/common/hoc';
import { getConfigurationSectionProps } from '../../selectors';
import ConfigurationSection from './ConfigurationSection';
import * as actions from '../../actions';

const mapDispatchToProps = {
    getSources: actions.getSourcesList,
    getCredentials: actions.getCollectorCredentialsList,
    getSourceDetails: actions.getSourceDetails,
    getCollectorConfigurationManifest: actions.getCollectorConfigurationManifest,
    resetCollectorConfigurationManifest: actions.resetFeedFormCollectorConfigurationManifest
};

export default connect(getConfigurationSectionProps, mapDispatchToProps)(withLocationState(ConfigurationSection));
