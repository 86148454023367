import { formatString } from 'modules/common/utils';
import { FEEDS_ROUTES } from './constants';
import FeedsListLayout from './components/FeedsListLayout';
import FeedDetailsLayout from './components/FeedDetailsLayout';
import FeedFormLayout from './components/FeedFormLayout';
import FeedRunsLayout from './components/FeedRunsLayout';

export default [
    {
        path: FEEDS_ROUTES.LIST,
        component: FeedsListLayout
    },
    {
        path: formatString(FEEDS_ROUTES.DETAILS, ':clientId', ':feedId'),
        component: FeedDetailsLayout
    },
    {
        path: formatString(FEEDS_ROUTES.EDIT, ':clientId', ':feedId'),
        component: FeedFormLayout,
        params: {
            isEditMode: true
        }
    },
    {
        path: formatString(FEEDS_ROUTES.PENDING, ':clientId', ':feedId'),
        component: FeedFormLayout,
        params: {
            isEditMode: true,
            isPendingVersion: true
        }
    },
    {
        path: FEEDS_ROUTES.NEW,
        component: FeedFormLayout,
        params: {
            isEditMode: false
        }
    },
    {
        path: formatString(FEEDS_ROUTES.RUNS, ':clientId'),
        component: FeedRunsLayout
    }
];
