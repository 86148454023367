export const SET_UPLOADS_TYPE = 'uploads/SET_UPLOADS_TYPE';
export const SET_UPLOADS_LIST_ITEMS = 'uploads/SET_UPLOADS_LIST_ITEMS';
export const SET_UPLOADS_LIST_ITEMS_LOAD_STATUS = 'uploads/SET_UPLOADS_LIST_ITEMS_LOAD_STATUS';
export const SET_UPLOADS_LIST_DATE = 'uploads/SET_UPLOADS_LIST_DATE';
export const SET_UPLOAD_IN_PROGRESS = 'uploads/SET_UPLOAD_IN_PROGRESS';
export const SET_UPLOAD_SUCCESS = 'uploads/SET_UPLOAD_SUCCESS';
export const SET_UPLOAD_ERRORS = 'uploads/SET_UPLOAD_ERRORS';
export const SET_UPLOADS_LIST_SORT_COLUMN = 'uploads/SET_UPLOADS_LIST_SORT_COLUMN';
export const SET_UPLOADS_LIST_SORT_ORDER = 'uploads/SET_UPLOADS_LIST_SORT_ORDER';
export const RESET_UPLOADS_LIST = 'uploads/RESET_UPLOADS_LIST';
