import React, { Component } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { intl } from 'modules/i18n';
import { memberApiDefinitions } from 'modules/service/types';
import { AUTOMATION_ID, CONTENT_CONTAINER_MAX_WIDTH, LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { formatString } from 'modules/common/utils';
import { LoadStatus, RouteComponentProps, UserProfile } from 'modules/common/interfaces';
import { Tabs, TabPanel } from 'modules/common/components/Tabs';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import RolesChips from 'modules/admin/components/RolesChips';
import { OWNERS_ROUTES } from 'modules/admin/owners/constants';
import { CLIENTS_ROUTES } from 'modules/admin/clients/constants';
import { ROLE_TYPE } from '../../../constants';
import OwnerMembershipDialog from '../OwnerMembershipDialog';
import ClientMembershipDialog from '../ClientMembershipDialog';
import GroupMembershipDialog from '../GroupMembershipDialog';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const OWNERS_TABLE_INDEX = 0;
const CLIENTS_TABLE_INDEX = 1;
const GROUPS_TABLE_INDEX = 2;

const GROUPS_TABLE_CONFIG = [
    {
        Header: <FormattedMessage id='common.name' />,
        accessor: 'name',
        sortable: false,
        showTooltip: false
    }
];

interface MemberDetailsLayoutProps {
    data: memberApiDefinitions['MemberProfileResponseDto'];
    profile: UserProfile;
    canAddOwner: boolean;
    canAddClient: boolean;
    canAddGroup: boolean;
    isOperationInProgress: boolean;
    loadStatus: LoadStatus;
    loadData: Function;
    resetData: Function;
    goToMembersListPage: Function;
    addOwnerRole: Function;
    deleteOwnerRole: Function;
    deleteOwnerMembership: Function;
    addClientRole: Function;
    deleteClientRole: Function;
    deleteClientMembership: Function;
    openOwnerMembershipDialog: Function;
    openClientMembershipDialog: Function;
    openGroupMembershipDialog: Function;
}

interface RouteMatchParams {
    memberId: string;
}

class MemberDetailsLayout extends Component<MemberDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public state = { selectedTabIndex: 0 };

    public componentDidMount() {
        const { loadData, resetData, match: { params: { memberId } } } = this.props;

        resetData();

        if (this.checkAccess()) {
            loadData(memberId);
        }
    }

    public componentDidUpdate(prevProps) {
        const { match: { params: { memberId: prevMemberId } } } = prevProps;
        const { loadStatus, loadData, resetData, match: { params: { memberId } } } = this.props;

        // in case when member ID in page URL is changed by user, component is not re-mounted, so need to reset data
        if (memberId !== prevMemberId) {
            resetData();
        }

        if (this.checkAccess() && loadStatus === LOAD_STATUS.REQUIRED) {
            loadData(memberId);
        }
    }

    public render() {
        if (!this.checkAccess()) {
            return (
                <ErrorPage error='403' />
            );
        }

        const {
            loadStatus,
            canAddOwner,
            canAddClient,
            canAddGroup,
            isOperationInProgress,
            openOwnerMembershipDialog,
            openClientMembershipDialog,
            openGroupMembershipDialog,
            data: { owners, clients, groups }
        } = this.props;

        const isLoaded = loadStatus === LOAD_STATUS.LOADED;
        const { selectedTabIndex } = this.state;

        return (
            <div>
                {this.renderBreadcrumbs()}
                <div className={classnames(local.tableControls, 'container-row')}>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={(event, idx) => { this.setState({ selectedTabIndex: idx }); }}
                        labels={[
                            <FormattedMessage id='common.owners' />,
                            <FormattedMessage id='common.clients' />,
                            <FormattedMessage id='common.groups' />
                        ]}
                    />
                    <div className={classnames(local.button, 'ls-button')}>
                        {
                            selectedTabIndex === OWNERS_TABLE_INDEX &&
                            <button
                                id={AUTOMATION_ID.ADD_OWNER_BTN}
                                onClick={() => { openOwnerMembershipDialog(); }}
                                disabled={!isLoaded || !canAddOwner || isOperationInProgress}
                            >
                                <FormattedMessage id='admin.addOwner' />
                            </button>
                        }
                        {
                            selectedTabIndex === CLIENTS_TABLE_INDEX &&
                            <button
                                id={AUTOMATION_ID.ADD_CLIENT_BTN}
                                onClick={() => { openClientMembershipDialog(); }}
                                disabled={!isLoaded || !canAddClient || isOperationInProgress}
                            >
                                <FormattedMessage id='admin.addClient' />
                            </button>
                        }
                        {
                            selectedTabIndex === GROUPS_TABLE_INDEX &&
                            <button
                                id={AUTOMATION_ID.ADD_GROUP_BTN}
                                onClick={() => { openGroupMembershipDialog(); }}
                                disabled={!isLoaded || !canAddGroup || isOperationInProgress}
                            >
                                <FormattedMessage id='admin.addGroup' />
                            </button>
                        }
                    </div>
                </div>
                <TabPanel index={OWNERS_TABLE_INDEX} selectedTabIndex={selectedTabIndex}>
                    <Table
                        columns={this.getMembershipsTableConfig(OWNERS_TABLE_INDEX)}
                        data={owners || []}
                        skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                        isLoaded={isLoaded}
                    />
                </TabPanel>
                <TabPanel index={CLIENTS_TABLE_INDEX} selectedTabIndex={selectedTabIndex}>
                    <Table
                        columns={this.getMembershipsTableConfig(CLIENTS_TABLE_INDEX)}
                        data={clients || []}
                        skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                        isLoaded={isLoaded}
                    />
                </TabPanel>
                <TabPanel index={GROUPS_TABLE_INDEX} selectedTabIndex={selectedTabIndex}>
                    <Table
                        columns={GROUPS_TABLE_CONFIG}
                        data={groups || []}
                        skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                        isLoaded={isLoaded}
                    />
                </TabPanel>
                <OwnerMembershipDialog />
                <ClientMembershipDialog />
                <GroupMembershipDialog />
            </div>
        );
    }

    private checkAccess = () => this.props.profile.odpMemberAdministrator;

    private renderBreadcrumbs = () => {
        const { goToMembersListPage, data: { username = '', odpMemberAdministrator } } = this.props;

        let memberLabel = username;
        if (odpMemberAdministrator) {
            memberLabel += ` [${intl.formatMessage({ id: 'admin.memberAdministrator' })}]`;
        }

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_MEMBERS_BREADCRUMB,
                        label: (<FormattedMessage id='admin.allMembers' />),
                        onClick: () => { goToMembersListPage(); }
                    },
                    {
                        label: memberLabel
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private getMembershipsTableConfig = (tableIndex) => [
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='common.name' />}
                />
            ),
            width: '25%',
            skeletonWidth: '100%',
            sortable: false,
            showTooltip: true,
            id: 'name',
            accessor: ({ id, name }) => (
                <a
                    href={`#${formatString(tableIndex === OWNERS_TABLE_INDEX ? OWNERS_ROUTES.DETAILS : CLIENTS_ROUTES.DETAILS, id)}`}
                    className={classnames(local.name, local.link)}
                >
                    {name}
                </a>
            )
        },
        {
            Header: (config) => (
                <SortableTableHeader
                    {...config}
                    title={<FormattedMessage id='admin.roles' />}
                />
            ),
            width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
            skeletonWidth: '100%',
            sortable: false,
            showTooltip: false,
            id: 'roles',
            Cell: (config) => {
                const {
                    addOwnerRole,
                    addClientRole,
                    deleteOwnerRole,
                    deleteClientRole,
                    deleteOwnerMembership,
                    deleteClientMembership,
                    isOperationInProgress,
                    profile: { owners, clients },
                    match: { params: { memberId } }
                } = this.props;

                const { id, roles } = config.row.original;

                let canManage, addRole, deleteRole, deleteMembership, type;
                if (tableIndex === OWNERS_TABLE_INDEX) {
                    canManage = owners?.find(({ id: ownerId }) => ownerId === id)?.permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE);
                    addRole = addOwnerRole;
                    deleteRole = deleteOwnerRole;
                    deleteMembership = deleteOwnerMembership;
                    type = ROLE_TYPE.OWNER;
                } else {
                    canManage = clients?.find(({ id: clientId }) => clientId === id)?.permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE);
                    addRole = addClientRole;
                    deleteRole = deleteClientRole;
                    deleteMembership = deleteClientMembership;
                    type = ROLE_TYPE.CLIENT;
                }

                return (
                    <div className={classnames(local.tableCellContainer, 'container-row')}>
                        <RolesChips
                            editable={canManage}
                            onSelect={
                                (role) => {
                                    addRole(memberId, id, role);
                                }
                            }
                            onRemove={
                                (role) => {
                                    deleteRole(memberId, id, role);
                                }
                            }
                            type={type}
                            selectedRoles={roles}
                            disabled={isOperationInProgress}
                        />
                        <div
                            className={classnames(local.deleteIconContainer, { [local.disabled]: isOperationInProgress || !canManage })}
                            onClick={() => { deleteMembership(memberId, id); }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
                        </div>
                    </div>
                );
            }
        }
    ];
}

export default MemberDetailsLayout;
