import React, { Fragment, FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch } from 'modules/common/hooks';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import LoadingSpinner from 'modules/common/components/LoadingSpinner';
import TableIcon from 'modules/common/components/SvgIcons/TableIcon';
import ViewIcon from 'modules/common/components/SvgIcons/ViewIcon';
import SnapshotIcon from 'modules/common/components/SvgIcons/SnapshotIcon';
import { LOAD_STATUS } from 'modules/common/constants';
import { CATALOG_ITEM_TYPE } from '../../constants';
import { getTablesListProps } from '../../selectors';
import { selectTable } from '../../actions';

import local from './local.module.scss';

interface ListItemProps {
    id: string;
    type: typeof CATALOG_ITEM_TYPE[keyof typeof CATALOG_ITEM_TYPE];
    isSelected: boolean;
    isDataLoading: boolean;
}

const getIcon = (type) => {
    let IconComponent;

    switch (type) {
        case CATALOG_ITEM_TYPE.TABLE:
        case CATALOG_ITEM_TYPE.EXTERNAL:
            IconComponent = TableIcon;
            break;
        case CATALOG_ITEM_TYPE.SNAPSHOT:
            IconComponent = SnapshotIcon;
            break;
        default:
            IconComponent = ViewIcon;
    }

    return <IconComponent className={local.itemIcon} />;
};

const ListItem: FunctionComponent<ListItemProps> = ({ id, type, isSelected, isDataLoading }) => {
    const dispatch = useAppDispatch();

    const clickHandler = useCallback(() => {
        if (!isSelected && !isDataLoading) {
            dispatch(selectTable(id));
        }
    }, [ isSelected, isDataLoading ]);

    return (
        <div
            className={classnames(local.item, { [local.selected]: isSelected, [local.disabled]: isDataLoading }, 'container-row')}
            onClick={clickHandler}
        >
            <div className={classnames(local.itemName, 'container-row')}>
                {getIcon(type)}
                <TextWithTooltip>{id}</TextWithTooltip>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className={local.itemArrowIcon} />
        </div>
    );
};

const TablesList: FunctionComponent = () => {
    const {
        tables,
        loadStatus,
        selectedDatasetId,
        selectedTableId,
        isDataLoading,
        data: { location, lastModified }
    } = useSelector(getTablesListProps);

    return selectedDatasetId ?
        <Fragment>
            <div className={local.title}>
                <TextWithTooltip>{selectedDatasetId}</TextWithTooltip>
            </div>
            {
                loadStatus === LOAD_STATUS.LOADING ?
                    <LoadingSpinner className={local.spinner} /> :
                    <Fragment>
                        <div className={local.propertyName}>
                            <FormattedMessage id='common.location' />
                        </div>
                        <div className={local.propertyValue}>
                            {location}
                        </div>
                        <div className={local.propertyName}>
                            <FormattedMessage id='common.lastUpdated' />
                        </div>
                        <div className={local.propertyValue}>
                            {lastModified && moment(lastModified).format('DD MMM YYYY')}
                        </div>
                        <div className={local.tableTitle}>
                            <FormattedMessage id='dataCatalog.dataTables' />
                        </div>
                        {
                            tables.length ?
                                tables.map(({ id, type }) => (
                                    <ListItem
                                        key={id}
                                        id={id}
                                        type={type}
                                        isSelected={id === selectedTableId}
                                        isDataLoading={isDataLoading}
                                    />
                                )) :
                                <div className={local.noDataMessage}>
                                    <FormattedMessage id='common.noData' />
                                </div>
                        }
                    </Fragment>
            }
        </Fragment> :
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id='dataCatalog.platformDetails' />
            </div>
            <div className={local.noDataMessage}>
                <FormattedMessage id='dataCatalog.noPlatformSelected' />
            </div>
        </Fragment>;
};

export default TablesList;
