import { createSelector } from 'reselect';

import { getUserProfile } from 'modules/auth/selectors';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { SelectInputItem } from 'modules/common/components/SelectInput';
import { REDUCER_NAME as moduleReducerName } from '../../constants';
import { GROUP_ITEM_TYPE, GROUPS_LIST_SORT_ATTRIBUTES_MAP, REDUCER_NAME } from '../constants';

// groups list
export const getGroupsListItems = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.items;
export const getGroupsListPage = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.page;
export const getGroupsListPageSize = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.pageSize;
export const getGroupsListTotalCount = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.totalCount;
export const getGroupsListLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.loadStatus;
export const getGroupsListSortColumn = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.sortBy;
export const getGroupsListSortOrder = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.sortOrder;
export const getGroupsListSearchText = (state) => state[moduleReducerName][REDUCER_NAME].groupsList.searchBy;

// group form
export const getGroupFormClients = (state) => state[moduleReducerName][REDUCER_NAME].groupForm.clients;
export const getGroupFormOwners = (state) => state[moduleReducerName][REDUCER_NAME].groupForm.owners;
export const getGroupFormOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].groupForm.groupOperationInProgress;

// group details
export const getGroupDetails = (state) => state[moduleReducerName][REDUCER_NAME].groupDetails.data;
export const getGroupDetailsLoadStatus = (state) => state[moduleReducerName][REDUCER_NAME].groupDetails.loadStatus;
export const getGroupDetailsOperationInProgress = (state) => state[moduleReducerName][REDUCER_NAME].groupDetails.groupOperationInProgress;
export const getIsAddMembershipsDialogOpen = (state) => state[moduleReducerName][REDUCER_NAME].groupDetails.isAddMembershipsDialogOpen;

export const getGroupsListLayoutProps = createSelector(
    getUserProfile,
    getGroupsListItems,
    getGroupsListLoadStatus,
    getGroupsListTotalCount,
    ({ clients, owners, groups }, items, status, totalCount) => {
        const canView = clients.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW)) ||
            owners.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW));

        const canManage = clients.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)) ||
            owners.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE));

        return {
            items,
            status,
            totalCount,
            canManage,
            canAccess: canView || Boolean(groups.length)
        };
    }
);

export const getGroupsListProps = createSelector(
    getGroupsListItems,
    getGroupsListLoadStatus,
    getGroupsListSortColumn,
    getGroupsListSortOrder,
    (items, status, sortBy, sortOrder) => ({
        status,
        sortBy,
        sortOrder,
        items
    })
);

export const getGroupsListSearchPayload = createSelector(
    getGroupsListPage,
    getGroupsListPageSize,
    getGroupsListSortColumn,
    getGroupsListSortOrder,
    getGroupsListSearchText,
    (page, pageSize, sortBy, ordering, searchString) => ({
        orderBy: [
            {
                attribute: GROUPS_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                ordering
            }
        ],
        searchString,
        page,
        pageSize
    })
);

const getGroupFormLayoutProps = (state, { isEditMode, match: { params: { groupId } } }) => {
    const data = getGroupDetails(state);
    const status = getGroupDetailsLoadStatus(state);
    const isOperationInProgress = getGroupFormOperationInProgress(state);
    const { clients, owners } = getUserProfile(state);

    let canAccess;
    if (isEditMode && status === LOAD_STATUS.LOADED && data.id === groupId) {
        const administeredClientIds = clients.filter(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)).map(({ id }) => id);
        const administeredOwnerIds = owners.filter(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)).map(({ id }) => id);

        canAccess = data.clients.every(({ id }) => administeredClientIds.includes(id)) &&
            data.owners.every(({ id }) => administeredOwnerIds.includes(id));
    } else {
        canAccess = clients.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)) ||
            owners.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE));
    }

    return {
        canAccess,
        isOperationInProgress,
        displayName: data.name || '',
        isDataLoading: status === LOAD_STATUS.LOADING
    };
};

export const makeGroupFormLayoutProps = () => createSelector(
    getGroupFormLayoutProps,
    (object) => object
);

export const getGroupFormProps = createSelector(
    getUserProfile,
    getGroupFormClients,
    getGroupFormOwners,
    getGroupDetails,
    getGroupDetailsLoadStatus,
    ({ clients, owners }, groupClients, groupOwners, data, dataLoadStatus) => ({
        canAssignClients: clients.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)),
        canAssignOwners: owners.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)),
        groupClients,
        groupOwners,
        data,
        dataLoadStatus
    })
);

export const getGroupItemProps = (state, { type, index }) => {
    const groupItems = type === GROUP_ITEM_TYPE.CLIENT ?
        getGroupFormClients(state) :
        getGroupFormOwners(state);

    const isOperationInProgress = getGroupFormOperationInProgress(state);

    if (!groupItems[index]) {
        return { isOperationInProgress, items: [] };
    }

    const { clients, owners } = getUserProfile(state);
    const { id: itemId } = groupItems[index];

    let items: SelectInputItem[];

    if (type === GROUP_ITEM_TYPE.CLIENT) {
        items = clients
            .filter(
                ({ id: clientId, permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE) && (itemId === clientId || !groupItems.some(({ id }) => id === clientId))
            )
            .map(({ id, name }) => ({ id, name }));
    } else {
        items = owners
            .filter(
                ({ id: ownerId, permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE) && (itemId === ownerId || !groupItems.some(({ id }) => id === ownerId))
            )
            .map(({ id, name }) => ({ id, name }));
    }

    return {
        items,
        isOperationInProgress
    };
};

const getGroupDetailsLayoutProps = (state, { match: { params: { groupId } } }) => {
    const data = getGroupDetails(state);
    const status = getGroupDetailsLoadStatus(state);
    const isOperationInProgress = getGroupDetailsOperationInProgress(state);
    const { clients, owners, groups } = getUserProfile(state);

    let canAccess, canManage;
    if (status === LOAD_STATUS.LOADED && data.id === groupId) {
        const clientWithViewPermissionIds = clients.filter(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW)).map(({ id }) => id);
        const ownerWithViewPermissionIds = owners.filter(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW)).map(({ id }) => id);

        canAccess = Boolean(groups.find(({ id }) => id === groupId)) || (
            data.clients.every(({ id }) => clientWithViewPermissionIds.includes(id)) &&
            data.owners.every(({ id }) => ownerWithViewPermissionIds.includes(id))
        );

        const clientWithManagePermissionIds = clients.filter(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)).map(({ id }) => id);
        const ownerWithManagePermissionIds = owners.filter(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.MANAGE)).map(({ id }) => id);

        canManage = data.clients.every(({ id }) => clientWithManagePermissionIds.includes(id)) &&
            data.owners.every(({ id }) => ownerWithManagePermissionIds.includes(id));
    } else {
        canManage = false;
        canAccess = Boolean(groups.find(({ id }) => id === groupId)) ||
            clients.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW)) ||
            owners.some(({ permissions }) => permissions.includes(PERMISSIONS.MEMBERSHIPS.VIEW));
    }

    return {
        data,
        status,
        isOperationInProgress,
        canAccess,
        canManage
    };
};

export const makeGroupDetailsLayoutProps = () => createSelector(
    getGroupDetailsLayoutProps,
    (object) => object
);

const getMemberFormValues = (state) => {
    const { addMembershipsForm } = state.form;

    return addMembershipsForm?.values || {};
};

export const isAddMembershipsFormReadyForSubmission = createSelector(
    getMemberFormValues,
    ({ usernames }) => Boolean(usernames?.trim())
);
