import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { SubmissionError } from 'redux-form';

import { intl } from 'modules/i18n';
import { confirm, isDeepEqual, showErrorSnackbar } from 'modules/common/utils';
import { INGEST_MODE } from 'modules/common/constants';
import { getUserProfile } from 'modules/auth/selectors';
import { GO_LIVE_TYPE, SAVE_MODE, SCHEMA_MODE, SOURCE_ORIGIN } from '../constants';
import { addSource, archiveSource, deleteSource, deleteSourceVersion, editSource, promoteTestSource } from './actions';
import { buildSourceReportFieldPrefix } from '../utils';
import { getSourceDetails, getSourceFormMode, getSourceFormSchema } from '../selectors';

import sourceFormStyles from '../components/SourceFormLayout/local.module.scss';

export const deletePendingSourceVersion = (ownerId, sourceId, versionId) => async (dispatch) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'sources.cancelPendingUpgrade' }),
        messages: [
            intl.formatMessage({ id: 'sources.cancelPendingMessage1' }),
            intl.formatMessage({ id: 'sources.cancelPendingMessage2' })
        ],
        confirmButtonText: intl.formatMessage({ id: 'sources.cancelUpgrade' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'sources.keepPendingUpgrade' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(deleteSourceVersion(ownerId, sourceId, versionId));
    }
};

const validateSourceFormValues = (getState, formValues, mode) => {
    const isDraft = mode === SAVE_MODE.DRAFT;
    const errors: any = {};
    const storeState = getState();

    const requiredFieldsForDraft = [ 'ownerId', 'origin', 'report' ];
    const requiredFields = [ ...requiredFieldsForDraft, 'ingestMode', 'goLiveType' ];
    const requiredArrayFields = [ 'dataCollectorConfigurations', 'primaryKeyColumns' ];

    const { ownerId, origin, report, ingestMode } = formValues;
    const schema = getSourceFormSchema(storeState);

    if (ingestMode === INGEST_MODE.INSERT_OR_OVERWRITE_BY_COLUMNS) {
        requiredArrayFields.push('overwriteByColumns');
    }

    if (origin === SOURCE_ORIGIN.CUSTOM) {
        const { owners } = getUserProfile(storeState);
        const owner = owners.find((item) => item.id === ownerId) || {};

        if (report === buildSourceReportFieldPrefix(owner.name)) {
            errors.report = 'custom.InvalidSourceReport';
        }
    }

    if (isDraft) {
        requiredFieldsForDraft.forEach((fieldName) => {
            if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
                errors[fieldName] = 'custom.Required';
            }
        });
    } else {
        requiredFields.forEach((fieldName) => {
            if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
                errors[fieldName] = 'custom.Required';
            }
        });

        requiredArrayFields.forEach((fieldName) => {
            const values = formValues[fieldName];

            if (!values || !values.length) {
                errors[fieldName] = 'custom.Required';
            }
        });

        const schemaMode = getSourceFormMode(storeState);
        const fieldNames = schema?.fields ? schema.fields.map(({ name }) => name.toLowerCase()) : [];

        if (fieldNames.length !== (new Set(fieldNames)).size) {
            schemaMode === SCHEMA_MODE.SIMPLE ?
                errors.schemaHiddenError = 'custom.InvalidSchema' :
                errors.schema = 'SRC056';
        }

        fieldNames.forEach((name, idx) => {
            if (!name) {
                errors[`schemaFields_${idx}`] = 'custom.Required';
            }
        });
    }

    return Object.keys(errors).length ? errors : undefined;
};

export const confirmAddSource = (formValues, saveMode, testMode) => async (dispatch, getState) => {
    const errors = validateSourceFormValues(getState, formValues, saveMode);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    const { goLiveType, goLiveDate } = formValues;

    if (saveMode === SAVE_MODE.LIVE) {
        const confirmation = await confirm({
            title: intl.formatMessage({ id: 'sources.submitSource' }),
            messages: [
                <Fragment key='msg1'>
                    {
                        goLiveType === GO_LIVE_TYPE.DATE ?
                            <Fragment>
                                <FormattedMessage id='sources.plannedToGoLiveOn' />
                                &nbsp;
                                <span className={sourceFormStyles.dialogBoldText}>{goLiveDate.format('D MMM YYYY')}</span>
                            </Fragment> :
                            <Fragment>
                                <FormattedMessage id='sources.plannedToGoLive' />
                                &nbsp;
                                <span className={sourceFormStyles.dialogBoldText}><FormattedMessage id='common.now' /></span>
                            </Fragment>
                    }
                </Fragment>
            ],
            confirmButtonText: intl.formatMessage({ id: 'common.submit' }),
            cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
            cancelButtonClass: 'btn-flat'
        });

        if (confirmation) {
            return dispatch(addSource(formValues, saveMode, testMode));
        }
    } else {
        return dispatch(addSource(formValues, saveMode, testMode));
    }
};

export const confirmEditSource = (sourceId, versionId, formValues, saveMode) => async (dispatch, getState) => {
    const errors = validateSourceFormValues(getState, formValues, saveMode);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    const storeState = getState();
    const data = getSourceDetails(storeState);
    const mode = getSourceFormMode(storeState);
    const schemaObj = getSourceFormSchema(storeState);

    if (!data.ingestionSettings) {
        return null;
    }

    const {
        ingestionSettings: {
            primaryKeyColumns,
            clusterColumns,
            partitionColumn
        },
        schema,
        schemaFields
    } = data;

    let isSchemaChanged = mode === SCHEMA_MODE.ADVANCED ?
        formValues.schema !== schema :
        !isDeepEqual(schemaObj.fields, schemaFields);

    isSchemaChanged = isSchemaChanged || (
        !isEqual(primaryKeyColumns, formValues.primaryKeyColumns) ||
        !isEqual(clusterColumns, formValues.clusterColumns) ||
        partitionColumn !== formValues.partitionColumn
    );

    const { goLiveType, goLiveDate } = formValues;

    if (saveMode === SAVE_MODE.LIVE) {
        const confirmation = await confirm({
            title: intl.formatMessage({ id: 'common.submitChanges' }),
            messages: [
                <Fragment key='msg1'>
                    {
                        goLiveType === GO_LIVE_TYPE.DATE ?
                            <Fragment>
                                <FormattedMessage id='sources.plannedToGoLiveOn' />
                                &nbsp;
                                <span className={sourceFormStyles.dialogBoldText}>{goLiveDate.format('D MMM YYYY')}</span>
                            </Fragment> :
                            <Fragment>
                                <FormattedMessage id='sources.plannedToGoLive' />
                                &nbsp;
                                <span className={sourceFormStyles.dialogBoldText}><FormattedMessage id='common.now' /></span>
                                <div className={sourceFormStyles.dialogWarningText}><FormattedMessage id='sources.disableFeedWarning' /></div>
                            </Fragment>
                    }
                </Fragment>,
                isSchemaChanged && <FormattedMessage id='sources.schemaValidationMessage' />,
                <FormattedMessage key='msg2' id='sources.feedOwnersNotificationMessage' />
            ],
            confirmButtonText: intl.formatMessage({ id: 'common.submit' }),
            cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
            cancelButtonClass: 'btn-flat'
        });

        if (confirmation) {
            return dispatch(editSource(sourceId, versionId, formValues, saveMode));
        }
    } else {
        return dispatch(editSource(sourceId, versionId, formValues, saveMode));
    }
};

export const confirmDeleteSource = (ownerId, sourceId) => async (dispatch) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'sources.deleteSource' }),
        messages: [ intl.formatMessage({ id: 'sources.deleteSourceConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'sources.deleteSource' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(deleteSource(ownerId, sourceId));
    }
};

export const confirmArchiveSource = (ownerId, sourceId) => async (dispatch) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'sources.archiveSource' }),
        messages: [ intl.formatMessage({ id: 'sources.archiveSourceConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'sources.archiveSource' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(archiveSource(ownerId, sourceId));
    }
};

export const confirmPromoteTestSource = (ownerId, sourceId) => async (dispatch) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'sources.promoteSourceDialogTitle' }),
        messages: [ intl.formatMessage({ id: 'sources.promoteSourceDialogMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.promote' }),
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(promoteTestSource(ownerId, sourceId));
    }
};
