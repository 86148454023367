import React, { Fragment, FunctionComponent, useCallback, useState } from 'react';
import classnames from 'classnames';
import { WrappedFieldProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/sql/sql';

import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import { intl } from 'modules/i18n';
import { removeSyncError } from 'modules/form/actions';
import { ingestionApiDefinitions } from 'modules/service/types';
import { useAppDispatch } from 'modules/common/hooks';
import { validateApSql } from '../../actions';

import local from './local.module.scss';

interface ApSqlInputProps extends WrappedFieldProps {
    disabled: boolean;
    formName: string;
    config: ingestionApiDefinitions['Field'];
    customSqlValidator?: Function;
}

const ApSqlInput: FunctionComponent<ApSqlInputProps> = ({ config, input, meta, disabled, formName, customSqlValidator }) => {
    const dispatch = useAppDispatch();
    const [validating, setValidating] = useState(false);

    const validate = useCallback(async (event) => {
        event.preventDefault();

        setValidating(true);

        if (customSqlValidator) {
            await customSqlValidator(formName, input.name, input.value);
        } else {
            await dispatch(validateApSql(formName, input.name, input.value));
        }

        setValidating(false);
    }, [ formName, input, setValidating, customSqlValidator ]);

    const onChange = useCallback((editor, data, value) => {
        if (meta.error) {
            dispatch(removeSyncError({
                form: formName,
                field: input.name
            }));
        }

        input.onChange(value);
    }, [ formName, input, meta ]);

    const { id, label, description } = config;
    const { error } = meta;

    return (
        <Fragment>
            <div className={classnames('container-row', local.titleContainer)}>
                {
                    (label || id) &&
                    <div className={local.label}>
                        <FormattedMessage id={input.name} defaultMessage={label || id} />
                        <IconWithTooltip className={local.transformationQueryTooltip}>
                            <FormattedMessage id='instances.baseAndBaseMergeTransformationPromptA' />
                            <br />
                            <FormattedMessage
                                id='instances.baseAndBaseMergeTransformationPromptB'
                                values={{ b: (chunks) => <strong>{chunks}</strong> }}
                            />
                            <br />
                            <FormattedMessage id='instances.baseAndBaseMergeTransformationPromptC' />
                            <br />
                            <FormattedMessage
                                id='instances.baseAndBaseMergeTransformationPromptD'
                                values={{ b: (chunks) => <strong>{chunks}</strong> }}
                            />
                            <br />
                            <FormattedMessage
                                id='instances.baseAndBaseMergeTransformationPromptE'
                                values={{ b: (chunks) => <strong>{chunks}</strong> }}
                            />
                        </IconWithTooltip>
                    </div>
                }
                <div className={classnames('ls-button', local.validateButton)}>
                    <button
                        disabled={!input.value || disabled || validating}
                        onClick={validate}
                    >
                        {
                            validating ?
                                <FormattedMessage id='common.validating' /> :
                                <FormattedMessage id='instances.validateApSql' />
                        }
                    </button>
                </div>
            </div>
            <CodeMirror
                value={input.value}
                options={{
                    mode: 'sql',
                    theme: 'idea',
                    lineNumbers: false,
                    lineWrapping: true,
                    readOnly: disabled ? 'nocursor' : false
                }}
                onBeforeChange={onChange}
                className={
                    classnames(local.editor, {
                        [local.disabled]: disabled || validating,
                        [local.error]: Boolean(meta.error)
                    })
                }
            />
            {
                description || error ?
                    <div className={local.message}>
                        {
                            error ?
                                <span className={classnames(local.error, 'form-error-message')}>
                                    <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                                </span> :
                                intl.messages[`${input.name}.description`] ?
                                    <FormattedMessage id={`${input.name}.description`} /> :
                                    <span dangerouslySetInnerHTML={{ __html: description! }} />
                        }
                    </div> :
                    null
            }
        </Fragment>
    );
};

export default ApSqlInput;
