import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import classnames from 'classnames';

import { formatString } from 'modules/common/utils';

import local from './local.module.scss';

interface LoadMoreButtonProps {
    intl: IntlShape;
    load: Function;
    count: number;
    totalCount: number;
}

const LoadMoreButton: FunctionComponent<LoadMoreButtonProps> = ({ intl, load, count, totalCount }) => (
    <Fragment>
        <div className={classnames(local.button, 'ls-button')}>
            <button className='btn-transparent' onClick={() => { load(); }}>
                <FormattedMessage id='common.loadMore' />
            </button>
        </div>
        <div className={local.itemsCount}>
            {formatString(intl.formatMessage({ id: 'common.itemsCountTemplate' }), count, totalCount)}
        </div>
    </Fragment>
);

export default LoadMoreButton;
