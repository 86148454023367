// target credential form
export const SET_CREDENTIAL_FORM_OPERATION_IN_PROGRESS = 'targetCredentials/SET_CREDENTIAL_FORM_OPERATION_IN_PROGRESS';
export const RESET_CREDENTIAL_FORM = 'targetCredentials/RESET_CREDENTIAL_FORM';

// target credential details
export const SET_CREDENTIAL_DETAILS = 'targetCredentials/SET_CREDENTIAL_DETAILS';
export const SET_CREDENTIAL_DETAILS_LOAD_STATUS = 'targetCredentials/SET_CREDENTIAL_DETAILS_LOAD_STATUS';
export const RESET_CREDENTIAL_DETAILS = 'targetCredentials/RESET_CREDENTIAL_DETAILS';

// common
export const SET_CREDENTIAL_MANIFEST = 'targetCredentials/SET_CREDENTIAL_MANIFEST';
export const SET_CREDENTIAL_MANIFEST_LOAD_STATUS = 'targetCredentials/SET_CREDENTIAL_MANIFEST_LOAD_STATUS';
export const RESET_CREDENTIAL_MANIFEST = 'targetCredentials/RESET_CREDENTIAL_MANIFEST';

// target credentials list
export const APPEND_CREDENTIALS_LIST_ITEMS = 'targetCredentials/APPEND_CREDENTIALS_LIST_ITEMS';
export const SET_CREDENTIALS_LIST_ITEMS = 'targetCredentials/SET_CREDENTIALS_LIST_ITEMS';
export const SET_CREDENTIALS_LIST_PAGE = 'targetCredentials/SET_CREDENTIALS_LIST_PAGE';
export const SET_CREDENTIALS_LIST_COUNT = 'targetCredentials/SET_CREDENTIALS_LIST_COUNT';
export const SET_CREDENTIALS_LIST_LOAD_STATUS = 'targetCredentials/SET_CREDENTIALS_LIST_LOAD_STATUS';
export const SET_CREDENTIALS_LIST_SORT_COLUMN = 'targetCredentials/SET_CREDENTIALS_LIST_SORT_COLUMN';
export const SET_CREDENTIALS_LIST_SORT_ORDER = 'targetCredentials/SET_CREDENTIALS_LIST_SORT_ORDER';
export const SET_CREDENTIALS_LIST_SEARCH_TEXT = 'targetCredentials/SET_CREDENTIALS_LIST_SEARCH_TEXT';
export const RESET_CREDENTIALS_LIST = 'targetCredentials/RESET_CREDENTIALS_LIST';
