import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import service from 'modules/service';
import ErrorPage from 'modules/common/components/ErrorPage';
import Breadcrumbs from 'modules/common/components/Breadcrumbs';
import { AUTOMATION_ID, EVENT_CATEGORY, PERMISSIONS } from 'modules/common/constants';
import FormSubmitButton from 'modules/common/components/FormSubmitButton';
import { Client, RouteComponentProps } from 'modules/common/interfaces';
import FeedForm from '../FeedForm';

import local from './local.module.scss';

interface FeedFormLayoutProps {
    isEditMode: boolean;
    isPendingVersion: boolean;
    isDataLoading: boolean;
    isOperationInProgress: boolean;
    goToFeedsListPage: Function;
    goToFeedDetailsPage: Function;
    addFeed: Function;
    editFeed: Function;
    clearSubmitErrors: Function;
    client: Client;
    displayName;
}

interface RouteMatchParams {
    clientId: string;
    feedId: string;
}

class FeedFormLayout extends Component<FeedFormLayoutProps & RouteComponentProps<RouteMatchParams>> {
    public render() {
        if (!this.checkAccess()) {
            return (
                <ErrorPage error='403' />
            );
        }

        const { isEditMode, isPendingVersion, isDataLoading, isOperationInProgress, match } = this.props;

        return (
            <Fragment>
                {
                    this.renderBreadcrumbs()
                }
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            <FormattedMessage id={isEditMode ? (isPendingVersion ? 'feeds.upgradeFeed' : 'feeds.editFeed') : 'feeds.newFeed'} />
                        </div>
                        <div className={classnames(local.buttonsContainer, 'container-row')}>
                            <div className='ls-button'>
                                <button
                                    disabled={isOperationInProgress}
                                    className='btn-flat'
                                    onClick={this.onCancelButtonClick}
                                >
                                    <FormattedMessage id='common.cancel' />
                                </button>
                            </div>
                            <FormSubmitButton
                                form='feedForm'
                                width={130}
                                disabled={isDataLoading || isOperationInProgress}
                                text={<FormattedMessage id='common.save' />}
                                isSubmitting={isOperationInProgress}
                            />
                        </div>
                    </div>
                    <FeedForm
                        onSubmit={this.onFormSubmit}
                        isEditMode={isEditMode}
                        isPendingVersion={isPendingVersion}
                        match={match}
                    />
                </div>
            </Fragment>
        );
    }

    private renderBreadcrumbs = () => {
        const { goToFeedsListPage, isEditMode, displayName } = this.props;

        return (
            <Breadcrumbs
                items={[
                    {
                        id: AUTOMATION_ID.ALL_FEEDS_BREADCRUMB,
                        label: (<FormattedMessage id='feeds.allFeeds' />),
                        onClick: () => { goToFeedsListPage(); }
                    },
                    {
                        label: isEditMode ? displayName : (<FormattedMessage id='feeds.newFeed' />)
                    }
                ]}
                selectedItemIndex={1}
            />
        );
    }

    private checkAccess = () => {
        return this.props.client.permissions.includes(PERMISSIONS.FEEDS.MANAGE);
    }

    private onFormSubmit = (values) => {
        const { isEditMode, editFeed, addFeed, clearSubmitErrors, match: { params: { feedId } } } = this.props;

        clearSubmitErrors('feedForm');

        return isEditMode ? editFeed(feedId, values) : addFeed(values);
    }

    private onCancelButtonClick = () => {
        const { isEditMode, goToFeedDetailsPage, goToFeedsListPage, match } = this.props;

        if (isEditMode) {
            const { feedId } = match.params;
            goToFeedDetailsPage(feedId);

            service.analytics.trackEvent('Cancel feed edit', EVENT_CATEGORY.FEEDS);
        } else {
            goToFeedsListPage();

            service.analytics.trackEvent('Cancel feed creation', EVENT_CATEGORY.FEEDS);
        }
    }
}

export default FeedFormLayout;
