import React, { FunctionComponent, PropsWithChildren } from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

import { SnackbarUtilsConfigurator } from 'modules/common/utils';

import local from './local.module.scss';

const SnackbarProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => (
    <NotistackSnackbarProvider
        maxSnack={3}
        hideIconVariant={true}
        autoHideDuration={3000}
        anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
        }}
        classes={{
            root: local.snackbarRoot,
            containerRoot: local.snackbarContainerRoot
        }}
    >
        <SnackbarUtilsConfigurator />
        {children}
    </NotistackSnackbarProvider>
);

export default SnackbarProvider;
