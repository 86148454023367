import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import * as actions from '../../actions';
import { getCollectorCredentialFormProps } from '../../selectors';
import CollectorCredentialForm from './CollectorCredentialForm';

const mapDispatchToProps = {
    getCollectors: actions.getCollectorsList,
    getManifest: actions.getCollectorCredentialManifest,
    getCredentialDataForEditing: actions.getCollectorCredentialDataForEditing,
    resetForm: actions.resetCollectorCredentialForm,
    resetData: actions.resetCollectorCredentialDetails
};

export default reduxForm({
    form: 'collectorCredentialForm'
})(injectIntl(connect(getCollectorCredentialFormProps, mapDispatchToProps)(CollectorCredentialForm)) as any) as any;
