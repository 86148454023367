import { push } from 'redux-first-history';
import { FormErrors, SubmissionError } from 'redux-form';

import service from 'modules/service';
import { intl } from 'modules/i18n';
import { EVENT_CATEGORY, HTTP_CODE, LOAD_STATUS, SECRET_VALUE_PLACEHOLDER, SORT_ORDER } from 'modules/common/constants';
import { confirm, formatString, getErrorText, prepareFormErrors, showErrorSnackbar } from 'modules/common/utils';
import { getRouterPath } from 'modules/common/selectors';
import { memberApiDefinitions } from 'modules/service/types';
import { getUserProfile } from 'modules/auth/selectors';
import { loadUserProfile } from 'modules/auth/actions';
import { CLIENTS_ROUTES } from '../constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';

export const getClients = () => async (dispatch) => {
    dispatch(storeActions.setClientsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const { clients }: memberApiDefinitions['ClientsResponseDto'] = await service.api.getClients();
        dispatch(storeActions.setClients(clients));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setClientsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const goToClientDetailsPage = (id) => (dispatch) => {
    dispatch(push(formatString(CLIENTS_ROUTES.DETAILS, id)));
};

export const goToClientsListPage = () => (dispatch) => {
    dispatch(push(CLIENTS_ROUTES.LIST));
};

export const goToAddClientPage = () => (dispatch) => {
    dispatch(push(CLIENTS_ROUTES.NEW));
};

export const goToEditClientPage = (id) => (dispatch) => {
    dispatch(push(formatString(CLIENTS_ROUTES.EDIT, id)));
};

const validateClientFormValues = (formValues) => {
    const requiredFields = [
        'name',
        'projectId',
        'projectRegion',
        'serviceAccount',
        'bucketName',
        'region',
        'transformationServiceAccount',
        'cloudComposerEnvironment'
    ];
    const errors = {};

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

export const addClient = (formValues) => async (dispatch, getState) => {
    const errors = validateClientFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setClientFormOperationInProgress(true));

    try {
        const {
            name,
            projectId,
            projectRegion,
            bucketName,
            serviceAccount,
            region,
            transformationServiceAccount,
            username,
            password,
            cloudComposerEnvironment
        } = formValues;

        const payload: memberApiDefinitions['ClientCreateRequestDto'] = {
            name,
            projectId,
            projectRegion,
            region,
            transformationServiceAccount,
            cloudComposerEnvironment,
            dataCollection: {
                bucketName,
                serviceAccount
            }
        };

        payload.adverityCredentials = username || password ? { username, password } : {};

        const { id: clientId } = await service.api.addClient(payload);

        service.analytics.trackEvent('Create client', EVENT_CATEGORY.ADMIN);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === CLIENTS_ROUTES.NEW) {
            dispatch(goToClientDetailsPage(clientId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST || status === HTTP_CODE.CONFLICT) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(
                status === HTTP_CODE.BAD_REQUEST ? prepareFormErrors(data) : { name: 'custom.ClientAlreadyExists' } as FormErrors
            );
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setClientFormOperationInProgress(false));
    }
};

export const addClientMember = (clientId, { username, roles }) => async (dispatch, getState) => {
    dispatch(storeActions.setClientMembershipsListOperationInProgress(true));

    try {
        await service.api.addClientMembershipByUsername(clientId, { username, roles });

        service.analytics.trackEvent('Add client member', EVENT_CATEGORY.ADMIN);

        const { username: currentUserName } = getUserProfile(getState());
        if (username === currentUserName) {
            await dispatch(loadUserProfile());
        }

        dispatch(storeActions.resetClientDetails());
        dispatch(storeActions.closeClientMembershipDialog());
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            throw new SubmissionError(prepareFormErrors(data));
        } else if (status === HTTP_CODE.NOT_FOUND) {
            // TODO remove this when proper validation error will be implemented on BE
            throw new SubmissionError({ username: 'custom.MemberNotFound' });
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setClientMembershipsListOperationInProgress(false));
    }
};

export const clientsListSearch = (text) => (dispatch, getState) => {
    const { searchBy } = selectors.getClientsListTableState(getState());

    if (text !== searchBy) {
        dispatch(storeActions.setClientsListPage(0));
        dispatch(storeActions.setClientsListSearchText(text));

        service.analytics.trackEvent('Clients list search', EVENT_CATEGORY.ADMIN);
    }
};

export const clientsListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getClientsListTableState(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setClientsListSortOrder(order));
    } else {
        dispatch(storeActions.setClientsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setClientsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setClientsListPage(0));

    service.analytics.trackEvent('Clients list sort', EVENT_CATEGORY.ADMIN);
};

export const loadMoreClients = () => (dispatch, getState) => {
    const { page } = selectors.getClientsListTableState(getState());

    dispatch(storeActions.setClientsListPage(page + 1));

    service.analytics.trackEvent('Load more clients', EVENT_CATEGORY.ADMIN);
};

export const clientMembershipsListSearch = (text) => (dispatch, getState) => {
    const { searchBy } = selectors.getClientMembershipsListTableState(getState());

    if (text !== searchBy) {
        dispatch(storeActions.setClientMembershipsListPage(0));
        dispatch(storeActions.setClientMembershipsListSearchText(text));

        service.analytics.trackEvent('Client memberships list search', EVENT_CATEGORY.ADMIN);
    }
};

export const clientMembershipsListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getClientMembershipsListTableState(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setClientMembershipsListSortOrder(order));
    } else {
        dispatch(storeActions.setClientMembershipsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setClientMembershipsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setClientMembershipsListPage(0));

    service.analytics.trackEvent('Client memberships list sort', EVENT_CATEGORY.ADMIN);
};

export const loadMoreClientMemberships = () => (dispatch, getState) => {
    const { page } = selectors.getClientMembershipsListTableState(getState());

    dispatch(storeActions.setClientMembershipsListPage(page + 1));

    service.analytics.trackEvent('Load more client memberships', EVENT_CATEGORY.ADMIN);
};

export const resetClientMembershipsTable = () => (dispatch) => {
    dispatch(storeActions.setClientMembershipsListSearchText(''));
    dispatch(storeActions.setClientMembershipsListSortOrder(SORT_ORDER.ASC));
    dispatch(storeActions.setClientMembershipsListPage(0));
};

export const getClientDetails = (clientId) => async (dispatch) => {
    dispatch(storeActions.setClientDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details: memberApiDefinitions['ClientDetailsResponseDto'] = await service.api.getClientDetails(clientId);

        dispatch(storeActions.setClientDetails(details));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setClientDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const editClient = (clientId, formValues) => async (dispatch, getState) => {
    const errors = validateClientFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setClientFormOperationInProgress(true));

    try {
        const { adverityCredentials, hasDependencies } = selectors.getClientDetails(getState());

        const {
            name,
            projectId,
            projectRegion,
            bucketName,
            serviceAccount,
            region,
            transformationServiceAccount,
            username,
            password,
            cloudComposerEnvironment
        } = formValues;

        const payload: memberApiDefinitions['ClientUpdateRequestDto'] = hasDependencies ? {
            name,
            transformationServiceAccount,
            cloudComposerEnvironment,
            dataCollection: {
                bucketName,
                serviceAccount
            }
        } : {
            name,
            projectId,
            projectRegion,
            region,
            transformationServiceAccount,
            cloudComposerEnvironment,
            dataCollection: {
                bucketName,
                serviceAccount
            }
        };

        if (serviceAccount === SECRET_VALUE_PLACEHOLDER) {
            delete payload.dataCollection!.serviceAccount;
        }

        if (transformationServiceAccount === SECRET_VALUE_PLACEHOLDER) {
            delete payload.transformationServiceAccount;
        }

        if (username || password) {
            if (password === SECRET_VALUE_PLACEHOLDER) { // password was present and user did not change it
                /**
                 * if username is present, we submit only it to keep existing password
                 * otherwise, user tries to submit empty username without changing the password, so we submit fake
                 * password to force BE validation error
                 */
                payload.adverityCredentials = username ? { username } : { password };
            } else {
                payload.adverityCredentials = { username, password: password || '' };
            }
        }

        if (adverityCredentials && !payload.adverityCredentials) {
            payload.adverityCredentials = {}; // if we had Adverity credentials and don't have it now, we submit empty DTO to overwrite it
        }

        await service.api.editClient(clientId, payload);

        service.analytics.trackEvent('Edit client', EVENT_CATEGORY.ADMIN);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(CLIENTS_ROUTES.EDIT, clientId)) {
            dispatch(goToClientDetailsPage(clientId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST || status === HTTP_CODE.CONFLICT) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(
                status === HTTP_CODE.BAD_REQUEST ? prepareFormErrors(data) : { name: 'custom.ClientAlreadyExists' } as FormErrors
            );
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setClientFormOperationInProgress(false));
    }
};

export const deleteClient = (clientId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'admin.deleteClient' }),
        messages: [ intl.formatMessage({ id: 'admin.deleteClientConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.delete' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        try {
            dispatch(storeActions.setClientMembershipsListOperationInProgress(true));

            await service.api.deleteClient(clientId);

            service.analytics.trackEvent('Delete client', EVENT_CATEGORY.ADMIN);

            const { clients } = getUserProfile(getState());
            const client = clients.find((item) => item.id === clientId);
            if (client) {
                await dispatch(loadUserProfile());
            }

            const path = getRouterPath(getState());

            // prevent changing location if user is not on this page anymore
            if (path === formatString(CLIENTS_ROUTES.DETAILS, clientId)) {
                dispatch(goToClientsListPage());
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setClientMembershipsListOperationInProgress(false));
        }
    }
};

export const clientGroupsListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getClientGroupsListTableState(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setClientGroupsListSortOrder(order));
    } else {
        dispatch(storeActions.setClientGroupsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setClientGroupsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setClientGroupsListPage(0));

    service.analytics.trackEvent('Client groups list sort', EVENT_CATEGORY.ADMIN);
};

export const loadMoreClientGroups = () => (dispatch, getState) => {
    const { page } = selectors.getClientGroupsListTableState(getState());

    dispatch(storeActions.setClientGroupsListPage(page + 1));

    service.analytics.trackEvent('Load more client groups', EVENT_CATEGORY.ADMIN);
};
