import React, { FunctionComponent, Fragment } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useReduxFormValue } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import { CUSTOM_VALUE } from '../../constants';
import { setReportCollectorCredentialFormData, setReportCollectorCredentialFormDrawerOpen } from '../../actions';
import {
    getReportFormCollectorCredentials,
    getReportFormCollectorCredentialsLoadStatus,
    getReportFormOperationInProgress
} from '../../selectors';

import local from './local.module.scss';

type CredentialSelectFieldProps = WrappedFieldProps & {
    items: SelectInputItem[];
    disabled: boolean;
    isLoading: boolean;
    collectorId: string;
    vieCollectorId: string;
    platformPrefix: string;
};

interface ReportPlatformCollectorCredentialProps {
    platformPrefix: string;
    platformName: string;
    collectorName?: string;
    collectorId: string;
    vieCollectorId: string;
}

const CredentialSelectField: FunctionComponent<CredentialSelectFieldProps> = ({
    input,
    meta,
    items,
    disabled,
    isLoading,
    collectorId,
    vieCollectorId,
    platformPrefix
}) => {
    const dispatch = useAppDispatch();

    return (
        <Fragment>
            <SelectInput
                inputProperties={input}
                items={items}
                disabled={disabled}
                error={meta.error}
                isLoading={isLoading}
                width={400}
                menuButton={
                    <div className={local.addCredentialButton}
                        onClick={() => {
                            dispatch(setReportCollectorCredentialFormData({
                                platformPrefix,
                                collectorId,
                                vieCollectorId
                            }));
                            dispatch(setReportCollectorCredentialFormDrawerOpen(true));
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className={local.plusIcon} />
                        <FormattedMessage id='common.newCredential' />
                    </div>
                }
            />
            {
                meta.error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                </div>
            }
        </Fragment>
    );
};

const WarningMessage: FunctionComponent<WrappedFieldProps> = ({ input }) => input.value ? (
    <div className={local.warning}>
        <FormattedMessage id='reports.platformCredentialWarning' />
    </div>
) : null;

const ReportPlatformCollectorCredential: FunctionComponent<ReportPlatformCollectorCredentialProps> = ({
    platformPrefix,
    platformName,
    collectorName,
    collectorId,
    vieCollectorId
}) => {
    const intl = useIntl();

    const credentials = useSelector(getReportFormCollectorCredentials);
    const credentialsLoadStatus = useSelector(getReportFormCollectorCredentialsLoadStatus);
    const isOperationInProgress = useSelector(getReportFormOperationInProgress);

    const customChangesFieldName = `platforms.${platformPrefix}.collectors.${collectorId}.customChanges`;
    const hasCustomChanges = useReduxFormValue('reportForm', customChangesFieldName);

    const items = credentials
        .filter(({ collectorId: credentialCollectorId }) => credentialCollectorId === vieCollectorId)
        .map(({ id, name }) => ({ id, name }));

    if (hasCustomChanges) {
        items.push({
            id: CUSTOM_VALUE,
            name: intl.formatMessage({ id: 'common.custom' })
        });
    }

    return (
        <Fragment>
            <Field
                name={customChangesFieldName}
                component={WarningMessage}
            />
            <div className={local.label}>
                {
                    collectorName ? `${platformName} - ${collectorName}` : platformName
                }
            </div>
            <div className={local.smallLabel}>
                <FormattedMessage id='common.credential' />
            </div>
            <Field
                name={`platforms.${platformPrefix}.collectors.${collectorId}.credentialId`}
                component={CredentialSelectField}
                items={items}
                disabled={isOperationInProgress}
                isLoading={credentialsLoadStatus !== LOAD_STATUS.LOADED}
                collectorId={collectorId}
                vieCollectorId={vieCollectorId}
                platformPrefix={platformPrefix}
            />
        </Fragment>
    );
};

export default ReportPlatformCollectorCredential;
