import { connect } from 'react-redux';

import { getClientMembershipsListProps } from '../../selectors';
import {
    addClientRole,
    deleteClientRole,
    deleteClientMembership,
    clientMembershipsListSort,
    loadMoreClientMemberships
} from '../../actions';
import ClientMembershipsList from './ClientMembershipsList';

const mapDispatchToProps = {
    addClientRole,
    deleteClientRole,
    deleteClientMembership,
    loadMore: loadMoreClientMemberships,
    onSort: clientMembershipsListSort
};

export default connect(getClientMembershipsListProps, mapDispatchToProps)(ClientMembershipsList);
