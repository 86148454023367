import * as t from '../actionTypes';
import { MEMBERS_LIST_PAGE_SIZE } from '../constants';
import { LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';

const ACTION_HANDLERS = {
    [t.SET_MEMBERS_LIST_ITEMS]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            items: action.payload
        }
    }),
    [t.APPEND_MEMBERS_LIST_ITEMS]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            items: state.membersList.items.concat(action.payload)
        }
    }),
    [t.SET_MEMBERS_LIST_PAGE]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            page: action.payload
        }
    }),
    [t.SET_MEMBERS_LIST_COUNT]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            totalCount: action.payload
        }
    }),
    [t.SET_MEMBERS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            sortBy: action.payload
        }
    }),
    [t.SET_MEMBERS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            sortOrder: action.payload
        }
    }),
    [t.SET_MEMBERS_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            loadStatus: action.payload
        }
    }),
    [t.SET_MEMBERS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            searchBy: action.payload
        }
    }),
    [t.RESET_MEMBERS_LIST]: (state) => ({
        ...state,
        membersList: initialState.membersList
    }),
    [t.SET_MEMBERS_LIST_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        membersList: {
            ...state.membersList,
            memberOperationInProgress: action.payload
        }
    }),
    [t.OPEN_MEMBER_DIALOG]: (state) => ({
        ...state,
        membersList: {
            ...state.membersList,
            isMemberDialogOpen: true
        }
    }),
    [t.CLOSE_MEMBER_DIALOG]: (state) => ({
        ...state,
        membersList: {
            ...state.membersList,
            isMemberDialogOpen: false
        }
    }),
    [t.SET_MEMBER_DETAILS]: (state, action) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            data: action.payload
        }
    }),
    [t.SET_MEMBER_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            loadStatus: action.payload
        }
    }),
    [t.RESET_MEMBER_DETAILS]: (state) => ({
        ...state,
        memberDetails: initialState.memberDetails
    }),
    [t.SET_MEMBER_DETAILS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            memberOperationInProgress: action.payload
        }
    }),
    [t.ADD_OWNER_MEMBER_ROLE]: (state, action) => {
        const { ownerId, role } = action.payload;

        const updatedItems = state.memberDetails.data.owners?.map((item) => {
            if (item.id === ownerId) {
                return { ...item, roles: [ ...item.roles, role ] };
            }

            return item;
        });

        return {
            ...state,
            memberDetails: {
                ...state.memberDetails,
                data: updatedItems ?
                    { ...state.memberDetails.data, owners: updatedItems } :
                    state.memberDetails.data
            }
        };
    },
    [t.REMOVE_OWNER_MEMBER_ROLE]: (state, action) => {
        const { ownerId, role } = action.payload;

        const updatedItems = state.memberDetails.data.owners?.map((item) => {
            if (item.id === ownerId) {
                const { roles } = item;
                const index = roles.indexOf(role);

                return roles.length === 1 ?
                    null :
                    {
                        ...item,
                        roles: [ ...roles.slice(0, index), ...roles.slice(index + 1, roles.length) ]
                    };
            }

            return item;
        }).filter(Boolean);

        return {
            ...state,
            memberDetails: {
                ...state.memberDetails,
                data: updatedItems ?
                    { ...state.memberDetails.data, owners: updatedItems } :
                    state.memberDetails.data
            }
        };
    },
    [t.REMOVE_OWNER_MEMBERSHIP]: (state, action) => {
        const updatedItems = state.memberDetails.data.owners?.filter(({ id }) => id !== action.payload);

        return {
            ...state,
            memberDetails: {
                ...state.memberDetails,
                data: updatedItems ?
                    { ...state.memberDetails.data, owners: updatedItems } :
                    state.memberDetails.data
            }
        };
    },
    [t.ADD_CLIENT_MEMBER_ROLE]: (state, action) => {
        const { clientId, role } = action.payload;

        const updatedItems = state.memberDetails.data.clients?.map((item) => {
            if (item.id === clientId) {
                return { ...item, roles: [ ...item.roles, role ] };
            }

            return item;
        });

        return {
            ...state,
            memberDetails: {
                ...state.memberDetails,
                data: updatedItems ?
                    { ...state.memberDetails.data, clients: updatedItems } :
                    state.memberDetails.data
            }
        };
    },
    [t.REMOVE_CLIENT_MEMBER_ROLE]: (state, action) => {
        const { clientId, role } = action.payload;

        const updatedItems = state.memberDetails.data.clients?.map((item) => {
            if (item.id === clientId) {
                const { roles } = item;
                const index = roles.indexOf(role);

                return roles.length === 1 ?
                    null :
                    {
                        ...item,
                        roles: [ ...roles.slice(0, index), ...roles.slice(index + 1, roles.length) ]
                    };
            }

            return item;
        }).filter(Boolean);

        return {
            ...state,
            memberDetails: {
                ...state.memberDetails,
                data: updatedItems ?
                    { ...state.memberDetails.data, clients: updatedItems } :
                    state.memberDetails.data
            }
        };
    },
    [t.REMOVE_CLIENT_MEMBERSHIP]: (state, action) => {
        const updatedItems = state.memberDetails.data.clients?.filter(({ id }) => id !== action.payload);

        return {
            ...state,
            memberDetails: {
                ...state.memberDetails,
                data: updatedItems ?
                    { ...state.memberDetails.data, clients: updatedItems } :
                    state.memberDetails.data
            }
        };
    },
    [t.OPEN_OWNER_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            isOwnerMembershipDialogOpen: true
        }
    }),
    [t.CLOSE_OWNER_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            isOwnerMembershipDialogOpen: false
        }
    }),
    [t.OPEN_CLIENT_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            isClientMembershipDialogOpen: true
        }
    }),
    [t.CLOSE_CLIENT_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            isClientMembershipDialogOpen: false
        }
    }),
    [t.SET_GROUPS_LIST]: (state, action) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            groupsList: action.payload
        }
    }),
    [t.OPEN_GROUP_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            isGroupMembershipDialogOpen: true
        }
    }),
    [t.CLOSE_GROUP_MEMBERSHIP_DIALOG]: (state) => ({
        ...state,
        memberDetails: {
            ...state.memberDetails,
            isGroupMembershipDialogOpen: false
        }
    })
};

export const initialState = {
    membersList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: MEMBERS_LIST_PAGE_SIZE,
        sortBy: 'username',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED,
        memberOperationInProgress: false,
        isMemberDialogOpen: false
    },
    memberDetails: {
        data: {},
        loadStatus: LOAD_STATUS.REQUIRED,
        memberOperationInProgress: false,
        isOwnerMembershipDialogOpen: false,
        isClientMembershipDialogOpen: false,
        isGroupMembershipDialogOpen: false,
        groupsList: []
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
