import { AUTOMATION_ID } from 'modules/common/constants';
import { CLIENTS_ROUTES } from 'modules/admin/clients/constants';
import { OWNERS_ROUTES } from 'modules/admin/owners/constants';
import { MEMBERS_ROUTES } from 'modules/admin/members/constants';
import { GROUPS_ROUTES } from 'modules/admin/groups/constants';

export const REDUCER_NAME = 'main';

export const ADMIN_MENU_CLIENTS_ITEM = {
    id: AUTOMATION_ID.ADMIN_MENU_CLIENTS,
    messageId: 'common.clients',
    route: CLIENTS_ROUTES.LIST
};

export const ADMIN_MENU_OWNERS_ITEM = {
    id: AUTOMATION_ID.ADMIN_MENU_OWNERS,
    messageId: 'common.owners',
    route: OWNERS_ROUTES.LIST
};

export const ADMIN_MENU_MEMBERS_ITEM = {
    id: AUTOMATION_ID.ADMIN_MENU_OWNERS,
    messageId: 'common.members',
    route: MEMBERS_ROUTES.LIST
};

export const ADMIN_MENU_GROUPS_ITEM = {
    id: AUTOMATION_ID.ADMIN_MENU_GROUPS,
    messageId: 'common.groups',
    route: GROUPS_ROUTES.LIST
};

export const MAIN_ROUTES = {
    UNAUTHORIZED: '/401',
    FORBIDDEN: '/403'
};
