export const REDUCER_NAME = 'groups';
export const GROUPS_LIST_PAGE_SIZE = 50;

export const GROUPS_ROUTES = {
    LIST: '/admin/groups',
    NEW: '/admin/groups/add',
    DETAILS: '/admin/groups/view/{0}',
    EDIT: '/admin/groups/edit/{0}'
};

export const GROUPS_LIST_SORT_ATTRIBUTES_MAP = {
    name: 'NAME',
    memberCount: 'MEMBER_COUNT',
    updateTimestamp: 'LAST_UPDATE'
};

export const GROUP_ITEM_TYPE = {
    CLIENT: 'CLIENT',
    OWNER: 'OWNER'
} as const;
