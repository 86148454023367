import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import LoadMoreButton from 'modules/common/components/LoadMoreButton';
import { LOAD_STATUS, CONTENT_CONTAINER_MAX_WIDTH, CLIENT_ROLES } from 'modules/common/constants';
import { LoadStatus, SortOrder, RouteComponentProps } from 'modules/common/interfaces';
import { formatString } from 'modules/common/utils';
import { ROLE_TYPE } from 'modules/admin/constants';
import RolesChips from 'modules/admin/components/RolesChips';
import { memberApiDefinitions } from 'modules/service/types';
import { MEMBERS_ROUTES } from 'modules/admin/members/constants';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

interface ClientMembershipsListProps {
    items: Array<memberApiDefinitions['ClientMembership']>;
    status: LoadStatus;
    addClientRole: Function;
    deleteClientRole: Function;
    deleteClientMembership: Function;
    isOperationInProgress: boolean;
    isOdpMemberAdministrator: boolean;
    canView: boolean;
    canManage: boolean;
    currentUserId: string;
    sortBy: string;
    sortOrder: SortOrder;
    onSort: Function;
    totalCount: number;
    loadMore: Function;
}

interface RouteMatchParams {
    clientId: string;
}

class ClientMembershipsList extends Component<ClientMembershipsListProps & Pick<RouteComponentProps<RouteMatchParams>, 'match'>> {
    public render() {
        const { items, status, onSort, totalCount, loadMore, canView, currentUserId } = this.props;
        const isLoaded = status === LOAD_STATUS.LOADED;

        const itemsToDisplay = canView ? items : items.filter(({ memberId }) => memberId === currentUserId);

        return (
            <Fragment>
                <Table
                    columns={this.getTableConfig()}
                    data={itemsToDisplay}
                    skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                    isLoaded={isLoaded}
                    noDataMessage={<FormattedMessage id={canView ? 'common.noData' : 'admin.noAccessToClientMembersTable'} />}
                    onSort={onSort}
                />
                {
                    canView && isLoaded && (totalCount > items.length) &&
                    <LoadMoreButton load={loadMore} count={items.length} totalCount={totalCount} />
                }
                {
                    Boolean(!canView && itemsToDisplay.length) &&
                    <div className={local.noAccessWarning}>
                        <FormattedMessage id='admin.noAccessToClientMembersTable' />
                    </div>
                }
            </Fragment>
        );
    }

    private getTableConfig = () => {
        const { sortBy, sortOrder, isOdpMemberAdministrator } = this.props;

        return [
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='admin.memberEmail' />}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                    />
                ),
                id: 'username',
                width: '25%',
                skeletonWidth: '100%',
                showTooltip: true,
                accessor: ({ memberId, username }) =>
                    (
                        isOdpMemberAdministrator ?
                            <a href={`#${formatString(MEMBERS_ROUTES.DETAILS, memberId)}`} className={classnames(local.name, local.link)}>
                                {username}
                            </a> :
                            <span className={local.name}>
                                {username}
                            </span>
                    ),
                sortable: true
            },
            {
                Header: (config) => (
                    <SortableTableHeader
                        {...config}
                        title={<FormattedMessage id='admin.roles' />}
                    />
                ),
                width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
                skeletonWidth: '100%',
                sortable: false,
                showTooltip: false,
                id: 'roles',
                Cell: (config) => {
                    const {
                        canManage,
                        currentUserId,
                        addClientRole,
                        deleteClientRole,
                        deleteClientMembership,
                        isOperationInProgress,
                        match: { params: { clientId } }
                    } = this.props;

                    const { memberId, roles } = config.row.original;
                    const canUpdateRoles = (
                        // user can change his own roles if he's super-admin or has client manage permission
                        ((isOdpMemberAdministrator || canManage) && (memberId === currentUserId)) ||
                        // memberships admin can change roles of non-admin users,
                        // super-admin with memberships admin role can change roles of all users
                        (canManage && (isOdpMemberAdministrator || !roles.includes(CLIENT_ROLES.MEMBERSHIPS_ADMIN)))
                    );

                    return (
                        <div className={classnames(local.tableCellContainer, 'container-row')}>
                            <RolesChips
                                editable={canUpdateRoles}
                                onSelect={
                                    (role) => {
                                        addClientRole(memberId, clientId, role);
                                    }
                                }
                                onRemove={
                                    (role) => {
                                        deleteClientRole(memberId, clientId, role);
                                    }
                                }
                                type={ROLE_TYPE.CLIENT}
                                selectedRoles={roles}
                                disabled={isOperationInProgress}
                            />
                            <div
                                className={classnames(local.deleteIconContainer, { [local.disabled]: !canUpdateRoles || isOperationInProgress })}
                                onClick={() => { deleteClientMembership(memberId, clientId); }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} className={local.deleteIcon} />
                            </div>
                        </div>
                    );
                }
            }
        ];
    }
}

export default ClientMembershipsList;
