import React, { Component, Fragment } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

import Input from 'modules/common/components/Input';
import SelectInput from 'modules/common/components/SelectInput';
import RadioSwitch from 'modules/common/components/RadioSwitch';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import Checkbox from 'modules/common/components/Checkbox';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import InstanceTrigger from '../InstanceTrigger';
import { InstanceTriggerData } from '../InstanceTrigger/InstanceTrigger';
import { METHOD_SWITCH_OPTIONS, TRIGGERS_LOGIC_TYPE_SWITCH_OPTIONS } from '../../constants';
import {
    AUTOMATION_ID,
    HOUR_SELECT_OPTIONS,
    MINUTE_SELECT_OPTIONS,
    WEEKDAY_SELECT_OPTIONS,
    DAY_OF_MONTH_SELECT_OPTIONS,
    SCHEDULE_FREQUENCY,
    LOAD_STATUS,
    CRON_FREQUENCY_SWITCH_OPTIONS,
    TRANSFORMATION_INGEST_MODE
} from 'modules/common/constants';
import { PIPELINES_ROUTES } from 'modules/transformationPipelines/constants';
import { detectCronFrequency, formatString, parseCronExpression } from 'modules/common/utils';
import { LoadStatus, RouteComponentProps } from 'modules/common/interfaces';
import CronField from 'modules/common/components/CronField';
import { transformationApiDefinitions } from 'modules/service/types';
import { ingestionApiDefinitions } from 'modules/service/types';

import local from '../InstanceFormLayout/local.module.scss';

type ExportTargetData =
    Omit<transformationApiDefinitions['ExportTargetListItemResponseDto'], 'configurationTemplate'> &
    { configurationTemplate: ingestionApiDefinitions['ManifestDto'] };

interface InstanceFormProps {
    intl: IntlShape;
    resetForm: Function;
    resetData: Function;
    isOperationInProgress: boolean;
    isEditMode: boolean;
    triggers: InstanceTriggerData[];
    addTrigger: Function;
    initTriggers: Function;
    getFormData: Function;
    exportTargetsData: ExportTargetData[];
    exportTargetsDataLoadStatus: LoadStatus;
    pipelines: Omit<transformationApiDefinitions['PipelineLiteResponseDto'], 'purpose'>;
    pipelinesLoadStatus: LoadStatus;
    getPipelineDetails: Function;
    configurationTemplate: ingestionApiDefinitions['ManifestDto'];
    pipelineDetailsLoadStatus: LoadStatus;
    getInstanceDataForEditing: Function;
    data: transformationApiDefinitions['InstanceDetailsResponseDto'];
    dataLoadStatus: LoadStatus;
    updateTriggersOverrides: Function;
    removeSubmitError: Function;
    formValues: { [key: string]: any };
    canViewFeeds: boolean;
    supportsIngestionSettings: boolean;
    supportsExportTargets: boolean;
}

interface RouteMatchParams {
    clientId: string;
    instanceId: string;
}

const normalizePurposeField = (value) => value.replace(/\W+/g, '').toUpperCase();

const normalizeColumnName = (value) => value.replace(/^[^a-zA-Z_]+|[^a-zA-Z0-9_]/g, '');

const normalizeCommaSeparatedColumnNames = (value) =>
    value
        .replace(/^[^a-zA-Z_]+|[^a-zA-Z0-9_,]/g, '')
        .replace(/,[^a-zA-Z_]+/g, ',');

class InstanceForm extends Component<InstanceFormProps & InjectedFormProps<{}, InstanceFormProps> & RouteComponentProps<RouteMatchParams>> {
    private METHOD_SWITCH_OPTIONS = [
        {
            name: <FormattedMessage id='common.schedule' />,
            value: METHOD_SWITCH_OPTIONS.SCHEDULE
        },
        {
            name: <FormattedMessage id='common.trigger' />,
            value: METHOD_SWITCH_OPTIONS.TRIGGER
        },
        {
            name: <FormattedMessage id='common.both' />,
            value: METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER
        },
        {
            name: <FormattedMessage id='common.manual' />,
            value: METHOD_SWITCH_OPTIONS.MANUAL
        }
    ];

    public componentDidMount() {
        const {
            isEditMode,
            resetForm,
            resetData,
            getFormData,
            match,
            getInstanceDataForEditing,
            canViewFeeds
        } = this.props;

        resetForm();
        getFormData();

        if (isEditMode) {
            const { clientId, instanceId } = match.params;

            resetData();
            getInstanceDataForEditing(clientId, instanceId, !canViewFeeds);
        }
    }

    public componentDidUpdate(prevProps) {
        const {
            match: { params: { clientId, instanceId } },
            data,
            change,
            resetForm,
            resetData,
            getInstanceDataForEditing,
            getPipelineDetails,
            isEditMode,
            canViewFeeds
        } = this.props;

        if (isEditMode) {
            const { match: { params: { clientId: prevClientId, instanceId: prevInstanceId } }, data: prevData } = prevProps;

            if ((clientId !== prevClientId) || (instanceId !== prevInstanceId)) {
                resetForm();
                resetData();
                getInstanceDataForEditing(clientId, instanceId, !canViewFeeds);
            }

            if (data !== prevData) {
                const {
                    purpose,
                    description,
                    pipelineId,
                    pipelineOwnerId,
                    transformationInstanceExportTargets,
                    transformationInstanceTriggers,
                    cronExpression,
                    producesFullDataset,
                    ingestionSettings,
                    instanceTriggerLogicalType
                } = data;

                pipelineId && pipelineOwnerId && getPipelineDetails(pipelineOwnerId, pipelineId);

                change('description', description);
                change('pipelineId', pipelineId);
                change('purpose', purpose);
                change('producesFullDataset', producesFullDataset);
                change('triggersLogicType', instanceTriggerLogicalType);

                if (ingestionSettings) {
                    const {
                        ingestMode,
                        primaryKeyColumns,
                        clusterColumns,
                        timePartitioningColumn,
                        overwriteByColumns
                    } = ingestionSettings;

                    change('ingestMode', ingestMode);

                    if (primaryKeyColumns?.length) {
                        change('primaryKeyColumns', primaryKeyColumns.join(','));
                    }

                    if (timePartitioningColumn) {
                        change('timePartitioningColumn', timePartitioningColumn);
                    }

                    if (clusterColumns?.length) {
                        change('clusterColumns', clusterColumns.join(','));
                    }

                    if (overwriteByColumns?.length) {
                        change('overwriteByColumns', overwriteByColumns.join(','));
                    }
                }

                transformationInstanceExportTargets?.length && change('exportTargets', transformationInstanceExportTargets.map(({ exportTargetName }) => exportTargetName));

                change('airflowConfigurationOverrides', {}); // clean up stale form values which may belong to another instance

                if (transformationInstanceTriggers?.length && cronExpression) {
                    change('method', METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER);

                    this.initSchedule();
                    this.initTriggers();
                } else if (transformationInstanceTriggers?.length) {
                    change('method', METHOD_SWITCH_OPTIONS.TRIGGER);

                    this.initTriggers();
                } else if (cronExpression) {
                    change('method', METHOD_SWITCH_OPTIONS.SCHEDULE);

                    this.initSchedule();
                } else {
                    change('method', METHOD_SWITCH_OPTIONS.MANUAL);
                }
            }
        }
    }

    public render() {
        const {
            pipelines,
            pipelinesLoadStatus,
            pipelineDetailsLoadStatus,
            exportTargetsData,
            exportTargetsDataLoadStatus,
            isOperationInProgress,
            handleSubmit,
            isEditMode,
            dataLoadStatus,
            supportsExportTargets,
            formValues: { method },
            data: {
                pipelineId,
                pipelinePurpose,
                pipelineOwnerId,
                purpose
            }
        } = this.props;

        return (
            <Fragment>
                <form id='instanceForm' onSubmit={handleSubmit} autoComplete='off'>
                    <Field
                        name='purpose'
                        isOperationInProgress={isOperationInProgress}
                        purpose={purpose}
                        component={this.renderPurposeField}
                        normalize={normalizePurposeField}
                    />
                    <Field
                        name='description'
                        isOperationInProgress={isOperationInProgress}
                        dataLoadStatus={dataLoadStatus}
                        component={this.renderDescriptionField}
                    />
                    {
                        isEditMode ?
                            <Fragment>
                                <div className={local.label}>
                                    <FormattedMessage id='common.transformationPipeline' />
                                </div>
                                {
                                    dataLoadStatus === LOAD_STATUS.LOADED ?
                                        <div className={local.linkContainer}>
                                            <a href={`#${formatString(PIPELINES_ROUTES.DETAILS, pipelineOwnerId, pipelineId)}`}>
                                                {pipelinePurpose}
                                            </a>
                                        </div> :
                                        <SkeletonItem width='160px' height='20px' />
                                }
                            </Fragment> :
                            <Field
                                name='pipelineId'
                                pipelines={pipelines}
                                pipelinesLoadStatus={pipelinesLoadStatus}
                                isOperationInProgress={isOperationInProgress}
                                component={this.renderPipelineField}
                            />
                    }
                    {this.renderAirflowConfiguration()}
                    {
                        pipelineDetailsLoadStatus === LOAD_STATUS.LOADED && supportsExportTargets &&
                        <Fragment>
                            <Field
                                name='exportTargets'
                                exportTargetsData={exportTargetsData}
                                exportTargetsDataLoadStatus={exportTargetsDataLoadStatus}
                                dataLoadStatus={dataLoadStatus}
                                isOperationInProgress={isOperationInProgress}
                                component={this.renderExportTargetsField}
                            />
                            {this.renderExportTargetsDynamicSections()}
                        </Fragment>
                    }
                    {this.renderMethodSwitch()}
                    {
                        ([ METHOD_SWITCH_OPTIONS.TRIGGER, METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER ]).includes(method) &&
                        this.renderTriggers()
                    }
                    {
                        ([ METHOD_SWITCH_OPTIONS.SCHEDULE, METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER ]).includes(method) &&
                        <Fragment>
                            <div className={local.bigLabel}>
                                <FormattedMessage id='common.schedule' />
                            </div>
                            {this.renderFrequencySwitch()}
                            {this.renderFrequencyOptions()}
                        </Fragment>
                    }
                    <Field
                        name='producesFullDataset'
                        isOperationInProgress={isOperationInProgress}
                        dataLoadStatus={dataLoadStatus}
                        component={this.renderDatasetField}
                    />
                    {this.renderIngestionSettings()}
                </form>
            </Fragment>
        );
    }

    private renderDescriptionField = ({ input, dataLoadStatus, isOperationInProgress }) => {
        const { isEditMode, intl } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'instances.descriptionPlaceholder';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.description' />
                </div>
                <Input
                    id={AUTOMATION_ID.INSTANCE_FORM_DESCRIPTION}
                    inputProperties={input}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    maxLength={65535} // max varchar size in PostgreSQL, no limit is set in DB at the moment
                    multiline={true}
                    trackTiming={true}
                    trackingName='Instance description'
                />
            </div>
        );
    }

    private renderMethodSwitch = () => {
        const { isEditMode, isOperationInProgress, dataLoadStatus } = this.props;
        const isDisabled = isEditMode ?
            (isOperationInProgress || dataLoadStatus !== LOAD_STATUS.LOADED) :
            isOperationInProgress;

        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='common.method' />
                </div>
                <RadioSwitch
                    fieldName='method'
                    options={this.METHOD_SWITCH_OPTIONS}
                    classes={{
                        option: local.option
                    }}
                    disabled={isDisabled}
                />
            </Fragment>
        );
    }

    private renderFrequencySwitch = () => {
        const { isOperationInProgress, removeSubmitError } = this.props;

        return (
            <Fragment>
                <div className={local.label}>
                    <FormattedMessage id='common.frequency' />
                </div>
                <RadioSwitch
                    fieldName='cron.frequency'
                    options={CRON_FREQUENCY_SWITCH_OPTIONS}
                    onChange={() => {
                        removeSubmitError({
                            form: 'instanceForm',
                            field: 'cron'
                        });
                    }}
                    classes={{
                        option: local.option
                    }}
                    disabled={isOperationInProgress}
                />
            </Fragment>
        );
    };

    private renderFrequencyOptions = () => {
        const { formValues: { cron: { frequency } }, isOperationInProgress } = this.props;

        switch (frequency) {
            case SCHEDULE_FREQUENCY.DAILY:
                return (
                    <div className={classnames(local.cronControls, 'container-row')}>
                        {this.renderTimeSelects(isOperationInProgress)}
                    </div>
                );
            case SCHEDULE_FREQUENCY.WEEKLY:
                return (
                    <div className={classnames(local.cronControls, 'container-row')}>
                        {this.renderWeekdaySelect(isOperationInProgress)}
                        {this.renderTimeSelects(isOperationInProgress)}
                    </div>
                );
            case SCHEDULE_FREQUENCY.MONTHLY:
                return (
                    <div className={classnames(local.cronControls, 'container-row')}>
                        {this.renderDayOfMonthSelect(isOperationInProgress)}
                        {this.renderTimeSelects(isOperationInProgress)}
                    </div>
                );
            case SCHEDULE_FREQUENCY.CUSTOM:
                return (
                    <div className={classnames(local.cronControls)}>
                        <CronField name='cron.expression' disabled={isOperationInProgress} />
                    </div>
                );
        }
    }

    private renderTimeSelects = (disabled) => {
        const { change, formValues: { cron: { minute } } } = this.props;

        const hourSelectConfig = {
            inputId: AUTOMATION_ID.INSTANCE_FORM_SCHEDULE_HOUR,
            onChange: () => {
                if (!minute) {
                    change('cron.minute', MINUTE_SELECT_OPTIONS[0].id);
                }
            },
            fieldName: 'cron.hour',
            placeholderId: 'common.hour',
            labelId: 'common.hour',
            options: HOUR_SELECT_OPTIONS,
            width: 100,
            searchable: true,
            disabled
        };

        const minuteSelectConfig = {
            inputId: AUTOMATION_ID.INSTANCE_FORM_SCHEDULE_MINUTE,
            fieldName: 'cron.minute',
            placeholderId: 'common.minute',
            labelId: 'common.minute',
            options: MINUTE_SELECT_OPTIONS,
            width: 100,
            searchable: true,
            disabled
        };

        return (
            <Fragment>
                {this.renderSelect(hourSelectConfig)}
                {this.renderSelect(minuteSelectConfig)}
            </Fragment>
        );
    }

    private renderWeekdaySelect = (disabled) => {
        const config = {
            inputId: AUTOMATION_ID.INSTANCE_FORM_SCHEDULE_WEEKDAY,
            fieldName: 'cron.weekday',
            placeholderId: 'common.weekday',
            labelId: 'common.weekday',
            options: WEEKDAY_SELECT_OPTIONS,
            width: 229,
            disabled
        };

        return this.renderSelect(config);
    }

    private renderDayOfMonthSelect = (disabled) => {
        const config = {
            inputId: AUTOMATION_ID.INSTANCE_FORM_SCHEDULE_DAY,
            fieldName: 'cron.dayOfMonth',
            placeholderId: 'common.dayOfMonth',
            labelId: 'common.dayOfMonth',
            options: DAY_OF_MONTH_SELECT_OPTIONS,
            width: 138,
            searchable: true,
            disabled
        };

        return this.renderSelect(config);
    }

    private renderSelect = ({ fieldName, ...rest }) => (
        <Field
            name={fieldName}
            component={this.selectComponent}
            props={rest}
        />
    )

    private selectComponent = ({
        input,
        inputId,
        onChange,
        placeholderId,
        labelId,
        options,
        width,
        searchable,
        disabled,
        meta
    }) => {
        const placeholder = this.props.intl.formatMessage({ id: placeholderId });

        return (
            <div>
                {
                    labelId &&
                    <div className={local.label}>
                        <FormattedMessage id={labelId} />
                    </div>
                }
                <SelectInput
                    inputId={inputId}
                    placeholder={placeholder}
                    items={options}
                    width={width}
                    inputProperties={
                        onChange ?
                            {
                                ...input,
                                onChange: (value) => {
                                    onChange(value);
                                    input.onChange(value);
                                }
                            } :
                            input
                    }
                    disabled={disabled}
                    searchable={searchable}
                    error={meta.error}
                    trackTiming={true}
                />
            </div>
        );
    }

    private renderTriggers = () => {
        const {
            isEditMode,
            triggers,
            isOperationInProgress,
            canViewFeeds,
            change,
            match: { params: { instanceId } }
        } = this.props;

        const items = triggers.map((trigger, index) => {
            const triggerProps: any = {
                index,
                data: trigger,
                change,
                canViewFeeds
            };

            if (isEditMode) {
                triggerProps.currentInstanceId = instanceId;
            }

            return (
                <InstanceTrigger key={index} {...triggerProps} />
            );
        });

        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='instances.triggers' />
                </div>
                <div className={local.logicTypeLabel}>
                    <FormattedMessage id='instances.logicType' />
                    <IconWithTooltip className={local.labelTooltip}>
                        <FormattedMessage
                            id='instances.triggersLogicTypePromptA'
                            values={{ u: (chunks) => <u>{chunks}</u> }}
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id='instances.triggersLogicTypePromptB'
                            values={{ u: (chunks) => <u>{chunks}</u> }}
                        />
                    </IconWithTooltip>
                </div>
                <RadioSwitch
                    fieldName='triggersLogicType'
                    options={Object.values(TRIGGERS_LOGIC_TYPE_SWITCH_OPTIONS).map((value) => ({ name: value, value }))}
                    classes={{
                        switch: local.triggerLogicTypeSwitch,
                        option: local.triggerLogicTypeOption
                    }}
                    disabled={isOperationInProgress}
                />
                {
                    items.length ?
                        <div>
                            <span className={local.tableLabel}>
                                <FormattedMessage id='instances.triggerType' />
                            </span>
                            <span className={local.tableLabel}>
                                <FormattedMessage id='common.name' />
                            </span>
                        </div> :
                        null
                }
                <div>
                    {items}
                </div>
                <div className={classnames({ [local.withTriggers]: items.length }, 'plus-button', 'ls-button')}>
                    <button disabled={isOperationInProgress} className='btn-transparent' onClick={this.addNewTrigger}>
                        <FontAwesomeIcon icon={faPlus} />
                        <FormattedMessage id='instances.addTrigger' />
                    </button>
                </div>
            </Fragment>
        );
    }

    private addNewTrigger = (event) => {
        const { isOperationInProgress, addTrigger } = this.props;

        if (!isOperationInProgress) {
            addTrigger();
        }

        // prevent form submission
        event.preventDefault();
    }

    private renderExportTargetsField = ({ input, exportTargetsData, dataLoadStatus, exportTargetsDataLoadStatus, isOperationInProgress }) => {
        const isDisabled = this.props.isEditMode ?
            (isOperationInProgress || dataLoadStatus !== LOAD_STATUS.LOADED) :
            isOperationInProgress;

        const items = exportTargetsData.map(({ name }) => ({ id: name, name })); // to strip redundant properties

        return (
            <Fragment>
                <div className={local.bigLabel}>
                    <FormattedMessage id='instances.exportTargets' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.INSTANCE_FORM_EXPORT_TARGETS}
                    items={items}
                    inputProperties={input}
                    disabled={isDisabled}
                    multiple={true}
                    limit={1} // TODO change to single value drop-down instead of multiselect
                    isLoading={exportTargetsDataLoadStatus !== LOAD_STATUS.LOADED}
                    trackTiming={true}
                    trackingName='Instance export targets'
                />
            </Fragment>
        );
    }

    private renderExportTargetsDynamicSections = () => {
        const {
            isEditMode,
            exportTargetsData,
            exportTargetsDataLoadStatus,
            isOperationInProgress,
            change,
            formValues: { exportTargets },
            data: { transformationInstanceExportTargets }
        } = this.props;

        if (exportTargetsDataLoadStatus !== LOAD_STATUS.LOADED) {
            return null;
        }

        const sections: JSX.Element[] = [];
        if (exportTargets) {
            for (const exportTargetName of exportTargets) {
                const { configurationTemplate } = exportTargetsData.find(({ name }) => name === exportTargetName)!;

                let defaultValues: { [key: string]: string } | undefined;
                if (isEditMode) {
                    const savedExportTarget = transformationInstanceExportTargets?.find(({ exportTargetName: savedExportTargetName }) => savedExportTargetName === exportTargetName);

                    if (savedExportTarget) {
                        defaultValues = savedExportTarget.configuration!.reduce((acc, { key, value }) => {
                            acc[key] = value;

                            return acc;
                        }, {});
                    }
                }

                sections.push(
                    <Fragment key={exportTargetName}>
                        <div className={local.exportTargetTitle}>
                            {exportTargetName}
                        </div>
                        <DynamicFieldsSection
                            change={change}
                            config={configurationTemplate}
                            disabled={isOperationInProgress}
                            prefix={`transformationInstanceExportTargets.${exportTargetName}`}
                            editMode={Boolean(isEditMode && defaultValues)}
                            defaultValues={defaultValues}
                            form='instanceForm'
                        />
                    </Fragment>
                );
            }
        }

        return sections.length ? sections : null;
    }

    private renderPipelineField = ({ input, pipelines, pipelinesLoadStatus, isOperationInProgress, meta }) => {
        const { getPipelineDetails, updateTriggersOverrides, change } = this.props;

        const onChange = async (pipelineId) => {
            const { ownerId } = pipelines.find(({ id }) => id === pipelineId);

            input.onChange(pipelineId);

            // clear stored airflowConfiguration and airflowConfigurationOverrides
            change('airflowConfiguration', {});
            change('airflowConfigurationOverrides', {});

            await getPipelineDetails(ownerId, pipelineId);

            // uncheck override checkboxes in case if pipeline configuration template doesn't contain editable fields
            updateTriggersOverrides();
        };

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.transformationPipeline' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.INSTANCE_FORM_PIPELINE}
                    items={pipelines}
                    inputProperties={{ ...input, onChange }}
                    disabled={isOperationInProgress}
                    isLoading={pipelinesLoadStatus !== LOAD_STATUS.LOADED}
                    searchable={true}
                    error={meta.error}
                    trackTiming={true}
                    trackingName='Instance pipeline'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderAirflowConfiguration = () => {
        const {
            configurationTemplate,
            pipelineDetailsLoadStatus,
            isOperationInProgress,
            change,
            isEditMode,
            data: { airflowConfiguration }
        } = this.props;

        switch (pipelineDetailsLoadStatus) {
            case LOAD_STATUS.REQUIRED:
                return null;
            case LOAD_STATUS.LOADING:
                return <SkeletonSectionLoaderIndicator />;
            case LOAD_STATUS.LOADED: {
                const defaultValues = isEditMode ? airflowConfiguration!.reduce((acc, { key, value }) => {
                    acc[key] = value;

                    return acc;
                }, {}) : {};

                return (
                    <Fragment>
                        <div className={local.bigLabel}>
                            <FormattedMessage id='instances.airflowConfiguration' />
                        </div>
                        <DynamicFieldsSection
                            change={change}
                            config={configurationTemplate}
                            disabled={isOperationInProgress}
                            prefix='airflowConfiguration'
                            editMode={isEditMode}
                            defaultValues={defaultValues}
                            form='instanceForm'
                        />
                    </Fragment>
                );
            }
        }
    }

    private initSchedule = () => {
        const { change, data: { cronExpression } } = this.props;
        const cronValues = parseCronExpression(cronExpression!);
        const frequency = detectCronFrequency(cronValues);

        change('cron.expression', cronExpression);
        change('cron.frequency', frequency);

        if (frequency !== SCHEDULE_FREQUENCY.CUSTOM) {
            if (frequency === SCHEDULE_FREQUENCY.MONTHLY) {
                change('cron.dayOfMonth', cronValues.dayOfMonth);
            } else if (frequency === SCHEDULE_FREQUENCY.WEEKLY) {
                change('cron.weekday', cronValues.weekday);
            }

            change('cron.hour', cronValues.hour);
            change('cron.minute', cronValues.minute);
        }
    }

    private initTriggers = () => {
        const { initTriggers, data: { transformationInstanceTriggers } } = this.props;

        initTriggers(transformationInstanceTriggers);
    }

    private renderPurposeField = ({ input, purpose, isOperationInProgress, meta }) => {
        const { isEditMode, intl } = this.props;
        const placeholder = intl.formatMessage({ id: 'instances.purposePlaceholder' });

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.purpose' />
                </div>
                {
                    isEditMode ?
                        <div className={local.nonEditableField}>
                            {
                                purpose || <SkeletonItem width='160px' height='20px' />
                            }
                        </div> :
                        <Fragment>
                            <Input
                                id={AUTOMATION_ID.INSTANCE_FORM_PURPOSE}
                                inputProperties={input}
                                placeholder={placeholder}
                                disabled={isOperationInProgress}
                                maxLength={1024}
                                className={classnames({ 'error-input': Boolean(meta.error) })}
                                trackTiming={true}
                                trackingName='Instance purpose'
                            />
                            {
                                meta.error ?
                                    <div className='form-error-message'>
                                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                                    </div> :
                                    <div className={local.warning}>
                                        <FormattedMessage id='instances.purposeWarning' />
                                    </div>
                            }
                        </Fragment>
                }
            </div>
        );
    }

    private renderDatasetField = ({ input, dataLoadStatus, isOperationInProgress }) => {
        const isDisabled = this.props.isEditMode ?
            (isOperationInProgress || dataLoadStatus !== LOAD_STATUS.LOADED) :
            isOperationInProgress;

        return (
            <div className={local.fullDatasetField}>
                <Checkbox
                    inputProperties={input}
                    disabled={isDisabled}
                    label={<FormattedMessage id='instances.fullDataset' />}
                />
            </div>
        );
    }

    private renderIngestionSettings = () => {
        const {
            isOperationInProgress,
            dataLoadStatus,
            pipelineDetailsLoadStatus,
            supportsIngestionSettings,
            formValues: { ingestMode }
        } = this.props;

        return (
            pipelineDetailsLoadStatus === LOAD_STATUS.LOADED && supportsIngestionSettings ?
                <Fragment>
                    <Field
                        name='primaryKeyColumns'
                        isOperationInProgress={isOperationInProgress}
                        dataLoadStatus={dataLoadStatus}
                        component={this.renderPrimaryKeyField}
                        normalize={normalizeCommaSeparatedColumnNames}
                    />
                    <Field
                        name='timePartitioningColumn'
                        isOperationInProgress={isOperationInProgress}
                        dataLoadStatus={dataLoadStatus}
                        component={this.renderPartitionColumnField}
                        normalize={normalizeColumnName}
                    />
                    <Field
                        name='clusterColumns'
                        isOperationInProgress={isOperationInProgress}
                        dataLoadStatus={dataLoadStatus}
                        component={this.renderClusterColumnsField}
                        normalize={normalizeCommaSeparatedColumnNames}
                    />
                    <Field
                        name='ingestMode'
                        isOperationInProgress={isOperationInProgress}
                        dataLoadStatus={dataLoadStatus}
                        component={this.renderIngestModeField}
                    />
                    {
                        ingestMode === TRANSFORMATION_INGEST_MODE.INSERT_OR_OVERWRITE_BY_COLUMNS &&
                        <Field
                            name='overwriteByColumns'
                            isOperationInProgress={isOperationInProgress}
                            dataLoadStatus={dataLoadStatus}
                            component={this.renderOverwriteByColumnsField}
                            normalize={normalizeCommaSeparatedColumnNames}
                        />
                    }
                </Fragment> :
                null
        );
    }

    private renderIngestModeField = ({ input, dataLoadStatus, isOperationInProgress, meta }) => {
        const { isEditMode, intl } = this.props;

        const items = Object.values(TRANSFORMATION_INGEST_MODE).map((id) => ({
            id,
            name: intl.formatMessage({ id: `common.ingestMode.${id}` })
        }));

        const isDisabled = isEditMode ?
            (isOperationInProgress || dataLoadStatus !== LOAD_STATUS.LOADED) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.ingestMode' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.INSTANCE_FORM_INGEST_MODE}
                    optionClassName={AUTOMATION_ID.INSTANCE_FORM_INGEST_MODE_OPTION}
                    items={items}
                    inputProperties={input}
                    disabled={isDisabled}
                    error={meta.error}
                    trackTiming={true}
                    trackingName='Instance ingest mode'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderPartitionColumnField = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { isEditMode, intl } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'common.partitionColumn';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.partitionColumn' />
                    <span className={local.additionalLabel}>
                        <FormattedMessage id='common.optional' />
                    </span>
                </div>
                <Fragment>
                    <Input
                        id={AUTOMATION_ID.INSTANCE_FORM_PARTITION}
                        inputProperties={input}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        maxLength={300}
                        className={classnames({ 'error-input': Boolean(meta.error) })}
                        trackTiming={true}
                        trackingName='Instance schema partition column'
                    />
                    {
                        meta.error &&
                        <div className='form-error-message'>
                            <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                        </div>
                    }
                </Fragment>
            </div>
        );
    }

    private renderPrimaryKeyField = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { isEditMode, intl } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'common.primaryKey';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.primaryKey' />
                </div>
                <Fragment>
                    <Input
                        id={AUTOMATION_ID.INSTANCE_FORM_PRIMARY_KEY}
                        inputProperties={input}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        className={classnames({ 'error-input': Boolean(meta.error) })}
                        trackTiming={true}
                        trackingName='Instance schema primary key'
                    />
                    {
                        meta.error ?
                            <div className='form-error-message'>
                                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                            </div> :
                            <div className={local.warning}>
                                <FormattedMessage id='instances.primaryKeyWarning' />
                            </div>
                    }
                </Fragment>
            </div>
        );
    }

    private renderClusterColumnsField = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { isEditMode, intl } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'common.clusterColumns';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.clusterColumns' />
                    <span className={local.additionalLabel}>
                        <FormattedMessage id='common.optional' />
                    </span>
                </div>
                <Fragment>
                    <Input
                        id={AUTOMATION_ID.INSTANCE_FORM_CLUSTER}
                        inputProperties={input}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        maxLength={1203} // 4 300-character-length column names, separated by 3 commas
                        className={classnames({ 'error-input': Boolean(meta.error) })}
                        trackTiming={true}
                        trackingName='Instance schema cluster columns'
                    />
                    {
                        meta.error ?
                            <div className='form-error-message'>
                                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                            </div> :
                            <div className={local.warning}>
                                <FormattedMessage id='instances.clusterColumnsWarning' />
                            </div>
                    }
                </Fragment>
            </div>
        );
    }

    private renderOverwriteByColumnsField = ({ input, isOperationInProgress, dataLoadStatus, meta }) => {
        const { isEditMode, intl } = this.props;

        const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
        const placeholderMessageId = isEditMode && !isLoaded ? 'common.loading' : 'common.overwriteByColumns';
        const placeholder = intl.formatMessage({ id: placeholderMessageId });

        const isDisabled = isEditMode ?
            (isOperationInProgress || !isLoaded) :
            isOperationInProgress;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.overwriteByColumns' />
                </div>
                <Fragment>
                    <Input
                        id={AUTOMATION_ID.INSTANCE_FORM_OVERWRITE_BY_COLUMNS}
                        inputProperties={input}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        maxLength={1203} // 4 300-character-length column names, separated by 3 commas
                        className={classnames({ 'error-input': Boolean(meta.error) })}
                        trackTiming={true}
                        trackingName='Instance schema overwrite by columns'
                    />
                    {
                        meta.error ?
                            <div className='form-error-message'>
                                <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                            </div> :
                            <div className={local.warning}>
                                <FormattedMessage id='instances.overwriteByColumnsWarning' />
                            </div>
                    }
                </Fragment>
            </div>
        );
    }
}

export default InstanceForm;
