import { connect } from 'react-redux';

import { getOwnerMembershipsListProps } from '../../selectors';
import {
    addOwnerRole,
    deleteOwnerRole,
    deleteOwnerMembership,
    ownerMembershipsListSort,
    loadMoreOwnerMemberships
} from '../../actions';
import OwnerMembershipsList from './OwnerMembershipsList';

const mapDispatchToProps = {
    addOwnerRole,
    deleteOwnerRole,
    deleteOwnerMembership,
    loadMore: loadMoreOwnerMemberships,
    onSort: ownerMembershipsListSort
};

export default connect(getOwnerMembershipsListProps, mapDispatchToProps)(OwnerMembershipsList);
