import { connect } from 'react-redux';

import * as actions from '../../actions';
import { getCollectorCredentialsListProps } from '../../selectors';
import CollectorCredentialsList from './CollectorCredentialsList';

const mapDispatchToProps = {
    onSort: actions.collectorCredentialsListSort,
    goToCollectorCredentialDetailsPage: actions.goToCollectorCredentialDetailsPage,
    loadMore: actions.loadMoreCollectorCredentials
};

export default connect(getCollectorCredentialsListProps, mapDispatchToProps)(CollectorCredentialsList);
