import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import keycloak from './keycloak';
import { updateTokens, loadUserProfile } from 'modules/auth/actions';
import { isUserDataLoaded, isUserAccessAllowed } from 'modules/auth/selectors';
import { useAppDispatch } from 'modules/common/hooks';
import AuthLoadingScreen from '../AuthLoadingScreen';
import ErrorPage from 'modules/common/components/ErrorPage';

const KEYCLOAK_EVENTS = {
    ON_READY: 'onReady',
    ON_AUTH_SUCCESS: 'onAuthSuccess',
    ON_AUTH_ERROR: 'onAuthError',
    ON_AUTH_REFRESH_SUCCESS: 'onAuthRefreshSuccess',
    ON_AUTH_REFRESH_ERROR: 'onAuthRefreshError',
    ON_AUTH_LOGOUT: 'onAuthLogout',
    ON_TOKEN_EXPIRED: 'onTokenExpired'
};

const KeycloakProviderWrapper: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const dispatch = useAppDispatch();
    const isLoaded = useSelector(isUserDataLoaded);
    const isAccessAllowed = useSelector(isUserAccessAllowed);

    let content: ReactNode = <AuthLoadingScreen />;

    if (isLoaded) {
        content = isAccessAllowed ?
            children :
            <ErrorPage
                error='401'
                buttonText={<FormattedMessage id='auth.logout' />}
                callback={keycloak.logout}
            />;
    }

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            LoadingComponent={<AuthLoadingScreen />}
            initOptions={{
                onLoad: 'login-required',
                // responseMode changed to 'query' to avoid adding parameters to the app URL, which breaks routing functionality
                responseMode: 'query',
                // prevents from Keycloak login infinite loop in browser incognito mode (ODP-2357)
                // When using modes ‘login-required’ or ‘check-sso’ in Keycloak’s init method, Keycloak Javascript Adapter sets an iframe
                // that checks at timed intervals whether the user is authenticated. This iframe contains a script that needs access to the
                // KEYCLOAK_SESSION cookie set previously by Keycloak on authentication.
                // As in incognito mode, cookies are still used, but everything starts “fresh” when the session is started.
                // So after the redirect to the application, there will be no cookies found, so we get redirected back to login.
                checkLoginIframe: false
            }}
            onTokens={(tokens) => {
                dispatch(updateTokens(tokens));

                if (!isLoaded) {
                    dispatch(loadUserProfile());
                }
            }}
            onEvent={(event) => {
                if ([ KEYCLOAK_EVENTS.ON_AUTH_REFRESH_ERROR, KEYCLOAK_EVENTS.ON_AUTH_ERROR ].includes(event)) {
                    keycloak.logout();
                }
            }}
        >
            {content}
        </ReactKeycloakProvider>
    );
};

export default KeycloakProviderWrapper;
