import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { LOAD_STATUS } from 'modules/common/constants';
import { useAppDispatch } from 'modules/common/hooks';
import { LoadStatus } from 'modules/common/interfaces';
import { DynamicFieldProps } from 'modules/common/components/DynamicField';
import Input from 'modules/common/components/Input';
import RadioSwitch from 'modules/common/components/RadioSwitch';
import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import { removeSubmitError } from 'modules/form/actions';
import { getFeedFormAdverityAuthorizations, getFeedFormAdverityAuthorizationsLoadStatus } from '../../selectors';
import { getAdverityAuthorizationsByDatastreamType } from '../../actions';

import local from './local.module.scss';

type AdverityAuthorizationSelectProps = WrappedFieldProps & {
    items: SelectInputItem[];
    itemsLoadStatus: LoadStatus;
    disabled: boolean;
};

type DatastreamIdInputProps = WrappedFieldProps & {
    config: DynamicFieldProps['config'];
    disabled: boolean;
};

type DatastreamAuthorizationOrIdFieldProps = DynamicFieldProps & {
    datastreamTypeId: number;
};

const SWITCH_OPTIONS = [
    {
        name: <FormattedMessage id='common.existing' />,
        value: false
    },
    {
        name: <FormattedMessage id='common.createNew' />,
        value: true
    }
];

const AdverityAuthorizationSelect: FunctionComponent<AdverityAuthorizationSelectProps> = ({
    input,
    meta: { error },
    items,
    itemsLoadStatus,
    disabled
}) => {
    const dispatch = useAppDispatch();

    return (
        <Fragment>
            <div className={local.label}>
                <FormattedMessage id='common.datastreamAuthorization' />
            </div>
            <SelectInput
                inputProperties={{
                    ...input,
                    onChange: (value) => {
                        dispatch(removeSubmitError({ form: 'feedForm', field: 'datastream' }));
                        input.onChange(value);
                    }
                }}
                items={items}
                isLoading={itemsLoadStatus === LOAD_STATUS.LOADING}
                searchable={true}
                disabled={disabled}
                error={error}
            />
            {
                error &&
                <div className='form-error-message'>
                    <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                </div>
            }
        </Fragment>
    );
};

const DatastreamIdInput: FunctionComponent<DatastreamIdInputProps> = ({
    input,
    meta: { error },
    config,
    disabled
}) => {
    const intl = useIntl();

    return (
        <Fragment>
            <Input
                id={input.name}
                inputProperties={input}
                disabled={disabled}
                className={classnames({ 'error-input': Boolean(error) }, local.textInput)}
                placeholder={intl.formatMessage({ id: 'common.enterId' })}
            />
            <div className={local.fieldMessage}>
                {
                    error ?
                        <span className='form-error-message'>
                            <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
                        </span> :
                        <FormattedMessage id={`${input.name}.description`} defaultMessage={config.description} />
                }
            </div>
        </Fragment>
    );
};

const DatastreamCreationError: FunctionComponent<WrappedFieldProps> = ({ meta: { error } }) => (
    <Fragment>
        {
            error &&
            <div className='form-error-message'>
                <FormattedMessage id={`validationErrors.${error}`} defaultMessage={error} />
            </div>
        }
    </Fragment>
);

const DatastreamAuthorizationOrIdField: FunctionComponent<DatastreamAuthorizationOrIdFieldProps> = ({
    prefix,
    panelId,
    config,
    disabled,
    datastreamTypeId
}) => {
    const dispatch = useAppDispatch();

    const [createNewDatastream, setCreateNewDatastream] = useState(false);

    const authorizations = useSelector(getFeedFormAdverityAuthorizations);
    const loadStatus = useSelector(getFeedFormAdverityAuthorizationsLoadStatus);

    useEffect(() => {
        if (loadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getAdverityAuthorizationsByDatastreamType(datastreamTypeId));
        }
    }, [ loadStatus, datastreamTypeId ]);

    return (
        <Fragment>
            <div className={local.label}>
                <FormattedMessage id='common.datastreamId' />
            </div>
            <RadioSwitch
                fieldName='createNewDatastream'
                options={SWITCH_OPTIONS}
                disabled={disabled}
                onChange={(value) => { setCreateNewDatastream(value); }}
                classes={{
                    switch: local.switchContainer,
                    option: local.option
                }}
            />
            {
                createNewDatastream ?
                    <Fragment>
                        <Field
                            name='adverityAuthorizationId'
                            component={AdverityAuthorizationSelect}
                            items={authorizations}
                            itemsLoadStatus={loadStatus}
                            disabled={disabled}
                        />
                        <Field
                            name='datastream'
                            component={DatastreamCreationError}
                        />
                    </Fragment> :
                    <Field
                        name={`${prefix}.${panelId}.${config.id}`}
                        config={config}
                        component={DatastreamIdInput}
                        disabled={disabled}
                    />
            }
        </Fragment>
    );
};

export default DatastreamAuthorizationOrIdField;
