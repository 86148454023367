import { UserProfile } from 'modules/common/interfaces';

interface MenuItemConfig {
    id: string;
    name: string;
    route: string;
    requiredPermissions?: string[];
    clientSpecific?: boolean;
    checkAccess?: Function;
    nestedRoutes?: string[];
}

export const isMenuItemAccessible = (
    { checkAccess, requiredPermissions, clientSpecific }: MenuItemConfig,
    userProfile: UserProfile,
    selectedClientId: string
) => {
    const { clients, owners } = userProfile;
    const client = clients!.find(({ id }) => id === selectedClientId);

    return checkAccess ?
        checkAccess(userProfile, selectedClientId) :
        requiredPermissions!.every((permission) => {
            return clientSpecific ?
                client?.permissions.includes(permission) :
                owners!.some(({ permissions }) => permissions.includes(permission)) ||
                clients!.some(({ permissions }) => permissions.includes(permission));
        });
};

export const isMenuItemSelected = (
    { route, nestedRoutes }: MenuItemConfig,
    routerPath: string
) =>
    nestedRoutes ?
        nestedRoutes.some((nestedRoute) => routerPath.startsWith(nestedRoute)) :
        routerPath.startsWith(route);
