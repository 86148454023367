import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import SelectInput, { SelectInputItem } from 'modules/common/components/SelectInput';
import DynamicFieldsSection from 'modules/common/components/DynamicFieldsSection';
import Checkbox from 'modules/common/components/Checkbox';
import { intl } from 'modules/i18n';
import { LOAD_STATUS } from 'modules/common/constants';
import { LoadStatus } from 'modules/common/interfaces';
import { ingestionApiDefinitions } from 'modules/service/types';
import { TRIGGER_TYPE } from '../../constants';

import local from './local.module.scss';

const getTriggerTypeItems = (canViewFeeds) => [
    {
        id: TRIGGER_TYPE.UPSTREAM_FEED,
        name: intl.formatMessage({ id: 'instances.upstreamFeed' }),
        disabled: !canViewFeeds
    },
    {
        id: TRIGGER_TYPE.UPSTREAM_INSTANCE,
        name: intl.formatMessage({ id: 'instances.upstreamInstance' })
    }
];

export interface InstanceTriggerData {
    id: string;
    type: typeof TRIGGER_TYPE[keyof typeof TRIGGER_TYPE];
    overrideConfiguration: boolean;
    savedConfiguration: { [key: string]: string };
}

interface InstanceTriggerProps {
    data: InstanceTriggerData;
    items: SelectInputItem[];
    feedsLoadStatus: LoadStatus;
    instancesLoadStatus: LoadStatus;
    index: number;
    updateTrigger: Function;
    removeTrigger: Function;
    currentInstanceId?: string;
    change: Function;
    configurationTemplate: ingestionApiDefinitions['ManifestDto'] | null;
    isOperationInProgress: boolean;
    canViewFeeds: boolean;
    validateApSql: Function;
}

interface InstanceTriggerState {
    error: boolean;
}

class InstanceTrigger extends Component<InstanceTriggerProps, InstanceTriggerState> {
    public state = { error: false };

    public shouldComponentUpdate(nextProps, nextState) {
        const { items, ...otherProps } = this.props;

        if (this.state.error !== nextState.error) {
            return true;
        }

        for (const key in otherProps) {
            if (otherProps[key] !== nextProps[key]) {
                return true;
            }
        }

        const { items: nextItems } = nextProps;
        if (items.length !== nextItems.length) {
            return true;
        }

        const { length } = items;
        for (let i = 0; i < length; i++) {
            if (items[i].id !== nextItems[i].id) {
                return true;
            }
        }

        return false;
    }

    public render() {
        const {
            data: { type, id: triggerId, overrideConfiguration, savedConfiguration },
            items,
            index,
            feedsLoadStatus,
            instancesLoadStatus,
            configurationTemplate,
            change,
            isOperationInProgress,
            canViewFeeds,
            validateApSql
        } = this.props;

        const isLoading = type && (
            type === TRIGGER_TYPE.UPSTREAM_INSTANCE ?
                instancesLoadStatus !== LOAD_STATUS.LOADED :
                feedsLoadStatus !== LOAD_STATUS.LOADED
        );

        const isEditable = !type || type === TRIGGER_TYPE.UPSTREAM_INSTANCE || canViewFeeds;

        return (
            <div className={classnames(local.container, 'container-column')}>
                <div className='container-row'>
                    <SelectInput
                        items={getTriggerTypeItems(canViewFeeds)}
                        width={200}
                        disabled={isOperationInProgress || !isEditable}
                        inputProperties={{ onChange: this.onTypeChange, value: type || '' }}
                        error={this.state.error && !type}
                    />
                    <SelectInput
                        items={items}
                        className={local.triggerItemRoot}
                        width={200}
                        inputProperties={{ onChange: this.onItemChange, value: triggerId || '' }}
                        disabled={!type || isOperationInProgress || !isEditable}
                        isLoading={isLoading}
                        searchable={true}
                        error={this.state.error && type && !triggerId}
                    />
                    {
                        Boolean(configurationTemplate?.panels?.length) &&
                        <div className={local.overrideCheckboxContainer}>
                            <Checkbox
                                label={<FormattedMessage id='common.overrideConfiguration' />}
                                inputProperties={{
                                    value: overrideConfiguration,
                                    onChange: this.toggleOverride
                                }}
                                disabled={isOperationInProgress || !type || !triggerId || !isEditable}
                            />
                        </div>
                    }
                    <div className={local.triggerDeleteIconContainer} onClick={this.onTriggerDelete}>
                        <FontAwesomeIcon icon={faTrashAlt} className={classnames(local.triggerDeleteIcon, { [local.disabled]: !isEditable })} />
                    </div>
                </div>
                <Field name={`transformationInstanceTriggers.trigger_${index}`} component={this.renderError} />
                {
                    configurationTemplate && overrideConfiguration &&
                    <DynamicFieldsSection
                        change={change}
                        config={configurationTemplate}
                        disabled={isOperationInProgress || !isEditable}
                        prefix={`airflowConfigurationOverrides.trigger_${index}`}
                        form='instanceForm'
                        editMode={Boolean(savedConfiguration)}
                        defaultValues={savedConfiguration}
                        customSqlValidator={validateApSql}
                    />
                }
            </div>
        );
    }

    private renderError = ({ meta }) => {
        this.setState({ error: Boolean(meta.error) });

        return (
            <Fragment>
                {
                    meta.error &&
                    <div className={classnames(local.error, 'form-error-message')}>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </Fragment>
        );
    };

    private onTypeChange = (value) => {
        const { updateTrigger, index, change } = this.props;

        change(`transformationInstanceTriggers.trigger_${index}`, null); // to clear field error

        updateTrigger({ index, data: { type: value, id: null } });
    }

    private onItemChange = (value) => {
        const { updateTrigger, data, index, change } = this.props;

        change(`transformationInstanceTriggers.trigger_${index}`, null); // to clear field error

        updateTrigger({ index, data: { ...data, id: value } });
    }

    private toggleOverride = (event, value) => {
        const { updateTrigger, data, index } = this.props;

        updateTrigger({ index, data: { ...data, overrideConfiguration: value } });
    }

    private onTriggerDelete = () => {
        const {
            removeTrigger,
            index,
            isOperationInProgress,
            canViewFeeds,
            data: { type }
        } = this.props;
        const isEditable = !type || type === TRIGGER_TYPE.UPSTREAM_INSTANCE || canViewFeeds;

        if (!isOperationInProgress && isEditable) {
            removeTrigger(index);
        }
    }
}

export default InstanceTrigger;
