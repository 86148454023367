import { renderRoutes } from 'modules/common/utils';
import { RouteItem } from 'modules/common/interfaces';
import ErrorPage from 'modules/common/components/ErrorPage';
import homeRoutes from 'modules/home/routes';
import collectorCredentialsRoutes from 'modules/collectorCredentials/routes';
import targetCredentialsRoutes from 'modules/targetCredentials/routes';
import targetsRoutes from 'modules/targets/routes';
import credentialsRoutes from 'modules/credentials/routes';
import feedsRoutes from 'modules/feeds/routes';
import sourcesRoutes from 'modules/sources/routes';
import adminRoutes from 'modules/admin/routes';
import transformationInstancesRoutes from 'modules/transformationInstances/routes';
import transformationPipelinesRoutes from 'modules/transformationPipelines/routes';
import dataCatalogRoutes from 'modules/dataCatalog/routes';
import uploadsRoutes from 'modules/uploads/routes';
import reportsRoutes from 'modules/reports/routes';
import adverityAuthorizationsRoutes from 'modules/adverityAuthorizations/routes';
import { MAIN_ROUTES } from './constants';

const mainRoutes = [
    {
        path: MAIN_ROUTES.UNAUTHORIZED,
        component: ErrorPage,
        params: {
            error: '401'
        }
    },
    {
        path: MAIN_ROUTES.FORBIDDEN,
        component: ErrorPage,
        params: {
            error: '403'
        }
    },
    {
        path: '/*',
        component: ErrorPage,
        params: {
            error: '404'
        }
    }
];

const routes: RouteItem[] = [
    ...homeRoutes,
    ...credentialsRoutes,
    ...collectorCredentialsRoutes,
    ...targetCredentialsRoutes,
    ...targetsRoutes,
    ...feedsRoutes,
    ...sourcesRoutes,
    ...adminRoutes,
    ...transformationInstancesRoutes,
    ...transformationPipelinesRoutes,
    ...dataCatalogRoutes,
    ...uploadsRoutes,
    ...reportsRoutes,
    ...adverityAuthorizationsRoutes,
    ...mainRoutes
];

export default () => renderRoutes(routes);
