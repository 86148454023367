import React, { FunctionComponent, Fragment, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { filesize } from 'filesize';

import MonthPicker from 'modules/common/components/MonthPicker';
import Table from 'modules/common/components/Table';
import SortableTableHeader from 'modules/common/components/SortableTableHeader';
import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { CONTENT_CONTAINER_MAX_WIDTH, LOAD_STATUS } from 'modules/common/constants';
import {
    getUploadInProgress,
    getUploadsListItems,
    getUploadsListState
} from '../../selectors';
import { getUploadsByDate, uploadsListSort } from '../../actions';

import local from './local.module.scss';

const LIST_SKELETON_ROWS_COUNT = 5;

const getTableConfig = (sortBy, sortOrder) => [
    {
        Header: (config) => (
            <SortableTableHeader
                {...config}
                title={<FormattedMessage id='common.name' />}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
        ),
        id: 'name',
        accessor: 'name',
        width: CONTENT_CONTAINER_MAX_WIDTH, // it allows this column to take all available space
        skeletonWidth: '100%',
        showTooltip: true,
        sortable: true
    },
    {
        Header: (config) => (
            <SortableTableHeader
                {...config}
                title={<FormattedMessage id='common.size' />}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
        ),
        id: 'size',
        accessor: ({ size }) => filesize(size, { base: 2, standard: 'jedec' }),
        width: '20%',
        skeletonWidth: '100%',
        showTooltip: false,
        sortable: true
    },
    {
        Header: (config) => (
            <SortableTableHeader
                {...config}
                title={<FormattedMessage id='common.time' />}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
        ),
        id: 'created',
        accessor: ({ created }) => moment.utc(created).format('DD MMM YYYY [at] HH:mm'),
        width: '30%',
        skeletonWidth: '100%',
        showTooltip: false,
        sortable: true
    }
];

const UploadsHistory: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const uploadInProgress = useSelector(getUploadInProgress);
    const { loadStatus, sortBy, sortOrder, date: selectedMonth } = useSelector(getUploadsListState);
    const items = useSelector(getUploadsListItems);

    useEffect(() => {
        if (loadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getUploadsByDate(moment().startOf('month')));
        }
    }, []);

    useDidUpdateEffect(() => {
        if (loadStatus === LOAD_STATUS.REQUIRED) {
            dispatch(getUploadsByDate(selectedMonth));
        }
    }, [ loadStatus ]);

    const onSort = useCallback((column) => {
        dispatch(uploadsListSort(column));
    }, []);

    return (
        <Fragment>
            <div className={classnames(local.titleContainer, 'container-row')}>
                <div className={local.title}>
                    <FormattedMessage id='uploads.uploadHistory' />
                </div>
                <MonthPicker
                    value={selectedMonth}
                    onChange={(value) => {
                        dispatch(getUploadsByDate(value));
                    }}
                    disabled={uploadInProgress || loadStatus === LOAD_STATUS.LOADING}
                />
            </div>
            <Table
                columns={getTableConfig(sortBy, sortOrder)}
                data={items}
                skeletonRowsCount={LIST_SKELETON_ROWS_COUNT}
                isLoaded={loadStatus === LOAD_STATUS.LOADED}
                onSort={onSort}
            />
        </Fragment>
    );
};

export default UploadsHistory;
