import * as t from '../actionTypes';

export const setPipelinesListItems = (data) => ({
    type: t.SET_PIPELINES_LIST_ITEMS,
    payload: data
});

export const appendPipelinesListItems = (data) => ({
    type: t.APPEND_PIPELINES_LIST_ITEMS,
    payload: data
});

export const setPipelinesListPage = (data) => ({
    type: t.SET_PIPELINES_LIST_PAGE,
    payload: data
});

export const setPipelinesListCount = (data) => ({
    type: t.SET_PIPELINES_LIST_COUNT,
    payload: data
});

export const setPipelinesListLoadStatus = (data) => ({
    type: t.SET_PIPELINES_LIST_LOAD_STATUS,
    payload: data
});

export const setPipelinesListSortColumn = (data) => ({
    type: t.SET_PIPELINES_LIST_SORT_COLUMN,
    payload: data
});

export const setPipelinesListSortOrder = (data) => ({
    type: t.SET_PIPELINES_LIST_SORT_ORDER,
    payload: data
});

export const setPipelinesListSearchText = (data) => ({
    type: t.SET_PIPELINES_LIST_SEARCH_TEXT,
    payload: data
});

export const resetPipelinesList = () => ({
    type: t.RESET_PIPELINES_LIST
});

export const setPipelineDetailsLoadStatus = (data) => ({
    type: t.SET_PIPELINE_DETAILS_LOAD_STATUS,
    payload: data
});

export const setPipelineDetails = (data) => ({
    type: t.SET_PIPELINE_DETAILS,
    payload: data
});

export const resetPipelineDetails = () => ({
    type: t.RESET_PIPELINE_DETAILS
});

export const resetPipelineForm = () => ({
    type: t.RESET_PIPELINE_FORM
});

export const setPipelineFormOperationInProgress = (data) => ({
    type: t.SET_PIPELINE_FORM_OPERATION_IN_PROGRESS,
    payload: data
});
