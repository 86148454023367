import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import local from './local.module.scss';

interface AccordionProps {
    label: ReactNode;
    classes?: { [key: string]: string };
}

const Accordion: FunctionComponent<PropsWithChildren<AccordionProps>> = ({ label, children, classes = {} }) => (
    <MuiAccordion
        classes={{
            root: classnames(local.accordionRoot, classes.root)
        }}
    >
        <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            classes={{
                root: classnames(local.accordionSummaryRoot, classes.summaryRoot),
                content: classnames(local.accordionSummaryContent, classes.summaryContent),
                expandIconWrapper: local.expandIcon
            }}
        >
            {label}
        </AccordionSummary>
        <AccordionDetails
            classes={{
                root: classnames(local.accordionDetailsRoot, classes.detailsRoot)
            }}
        >
            {children}
        </AccordionDetails>
    </MuiAccordion>
);

export default Accordion;
