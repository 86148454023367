import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { getClientsList, getSelectedClientId } from 'modules/auth/selectors';
import { isAdminMenuAccessible } from '../../selectors';
import UserMenu from '../UserMenu';
import ClientSelect from '../ClientSelect';
import Logo from '../Logo';
import Tabs from '../Tabs';
import AdminMenu from '../AdminMenu';

import local from './local.module.scss';

const Menu = () => {
    const clients = useSelector(getClientsList);
    const selectedClientId = useSelector(getSelectedClientId);
    const client = clients.find(({ id }) => id === selectedClientId);

    let clientSection: JSX.Element | null = null;
    if (clients.length > 1) {
        clientSection = <div className={local.clientSelectContainer}><ClientSelect /></div>;
    } else if (clients.length && client) {
        clientSection = <div className={local.clientName}>{client.name}</div>;
    }

    const showAdminMenu = useSelector(isAdminMenuAccessible);

    return (
        <div className={classnames('container-row', local.menuContainer)}>
            <div className={classnames('container-row', local.startSection)}>
                <Logo />
                {clientSection}
            </div>
            <Tabs />
            <div className={local.endSection}>
                {/* TODO add 'notification' and 'help' icons */}
                <UserMenu />
                {showAdminMenu && <AdminMenu />}
            </div>
        </div>
    );
};

export default Menu;
